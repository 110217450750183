<form [formGroup]="formGroupSearch">
    <div class="row align-center m-1 p-0 filter-card">
        <div class="col-sm-12 m-1 p-1 card-border">
            <mat-form-field class="col-sm-12 p-0 d-flex" appearance="outline">
                <span class="col-sm-1 align-center p-0">
                    <mat-icon matPrefix class="search-icon">search</mat-icon>
                </span>
                <span class="col-sm-10">
                    <input class="ipt-height" matInput type="search" formControlName="frmInput"
                        [placeholder]="placeholder"
                        (keyup)="fetchUserSeries(formGroupSearch.value.frmInput,placeholder)" />
                </span>
                <span class="col-sm-1 align-center p-0">
                    <mat-icon *ngIf="formGroupSearch.value.frmInput" title="Clear" matPrefix class="cursor"
                        (click)="clearSearch()">close</mat-icon>
                </span>
            </mat-form-field>

            <div class="card-wrap">
                <span>
                    <div *ngFor="let item of DataList">
                        <mat-checkbox [color]="task.color" [checked]="item.checked"
                            (change)="selectedData($event,item)">
                            {{getDisplayName(item)}}</mat-checkbox>
                    </div>
                </span>
            </div>
        </div>
    </div>
</form>