<div class="text-center mt-2 popup-user">
  <div mat-dialog-title class="m-0 p-0">
    <img src="{{data.imagePath}}" alt="Warning" class="material-icons md-48">
    <h1 class="header m-0 p-0">{{data.header}}</h1>
  </div>
  <!-- <div class="header-div">
    <b mat-dialog-title class="header-text">{{ data.header }}</b>
    <span
      type="button"
      data-dismiss="modal"
      (click)="closeDialog()"
      class="mr-2"
    >
      <img
        src="../../../../assets/icons/Close_Icon.svg"
        alt="Close"
        class="material-icons md-18 cursor"
        title="Close"
      />
    </span>
  </div> -->
  <div mat-dialog-content class="m-2 m-0 p-0">
     <div class="message-wrap" >
        <span class="show-password" *ngIf="data.type === 'generate-password'">The Password for <b>{{data.row1value}}</b> is <b>{{data.row2value}}</b></span>
      <div class="col-sm-12 user-status-div" *ngIf="data.type === 'lockunlock-user'">
        <div class="col-sm-12 d-flex"><span class="col-sm-6 name-wrapper"> {{data.row1text}} </span> <span>:</span>
          <span class="col-sm-6 name-wrapper" matTooltip="{{data.row1value}}"><b>{{ data.row1value}}</b></span>
        </div>
        <div class="col-sm-12 d-flex"><span class="col-sm-6 name-wrapper"> {{data.row2text}} </span> <span>:</span>
          <span class="col-sm-6 name-wrapper" matTooltip="{{data.row2value}}"><b>{{ data.row2value }}</b></span>
        </div>

        <!-- <div class="tool-tip" matTooltip="{{data.row1value | titlecase}}">{{data.row1text}} : <b>{{ data.row1value | titlecase }}</b></div>
        <div>{{data.row2text}}<span [ngStyle]="{'margin-left.px': data.type === 'lockunlock-user' ? 31 : 11}">:</span><b> {{ data.row2value }}</b></div> -->

      </div>
      <div class="col-sm-12 message" *ngIf="data.type === 'mail-sent'">
          <p>{{data.message}}</p>
          <div *ngIf="data.email || data.phone" class="suppor-info">
            <div class="d-flex align-items-center">
              <mat-icon class="vlm-mat-icon">mail</mat-icon>
              <span>{{data.email || 'N/A'}}</span>
            </div>
              <div  class="d-flex align-items-center">
                <mat-icon class="vlm-mat-icon">phone</mat-icon>
                <span>{{data.phone || 'N/A'}}</span>
              </div>
          </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions class="col-sm-12 d-flex justify-content-center align-self-center mb-3">
    <!-- <button type="button" class="col-sm-4 btn cancelButton mr-2" (click)="closePopup()"
      [ngClass]="{'d-none': isMessageValid()}">
      <span>Close</span>
    </button> -->
    <app-button buttonclass="secondary" class="mr-2 popup-btn" [ngClass]="{'d-none': isMessageValid()}" customClass="admin" (click)="closePopup()">Close</app-button>

    <app-button buttonclass="primary" [class]="isMessageValid() ? 'single-btn' : 'popup-btn'" customClass="admin" (click)="!isUpdatOnProcess && actionClick()">{{ data.buttonname }}</app-button>
    <!-- <button type="button" class="col-sm-4 btn lock-unlock-button" (click)="!isUpdatOnProcess && actionClick()">
      <span class="lock-unlock-button-text" [ngClass]="{'action-button-disabled': isUpdatOnProcess}">{{ data.buttonname
        }}</span>
    </button> -->
  </div>
</div>
<div class="lock-unlock-user" *ngIf="isUpdatOnProcess">
  <mat-spinner class="labadmin-spinner" [diameter]="50">
  </mat-spinner>
</div>