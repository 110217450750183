
export interface UserRoles {
  userID: number,
  role: string,
  isPrimary: boolean,
  status: boolean,
  usrStatus: string,
  modifyTechnicalData: boolean,
  isModifyTechnicalDataAccession: boolean,
  allowReviewPathCanSignOut: boolean,
  allowPathAsMedicalDirector: boolean,
  diagICDcodeReminder: boolean,
  isCTRCertified: boolean,
  allowAdminAsPathologistPrivileges: boolean,
  holdReleas: string,
  showMyCaseOnly: boolean
}

export interface Roles {
  DisplayName: string,
  RoleName: string,
  isSelected: boolean,
  isPrimary: boolean
  isDisable: boolean
  status? : boolean,
  isSaved:boolean,
  userId:number
}

export interface States {
  LookupID: string;
  LookupValue: string;
  Description: string;
  SequenceOrder: number;
}

export interface Services {
  Attribute_Type: string,
  Attribute_Name: string,
  Attribute_Value: string
}

export class mediaConstants {
  public static readonly attachments = { filetype: 'bat,exe,dll', fileSize: '5' /* only in mb */ }
  public static readonly logo = { filetype: 'jpg,png,jpeg', fileSize: '5' /* only in mb */ }
  public static readonly usersSignature = { filetype: 'jpg,png,jpeg,bmp,gif', fileSize: '5' /* only in mb */ }
}

export interface associationForSE {
  orderingFacilityName: string,
  orderingFacilityId: number,
  locations: associatedAccounts[]
  filteredLoc: associatedAccounts[],
  selectedObjects: associatedAccounts[],
  isPreviouslySelected: {value : boolean}
}

export interface associatedAccounts {
  associatedaccountid: number,
  displayname: string,
  accountname: string,
  status: string,
  organizationid: number,
  isAssociated: boolean,
  OrganizationName: string
}

export interface labLocations {
  AccountId: string,
  OrganizationName: string,
  OrganizationId: string,
  DisplayName: string,
  OrgGUID: string,
  accountGUID?: string
  IsChecked: boolean
  isReadingLocationEnabled?: boolean
}

export interface savedRole {
  UserID: number,
  Role: string,
  IsPrimary: boolean,
  Status: boolean,
  Usrstatus: string,
  ModifyTechnicalData: boolean,
  IsModifyTechnicalDataAccession: boolean,
  AllowReviewPathCanSignOut: boolean,
  AllowPathAsMedicalDirector: boolean,
  DiagICDcodeReminder: boolean,
  IsCTRCertified: boolean,
  AllowAdminAsPathologistPrivileges: boolean,
  HoldReleas: number,
  ShowMyCaseOnly: boolean,
  IsGrosser: boolean,
  IsGrossingTranscriptionist: boolean

}

export interface Lookup {
  LookupID: string;
  LookupValue: string;
  Description: string;
  SequenceOrder: number;
}
export class NPIDataFromLookup {
  npi: string
  firstName: string
  lastName: string
  middleInitial: string
  gender: string
  accountName: string
  city: string
  state: string
  zip: string
  address1: string
  primaryContactPhone: string
  npiVerifiedByUser: boolean
  npiVerifiedByMdm: boolean
  npiVerifiedByMdmDate: string
  npiStatus: string
  mdmId: number
  verifiedFrom: string
  mdmVerifiedStatus: string
  verifiedDateFromUser: Date
  ProviderStatus: string
  ProviderStatusDetails: string
}

// export class mediaConstants {
//   public static readonly attachments = {
//     filetype: 'bat,exe,dll',
//     fileSize: '5' /* only in mb */,
//   };
//   public static readonly logo = {
//     filetype: 'jpg,png,jpeg',
//     fileSize: '5' /* only in mb */,
//   };
// }

// export interface Lookup {
//   LookupID: string;
//   LookupValue: string;
//   Description: string;
//   SequenceOrder: number;
// }

export interface SalesExecutiveUsers {
  UserID: number;
  name: string;
  RoleName: string;
}

// export interface Services {
//   Attribute_Type: string;
//   Attribute_Name: string;
// }

export interface CustomOrgAttributes {
  Attribute: string;
  CAID: number;
  LaborgId: number;
  value: string;
}
export interface StateAndCity {
  City: string;
  State: string;
}

export interface LabLocation {
  AccountId: string;
  OrganizationName: string;
  OrganizationId: string;
  DisplayName: string;
  IsChecked: boolean;
}

export interface savedRole {
  UserID: number,
  Role: string,
  IsPrimary: boolean,
  Status: boolean,
  Usrstatus: string,
  ModifyTechnicalData: boolean,
  IsModifyTechnicalDataAccession: boolean,
  AllowReviewPathCanSignOut: boolean,
  AllowPathAsMedicalDirector: boolean,
  DiagICDcodeReminder: boolean,
  IsCTRCertified: boolean,
  AllowAdminAsPathologistPrivileges: boolean,
  HoldReleas: number,
  ShowMyCaseOnly: boolean,
  IsGrosser: boolean,
  IsGrossingTranscriptionist: boolean

}

export interface AssociatedAccount {
  AccountID: string,
  AccountName: string,
  Status: string,
  DisplayName: string
  LocationEnabled: boolean,
  IsSharedSurgicenter: boolean,
  IsChecked: boolean
  isReadingLocationEnabled?: boolean
}
export interface VaRulesExclusions {
  exclusionId: string,
  ruleId: number,
  entityType: string,
  entityId: string,
}
export interface templateOrganizations {
  OrganizationId: string,
  OrganizationName: string
}