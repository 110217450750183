import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserSession } from 'src/app/core/services/user.session';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../core/services/commonservices';
import { DataShareService } from '../../core/services/datashare.service';
import { InjectorService } from '../../core/services/Injectorservices';
import { VitalHttpServices } from '../../core/services/VitalHttpServices';
import { VitalAdminTab } from '../commoncomponents/tab';
import { InitialsearchpageComponent } from '../initialsearchpage/initialsearchpage.component';
import { VitalAdminTabService } from '../tab.service';

// import { SubMenuCardModel } from '../DbModel/SubMenuCard/Submenucardmodel';
@Component({
  selector: 'app-vital-admin-search-connector',
  templateUrl: './vital-admin-search-connector.component.html',
  styleUrls: ['./vital-admin-search-connector.component.scss'],
})
export class VitalAdminSearchConnectorComponent implements OnInit, OnChanges {
  tabs = new Array<VitalAdminTab>();
  @ViewChild('myDiv') myDiv: ElementRef;
  selectedTab!: number;
  @Input() currentTab: number = 0;
  @Input()
  searchTabContent: any = {};
  @Output() adminsearchTabContent = new EventEmitter();
  @Output() adminsearchTabCompleted = new EventEmitter<any>();
  currentAccountId: number = 0;
  cardType: string = '';
  public count: number = 0;
  public rolepermission;
  public tab: any = {};
  public permission: any = {};
  public context: string = '';

  // public GetQuery(keyword: string) {
  //   let query: string = '';
  //   let mainQueryIndex: number = this.vitalHttpServices.mainQueryList.findIndex(
  //     (mainQuery) =>
  //       mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase()
  //   );
  //   if (mainQueryIndex > -1)
  //     query = this.vitalHttpServices.mainQueryList[mainQueryIndex].Query;
  //   return query;
  // }

  // public GetconfigQuery() {
  //   let query =
  //     'query ($RoleName: String, $CardType: String) {    submenuData: VitalAdminUserRoles_list(RoleName: $RoleName, CardType: $CardType) {      RoleName      CardType      Permission      Status    }  }';
  //   return query;
  // }
  data: any = {};
  dxurl: any;
  initialsearchpage: InitialsearchpageComponent;
  orgTypeObj: any;
  search_context: any;
  constructor(public injectorService: InjectorService, public commonService: CommonService, public cookieService: CookieService,
    public router: Router, public usersession: UserSession, public vitalHttpServices: VitalHttpServices,
    public tabService: VitalAdminTabService, private ngxService: NgxUiLoaderService,
    private datashare: DataShareService, private _snackbar: MatSnackBar, public DatabaseService: VitalHttpServices,
    // private subMenuCardModel:SubMenuCardModel
  ) {
    this.injectorService.setCommonService(this.commonService);
    this.injectorService.setTabService(this.tabService);
    this.injectorService.setVitalHttpServices(this.vitalHttpServices);

    this.initialsearchpage = new InitialsearchpageComponent(
      this.tabService,
      this.commonService,
      this.vitalHttpServices,
      this.ngxService,
      this._snackbar
    );
  }
  ngOnInit() {
    this.ngxService.start('entityLoad');
    try{
      this.geturl();
      this.tabService.tabSub.subscribe((tabs) => {
        // pipe(take(1)).
        if (tabs.length > 0) {
          if (tabs[0].cardType === 'Users' || tabs[0].cardType === 'Physician' || tabs[0].cardType === 'Pathologist') {
            tabs[0].Removable = false;
          }
        }
        this.tabs = tabs;
        if (this.tabs.length > 0) {
          this.tabChanged(this.tabs[0]);
        }
        this.selectedTab = tabs.findIndex((tab) => tab.active);
      });
      this.ngxService.stop();
    }
    catch(error) {
      console.error(error);
      this._snackbar.open('An error occurred while processing your request','Close');
      this.ngxService.stop('entityLoad');
    }
  }

  scrolltoTop(event){
    // this.myDiv.nativeElement.scrollTop = 0;
    document.getElementById('container_maincard').scrollIntoView({ behavior: "smooth"});
  }
  // to get the DX Url
  geturl() {
    let query = this.vitalHttpServices.GetQuery('organizationList');
    let queryVariable: any = {
      "organizationID": this.commonService.orgid.toString(),
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult).subscribe(
      (data) => {
        this.ngxService.stop();
        if(!data.error)
        {
          let apiURLdata = data.data.submenuData!=null
            ? data.data.submenuData[0].AppUrl
            : null;

          this.vitalHttpServices.OrgaAppUrl="";
          if (apiURLdata != null && apiURLdata != undefined && apiURLdata != "") {
            this.vitalHttpServices.OrgaAppUrl = apiURLdata;
            this.dxurl = apiURLdata;
          }      
                
          else
          {
            this.dxurl = this.DatabaseService.GetDxurlBasedOnDeployment(sessionStorage.getItem('deploymentKey')=='configdb'?sessionStorage.getItem('DeploymentKey'):sessionStorage.getItem('deploymentKey'));
          }
        }

      },(error) => {
        this.ngxService.stop();
        this._snackbar.open('An error occurred while processing your request', 'Failed');
      });

  }

  // to re-direct the Dx
  goToLink(dxurl: string) {
    window.open(dxurl)
  }

  tabChanged(event: any) {
    this.geturl();
    let eventIndex: number = -1;
    if (event.index != -1) eventIndex = event.index;
    this.tabService.makeTabActiveByIndex(eventIndex);
    // this.vitalHttpServices.SubmenuAction = this.tab.roleMenuPermissions;
    //this.vitalHttpServices.Action = this.tab.rolepermission;
    // this.datashare.getMenuButtonData(this.tab.roleMenuPermissions);
    // this.datashare.getActionButtonData(this.tab.rolepermission);
  }
  removeTab(index: number): void {
    this.tabService.removeTab(index);
  }

  GetUserIdInfo() {
    let query = this.vitalHttpServices.GetQuery('userroles');
    let queryRequest: any = {
      OperationName: null,
      Query: query,
      Variables: { email: localStorage.getItem("UserEmail") },
    };
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryRequest).subscribe(
      (data) => {
        this.ngxService.stop();
        if (!data.errors && (data != undefined && data.data.submenuData.length > 0)) {
          let userid = data.data.submenuData[0].userid;
          sessionStorage.setItem('Userid', userid);
        }
        else {
          sessionStorage.setItem('Userid', '');
        }
      },(error) => {
        this.ngxService.stop();
        console.error(error);
        this._snackbar.open('An error occurred while processing your request', 'Failed');
      });
  }

  GetRoleInfo(OrganizationId) {
    this.GetUserIdInfo();
    let queryVariable = {
      UserEmail: localStorage.getItem("UserEmail"),
      Organizationid: OrganizationId.toString(),
    };
    let query = this.vitalHttpServices.GetQuery('role');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe((Resdata) => {
      let queryVariable;
      if (Resdata.data.submenuData && Resdata.data.submenuData.length > 0) {
        this.rolepermission = Resdata.data.submenuData[0];
        // sessionStorage.setItem('UserEmail', this.rolepermission['UserEmail']);
        sessionStorage.setItem('RoleName', this.rolepermission['RoleName']);
        queryVariable = {
          // RoleName: sessionStorage.getItem('RoleName'),
          RoleName: this.rolepermission['RoleName'],
          //CardType: this.context,
          CardType: sessionStorage.getItem('contextdata')
        };

      }
      else {
        let allowAllUsersRole = atob(localStorage.getItem("allowAllUsersRole"));
        sessionStorage.setItem('RoleName', allowAllUsersRole);
        queryVariable = {
          RoleName: allowAllUsersRole,
          // CardType: this.context,
          CardType: sessionStorage.getItem('contextdata')
        };
      }
      this.getPermissionDetails(queryVariable)
    },error => {

        console.error(error);
        this._snackbar.open('An error occurred while processing your request', 'Failed');
      });
  }

  GetUserInfo(UserId) {
    this.GetUserIdInfo();
    this.vitalHttpServices.GetUserRoleInfo(UserId, localStorage.getItem("UserEmail"), this.cardType).subscribe((Info) => {
      let queryVariable;
      if (Info.Rolename) {
        sessionStorage.setItem('RoleName', Info.Rolename);
        queryVariable = {
          RoleName: Info.Rolename,
          CardType: this.context,
        };
      }
      else {
        let allowAllUsersRole = atob(localStorage.getItem("allowAllUsersRole"));
        sessionStorage.setItem('RoleName', allowAllUsersRole);
        queryVariable = {
          RoleName: allowAllUsersRole,
          CardType: this.context
        };
      }
      this.getPermissionDetails(queryVariable);
    },error => {

        console.error(error);
        this._snackbar.open('An error occurred while processing your request', 'Failed');
      });
  }

  getPermissionDetails(queryVariable) {
    this.ngxService.start();
    let query = this.vitalHttpServices.GetconfigQuery();
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    sessionStorage.setItem('deploymentKey', 'configdb');
    this.vitalHttpServices.GetData(queryResult).subscribe((Resdata) => {
      if (!Resdata.errors) {
        let newQuery = this.vitalHttpServices.mainQueryList.filter(x => x.Keyword == "getVitalAdminMenus")[0].Query;
        let newQueryVariable = {
          // CardName: this.context
          CardName: sessionStorage.getItem('contextdata')
        };
        let newQueryResult = this.commonService.GetCardRequest(newQueryVariable, newQuery)
        this.vitalHttpServices.GetData(newQueryResult).subscribe((newResData) => {
          sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
          let roledata = JSON.parse(Resdata.data.submenuData[0].Permission);
          //this.tab.rolemenu = roledata.MenuItems;
          this.tab.rolemenu = JSON.parse(newResData.data.submenuData[0].JSONMenu).MenuItems;
          this.tab.rolepermission = roledata.ActionButton;
          this.tab.roleMenuPermissions = roledata.MenuItems;
          this.vitalHttpServices.SubmenuAction = roledata.MenuItems
          // if (sessionStorage.getItem('search_context').toLowerCase() == 'casetypetemplate' || sessionStorage.getItem('search_context').toLowerCase() == 'homepage')
          //   this.tab.rolepermission = roledata.ActionButton;
          // else
          //   this.tab.rolepermission = roledata;
          this.tab.cardtype = this.context;
          this.tab.text = this.context;
          // if (temp_context == 'lab' || temp_context == 'location' || temp_context == 'facility')
          //   this.tab.Removable = false;
          // else
          this.tab.Removable = false;
          let mainQueryIndex: any = this.vitalHttpServices.mainQueryList.length;
          if (mainQueryIndex > 0) {
            this.initialsearchpage.addNewOrSelectTab(this.tab);
          } else {
            let newqueryRequest: any = {
              OperationName: null,
              Query: this.vitalHttpServices.mainQuery,
              Variables: {}
            };
            this.ngxService.start();
            this.vitalHttpServices.GetData(newqueryRequest).subscribe((result) => {
              if (!result.errors) {
                this.vitalHttpServices.mainQueryList = result.data.Card;
                this.initialsearchpage.addNewOrSelectTab(this.tab);
              } else {
                this._snackbar.open('An error occurred while processing your request', 'Failed');
              }
              this.ngxService.stop();
            },(error) => {
                this.ngxService.stop();
                this._snackbar.open('An error occurred while processing your request', 'Failed');
                console.error(error);
              });
          }
          this.ngxService.stop();
        }, error => {
          this.ngxService.stop();
          console.error(error);
          sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
        });
      }
    }, error => {
      sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
      this.ngxService.stop();
      console.error(error);
    });
  }

  logout() {
    this.vitalHttpServices.logout();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!localStorage.getItem('user_name') || localStorage.getItem('user_name') === '') {
      this.logout();
    }
    else {
      this.tabService.tabs = [];
      let UniqueID;
      let OrganizationId;
      this.tabService.tabSub.next(this.tabService.tabs);
      this.commonService.orgidAssign(this.searchTabContent.DisplayField.organizationid)
      let search_context = this.searchTabContent.Context.toLowerCase();
      this.search_context = search_context;
      let ContextType=this.searchTabContent.DisplayField.context;
      if (this.searchTabContent.DisplayField.context)
        this.searchTabContent.DisplayField.context =
          (this.searchTabContent.DisplayField.context.toLowerCase() == 'laboratory' ||
            this.searchTabContent.DisplayField.context.toLowerCase() == 'send out' ||
            this.searchTabContent.DisplayField.context.toLowerCase() == 'epg' ||
            this.searchTabContent.DisplayField.context.toLowerCase() == 'send in' ||
            this.searchTabContent.DisplayField.context.toLowerCase() == 'send in lab'
          ) ? 'lab' : this.searchTabContent.DisplayField.context;
      else
        this.searchTabContent.DisplayField.context = this.searchTabContent.Context.toLowerCase();
      sessionStorage.setItem('search_context', this.searchTabContent.DisplayField.context);
      // sessionStorage.setItem('search_context', this.searchTabContent.Context.toLowerCase());
      // let labname = this.searchTabContent.DisplayField.labname;
      let labname = this.searchTabContent.DisplayField.locationname ? this.searchTabContent.DisplayField.locationname : this.searchTabContent.DisplayField.labname;
      sessionStorage.setItem('locationName', labname);
      if (search_context == 'userid' || search_context == 'users' || search_context == 'pathologist' || search_context == 'physician') {
        UniqueID = this.searchTabContent.DisplayField.userid; //dxuserid
        sessionStorage.setItem('DxUserId',UniqueID);
        sessionStorage.setItem('locationName',this.searchTabContent.DisplayField.displayname);
      } else {
        UniqueID = this.searchTabContent.DisplayField.locationid;//account id
        sessionStorage.setItem('Locationid', UniqueID);
        OrganizationId = this.searchTabContent.DisplayField.organizationid;//org id
        sessionStorage.setItem('org_id', OrganizationId)
      }
      if (
        this.searchTabContent && this.searchTabContent.DisplayField && UniqueID &&
        this.searchTabContent && this.searchTabContent.Context) {
        this.currentAccountId = UniqueID;
        this.context = this.searchTabContent.Context.toLowerCase();
        this.cardType = this.context;
        if (this.cardType == 'userid') {
          this.context = 'users';
          this.cardType = 'users';
        }
        //sessionStorage.setItem('deploymentKey', 'configdb');
        //check for else then this card type based on there context
        if (this.context == 'location' || this.context == 'accid' || this.context == 'orgid') {
          let Context = this.searchTabContent.DisplayField.context.toLowerCase();
          let data;
          if (Context == 'laboratory' || Context == 'send out' || Context == 'epg' || Context == 'send in') {
            data = "Lab";
            sessionStorage.setItem('contextdata', data);
          }
          // else {
          //   sessionStorage.setItem('Context', Context);
          // }
          sessionStorage.setItem('contextdata', Context);

        }
        else {
          sessionStorage.setItem('contextdata', this.context);
        }

        this.vitalHttpServices.deploymentKey = this.searchTabContent.DisplayField.deploymentkey.toString().trim();
        this.tab.active = true;
        this.tab.accountid = this.currentAccountId;
        this.searchTabContent.DisplayField.context=ContextType;
        if (this.cardType == 'lab' || this.cardType == 'location' || this.cardType == 'facility' ||
          this.cardType == 'accid' || this.cardType == 'orgid') {
          this.GetRoleInfo(OrganizationId);
        } else if (this.cardType == 'users' || this.cardType == 'physician' || this.cardType == 'pathologist') {
          this.GetUserInfo(UniqueID);
        }
      }
    }
  }


  changeTab(event){
    this.tabs[event.index].tabData.mainCard.redirectDest = event.destination;
    this.tabs[event.index].tabData.mainCard = { ...this.tabs[event.index].tabData.mainCard };
    this.tabChanged({index : event.index});
  }
}
