import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShimmeruiComponent } from './shimmerui/shimmerui.component';
import { SpinnerLoaderComponent } from './spinner-loader/spinner-loader.component';
import {  MatProgressSpinnerModule } from '@angular/material/progress-spinner';




@NgModule({
  declarations: [
    ShimmeruiComponent,
    SpinnerLoaderComponent
  ],
  exports: [ShimmeruiComponent,SpinnerLoaderComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ]
})
export class ShimmerModule { }
