import { Injectable, SimpleChanges } from '@angular/core';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { CardQueries } from '../../model/card-query';
import { DataShareService } from './datashare.service';
import { VitalHttpServices } from './VitalHttpServices';
import { MultisheetPopupComponent } from 'src/app/base/popup/multisheet-popup/multisheet-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { CreateActivityTrackerInput, Attachments, Operations } from 'src/app/model/activity-tracker';
import * as XLSX from 'xlsx';
import { ActivityTrackerService } from './activity-tracker.service';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { FormGroup } from '@angular/forms';

@Injectable()

export class CommonService {
  public subjectTest = new BehaviorSubject<any>({});
  public facilityIdChange = new BehaviorSubject<any>({});
  public facilityInput: string = '';
  private cardQueries: CardQueries = new CardQueries();
  public menu_accountChange = new BehaviorSubject<any>({});
  public falseToTrue = new BehaviorSubject<boolean>(false);
  public orgid;
  orgGuid: any;
  oldJsonData = {};
  ActivityTrackerObject: {};
  isLiveAccount: any = false;
  selectdSheet: any;
  selectedRolesUserIDForMicroCodes = null;
  selectedUserForCaseComments = null;
  selectedUserForAC = null;
  finalizeAfterTc: any;
  isCompendiumCaseType : boolean = false;
  isScreeningEnabled: any;
  createActivityInput = new CreateActivityTrackerInput();
  createActivityOperations = new Operations();
  createActivityAttachments = new Attachments();
  templateData: any = {};
  labAdminSessionData: any;
  isPol: boolean = false;
  debouncerTime: any;
  selectedContext: any = '';
  selectedRepresentatives:any = []
  adminLoggedInUser :string='';
  adminLoggedInUserEmail :string= '';
  locationname: string='';

  constructor(public vaservices: VitalHttpServices, public dataService: DataShareService, private dialog: MatDialog, public datePipe: DatePipe,
    public ngxService: NgxUiLoaderService, public activityService: ActivityTrackerService,private labdaminService: LabadminService,
    public _snackbar: MatSnackBar,private labAdminSessionService : LabadminSessionService,) {
  }

  // setBooleanToTrue(valueChange: boolean) {
  //   this.falseToTrue.next(valueChange);
  // }

  // getBooleanValue() {
  //   return this.falseToTrue.asObservable();
  // }

  //#region set orgid for Lab Location Facility
  getOrgid() {
    return this.orgid;
  }

  orgidAssign(orgid: string) {
    if (orgid != null || orgid != undefined || orgid != '') {
      this.orgid = orgid;
    }
  }

  //return the eligible roles for the user for a particular feature
  userRoles(feature: string): Array<any> {

    let userData = this.dataService.userData.filter((va: any) => this.dataService.eligibleRoles[feature].some(val => val["role"] == va["RoleName"]) &&
      va.status && va.status.toString().toLowerCase() == 'active')
    let uniqueData: any = [...new Map(userData.map(item =>
      [item['userid'], item])).values()];
    if (sessionStorage.getItem("search_context").toLowerCase() == 'pathologist') {
      let eligibleRoles = uniqueData.filter((va: any) => va.RoleName.toLowerCase() == 'pathologist')
      eligibleRoles.push(...uniqueData.filter((va: any) => va.RoleName.toLowerCase() != 'pathologist'));
      uniqueData = eligibleRoles;
    }
    else if (sessionStorage.getItem("search_context").toLowerCase() == 'users') {
      let eligibleRoles = uniqueData.filter((va: any) => va.primaryrole)
      eligibleRoles.push(...uniqueData.filter((va: any) => !va.primaryrole));
      uniqueData = eligibleRoles;
    }
    return uniqueData;
  }

  //#endregion
  GetQueryResult(queryVariable: any, query: string) {
    let cancellationToken: any = {
      'IsCancellationRequested': false,
      'CanBeCancelled': true
    };
    let queryRequest: any = {
      "OperationName": null,
      "Query": query,
      "Variables": queryVariable
    };
    return queryRequest;
  }

  GetUpdateQuery(QueryVariable) {
    let update = {
      "OperationName": null,
      "Query": `mutation ($inputdata : UpdateInputCondidtion!,$dynamic: DynamicUpdate!) {
        MutationUpdate(uinputdata:$inputdata updateobj: $dynamic) {
          details
        }
      }`,
      "Variables": QueryVariable
    }
    return update;
  }

  GetCardRequest(queryVariable: any, query: string) {
    return this.GetQueryResult(queryVariable, query);
  }

  RemoveLabelFromData(data: any, removelabel: string) {
    let labels = Object.keys(data);
    const index = labels.indexOf(removelabel, 0);
    if (index > -1) {
      labels.splice(index, 1);
    }
    return labels;
  }

  RemoveLabelFromLabels(labels: string[], removelabel: string) {
    const index = labels.indexOf(removelabel, 0);
    if (index > -1) {
      labels.splice(index, 1);
    }
    return labels;
  }

  setBooleanToTrue(valueChange: boolean) {
    this.falseToTrue.next(valueChange);
  }

  getBooleanValue() {
    return this.falseToTrue.asObservable();
  }

  //making commom function for checking P4 deployements (THX and THXVAR)
  isP4deployment(deployment: string) {
    if (deployment.toLowerCase() === 'thx' || deployment.toLowerCase() === 'thxvar') {
      return true;
    }
    else {
      return false;
    }
  }

  GetOrganizationGuid() {
    return this.orgGuid;
  }

  GuidAssaigning(orgGuid: string) {
    // simple check, title must be at least 1 char
    if (orgGuid != null) {
      this.orgGuid = orgGuid;
    }
  }

  downloadBlob(data, filename, isjson, size) {
    let blobData: any;
    var blob: any;
    if (isjson) {
      blobData = JSON.stringify(data);
      blobData = JSON.stringify(JSON.parse(blobData));
      blob = new Blob([blobData], { type: 'application/text' });
    } else {
      let byteCharacters = atob(data);
      let byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += size) {
        let slice = byteCharacters.slice(offset, offset + size);

        let byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      blob = new Blob(byteArrays, { type: 'application/text' });
      //return new Blob(byteArrays, { type: contentType });
    }

    if ((window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(blob, filename);
    } else {
      const elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      window.URL.revokeObjectURL(elem.href);
      document.body.removeChild(elem);
    }
  }

  compare(newobj, oldObj) {
    let ActivityTracker = {};
    this.oldJsonData = {};
    for (const key1 of Object.keys(oldObj)) {
      for (const key2 of Object.keys(newobj)) {
        if (key1.toLowerCase() == key2.toLowerCase()) {
          if (oldObj[key1] != newobj[key2]) {
            ActivityTracker[key1] = newobj[key2];
            this.oldJsonData[key1] = oldObj[key1];
          } else if (this.isJSON(oldObj[key1]) && this.isJSON(newobj[key2])) {
            ActivityTracker[key1] = newobj[key2];
            this.oldJsonData[key1] = oldObj[key1];
          }
        }
      }
    }
    return ActivityTracker;
  }

  isJSON(value) {
    try {
      JSON.parse(value);
      if (typeof (JSON.parse(value)) == 'object'
        && JSON.parse(value)['type'] == 'file') {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  createActivityTracker(info, entityid, entityType, actionType, obj, oldobject, text?: string) {
    this.ActivityTrackerObject = {};
    this.ActivityTrackerObject['info'] = info;
    this.ActivityTrackerObject['customerid'] = this.orgGuid;
    this.ActivityTrackerObject['entitytype'] = entityType; //custom report
    this.ActivityTrackerObject['entityid'] = entityid; //report id
    this.ActivityTrackerObject['actiontype'] = actionType; //audit,
    this.ActivityTrackerObject['username'] = localStorage.getItem('user_name');;
    this.ActivityTrackerObject['userid'] = localStorage.getItem('UserEmail');;
    this.ActivityTrackerObject['Org_Name'] = sessionStorage.getItem('Org_Name');
    this.locationname= sessionStorage.getItem('locationName');
    // let extendedAttributesArr;
    // for (let m = 0; m < oldobject.length; m++) {
    // extendedAttributesArr=[];
    let primary = '';
    let extendedAttributes = {};
    if (!text) {
      // if (oldobject.length > 0) {
      let i = 1;
      for (let [key, value] of Object.entries(this.compare(obj, oldobject))) {
        primary = key + ' changed from ' + this.oldJsonData[key] + ' to ' + value;
        extendedAttributes['additionalProp' + i] = primary;
        primary = '';
        i++;
      }
      // }
      // else {
      //   let i = 1;
      //   for (let j = 0; j < obj.length; j++) {
      //     for (let [key, value] of Object.entries(obj[j])) {
      //       {
      //         primary = key + ' is ' + value;
      //         extendedAttributes['additionalProp' + i] = primary;
      //         primary = '';
      //         i++;
      //       }
      //     }
      //   }
      // }
    } else {
      extendedAttributes["operation"] = text
    }
    // extendedAttributesArr.push(extendedAttributes)
    // }
    this.ActivityTrackerObject['extendedAttributes'] = extendedAttributes;
    this.vaservices.CreateActivityTracker(this.ActivityTrackerObject).subscribe((res) => { });
  }

  createActivityObject(entityid, changedValue, entityType, actionType, newObj, oldobject, transactionId, auditableColumns, uploadedFile?: any,jsonData?:any, activityInfo?:any) {
    try {
      let internalUserRegex = /^\w+([\.-]?\w+)@starmarkit|@vitalaxis(\.\w{2,3})+$/;
      let date = new Date();
      let entityTypeContext: any
      let deployid = sessionStorage.getItem('DeploymentKey')
      this.activityService.getActivitySession.subscribe(activityTab => entityTypeContext = activityTab);

      let checkLaunchUrl=window.location.href.split('/#/')[1]
      if(checkLaunchUrl.toString().toLocaleLowerCase() == "home"){
      this.createActivityInput.productId = entityType!="Default Role"?this.vaservices.GetDxurlBasedOnDeployment(deployid).replace(/(^\w+:|^)\/\//, ''):'vitaladmin@vitalaxis.com';
      this.createActivityInput.createdByApplication = 'Admin';
      this.createActivityInput.customerId = entityType!="Default Role"?sessionStorage.getItem('org_id'):sessionStorage.getItem('defaultOrgId');
      this.createActivityInput.customerName = entityType!="Default Role"?sessionStorage.getItem('Org_Name'):'';
      this.createActivityInput.locationId = entityType!="Default Role"?sessionStorage.getItem('Locationid'):'';
      this.createActivityInput.locationName = entityType!="Default Role"?sessionStorage.getItem('locationName'):'';
      this.createActivityInput.userName =this.adminLoggedInUser;
      this.createActivityInput.userId = this.adminLoggedInUserEmail;
      }else{
        this.labAdminSessionService.getLabAdminSession.subscribe(labAdminSessionData => this.labAdminSessionData = labAdminSessionData);
        this.createActivityInput.productId = atob(sessionStorage.getItem("domainUrl"));
        this.createActivityInput.createdByApplication = 'Lab Admin';
        this.createActivityInput.customerId = this.labAdminSessionData?.userDetails?.organizationId;
        this.createActivityInput.customerName = this.labAdminSessionData?.userDetails?.orgName;
        this.createActivityInput.locationId = '';
        this.createActivityInput.locationName = '';
        this.createActivityInput.userName = this.labdaminService?.labadminLoggedinUser;
        this.createActivityInput.userId = this.labdaminService?.labadminLoggedinUserEmail;
      }
      this.createActivityInput.product = 'VitalDx';
      this.createActivityInput.entityType = entityType;
      this.createActivityInput.actionType = 'audit';
      this.createActivityInput.userName = checkLaunchUrl.toString().toLocaleLowerCase() == "home" ? this.adminLoggedInUser:this.labdaminService.labadminLoggedinUser;
      this.createActivityInput.userId = checkLaunchUrl.toString().toLocaleLowerCase() == "home" ? this.adminLoggedInUserEmail: this.labdaminService.labadminLoggedinUserEmail;
      this.createActivityInput.isInternal = internalUserRegex.test(localStorage.getItem('UserEmail').toLowerCase());
      this.createActivityInput.transactionId = transactionId;
      this.createActivityInput.ticketId = 'Tic' + this.datePipe.transform(date, 'dd-MM-yyyyHH:mm a');
      this.createActivityInput.ticketInfo = actionType + " " + entityType;

      let extendedAttributes = {};
      let attachments = {};
      let changedAttrValues = {}
      let i = 1;
      if (actionType == 'Edit') {
        this.createActivityInput.entityId = entityid;
        for (let [key, value] of Object.entries(this.compare(newObj, oldobject))) {
          changedAttrValues['oldValue'] = (this.oldJsonData[key] != null) ? this.oldJsonData[key].toString() : "";
          changedAttrValues['newValue'] = (value != null) ? value.toString() : "";
          extendedAttributes[key] = changedAttrValues;
          changedAttrValues = {};
          i++;
        }
        attachments = null;
        this.createActivityOperations.info = activityInfo ? activityInfo :this.getActivityInfo(actionType, entityType, changedValue);
        this.createActivityOperations.extendedAttributes = this.displayColumns(extendedAttributes, auditableColumns);

      } else if (actionType == 'Upload' || actionType == 'Copy' || actionType == 'Migrate' || actionType == 'Bulk Update' || actionType == 'Associate' || actionType == 'De-Associate') {
        this.createActivityInput.entityId = entityid;
        attachments = uploadedFile;
        this.createActivityOperations.info = activityInfo ? activityInfo :this.getActivityInfo(actionType, actionType == 'Upload' ? 'a file' : entityType, changedValue);
        this.createActivityOperations.extendedAttributes = {};
      } else {
        this.createActivityInput.entityId = entityid;
        let activityObject = actionType != 'Delete' ? newObj : oldobject
        for (let [key, value] of Object.entries(activityObject)) {
          changedAttrValues['oldValue'] = actionType != 'Delete' ? "" : (value == null) ? "" : value.toString();
          changedAttrValues['newValue'] = actionType != 'Delete' ? (value == null) ? "" : value.toString() : "";
          extendedAttributes[key] = changedAttrValues;
          changedAttrValues = {};
          i++;
        }
        attachments = null;
        this.createActivityOperations.info = activityInfo ? activityInfo :this.getActivityInfo(actionType, entityType, changedValue);
        this.createActivityOperations.extendedAttributes = this.displayColumns(extendedAttributes, auditableColumns);
      }
      if(jsonData)
        {
          this.createActivityOperations.extendedAttributes = jsonData;
        }
      this.createActivityOperations.attachments = (attachments == null ? null : [attachments]);
      this.createActivityInput.operation = this.createActivityOperations;
      this.createActivityInput.context = entityTypeContext.context;
      if (Object.keys(this.createActivityOperations.extendedAttributes).length != 0 || attachments != null) {
        this.vaservices.CreateActivity(this.createActivityInput)
          .subscribe((res) => { });
      }
    } catch (error) {
      console.error("Error occured while creating activity");
    }
  }

  getExcelFileName(URL, id) {
    return URL.toString() + (id ? '_' + id.toString() : '') + '.xlsx';
  }

  ExportToExcel(flex, filename?) {
    filename = filename ? filename : 'Download.xlsx';
    const view = flex.collectionView;
    let oldPgSize = view.pageSize,
      oldPgIndex = view.pageIndex;
    flex.beginUpdate();
    view.pageSize = 0;
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeCellStyles: false,
        includeColumnHeaders: true
      },
      filename,
      saved => {
        view.pageSize = oldPgSize;
        view.moveToPage(oldPgIndex);
        flex.endUpdate();
      },
      null
    );
  }

  //#region Convert ObjKeys to lowercase
  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        newobj[key.replace(/\s/g, "").toLowerCase()] = inputArr[i][key]
      }
      array.push(newobj)
    }
    return array;
  }
  //#endregion

  ngOnChanges(changes: SimpleChanges, dataColumn: string) {
    let columnChanges: boolean = false;
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case dataColumn: columnChanges = true;
            break;
        }
      }
    }
    return columnChanges;
  }

  getDefaultDeployment() {
    let defaultdeployment = localStorage.getItem('defaultdeployment');
    if (defaultdeployment === undefined || defaultdeployment === null || defaultdeployment === '' || defaultdeployment==='Not Specified') {
      return "ALL";
    } else {
      return defaultdeployment.toUpperCase();
    }
  }

  selectedDefaultDeployment() {
    let favdefaultdeployment = localStorage.getItem('defaultdeployment');
    let selectedDefaultDeployment = this.dataService.selectedDefaultDeployment;
    if (favdefaultdeployment !== undefined || favdefaultdeployment !== null || favdefaultdeployment !== '' &&
      selectedDefaultDeployment !== undefined || selectedDefaultDeployment !== null || selectedDefaultDeployment !== '') {
      if (selectedDefaultDeployment == favdefaultdeployment || selectedDefaultDeployment === 'ALL') {
        return favdefaultdeployment;
      } else {
        return selectedDefaultDeployment;
      }
    } else {
      return "Deployment"
    }
  }

  getAccountType(value) {
    if (value.Context.toLowerCase() != 'users' && value.Context.toLowerCase() != 'userid' && value.Context.toLowerCase() != 'pathologist' && value.Context.toLowerCase() != 'physician') {
      let tempcontext = value.DisplayField?.context.toLowerCase();
      if (tempcontext != 'epg') {
        let contxt = tempcontext == "laboratory" || tempcontext == 'send in' || tempcontext == 'send out' ? 'Lab' : 'facility';
        let obj = {
          contextId: value.DisplayField.accountid,
          context: contxt,
          contexttype: 'account'
        }
        let dbname = value.DisplayField.deploymentkey;
        this.vaservices.getAccountType(obj, dbname).subscribe(result => {
          if (result.length > 0) {
            this.isLiveAccount = result[0].isLive ? true : false;
          }
          else {
            this.isLiveAccount = false;
          }
        }, error => {
          console.error(error)
        })
      }
      else {
        this.isLiveAccount = false;
      }
    }
  }
  async openMultisheetModal(sheets) {
    let dialogRef = this.dialog.open(MultisheetPopupComponent, {
      disableClose: true,
      width: '500px',
      data: {
        header: 'Choose a sheet to be uploaded',
        continue: sheets,
        cancel: 'Cancel',
      },
    });
    return await dialogRef.afterClosed().toPromise().then((result) => {
      if (result) {
        return result;
      }
    });
  }

  getActivityInfo(actionType: any, entityType: any, changedValue: any) {
    this.locationname= sessionStorage.getItem('locationName');
    let activityTypeList = {
      'Edit': 'Modified ',
      'Delete': 'Deleted ',
      'Upload': 'Uploaded ',
      'Create': 'Created new ',
      'Create and Associate':`Created new and Associated to the location "${this.locationname}" `,
      'Association': `Associated to the location "${this.locationname}" `,
      'Add': 'Added ',
      'Copy': 'Copied ',
      'Migrate':'Migrated ',
      'Export': 'Exported ',
      'Bulk Update': 'Bulk Update ',
      'Associate': 'Associated Organization of ',
      'De-Associate': 'De-Associated Organization of '
    }
    if(entityType=='Site Order')
    {
      return activityTypeList[actionType] + 'site order' + ' "' + changedValue + '" ';
    }
    else if(actionType=='Migrate'){
      return activityTypeList[actionType] + entityType + ' "' + changedValue + '" '+'to Group Level';
    }else{
      return activityTypeList[actionType] + entityType + ' "' + changedValue + '" ';
    }

  }

  generateGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  async auditDetails(entityIdKey, entityNameKey, oldObj: any[], newObjArray: any[], actionType, templateData, auditableColumns,info?) {
    let transactionId = this.generateGuid();
    try {
      if (actionType == 'Edit') {
        this.createActivityObject(oldObj[0][entityIdKey], oldObj[0][entityNameKey], templateData.menuURL, actionType, newObjArray[0], oldObj[0], transactionId, auditableColumns);
      } else if (actionType == 'Create' || actionType == 'Delete' || actionType == 'Create and Associate' || actionType == 'Association') {
        this.createActivityObject(oldObj[0][entityIdKey], (actionType == 'Create' || actionType == 'Create and Associate' || actionType == 'Association') ? newObjArray[0][entityNameKey] : oldObj[0][entityNameKey], templateData.menuURL, actionType, newObjArray[0], oldObj[0], transactionId, auditableColumns);
      } else if (actionType == 'Upload' || 'Copy' || 'Migrate' || 'Bulk Update' || 'Associate' || 'De-Associate') {
        this.dataToExcelAsBinary(newObjArray, templateData.menuURL, actionType, transactionId, auditableColumns,info,entityIdKey);

      }
    } catch (error) {
      console.error("Error occured while creating audit",error, 'Close');
    }
  }
  //sorts array of objects alphabetically using property name
  sortByName(arr, key) {
    return arr.sort((a, b) => {
      const nameA = a[key].toUpperCase(); // ignore upper and lowercase
      const nameB = b[key].toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    })
  }

  async dataToExcelAsBinary(dataArray: any, menuUrl, actionType, transactionId, auditableColumns,info?,entityId?): Promise<any> {
    var wb = XLSX.utils.book_new();
    var ws;
    let date = new Date();
    let filename = menuUrl.toString() + '_' + this.datePipe.transform(date, 'dd-MM-yyyyHH:mm a') + '.xlsx';
    if (!Array.isArray(dataArray)) {
      for (let key of Object.keys(dataArray)) {
        ws = XLSX.utils.json_to_sheet(
          dataArray[key]
        );
        XLSX.utils.book_append_sheet(wb, ws, key);
      }
    }
    else {
      ws = XLSX.utils.json_to_sheet(
        dataArray
      );
      XLSX.utils.book_append_sheet(wb, ws, menuUrl);
    }

    let fileData = XLSX.write(wb, { type: 'base64', bookType: 'xlsx' });
    let attachments = [{
      "id": "",
      "name": filename,
      "source": fileData
    }]
    this.vaservices.uploadFile(attachments)
      .subscribe((res) => {
        if (!res.error) {
          // return res.content;
          res.content.forEach(obj => {
            this.createActivityObject(entityId ? entityId:'', obj.name, menuUrl, actionType, {}, {}, transactionId, auditableColumns, obj,'',info);
          });
        } else {
          console.error("Error occured while uploading file");
        }
      });
  }

  displayColumns(changedValues: any, displayColumns: any) {
    return Object.keys(displayColumns).reduce((orderedValues, displayKey) => {
      const matchingKey = Object.keys(changedValues).find(
        key => key.toLowerCase() === displayKey.toLowerCase()
      );
      
      if (matchingKey) {
        orderedValues[displayColumns[displayKey]] = changedValues[matchingKey];
      }
      
      return orderedValues;
    }, {});
  }

  createContext(menuData, entityId, entityType) {
    let activityContext = []
    let context = {};
    for (let [key, value] of Object.entries(menuData)) {
      if(key == 'casetype'){
        context['key'] = key;
        context['value'] = value;
        activityContext = [{ 'key': 'parentMenu', 'value': key }]
      }
    }
    activityContext.push(context)
    this.activityService.setActivitySession({ 'entityId': entityId, 'entityType': entityType, 'context': activityContext })
  }


  getStatus(status: any) {
    if (status == true || status == "true"
    || status == 1 ||
      status == 'yes') {
      return 'Active';
    } else {
      return 'Inactive';
    }
  }
  getDefault(isdefault: any) {
    if (isdefault ||
      isdefault == 1) {
      return 'Yes';
    } else {
      return 'No';
    }
  }

  async getAllOrganization() {
    let query = this.vaservices.GetQuery('getallorganization');
    this.ngxService.start();
    let orgArr = [];
    let queryVariable =null
    let queryResult = this.GetCardRequest(queryVariable, query);
    try {
      let res = await this.vaservices.GetData(queryResult).toPromise();
      let result = res.data.organizationData;
      orgArr = result;
    }
    catch(e) {
      console.error(e)
    }
    return orgArr;
  }

  async getCommmonTemplateData(menuObj, userSessionDetails, dataKey = 'submenuData',donotQuery = false) {
   let keyword = menuObj.Keyword;
   if(donotQuery) {
          this.templateData.template = menuObj.Template;
          this.templateData.menuURL = menuObj.URL || menuObj.Htext;
          this.templateData.headerText = '';
          this.templateData.Keyword = keyword;
          this.labdaminService.templateData = this.templateData;
          //  this.templateData.labels = labels;
          //  this.templateData.GroupData = secondarKeys;
          //  this.templateData.secondarykeys = secondarKeys;
        return this.templateData;

   }
   else{
    let Query = this.vaservices.mainQueryList.filter(
      (x) => x.Keyword == keyword
    )[0]?.Query;
    if(!Query) return;
    // let queryVariable = { orgid: userSessionDetails.userAccountIDs[0].organizationId.toString() };
    let queryVariable
    if(keyword.match(/getallrejectioncomments/i)){
      queryVariable = { organizationid : this.labdaminService.organizationId };
    }
    else if(keyword.match(/correlationtemplate/i)) {
      if(menuObj.URL === 'Tube Type Templates') {
        queryVariable = { orgid: this.labdaminService.organizationId, attributetype: "TubeType" };
      } else {
        queryVariable = { orgid: this.labdaminService.organizationId, attributetype: "Correlation" };
      }
    }  else if(menuObj.accountLevel) {
      queryVariable = userSessionDetails.queryVariables;
    }else {
      queryVariable = { orgid: this.labdaminService.organizationId };

    }
    let queryResult = this.GetCardRequest(queryVariable, Query);
    await this.vaservices
      .GetData(queryResult,this.labdaminService.deploymentKey)
      .toPromise()
      .then(
        data => {
          if (!data.errors) {
            if (data) {
              let index = -1
              let labels
              if(data.data?.[dataKey][0]){
                labels = Object.keys(data.data?.[dataKey][0]);
                index = labels.indexOf("", 0);
                if (index > -1) {
                  labels.splice(index, 1);
                }
              }
                //createing a object for secondarKeys
                let secondarKeys = {
                  "OrganizationId":Number(this.labdaminService.organizationId)
                }
                //Setting all the templateData where that will pass to the after calling for the lab admin
                if(menuObj.Template == 'Case Comments'){
                  this.templateData.submenuData = data.data.submenuData;
                  this.templateData.submenuData1 = data.data.submenuData1;
                  this.templateData.submenuData2 = data.data.submenuData2;
                }
                else if(menuObj.Template == 'AdequacyCodes'){
                  this.templateData.submenuData = data.data.submenuData;
                  this.templateData.submenuData.submenuData1 = data.data.submenuData1;
                  this.templateData.submenuData1 = data.data.submenuData1;
                }
                else{
                  this.templateData[dataKey] = data.data?.[dataKey];
                }
                 this.templateData.template = menuObj.Template;
                 this.templateData.menuURL = menuObj.URL || menuObj.Htext;
                 this.templateData.headerText ='';
                 this.templateData.labels = labels;
                 this.templateData.GroupData = secondarKeys;
                 this.templateData.Keyword = keyword;
                 this.templateData.secondarykeys = secondarKeys;
                 this.labdaminService.templateData=this.templateData;
              }

          }
        },
        error => {
          console.error(error);
        }
      );
    }
  }


  generateComparisonJson(oldUser: any, newUser: any, mappings: any): any {
    const result = {};
    if (!oldUser) {
      oldUser = {};
      Object.keys(newUser).forEach(key => {
        if (!(key in oldUser)) {
            oldUser[key] = '';
        }
      });
      }
    Object.keys(mappings).forEach(section => {
      const sectionObj = {};
      let hasChangesInSection = false;
        mappings[section].forEach(mapping => {
            Object.keys(mapping).forEach(key => {
                if (Array.isArray(oldUser[key]) && Array.isArray(newUser[key])) {
                    const maxLength = Math.max(oldUser[key].length, newUser[key].length);
                    for (let i = 0; i < maxLength; i++) {
                        const oldValue = oldUser[key][i] || {};
                        const newValue = newUser[key][i] || {};
                        const obj = {};
                        let hasChanges = false;
                        Object.keys(mapping[key]).forEach(subKey => {
                            const mappedKey = mapping[key][subKey];
                            const oldVal = oldValue[subKey] || '';
                            const newVal = newValue[subKey] || '';
                            if (oldVal !== newVal) {
                              obj[mappedKey] = {
                                  oldValue: oldVal,
                                  newValue: newVal
                              };
                              hasChanges = true;
                             }
                        });
                        if (hasChanges) {
                          sectionObj[i.toString()] = [obj];
                          hasChangesInSection = true;
                           }
                    }
                } else {
                  const oldValue = oldUser[key] || '';
                  const newValue = newUser[key] || '';
                  const mappedKey = mapping[key];
                  if (oldValue !== newValue) {
                      sectionObj[mappedKey] = { oldValue, newValue };
                      hasChangesInSection = true;
                  }
                }
            });
            if (hasChangesInSection) {
              result[section] = [sectionObj];
          }
        });
    });
    console.log(result);
    return result;
}
  setTitle(title: any, url: any) {
    document.title = title;
    this.setFavicon(url);
  }

  setFavicon(url: any) {
    const favicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
    favicon.href = url;
  }

  trimFieldValue(form: FormGroup, controlName: any, val:string) {
    clearTimeout(this.debouncerTime);
    this.debouncerTime = setTimeout(() => {
      form.patchValue({ [controlName]: val.trim() });
    }, 1000)
  }
  // Replace ';' and '/' with spaces
  removeSpecialChars(str) {
    if (/[;/]/.test(str)) {
      return str.replace(/[;/]/g, ' ');
    } else {
      return str;
    }
  }

  // getting auditable column names from DB
  getAuditableDetails(location: any, deploymentKey: string): Observable<any> {
    return this.vaservices.getDisplayColumns({ "TableName": location, "DeploymentKey": deploymentKey })
        .pipe(
            map(res => JSON.parse(res.content.JsonData)),
            catchError(error => {
                console.error(error);
                return of('');  // Return an empty observable or handle the error as needed
            })
        );
}

}
