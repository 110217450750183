export class ExtAttributeConstants {
    public static readonly AllowLabAdminToCreateFacilityRoles = "AllowLabAdminToCreateFacilityRoles"
    public static readonly EnablePathologistManagement = "EnablePathologistManagement"
    public static readonly EnableNPIvalidation = 'EnableNPIvalidation'
    public static readonly EnableUserAssociation = 'Enable.UserAssociation'
    public static readonly ManagePathologistPrivilegesForLabAdmin = 'ManagePathologistPrivilegesForLabAdmin'
    public static readonly DisplayNameFormatForLA = 'DisplayNameFormatForLA'
    public static readonly GetAssocOrderingOrganization = 'GetAssocOrderingOrganization'
    public static readonly Default_ShowMyCaseOnlyAtLabOrgLevel = 'Default.ShowMyCaseOnlyAtLabOrgLevel'
    public static readonly UsersType ='users'
    public static readonly EnableTimeZoneConversion = 'EnableTimeZoneConversion'
    public static readonly IsPathologistLicensureEnabled = 'IsPathologistLicensureEnabled'
    public static readonly EnableInstructions = 'EnableInstructions'
    public static readonly AllowLabAdminToViewReports = 'AllowLabAdminToViewReports'
    public static readonly EnableGrossingRoles = 'EnableGrossingRoles'
    public static readonly EnablePatientPortal = "EnablePatientPortal";
    public static readonly PatientPortalEnableForAllFacilities = "PatientPortal.EnableForAllFacilities";
    public static readonly EnableMDMFlag = "Enable.ProviderMDM";
    public static readonly TemplateOrderingFacilityOrganizations = "Template.OrderingFacilityOrganizations";
}
export class Scope {
    public static readonly Scope_1 ='1'
    public static readonly Scope_2 ='2'
    public static readonly Scope_3 ='3'
}