import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {  MatDialog } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { VitalHttpServices } from './VitalHttpServices';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { CookieService } from 'ngx-cookie-service';

// declare var Pace;
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  isLoading = false;
  
  constructor(private dialog: MatDialog, private _snackbar: MatSnackBar, private vaServices: VitalHttpServices, private ngxService: NgxUiLoaderService,private labAdminService:LabadminService, public cookieService: CookieService) {};

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.isLoading = true;
    let idToken; 
    let deployment;
    // for vb only ---
    let vdUser;
    // ---
    // Pace.start();
    deployment = sessionStorage.getItem('deploymentKey');
    vdUser = localStorage.getItem("vbCredential");
    let userlogged = localStorage.getItem("UserEmail");
    if (userlogged && userlogged != "") {
      // if (idToken) {
      let cloned;
      const urlWithParams = req.urlWithParams;
      const dbName = req.params.get('Conn');
      if (vdUser !== null) {
        let vbdata = JSON.parse(vdUser)
        if(urlWithParams.indexOf('Conn') > -1 && dbName) {  //If deployment key sent with the request
          cloned = req.clone({
            params:req.params.delete('Conn'),
            headers: req.headers.set("Authorization", "Bearer " + idToken).set("Conn", dbName).set("x-va-userid", vbdata.userid).set("x-va-validate", vbdata.validate).set("x-va-token", vbdata.token)
          });
        }
        else {
          cloned = req.clone({
            headers: req.headers.set("Authorization", "Bearer " + idToken).set("Conn", deployment).set("x-va-userid", vbdata.userid).set("x-va-validate", vbdata.validate).set("x-va-token", vbdata.token)
          });
        }        
      }
      else {
        if (urlWithParams.indexOf('Conn') > -1 && dbName) {  //If deployment key sent with the request
          cloned = req.clone({
            params: req.params.delete('Conn'),
            headers: req.headers.set("Authorization", "Bearer " + idToken).set("Conn", dbName)
          });
        }
        else {
          if(req.url.startsWith(this.vaServices.baseUrl2)) {
            cloned = req.clone({
              headers: req.headers.set("Authorization", "Bearer " + idToken).set("Conn", deployment)
            });
          }
          else if(req.url.startsWith(this.vaServices.reporturl)) {
            cloned = req.clone({
              headers: req.headers.set("Conn", deployment)
            });
          }
          else {
            cloned = req.clone();
          }
        }

        if(req.url.startsWith(environment.baseUrl2))
          cloned = cloned.clone({withCredentials: true});
      }
      return next.handle(cloned).pipe(
        finalize(() => {
          // Pace.stop();
        }),
        catchError((error: HttpErrorResponse) => {
          // Pace.stop();
          if(error.status !== 401)
            return throwError(error);
          else {            
            //session expired
            console.error('session expired');
            this.ngxService.stopAll();
            this._snackbar.open("Your session has expired. Please log in again.", "Failed");
            if(req.url.indexOf('GetEntityDetails') !== -1 
            || req.url.indexOf('GetEntityResults') !== -1
            || req.url.indexOf('GetAllAngularURLList') !== -1) {
              localStorage.setItem("ReloadPage", "Yes");  //to load parent from popup window
            }
           if(this.vaServices.SsoType!='SoloLaunch')
            {
              this.sendMssageToStopParentLoader("Logout");
            }
            else{
              if(this.labAdminService.sourceApp.toLowerCase()!='vitaldx' || !this.labAdminService)
              {
                this.vaServices.logout();
              }
              else{
                // Retrieve the domain URL from sessionStorage
                let domainUrl = atob(sessionStorage.getItem("domainUrl"));
                // If not found in sessionStorage, try cookies
                if (!domainUrl) {
                  const cookieValue = this.cookieService.get('domainUrl');
                  if (cookieValue) {
                    domainUrl = atob(cookieValue); // Decode base64 encoded value
                  }
                  else {
                    domainUrl = this.labAdminService.domainUrl
                  }
                }
                // Example URL
                let url = domainUrl;
                url.replace(/back/g, 'forward');
                window.location.replace(environment.fusionauth + 'logout?client_id=' + environment.clientid + '&post_logout_redirect_uri=' + domainUrl + "/VALogOut.aspx?sourceapplication=newlabadmin");
                return;
              }
            }
        }          
        }));
    }
    else {
      if (atob(sessionStorage.getItem("page")) != "login") {
        // return new this.vitalHttpServices.logout();
        if(this.labAdminService.sourceApp.toLowerCase()!='vitaldx' || !this.labAdminService)
        {
          window.location.href = environment.client;
          this.sendMssageToStopParentLoader("Logout");
        }
      }
      else {
        return next.handle(req).pipe(finalize(() => {
          // Pace.stop();
        }),
          catchError((error: HttpErrorResponse) => {
            // Pace.stop();
            return throwError(error);
          }));
      }
    }
  }
  sendMssageToStopParentLoader(inputmsg) {
    let message = inputmsg;
    parent.postMessage(message, '*');
  }
}
