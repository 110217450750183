import { Component, OnInit } from '@angular/core';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../../core/services/commonservices';
import { VitalHttpServices } from '../../core/services/VitalHttpServices';
import { VitalAdminTab } from '../commoncomponents/tab';
import { MainCardModel } from '../DbModel/MainCardModel';
import { VitalAdminTabService } from '../tab.service';

@Component({
  selector: 'app-initialsearchpage',
  templateUrl: './initialsearchpage.component.html',
  styleUrls: ['./initialsearchpage.component.css']
})
export class InitialsearchpageComponent implements OnInit {
  mainCardModel: MainCardModel;

  constructor(private tabService: VitalAdminTabService, public commonService: CommonService, public vitalHttpServices: VitalHttpServices,
    private ngxService: NgxUiLoaderService, private _snackbar: MatSnackBar) {
    this.mainCardModel = new MainCardModel(commonService, vitalHttpServices, _snackbar);
  }

  ngOnInit(): void {
  }
  SetTabActive(tabdetails: any) {
    let menubutton: any = {};
    menubutton.active = true;
    menubutton.accountid = tabdetails.accountid;
    menubutton.GroupCode = tabdetails.GroupCode;
    switch (tabdetails.cardtype) {
      case 'facility': {
        menubutton.text = 'Facilities ';
        menubutton.cardtype = 'facility';
      }
        break;
      case 'lab': {
        menubutton.text = 'Labs  ';
        menubutton.cardtype = 'lab';

      }
        break;
      case 'search': {
        menubutton.text = 'Search Results ';
        menubutton.cardtype = 'search';
      }
        break;

      case 'covid': {
        menubutton.text = tabdetails.accountid.toString() + " Covid";
        menubutton.cardtype = 'covid';
      }
        break;
    }
    this.addNewOrSelectTab(menubutton);
  }

  addNewOrSelectTab(menubutton: any) {
    let selectedTab: number = -1;
    let array=[];
    // let labTab: number = -1;
    this.ngxService.start();
    // sessionStorage.setItem( 'deploymentKey', 'configdb');
    this.mainCardModel.GetData(menubutton.accountid, menubutton.cardtype, menubutton.cardtype, 0).subscribe(result => {
      if (!result.errors) {
        // let newQuery = this.vitalHttpServices.mainQueryList.filter(x => x.Keyword == "getVitalAdminMenus")[0].Query;
        // let newQueryVariable = {
        //   CardName: menubutton.cardtype
        // };
        // let newQueryResult = this.commonService.GetCardRequest(newQueryVariable, newQuery)
        // sessionStorage.setItem('deploymentKey', 'configdb');
        // this.vitalHttpServices.GetData(newQueryResult).subscribe((newResData) => {
        // sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
        this.ngxService.stop();
        if (result.data.Card != undefined && result.data.Card != null && result.data.Card.length > 0) {
          let mainCard = result.data.Card[0];
          if (mainCard) {
            // //let obj2 = JSON.parse(result.data.VitalAdminMenus_List[0].JSONMenu);
            // let obj2 = JSON.parse(newResData.data.submenuData[0].JSONMenu);
            // // let menuJson = obj2.MenuItems;
            let menuJson = menubutton.rolemenu
            let labels = Object.keys(mainCard);
            let secondarykeys: any;
            const index = labels.indexOf('id', 0);
            if (index > -1) {
              labels.splice(index, 1);
            }
            // if(menubutton.cardtype.toLowerCase() == 'users' || menubutton.cardtype.toLowerCase() == 'physician' || menubutton.cardtype.toLowerCase() == 'pathologist' ||  menubutton.keyword == 'casetype'){
              let OrgIndex: number = -1;
              OrgIndex = labels.indexOf('Org_ID', 0);
              if(OrgIndex > -1){
                secondarykeys = { "OrganizationId": mainCard[labels[OrgIndex]], "casetype": menubutton.cardtype }
              }
          //     }else{
          //   // OrgIndex = labels.indexOf('OrganizationId', 0);.split('(')[1].split(')')[0] mainCard[labels[OrgIndex]]
          //   //  OrgIndex = Number(mainCard.Group_Name.toString().split('(')[1].split(')')[0])
          //   // let index1 = mainCard.Group_Name && mainCard.Group_Name.toString().lastIndexOf('(');
          // //  let OrgIndex1 = Number(mainCard.Group_Name.toString().slice(index1 + 1).split(')')[0]);
          // //  let OrgIndex1 = mainCard.Group_Name && mainCard.Group_Name.toString().slice(index1 + 1).split(')')[0];
          //  if (OrgIndex1 > -1) {
          //     secondarykeys = { "OrganizationId": OrgIndex1, "casetype": menubutton.cardtype }
          //   }
          // }

            let tabData: any;
            tabData = {
              notinitial: true,
              mainCard: mainCard,
              menuJson: menuJson,
              labels: labels,
              accountid: menubutton.accountid,
              cardtype: menubutton.cardtype,
              secondarykeys: secondarykeys,
              rolepermission: menubutton.rolepermission,
              rolemenu: menubutton.rolemenu,
              roleMenuPermissions: menubutton.roleMenuPermissions,
              GroupCode:menubutton.GroupCode
            }
            selectedTab = this.tabService.tabs.findIndex(tab => tab.cardType.trim().toLocaleLowerCase() === menubutton.cardtype.trim().toLocaleLowerCase());

            if (selectedTab === -1) {
              this.tabService.addTab(
                new VitalAdminTab(menubutton.text, tabData, menubutton.cardtype, menubutton.accountid, menubutton.Removable, menubutton.GroupCode));
            }
            else {
              this.tabService.tabs[selectedTab].title = menubutton.text;
              this.tabService.tabs[selectedTab].tabData = tabData;
              this.tabService.tabs[selectedTab].cardType = menubutton.cardtype;
              this.tabService.tabs[selectedTab].accountid = menubutton.accountid;
              this.tabService.tabs[selectedTab].Removable = menubutton.Removable;
              this.tabService.tabs[selectedTab].GroupCode = menubutton.GroupCode;
              this.tabService.makeTabActiveByCardType(menubutton.cardtype);
            }
          }
        }
        else {
          this.ngxService.stopAll();
          this._snackbar.open("No data found", "Success");
        }
        // });
      } else {
        this.ngxService.stop();
        this._snackbar.open("An error occurred while processing your request", "Failed");
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", "Failed");
      console.error(error);
    });
  }
}
