<div *ngIf="templateData && !isEdit" class="col-lg-12 p-0 m-md-1 m-lg-0 Workgroups">
    <app-templates-listing class="p-0" [gridHeader]="listedGridHeader" [gridData]="gridData" [context]="'Workgroups'"
        [hideEdit]="editBtn" [hideCreate]="createBtn" [hideExport]="exportBtn" [hideUpload]="true"
        (saveOrEditEventFromListingTriggered)="recieveObjFromListing($event)" [templateData]="templateData"
        [subMenuCardModel]="SubMenuCardModel" [saveOrEditCompleted]="saveOrEditCompleted" [showHeaderModule]="true"
        [isAdmin3]="sourceApp =='VitalDx'? false : true" (emitFilters)="emitFilters($event)"
        (editTemplateClicked)="editTemplateClicked($event)">
    </app-templates-listing>
</div>

<div *ngIf="isEdit && rowData" class="col-md-12">
    <div class="d-flex mt-2">
        <div class="col-sm-6 modal-header admin-model-header mb-2 p-0 listingModalHeader">
            <h3 class="modal-title w-100 admin-model-header-txt main-title">
                <strong class="header-View">Edit Workgroups</strong>
            </h3>
        </div>

        <form class="col-sm-6 mt-2 mb-2 ml-2 p-0">
            <div class="button-wrap">
                <a class="reset-btn mr-3 mt-1" (click)="resetDynamicForm()" *ngIf="isFormChanged" >Reset</a>
                <button class="mr-3 align-center rtn-brn buttonHover" (click)="returnWorkgroup()">Return</button>
                <button class="align-center primaryButtonColor d-flex justify-content-space-evenly"
                    (click)="savedData()"> Save
                </button>
            </div>
        </form>
    </div>

    <form #myForm="ngForm" autocomplete="off" class="row pl-0 pr-0 d-flex form-wrap-view">
        <div class="col-sm-12 pt-2 pb-2"
            style="border-bottom: 1px solid #d7d7d7; padding: 5px 20px;display: flex; justify-content: flex-end;">
            <div class="badgeAlignment col-md-12 button-wrap" [ngClass]>
                <span class="mr-2"> Status: </span>
                <label class="ct-tag-toggle mb-0">
                    <input type="checkbox" [disabled]="!isEdit" [(ngModel)]="rowData.isActive" name="isActive"
                        [value]="rowData.isActive == ('Inactive' || 'No' || false || 0 || '') ? 0 : 1">
                    <span class="slider"></span>
                </label>
            </div>
        </div>

        <div class="col-sm-12 d-flex mt-4 wrap-height">
            <div class="col-md-3 viewInputContainer">
                <mat-form-field class="col-sm-12 p-0">
                    <mat-label>
                        <strong>Department Name</strong>
                    </mat-label>
                    <input (input)="isFormChanged = true" [(ngModel)]="rowData.departmentName" [disabled]="isEdit" name="{{ rowData?.departmentName }}"
                        trim matInput [ngModelOptions]="{standalone: true}" [required]="true" />
                </mat-form-field>
            </div>

            <div class="col-md-3">
                <mat-label class="col-sm-12 ng-multiselect-alignment">
                    <strong>Role<span class="text-danger">* </span></strong>
                </mat-label>
                <ng-multiselect-dropdown [placeholder]="'Select'" #matRoleSelectOption [(ngModel)]="rowData.role"
                    [settings]="dropdownRoleSettings" [ngModelOptions]="{standalone: true}" [data]="roleDropdownOptions"
                    (onSelect)="onRoleChange($event)" (onDeSelect)="onRoleChange($event)"
                    (onSelectAll)="onRoleChange($event, true)" (onDeSelectAll)="onRoleDeslectAll()">
                </ng-multiselect-dropdown>
            </div>

            <div class="col-md-3">
                <mat-label class="col-sm-12 ng-multiselect-alignment">
                    <strong>Tab Name<span class="text-danger">* </span></strong>
                </mat-label>
                <ng-multiselect-dropdown [placeholder]="'Select'" #matTabSelectOption
                    [(ngModel)]="rowData.tabDisplayName" [settings]="dropdownTabSettings"
                    [ngModelOptions]="{standalone: true}" [data]="tabDisplayNameDropdownOptions"
                    (onSelect)="onTabChange($event)" (onDeSelect)="onTabChange($event)"
                    (onSelectAll)="onTabChange($event, true)" (onDeSelectAll)="onTabDeslectAll()">
                </ng-multiselect-dropdown>
            </div>

            <div class="col-md-3">
                <mat-label class="col-sm-12 ng-multiselect-alignment">
                    <strong>Worklist<span class="text-danger">* </span></strong>
                </mat-label>
                <ng-multiselect-dropdown [placeholder]="'Select'" #matDisplaySelectOption
                    [(ngModel)]="rowData.customWorklist" [settings]="dropdownDisplaySettings"
                    [ngModelOptions]="{standalone: true}" [data]="customWorkListDropdownOptions"
                    (onSelectAll)="onWorklistChange($event)" (onDeSelectAll)="onWorklistDeslectAll()">
                </ng-multiselect-dropdown>
            </div>
        </div>
    </form>
</div>