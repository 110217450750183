import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class UserSession {
    remotePosts = new BehaviorSubject([]);
    _router;
   
    public baseUrl: string = environment.serverURL;
    public org_id = "";
    public user_name = "";
    public password = "";
    public user_id = 0;
    public email_id = "";
    public user_role = "";
    public user_fname = "";
    public user_lname = "";
    public loginname = "";
    public userProfile = "";
    public token = "";

    public isSSO = false;

    public loggedInUser = "";
    public authtoken = "";
    public userdata = {};
    public timeOut = 0;
    public Refreshtoken = "";
    public query={}

    constructor(public http: HttpClient, router: Router) {
        this._router = router;
    }
    setDefaults() {
        this.org_id = "";
        this.user_id = 0
        this.user_fname = "guest"
        this.user_lname = "";
        this.loginname = "guest";
        this.user_name = "guest";
        this.user_role = "superadmin"
        this.isSSO = false
    }

}
