import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import * as wjCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjGrid from '@grapecity/wijmo.grid';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { OrgTableComponent } from 'src/app/base/common/org-table/org-table.component';
import { DataShareService } from 'src/app/core/services/datashare.service';
//import 'src/assets/css/styles.css';
import * as XLSX from 'xlsx';
import { environment } from '../../../../environments/environment';
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';
import { CommonService } from '../../../core/services/commonservices';
import { InjectorService } from '../../../core/services/Injectorservices';
import { UserSession } from '../../../core/services/user.session';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { BaseGridContainerComponent } from '../../commoncomponents/templateclasses/basegrid';
import { ThemePalette } from '@angular/material/core';
import { ExcelService } from '../../../core/services/excel.service';
import { ActivityTrackerService } from '../../../core/services/activity-tracker.service';
import { CreateActivityTrackerInput, Operations } from '../../../model/activity-tracker';
import { DatePipe } from '@angular/common';
declare var $: any;

export interface Task {
  color: ThemePalette;
}

@Component({
  selector: 'customreport-grid',
  templateUrl: './customreportgrid.component.html',
  styleUrls: ['./customreportgrid.component.scss'],
})

export class CustomReportGridContainerComponent
  extends BaseGridContainerComponent
  implements OnInit, OnChanges {

  gridheader = [
    'Report_scope',
    'Modified_Date',
    'StoredProcedure',
    'Category',
    'Title',
    'Name',
    'ReportId',
  ];

  columns = [
    new ColDef('Report_scope', 'Report Scope'),
    new ColDef('StoredProcedure', 'Stored Procedure Name'),
    new ColDef('Modified_Date', 'Modified Date'),
    new ColDef('Category', 'Category'),
    new ColDef('Title', 'Title'),
    new ColDef('Name', 'Name'),
    new ColDef('ReportId', 'Report ID'),
  ];
  task: Task = {
    color: 'primary',
  };

  @Input()
  public subMenudata: any;
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  labels: any;
  @Input()
  rowData: any;
  columnDefs: any = [];
  gridwidth: number = 0;
  labelFirstCount: number = 0;
  @Input()
  templateData: any;
  @Input()
  templateChangedTime: string;
  cvPaging: CollectionView;
  data: any;
  gridData: CollectionView;
  @ViewChild('select') select: MatSelect;

  public hideExportBtn: boolean = true;
  public hideCreateBtn: boolean = true;
  public hideEditBtn: boolean = true;
  public hideGrid = true;
  public showDescription = false;
  public description;
  public hideCopyToOrgBtn: boolean = false;
  public nameDesc;
  public createdBy;
  public ifUpdate: boolean = false;
  public ifSaved: boolean = false;
  public createdDate;
  public expiryDate;
  public effectiveDate;
  public evaluationCriteriaCode;
  public reportId;
  public title;
  public isActive: boolean = true;
  public isSelect: boolean = false;
  public showrolemandatoryfield: boolean = false;
  public category;
  public storedProc;
  public templateFile;
  public namedrange;
  public version;
  public criteria;
  public ifCleared: boolean = false;
  public criteriaDispName;
  public maxRecords;
  public showMaxRecords;
  public roleMapping;
  public isScheduledReport;
  public isAsyncReport;
  public statsData;
  public schchecked = false;
  public asynchecked = false;
  public OrgLevelDisplay = true;
  public activeMsg: string;
  recipientData = this._fb.group({
    editEmailTo: null,
    editEmailCc: null,
    editEmailBcc: null,
    EmailSubject: '',
    EmailBody: '',
    name: '',
    CreatedDate: ['', Validators.required],
    ModifiedDate: ['', Validators.required],
    recipientProtectFile: ['true', Validators.required],
    recipientPassword: ['', Validators.required],
    makeEditable: true,
    title: '',
    storedProcedure: '',
    templateFile: '',
    Description: '',
    Category: '',
    Version: '',
    criteria: '',
    NamedRange: '',
    description: '',
    approxGenarationTime: '',
    ConnectionName: '',
    MaxDays: '',
    CriteriaDisplayNames: '',
    IsAsynchronousReport: false,
    isScheduledReport: '',
    password: '',
    fileToBeProtected: '',
    isActive: '',
    includeHeader: '',
    ShowMaxRecord: '',
    Password_to_be_Mailed: '',
    Report_Type: '',
    Recepient_Type: '',
    roleChecked: false,
    selectedRoleArr: [],
    item: [],
    isSelect: false
  });

  customReportData = this._fb.group({
    name: '',
    CreatedDate: ['', Validators.required],
    ModifiedDate: ['', Validators.required],
    recipientProtectFile: ['true', Validators.required],
    recipientPassword: ['', Validators.required],
    makeEditable: true,
    title: '',
    Spname: '',
    templateFile: '',
    Description: '',
    Category: '',
    Version: "",
    criteria: '',
    NamedRange: '',
    description: '',
    approxGenarationTime: '',
    ConnectionName: '',
    MaxDays: '',
    CriteriaDisplayNames: '',
    IsAsynchronousReport: false,
    isScheduledReport: '',
    isSelect: false,
    includeHeader: '',
    ShowMaxRecord: '',
    roleChecked: false,
    isActive: '',
    item: []
  });

  columnHeader: any[];
  showPaginationMainGrid: boolean;
  ShowModal: boolean;
  userid = sessionStorage.getItem('Userid');
  public tableGrid = true;
  uniqdata: any;
  custReportName: any;
  custTitle: any;
  custStoredProcedure: any;
  custTemplateFile: any;
  custVersion: any;
  custCategory: any;
  custDescription: any;
  custReportId: any;
  custAsync: string;
  custMaxDays: any;
  custNamedRange: any;
  custCreatedDate: any;
  custmodifiedDate: any;
  myRolesArray: any = [];
  custCriteria: any = [];
  versiondesc: string;
  custName: any;
  custConnectionName: any;
  custCriteriaDisplayName: any;
  custShowMaxRecord: any;
  ReportType: any;
  showCustomReportView: boolean;
  filename: any;
  base64: any;
  base64split: any;
  ShowModalEdit: boolean;
  showCustomRepEdit: boolean;
  showManageSchedulesMail: boolean;
  customerReportGrid: any;
  errorFlag: number;
  selectedRoleArr: [];
  bigArrRole: any = [];
  roleArray: any;
  updatedRoles: any;
  distinctCategory: any;
  addCustomReportModel: boolean = false;
  sheetHeader: any[];
  dropdownSettings: {
    singleSelection: boolean;
    idField: string;
    textField: string;
    enableCheckAll: boolean;
    allowSearchFilter: boolean;
    limitSelection: number;
    clearSearchFilter: boolean;
    maxHeight: number;
    itemsShowLimit: number;
    closeDropDownOnSelection: boolean;
    showSelectedItemsAtTop: boolean;
    defaultOpen: boolean;
  };

  selectedLevel: any = [];
  dkey: string;
  defaultReports: any;
  CreatedBy: any;
  Modifiedby: any;
  UserDetails: any;
  modifierName: any = [{ firstname: '', lastname: '' }];
  createrName: any = [{ firstname: '', lastname: '' }];
  changeToTrue: boolean;
  tempExport: any[];
  approx: any;
  path: any;
  public noData = false;
  subMenuCardModel: SubMenuCardModel;
  tempcre: UntypedFormControl;
  txtvalue: any = ""
  disable: any = false
  textview: any = -1
  finalarray = []
  view: boolean = false;
  CriteriaList: any = [];
  TempCriteriaList: any;
  criteriaSelectedCount: number = 0;
  isuncheck: boolean;
  p4Deployment: boolean = false;
  oldJsonData: {};
  oldobject: { path: any; organizationID: any; reportID: any; name: any; title: any; description: any; category: any; criteria: any; storedProcedure: any; namedRange: any; CriteriaDisplayNames: any; TemplateFile: any; version: any; IsAsynchronousReport: number; ApproxGenerationTime: any; MaxDays: any; ShowMaxRecord: any; ConnectionName: any; reportRoleMapping: any[]; isActive: boolean ;IsMultiSheetEnabled:any,templateFileName:any};
  allFields = [{}];
  mandatoryFields = [{}];
  sampleDataAll = [{
    name: 'Name',
    title: 'Report Title',
    description: 'Description',
    category: 'Category',
    storedprocedure: 'Stored Procedure',
    templatefile: 'Template File',
    isasynchronousreport: '0/1',
    showmaxrecord: '0-9',
    namedrange: 'Named Range',
    criteria: 'Criteria',
    criteriadisplaynames: 'Criteria Display Names',
    version: '0-9',
    roles: 'Roles',
    connectionname:'ReportDB/liveDB',
    includeheader: '0/1',
    approxgenerationtime: '0-9',
    maxdays: '0-9',
    cronexpression: 'Cron Expression',
    crondescription: 'Cron Description',
    ishtmlreport: '0/1',
    ismultisheetenabled: '0/1',
    isactive: '0/1'
  }]
  sampleDataMin = [{
    name: 'Name',
    title: 'Report Title',
    description: 'Description',
    category: 'Category',
    storedprocedure: 'Stored Procedure',
    templatefile: 'Template File',
    isasynchronousreport: '0/1',
    showmaxrecord: '0-9',
    namedrange: 'Named Range',
    criteria: 'Criteria',
    criteriadisplaynames: 'Criteria Display Names',
    version: '0-9',
    roles: 'Roles',
    connectionname:'ReportDB/liveDB'
  }]
  uploadScreen: boolean;
  gridWidth: number;
  workBook: any;
  sheetsToSelect: any[];
  excelDataArray: any[];
  destDeployment: string;
  showInvalidColumns: boolean;
  invalidColumns: any;
  showDelete: boolean;
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  gridDisplay: boolean;
  postDownload: boolean;
  postUpload: boolean;
  configuredJson: any;
  hideUploadBtn: boolean;
  showUploadInEdit: boolean;
  modalflag: boolean = false;
  public workbookData: any;
  uploadFileContent: { "id": string; "name": any; "source": any; }[];
  auditableColumns: any;
  activityEntity: any;
  createActivityOperations = new Operations();
  createActivityInput = new CreateActivityTrackerInput();
  oldFileContent: { "id": string; "name": any; "source": string; }[];
  
  constructor(public _snackbar: MatSnackBar, private _fb: UntypedFormBuilder, private ngxService: NgxUiLoaderService,
    private vitalservice: VitalHttpServices, private dialog: MatDialog, private injectorServiceGridContainer: InjectorService,
    public usersession: UserSession, public vitalHttpServices: VitalHttpServices, private commonService: CommonService, public detect: ChangeDetectorRef,
    private datashare: DataShareService, private clipboard: Clipboard, private excelService: ExcelService,public activityService: ActivityTrackerService,public datePipe: DatePipe,
  ) {
    super(injectorServiceGridContainer);
    this.subMenuCardModel = new SubMenuCardModel(commonService, vitalHttpServices, datashare);
  }

  // Criteria selected data sorted
  getSortData(evt) {
    this.CriteriaList = this.CriteriaList.sort((a, b) => { return b.check - a.check })
  }

  // Criteria checkbox value
  toggleAllSelection(val, $event) {
    $event.preventDefault($event)
    $event.stopPropagation();
    this.CriteriaList.forEach(ele => {
      ele.check = this.finalarray.findIndex(t => t.split(',')[1] == ele.Criteria) == -1 ? -1 : this.finalarray.findIndex(t => t.split(',')[1] == ele.Criteria) + 1
    })
    if (this.finalarray.length == 0) {
      this.CriteriaList.forEach(ele => ele.check = -1)
    }
  }

  // selected value of criteria
  selectedValue(data) {
    this.finalarray = data.value
  }

  // Edit criteria display name
  editCDN($event: any, i: any, item) {
    this.tempValue($event);
    item.isEdit = true;
    this.disable = true
    this.textview = i;
    this.txtvalue = this.CriteriaList[i].CDN;
    let numb = this.finalarray.findIndex(va => va.includes(this.txtvalue))
    let numbs = this.CriteriaList.findIndex(va => va.CDN.includes(this.txtvalue))
  }

  // Save criteria display name
  saveCDN($event: any, i: any, item) {
    this.CriteriaList[i].CDN = this.txtvalue
    this.textview = -1
    this.disable = false;
    item.isEdit = false;
    let ind = this.finalarray.findIndex(ele => ele.split(',')[1] == item.Criteria)
    if (ind != -1) {
      this.finalarray[ind] = item.CDN + ',' + item.Criteria
    }
    this.recipientData.patchValue({
      criteria: this.finalarray
    })
    $event.stopPropagation();
  }

  // Cancel criteria display name
  cancelCDN($event: any, i: any, item) {
    this.tempValue($event)
    this.textview = -1
    this.disable = false;
    item.isEdit = false;
  }

  targetValue(evt) {
    this.txtvalue = evt.target.value
  }

  disableEvent($event: any) {
    this.tempValue($event)
  }

  tempValue(event) {
    event.preventDefault(event)
    event.stopPropagation();
  }

  disableButton(customReportData) {
    if (customReportData.title && customReportData.Spname && customReportData.NamedRange &&
      customReportData.criteria && customReportData.Description && customReportData.name
    ) {
      if (
        customReportData.title.trim() == '' ||
        customReportData.Spname.trim() == '' ||
        customReportData.NamedRange.trim() == '' ||
        customReportData.criteria == '' ||
        customReportData.Description.trim() == '' ||
        customReportData.name.trim() == ''
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  downloadTemplate(TemplateFile) {
    this.dkey = sessionStorage.getItem('deploymentKey');
    let prefix;
    prefix = this.vitalHttpServices.GetReportLinksBasedOnDeployment(this.dkey)
    window.open(prefix + TemplateFile);
  }

  getCategoryDropdown() {
    this.ngxService.start();
    sessionStorage.setItem('deploymentKey', 'configdb');
    let query = this.vitalHttpServices.GetQuery('CategoryDropDown');
    let queryVariable = { context: 'Statistics' };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalservice.GetData(queryResult).subscribe((Resdata) => {
      sessionStorage.setItem('deploymentKey', this.vitalservice.deploymentKey);
      if (!Resdata.errors) {
        this.configuredJson = JSON.parse(Resdata.data.submenuData[0].Items_JSON);
        this.distinctCategory = this.configuredJson.Category;
        this.CriteriaList = this.configuredJson.criteria;
        this.TempCriteriaList = JSON.parse(JSON.stringify(this.CriteriaList));
        this.ngxService.stop();
      }
      else {
        this.ngxService.stop();
      }
    }, error => {
      console.error(error);
      this.ngxService.stop();
      sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
    });
  }

  ngOnInit(): void {
    this.customerReportGrid = this.templateData.GroupData && this.templateData.GroupData.OrganizationId ? this.templateData.GroupData.OrganizationId : sessionStorage.getItem("org_id");
    this.p4Deployment = this.commonService.isP4deployment(sessionStorage.getItem('deploymentKey'));
    this.ngxService.start();
    this.roleArray = [];
    let card = sessionStorage.getItem('context');
    if (card.toLowerCase() == 'laboratory') {
      // card = 'Laboratory';
      let cards = 'Laboratory';
      let query = this.vitalHttpServices.GetQuery('getuserrolesforassociationtype');
      let queryVariable = {
        ClassName: 'com.va.distribution.ScheduleReports',
        associationtype: "associationtype = \"" + card + "\""
      };
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      // return await this.vitalservice.getQueriedData(queryResult);
      this.vitalHttpServices.GetData(queryResult).subscribe(resData => {
        if (!resData.errors) {
          this.roleArray = [];
          if (resData.data.vwGetRolesBasedOnAccountAssociation_list != undefined && resData.data.vwGetRolesBasedOnAccountAssociation_list.length > 0) {
            resData.data.vwGetRolesBasedOnAccountAssociation_list.forEach(va => this.roleArray.push({ Role_Name: va.rolename }));
          }
          //next code is executed after usr roles are fetched
          setTimeout(() => {
            this.getpathtoFile();
            this.getCategoryDropdown();
          });
          // this.customerReportGrid = this.templateData.GroupData.OrganizationId;
          this.dropdownSettings = {
            singleSelection: false,
            idField: 'Role_Name',
            textField: 'Role_Name',
            enableCheckAll: true,
            allowSearchFilter: true,
            limitSelection: -1,
            clearSearchFilter: true,
            maxHeight: 150,
            itemsShowLimit: 44,
            closeDropDownOnSelection: false,
            showSelectedItemsAtTop: false,
            defaultOpen: false
          };
          this.commonService.getBooleanValue().subscribe((res) => {
            this.changeToTrue = res;
          });
          this.GetButtondetails();
          this.ngxService.stop();
        }
        else {
          this.roleArray = [];
          console.error(resData.errors);
          this.ngxService.stop();
        }
      }, error => {
        console.error(error);
        this.ngxService.stop();
      });

    }
    else if (card.toLowerCase() == 'facility') {
      let cards = 'Ordering Facility';
      let query1 = this.vitalHttpServices.GetQuery('getuserrolesforassociationtype');
      let Qstr = '';
      Qstr += "associationtype = \"" + cards + "\"";

      let queryVariable1 = { "associationtype": Qstr };
      let queryResult1 = this.commonService.GetCardRequest(queryVariable1, query1);
      let dbName = sessionStorage.getItem('deploymentKey').toUpperCase();;
      this.vitalHttpServices.GetData(queryResult1, dbName).subscribe(res => {
        if (!res.errors) {
          if (res.data.vwGetRolesBasedOnAccountAssociation_list.length > 0) {
            res.data.vwGetRolesBasedOnAccountAssociation_list.forEach(va => this.roleArray.push({ rolename: va.rolename }));
          }

          setTimeout(() => {
            this.getpathtoFile();
            this.getCategoryDropdown();
          });
          // this.customerReportGrid = this.templateData.GroupData.OrganizationId;
          this.dropdownSettings = {
            singleSelection: false,
            idField: 'rolename',
            textField: 'rolename',
            enableCheckAll: true,
            allowSearchFilter: true,
            limitSelection: -1,
            clearSearchFilter: true,
            maxHeight: 150,
            itemsShowLimit: 44,
            closeDropDownOnSelection: false,
            showSelectedItemsAtTop: false,
            defaultOpen: false
          };
          this.commonService.getBooleanValue().subscribe((res) => {
            this.changeToTrue = res;
          });
          this.GetButtondetails();
          this.ngxService.stop();
        }
      });
    }
    else if (card.toLowerCase() == 'epg') {
      let cards = 'Pathologists';
      let query1 = this.vitalHttpServices.GetQuery('getuserrolesforassociationtype');
      let Qstr = '';
      Qstr += "associationtype = \"" + cards + "\"";

      let queryVariable1 = { "associationtype": Qstr };
      let queryResult1 = this.commonService.GetCardRequest(queryVariable1, query1);
      let dbName = sessionStorage.getItem('deploymentKey').toUpperCase();;
      this.vitalHttpServices.GetData(queryResult1, dbName).subscribe(res => {
        if (!res.errors) {
          if (res.data.vwGetRolesBasedOnAccountAssociation_list.length > 0) {

            res.data.vwGetRolesBasedOnAccountAssociation_list.forEach(va => this.roleArray.push({ rolename: va.rolename }));
          }
          setTimeout(() => {
            this.getpathtoFile();
            this.getCategoryDropdown();
          });
          // this.customerReportGrid = this.templateData.GroupData.OrganizationId;
          this.dropdownSettings = {
            singleSelection: false,
            idField: 'rolename',
            textField: 'rolename',
            enableCheckAll: true,
            allowSearchFilter: true,
            limitSelection: -1,
            clearSearchFilter: true,
            maxHeight: 150,
            itemsShowLimit: 44,
            closeDropDownOnSelection: false,
            showSelectedItemsAtTop: false,
            defaultOpen: false
          };
          this.commonService.getBooleanValue().subscribe((res) => {
            this.changeToTrue = res;
          });
          this.GetButtondetails();
          this.ngxService.stop();

        }
      });
    }
    this.getAuditableDetails(this.templateData.menuURL);
    this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': 'parentMenu', 'value': this.templateData.menuURL }] })
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
  }

  getAuditableDetails(location: any) {
    this.vitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }
  AddGridData() {
    let gridarray = [];
    let primary = {};
    if (!this.ifUpdate) {
      this.defaultReports = this.templateData.submenuData.submenuData1;
    } else {
      this.defaultReports = this.templateData.submenuData1;
    }

    //  this.defaultReports = this.templateData.submenuData1;
    if (this.defaultReports != undefined) {
      for (let itr = 0; itr < this.defaultReports.length; itr++) {
        this.templateData.submenuData.push(this.defaultReports[itr]);
      }
    }

    this.tempExport = [...this.templateData.submenuData]; // deep copy
    if (this.templateData.submenuData) {
      if (this.templateData.submenuData.length > 0) {
        for (let i = 0; i < this.templateData.submenuData.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(this.templateData.submenuData[i])) {
            for (let j = 0; j < this.gridheader.length; j++) {
              if (key.toLowerCase() === this.gridheader[j].toLowerCase()) {
                if (key.toLowerCase() == 'reportid') {
                  primary[key] = value.toString().replace(/,/g, '');
                } else {
                  if (value.toString() == 'null' || value.toString().trim() == '') {
                    value = 'Not Specified';
                  }
                  // primary[key] = value;
                }
                primary[key] = value;
              }
            }
          }
          gridarray.push(primary);
        }
      }
    }

    if (gridarray.length > 10) {
      this.showPaginationMainGrid = true;
    } else {
      this.showPaginationMainGrid = false;
    }

    if (this.custReportId != undefined) {
      this.showReportDetailsAfterEdit(this.custReportId);
    }
    this.gridData = new CollectionView(gridarray, { pageSize: 10 });
    this.gridwidth = 120 * (this.gridheader.length - 1 + 37);
    if (this.gridwidth > 1300) this.gridwidth = 800;
  }


  uniqByKeepLast(data, key) {

    return [new Map(
      data.map(x => [key(x), x])
    ).values()
    ]
  }

  AllowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  getpathtoFile() {
    this.ngxService.start();
    sessionStorage.setItem('deploymentKey', 'configdb');
    let queryVariable = {
      //for QC
      name: (environment.envPrefix == '' ? this.vitalservice.deploymentKey : environment.envPrefix + '-' + this.vitalservice.deploymentKey).toLocaleLowerCase()
    };
    let query = String(this.vitalHttpServices.GetQuery('StatisticsReportsPath'));
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalservice.GetData(queryResult).subscribe((Resdata) => {
      sessionStorage.setItem('deploymentKey', this.vitalservice.deploymentKey);
      if (!Resdata.errors) {
        if (Resdata.data.submenuData.length > 0) {
          this.path = Resdata.data.submenuData[0].ReportTemplatesBasePaths;
        }
        this.ngxService.stop();
      }
      else {
        this.ngxService.stop();
      }
    }, error => {
      console.error(error);
      this.ngxService.stop();
      sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
    });
    // this.path = "D:\\New folder\\mithun";
  }

  showReportDetails(grid, e) {
    var hti = grid.hitTest(e);
    var row = hti.row;
    var item = hti.panel.rows[row].dataItem;
    if (item != null) {
      this.showCustomReportView = true;
      this.showReportDetailsAfterEdit(item.ReportId);
    }
  }

  copy(value: string) {
    this.clipboard.copy(value);
    this._snackbar.open('', 'Copied');
  }

  showReportDetailsAfterEdit(reportID) {
    // await this.getAllData();
    if (reportID != null) {
      this.uniqdata = this.templateData.submenuData.find((va) => va.ReportId == reportID);
      if (this.uniqdata !== undefined) {
        this.showCustomReportView = true;
        this.filename = '';
        this.base64split = '';
        this.custReportName = this.uniqdata.Name;
        this.customerReportGrid = this.uniqdata.OrganizationId;
        this.approx = this.uniqdata.Approx_Generation_Time;
        this.custTitle = this.uniqdata.Title;
        this.custStoredProcedure = this.uniqdata.StoredProcedure;
        this.custTemplateFile = this.uniqdata.TemplateFile;
        this.custCategory = this.uniqdata.Category;
        this.custVersion = this.uniqdata.Version;
        this.custDescription = this.uniqdata.description;
        this.custReportId = this.uniqdata.ReportId;
        this.CreatedBy = this.uniqdata.CreatedBy;
        this.Modifiedby = this.uniqdata.Modified_By;
        this.custAsync =
          this.uniqdata.IsAsynchronousReport != null
            ? (this.uniqdata.IsAsynchronousReport.toString().toLowerCase() == 'true' ? 'True' : 'False')
            : 'False';
        this.custNamedRange = this.uniqdata.NamedRange;
        this.custMaxDays = this.uniqdata.maxDays.toLowerCase() == 'not specified' ? 31 : this.uniqdata.maxDays;
        this.custCreatedDate = this.uniqdata.CreatedDate;
        this.custmodifiedDate = this.uniqdata.Modified_Date;
        this.myRolesArray = [];
        if (this.uniqdata.roleMapping) {
          this.myRolesArray = this.uniqdata.roleMapping.split(',');
          this.updatedRoles = this.uniqdata.roleMapping.split(',');
          this.bigArrRole = this.myRolesArray;
        } else {
          this.updatedRoles = [];
        }
        if (this.uniqdata.Criteria) {
          this.custCriteria = this.uniqdata.Criteria.split(',');
        }
        if (this.uniqdata.Version == 1) {
          this.versiondesc = 'Normal Reports';
        } else if (this.uniqdata.Version == 2) {
          this.versiondesc = 'XLSX format files';
        } else if (this.uniqdata.Version == 3) {
          this.versiondesc = '';
        } else if (this.uniqdata.Version == 4) {
          this.versiondesc = 'CSV format files';
        }
        else if (this.uniqdata.Version == 0) {
          this.versiondesc = 'Multi Sheet';
        }
        else if (this.uniqdata.Version == 5) {
          this.versiondesc = 'XLSX using GrapeCity';
        }
        this.custName = this.uniqdata.Name;
        this.custConnectionName = this.uniqdata.connection_Name;
        if (!this.uniqdata.CriteriaDisplayNames) {
          this.custCriteriaDisplayName = "";
        }
        else {
          this.custCriteriaDisplayName =
            this.uniqdata.CriteriaDisplayNames.split(',');
        }
        this.custShowMaxRecord = this.uniqdata.ShowMaxRecord;
        this.activeMsg = this.uniqdata.IsActive;
        this.isActive = this.uniqdata.IsActive == 'Active' ? true : false
        this.showrolemandatoryfield = this.isActive;
        //this.showCustomReportView = true;
        this.tableGrid = false;

        //get user Details
        //compute query variable
        let Qstr = "";
        if (this.Modifiedby.toLowerCase() != 'not specified' && this.Modifiedby.toLowerCase().trim() != '') {
          Qstr += "userid = \"" + this.Modifiedby + "\"";
        }
        if (this.CreatedBy.toLowerCase() != 'not specified' && this.CreatedBy.toLowerCase().trim() != '') {
          if (Qstr != "") {
            Qstr += " or userid = \"" + this.CreatedBy + "\"";
            // this.modifierName = this.UserDetails.filter((va) => va.userid == this.Modifiedby);
          }
          else {
            Qstr += "userid = \"" + this.CreatedBy + "\"";
          }
          // this.createrName = this.UserDetails.filter((va) => va.userid == this.CreatedBy);
        }
        if (Qstr != "") {
          //if modifier and creater are both are specified execute this code
          let queryVariable = { "userid": Qstr };
          let query = this.vitalHttpServices.GetQuery('userDetails');
          // let queryVariable = {
          //   // ClassName: 'com.va.distribution.ScheduleReports',
          //   userid: userid
          // };
          let queryResult = this.commonService.GetCardRequest(queryVariable, query);
          this.ngxService.start();
          this.vitalservice.GetData(queryResult).subscribe(resdata => {
            if (!resdata.errors) {
              this.UserDetails = resdata.data.submenuData
              this.modifierName = this.UserDetails.filter((va) => va.userid == this.Modifiedby).length ?
                this.UserDetails.filter((va) => va.userid == this.Modifiedby) : [{ firstname: "", lastname: "" }];
              this.createrName = this.UserDetails.filter((va) => va.userid == this.CreatedBy).length ?
                this.UserDetails.filter((va) => va.userid == this.CreatedBy) : [{ firstname: "", lastname: "" }];
            }
            else {
              console.error(resdata.errors);
            }
          }, error => {
            this.ngxService.stop();
            this._snackbar.open('Something went wrong.Please try again', 'Close');
            console.error(error);
          });
          this.ngxService.stop();
        }
        else {
          //if modifier and creater are both not specified execute this code
          this.modifierName = [{ firstname: "", lastname: "" }];
          this.createrName = [{ firstname: "", lastname: "" }];
        }
      }
    }
    else {
      this.showCustomReportView = false;
    }
  }

  //Create button when clicked this function is triggered
  addNewReport() {
    this.ngxService.start();
    this.CriteriaList = JSON.parse(JSON.stringify(this.TempCriteriaList));
    this.addCustomReportModel = true;
    this.showCustomReportView = false;
    this.customerReportGrid = this.templateData.GroupData.OrganizationId;
    this.custCriteria = [];
    this.selectedLevel =[];
    this.onDeSelectAll();
    this.tableGrid = false;
    this.customReportData.patchValue({
      StoredProcedure: this.custStoredProcedure,
      title: '',
      name: '',
      templateFile: '',
      Category: '',
      Version: "",
      IsAsynchronousReport: false,
      Description: '',
      criteria: '',
      NamedRange: '',
      CriteriaDisplayNames: '',
      MaxDays: 31,
      ShowMaxRecord: 5,
      ConnectionName: '',
      Spname: '',
      approxGenarationTime: 0,
      item: '',
      isSelect: false,
      isActive:true
    });
    this.criteriaSelectedCount = 0;
    this.ngxService.stop();
  }

  //to save new Custom report
   async addNewCustomReport(customReportData) {

    if (this.isMandatoryCheck(customReportData, "add") == true) {
      this._snackbar.open("Mandatory Field Missing", "Alert!")
    }
    else {
      // if (customReportData.IsAsynchronousReport.toLowerCase() == "true" || customReportData.IsAsynchronousReport == 1) {
      //   customReportData.IsAsynchronousReport = "True"
      // } else {
      //   customReportData.IsAsynchronousReport = "False"
      // }
      let length = 0
      this.p4Deployment = this.commonService.isP4deployment(sessionStorage.getItem('deploymentKey'));
      this.finalarray.forEach(ele => {
        length += ele.length
      })
      if (length > 500) {
        this._snackbar.open("The criteria length should not be more than 500 letters.", "Failed")
      }
      else if ( this.updatedRoles.length == 0) {
        this._snackbar.open("Please select Roles", "Alert!")
      }
      else
        if (this.userid != undefined) {
          this.errorFlag = -1;
          this.ifSaved = true;
          // this.ngxService.start();
          if (this.errorFlag != 1) {
            this.validatedetails(customReportData);
            this.filename=customReportData.templateFile?customReportData.templateFile:this.filename;
            let tempCriteria = []
            let tempCriteriaDisplayname = []
            if (customReportData.criteria.length > 0) {
              for (let i = 0; i < customReportData.criteria.length; i++) {
                tempCriteria.push(customReportData.criteria[i].split(",")[1]);
                tempCriteriaDisplayname.push(customReportData.criteria[i].split(",")[0]);
              }
            }
            customReportData.includeHeader == 'true' ? (customReportData.includeHeader = 1) : (customReportData.includeHeader = 0);
            customReportData.isScheduledReport == 'true' || customReportData.isScheduledReport == true
              ? (customReportData.isScheduledReport = 1)
              : (customReportData.isScheduledReport = 0);
            customReportData.includeHeader == 'true' ||
              customReportData.includeHeader == true
              ? (customReportData.includeHeader = 1)
              : (customReportData.includeHeader = 0);
            customReportData.ShowMaxRecord != undefined
              ? customReportData.ShowMaxRecord
              : (customReportData.ShowMaxRecord = 0);
            customReportData.MaxDays != undefined
              ? customReportData.MaxDays
              : (customReportData.MaxDays = 0);
            customReportData.approxGenarationTime != undefined ||
              customReportData.approxGenarationTime != null ||
              customReportData.approxGenarationTime != ''
              ? customReportData.approxGenarationTime
              : (customReportData.approxGenarationTime = 0);
            // customReportData.IsAsynchronousReport == 'True'
            //   ? (customReportData.IsAsynchronousReport = 1)
            //   : (customReportData.IsAsynchronousReport = 0);
            customReportData.ConnectionName == 'true' ||
              customReportData.ConnectionName == true
              ? (customReportData.ConnectionName = 'ReportDB')
              : (customReportData.ConnectionName = null);
            customReportData.isSelect == 'true' || customReportData.isSelect == true
              ? (customReportData.isSelect = 1)
              : (customReportData.isSelect = 0);
            var obj = {
              path: this.path,
              organizationID: this.customerReportGrid,
              // reportID: this.uniqdata.ReportId,
              name: customReportData.name.trim(),
              title: customReportData.title.trim(),
              description: customReportData.Description.trim(),
              category: customReportData.Category,
              criteria: tempCriteria.join(",").trim(),
              storedProcedure: customReportData.Spname.trim(),
              namedRange: customReportData.NamedRange.trim(),
              CriteriaDisplayNames: tempCriteriaDisplayname.join(",").trim(),
              TemplateFile: this.filename,
              includeHeader: customReportData.includeHeader,
              version: customReportData.Version,
              IsScheduledReport: customReportData.isScheduledReport,
              IsAsynchronousReport: customReportData.IsAsynchronousReport,
              ApproxGenerationTime: customReportData.approxGenarationTime,
              IsMultiSheetEnabled: customReportData.isSelect,
              MaxDays: customReportData.MaxDays,
              ShowMaxRecord: customReportData.ShowMaxRecord,
              ConnectionName: customReportData.ConnectionName,
              Created_By: this.userid.trim() != "" ? this.userid.trim() : "-100",
              file: {
                fileName: this.filename,
                fileContent: this.base64split,
              },
              reportRoleMapping: this.updatedRoles,
              deployment: this.p4Deployment ? 'p4' : null,
              IsActive : customReportData.isActive
            };
            this.ngxService.start();
            await this.vitalservice.AddnewCustomReport(obj).subscribe(async (res) => {
              this.ngxService.stop();
              if (res.Success) {
                this._snackbar.open(res.Message, 'Success');
                this.custReportId = parseInt(res[0]);
                this.commonService.setBooleanToTrue(true);
                try {
                    this.uploadFileContent = [{
                      "id": "",
                      "name": obj.file.fileName,
                      "source": obj.file.fileContent.toString()
                    }]
                  let transactionid=this.commonService.generateGuid();
                  await this.vitalHttpServices.uploadFile(this.uploadFileContent)
                  .subscribe(async (res) => {
                    res.content[0]['type'] = 'file';
                    let newObject=await this.generateObject(obj)
                    newObject[0]['templatefile'] = JSON.stringify(res.content[0]);
                    newObject[0]['templatefilename'] = obj.file.fileName;
                    this.commonService.createActivityObject('', newObject[0].name, this.templateData.menuURL, 'Create', newObject[0], {}, transactionid, this.auditableColumns);
                   
                  });
                } catch (err) {
                  console.error(err);
                }
                this.tableGrid = true;
                this.filename = '';
                this.showrolemandatoryfield = false;
                this.addCustomReportModel = false;
                this.showCustomRepEdit = false;
                this.showCustomReportView = false;
              }
              else {
                this._snackbar.open(res.Message, 'Failed');
                this.showCustomRepEdit = false;
                this.tableGrid = false;
                this.addCustomReportModel = true;
                this.showCustomReportView = false;
              }
            }, (error: any) => {
              this.ngxService.stop();
              this._snackbar.open('Something went wrong.Please try again', 'Close');
              console.error(error);
            });
          } else {
            this._snackbar.open('Insert valid data', 'Failed');
            this.showCustomRepEdit = false;
            this.tableGrid = false;
            this.addCustomReportModel = true;
            this.showCustomReportView = false;
          }
        }
        else {
          this._snackbar.open("Logged in user is Unauthorized!", "Failed");
        }
    }
  }

   async generateObject(obj) {
    const lowercaseObj:any = {};
    await Object.keys(obj).forEach(key => {
      lowercaseObj[key.toLowerCase()] = obj[key];
    });
    return [{
      "name": lowercaseObj.name,
      "title": lowercaseObj.title,
      "description": lowercaseObj.description,
      "category": lowercaseObj.category,
      "criteria": lowercaseObj.criteria,
      "storedprocedure": lowercaseObj.storedprocedure,
      "namedrange": lowercaseObj.namedrange,
      "version": lowercaseObj.version,
      "isasynchronousreport": lowercaseObj.isasynchronousreport ? 'Yes' : 'No',
      "approxgenerationtime": lowercaseObj.approxgenerationtime,
      "ismultisheetenabled": lowercaseObj.ismultisheetenabled == 1  || lowercaseObj.ismultisheetenabled ? 'Yes' : 'No',
      "maxdays": lowercaseObj.maxdays,
      "showmaxrecord": lowercaseObj.showmaxrecord,
      "connectionname": lowercaseObj.connectionname,
      "reportrolemapping": lowercaseObj.reportrolemapping.toString(),
      "isactive": lowercaseObj.isactive ? 'Yes' : 'No',
      "templatefilename":lowercaseObj.templatefilename
    }];
  }
  




  //IsMultiSheetEnabled
  multisheetcheckValue(event) {
    if (event.target.checked) {
      this.isSelect = true
    } else {
      this.isSelect = false;
    }
  }

  handleUpload(event: any, type?: string) {
    const file = event.target.files[0];
    
    if (file) {
      if (file.name.split('.').pop() !== 'xlsx') {
        this.resetFileInput();
        this._snackbar.open('File format is not correct', '', {
          duration: 3000,
        });
        return;
      }

      this.filename = file.name;

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.base64split = reader.result?.toString()?.split('base64,')[1] ?? '';
      };
      if (type === 'create') {
        this.customReportData.get('templateFile')?.setValue(this.filename);
      }else{
        this.recipientData.get('templateFile')?.setValue(this.filename);
      }
    } else {
      this.resetFileInput();
    }
  }

  resetFileInput() {
    const fileInput = document.getElementById('myFileInputID') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
    this.filename = '';
    this.base64split = '';
  }

  back() {
    this.hideGrid = true;
    this.showDescription = false;
  }

  editBack() {
    this.ngxService.start();
    this.ShowModalEdit = false;
    this.myRolesArray = [];
    this.showCustomRepEdit = false;
    this.ShowModal = false;
    this.tableGrid = true;
    this.isSelect = false;
    this.recipientData.reset();
    this.showrolemandatoryfield = false;
    this.showCustomReportView = false;
    this.showManageSchedulesMail = false;
    this.getUpdatedGrid();
    this.ngxService.stop();
    this.custReportId = undefined;

  }
  public formGroup: UntypedFormGroup;
  public setForm() {
    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(this.myRolesArray, Validators.required),
    });
    // this.loadContent = true;
  }

  get formdata() {
    return this.formGroup.controls;
  }

  // Edit Custome Report for CDN
  editCustomReport() {
    this.CriteriaList = JSON.parse(JSON.stringify(this.TempCriteriaList));
    this.tableGrid = false;
    this.ShowModal = false;
    this.showCustomReportView = false;
    this.isuncheck = false;
    this.ShowModalEdit = false;
    this.showCustomRepEdit = true;
    this.setForm();
    this.ngxService.start();
    this.selectedLevel = 'Lab Manager';
    //let tempcri = String();
    //let tempcdn = String(this.custCriteriaDisplayName);
    if (this.CriteriaList.length > 0 && this.custCriteria.length > 0) {
      for (let i = 0; i < this.custCriteria.length; i++) {
        const element = this.CriteriaList.find(
          va =>
            va.Criteria === this.custCriteria[i])
        if (element !== undefined) {
          element.check = i + 1
          // this.custCriteriaDisplayName = ''
          element.CDN = this.custCriteriaDisplayName[i]
        }

        // this.criteriaSelectedCount = i +1;
      }
      this.CriteriaList = this.CriteriaList.sort((a, b) => { return b.check - a.check })
      this.finalarray = [];
      for (let i = 0; i < this.custCriteria.length; i++) {
        const element = this.custCriteriaDisplayName[i] + "," + this.custCriteria[i];
        this.finalarray.push(element)
      }
    }



    this.showrolemandatoryfield = this.isActive;
    this.recipientData.patchValue({
      storedProcedure: this.custStoredProcedure,
      title: this.custTitle,
      name: this.custReportName,
      templateFile: this.custTemplateFile,
      Category: this.custCategory,
      Version: this.custVersion,
      IsAsynchronousReport: this.custAsync == "True"? true : false   ,
      isActive: this.isActive,
      isSelect: this.uniqdata.IsMultiSheetEnabled == 'False' ? false : true,
      Description:
        this.custDescription.toLowerCase() == 'not specified'
          ? null
          : this.custDescription,
      criteria: this.custCriteria == 'not specified' ? '' : this.finalarray,
      NamedRange: this.custNamedRange,
      CriteriaDisplayNames:
        this.custCriteriaDisplayName == 'not specified' ? '' : this.custCriteriaDisplayName,
      MaxDays: this.custMaxDays,
      ShowMaxRecord:
        this.custShowMaxRecord.toLowerCase() == 'not specified'
          ? 0
          : this.custShowMaxRecord,
      approxGenarationTime:
        this.approx.toLowerCase() == 'not specified' ? 0 : this.approx
    });
    // if (!this.p4Deployment) {
      this.recipientData.patchValue({
        ConnectionName:
          this.custConnectionName.toLowerCase() == 'live db'
            ? null
            : this.custConnectionName,
      })
    //}


    if (this.isActive) {
      this.showrolemandatoryfield = true
    }
    else {
      this.showrolemandatoryfield = false
    }
    this.oldobject = {
      path: this.path,
      organizationID: this.uniqdata.OrganizationId,
      reportID: this.uniqdata.ReportId,
      name: this.recipientData.value.name,
      title: this.recipientData.value.title,
      description: this.recipientData.value.Description,
      category: this.recipientData.value.Category,
      criteria: this.custCriteria,
      storedProcedure: this.recipientData.value.storedProcedure,
      namedRange: this.recipientData.value.NamedRange,
      CriteriaDisplayNames: this.recipientData.value.CriteriaDisplayNames,
      TemplateFile: this.recipientData.value.templateFile,
      version: this.recipientData.value.Version,
      IsAsynchronousReport:this.recipientData.value.IsAsynchronousReport == false ? 0 : 1,
      ApproxGenerationTime: this.recipientData.value.approxGenarationTime,
      MaxDays: this.recipientData.value.MaxDays,
      ShowMaxRecord: this.recipientData.value.ShowMaxRecord,
      ConnectionName: this.recipientData.value.ConnectionName,
      reportRoleMapping: this.uniqdata.roleMapping.split(','),
      isActive: this.isActive,
      IsMultiSheetEnabled:this.recipientData.value.isSelect,
      templateFileName:this.recipientData.value.templateFile
    };

    this.ngxService.stop();
  }

  resetTheForm() {
    this.tableGrid = false;
    this.ShowModal = false;
    this.showCustomReportView = false;
    this.ShowModalEdit = false;
    this.showCustomRepEdit = true;
    this.ngxService.start();
    this.updatedRoles =  this.uniqdata.roleMapping.split(',');
    this.editCustomReport();
    this.ngxService.stop();
  }

  updateBackCustom() {
    this.ngxService.start();
    this.ShowModalEdit = false;
    this.showCustomRepEdit = false;
    this.tableGrid = false;
    this.showCustomReportView = true;
    this.ShowModal = false;
    this.ngxService.stop();
    this.updatedRoles = this.oldobject.reportRoleMapping;
    this.selectedRoleArr = [];
    if (!this.ifSaved) {
      this.selectedLevel = this.bigArrRole;
      this.myRolesArray = this.bigArrRole;

    }

  }

  GetButtondetails() {
    this.GetButtonAccess(this.vitalHttpServices.SubmenuAction);
  }

  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case 'Export':
          this.hideExportBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case 'Create':
          this.hideCreateBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case 'Edit':
          this.hideEditBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case 'Upload':
          this.hideUploadBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case 'CopyToOrg':
          this.hideCopyToOrgBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case 'EnableUploadInEdit':
          this.showUploadInEdit = seletedMenuPermissions[i].IsPermitted == "true" ? true : false;
          break;
      }
    }
  }

  ExportToExcel(data) {
    let orgname = sessionStorage.getItem('Org_Name');
    let filename = 'Custom Reports_';
    if (orgname != 'undefined' && orgname) {
      filename = filename + orgname;
      if (filename.length > 31) {
        let fileName = filename.split('_')[0] + '_';
        let orgName = filename.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
        if (orgname.length > 31) {
          fileName = filename.split('_')[0].replace(' ', '') + '_';
          filename = fileName + orgName + '_';
        }
        else {
          filename = fileName + orgname + '_';
        }
      }
      else {
        filename = filename + '_';
      }
    }
    var sheet = XLSX.utils.book_new();
    let array = [];
    for (let itr = 0; itr < data.length; itr++) {
      array.push(data[itr]);
    }
    filename = filename + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    if (filename.length > 31) {
      let fileName = filename.split('_')[0] + '_';
      if(fileName.length>31){
        filename = fileName.split('_')[0] +'.xlsx';
      }else{
         if(this.templateData.secondarykeys){
        filename = fileName  + this.templateData.secondarykeys.OrganizationId.toString()+'.xlsx';
      }
      else{
        filename = fileName.split('_')[0] +'.xlsx';
      }
    }
    }
    let sheetData = XLSX.utils.json_to_sheet(array);
    XLSX.utils.book_append_sheet(sheet, sheetData, '');
    XLSX.writeFile(sheet, filename);
  }
  exportDetailsToExcel() {
    let orgname = sessionStorage.getItem('Org_Name');
    let filename = 'Custom Reports_';
    if (this.custTitle != undefined || this.custTitle != null) {
      filename = filename + this.custTitle;
      if (filename.length > 31) {
        let fileName = filename.split('_')[0] + '_';
        let title = filename.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
        if (title.length > 31) {
          fileName = filename.split('_')[0].replace(' ', '') + '_';
          filename = fileName + title + '_';
        }
        else {
          filename = fileName + title + '_';
        }
      }
      else {
        filename = filename + '_';
      }
    }
    else if (orgname != 'undefined' && orgname) {
      filename = filename + orgname;
      if (filename.length > 31) {
        let fileName = filename.split('_')[0] + '_';
        let orgName = filename.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
        if (orgName.length > 31) {
          fileName = filename.split('_')[0].replace(' ', '') + '_';
          filename = fileName + orgName + '_';
        }
        else {
          filename = fileName + orgName + '_';
        }
      }
      else {
        filename = filename + '_';
      }
    }
    else {
      filename = filename;
    }
    filename = filename + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    if (filename.length > 31) {
      let fileName = filename.split('_')[0] + '_';
      if(fileName.length>31){
        filename = fileName.split('_')[0] +'.xlsx';
      }else{
         if(this.templateData.secondarykeys){
        filename = fileName  + this.templateData.secondarykeys.OrganizationId.toString()+'.xlsx';
      }
      else{
        filename = fileName.split('_')[0] +'.xlsx';
      }
    }
    }
    var sheet = XLSX.utils.book_new();
    let array = [];
    array.push(this.uniqdata);
    let sheetData = XLSX.utils.json_to_sheet(array);
    XLSX.utils.book_append_sheet(sheet, sheetData, '');
    XLSX.writeFile(sheet, filename);
  }

  initGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell)
        return;
      tt.hideDelay = 9999999;
      tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }

  sortComparator(a: any, b: any) {
    return 1;
  }

  async downloadOldCustomReportTemplate(oldData, newData, action) {
    try {
      let transactionid = this.commonService.generateGuid();
      const obj = {
        fileName: oldData[0].templatefilename,
        path: oldData[0].path,
        reportType:'customreport'
      };
      const res = await this.vitalHttpServices.downloadStatisticsTemplate(obj).toPromise();
  
      if (res) {
        const fileReader = new FileReader();
  
        fileReader.onload = async () => {
          let fileContent = (<string>fileReader.result).split(',')[1];
          this.oldFileContent = [{
            "id": "",
            "name": oldData[0].templatefilename,
            "source": fileContent.toString()
          }];
  
          if (action === 'Edit') {
            try {
              const uploadRes = await this.vitalHttpServices.uploadFile(this.oldFileContent).toPromise();
              uploadRes.content[0]['type'] = 'file';
              oldData[0]['templatefile'] = JSON.stringify(uploadRes.content[0]);
              const uploadNewRes = await this.vitalHttpServices.uploadFile(this.uploadFileContent).toPromise();
              uploadNewRes.content[0]['type'] = 'file';
              newData[0]['templatefile'] = JSON.stringify(uploadNewRes.content[0]);
              newData[0]['templatefilename'] = this.uploadFileContent[0].name;
  
              this.commonService.createActivityObject('', oldData[0].name, this.templateData.menuURL, 'Edit', newData[0], oldData[0], transactionid, this.auditableColumns);
            } catch (error) {
              console.error('Error uploading files:', error);
            }
          } else {
            try {
              const uploadRes = await this.vitalHttpServices.uploadFile(this.oldFileContent).toPromise();
              uploadRes.content[0]['type'] = 'file';
              oldData[0]['templatefile'] = JSON.stringify(uploadRes.content[0]);
  
              this.commonService.createActivityObject('', oldData[0].name, this.templateData.menuURL, 'Edit', newData[0], oldData[0], transactionid, this.auditableColumns);
            } catch (error) {
              console.error('Error uploading file:', error);
            }
          }
        };
  
        fileReader.readAsDataURL(res);
      } else {
        // Handle case where download returns empty or null
        if (action === 'Edit') {
          oldData[0]['templatefile'] = 'File not found';
          newData[0]['templatefile'] = this.uploadFileContent[0];
          this.commonService.createActivityObject('', oldData[0].name, this.templateData.menuURL, 'Edit', newData[0], oldData[0], transactionid, this.auditableColumns);
        } else {
          oldData[0]['templatefile'] = 'File not found';
          this.commonService.createActivityObject('', oldData[0].name, this.templateData.menuURL, 'Edit', newData[0], oldData[0], transactionid, this.auditableColumns);
        }
      }
    } catch (error) {
      console.error('Error downloading template:', error);
    }
  }
  

  async UpdateCustomReport(UpdateMailRecipientDetails) {
    if (this.isMandatoryCheck(UpdateMailRecipientDetails, "update") == true) {
      this._snackbar.open("Mandatory Field Missing", "Alert!")
    }
    else {
      // if (UpdateMailRecipientDetails.IsAsynchronousReport.toLowerCase() == "true" || UpdateMailRecipientDetails.IsAsynchronousReport == 1) {
      //   UpdateMailRecipientDetails.IsAsynchronousReport = "True"
      // } else {
      //   UpdateMailRecipientDetails.IsAsynchronousReport = "False"
      // }
      this.p4Deployment = this.commonService.isP4deployment(sessionStorage.getItem('deploymentKey'));
      let length = 0
      this.finalarray.forEach(ele => {
        length += ele.length
      })
      if (length > 500) {
        this._snackbar.open("The criteria length should not be more than 500 letters.", "Failed")
      }
      else if (this.showrolemandatoryfield == true && this.updatedRoles.length == 0) {
        this._snackbar.open("Please select Roles", "Alert!")
      }
      else
        if (this.userid != undefined) {
          this.ifSaved = true;
          this.errorFlag = -1;
          this.ngxService.start();
          this.showCustomReportView = false;
          this.validatedetails(UpdateMailRecipientDetails);
          this.filename=UpdateMailRecipientDetails.templateFile?UpdateMailRecipientDetails.templateFile:this.filename;
          if (this.errorFlag != 1) {
            var tempCriteria: any = [];
            var tempCriteriaDisplayname: any = []
            if (UpdateMailRecipientDetails.criteria.length > 0) {
              for (let i = 0; i < UpdateMailRecipientDetails.criteria.length; i++) {
                tempCriteria.push(UpdateMailRecipientDetails.criteria[i].split(",")[1]);
                tempCriteriaDisplayname.push(UpdateMailRecipientDetails.criteria[i].split(",")[0]);
              }
            }
            tempCriteria = String(tempCriteria);
            tempCriteriaDisplayname = String(tempCriteriaDisplayname);
            UpdateMailRecipientDetails.ConnectionName == null ? (this.oldobject.ConnectionName) : UpdateMailRecipientDetails.ConnectionName
            UpdateMailRecipientDetails.ConnectionName == 'true' ||
              UpdateMailRecipientDetails.ConnectionName == true || UpdateMailRecipientDetails.ConnectionName == 'ReportDB'
              ? (UpdateMailRecipientDetails.ConnectionName = 'ReportDB')
              : (UpdateMailRecipientDetails.ConnectionName = null);
            UpdateMailRecipientDetails.isScheduledReport == 'true' ||
              UpdateMailRecipientDetails.isScheduledReport == true
              ? (UpdateMailRecipientDetails.isScheduledReport = 1)
              : (UpdateMailRecipientDetails.isScheduledReport = 0);
            UpdateMailRecipientDetails.includeHeader == 'true'
              ? (UpdateMailRecipientDetails.includeHeader = 1)
              : (UpdateMailRecipientDetails.includeHeader = 0);
            UpdateMailRecipientDetails.ShowMaxRecord != undefined
              ? UpdateMailRecipientDetails.ShowMaxRecord
              : (UpdateMailRecipientDetails.ShowMaxRecord = 0);
            UpdateMailRecipientDetails.MaxDays != undefined
              ? UpdateMailRecipientDetails.MaxDays
              : (UpdateMailRecipientDetails.MaxDays = 0);
            UpdateMailRecipientDetails.approxGenarationTime != undefined
              ? UpdateMailRecipientDetails.approxGenarationTime
              : (UpdateMailRecipientDetails.approxGenarationTime = 0);
            // UpdateMailRecipientDetails.IsAsynchronousReport == 'True'
            //   ? (UpdateMailRecipientDetails.IsAsynchronousReport = 1)
            //   : (UpdateMailRecipientDetails.IsAsynchronousReport = 0);
            UpdateMailRecipientDetails.isSelect == 'true' || UpdateMailRecipientDetails.isSelect == true
              ? (UpdateMailRecipientDetails.isSelect = 1)
              : (UpdateMailRecipientDetails.isSelect = 0)
            this.filename = this.filename || this.filename != "" ? this.filename : (UpdateMailRecipientDetails.templateFile);
            var obj = {
              path: this.path,
              organizationID: this.uniqdata.OrganizationId,
              reportID: this.uniqdata.ReportId,
              name: UpdateMailRecipientDetails.name.trim(),
              title: UpdateMailRecipientDetails.title.trim(),
              description: UpdateMailRecipientDetails.Description.trim(),
              category: UpdateMailRecipientDetails.Category,
              criteria: tempCriteria,
              storedProcedure: UpdateMailRecipientDetails.storedProcedure.trim(),
              namedRange: UpdateMailRecipientDetails.NamedRange.trim(),
              CriteriaDisplayNames: tempCriteriaDisplayname,
              TemplateFile: this.filename,
              includeHeader: UpdateMailRecipientDetails.includeHeader,
              version: UpdateMailRecipientDetails.Version,
              IsScheduledReport: UpdateMailRecipientDetails.isScheduledReport,
              IsAsynchronousReport: UpdateMailRecipientDetails.IsAsynchronousReport,
              ApproxGenerationTime: UpdateMailRecipientDetails.approxGenarationTime,
              MaxDays: UpdateMailRecipientDetails.MaxDays,
              ShowMaxRecord: UpdateMailRecipientDetails.ShowMaxRecord,
              ConnectionName: UpdateMailRecipientDetails.ConnectionName,
              IsMultiSheetEnabled: UpdateMailRecipientDetails.isSelect,
              ModifiedBy: this.userid.trim() != "" ? this.userid.trim() : "-100",
              IsActive: this.recipientData.value.isActive,
              file: {
                fileName: this.filename,
                fileContent: this.base64split,
              },
              reportRoleMapping: this.updatedRoles,
              deployment: this.p4Deployment ? 'p4' : null
            };
            if (this.updatedRoles.length == 0) {
              this._snackbar.open("Mandatory field missing");
              this.ngxService.stop();
              return;
            }
            await this.vitalservice.UpdateOrganizationCustomReport(obj).subscribe(async (res) => {
              this.ngxService.stop();
              if (res.Success) {
                try {
                  this.uploadFileContent = [{
                    "id": "",
                    "name": obj.file.fileName,
                    "source": obj.file.fileContent.toString()
                  }]
                let transactionid=this.commonService.generateGuid();
                if(this.uploadFileContent[0].source){
                  let newObject=await this.generateObject(obj)
                    let oldObject=await this.generateObject(this.oldobject)
                    this.downloadOldCustomReportTemplate(oldObject,newObject,"Edit")
                }else{
                  await this.vitalHttpServices.uploadFile(this.uploadFileContent)
                  .subscribe(async (res) => {
                    res.content[0]['type'] = 'file';
                    let newObject=await this.generateObject(obj)
                    let oldObject=await this.generateObject(this.oldobject)
                    newObject[0]['templatefile'] = JSON.stringify(res.content[0]);
                    newObject[0]['templatefilename'] = obj.file.fileName;
                   this.commonService.createActivityObject('', oldObject[0].name, this.templateData.menuURL, 'Edit', newObject[0], oldObject[0], transactionid, this.auditableColumns);
                   
                  });
                }
                
              } catch (err) {
                console.error(err);
              }
                this.commonService.setBooleanToTrue(true);
                this.showCustomRepEdit = false;
                this.showCustomReportView = true;
                this.filename = '';
                this.showrolemandatoryfield = false;
                this.selectedRoleArr = [];
                this.updatedRoles = [];
                this._snackbar.open(res.Message, 'Success');
                this.customReportData.reset();
              } else {
                this._snackbar.open("Custom " + res.Message, 'Failed');
                this.showCustomRepEdit = true;
                this.showCustomReportView = false;
                this.filename = '';
              }
            }, (error: any) => {
              this.ngxService.stop();
              console.error(error);
              this._snackbar.open('Something went wrong.Please try again', 'Close');
            });
          } else {
            this.ngxService.stop();
            this._snackbar.open('Insert valid data', 'Failed');
            this.showCustomRepEdit = true;
            this.showCustomReportView = false;
          }

        }
        else {
          this._snackbar.open("Logged in user is Unauthorized!", "Failed");
        }
    }
  }
  addBack() {
    this.ngxService.start();
    this.CriteriaList = JSON.parse(JSON.stringify(this.TempCriteriaList))
    this.addCustomReportModel = false;
    this.ShowModalEdit = false;
    this.showCustomRepEdit = false;
    this.ShowModal = false;
    this.tableGrid = true;
    this.customReportData.reset();
    this.showCustomReportView = false;
    this.showManageSchedulesMail = false;
    this.getUpdatedGrid()
    this.ngxService.stop();
  }

  validatedetails(customReportDetails: any) {
    if (
      customReportDetails.Description == null ||
      customReportDetails.Description == '' ||
      customReportDetails.Description == undefined
    ) {
      this.errorFlag = 1;
      return;
    } else if (
      customReportDetails.storedProcedure == null ||
      customReportDetails.storedProcedure == '' ||
      customReportDetails.storedProcedure == undefined
    ) {
      this.errorFlag = 1;
      return;
    } else if (
      customReportDetails.title == null ||
      customReportDetails.title == '' ||
      customReportDetails.title == undefined
    ) {
      this.errorFlag = 1;
      return;
    } else if (
      customReportDetails.NamedRange == null ||
      customReportDetails.NamedRange == '' ||
      customReportDetails.NamedRange == undefined
    ) {
      this.errorFlag = 1;
      return;
    } else if (
      customReportDetails.criteria == null ||
      customReportDetails.criteria == '' ||
      customReportDetails.criteria == undefined
    ) {
      this.errorFlag = 1;
      return;
    }
    else if (customReportDetails.version) {
      this.errorFlag = 1;
      return;
    }
  }

  onCheckChange(item: any) {

    if (sessionStorage.getItem('context').toLowerCase() == 'laboratory') {
      this.updatedRoles.push(item.Role_Name);
    } else if (sessionStorage.getItem('context').toLowerCase() == 'facility'|| sessionStorage.getItem('context').toLowerCase() == 'epg') {
      this.updatedRoles.push(item.rolename);
    }
    this.isActive = true;
    this.showrolemandatoryfield = true;
    // this.bigArrRole = this.updatedRoles;
  }

  onSelectAll(items: any) {
    this.updatedRoles = [];
    if (sessionStorage.getItem('context').toLowerCase() == 'laboratory') {
      for (let itr = 0; itr < items.length; itr++) {
        this.updatedRoles.push(items[itr].Role_Name);
      }
    } else if (sessionStorage.getItem('context').toLowerCase() == 'facility' || sessionStorage.getItem('context').toLowerCase() == 'epg') {
      for (let itr = 0; itr < items.length; itr++) {
        this.updatedRoles.push(items[itr].rolename);
      }
    }

    this.isActive = true;
    this.showrolemandatoryfield = true;
    // this.bigArrRole = this.updatedRoles;
  }

  onItemDeSelect(items: any) {
    let index: number;
    if (sessionStorage.getItem('context').toLowerCase() == 'laboratory') {
      index =  this.updatedRoles.indexOf(items.Role_Name);
    } else if (sessionStorage.getItem('context').toLowerCase() == 'facility' || sessionStorage.getItem('context').toLowerCase() == 'epg') {
      index =  this.updatedRoles.indexOf(items.rolename);
    }

    if (index !== -1) {
      this.updatedRoles.splice(index, 1);
    }
    if (this.updatedRoles.length == 0) {
      this.isActive = false;
      this.showrolemandatoryfield = false;
    }
    // this.bigArrRole = this.updatedRoles;
  }
  public onDeSelectAll() {
    this.updatedRoles = [];
    this.isActive = false;
    //this.bigArrRole = this.updatedRoles;
  }
  //#region get updated grid data after copy from Entity
  getUpdatedGrid() {
    this.ngxService.start();
    let queryVariable;
    let query;
    queryVariable = { "orgid": this.commonService.orgid.toString() };
    query = this.subMenuCardModel.GetQuery("labcustomreports");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        this.templateData.submenuData = [];
        this.templateData.submenuData1 = [];
        this.templateData.submenuData = data.data.submenuData;
        this.templateData.submenuData1 = data.data.submenuData1;
        this.ifUpdate = true;
        this.AddGridData();
      }
    }, error => {

      this.ngxService.stop();
      console.log(error);
    });
    this.ngxService.stop();
  }
  //#endregion

  //#region popup for copy org
  copyOrg() {
    let colNames = [];
    let colDefs = [];
    colNames = ['title', 'name', 'description', 'category',
      'storedProcedure', 'templateFile', 'namedRange', 'criteria', 'includeHeader', 'version', 'IsScheduledReport',
      'CriteriaDisplayNames', 'IsAsynchronousReport', 'ApproxGenerationTime', 'OrganizationReportsDefGUID', 'MaxDays', 'ShowMaxRecord',
      'CreatedBy', 'ModifiedBy', 'CreatedDate', 'ModifiedDate', 'organizationID', 'IsMultiSheetEnabled', 'ConnectionName', 'IsHTMLReport','isActive']
    colDefs = ['Report_ID', 'Role_Name', 'MaxDays', 'IsAsynchronous_Report',
      'Criteria', 'Template_File', 'Stored_Procedure', 'Category', 'Title','Notes']
    let dialogRef = this.dialog.open(OrgTableComponent, {
      disableClose: true,
    });
    let orgid, casetypeData;
    orgid = sessionStorage.getItem('org_id');
    let card = sessionStorage.getItem('context');
    casetypeData = '';
    (<OrgTableComponent>dialogRef.componentInstance).dataToTakeAsInput = {
      OrganizationID: orgid,
      templateData:this.templateData,
      CaseType: casetypeData,
      accountid: this.templateData.cardIdentifier,
      cardType: card,
      isP4Deployment: this.p4Deployment,
      roles: this.roleArray,
      TableName: 'OrganizationReportsDef',
      ChildTableName: 'OrganizationReportsDefRoleMapping',
      ColumnNames: colNames,
      columnDefs: colDefs,
      ChildColumNNames: ['reportID', 'roleName']
    };
    return dialogRef.afterClosed().toPromise().then(result => {
      if (result) {
        this.getUpdatedGrid();
      }
    })
  }
  //#endregion
  // bulk upload
  uploadCustomReport() {
    if (this.hideUploadBtn) {
      this._snackbar.open("User is not authorized", "close")
      return;
    }
    this.uploadScreen = true;
    this.showCustomRepEdit = false;
    this.tableGrid = false;
    this.gridWidth = 0;
    // this.getTemplates()
    let queryVariable = { tablename: 'organizationreportsdef', identity: "false", nullable: "false" };
    let query = this.vitalservice.GetQuery("getTableColumns");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalservice.GetData(queryResult).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        if (data) {
          let customReportFields = ["name", "title", "description", "category", "storedprocedure", "templatefile", "isasynchronousreport", "showmaxrecord", "namedrange", "criteria", "criteriadisplaynames", "version", "roles","connectionname"
          ]
          for (let item of customReportFields) {
            this.allFields[0][item] = null
            this.mandatoryFields[0][item] = null
          }
          for (let item of data.data.allFields) {
            item.Column = item.Column.toLowerCase();
            this.allFields[0][item.Column] = null;
          }
          delete this.allFields[0]['reportID']
          delete this.allFields[0]['jobid']
          //delete this.allFields[0]['connectionname']
          delete this.allFields[0]['organizationid']
          delete this.allFields[0]['createdby']
          delete this.allFields[0]['createddate']
          delete this.allFields[0]['modifieddate']
          delete this.allFields[0]['modifiedby']
          delete this.allFields[0]['isscheduledreport']
          delete this.allFields[0]['organizationreportsdefguid']
        }
      }
    },
      error => {
        this.ngxService.stop();
        console.error(error);
      });
  }

  downloadExcelTemplate(allFieldsCheck: boolean = false) {
    let filenamePrefix = allFieldsCheck ? 'All Fields' : 'Minimal Fields';
    let filename = `Custom Report_${filenamePrefix}_${this.customerReportGrid}.xlsx`;
    let mandatoryFields = allFieldsCheck ? this.allFields : this.mandatoryFields;
    let sampleData = allFieldsCheck ? this.sampleDataAll : this.sampleDataMin;

    let finalArrData = [];

    for (let fields of mandatoryFields) {
        let data = [];
        for (let [key, value] of Object.entries(fields)) {
            let primary = {};
            primary["header"] = key;
            primary["value"] = key.toLowerCase() === 'connectionname' ? [{ name: "ReportDB" }, { name: "Live DB" }] : "";
            data.push(primary);
        }
        finalArrData.push({ name: "CustomReportUpload", values: data });
    }

    let sampleDataArray = [];
    for (let data of sampleData) {
        let sample = [];
        for (let [key, value] of Object.entries(data)) {
            let primary = { header: key, value: value };
            sample.push(primary);
        }
        sampleDataArray.push({ name: "SampleData", values: sample });
    }

    finalArrData.push(...sampleDataArray);

    this.workbookData = this.transform(finalArrData);
    this.exportAsXLSX(filename);
}


  transform(data) {
    let noOfRowaToGenerate = 200;
    return data.map(({ name, values }) => {
      if (name == 'SampleData') {
        noOfRowaToGenerate = 1;
      }
      const headers = values.reduce((prev, next) =>
        ({
          ...prev, [next.header]: Array.isArray
            (next.value) ? next.value.map(({ name }) => name) : next.value
        }), {})
      return {
        workSheet: name,
        rows: Array(noOfRowaToGenerate).fill(headers)
      }
    })
  }

  exportAsXLSX(name): void {
    this.excelService.exportAsExcelFile(this.workbookData, name);
  }
  onFileChange(ev) {
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect.filter(va => va.toLowerCase() != 'sampledata');
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == 'OrganizationAttributes')
            this.sheetsToSelect.length == 1 ? this.convertToJson(validSheet[0]) : this.openModal();

          }
          else {
            this.convertToJson(this.sheetsToSelect[0])
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }
  convertToJson(sheetname: string) {
    var worksheet;
    this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    });
    this.excelDataArray = [];
    let tempExcelArr = [];
    tempExcelArr = XLSX.utils.sheet_to_json(worksheet, { defval: null });
    tempExcelArr = tempExcelArr.filter(obj => {
      for (let key in obj) {
          if ((obj[key] !== null) && (obj[key] != 'null') && (obj[key] !== '')) {
            return true; // If any non-null value is found, keep the object
          }
      }
      return false; // If all values are null, discard the object
    });
    if (tempExcelArr.length == 0) {
      this._snackbar.open("The uploaded excel does not contain any data", "Close");
      tempExcelArr = [];
      return;
    }
    let primary = {}
    let tempArray = []
    for (let i = 0; i < tempExcelArr.length; i++) {
      for (let [key, value] of Object.entries(tempExcelArr[i])) {
        if (!key.toString().match(/empty/i)) {
          if (value === null) {
            value = '';
          }
        }
        if (key.toString().match(/isactive/i) || key.toString().match(/active/i)) {
          // key='IsActive'
          value =  (value == null || value.toString() == '')  ? true : (value ? true : false);
        }
        primary[key] = value;
      }
      tempArray.push(primary)
      primary = {}
    }
    tempExcelArr = tempArray;
    this.sheetHeader = [];
    let temp = [];
    temp = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
    temp[0].reverse();
    temp[0].push("Notes")
    temp[0].push("status")
    this.sheetHeader = temp[0];
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = []
    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
    let context = sessionStorage.getItem('context') == "Facility" ? 'Ordering Facility' : sessionStorage.getItem('context') == 'EPG' ? 'Pathologists' : sessionStorage.getItem('context')
    // this.excelDataArray.sort((a, b) => a.procedurename < b.procedurename ? -1 : a.procedurename > b.procedurename ? 1 : 0)
    let i = 1;
    this.excelDataArray.find(d => {
      Object.assign(d, { slno: i, notes: '', tablename: "organizationreportsdef", organizationid: this.customerReportGrid.toString(), context: context });
      i++;
    });
    this.validateExcel(this.excelDataArray);
  }
  validateExcel(excelArr) {
    this.ngxService.start();
    let dataArray = [];
    dataArray = this.convertObjKeysToLower(excelArr);
    // dataArray = dataArray.map((va) => ({...va,destDeployment:sessionStorage.getItem('DeploymentKey')}))
    this.vitalservice.bulkCustomReportValidation(dataArray,sessionStorage.getItem('DeploymentKey')).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        if (result.length > 0) {
          if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
            this.showInvalidColumns = true;
            this.invalidColumns = result[0].InvalidColumns;
          }
          this.showDelete = true
          dataArray.filter(e => {
            result.filter(r => {
              if (r.SlNo === e.slno) {
                e.notes = r.NoteMessage;
              }
            });
          });
          this.AddExcelData(dataArray);
        }
        else {
          this._snackbar.open("Please provide valid data", 'Failed');
        }
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", 'Failed');
      console.log(error);
    });
    // this.ngxService.stop();
  }
  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        let value = ""
        value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
        newobj[key.replace(/\s/g, "").toLowerCase()] = value
      }
      array.push(newobj)
    }
    return array;
  }

  AddExcelData(data: object[]) {
    this.ngxService.start();
    this.gridDisplay = true;
    this.gridWidth = 0;
    let gridArray = [];
    this.gridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(data[i])) {
            if (!key.toString().match(/empty/i)) {
              if (value == null) {
                value = "NULL"
                primary['notes'] = 'Null values exist!'
              }
              this.validationOfBulkdata(key, value);
            }
            primary[key] = value;
          }
          gridArray.push(primary);
        }
        if (this.showDelete) {
          this.sheetHeader.splice(this.sheetHeader.findIndex((va) => va == 'status'), 1)
        }
        else {
          this.sheetHeader.push('status');
        }
        // (e) => e.associatedAccountID === row.dataItem.associatedAccountID && e.AssociationType == row.dataItem.AssociationType
        this.excelDataArray = gridArray;
        this.gridData = new CollectionView(gridArray)
        // this.gridData = new CollectionView(gridarray, )
        this.gridWidth = (170 * this.sheetHeader.length) + 37;
        if (this.gridWidth > 1300) {
          this.gridWidth = 1300;
        }
        if (gridArray.length > 10) {
          this.showPaginationMainGrid = true;
        } else {
          this.showPaginationMainGrid = false;
        }
      }

    }
    this.ngxService.stop();
  }
  validationOfBulkdata(key: any, value: any) {

  }

  disableApprovebtn() {
    return this.excelDataArray.some(va => {
      return !(
        va['notes'] &&
        (va['notes'].toString().match(/Mandatory field is missing/i) || va['notes'].toString().match(/valid/i) || va["notes"].toString().toLowerCase().match(/Roles does not exist/i))
      );
    });
  }

  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    if (grid.rows.length == 0) {
      this.removeGrid();
    }
    this._snackbar.open('Deleted successfully', 'Close');
  }
  removeGrid() {
    this.gridWidth = 0;
    this.postDownload = false;
    this.uploadScreen = true;
    this.postUpload = false;
    this.showInvalidColumns = false;
  }
  backtoGrid() {
    this.tableGrid = false
    this.uploadScreen = true;
    this.postUpload = false;
    this.postDownload = false;
    this.showInvalidColumns = false;
    this.gridWidth = 0;
  }
  refreshGrid() {
    this.uploadScreen = false;
    this.tableGrid = true;
    this.postUpload = false;
    this.postDownload = false;
    this.showInvalidColumns = false;
    this.commonService.setBooleanToTrue(true);
  }


  formatItem(flexGrid: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel === flexGrid.cells) {
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value !== null) {
        wjCore.toggleClass(e.cell, 'error-msg', value == 'Mandatory field(s) missing!' || value == 'Null values exist!' || value == 'failure' || value == 'Report already exists!' || value == 'Please check the version');
        wjCore.toggleClass(e.cell, 'high-value', value == 'Valid' || value == 'Created Successfully' || value == 'Success');
        wjCore.toggleClass(e.cell, 'warn-value', value == 'Ignored' || value.toString().includes('Roles does not exist'));
      }
    }
  }

  initializedGrid(flexgrid) {
    const tt = new wjcCore.Tooltip();
    flexgrid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell)
        return;
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null)
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }
  uploadBulkAccountCreation() {
    let sendjson = [];
    //this.SaveOrgCopyData(selectedData);

    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      // let datacount = 0;
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if (this.excelDataArray[i]["notes"] == "") {
          this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
          return;
        }
      }

      if ((this.showInvalidColumns == true)) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          for (let [key, value] of Object.entries(this.excelDataArray[i])) {
            if (value == "null" || value == "" || value == null) {
              if (key.toString().match(/isactive/i)) {
                value = value == true || value.toString().toLowerCase() == 'true' ? 1 : 0
              }

              if(key.toLowerCase()=='connectionname'){
                if(value.toString().toLowerCase() !='reportdb'){
                  value=null
                }
              }
            }
            else {
              this.excelDataArray[i][key] = value.toString();
            }
          }
          for (let i = 0; i < this.excelDataArray.length; i++) {
            // this.addDescAndType(this.excelDataArray[i]);
            this.excelDataArray[i]["createdby"] = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : sessionStorage.getItem("Userid")
          }
        }
      }
      let dataArray = [];
      dataArray = this.convertObjKeysToLower(this.excelDataArray);
      sendjson = dataArray.filter(va => va.notes.toString().toLowerCase() == 'valid' || va.notes.toString().toLowerCase() == 'valid but report will be inactive!' || va.notes.toString().toLowerCase().includes('roles does not exist'))
      this.addBulkCustomReport(sendjson)
    }
  }
  addBulkCustomReport(dataArray) {
    this.ngxService.start();
    this.vitalservice.bulkCustomReportUpload(dataArray, sessionStorage.getItem('DeploymentKey')).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        if (result.length > 0) {
          // let attrIdArray = []
          this.showDelete = false;
          this.addingCategory(dataArray)
          this.postDownload = true;
          let uploadresult = this.convertObjKeysToLower(result);
          for (let i = 0; i < this.excelDataArray.length; i++) {
            for (let j = 0; j < uploadresult.length; j++) {
              if (this.excelDataArray[i]['slno'] == result[j]['SlNo']) {
                this.excelDataArray[i]['notes'] = result[j]['Notes']
                this.excelDataArray[i]['status'] = result[j]['Status']
                this.commonService.auditDetails('Custom Reports','Custom Reports',null,dataArray, 'Upload', this.templateData, this.allFields);
                break;
              }
              else {
                this.excelDataArray[i]['status'] = 'Ignored'
                if (this.excelDataArray[i]['notes'].toString().match(/already exists/i)) {
                  this.excelDataArray[i]['status'] = 'Ignored'
                }
              }
            }
          }
          this.postUpload = true;
          this.AddExcelData(this.excelDataArray)
          let dataExistsCount = 0;
          for (let i = 0; i < result.length; i++) {
            if (Object.values(result).every(function (item: any) {
              return item.Status == "Ignored" ? true : false
            })) {
              dataExistsCount++;
            }
          }
          this._snackbar.open('Data uploaded successfully', 'Close');
        }
        else {
          this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close')
        }
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open('Something went wrong.Please try again', 'Close');
      console.error(error);
    })
  }
  addingCategory(data) {
    let newCategory = false;
    let configureArray = [];
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < this.configuredJson.Category.length; j++)
        if (data[i].category.toLowerCase() == this.configuredJson.Category[j].item.toLowerCase()) {
          break;
        }
        else {
          if (j == this.configuredJson.Category.length - 1) {
            this.configuredJson.Category.push({ "item": data[i].category.toString() })
            newCategory = true;
            break;
          }
        }
    }
    if (newCategory) {
      configureArray.push(this.configuredJson);
      this.ngxService.start();
      this.vitalservice.updateCustomReportCategory(configureArray, 'configdb').subscribe(result => {
        this.ngxService.stop();

      }, error => {
        this.ngxService.stop();
        this._snackbar.open('Something went wrong.Please try again', 'Close');
        console.error(error)
      })
    }
  }
  downloadResult(flex) {
    let excel = [];
    flex.rows.find(e => {
      let notes = e._data["notes"];
      delete e._data["notes"];
      delete e._data["tablename"];
      delete e._data['slno'];
      excel.push(e._data);
      e._data["notes"] = notes
    });
    let filename = 'CustomReportResult_' + this.customerReportGrid.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      excel.reverse()
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'BulkFacCreate_');
    XLSX.writeFile(wb, filename);
  }

  isMandatoryCheck(customReportData, button) {
    if (button == "add") {
      if (customReportData.title == '' ||
        customReportData.Spname == '' || customReportData.NamedRange == '' || customReportData.criteria == '' ||
        customReportData.Description == '' ||
        customReportData.Category == '' ||
        customReportData.templateFile == '' ||
        customReportData.name == '' ) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      if (customReportData.title == '' ||
        customReportData.storedProcedure == '' ||
        customReportData.NamedRange == '' ||
        customReportData.criteria == '' ||
        customReportData.Description == '' ||
        customReportData.Category == '' ||
        customReportData.name == ''
      ) {
        return true;
      }
      else {
        return false;
      }
    }
  }
  // Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    //let result=this.commonService.selectdSheet;
    if (result) {
      this.convertToJson(result)
    }
  }
}

class ColDef {
  constructor(public binding?: string, public header?: string) { }
}
