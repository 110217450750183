import { SelectionModel } from '@angular/cdk/collections';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, startWith, tap } from 'rxjs';

@Component({
  selector: 'app-surgicenter-association',
  templateUrl: './surgicenter-association.component.html',
  styleUrls: ['./surgicenter-association.component.scss'],
})
export class SurgicenterAssociationComponent implements OnInit, OnChanges,OnDestroy {


  
  @Input() intialList: any[] = [];
  @Input() selectedList: any[] = [];

  @Input() key1: string = '';
  @Input() key2: string = '';
  @Output() onAdd:EventEmitter<any[]>=new EventEmitter<any[]>();
  @Output() onReturn:EventEmitter<any>=new EventEmitter<any>();
  
  intialFilterList:any[]=[]
  selectedFilterList:any[]=[]
  searchControl=new FormControl('')
  searchControlUnsubscribe$:any
  moveElemetOne: SelectionModel<any> = new SelectionModel<any>(true, []);
  moveElemetTwo: SelectionModel<any> = new SelectionModel<any>(true, []);
  errorMessage:string='';
  drop(event: CdkDragDrop<string[]>,clear:boolean=false) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      clear&&this.searchControl.patchValue('')
    }
    this.moveElemetOne.clear();
    this.moveElemetTwo.clear();
    // this.filterStatus(this.searchControl.value)
  }
  move(oper: number) {
    if (oper) {
      for (const i of this.moveElemetOne.selected) {
        transferArrayItem(
          this.intialFilterList,
          this.selectedFilterList,
          i,
          this.selectedFilterList.length
        );
      }
    } else {
      for (const i of this.moveElemetTwo.selected) {
        transferArrayItem(
          this.selectedFilterList,
          this.intialFilterList,
          i,
          this.intialFilterList.length
        );
      }
      this.searchControl.patchValue('')
    }
    this.moveElemetOne.clear();
    this.moveElemetTwo.clear();
    // this.filterStatus(this.searchControl.value)
  }
  ngOnInit(): void {
    this.filterStatus('');
   this.searchControlUnsubscribe$= this.searchControl.valueChanges.subscribe(
      {
        next:data=>{
          this.filterStatus(this.searchControl.value)
        }
      }
    )
   
  }
  ngOnChanges(changes: SimpleChanges): void {
  
    this.selectedFilterList=[...this.selectedList]
    this.filterStatus(this.searchControl.value)
  }
  filterStatus(text)
  {
    let list=[...new Set([...this.intialFilterList,...this.intialList])]
    this.errorMessage=!list.length?'No results':''
    this.intialFilterList=text!=''?
    list.filter(i=>
      this._normalizeValue(i[this.key1]).includes(this._normalizeValue(text))||
    this._normalizeValue(i[this.key2]).includes(this._normalizeValue(text))
      ):[...list];
      if(this.selectedFilterList.length)
        {
          this.intialFilterList=this.intialFilterList?.reduce((p,c)=>(
            !this.selectedFilterList.filter(i=>i[this.key1]==c[this.key1]).length?[...p,c]:[...p]
          ),[]) //this is to filter out data from selected data
        }

  }
  searchInput(text)
  {
    this.searchControl.patchValue(text)
  }
  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s?[-)(]/g, '');
  }
  ngOnDestroy(): void {
    this.searchControlUnsubscribe$.unsubscribe()
  }
  selectedData(){
    this.searchControl.patchValue('')
    this.onAdd.emit([this.intialFilterList,this.selectedFilterList]);
  }
}
