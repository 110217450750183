<div class="modal-dialog replicate-org">
  <form [formGroup]="replicateOrgForm">
    <div class="modal-content header-wrapper">

      <!-- Modal Header -->
      <div class="modal-header admin-model-header header-freeze">
        <h3 class="modal-title w-100 admin-model-header-txt">Replica of Group</h3>
        <span type="button" data-dismiss="modal" (click)="replicateOrgFormClose()" class="mr-2">
          <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24 cursor" title="Close">
        </span>
      </div>
      <br />

      <!-- Modal body -->
      <div class="modal-body">
        <div class="container-fluid">
          <div class="col-sm-12 d-flex">
            <div class="col-sm-6 p-0">
              <mat-form-field class="col-sm-12 example-additional-selection form" appearance="outline">
                <mat-label>
                    <strong>Deployment</strong>
                    </mat-label>
                <mat-select type="text" disableOptionCentering matInput #searchbar formControlName="deploymentKey">
                  <mat-option class="temp-font-size" *ngFor="let i of deploymentKeys" value="{{i}}" (onSelectionChange)="onChangeDeployment($event,i)">
                    <span>{{ i }}</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-sm-6 p-0">
              <mat-form-field class="col-sm-12 example-additional-selection form" appearance="outline">
                <mat-label>
                  <strong>Group</strong>
                </mat-label>
                <mat-select placeholder="Select" disableOptionCentering formControlName="groupName" (closed)="searchGrp.value= ''">
                  <div class="select-container">
                    <mat-form-field class="w-100">
                      <input #searchGrp autocomplete="off" placeholder="Search" aria-label="Search" matInput />
                      <button [disableRipple]="true" *ngIf="searchGrp.value" matSuffix mat-icon-button aria-label="Clear"
                        (click)="searchGrp.value= ''">
                        <mat-icon>close</mat-icon>
                      </button>

                    </mat-form-field>
                    <mat-optgroup *ngIf="filterGroups(searchGrp.value).length == 0">
                      <div>No result found!</div>
                    </mat-optgroup>
                    <mat-option class="mat-opt-align" *ngFor="let org of filterGroups(searchGrp.value)" value="{{org.Org_Name}}"
                      title="{{org.Org_Name}}" (onSelectionChange)="searchGrp.value='';assignGroupId($event,org)">{{org.Org_Name}}
                    </mat-option>
                  </div>
                </mat-select>
              </mat-form-field>
              <!-- <mat-form-field class="select-input-field example-additional-selection p-0" appearance="outline">
                <input class="input-width" matInput [matAutocomplete]="auto" />
                <mat-label class="label-wrap">Group</mat-label>
                <em class="fa fa-chevron-down chevron-align"></em>
                <mat-select disableOptionCentering formControlName="groupName">
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option class="mat-opt-align" *ngFor="let org of organizationList" value="{{org.Org_Name}}"
                      (onSelectionChange)="assignGroupId($event,org)">{{org.Org_Name}}</mat-option>
                  </mat-autocomplete>
                </mat-select>
              </mat-form-field> -->
            </div>
          </div>

          <div class="col-sm-12 note-instr mt-1 ml-3">
            <span><b>Note:</b> &nbsp;</span> <span>Live groups will not be copied in production hours (EST). Still you can start copy now and groups will be copied in non-PROD hours.</span>
          </div>
          
          <div class="ml-2" *ngIf="replicateOrgForm.value.deploymentKey">
            <mat-checkbox class="col-sm-6" [color]="task.color" formControlName="copyLiveUsers" (change)="copyCheck($event)">
              Copy Live users from Labs and Ordering Facilities
            </mat-checkbox>
            <mat-checkbox class="col-sm-6" [color]="task.color" formControlName="isAutoOrg" (change)="errorFlag = false">
               Automation Group
            </mat-checkbox>
          </div>

          <div class="col-sm-12 mt-2" *ngIf="replicateOrgForm.value.copyLiveUsers">
            <mat-form-field class="col-sm-6 example-additional-selection form" appearance="outline">
              <mat-label>
                <strong>Roles</strong>
              </mat-label>
              <mat-select placeholder="Select" disableOptionCentering #matSelectOptionRoles formControlName="roles"
                [ngModelOptions]="{standalone: true}" (closed)="search.value= ''" multiple>
                <div class="select-container">
                  <mat-form-field class="w-100">
                    <input #search autocomplete="off" placeholder="Search" aria-label="Search" matInput />
                    <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear"
                      (click)="search.value = ''">
                      <mat-icon>close</mat-icon>
                    </button>

                  </mat-form-field>
                  <mat-optgroup *ngIf="filterRoles(search.value).length == 0">
                    <div>No result found!</div>
                  </mat-optgroup>
                  <div class="select-all" *ngIf="filterRoles(search.value).length != 0">
                    <mat-checkbox [color]="task.color" class="chk-box-align" (change)="toggleAllSelection($event)"
                      [checked]="allRolesChecked()" [indeterminate]="!allRolesChecked() && someRolesChecked()">
                      Select All</mat-checkbox>
                  </div>
                  <mat-option class="mat-opt-align" *ngFor="let option of filterRoles(search.value)" value="{{option.displayname}}"
                    title="{{option.displayname}}">
                    {{option.displayname}}
                  </mat-option>
                </div>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <span class="row pr-0 pl-0 mr-0 ml-0 mb-3 align-center " *ngIf="errorFlag">
        <span class="note-error"> {{failedMess}} </span> 
        <span [ngClass]="failedMess ? 'note-error' : ''">{{ errorMessage }} </span>
      </span>
      <span class="row pr-0 pl-0 mr-0 ml-0 mb-3 align-center">
        <button mat-raised-button class="admin-btn-success mr-3" (click)="replicateOrgFormClose()">Cancel</button>
        <button mat-raised-button class="admin-btn-success" [disabled]="errorFlag || checkFieldSelection()" (click)="replicateOrg()">Copy</button>
      </span>
    </div>
  </form>
</div>
