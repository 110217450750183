import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {  MatDialog } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as XLSX from 'xlsx';
import { ConfirmComponent } from '../../../base/popup/confirm/confirm.component';
import { CommonService } from '../../../core/services/commonservices';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { DataShareService } from '../../../core/services/datashare.service';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';

@Component({
  selector: 'app-MigrateUsers',
  templateUrl: './migrate-bulk-users.component.html',
  styleUrls: ['./migrate-bulk-users.component.scss']
})
export class MigrateBulkUsersComponent implements OnInit {
  files: any;
  filename: string;
  isProperfile: boolean;
  newTempfile: File;
  gridData: any;
  gridheader: any
  gridDisplay: boolean = false;
  gridEmpty: boolean = false;
  gridwidth: number;
  showPaginationMainGrid: boolean;
  columns: any[];
  userExcelData: any[];
  organizationLoginType: string;
  showComponent: boolean = false;
  btnmigrateuser: boolean;
  btnmigratemultipleusers: boolean;
  btnUpload: boolean;
  postUpload: boolean = false;
  deploymentKey : any ;
  @Input() templateData:any
  auditableColumns: any;

  constructor(private _fb: FormBuilder,
    public vitalHttpServices: VitalHttpServices,
    public _snackbar: MatSnackBar, private dialog: MatDialog,
    private commonService: CommonService,
    public ngxservice: NgxUiLoaderService,
    public dataService : DataShareService ,
    private activityService: ActivityTrackerService,
  ) {
  }

  myForm = this._fb.group({
    frmUserExcel: ['', Validators.required]
  })

  ngOnInit(): void {
    this.deploymentKey = sessionStorage.getItem('deploymentKey');
    this.getButtonAccess(this.vitalHttpServices.SubmenuAction);
    this.showComponent = true;
    this.getOrganizationLoginType()
  }

  getButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == 'Users')['SubMenu'].find(ele => ele.URL == 'Users')['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {

        case "Migrate user":
          this.btnmigrateuser = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case "Migrate multiple users":
          this.btnmigratemultipleusers = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case "Upload":
          this.btnUpload = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
      }
    }
  }

  onFileDropped($event) {
    this.onFileChange($event);
  }

  onFileChange(event) {
    let fileList: FileList = event.target.files;
    const file = event.target.files[0];
    this.filename = fileList[0].name;
    if (this.filename) {
      let splitarry = this.filename.split('.');
      if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
        this.myForm.patchValue({
          frmUserExcel: ''
        })
        this._snackbar.open('Please upload an excel file only', 'Close');
        this.isProperfile = false;
      } else {
        let workBook = null;
        let jsonData = null;
        const reader = new FileReader();
        const file = event.target.files[0];
        reader.onload = (event) => {
          const data = reader.result;
          workBook = XLSX.read(data, { type: 'binary' });
          jsonData = workBook.SheetNames.reduce((initial, name) => {
            const sheet = workBook.Sheets[name];
            initial[name] = XLSX.utils.sheet_to_json(sheet);
            return initial;
          }, {});
          this.checkSheetname(jsonData);
        }
        reader.readAsBinaryString(file);
      }
    }
  }

  checkSheetname(jsonData) {
    let message
    try {
      this.gridData = [];
      if (jsonData.Users) {
        for (let i = 0; i < jsonData.Users.length; i++) {
          this.gridData.push(jsonData.Users[i])
        }
      }
      else {
        this.gridData = [];
        message = "Please make sure that the sheet name is Users!"
      }
    }
    catch {
      this.gridData = [];
      message = "Please make sure that the excel is valid!";
    }

    if (message) {
      this._snackbar.open(message);
      return;
    }
    if (jsonData.Users.length == this.gridData.length)
      this.addGridData(this.gridData);

  }


  async addGridData(arraydata) {
    let gridarray = [];
    let primary = {};
    this.userExcelData = [];
    this.gridheader = ['LoginName', 'Email','Status'];
    if (arraydata && arraydata.length > 0) {
      for (let i = 0; i < arraydata.length; i++) {
        primary = {};
        for (let [key, value] of Object.entries(arraydata[i])) {
          for (let j = 0; j < this.gridheader.length; j++) {
            if (key === this.gridheader[j].toString().replace(/ /g, '')) {
              if (!value || value.toString().toLowerCase() == 'na' || value.toString().toLowerCase() == 'null' || value.toString().toLowerCase() == '') {
                value = 'Not Specified';
                primary[key] = value;
              } else {
                primary[key] = value;
              }
            }
          }
        }
        if (Object.keys(primary).length > 0) {
          gridarray.push(primary);
          this.userExcelData = gridarray;
        }


      }
      if (this.userExcelData.length == 0) {
        this._snackbar.open('Please upload a valid excel', 'Close');
        this.gridwidth = 0;
        this.gridDisplay = false;
        this.gridEmpty = true;
        this.myForm.patchValue({
          frmUserExcel: ''
        })
        return;
      }

      this.gridDisplay = gridarray.length > 0 ? true : false;
      this.gridEmpty = false;
      let checkHeader = 0;

      this.assignID(this.userExcelData);
      for (let i = 0; i < this.userExcelData.length; i++) {
        if ((!this.userExcelData[i]['Email'] || this.userExcelData[i]['Email'].toString().toLowerCase().trim() == 'not specified') && (!this.userExcelData[i]['LoginName'] || this.userExcelData[i]['LoginName'].toString().toLowerCase().trim() == 'not specified')) {
          this.userExcelData[i].Notes = 'No login name or email provided';
        }
        if (!this.userExcelData[i]['Email'] || !this.userExcelData[i]['LoginName'] || this.userExcelData[i]['LoginName'].toString().toLowerCase().trim() == 'not specified' || this.userExcelData[i]['Email'].toString().toLowerCase().trim() == 'not specified') {
          if (!this.userExcelData[i]['LoginName'] || this.userExcelData[i]['LoginName'].toString().toLowerCase().trim() == 'not specified') {
            this.userExcelData[i]["LoginName"] = 'Not Specified';
            this.userExcelData[i].Notes = 'Please provide a Login name';
          }
          else if (!this.userExcelData[i]['Email'] || this.userExcelData[i]['Email'].toString().toLowerCase().trim() == 'not specified') {
            this.userExcelData[i]["Email"] = 'Not Specified';
            this.userExcelData[i].Notes = 'Please provide an Email ID';
          }
        }
      }
      try {
        let excelData = this.userExcelData.filter(va => !va.Notes);
        if (excelData.length > 0) {
          await this.validateExcelData(excelData);
        }
      }
      catch {
        console.error('Data validation failed');
        this.resetData()
      }

      this.gridheader.push('Notes');
      this.columns = [
        new ColDef ('Status','Status'),
        new ColDef('Notes', 'Notes'),
        new ColDef('LoginName', 'Login Name'),
        new ColDef('Email', 'Email')
      ];
      this.postUpload = false;
      this.gridData = new CollectionView(this.userExcelData, { pageSize: 10 });
      this.gridwidth = 500 * ((this.gridheader.length - 1) + 37);
      if (this.gridwidth > 1300) {
        this.gridwidth = 800;
      }
      if (this.userExcelData.length > 10) {
        this.showPaginationMainGrid = true;
      } else {
        this.showPaginationMainGrid = false;
      }

    } else {
      this.gridEmpty = true;
      this.gridDisplay = false;
    }
    // });
  }

  assignID(userExcelData) {
    for (let i = 0; i < userExcelData.length; i++) {
      Object.assign(userExcelData[i], { slno: Number(i), OrganizationID: sessionStorage.getItem('org_id') });
    }
  }

  async validateExcelData(userExcelData) {
    await this.vitalHttpServices.validateMigrateUser(userExcelData,this.deploymentKey).toPromise().then(data => {
      if (data && data.length > 0) {
        data.filter(va => {
          this.userExcelData.forEach(val => {
            if (val.slno == va.slno) {
              val.Notes = va.StatusMessage ? va.StatusMessage : 'Valid';
              if (val.Notes == 'Valid') {
                val.UserID = va.UserID
              }
            }
          })
        });
        var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        this.userExcelData.forEach(val => {
          // val.Notes = val.Notes ? val.Notes : re.test(val.Email) ? 'Valid' : 'Invalid Email ID provided!';
          if(val.Email && val.Email.toString().toLowerCase().trim() != 'not specified')
          {
            val.Notes = re.test(val.Email) ? (val.Notes ? val.Notes : 'Valid') : val.Notes + (val.Notes ? ';' : '') + 'Invalid Email ID provided!';
          }
          else {
            val.Notes = val.Notes ? val.Notes : 'Valid';
          }
        });
      }
      else {
        console.error('Data validation failed');
        this.resetData();
      }
    },
      error => {
        console.error(error);
        this.resetData();
      });
  }

  resetData() {
    this.myForm.reset();
    this.myForm.patchValue({
      frmUserExcel: ''
    });
    let arrayData = [];
    this.addGridData(arrayData);
  }

  downloadTemplate() {
    let fileName = 'Migration Template_';
    if (fileName.length > 32) {
      let Org_Name = sessionStorage.getItem("Org_Name")
      var matches = Org_Name.match(/\b(\w)/g);
      fileName = matches.join('') + sessionStorage.getItem('org_id').toString() + '.xlsx';
    } else {
      fileName = 'Migration Template_' + sessionStorage.getItem('org_id').toString() + '.xlsx';
    }
    let interfaceGrid = [{ "LoginName": "", "Email": "" }];
    var sheet = XLSX.utils.book_new();
    var mainGrid = XLSX.utils.json_to_sheet(interfaceGrid);
    XLSX.utils.book_append_sheet(
      sheet,
      mainGrid,
      'Users',
    );

    XLSX.writeFile(sheet, fileName);
  }

  bulkMigrateUsers(userExcelData) {
    // if (userExcelData.some(va => va.Notes.toString().toLowerCase().trim() != 'valid')) {
    //   this._snackbar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
    //   return;
    // }
    try {
      let data = userExcelData.filter(va=> va.Notes == 'Valid')
      if(data && data.length > 0) {
        this.confirmMigration(data);
      }
    }
    catch (e) {
      console.error(e);
      this.ngxservice.stop();
      this._snackbar.open('Users migration failed.', 'Close');
    }
  }

  disableUpload(userExcelData){
    return !(userExcelData && userExcelData.some(va=> va.Notes.toString().toLowerCase().trim() == 'valid'))
  }
  confirmMigration(userExcelData) {
    this.ngxservice.start();
    this.vitalHttpServices.migrateUser(userExcelData,this.deploymentKey).subscribe(result => {
      if (result && result.length > 0) {
        this.ngxservice.stop();
        result.filter(va => {
          this.userExcelData.forEach(val => {
            if (val.slno == va.slno) {
              val.Notes = va.StatusMessage
              val.Status = 'Success'
            }
          })
        })

        this.userExcelData.find(val => {
          val.Status = (val.Status != 'Success') ? 'Ignored' : val.Status;
        });

        this.myForm.reset();
        this.myForm.patchValue({
          frmUserExcel: ''
        });
        this.gridData = new CollectionView(this.userExcelData, { pageSize: 10 });
        this.postUpload = true
        let info = `Migrate User - Bulk email migrations have been initiated`
        let entitykey=this.userExcelData[0].UserID;
        this.activityService.setActivitySession({ 'entityId': '', 'entityType': 'Users', 'context': [{ 'key': 'parentMenu', 'value': 'Migrate Users' }] })
        this.commonService.auditDetails(entitykey, '', [], this.userExcelData, 'Migrate', this.templateData, this.auditableColumns, info);
        this._snackbar.open('Data uploaded successfully.', 'Close')
      }
      else {
        this.ngxservice.stop();
        this._snackbar.open('Users migration failed.', 'Close')
      }
    }, error => {
      this.ngxservice.stop();
      this._snackbar.open('Users migration failed.', 'Close')
      console.error(error);
    });
  }

  getAuditableDetails(location: any) {
    this.vitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }
  //re-usable popup
  // openmodalconfrim(width,header,message,cancel,continues){
  //   let dialogRef = this.dialog.open(ConfirmComponent, {
  //     disableClose: true,
  //     width: width,
  //     data: { header: header, message: message, alert: "", continue: continues, cancel:cancel }
  //   })
  // }
  styleComponent(cell,column){
    if(column.header == 'Notes'){
      if(cell && (cell.item.Notes.toString().toLowerCase().trim() == 'valid' || cell.item.Notes.toString().match(/sent to user/i))) {
        return {color: 'green'}
      }
      else if(cell.item.Status == 'Ignored'){
        return {color: '#ff9800'} 
      }
      else {
        return {color: 'red'} 
      }
    }
    return 
  }

  initUserGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null)
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }

  uploadUserData() {
    if (!this.gridEmpty) {
      if (this.userExcelData.length > 0) {
        this.bulkMigrateUsers(this.userExcelData);
      }
      else {
        this._snackbar.open('Please select a file and then upload!', 'Close')
      }
    }
    else {
      this._snackbar.open('Please select a file and then upload!', 'Close')
    }
  }

  getOrganizationLoginType() {
    let queryVariable = { "Orgid": sessionStorage.getItem('org_id') };
    let query = this.vitalHttpServices.GetQuery("getorganizationname");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult,this.deploymentKey).subscribe(res => {
      if (res) {
        this.organizationLoginType = res.data.submenuData[0].LoginType == null || res.data.submenuData[0].LoginType == '' ? 'LoginName' : 'Email';
        if (this.organizationLoginType.toString().toLowerCase() != 'email') {
          let dialogRef = this.dialog.open(ConfirmComponent, {
            disableClose: true,
            width: '436px',
            data: { header: "Migrate User", message: "This group does not support Email based login for the Users. Please contact support team.", alert: "", continue: "OK", cancel: "dontshow" }
          })
          return;
        }
      }
    },
      error => {
        console.error(error);
      })
  }


  initGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }

  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.userExcelData = this.userExcelData.filter(va => va.slno != row.dataItem.slno);
    if (grid.rows.length == 0) {
      this.resetData();
    }
    this._snackbar.open('Deleted successfully', 'Close');
  }
}
class ColDef {
  constructor(public binding?: string, public header?: string) {
  }
}
