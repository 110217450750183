import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/core/services/commonservices';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { Editor, Toolbar } from 'ngx-editor';
import { ActivityTrackerService } from '../../../core/services/activity-tracker.service';
import { ConfigFieldsPopupComponent } from 'src/app/base/popup/config-fields-popup/config-fields-popup.component';
import {  MatDialog } from '@angular/material/dialog';

export interface Task {
  color: ThemePalette;
}

@Component({
  selector: 'app-new-association-form',
  templateUrl: './new-association-form.component.html',
  styleUrls: ['./new-association-form.component.scss']
})



export class NewAssociationFormComponent implements OnInit {

  task: Task = {
    color: 'primary',
  };

  isCurrentLocation = false;
  associationTypeList: any;
  servicesList: any;
  usersubscript: Subscription;
  epgAccountDetails: any;
  associationForm: FormGroup;
  temporgid: any;
  AssociatedOrgaDetails: any;
  tempAssociatedOrgaDetails: any;
  ofAccountDetails: any;
  organizationsList: any;
  locationsList: any;
  accountsList: any;
  accountList: any;
  accountsOfSelectedOrg: any;
  AccountsAdded: any;
  AddMultiselect: any;
  UpdateMultiselect: any;
  selectedGroupAccountsList: any;
  accessEditor: Editor;
  diagEditor: Editor;
  addeddata: any;
  associationTable = false;
  AssociationDetails: any = [];
  ExistData = false;
  AssociatedOrgid: any;
  AsssociatedAccountName: any;
  AsssociatedOrgName: any;
  accountid = sessionStorage.getItem('Locationid');
  AccountName: any;
  orgid = sessionStorage.getItem("org_id");
  finalData: any = [];
  isPreviewOpened = false;
  GridData: any = [];
  gridHeader = [
    {key: 'AssociationType', Name: 'Association Type', colWidth: '20%'},
    {key: 'AsssociatedAccountName', Name: 'Location', colWidth: '20%'},
    {key: 'AccountName', Name: 'Associate To', colWidth: '20%'},
    {key: 'services', Name: 'Services', colWidth: '12%'},
    {key: 'isPcEnabled', Name: 'PC Enabled', colWidth: '10%', textAlign: 'center'},
    {key: 'AllowReportViewing', Name: 'Reporting Viewing', colWidth: '15%', textAlign: 'center'},
  ];
  currentSearchText: any;
  accountSearchText: any;
  toolTipText: any;
  finalDataToBeSaved = [];

  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  backUpGridData: any;
  showConfigPopupForm = true;

  @Input() templateData: any;
  @Output() returnButtonClicked = new EventEmitter<any>();
  @ViewChild('currLoc') currLoc: ElementRef;
  activityEntity: any;
  accessIns: any = '';
  diagIns: any = '';

  constructor(private commonService: CommonService,
              private ngxService: NgxUiLoaderService,
              private vitalservice: VitalHttpServices,
              private fb: FormBuilder,
              private activityService: ActivityTrackerService,
              private dialog: MatDialog,
              private snackbar: MatSnackBar) {

  }
  
  ngOnInit() {
    this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': this.templateData['menuURL'] }] })
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
    this.accessEditor = new Editor();
    this.diagEditor = new Editor();
    this.ngxService.start();
    this.getAllAssociationTypes();
    this.associationForm = this.fb.group({
      AssociationType: '',
      AssociatedAccountid: '',
      associateOrg: '',
      serviceType: '',
      locationCode: '',
      AllowLabToView: null,
      PCEnabled: null,
      DiagnosisReporting: null,
      accsessionInstrcution: [''],
      diagInstrcution: '',
      Selecttype: 'Organizations',
      L2LConfigId: null,
      AccessionInstructions: null,
      LocationCode: null,
      AccountCode: null,
      ClientNumber: null,
      DiagnosisCustomInstructions: ['']
    });
  }

  GetQuery(keyword: string) {
    let mainQueryIndex: number = this.vitalservice.mainQueryList.findIndex(
      (mainQuery) =>
        mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase()
    );
    if (mainQueryIndex > -1)
      return this.vitalservice.mainQueryList[mainQueryIndex].Query;
  }

  getAllAssociationTypes() {
    sessionStorage.setItem('deploymentKey', 'configdb');
    const query = this.GetQuery('CategoryDropDown');
    const queryVariable = { context: 'AssociatedAccounts' };
    const queryResult = this.commonService.GetCardRequest(queryVariable, query);
    const contextFilter = {
      'Facility': 'Ordering Facilities',
      'Laboratory': 'Laboratories',
      'Send Out': 'Send-out Laboratories',
      'Send In': 'Send In Lab',
      'EPG': 'Patologists'
    };
  
    // this.ngxService.start();
    this.usersubscript = this.vitalservice.GetData(queryResult).subscribe({
      next: (Resdata) => {
        // this.ngxService.stop();
        if (!Resdata.errors) {
          const data = JSON.parse(Resdata.data.submenuData[0].Items_JSON);
          this.associationTypeList = data.AssociationTypes.filter(va => va.item != contextFilter[sessionStorage.getItem('selectedContext')]);
          this.servicesList = data.Services;
          this.usersubscript.unsubscribe();
          sessionStorage.setItem('deploymentKey', this.vitalservice.deploymentKey);
          this.getAccountName();
        }
      },
      error: (error) => {
        this.snackbar.open('An error occurred while processing your request', 'Failed');
        sessionStorage.setItem('deploymentKey', this.vitalservice.deploymentKey);
        this.ngxService.stop();
        console.error(error);
      }
    });
  }
  
  getAccountName() {
    const query = this.GetQuery('accountDetails');
    const queryVariable = { context: 'AssociatedAccounts' };
    const queryResult = this.commonService.GetCardRequest(queryVariable, query);
  
    // this.ngxService.start();
    this.vitalservice.GetData(queryResult).subscribe({
      next: (Resdata) => {
        if (!Resdata.errors) {
          this.epgAccountDetails = Resdata.data.submenuData;
          this.AccountName = this.epgAccountDetails.filter((va) => va.accountid == this.accountid);
        }
        // this.ngxService.stop();
        this.fetchOrgAcc();
      },
      error: () => {
        this.epgAccountDetails = [];
        this.snackbar.open('An error occurred while processing your request', 'Failed');
        this.ngxService.stop();
      }
    });
  }
  

  async locationFilter(event) {
    this.temporgid = null;
    var RegExp = /^[0-9]+$/
    let value = event.target.value ? event.target.value.trim() : ''
    let idCheck = RegExp.test(value)
    if (value.length >= 3 || idCheck) {
      let item = this.associationForm.value.AssociationType;
      if (value.length == 3 || RegExp.test(value)) {
        let associationtype = item == 'Ordering Facilities' ? 'Ordering Facilities' : item == 'Pathologists' ? 'epg' : 'Laboratories'
        var obj = {
          loggedInAccountId:this.templateData.cardIdentifier,
          associationType: associationtype,
          organizationName: !idCheck ? value : null,
          organizationid: idCheck ? value : null
        };
        this.ngxService.start();
        await this.vitalservice.getAssociatedOrganizations(obj).toPromise().then(
          (res) => {
            this.ngxService.stop();
            if (res.Success) {
              this.isCurrentLocation = false;
              this.AssociatedOrgaDetails = JSON.parse(res.Message);
              this.tempAssociatedOrgaDetails = this.AssociatedOrgaDetails;
            }
            else {
              this.AssociatedOrgaDetails = [];
            }
          }), error => {
            this.ngxService.stop();
            console.log(error)
          }
      }
      else if (event.target.value.length > 3) {
        this.AssociatedOrgaDetails = this.tempAssociatedOrgaDetails?.filter(va => va.accountname.toLowerCase().includes(event.target.value))
      }
      let tempAssociationArray = this.templateData.submenuData && this.templateData.submenuData.filter(va => va.Association_Type == item)
      if (tempAssociationArray && tempAssociationArray.length > 0) {
        const associatedAccountIdsSet = new Set(tempAssociationArray.map(element => element.Associated_Account_Id.toString()));

        this.AssociatedOrgaDetails = this.AssociatedOrgaDetails.filter(element1 =>
          !associatedAccountIdsSet.has(element1.accountid)
        );
      }

      this.ofAccountDetails = this.AssociatedOrgaDetails.filter(
        (va) =>
          va.organizationid != null && !(Math.sign(va.organizationid) == -1)
      );
      this.organizationsList = [...new Map(this.ofAccountDetails.map((item) => [item['organizationid'], item])).values(),];
      this.locationsList = this.AssociatedOrgaDetails;
    }
    else {
      this.organizationsList = [];
      this.locationsList = [];

      if(!event.target.value.trim()) {
        this.associationForm.patchValue({
          AssociatedAccountid: '',
        });
        this.selectedGroupAccountsList = {
          Organization: '',
          organizationId: '',
          checked: false,
          Accounts: [...[]]
        }
      }
    }
  }

  //#endregion
  fetchOrgAcc() {
    let query = this.GetQuery('ListAccountNameandId');
    let queryVariable = {
      orgid: sessionStorage.getItem("org_id"),
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalservice.GetData(queryResult).subscribe((Resdata) => {
      if (!Resdata.errors) {
        let data = Resdata.data.submenuData;
        this.accountsList = {
          Organization: sessionStorage.getItem("Org_Name"),
          checked: true,
          Accounts: []
        }
        let primaryData = []
        for (let i = 0; i < data.length; i++) {
          if (this.templateData.cardIdentifier == data[i].ID) {
            primaryData.push({ Name: data[i].Location, ID: data[i].ID, checked: true, disabled: false });
            delete data[i];
          }
        }

        data.forEach(va =>
          primaryData.push({ Name: va.Location, ID: va.ID, checked: false, disabled: false })
        )
        this.accountsList.Accounts = primaryData;
        this.accountList = structuredClone(this.accountsList.Accounts)
      }
      this.ngxService.stop();
    });
    (error) => {
      this.ngxService.stop();
      this.epgAccountDetails = [];
      this.snackbar.open('An error occurred while processing your request', 'Failed');
    };
  }

  getOrganizationName(value) {
    if (value?.source?.selected) {
      this.temporgid = value?.source?.value?.split('(')[1].split(')')[0];
      if (this.temporgid != 'null') {
        this.associationForm.patchValue({
          associateOrg: value.source.value
        });
      }
      else {
        this.associationForm.patchValue({
          associateOrg: ''
        });
      }
    }
  }

  //#region to get the account to an group based on the association type
  onOrganizationChange(newValue, item, selectedOrganization: any) {

    let primaryData = [];
    newValue = this.temporgid;
      this.accountsOfSelectedOrg = structuredClone(this.locationsList?.filter(
        (va) => va.organizationid == newValue && va.accountname != null
    ));

    this.accountsOfSelectedOrg?.forEach(va => va.accountname += ' (' + va.accountid + ')')

    this.accountsOfSelectedOrg?.forEach(va =>
      primaryData.push({ Name: va.accountname, ID: va.accountid, checked: false, disabled: false })
    );

    this.accountsOfSelectedOrg = [...primaryData];

    this.selectedGroupAccountsList = {
      Organization: selectedOrganization?.organizationname,
      organizationId: selectedOrganization?.organizationid,
      checked: false,
      Accounts: [...primaryData]
    }

    this.AccountsAdded = [];
    this.AddMultiselect = true;
    this.UpdateMultiselect = false;
  }
  //#endregion


  checkedAllAccounts(accountsList: any) {
    return accountsList?.Accounts?.every(va => va.checked)
  }

  notAllUnchecked(accountsList: any) {
    return !accountsList?.Accounts?.every(va => !va.checked)
  }

  checkAll(accountsList: any, checked: any) {
    accountsList.checked = checked
    accountsList?.Accounts?.forEach(va => va.checked = checked);
    if(this.isCurrentLocation) {
      this.finalData = [...[]];
      this.GridData = [...[]];
      this.getCurrentAssociations(sessionStorage.getItem("AccountID"));
    }
  }

  checkNoAccountSelected(accountsList: any = this.accountList) {
    return accountsList?.Accounts?.every(va => !va?.checked)
  }

  getAccountsList(accountsList, account): any {
    if (!account) {
      return accountsList.Accounts
    }
    return accountsList.Accounts.filter(va => va.Name && account && va.Name.toString().toLowerCase().includes(account.toString().toLowerCase()));
  }

  checkAccount(value, checked, accountsList: any = this.accountsList) {
    value.checked = checked;
    if (accountsList.Accounts.every(va => !va.checked)) {
      this.currLoc["checked"] = false;
      // return;
    }
    if(this.isCurrentLocation) {
      this.finalData = [...[]];
      this.GridData = [...[]];
      this.getCurrentAssociations(sessionStorage.getItem("AccountID"));
    }
  }

  returnClicked() {
    this.returnButtonClicked.emit();
  }

  save() {
    if(this.GridData?.length) {
      this.saveAssociation(this.GridData);
    } else {
      this.snackbar.open('At least one association must be present in order to save.', 'Failed');
    }
  }

  preview() {
    this.addeddata = this.accountsList?.Accounts?.filter((item: any) => item.checked);
    this.AccountsAdded = this.selectedGroupAccountsList?.Accounts?.filter((item: any) => item.checked);
    this.addAssociationList(this.associationForm?.value, true);
  }

  addNewAccounts() {
    this.associationForm.patchValue({Selecttype: 'Organizations'});
    this.addeddata = this.accountsList?.Accounts?.filter((item: any) => item.checked);
    this.AccountsAdded = this.selectedGroupAccountsList?.Accounts?.filter((item: any) => item.checked);
    this.addAssociationList(this.associationForm?.value);
  }

  saveAssociation(data, newAssociation?) {
    let associatedData = data;
    let editableData = [];
    if (!this.accountsList.Accounts.some(va => va.checked)) {
      this.snackbar.open("Select at least one location!", "Close")
      return
    }
    let finalData = associatedData;

    
    editableData = finalData.filter(va => va.Action == 'Edit')
    associatedData = finalData.filter(va => va.Action != 'Edit')

    associatedData.forEach(va => {
      if (va.AssociationType.toString().match(/my organization/i)) {
        va.associatedAccountID = va.accountid
      }
    })

    this.ngxService.start();
    if (associatedData.length > 0) {
      this.vitalservice.associateAccounts(associatedData).subscribe((res: any) => {
        if (res.Success) {
          this.commonService.auditDetails('', '', [], associatedData, 'Associate', this.templateData, '');
          this.ngxService.stop();
          this.snackbar.open(res.Message, 'Success');
          this.commonService.setBooleanToTrue(true);
          this.addeddata = [];
          this.AssociationDetails = [];

          if (editableData.length > 0) {
            this.editAssociations(editableData, res.Message);
            return;
          }
          else {
            this.AssociationDetails = [];
            this.returnButtonClicked.emit();
          }
        } else {
          this.snackbar.open(res.Message, 'Failed');
          this.ngxService.stopAll();
        }
      }, error => {
        console.error(error);
        this.snackbar.open('An error occurred while processing your request', 'Failed');
        this.ngxService.stopAll();
      });
    }
    else if (editableData.length > 0) {
      this.editAssociations(editableData)
    }
  }

  editAssociations(editData, Message?) {
    this.ngxService.start();
    this.vitalservice.editAssociations(editData).subscribe((res: any) => {
      if (res.Success) {
        this.snackbar.open(Message ? Message : res.Message, 'Success');
        this.commonService.setBooleanToTrue(true);
        this.addeddata = [];
        this.AssociationDetails = [];
        this.returnButtonClicked.emit();
      } else {
        this.snackbar.open(res.Message, 'Failed');
      }
      this.ngxService.stop();
    }, error => {
      console.error(error);
      this.snackbar.open('An error occurred while processing your request', 'Failed');
      this.ngxService.stop();
    });
  }

  addNewAssociation() {
    this.AccountName = this.epgAccountDetails.filter((va) => va.accountid == this.accountid);
    this.addeddata = [];
    this.UpdateMultiselect = false;
    this.AddMultiselect = true;
    this.AccountsAdded = [];
    this.fetchOrgAcc();
  }

  addAssociationList(associatedData, isFromPreview = false) {
    this.ngxService.start();
    associatedData.associateOrg = this.temporgid;
    
    if (associatedData?.Selecttype == 'Organizations') {
      if (this.AccountsAdded?.length > 0 && this.addeddata?.length > 0) {
        this.finalData = [];
        this.isPreviewOpened = true;
        for (let i = 0; i < this.AccountsAdded?.length; i++) {
          associatedData.AssociatedAccountid = this.AccountsAdded[i];
          if (this.validateDetails(associatedData)) {
            this.ExistData = false;
            this.templateData.submenuData;

            let existDataInList = [];

            if (existDataInList) {
              if (existDataInList.length >= 1) {
                this.ExistData = true;
              }
            }

            var filtereddata = this.epgAccountDetails.filter(
              (va) => va.accountid == associatedData.AssociatedAccountid
            );

            if (filtereddata.length >= 1) {
              this.AssociatedOrgid = filtereddata[0].organizationid;
            }
            
            var obj = {
              accountid: sessionStorage.getItem('Locationid'),
              associatedAccountID: this.AccountsAdded[i]['ID'],
              AsssociatedAccountName: this.AccountsAdded[i]['Name'].toString().split('(')[0].trim(),
              organizationID: this.orgid,
              services:  associatedData.AssociationType === 'Ordering Facilities' ? associatedData.serviceType ? associatedData.serviceType :  'None' : '',
              AccountName: this.AccountName[0].accountname,
              AssociationType: associatedData.AssociationType,
              AsssociatedOrgName: this.selectedGroupAccountsList?.Organization,
              AssociatedOrgid: this.selectedGroupAccountsList?.organizationId,
              Selecttype: associatedData.Selecttype,
              userid: sessionStorage.getItem('Userid') ? sessionStorage.getItem('Userid') : '-100',
              L2LConfigId: associatedData.L2LConfigId ? associatedData.L2LConfigId : null,
              AccessionInstructions: this.accessIns !== null ? this.accessIns : null,
              LocationCode: associatedData.LocationCode !== null ? associatedData.LocationCode : null,
              AccountCode: associatedData.AccountCode !== null ? associatedData.AccountCode : null,
              ClientNumber: associatedData.ClientNumber !== null ? associatedData.ClientNumber : null,
              DiagnosisCustomInstructions: this.diagIns !== null ? this.diagIns : null,
              isPcEnabled: associatedData?.PCEnabled ? true : false,
              AllowReportViewing: associatedData.AllowLabToView ? true : false,
              DiagnosisReporting: associatedData.DiagnosisReporting ? true : false,
            };

            if (this.ExistData != true) {
            this.AssociationDetails.push(obj);
            if(!this.finalData.includes(obj)) {
              this.finalData.push(obj);
            }
              
            this.UpdateMultiselect = false;
            this.AddMultiselect = true;
            this.associationTable = true;
            } else {
              obj = null;
              this.snackbar.open('"' + this.AsssociatedAccountName[0].accountname + '"' + ' is already selected for association with the type: ' + '"' + existDataInList[0].AssociationType + '"', 'Close');
            }
          } else {
            this.snackbar.open('Insert valid data', 'Failed');
          }
        }
        this.getDataToDisplayInPreviewGrid(isFromPreview)
      }
      else {
        this.snackbar.open('Select atleast one location', 'Failed');
      }
      this.AccountsAdded = [];
    }
    this.ngxService.stop();
  }

  getDataToDisplayInPreviewGrid(isFromPreview = false) {
    let arr = this.getFinalData(this.finalData, []);
    // to remove duplicates
    this.GridData = [...this.GridData, ...arr.filter(item => !new Set(this.GridData.map(JSON.stringify)).has(JSON.stringify(item)))];
    if(isFromPreview) {
      this.backUpGridData = structuredClone(this.GridData);
    }
    this.isPreviewOpened = true;
    this.associationForm.reset();
    this.selectedGroupAccountsList = {
      Organization: '',
      organizationId: '',
      checked: false,
      Accounts: [...[]]
    }
  }
  

  getFinalData(associatedData, finalData): any {
    for (let i = 0; i < this.accountsList.Accounts.length; i++) {
      if (this.accountsList.Accounts[i].checked) {
        for (let j = 0; j < associatedData.length; j++) {
          let { accountid: _, ...rest } = associatedData[j];
          rest.accountid = this.accountsList.Accounts[i].ID;
          rest.AccountName = this.accountsList?.Accounts[i]?.['Name']?.toString().split('(')[0].trim(),
          rest.userid = rest.userid ? rest.userid : sessionStorage.getItem('Userid') ? sessionStorage.getItem('Userid') : -100
          finalData.push(rest);
        }
      }
    }
    finalData.forEach(va => {
      if (va.accountid == this.templateData.cardIdentifier && va.associationid) {
        va.Action = "Edit"
      }
    })
    return finalData;
  }

  //#region  validation the field
  validateDetails(associationDetails) {
    if (!associationDetails.AssociatedAccountid || associationDetails.AssociatedAccountid == '') {
      return false;
    } else if (
      !associationDetails.AssociationType || associationDetails.AssociationType == '') {
      return false;
    }
    return true;
  }

  deleteMethod(index: any, $event: any) {
    $event.stopPropagation(); 
    if (index > -1 && index < this.GridData?.length) {
      this.GridData?.splice(index, 1);
      this.GridData = [...this.GridData];
    }
  }

  onTypeSelectChange() {
    this.associationForm.patchValue({
      AssociatedAccountid: '',
      associateOrg: '',
      serviceType: '',
      accountCode: '',
      locationCode: '',
      clientCode: '',
      AllowLabToView: false,
      PCEnabled: false,
      DiagnosisReporting: false,
      accsessionInstrcution: '',
      diagInstrcution: '',
      Selecttype: 'Organizations',
      L2LConfigId: null,
      AccessionInstructions: null,
      LocationCode: null,
      AccountCode: null,
      ClientNumber: null,
      DiagnosisCustomInstructions: null
    });
    this.accessIns = '';
    this.diagIns = '';
    this.selectedGroupAccountsList = {
      Organization: '',
      organizationId: '',
      checked: false,
      Accounts: [...[]]
    };
    this.organizationsList = [];
  }
  
  enableToolTip(e) {
    this.toolTipText=e.scrollWidth > e.clientWidth?e.textContent:'';
  }

  checkForDisabled() {
    return !this.accountsList?.Accounts.some((va: any) => va?.ID != this.templateData?.cardIdentifier && va?.checked)
  }

  getpatchdata() {
    this.associationForm.patchValue({
      AssociationType: '',
      AssociatedAccountid: '',
      associateOrg: '',
      serviceType: '',
      accountCode: '',
      locationCode: '',
      clientCode: '',
      AllowLabToView: false,
      PCEnabled: false,
      DiagnosisReporting: false,
      accsessionInstrcution: '',
      diagInstrcution: '',
      Selecttype: 'Organizations',
      L2LConfigId: null,
      AccessionInstructions: null,
      LocationCode: null,
      AccountCode: null,
      ClientNumber: null,
      DiagnosisCustomInstructions: null
    });
    this.accessIns = '';
    this.diagIns = '';
  }

  getCurrentAssociations(accountID) {
    let query = this.GetQuery('getAllAssociations');
    let queryVariable = {
      accid: accountID,
      associd : null
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalservice.GetData(queryResult).subscribe((Resdata) => {
      if (!Resdata.errors) {
        let data = Resdata.data.submenuData;
        data = data.filter(va => va.AssociationType != 'My Organization')
        if (data.length > 0) {
          this.AccountsAdded = [...data];
          data = data.map(obj => ({ ...obj, L2LConfigId: obj.L2LConfigId === 0 ? null : obj.L2LConfigId }));
          this.addAssociationsOfCurrentLocation(data);
        }
        else {
          this.currLoc["checked"] = false;
          this.snackbar.open("No associations present for current location!", "Close");
        }
      }
      this.ngxService.stop();
    });
    (error) => {
      this.ngxService.stop();
      this.snackbar.open('An error occurred while processing your request', 'Failed');
    };
  }

  fetchCurrentLocationAssociations(checked) {
    let primaryAccountData = this.accountsList.Accounts.find(va => va.ID == this.templateData.cardIdentifier)
    this.finalData =  [...[]];
    this.GridData =  [...[]];
    this.addeddata = [];
    if (!checked) {
      primaryAccountData.checked = false;
      primaryAccountData.disabled = false;
      this.AssociationDetails = []
      this.associationTable = false;
      this.isPreviewOpened = false;
    } else {
      primaryAccountData.checked = false;
      primaryAccountData.disabled = true;
      this.getpatchdata();
      this.addeddata = this.accountsList?.Accounts?.filter((item: any) => item.checked);
      this.getCurrentAssociations(sessionStorage.getItem("AccountID"));
    }
  }


  addAssociationsOfCurrentLocation(data: any) {
    if(data?.length) {
      this.finalData =  [...data];
      this.getDataToDisplayInPreviewGrid();
    }
  }

  resetClickedInForm() {
    this.accountsList?.Accounts?.forEach(va => va.checked =  false);
    let primaryAccountData = this.accountsList.Accounts.find(va => va.ID == this.templateData.cardIdentifier)
    primaryAccountData.checked = true;
    primaryAccountData.disabled = false;
    this.isCurrentLocation = false;
    this.selectedGroupAccountsList = {
      Organization: '',
      organizationId: '',
      checked: false,
      Accounts: [...[]]
    }
    if(this.isPreviewOpened) {
      this.GridData = structuredClone(this.backUpGridData);
    }
    this.getpatchdata();
  }

  showConfigPopup(rowDataValue: any, index: any) {
    let rowData = rowDataValue;
    let originalRowData = structuredClone(rowData);
    let dialogRef = this.dialog.open(ConfigFieldsPopupComponent, {
      disableClose: true,
      width: '80%',
      height : 'auto',
      data:{header : 'Edit Ordering Facility Configuration', rowData : rowData, servicesList: this.servicesList, toolbar: this.toolbar, diagEditor: this.diagEditor, accessEditor: this.accessEditor, task: this.task}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Ok was clicked');
      } else {
        this.GridData[index] = { ...originalRowData };
        this.GridData = [...this.GridData];
      }
    });
  }
}
