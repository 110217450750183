import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { saveAs as importedSaveAs } from "file-saver";
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { Observable, catchError, map, of, shareReplay, tap } from 'rxjs';
import { DialogService } from 'src/app/core/services/dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { MatDialog } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { StaticMessages } from '../../utility/MessageConstants';

@Component({
  selector: 'app-user-bulk-upload',
  templateUrl: './user-bulk-upload.component.html',
  styleUrls: ['./user-bulk-upload.component.scss']
})
export class UserBulkUploadComponent implements OnChanges, OnInit {

  @Input() type: 'users' | 'labusers' | 'referringphy' | 'physician' = 'users'
  @Input() file: any
  @Output() onReturn = new EventEmitter()
  @Input() sessionData: any

  headings: string[] = ['Upload Status', 'Npi', 'Login Name', 'Email', 'First Name', 'Last Name', 'Middle Initials', 'Title', 'Gender', 'Edu Qualification', 'Address 1', 'Address 2', 'City', 'State', 'Zip', 'Phone Number', 'Fax', 'User Code', 'Role', 'Account', 'Ordering Facility', 'Reading Location', 'Licensure']
  dataProperties: string[] = ['ValidationResponse', "NPI", 'LoginName', 'Email', "FirstName", "LastName", "MiddleInitials", "Title", "Gender", "EducationQualification", "Address1", "Address2", "City", "State", "Zip", "Phone", "Fax", "UserCode", "Role", "Account", "OrderingFacility", "ReadingLocation", "Licensure"]
  bulkUploadData: bulkUploadUser[] = []
  isDataInvalid: boolean = false
  userDataToUpload: bulkUploadUser[] = []
  bodyClass: 'bulk-user-body-loc' | 'bulk-user-body' | 'bulk-user-body-ref' = 'bulk-user-body'
  headerClass: 'bulk-user-header-loc' | 'bulk-user-header' | 'bulk-user-header-ref' = 'bulk-user-header'
  warningMessages: string
  reuploadPopupText: string = 'Facility User(s)'
  loading = false;
  entirePageLoader = false;
  states : any = []

  constructor(
    private _labAdminService: LabadminService,
    private _dailogService: DialogService,
    private _snackbar: MatSnackBar,
    private matdialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getStates("state code", "PathologistLicensure")
    if (this.type === 'labusers') {
      this.headings.splice(20, 1)
      this.dataProperties.splice(20, 1)
      this.bodyClass = 'bulk-user-body-loc'
      this.headerClass = 'bulk-user-header-loc'
      this.reuploadPopupText = 'Lab User(s)'
    }
    else if(this.type === 'referringphy' || this.type === 'physician'){
      this.headings.splice(21, 2)
      this.dataProperties.splice(21, 2)
      this.bodyClass = 'bulk-user-body-ref'
      this.headerClass = 'bulk-user-header-ref'

      if(this.type === 'referringphy') this.reuploadPopupText = 'Referring Physician(s)'
      if(this.type === 'physician' ) this.reuploadPopupText = 'Provider(s)'
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.file) {
      this.reloadTable()
    }
  }

  private reloadTable() {
    this.bulkUploadData = []
    this.isDataInvalid = false
    // var formData: any = new FormData()
    // formData.append('file', this.file)
    let dataToPost: any = []
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      //const wsname: string = wb.SheetNames['UsersTemplate'];
      const ws: XLSX.WorkSheet = wb.Sheets['UsersTemplate'];
      let templateFile: any = XLSX.utils.sheet_to_json(ws, { header: 1 });
      if (templateFile.length <= 1) {
        this.isDataInvalid = true
        this.warningMessages = "No Data Available"
      }
      else {
        const fileContent: any = templateFile.splice(1)
        fileContent.forEach((value, i) => {
          if (value.length) {
            let tempData = { 'ValidationMessage': '', 'ValidationResponse': '' }
            value.forEach((value, i) => {
              if(templateFile[0][i].trim() === 'eduqualification') tempData['EducationQualification'] = value.toString().trim()
              else tempData[templateFile[0][i].trim()] = value.toString().trim()
            })
            // tempData['street'] = ''
            // tempData['ssn'] = ''
            // tempData['country'] = ''
            // tempData['upin'] = ''
            if(this.type === 'referringphy'){
              tempData['role'] = 'Referring Physician'
              tempData['readinglocation'] = ''
              tempData['licensure'] = ''
            }
            else if(this.type === 'physician'){
              tempData['role'] = 'Physician'
              tempData['readinglocation'] = ''
              tempData['licensure'] = ''
            }
            dataToPost.push(tempData)
          }
        })
        this.validateData(dataToPost)
      }
      //console.log(templateFile);
      console.log(dataToPost)
    };
    reader.readAsArrayBuffer(this.file);
  }

  validateData(data: any) {
    this.loading = true
    const type = ['referringphy','physician','users'].includes(this.type) ? 'users' : 'labusers'
    try {
      var bulkUserObj = {
        SessionData: {
          LoggedInUserId: this.sessionData.userDetails.userId,
          ApplicationDomain: JSON.parse(this.sessionData.labAminSession.data).domainUrl.split('//')[1],
          LoggedInUserOrgName: this.sessionData.userDetails.orgName,
          LoggedInUserOrgGuid: this.sessionData.userDetails.userOrgGUID,
          LoggedInUserOrdId: this.sessionData.userDetails.organizationId,
          LoginType: this.sessionData.userDetails.loginType
        },
        userBulkUploads: data
      }

      this._labAdminService.ValidateUploadedData(type, bulkUserObj, this._labAdminService.deploymentKey)
        .subscribe({
          next: (res) => {
            if (res.length === 0) {
              this.isDataInvalid = true
              this.warningMessages = "No Data Available"
            }
            else this.bulkUploadData = this.setErrorObject(res)
            this.loading = false
          },
          error: (err) => {
            this.warningMessages = "Unable to upload the file. Please check the template or try after some time."
            this.isDataInvalid = true
            this.loading = false
          }
        })
    } catch (error) {
      this.warningMessages = "Unable to upload the file. Please check the template or try after some time."
      this.isDataInvalid = true
      this.loading = false
    }
  }

  setErrorObject(usersData: bulkUploadUser[]) {
    this.userDataToUpload = [...usersData]
    usersData.forEach(user => {
      if (user.ValidationResponse.toLowerCase() === 'failed') {
        var errorObject = this.getError(user.ValidationMessage)
        user.errorCount = Object.keys(errorObject).length
        this.isDataInvalid = true
        user.filteredValidationMessage = Object.values(errorObject).map((j, i) => `${i + 1}. ${j}`).join('\n\r')

        Object.keys(errorObject).forEach(errorKey => {
          user[`is${errorKey}`] = user[errorKey] ? 'show-error-border' : 'show-error-empty'
        })

      }
      else user.errorCount = 0
    });
    console.log(usersData)
    return usersData
  }

  onReupload() {
    this._dailogService.openUserBulkUploadDailog(`Bulk Upload ${this.reuploadPopupText}`, this.type).afterClosed().subscribe({
      next: (file) => {
        if (file) {
          this.file = file
          this.reloadTable()
        }
      }
    })
  }

  returnToList() {
    if (this.bulkUploadData.length) {
      this._dailogService.openLabadminConfimationdialog('', 'All your changes will be lost. Do you want to proceed?', StaticMessages.OK, StaticMessages.CANCEL).afterClosed().subscribe((res) =>{
        if (res) {
          this.onReturn.emit()
        }
      })
      // let dialogRef = this.matdialog.open(ConfirmLabadminComponent, {
      //   disableClose: true,
      //   width: '360px',
      //   panelClass: 'admin-custom-popup',
      //   data: { header: "", message: "All your changes will be lost. Do you want to proceed?", continue: "OK", cancel: "Cancel" }
      // })
      // return dialogRef.afterClosed().toPromise().then(result => {
      //   if (result) {
      //     this.onReturn.emit()
      //   }
      // })
      // this._dailogService.openLabAdminconfirmDialog('Alert', 'All your changes will be lost. Do you want to proceed?', 'Proceed', 'Cancel').afterClosed().subscribe({
      //   next: (res) => {
      //     if(res) this.onReturn.emit()
      //   }
      // })
    }
    else this.onReturn.emit()
  }


  onSave() {
    try {
      this.userDataToUpload = this.userDataToUpload.map((user : any) => {
        if(user.State){
          const state = this.states.find((i: any) => i.Description == user.State)
          return {...user, State: state.LookupValue, StateDisplayName: `${state.LookupValue} (${state.Description})`}
        }
        else return user
      }
      )
      var bulkUserObj = {
        SessionData: {
          LoggedInUserId: this.sessionData.userDetails.userId,
          ApplicationDomain: JSON.parse(this.sessionData.labAminSession.data).domainUrl.split('//')[1],
          LoggedInUserOrgName: this.sessionData.userDetails.orgName,
          LoggedInUserOrgGuid: this.sessionData.userDetails.userOrgGUID,
          LoggedInUserOrdId: this.sessionData.userDetails.organizationId
        },
        userBulkUploads: this.userDataToUpload
      }
      this.entirePageLoader = true
      const type = ['referringphy','physician','users'].includes(this.type) ? 'users' : 'labusers'
      this._labAdminService.saveUploadedUsers(type, bulkUserObj, this._labAdminService.deploymentKey).subscribe({
        next: (res) => {
          this._snackbar.open('Data Added Successfully.', 'close')
          this.onReturn.emit()
          this.entirePageLoader = false
        },
        error: (err) => {
          console.error(err)
          this._snackbar.open('Somthing Went Wrong.', 'close')
          this.entirePageLoader = false
        }
      })
    } catch (error) {
        this.entirePageLoader = false
        console.error(error)
        this._snackbar.open('Somthing Went Wrong.', 'close')
    }
  }

  private getError(errors: string) {
    var errorObject = {}
    var temperrors = errors.split(';\n\r')
    temperrors.forEach(err => {
      var keyvalue = err.split(':')
      if (errorObject.hasOwnProperty(keyvalue[0])) errorObject[keyvalue[0]] = `${errorObject[keyvalue[0]]}, ${keyvalue[1]}`
      else errorObject[keyvalue[0]] = keyvalue[1]
    })
    return errorObject
  }

  getStates(contextID, lookuptype) {
    try {
      this._labAdminService.getValueFromLookup(contextID, lookuptype, this._labAdminService.deploymentKey).subscribe({
        next: ((res) => this.states = res),
        error: ((err) => console.error(err))
      })
    } catch (error) {
      console.error('Error fetching lookup data:', error);
    }
  }
}

export interface bulkUploadUser {
  ValidationMessage: string
  ValidationResponse: string
  AllowDomains: string
  AutoRegisterDomains: string
  LoginName: string
  Email: string
  Title: string
  FirstName: string
  LastName: string
  MiddleInitials: string
  Gender: string
  NPI: string
  EducationQualification: string
  Address1: string
  Address2: string
  Street: string
  City: string
  State: string
  Zip: string
  Phone: string
  Fax: string
  UserCode: string
  Role: string
  SSN: string
  Country: string
  Upin: string
  Account: string
  OrderingFacility: string
  ReadingLocation: string
  Licensure: string
  errorCount: number
  filteredValidationMessage: string
  isEmail: string
  isFirstName: string
  isLastName: string
  isTitle: string
  isNPI: string
  isUserCode: string
  isLoginName: string
  isRole: string
  isAccount: string
  isReadingLocation: string
  isLicensure: string
  isFax: string
}
