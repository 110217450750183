<div class="modal-header admin-model-header mt-3">
    <h3 class="modal-title w-100 admin-model-header-txt">
        <strong>
            <div>{{action}}</div>
        </strong>
    </h3>
</div>
<div class="col-sm-12">
    <div *ngIf="showDetails ">
        <div class="row">
            <div class="row mt-1 col-sm-6 ">
                <div class="text-xs text-left col-sm-4">
                    <label class="label-font-size">Display Name</label>
                </div>
                <div class="col-sm-8 break-words text-xs">
                    <span title="{{templateData.submenuData[0].Display_Name}} ">{{templateData.submenuData[0].Display_Name}} </span>
                </div>
            </div>
            <div class="row mt-1 col-sm-6 ">
                <div class="text-xs text-left col-sm-4">
                    <label class="label-font-size">Short Name</label>
                </div>
                <div class="col-sm-8 break-words text-xs">
                    <span title="{{templateData.submenuData[0].Short_Name}}">{{templateData.submenuData[0].Short_Name}} </span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="row mt-1 col-sm-6 ">
                <div class="text-xs text-left col-sm-4">
                    <label class="label-font-size">Category</label>
                </div>
                <div class="col-sm-8 break-words text-xs">
                    <span title="{{templateData.submenuData[0].Case_Type_Category}}">{{templateData.submenuData[0].Case_Type_Category}} </span>
                </div>
            </div>
            <div class="row mt-1 col-sm-6 ">
                <div class="text-xs text-left col-sm-4">
                    <label class="label-font-size">Mnemonic</label>
                </div>
                <div class="col-sm-8 break-words text-xs">
                    <span title="{{templateData.submenuData[0].AccountMnemonic}}">{{templateData.submenuData[0].AccountMnemonic}} </span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="row mt-1 col-sm-6 ">
                <div class="text-xs text-left col-sm-4">
                    <label class="label-font-size">Summary display name</label>
                </div>
                <div class="col-sm-8 break-words text-xs">
                    <span title="{{templateData.submenuData[0].SummaryDisplayName}}">{{templateData.submenuData[0].SummaryDisplayName}} </span>
                </div>
            </div>
            <div class="row mt-1 col-sm-6 ">
                <div class="text-xs text-left col-sm-4">
                    <label class="label-font-size">Status</label>
                </div>
                <div class="col-sm-8 break-words text-xs">
                    <span title="{{templateData.submenuData[0].Status}}">{{templateData.submenuData[0].Status}} </span>
                </div>
            </div>
        </div>
        <div class="align-center mt-4">
            <!-- Create new report -->
            <button mat-raised-button class="admin-btn-success" [disabled]="hideUpdateBtn" (click)="editCaseDetails()">Edit</button>
        </div>
    </div>
</div>
<div *ngIf="editScreen" class="mt-2">
    <form [formGroup]="frmEditCaseTypeDetails">
        <div class="text-wrap">
            <mat-form-field appearance="outline" class="w-100 col-sm-3 pt-4">
                <mat-label>
                    <strong> 
                        <!-- <span class="text-danger">*</span> -->
                        Display Name</strong>
                </mat-label>
                <mat-error class="mt-2">{{getErrorMessage(frmEditCaseTypeDetails.value.frmDisplayName,'')}}</mat-error>
                <input type="text" matInput maxlength="50" formControlName="frmDisplayName"
                    [errorStateMatcher]="matcher">
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-100 col-sm-3 pt-4">
                <mat-error class="mt-2">{{getErrorMessage(frmEditCaseTypeDetails.value.frmShortName,'')}}</mat-error>
                <mat-label>
                    <strong> 
                        <!-- <span class="text-danger">*</span> -->
                        Short Name</strong>
                </mat-label>
                <input type="text" matInput maxlength="10" formControlName="frmShortName"
                    [errorStateMatcher]="matcher">
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-100 col-sm-3 pt-4">
                <mat-label>
                    <strong> Mnemonic</strong>
                </mat-label>
                <input type="text" matInput maxlength="10" formControlName="frmMnemonic"
                    [errorStateMatcher]="matcher">
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-100 col-sm-3 pt-4">
                <mat-label>
                    <strong>Category</strong>
                </mat-label>
                <input type="text" matInput maxlength="50" formControlName="frmCategory"
                    [errorStateMatcher]="matcher">
            </mat-form-field>
            <!-- <mat-form-field appearance="outline" class="w-100 col-sm-3">
        <mat-label>
            <strong> <span class="text-danger">*</span>Display Name</strong>
        </mat-label> 
        <input type="text" matInput maxlength="2000" formControlName="frmCategory"
            [errorStateMatcher]="matcher">
    </mat-form-field> -->
            <mat-form-field appearance="outline" class="w-100 col-sm-3 pt-4 pb-4">
                <mat-label>
                    <strong> Summary Display Name</strong>
                </mat-label>
                <input type="text" matInput maxlength="50" formControlName="frmSummary" [errorStateMatcher]="matcher">
            </mat-form-field>
            <span class="col-sm-3">
                <mat-checkbox [color]="task.color" class="mt-2 mr-3" style="float: right" formControlName="frmIsActive">
                    Active</mat-checkbox>   
            </span>
        </div>
        <div class="sub-button mt-4">
            <!-- Create new report -->
            <button mat-raised-button class="admin-btn-success mr-4" (click)="backEditpage()">Cancel</button>
            <button mat-raised-button class="admin-btn-success" type="submit"
                (click)="updateCaseDetails(frmEditCaseTypeDetails.value)">Save</button>
        </div>
    </form>
</div>