<div class="container-fluid m-0 p-0"  [ngClass]="{'enableDialog': (isAlertOpen) }"   >
  <div class="row col-sm-12 m-0 p-0 mb-2 mt-2">
      <div class="col-sm-6 m-0">
        <h3 class="m-0"><strong class="header-View">{{data.title}}</strong></h3>
      </div>
      <div class="col-sm-4 m-0 p-0 mt-2">
        <div class="global-search-panel d-flex">
          <div class="p-1">
              <mat-icon class="search-icon">search</mat-icon>
          </div>
          <div class="standing-line">
          </div>
          <input type="text" class="search-input" [placeholder]="'Search'" (keyup)="filterData($event)">
      </div>
      </div>
      <div class="col-sm-2 m-0 p-0 mt-2 d-flex justify-content-end" style="height: 30px;">
        <app-button buttonclass="secondary" class="edit-page-button mr-2" style="width:70px" [isDisabled]="isAlertOpen" (click)="closeDialog()">Return</app-button>
        <app-button buttonclass="primary" class="edit-page-button mr-2" style="width:70px" [isDisabled]="savedata?.length == 0" (click)="saveUserConfig()">Save</app-button>
      </div>
  </div>
  <div class="col-sm-12 m-0 p-0">
    <div class="col-sm-12 header d-flex py-1 mb-1" *ngIf="gridData?.length">
      <div class="col-sm-12 header-wrap">
          <span [ngClass]="header.colWidth" *ngFor="let header of gridHeader">
              <div class="dataTable">{{header.Name}}</div>
          </span>
      </div>
    </div>
    <div class="container-fluid col-sm-12 header-wrap p-0">
      <div class="col-sm-12 tableScroll">
          <span *ngIf="gridData?.length > 0">
              <div  class="card-data mt-1 ml-0  mb-1" *ngFor="let item of gridData;index as i">
                  <div [ngClass]="header.colWidth" class="text-muted text-ellipsis dataTable" *ngFor="let header of gridHeader">
                      <span *ngIf="header.Name == 'Seq';else otherCol">{{i+1}}</span>
                      <ng-template #otherCol>
                        <span *ngIf="header.Name != 'Accessibility' && header.Name != 'Permission'">{{displayColumns(item,header)}}</span>
                      </ng-template>
                      <span *ngIf="header.Name == 'Accessibility'"><span style="display: inline-block;width: 74px;">{{displayColumns(item,header)?'User Level: ':'Group Level: '}}</span>
                        <app-toggle-switch type="secondary-primary" [isDisable]="isUserLevel" [isChecked] = "displayColumns(item,header)" (clicked)="toggleChanged($event,item,'Allowuserlvl')"></app-toggle-switch>
                      </span>
                      <span *ngIf="header.Name == 'Permission'"><span style="display: inline-block;width: 32px;">{{displayColumns(item,header)?'Edit: ':'View: '}}</span>
                        <app-toggle-switch type="secondary-primary" [isDisable]="isUserLevel || disableToggle(item)" [isChecked] = "displayColumns(item,header)" (clicked)="toggleChanged($event,item,'Allowedit')"></app-toggle-switch>
                      </span>
                  </div>
              </div>
          </span>
          <div class="col-sm-12 nodata-header-wrap" *ngIf="gridData?.length === 0">
              <span class="col-sm-12 nodata-wrapper"> No Data Available </span>
          </div>
          <div class="col-sm-12 nodata-header-wrap" *ngIf="gridData.length === 0  && gridData?.length !== 0">
              <span class="col-sm-12 nodata-wrapper"> No Results </span>
          </div>
      </div>
    </div>
  </div>
</div>