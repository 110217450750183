<app-templates-listing *ngIf="templateData && gridPage" [gridHeader]="gridHeaderList" [gridData]="collectionMethodGridData" [context]="templateData.menuURL.toString().trim()" 
[hideEdit]="editBtn" [hideCreate]="createBtn" [hideUpload]="uploadBtn" [hideExport]="exportBtn" [hideCopy]="copyBtn"
[hideDelete]="true" [templateData]="templateData" [subMenuCardModel]="SubMenuCardModel" 
[saveOrEditCompleted]="saveOrEditCompleted" [showHeaderModule]="true" (saveOrEditEventFromListingTriggered)="recieveObjFromListing($event)"
(closingListTemplate)="handleListingScreen($event)"
(copyTemplateEventTriggered)="loadCopyScreen()" (uploadTemplateEventTriggered)="loadUploadScreen()"
[isAdmin3]="sourceApp =='VitalDx'? false : true" (emitFilters)="emitFilters($event)" [note]="getNote(masterLevel)"
[enableorDisableSeqOnDefault]="true" [exportExcelFields]="sourceApp =='VitalDx' ? exportExcelFields : null">
</app-templates-listing>

<div  class="my-manage-form">
    <app-common-bulk-functions *ngIf="uploadClicked" [templateData]="templateData" (uploadBack)=backClicked($event)
        [cardContext]="cardContext" [columnsToRemove]="['organizationid','accountid']"></app-common-bulk-functions>
</div>

<!-- Copy From Other Entity -->
<div *ngIf="copyDataClicked">
    <div class="modal-header admin-model-header mb-2 mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong>{{action}}</strong>
        </h3>
    </div>
    <form [formGroup]="copycollectionMethodForm" class="copy-collection">
        <div class="row col-sm-12 p-0 ml-0">
            <mat-form-field class="col-sm-4 example-additional-selection mat-height-wrap" appearance="outline">
                <mat-label>Deployment</mat-label>
                <mat-select disableOptionCentering type="text" matInput #searchbar formControlName="frmDepKey">
                    <mat-option class="temp-font-size" *ngFor="let i of DeploymentKeys" value="{{i}}"
                        (onSelectionChange)="onChangeDeployment($event,i)">
                        <span>{{ i }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
                <mat-label>Group </mat-label>
                <input type="text" matInput formControlName="frmOrganization" #searchbar
                (click)="fetchOrgSeries(copycollectionMethodForm.value.frmOrganization)"
                    (keyup)="fetchOrgSeries(copycollectionMethodForm.value.frmOrganization); trimFieldValue('frmOrganization')" [matAutocomplete]="orgauto"
                    placeholder="Search here ..." style="width: 95%;">
                <mat-autocomplete #orgauto="matAutocomplete">
                    <div *ngFor="let show of searchResult">
                        <mat-option class="mat-opt-align"
                            *ngIf="show.orgname.toLowerCase().includes(searchInput.toLowerCase())"
                            title="{{ show.orgname }} ({{show.orgid}})"
                            (onSelectionChange)="selectedCopyDataOrganization($event, show)" value="{{ show.orgname }}">
                            <span>{{ show.orgname }} ({{show.orgid}})</span>
                        </mat-option>
                    </div>
                </mat-autocomplete>
                <button matSuffix mat-icon-button (click)="fetchOrgSeries(copycollectionMethodForm.value.frmOrganization)"><em class="fa fa-chevron-down chevron-align"
                        style="position: relative;margin-bottom: 6px;"></em></button>
                <button *ngIf="copycollectionMethodForm.value.frmOrganization" matSuffix mat-icon-button
                    aria-label="Clear" title="Reset Search" (click)="clearEntity()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <div class="col-sm-4 mb-3 flex-right">
                <button mat-raised-button class="admin-btn-success mt-1 get-height" [disabled]="postUpload"
                    (click)="getMasterLevelData('copy')">Get Global Data</button>
            </div>

            <div *ngIf="gridWidth > 0" class="col-sm-12 mt-4">
                <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData"
                    (initialized)="initGrid(flexgrid,1)" #flexgrid (formatItem)="formateItem(flexgrid, $event)">
                    <!-- Status -->
                    <wj-flex-grid-column [header]="'Status'" [binding]="status" *ngIf="postUpload"
                        [allowResizing]="true" [width]="170" [allowDragging]="true" [allowSorting]="false"
                        [format]="'d'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                            <span
                                [ngStyle]="{color: cell.item.status=='Success' ?'green':cell.item.status=='Ignored' ? '#ff9800' : 'red' }">{{cell.item.status}}
                            </span>
                        </ng-template>
                    </wj-flex-grid-column>

                    <!-- Notes -->
                    <!-- <wj-flex-grid-column [header]="'Notes'" [binding]="notes" [allowResizing]="true" [width]="477"
                        [allowDragging]="false" [allowSorting]="true" [format]="'d'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                            <span
                                [ngStyle]="{color: cell.item.notes == 'Collection Method will be added on approval'|| cell.item.notes=='Collection Method added' || cell.item.notes == 'Extraction Procedure will be added on approval'|| cell.item.notes=='Extraction Procedure added' ? 'green' : cell.item.notes=='Mandatory field is missing!'
                                 || cell.item.notes== 'Extraction Procedure already exists!' || cell.item.notes=='Null values exist!' || cell.item.notes=='Collection Method already exists!'?'red': '#ff9800' }">{{cell.item.notes}}
                            </span>
                        </ng-template>
                    </wj-flex-grid-column> -->

                    <div *ngFor="let columnname of sheetHeader">
                        <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                            [visible]="!(columnname == 'Status')" [allowResizing]="true"
                            [width]="'*'" [allowDragging]="false" [allowSorting]="false">
                        </wj-flex-grid-column>
                    </div>
                    <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                </wj-flex-grid>

                <section class="example-section align-center row">
                    <mat-checkbox [color]="task.color" class="mt-3 example-margin" *ngIf="!postUpload"
                        formControlName="frmcpyasinactive">Copy
                        as Inactive</mat-checkbox>
                </section>
                <div class="col-sm-12 pl-0 mt-4">
                    <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridWidth == 0"
                        (click)="refreshGrid('list')">Back</button>
                    <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridWidth > 0"
                        (click)="removeGrid()">Back</button>
                    <span style="float: right;">
                        <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                            (click)="refreshGrid('list')">Cancel</button>
                        <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
                            (click)="refreshGrid('list')">Finish</button>
                        <button mat-raised-button *ngIf="!postUpload" [disabled]="selectedData.length == 0"
                            class="admin-btn-success mr-4" (click)="approveCollectionMethods(true)">Approve</button>
                        <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                            (click)="ExportExcel(flexgrid)">Download</button>
                    </span>
                </div>
            </div>
            <div class="col-sm-12" *ngIf="gridWidth == 0">
                <div class="container create-btn col-sm-12 align-center" *ngIf="noDataFound">
                    <div class="col-sm-10 nodata-wrap no-data-wraps mt-4">
                        <div class="nodata-design">No Data Found</div>
                    </div>
                </div>
                <button mat-raised-button class="admin-btn-success mt-4" (click)="refreshGrid('list')">Back</button>
            </div>
        </div>
    </form>
</div>

<div *ngIf="showConfirmPopupFlag && sourceApp != 'VitalDx'" class="col-sm-12">
    <div class="modal overlay" id="myModal1" role="dialog">
        <div class="modal-dialog" id="modaldialog1">
            <div class="modal-content">
                <div class="modal-header dialog-header p-0 pt-2">
                    <img src="../../../../assets/icons/user_icons/warning.svg" alt="Warning" class="material-icons md-48">
                    <h3 class="modal-title w-100 headerText">Confirmation</h3>
                </div>
                <div class="modal-body p-0 msg-wrap">
                    <div class="alert-text">
                        <ol>
                        <li style="text-align: start;" *ngIf="showConfirmPopupFlag==1 || showConfirmPopupFlag==2">{{ PopUpmsgs(locations)?.[showConfirmPopupFlag]?.['message'] }}</li>
                        <li style="text-align: start;" *ngIf="caseprofileAlert">{{ PopUpmsgs(locations)?.[3]?.['message'] }}</li>
                        </ol>
                    </div>
                </div>
                <div class="modal-footer">
                      <button mat-flat-button class="nextBtn" (click)="showConfirmPopupFlag = undefined">
                        Cancel
                      </button>
                      <button mat-flat-button *ngIf="showConfirmPopupFlag==2" class="returnBtn" (click)="showConfirmPopupFlag = undefined">
                        Ok
                      </button>
                    <div class="d-flex"  *ngIf="showConfirmPopupFlag==1">
                    <mat-icon role="img" class="align-items-center redirect-icon">launch</mat-icon>
                    <a  class="redirect-btn mr-3" (click)="redirectiontoTabSave('Default Stains')"> Default Stain</a>
                 </div>
                    <div class="d-flex"   *ngIf="caseprofileAlert">
                    <mat-icon role="img" class="align-items-center redirect-icon">launch</mat-icon>
                    <a  class="redirect-btn mr-3"  (click)="redirectiontoTabSave('Case Profiles')"> Case Profile</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


