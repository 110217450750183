<div class="p-2 infodetails">

  <mat-spinner *ngIf="!checkUserDetails()" class="labadmin-spinner" [ngClass]="{'spinner-margin-hide-grid': isHideLocationGrid, 'spinner-margin-show-grid': !isHideLocationGrid}" [diameter]="50">
  </mat-spinner>


  <div class=" col-sm-12 row font-size-color"  *ngIf="checkUserDetails()">
    <div class="col-sm-8 mt-2 pl-4">
      <div class="row mb-2">
        <span class="span-first">VitalAxis ID </span>
        <span class="span-second">: </span>
        <strong class="pl-0 tool-tip span-third" [appTooltipEllipsis]>{{locationDetails?.['UniqueID'] || '-' | uppercase}}</strong>
      </div>
      <div class="row  mb-2">
        <span class="span-first">GUID </span>
        <span class="span-second">: </span>
        <strong class="pl-0 tool-tip span-third" [appTooltipEllipsis]>{{locationDetails?.['GUID'] || '-' | uppercase}}</strong>
      </div>
      <div class="row  mb-2">
        <span class="span-first">NPI</span>
        <span class="span-second">: </span>
        <div>
          <strong class="pl-0 tool-tip span-third" [appTooltipEllipsis]>{{locationDetails?.['NPI'] || '-'}} </strong>
          <span *ngIf="locationDetails?.['viewNPIStatus']" [class]="locationDetails?.['viewNPIStatus']?.toLowerCase()+'_label'">
            {{locationDetails?.['viewNPIStatus'] | uppercase}}
          </span>
        </div>
      </div>
      <div class="row  mb-2">
        <span class="span-first">Mnemonic </span>
        <span class="span-second">: </span>
        <strong class="pl-0 tool-tip span-third" [appTooltipEllipsis]>{{locationDetails?.['Mnemonic'] || '-' | uppercase}}</strong>
      </div>
      <div class="row  mb-2">
        <span class="span-first">CLIA </span>
        <span class="span-second">: </span>
        <strong class="pl-0 tool-tip span-third" [appTooltipEllipsis]>{{locationDetails?.['CLIA'] || '-' | uppercase}}</strong>
      </div>
      <div class="row  mb-2">
        <span class="span-first">LocationCode</span>
        <span class="span-second">: </span>
        <strong class="pl-0 tool-tip span-third" [appTooltipEllipsis]>
          {{locationDetails?.['LocationCode'] || '-' | uppercase}}
        </strong>
      </div>
      <div class="row  mb-2">
        <span class="span-first">ClientNumber </span>
        <span class="span-second">: </span>
        <strong class="pl-0 tool-tip span-third" [appTooltipEllipsis]>
          {{locationDetails?.['ClientNumber'] || '-' | uppercase}}
        </strong>
      </div>
      <div class="row  mb-2" *ngIf="isEnableTimeZoneConversion">
        <span class="span-first">TimeZone </span>
        <span class="span-second">: </span>
        <strong class="pl-0 tool-tip span-third" [appTooltipEllipsis]>   
          {{ getCapitalLetters(locationDetails?.['TimeZone']) }}       
     </strong>
      </div>
      <div class="row  mb-2">
        <span class="span-first">Default Service </span>
        <span class="span-second">: </span>
        <strong class="pl-0 tool-tip span-third" [appTooltipEllipsis]>
          {{locationDetails?.['serviceDetails'] ? (locationDetails?.['serviceDetails'] | titlecase) : '-'}}
        </strong>
      </div>
    </div>
    <div class="col-sm-4 mt-2">

      <div class="row d-block " style="width: 99%;">
        <span class="_600">Primary Contact :</span>

        <div class=" ml-2 tool-tip mt-2 mb-2 " [appTooltipEllipsis]>
          <mat-icon class="vdm_icon">person</mat-icon>
          {{locationDetails?.['PrimaryContactName'] ? (locationDetails?.['PrimaryContactName'] | titlecase) : '-' }}
        </div>
        <div class=" ml-2 tool-tip  mb-2 " [appTooltipEllipsis]>
          <mat-icon class="vdm_icon">email</mat-icon>
          {{locationDetails?.['PrimaryContactEmail'] ? (locationDetails?.['PrimaryContactEmail'] | lowercase) : '-' }}
        </div>
        <div class=" ml-2 tool-tip  mb-2 " [appTooltipEllipsis]>
          <mat-icon class="vdm_icon">phone</mat-icon>
          {{locationDetails?.['PrimaryContactPhone'] || '-'}}
      </div>
      </div>
    </div>
  </div>
</div>
