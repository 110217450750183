import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import { WjFlexGrid } from '@grapecity/wijmo.angular2.grid';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import * as XLSX from 'xlsx';
import { ConfirmComponent } from '../../../base/popup/confirm/confirm.component';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { VitalAdminTabService } from '../../tab.service';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { ChangeDetectorRef } from '@angular/core';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { LabadminSessionService } from '../../../labadmin/services/labadmin-session.service';
import { Store } from '@ngrx/store';
import { LabadminService } from '../../../labadmin/labadmin.service';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { Subscription, take } from 'rxjs';


declare var $: any;
@Component({
  selector: 'app-revise-templates',
  templateUrl: './revise-templates.component.html',
  styleUrls: ['./revise-templates.component.scss']
})
export class ReviseTemplatesComponent implements OnInit {
  @Input()
  templateDataInp: any;
  public caseId;
  private deploymentKey = "";
  showTab = false;
  @Input()
  templateData: any;
  public SubMenuCardModel;
  public DeploymentKeys = [];
  dropdownVal: any;
  public RevData: [];
  dKey: boolean;
  gridheader: string[];
  RevReasonGridData: wjcCore.CollectionView<any>;
  showPaginationMainGrid: boolean;
  showPagination: boolean;
  gridData: wjcCore.CollectionView<any>;
  gridShowDiv: boolean;
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  @ViewChild('grid') gridRef: WjFlexGrid;
  gridWidth: number = 0;
  postUpload: boolean;
  selectedData: any = [];
  selector: Selector = null;
  showGrid: boolean = false;
  templateType: string;
  str = '';
  changeData: any;
  action: string = '';
  currentData: any;
  gridPage: boolean = true;
  addEditScreen: boolean = false;
  uploadBtn: boolean;
  createBtn: boolean;
  editBtn: boolean;
  exportBtn: boolean;
  dragnDrop: boolean;
  copyBtn: boolean;
  excelDataArray: any[] = [];
  showInvalidColumns: boolean = false;
  copyDataClicked: boolean = false;
  bulkUpload: any = false;
  uploadClicked: boolean = false;
  masterLevel: boolean = false;
  rowId: any;
  selectHeader: string;
  addeddata = [];
  AssociationDetails: any = [];
  accountsList: any = [];
  allFields = [{}]
  sampleData = [{
    templateName: 'Name',
    description: 'Description',
  }]
  sampleDataUpdate = [{
    ID: 1234,
    templateName: 'Name',
    description: 'Description',
  }]
  BulkUpdate = [{}]
  workBook: any;
  sheetsToSelect: string[];
  sheetHeader: any[];
  copyFlag: any;
  invalidColumns: any = '';
  noDataFound: boolean = false;
  gridDisplay: boolean;
  showDelete: boolean;
  selectedCopyData: any = [];
  searchResult: any[];
  searchInput: any;
  orgList: any = [];
  organizationList: any;
  saveOrEditCompleted: any;

  columns = [
    new ColDef('Description', 'Description'),
    new ColDef('TemplateName', 'Template Name'),
    new ColDef('OrganizationID', 'OrganizationID'),
    new ColDef('Id', 'Id'),

  ];

  @ViewChild('reasonType') reasonType: MatSelect
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;


  revisionReasonsForm = this._fb.group({
    frmname: "",
    frmdesc: "",
    frmcasetype: ""
  })

  copyRevisionReasonsForm = this._fb.group({
    frmDepKey: ["", Validators.required],
    frmOrgType: ["", Validators.required],
    frmOrganization: ["", Validators.required],
    frmOrgID: [""],

  })
  deleteBtn: boolean = true;
  gridHeaderList: any;
  listedGridHeader: any;
  listHeaderbackup: any;
  ViewScreen: boolean = false;
  receivedObject: any = {};
  auditableColumns: any;
  activityEntity: any;
  userSessionDetails: any;
  sourceApp: string;
  storesub:Subscription = new Subscription();
  expectedHeaders: any = [];
  uploadedHeaders: any = [];
  UpdateColumnMisMatch: boolean = false;
  missingColumn: any = [];
  bulkUpdateFile: boolean = false;
  noteMessage: string;
  dbName: string;

  constructor(private ngxService: NgxUiLoaderService, private datashare: DataShareService,
    private _fb: FormBuilder, public tabService: VitalAdminTabService, public VitalHttpServices: VitalHttpServices, private vitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar, private dialog: MatDialog, public commonService: CommonService, public templateHeaders: TemplateHeadersService,
    private changeDetectorRef: ChangeDetectorRef, private labAdminSessionService: LabadminSessionService,
    private store : Store<{ breadcrum : [] }>,
    private labAdminService: LabadminService,
    private activityService: ActivityTrackerService) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, VitalHttpServices, datashare);
    this.DeploymentKeys = this.VitalHttpServices.DeploymentKeys;
  }

  async ngOnInit() {
    let checkLaunchUrl=window.location.href.split('/#/')[1]
    if(checkLaunchUrl.toString().toLowerCase()!="home"){
      let _this = this;
     await this.labAdminSessionService.getLabAdminSession.pipe(take(1)).subscribe(async session => {
      this.storesub = this.store.select("breadcrum").pipe(take(1)).subscribe(async result => {
        let menuObj: any = result[result.length -1];
        this.storesub.unsubscribe();
       if (session["userDetails"] && Object.keys(session["userDetails"]).length > 1) {
         _this.userSessionDetails = session["userDetails"]
         _this.sourceApp = 'VitalDx';
         menuObj = { ...menuObj , accountLevel : 1};
        _this.userSessionDetails.queryVariables = {    orgid: `${this.userSessionDetails.organizationId}`, type: 'AddendumReason'};
         await _this.commonService.getCommmonTemplateData(menuObj,_this.userSessionDetails);
         _this.templateData=_this.labAdminService.templateData;
         _this.dbName = _this.labAdminService.deploymentKey;
       }
       _this.selectHeader = _this.changeData = "Addendum Reasons";
       _this.addDataToGrid(_this.templateData.submenuData);
       _this.GetButtonAccess(_this.labAdminService.subMenuPermissions)
       this.getAuditableDetails('Revision Reasons')
      this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': 'parentMenu', 'value': 'Lab' },{'key':'Lab Management','value':this.templateData.menuURL}] })
      this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
     })
    });
     }
     else {
      this.dbName = this.vitalHttpServices?.deploymentKey?.toString();
      this.selectHeader = this.changeData = "Addendum Reasons";
      this.addDataToGrid(this.templateData.submenuData);
      this.GetButtonAccess(this.vitalHttpServices.SubmenuAction);
      this.getAuditableDetails(this.templateData.menuURL)

    this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': this.templateData['menuURL'] }] })
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
     }
    // this.getData(this.dropdownVal);
  }

  addDataToGrid(data: any) {
    this.gridHeaderList = this.templateHeaders.templateList;
    for (let i = 0; i < this.gridHeaderList.length; i++) {
      if (this.gridHeaderList[i].templateName == 'RevisionReasons') {
        this.listedGridHeader = this.gridHeaderList[i].headers;
        this.listHeaderbackup = JSON.parse(JSON.stringify(this.listedGridHeader))
      }

    }
    this.gridheader = ['CreatedBy', 'CreatedDate', 'ModifiedBy', 'ModifiedDate', 'Description', 'TemplateName', 'OrganizationID', 'Id', 'TemplateType'];
    let gridarray = []
    let primary = {}
    this.ngxService.start();
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {}
          for (let [key, value] of Object.entries(data[i])) {
            for (let j = 0; j < this.gridheader.length; j++) {
              if (key === this.gridheader[j].toString().replace(/\s/g, "")) {
                if (value == null) {
                  value = '';
                }
                primary[key] = value
              }
            }
          }
          gridarray.push(primary)
        }
        gridarray.sort((a, b) => a.Id < b.Id ? -1 : 1)
      }
      if (gridarray.length > 0) {
        this.gridShowDiv = true;
        // this.hideGrid = false;
        // this.noDataFound = false
      }
      else {
        this.ngxService.stop();
        this.gridShowDiv = false;
        // this.hideGrid = false;
        this.noDataFound = true;
        this.RevReasonGridData = new CollectionView([...[]]);
      }
      if (gridarray.length > 10) {
        this.showPaginationMainGrid = true;
      } else {
        this.showPaginationMainGrid = false;
      }
      this.RevReasonGridData = new CollectionView(gridarray)
      // this.RevReasonGridData = new CollectionView(gridarray, { pageSize: 10 })
      this.showGrid = true
      //   this.gridwidth = (170 * this.gridHeader.length) + 37;
      //   if (this.gridwidth > 1300)
      //     this.gridwidth = 1300;
      // } else {
      //   this.gridShowDiv = (this.templateData && this.templateData.labels && this.gridwidth > 0) ? true : false;
      //   this.gridwidth = 0;
      //   this.noDataFound = true;
      // }
      this.ngxService.stop();
      // this.gridData = new CollectionView(data, { pageSize: 10 })
      // this.gridwidth = (170 * this.templateData.labels.length) + 37;

    }
    else {
      this.ngxService.stop();
      this.gridShowDiv = false;
      this.RevReasonGridData = new CollectionView([...[]]);
      // this.hideGrid = false;
      this.noDataFound = true;
    }
  }
  // test() {
  //     console.log("rest");
  //     this.str = this.changeData;
  // }

  refreshGrid(context?) {
    // this.removeGrid();
    if (this.copyDataClicked == true) {
      this.formGroupDirective.resetForm();
    }
    this.excelDataArray = [];
    this.showInvalidColumns = false;
    this.postUpload = false;
    if(this.copyDataClicked){
    this.copyRevisionReasonsForm.controls.frmOrganization.enable()
    }
    this.copyDataClicked = false;
    this.bulkUpload = false
    //this.resetFormErrors();
    this.uploadClicked = false;
    this.selectHeader = 'Addendum Reasons';
    this.getData(this.selectHeader, context);
    //this.fetchCollectionMethodList(context)
  }

  getData(dropdownVal, context?) {
    let keyword = ''
    keyword = 'getOrganizationReviseTemplatesReason'
    // this.reasonType.value = dropdownVal;
    

    this.selectHeader = dropdownVal
    dropdownVal = dropdownVal.toString().replace(/\s/g, "")
    dropdownVal = dropdownVal.substring(0, dropdownVal.length - 1);
    // dropdownVal = dropdownVal.toString().replace(' ', '');
    // dropdownVal = dropdownVal.lastIndexOf('s')
    let queryVariable = { type: dropdownVal, orgid: this.sourceApp ? this.labAdminService.organizationId.toString() : sessionStorage.getItem('org_id') };
    let query = this.SubMenuCardModel.GetQuery(keyword);
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult,this.dbName).subscribe(data => {
      if (!data.errors) {
        if (data) {
          this.templateData.submenuData = data.data.submenuData;
          if (context) {
            return
          }

          this.templateData.submenuData.length > 0 ? this.addDataToGrid(this.templateData.submenuData) : this.showGrid = true;
          if (!this.templateData?.submenuData?.length) this.RevReasonGridData = new CollectionView(...[]);
          this.gridPage = true;
          this.addEditScreen = false;
        }
      }
    }, error => {

      console.error(error);
    });

  }

  ShowRevReasonList(templateData: object[], dropdownVal: string) {

    this.gridheader = ['Description', 'TemplateName', 'OrganizationID', 'Id'];
    let gridarray = []
    let primary = {}
    this.ngxService.start();
    // this.templateData.some(va=>va.Level != null) ? templateData = [] : null
    if (templateData.length > 0) {
      for (let i = 0; i < templateData.length; i++) {
        primary = {}
        for (let [key, value] of Object.entries(templateData[i])) {
          for (let j = 0; j < this.gridheader.length; j++) {
            if (key === this.gridheader[j]) {
              if (value == null) {
                value = 'Not Specified';
              }
              primary[key] = value
            }
          }
        }
        gridarray.push(primary)
      }
      this.showGrid = gridarray.length > 0 ? true : false;
      // gridarray.sort((a, b) => a.Sequence < b.Sequence ? -1 : 1)
      this.RevReasonGridData = new CollectionView(gridarray, { pageSize: 10 })
      this.showPaginationMainGrid = gridarray.length > 10 ? true : false;
    }
    else this.getData(this.dropdownVal);
    this.ngxService.stop();
  }

  //#region Open upload screen with prerequisite data fetch
  loadUploadScreen() {
    if (this.uploadBtn) {
      this._snackbar.open('User does not have access', "Close");
      return;
    }
    this.uploadClicked = true;
    this.gridPage = false;
    // this.getTemplates()
    this.bulkUpload = true;
    this.gridWidth = 0
    let queryVariable = { tablename: 'OrganizationReviseTemplates', identity: "false", nullable: "false" };
    let query = this.SubMenuCardModel.GetQuery("getTableColumns");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        if (data) {
          for (let item of data.data.allFields) {
            if (!item.Column.match(/OrganizationReviseTemplateGUID/i) && !item.Column.match(/templateType/i) && !item.Column.match(/OrganizationID/i) && !item.Column.match(/Casetype/i) && !item.Column.match(/AdminKey/i)) {
              this.allFields[0][item.Column] = null;
            }
            if (!item.Column.match(/OrganizationID/i) && !item.Column.match(/templateType/i) && !item.Column.match(/Casetype/i) && !item.Column.match(/OrganizationReviseTemplateGUID/i) && !item.Column.match(/AdminKey/i)) {
              this.BulkUpdate[0][item.Column] = null;
            }
            this.BulkUpdate[0]["ID"] = null;
          }
          delete this.allFields[0]['CreatedDate']
          delete this.allFields[0]['CreatedBy']
          delete this.allFields[0]['ModifiedDate']
          delete this.allFields[0]['ModifiedBy']
          delete this.BulkUpdate[0]['CreatedDate']
          delete this.BulkUpdate[0]['CreatedBy']
          delete this.BulkUpdate[0]['ModifiedDate']
          delete this.BulkUpdate[0]['ModifiedBy']
          delete this.BulkUpdate[0]['SourceGUID']
        }
      }
    }, error => {

      console.error(error);
    });

  }
  //#endregion

  downloadTemplate(allFieldsCheck: boolean = false) {
    let filename = ""
    // let dropdownVal = this.selectHeader.toString().replace(/\s/g,'');
    filename = this.selectHeader.toString() + '_' + 'All Fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(this.allFields);
    var wb = XLSX.utils.book_new();
    let sheet1name = this.selectHeader.toString().replace(/\s/g, '') + '_BulkUpload'
    XLSX.utils.book_append_sheet(wb, ws, "" + sheet1name);
    ws = XLSX.utils.json_to_sheet(this.sampleData);
    XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData');
    XLSX.writeFile(wb, filename);
  }

  //Download Bulk Update
  downloadBulkUpdate() {
    // let dropdownVal = this.selectHeader.toString().replace(/\s/g,'');
    let filename = this.selectHeader.toString() + '_' + 'Bulk Update_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(this.BulkUpdate);
    var wb = XLSX.utils.book_new();
    let sheet1name = this.selectHeader.toString().replace(/\s/g, '') + '_BulkUpdate'
    XLSX.utils.book_append_sheet(wb, ws, "" + sheet1name);
    ws = XLSX.utils.json_to_sheet(this.sampleDataUpdate);
    XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData');
    XLSX.writeFile(wb, filename);
  }

  onFileChange(ev) {
    this.bulkUpdateFile = false
    this.UpdateColumnMisMatch = false
    this.missingColumn = []
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if(filename.toLowerCase().includes('bulk update')){
      this.bulkUpdateFile = true
    }
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect.filter(va => va.toLowerCase() != 'sampledata');
          if (this.sheetsToSelect.length > 1) {
            let dropdownVal = this.selectHeader.toString().replace(/\s/g, '');
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == dropdownVal.toLowerCase())
            this.sheetsToSelect.length == 1 ? this.convertToJson(validSheet[0]) : this.openModal();

          }
          else {
            this.convertToJson(this.sheetsToSelect[0])
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }

  convertToJson(sheetname, copyfrmorg: boolean = false, copyData: object[] = []) {
    var worksheet;
    !copyfrmorg ? this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    }) : null;
    this.excelDataArray = [];
    let tempExcelArr = [];
    tempExcelArr = !copyfrmorg ? XLSX.utils.sheet_to_json(worksheet, { defval: null }) : copyData;
    if (tempExcelArr.length == 0) {
      !copyfrmorg ? this._snackbar.open("The uploaded excel does not contain any data", "Close") : null;
      tempExcelArr = [];
      return;
    }
    if (tempExcelArr[0].casetype != null) {
      !copyfrmorg ? this._snackbar.open("Invalid excel", "Close") : null;
      tempExcelArr = [];
      return;
    }   
      this.uploadedHeaders = Object.keys(tempExcelArr[0]);
      this.expectedHeaders = this.bulkUpdateFile ? Object.keys(this.BulkUpdate[0]) : Object.keys(this.allFields[0]);
       if(this.bulkUpdateFile){
          // Check if arrays are of equal length
          if (this.expectedHeaders.length !== this.uploadedHeaders.length || !(this.expectedHeaders.sort().join(',') === this.uploadedHeaders.sort().join(','))) {
            this.UpdateColumnMisMatch = true;
         }
         this.missingColumn = this.expectedHeaders.filter(header => !this.uploadedHeaders.includes(header))
       }else if(!this.bulkUpdateFile && !copyfrmorg){
        if (this.expectedHeaders.length !== this.uploadedHeaders.length || !(this.expectedHeaders.sort().join(',') === this.uploadedHeaders.sort().join(','))) {
          this._snackbar.open("Invalid excel", "Close") 
          return;
        }
       }

    let primary = {}
    let tempArray = []
    if (!this.copyFlag) {
      for (let i = 0; i < tempExcelArr.length; i++) {
        for (let [key, value] of Object.entries(tempExcelArr[i])) {
          if (!key.toString().match(/empty/i)) {
            if ((key.toString().toLowerCase() == 'templatename' || key.toString().toLowerCase() == 'description') && value != null && value != undefined) {
              value = value.toString().trim();
            }
            if (key.toString().toLowerCase() == 'id') {
              value = value == null || value == undefined ? '' : value
            }
            if (value == null) {
              value = null;
            }
            else if (value == '') {
              value = ''
            }
            else if (value.toString().match(/null/i)) {
              value = 'null'

            }
            primary[key] = value;
          }
        }
        let ttype = this.selectHeader.toString().replace(/\s/g, "")
        ttype = ttype.substring(0, ttype.length - 1);
        primary['templatetype'] = ttype
        tempArray.push(primary)
        primary = {}
      }
      tempExcelArr = tempArray;
    }
    this.sheetHeader = [];
    let temp = [];
    temp = !copyData ? XLSX.utils.sheet_to_json(worksheet, { header: 1 }) : Object.keys(tempExcelArr[0]);
    if (!copyData) {
      temp[0].reverse()
      temp[0].push("Notes")
      this.sheetHeader = temp[0];
    }
    else {
      temp.reverse()
      temp.push("Notes")
      this.sheetHeader = temp;
    }
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    // Add the key and value to the primary object in tempArray
    if(this.UpdateColumnMisMatch){
      for(let i=0; i<tempExcelArr.length; i++){
       for(let j=0; j<this.missingColumn.length; j++){
         tempExcelArr[i][this.missingColumn[j]] = '';
       }
      }
    }
    this.excelDataArray = []
    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
    let i = 1;
    this.excelDataArray.find((d): any => {
      Object.assign(d, { slno: i, notes: '', tablename: 'OrganizationReviseTemplates', organizationid: this.templateData.secondarykeys.OrganizationId.toString(), });
      i++;
    });
    this.validateExcel(this.excelDataArray);
  }


  // Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    if (result) {
      this.convertToJson(result)
    }
  }

  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        let value = ""
        value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
        newobj[key.replace(/\s/g, "").toLowerCase()] = value
      }
      array.push(newobj)
    }
    return array;
  }
  formatItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      var value = e.panel.getCellData(e.row, e.col, false);
      let dropdownVal = this.selectHeader.toString().substring(0, this.selectHeader.length - 1);
      if (value) {
        if (value == 'Mandatory field is missing!' || (value.toString().toLowerCase().includes('duplicate')) || value == dropdownVal + 'already exists' || value == 'This record does not exist!' || value == 'Failure'
          || value.toString().includes('Invalid') || value.toString().includes('accepts only') || value.toString().includes('Does not') || value.toString().includes('not exists')|| value === 'Id Missing') {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value == 'Valid' || value == dropdownVal + ' will be added on approval' || value.toString().includes('successfully') || value == dropdownVal + ' will be updated on approval' || value.toString().includes('successfully') || value == 'Success') {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
        else if ((value.toString() == 'Ignored') || (value.toString().toLowerCase().includes('already exist')) || value == dropdownVal + ' already exists, will be added on approval' || value == dropdownVal + ' already exists, will be updated on approval') {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
    }
  }


  validateExcel(excelArr) {
    this.ngxService.start();
    let dataArray = [];
    dataArray = this.convertObjKeysToLower(excelArr);
    this.vitalHttpServices.validateRevisionReasonData(dataArray).subscribe(result => {
      if (!result.errors) {
        if (result.length > 0) {
          this.ngxService.stop();
          if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
            this.showInvalidColumns = true;
            this.invalidColumns = result[0].InvalidColumns;
          }
          for (let i = 0; i < dataArray.length; i++) {
            if (!this.copyDataClicked) {
              if (this.bulkUpdateFile && (this.missingColumn.some(e=> e.toLowerCase() == 'id') || (!dataArray[0].templatename && !dataArray[0].description))) {
                this._snackbar.open("Please provide valid data", 'Failed');
                this.ngxService.stop();
                return;
              }
            }
          }
          let dropdownVal = this.selectHeader.toString().substring(0, this.selectHeader.length - 1);
          dataArray.filter(e => {
            result.filter(r => {
              if (r.SlNo === e.slno) {
                if (!(r.NoteMessage).match(/does not exist/i)) {
                  e.notes = dropdownVal + r.NoteMessage;
                }
                else {
                  e.notes = r.NoteMessage;
                }
              }
            });
          });
          this.AddGridData(dataArray, 1);
        }
        else {
          this._snackbar.open("Please provide valid data", 'Failed');
          this.ngxService.stop()
        }
      }
    }, error => {
      this._snackbar.open("An error occurred while processing your request", 'Failed');
    });
    this.ngxService.stop();
  }

  AddGridData(data, hitCount) {
    for (let i = 0; i < this.excelDataArray.length; i++) {
      if (!this.excelDataArray[i]["status"] || this.excelDataArray[i]["status"] == '') {
        this.excelDataArray[i]["status"] = 'Ignored'
      }
    }
    this.noDataFound = false
    this.copyFlag = null;
    this.gridDisplay = true;
    let gridArray = [];
    this.gridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(data[i])) {
            if (!key.toString().toLowerCase().match(/empty/i) && hitCount == 1) {
              if (value && value.toString().match(/null/i)) {
                value = "NULL"
                primary['notes'] = 'Null values exist!'
              }
              // if ((key == 'templatename' || key == 'description') && (!value || value.toString() == '')) {
              //   value = ''
              //   primary['notes'] = 'Mandatory field is missing!'
              // }
              if ((key == 'templatename' || key == 'description')) {
                if(value){
                  value = value
                }
                else if(this.UpdateColumnMisMatch && this.missingColumn.length > 0){
                  if(this.missingColumn.find(e=> e.toLowerCase() == key.toString().toLowerCase())){
                   value = '';
                  }else{
                    value = ''
                    primary['notes'] = 'Mandatory field is missing!'
                  }
                } 
                else{
                  value = ''
                  primary['notes'] = 'Mandatory field is missing!'
                }
              }
              if(key == 'id' && (!value || (value && value.toString().toLowerCase().trim() == ''))){
                  value = ''
                  primary['notes'] = 'Id Missing'
              }

            }
            primary[key] = value;
          }
          gridArray.push(primary);
        }
        this.excelDataArray = gridArray;
        this.showDelete = true
        if (this.postUpload) {
          this.showDelete = false
          this.selector ? this.selector.column.grid.headersVisibility = HeadersVisibility.Column : null
        }
        this.gridData = new CollectionView(gridArray);
        this.gridWidth = (170 * this.sheetHeader.length) + 37;
        if (this.gridWidth > 1300) {
          this.gridWidth = 1300;
        }
      }
    }
    this.ngxService.stop();
  }

  //#region  Function to delete row from temporary grid
  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    this._snackbar.open('Deleted successfully', 'Close');
    if (grid.rows.length == 0) {
      this.removeGrid();
    }
  }
  //#region  Function to cancel the data selection
  removeGrid() {
    this.gridWidth = 0;
    this.excelDataArray = [];
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    //this.copyRevisionReasonsForm.controls.frmOrganization.enable();
    this.postUpload = false;
    this.noDataFound = false;
    this.showDelete = false;
    // this.fileDropRef.nativeElement.value = "";
    // this.copyRevisionReasonsForm.patchValue({
    //   frmDepKey: sessionStorage.getItem('deploymentKey'),
    //   frmOrgType: "Laboratory",
    //   frmOrganization: "",
    //   frmOrgID: "",
    //   frmcpyasinactive: false
    // })
    let defDep = sessionStorage.getItem('deploymentKey')
    this.copyRevisionReasonsForm.patchValue({
      frmDepKey: defDep.toUpperCase(),
      frmOrganization: ''
    })
    Object.keys(this.copyRevisionReasonsForm.controls).forEach(key => {
      this.copyRevisionReasonsForm.controls[key].setErrors(null)
    });
    // this.resetFormErrors();
    if(this.copyDataClicked){
    if(!this.copyRevisionReasonsForm.controls['frmDepKey'].value){
      this.copyRevisionReasonsForm.controls['frmOrganization'].disable();
    }
    else{
      this.copyRevisionReasonsForm.controls['frmOrganization'].enable();
    }
  }
  }

  approveRevReasons(copyData?) {
    this.copyFlag = copyData;
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      if (!copyData) {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          if (this.excelDataArray[i]["notes"] == "") {
            this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
            return;
          }
          else if (!this.excelDataArray[i]["notes"].toString().match(/approval/i) && !this.excelDataArray[i]["notes"].toString().match(/already exists/i)) {
            errorcount++
          }
        }
      }
      if (errorcount > 0) {
        this._snackbar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
        return
      }
      if ((this.showInvalidColumns)) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          for (let [key, value] of Object.entries(this.excelDataArray[i])) {
            if (!this.bulkUpdateFile && (value === "null" || value === "" || value === null)) {
              this.excelDataArray[i][key] = null; 
            }
            else if(this.bulkUpdateFile){
              if(value === ""){
                this.excelDataArray[i][key] = ''
              }else if(value === null || value === "null"){
                this.excelDataArray[i][key] = null
              }else{
                this.excelDataArray[i][key] = value.toString();
              }
            }
            else {
              this.excelDataArray[i][key] = value.toString();
            }
          }
          if(this.bulkUpdateFile){
            this.excelDataArray[i]["modifiedby"] = !sessionStorage.getItem("Userid") ? -100 : sessionStorage.getItem("Userid")
          }else if(!this.bulkUpdateFile){
            this.excelDataArray[i]["createdby"] = !sessionStorage.getItem("Userid") ? -100 : sessionStorage.getItem("Userid")
          }
        }
      }
      let selectedData = []
      for (let i = 0; i < this.selectedData.length; i++) {
        selectedData.push(this.selectedData[i]._data);
      }
      if (copyData) {
        this.selectedCopyData = selectedData;
        for (let i = 0; i < this.selectedCopyData.length; i++) {
          if (!this.selectedCopyData[i]["notes"].toString().match(/approval/i) && !this.selectedCopyData[i]["notes"].toString().match(/already exists/i)) {
            errorcount++
          }
          if (errorcount > 0) {
            this._snackbar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
            return
          }
        }
        // if (this.copyRevisionReasonsForm.value.frmcpyasinactive == true) {
        //   for (let i = 0; i < this.selectedCopyData.length; i++) {
        //     this.selectedCopyData[i]["isactive"] = false
        //   }
        // }
        for (let i = 0; i < this.selectedCopyData.length; i++) {
          this.selectedCopyData[i]["copyflag"] = true;
        }
        this.excelDataArray = this.selectedCopyData
      }
      let dataArray = [];
      dataArray = this.convertObjKeysToLower(this.excelDataArray);

      // !copyData ? dataArray.some(va => va.notes.toString().toLowerCase().match(/already exists/i)) ? this._snackbar.open("Some selected revision template already exists", "Close") : null : null
      let sendjson = dataArray;
      let context = copyData ? null : ''
      sendjson.length > 0 ? sendjson.some(va => va.id) ? this.editMethod(sendjson, true) : this.addRevReasons(sendjson, true, context) : this.addRevReasons(dataArray, true, context)
    }
  }

  //#region
  addRevReasons(dataArray: object[], bulkUpload: boolean = false, context?) {
    this.ngxService.start();
    this.vitalHttpServices.InsertRevReasons(dataArray,sessionStorage.getItem('deploymentKey')).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        if (this.bulkUpload) {
          this.commonService.auditDetails('', '', [], this.excelDataArray, 'Upload', this.templateData, this.auditableColumns);
        }
        else {
          this.commonService.auditDetails('', '', [], this.excelDataArray, 'Copy', this.templateData, this.auditableColumns);
        }
        if (result.length > 0) {
          this.postUpload = true;
          this.showDelete = false;
          if(this.copyDataClicked && this.postUpload){
          this.copyRevisionReasonsForm.controls['frmOrganization'].disable();
          }
          // this.postDownload = true;
          this.showDelete = false;
          this.masterLevel = false;
          let uploadresult = this.convertObjKeysToLower(result);
          for (let i = 0; i < this.excelDataArray.length; i++) {
            for (let j = 0; j < uploadresult[0].data.length; j++) {
              if (this.excelDataArray[i]['slno'] == uploadresult[0].data[j]['slno']) {
                this.excelDataArray[i]['status'] = uploadresult[0].data[j]['Message'].toString().match(/Created successfully/i) ? 'Success' : 'Ignored'
                this.excelDataArray[i]['notes'] = uploadresult[0].data[j]['notes']
              }
            }
          }
          let dataExistsCount = 0;
          let dropdownVal
          dropdownVal = this.selectHeader.toString().substring(0, this.selectHeader.length - 1);
          for (let i = 0; i < result.length; i++) {
            if (Object.values(result).every(function (item: any) {
              return item.Status == "Ignored" ? true : false
            })) {
              dataExistsCount++;
            }
          }
          this.sheetHeader.push('status');
          this.AddGridData(this.excelDataArray, 0);

          if (dataExistsCount == result.length) {
            if (bulkUpload) { this._snackbar.open(dropdownVal + ' already exists', 'Close') }
            else { this._snackbar.open(dropdownVal + ' already exists', 'Close'); }
            return;
          }
          else {

            let message = bulkUpload ? 'Data uploaded successfully' : this.copyFlag ? 'Revision template copied successfully' : dropdownVal + ' created successfully';
            this._snackbar.open(message, 'Close');
          }

          if (!this.bulkUpload) {
            if (context == '') {
              this.getInputData('', this.templateData.submenuData);
            }
          }
          //context ? this.refreshGrid(context) : this.fetchCollectionMethodList();
        }
        else {
          bulkUpload ? this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close') : this._snackbar.open(this.templateData.menuURL.toString().trim() + ' creation failed! Please try again.', 'Close');
        }
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open('Something went wrong.Please try again', 'Close');
      console.error(error)
    })
  }
  //#endregion

  ExportExcel(flex) {
    let excel = [];
    flex.rows.find(e => {
      delete e._data['slno']
      delete e._data['tablename']
      delete e._data['copyflag']
      excel.push(e._data);
    });
    let dropdownVal
    // dropdownVal = this.selectHeader.toString().replace(/\s/g, "")
    dropdownVal = this.selectHeader.toString()
    dropdownVal = dropdownVal.substring(0, dropdownVal.length - 1);
    let filename = dropdownVal + '_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      excel
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'OrganizationReviseTemplates');
    XLSX.writeFile(wb, filename);
  }

  //Copy from diff entity
  loadCopyScreen() {
    if (this.copyBtn) {
      this._snackbar.open('User does not have access', "Close");
      return;
    }
    this.copyDataClicked = true
    this.gridPage = false;
    this.copyRevisionReasonsForm.patchValue({
      frmDepKey: sessionStorage.getItem('deploymentKey'),
      frmOrgType: "Laboratory",
      frmOrganization: "",
      frmOrgID: ""
    })
    //this.resetFormErrors()
    // this.disableOrgSearch = true;
    // this.destDeployment = sessionStorage.getItem('deploymentKey')
    // this.DeploymentKeys = this.vitalHttpServices.DeploymentKeys
    this.getListOrg();
    if(!this.copyRevisionReasonsForm.controls['frmDepKey'].value){
      this.copyRevisionReasonsForm.controls['frmOrganization'].disable();
    }
  }

  onChangeDeployment(e, depKey) {
    if (e.source.selected) {
      this.copyRevisionReasonsForm.patchValue({
        frmDepKey: depKey,
        frmOrganization: "",
        frmOrgID: "",
      })
      this.gridWidth = 0
      this.searchInput = '';
      this.searchResult = [];
      this.postUpload = false;
      this.excelDataArray = []
      this.getListOrg();
      this.copyRevisionReasonsForm.controls['frmOrganization'].enable();
    }
  }

  getListOrg() {
    // let dbName = this.copyRevisionReasonsForm.value.frmDepKey;
    let queryVariable = {};
    let query = this.SubMenuCardModel.GetQuery("orgtype");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, this.dbName).subscribe(data => {
      if (!data.errors) {
        //this.resetFormErrors();
        if (data) {
          if (data.data.submenuData.length > 0) {
            this.orgList = data.data.submenuData
            this.organizationList = this.orgList.filter(o => o.orgtype.toLowerCase() != 'ordering facilities')
          }
        }
      }
    }, error => {
    });
    this.ngxService.stop();
  }

  fetchOrgSeries(value: string) {
    value = value?.toString().trim();
    this.searchResult = []
    this.copyRevisionReasonsForm.value.frmOrgID = ""
    if (!value.trim()) {
      let copyOrgList=  this.organizationList.slice()
      return (this.searchResult = copyOrgList.splice(0,25));
    }
    let idValidate = /^[0-9]*$/

    if (this.copyRevisionReasonsForm.value.frmOrgType) {
      if (!idValidate.test(value)) {
        if (value.length > 2) {
          this.searchResult = this.organizationList.filter(function (series) {
            if (series && series.orgname != null) {
              return series.orgname.toString().toLowerCase().includes(value.toLowerCase());
            }
          });

          this.searchResult = this.searchResult.splice(0, 25);
        }
      }
      else {
        this.searchResult = this.organizationList.filter(function (series) {
          if (series && series.orgid != null) {
            return series.orgid.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchResult = this.searchResult.splice(0, 25);
      }
      this.copyRevisionReasonsForm.value.frmOrgID = this.organizationList.filter(function (series: any) {
        if (series && series.orgname != null) {
          return series.orgname.toString().toLowerCase() == value.toLowerCase() ? series.orgid : "";
        }
      });
      //this.resetFormErrors()
    }
  }

  selectedCopyDataOrganization(event, data) {
    if (event.source.selected) {
      this.copyRevisionReasonsForm.patchValue({
        frmOrgID: data.orgid,
        frmOrganization: data.orgname
      })
    }
    this.getDataToCopy();
  }

  getDataToCopy() {
    this.postUpload = false;
    if (typeof (this.copyRevisionReasonsForm.value.frmOrgID) == 'object') {
      let frmorgid: any = this.copyRevisionReasonsForm.value.frmOrgID
      this.copyRevisionReasonsForm.value.frmOrgID = frmorgid[0].orgid
    }
    let dropdownVal
    dropdownVal = this.selectHeader.toString().replace(/\s/g, "")
    dropdownVal = dropdownVal.substring(0, dropdownVal.length - 1);
    let queryVariable = { type: dropdownVal.toString(), orgid: this.copyRevisionReasonsForm.value.frmOrgID.toString() };
    let query = this.SubMenuCardModel.GetQuery("getOrganizationReviseTemplatesReason");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, this.copyRevisionReasonsForm.value.frmDepKey).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        let copyData = data.data.submenuData;
        if (copyData.length == 0) {
          this.gridWidth = 0;
          this.noDataFound = true;
          return
        }
        this.noDataFound = false;
        this.copyDataProcess(copyData);
      }
    }, error => {

      this.ngxService.stop();
      console.error(error);
    });
  }

  copyDataProcess(copyData: []) {
    let dataForValidation = []
    for (let i = 0; i < copyData.length; i++) {
      let primary = {}
      for (let [key, value] of Object.entries(copyData[i])) {
        if (key.match(/name/i)) {
          primary['templatename'] = value
        }
        else if (key.match(/description/i)) {
          primary['description'] = value
        }
        delete copyData[i]['status']
      }
      dataForValidation.push(primary);
    }
    this.convertToJson("", true, dataForValidation);
  }


  //Export Revision Reasons list
  exportReviseTemplatesMethodList(grid) {
    if (this.exportBtn) {
      this._snackbar.open('User does not have access', "Close");
      return;
    }
    let excel = [];
    let modifiedExcel: any = [];
    // const view = this.gridRef.collectionView;
    // this.gridRef.beginUpdate()
    // let rows = this.gridRef.rows
    let rows = grid._pgView
    rows.find((e: any) => {
      excel.push(e);
    });

    for (let i = 0; i < excel.length; i++) {
      modifiedExcel[i] = {}
      modifiedExcel[i]["Id"] = excel[i]["Id"]
      modifiedExcel[i]["OrganizationID"] = excel[i]["OrganizationID"]
      modifiedExcel[i]["Template Type"] = this.selectHeader
      modifiedExcel[i]["Template Name"] = excel[i]["TemplateName"]
      modifiedExcel[i]["Description"] = excel[i]["Description"]
      modifiedExcel[i]["CreatedBy"] = excel[i]["CreatedBy"]
      modifiedExcel[i]["CreatedDate"] = excel[i]["CreatedDate"]
      modifiedExcel[i]["ModifiedBy"] = excel[i]["ModifiedBy"]
      modifiedExcel[i]["ModifiedDate"] = excel[i]["ModifiedDate"]
    }
    let dropdownVal
    // dropdownVal = this.selectHeader.toString().replace(/\s/g, "")
    dropdownVal = this.selectHeader.toString();
    dropdownVal = dropdownVal.substring(0, dropdownVal.length - 1);
    let filename = dropdownVal + '_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      modifiedExcel
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + dropdownVal);
    XLSX.writeFile(wb, filename);
    // view.pageSize = oldPgSize;
    // this.gridRef.endUpdate()
  }
  //#endofexport

  initGrid(grid, hitCount?) {
    // this.masterLevel ? grid.headersVisibility = wjcGrid.HeadersVisibility.Column : null
    if (hitCount) {
      if (!this.postUpload) {
        let selectedData = [];
        this.selector = new Selector(grid, {
          itemChecked: () => {
            this.selectedData = grid.rows.filter(r => r.isSelected);
          }
        });
      }
    }
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      const col = e.getColumn(),
        row = e.getRow();
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }

  getInputData(action, data) {
    if (this.createBtn || this.editBtn) {
      this._snackbar.open('User does not have access', "Close");
      return;
    }
    this.action = action;
    if (action.toLowerCase() == 'edit') {
      // this.action = this.dropdownVal == 'Notes'  ? 'Edit ' : 'Edit Collection Method'
      this.currentData = data
      this.revisionReasonsForm.patchValue({
        frmname: this.currentData.TemplateName,
        frmdesc: this.currentData.Description
      })
    }
    else {
      this.revisionReasonsForm.reset();
      //this.action = this.templateData.menuURL.toString().match(/extraction procedure/i) ? 'Create Extraction Procedure' : 'Create Collection Method'
      Object.keys(this.revisionReasonsForm.controls).forEach(key => {
        this.revisionReasonsForm.controls[key].setErrors(null)
      });
    }
    this.gridPage = false;
    this.addEditScreen = true;
  }
  GetButtonAccess(CasemenuAction) {
    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = CasemenuAction.find(e => e.Htext == "Templates").SubMenu.find(va=> va.URL == this.labAdminService.templateData.menuURL)["ActionButton"]
    }
    else {
      seletedMenuPermissions = CasemenuAction.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    }
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.uploadBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Create":
          this.createBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "CopyToOrg":
          this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Copy":
          this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Delete":
          this.deleteBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
      }
    }
  }
  openEditScreen(grid, event?) {
    if (this.editBtn) {
      this._snackbar.open('User does not have access', "Close");
      return;
    }
    // var hti = grid.hitTest(event);
    // let selectedrowData = hti.panel.rows[hti.row].dataItem;
    let selectedrowData = grid;
    // this.selectedrowData = selectedrowData;
    this.getInputData('edit', selectedrowData);
    this.rowId = selectedrowData.Id


  }

  editRevTemplates(data) {
    const { frmname, frmcasetype, frmdesc } = data;
    if (!frmname.toString().trim() || !frmdesc.toString().trim()) {
      this._snackbar.open('Please enter the required fields!', 'Close')
      return
    }
    let dropdownVal
    dropdownVal = this.selectHeader.toString().replace(/\s/g, "")
    dropdownVal = dropdownVal.substring(0, dropdownVal.length - 1);
    let saveData = {
      id: this.rowId,
      organizationid: this.templateData.secondarykeys.OrganizationId,
      casetype: frmcasetype,
      templatetype: dropdownVal,
      templatename: frmname,
      description: frmdesc,
      flag: 1
    }
    //
    if (this.templateData.submenuData.some((va: any) =>
      va.TemplateName && va.TemplateName.toString().toLowerCase().trim() == frmname.toString().toLowerCase().trim() && va.Description && va.Description.toString().toLowerCase().trim() == frmdesc.toString().toLowerCase().trim() && va.Id != this.rowId)) {
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: this.selectHeader, message: "Template with the same name already exists, please enter different template name", alert: "", cancel: "OK" }
      });
      return dialogRef
        .afterClosed()
        .toPromise()
        .then((result) => {
          if (result) {
            this.editMethod([saveData]);
          }
        })
    }
    else {
      this.editMethod([saveData]);
    }
    // .catch(error => { this._snackbar.open("An error occurred while processing your request", "Failed"); console.error(error) })
    // this.ngxService.stop();
  }

  editMethod(saveData, bulkUpdate?: boolean) {
    bulkUpdate ?
      saveData.forEach(element => {
        element.flag = 0
      }) : null;
    this.ngxService.start();
    let dropdownVal = this.selectHeader.substring(0, this.selectHeader.length - 1);
    this.vitalHttpServices.editRevTemplates(saveData,this.dbName).subscribe(response => {
      if (response[0].Success == true) {
        this.commonService.auditDetails('', '', [], this.excelDataArray, 'Bulk Update', this.templateData, this.auditableColumns);
        this._snackbar.open(dropdownVal + " updated successfully", "Close")
        if (!bulkUpdate) { this.refreshGrid(); }
        else {
          if (response.length > 0) {
            this.postUpload = true;
            this.showDelete = false;
            // this.postDownload = true;
            this.showDelete = false;
            this.masterLevel = false;
            let uploadresult = this.convertObjKeysToLower(response);
            for (let i = 0; i < this.excelDataArray.length; i++) {
              for (let j = 0; j < uploadresult[0].data.length; j++) {
                if (this.excelDataArray[i]['slno'] == uploadresult[0].data[j]['slno']) {
                  this.excelDataArray[i]['status'] = uploadresult[0]['message'].toString().match(/updated successfully/i) ? 'Success' : 'Ignored'
                  this.excelDataArray[i]['notes'] = uploadresult[0].data[j]['notes']
                }
              }
            }
            let dataExistsCount = 0;
            for (let i = 0; i < response.length; i++) {
              if (Object.values(response).every(function (item: any) {
                return item.Status == "Ignored" ? true : false
              })) {
                dataExistsCount++;
              }
            }
            this.sheetHeader.push('Status');
            this.AddGridData(this.excelDataArray, 0);

            if (dataExistsCount == response.length) {
              this._snackbar.open(dropdownVal + ' already exist', 'Close');
              return;
            }
            else {
              let message = 'Data updated successfully';
              this._snackbar.open(message, 'Close');
            }
            // if (context != null) {
            // this.fetchSiteList(context);
            // }
            // else {
            // this.getInputData('', this.templateData.submenuData);
            // }

            //context ? this.refreshGrid(context) : this.fetchCollectionMethodList();
          }
          else {
            this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close');
          }
        }
      }
      else {
        this._snackbar.open(dropdownVal + " update failed!", "Close")
      }
      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      console.error(error);
    })
  }



  //create
  createRevTemplates(action, data) {
    const { frmname, frmcasetype, frmdesc } = data;
    if (!frmname || !frmdesc || !frmname.toString().trim() || !frmdesc.toString().trim()) {
      this._snackbar.open('Please enter the required fields!', 'Close')
      return
    }
    let dropdownVal
    dropdownVal = this.selectHeader.toString().replace(/\s/g, "")
    dropdownVal = dropdownVal.substring(0, dropdownVal.length - 1);
    //
    if (this.templateData.submenuData && this.templateData.submenuData.some((va: any) =>
      va.TemplateName.toString().toLowerCase().trim() == frmname.toString().toLowerCase().trim() && va.Description.toString().toLowerCase().trim() == frmdesc.toString().toLowerCase().trim())) {
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: this.selectHeader, message: "Template with the same name already exists, please enter different template name", alert: "", cancel: "OK" }
      });
      return dialogRef
        .afterClosed()
        .toPromise()
        .then((result) => {
          if (result) {
            this.insertRevReasons(dropdownVal, frmname, frmdesc, action);
          }
        })
    }
    else {
      this.insertRevReasons(dropdownVal, frmname, frmdesc, action);
    }
    // .catch(error => { this._snackbar.open("An error occurred while processing your request", "Failed"); console.error(error) })
    // this.ngxService.stop();
  }

  insertRevReasons(dropdownVal, frmname, frmdesc, action) {
    let saveData = {
      id: this.rowId,
      organizationid: this.templateData.secondarykeys.OrganizationId,
      templatetype: dropdownVal,
      templatename: frmname,
      description: frmdesc
    }
    this.ngxService.start();
    this.vitalHttpServices.InsertRevReasons([saveData],sessionStorage.getItem('deploymentKey')).subscribe(response => {
      this._snackbar.open(this.selectHeader.substring(0, this.selectHeader.length - 1) + " " + response[0].Data[0].notes, "Close")
      if (response[0].Success == true) {
        if (this.bulkUpload) {
          this.commonService.auditDetails('', '', [], this.excelDataArray, 'Upload', this.templateData, this.auditableColumns);
        }
        else {
          this.commonService.auditDetails('', '', [], this.excelDataArray, 'Copy', this.templateData, this.auditableColumns);
        }
      }
      if (action == 'close') {
        this.refreshGrid();
      }
      else {
        this.refreshGrid(true);
        this.revisionReasonsForm.reset();
      }
      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      console.error(error);
    })
  }



  auditDetails(oldObj, newObjArray, value) {
    // let oldObject = {}
    if (value.match(/edit/i)) {
      this.ngxService.start();
      this.commonService.createActivityTracker(value, oldObj.ID, this.templateData.menuURL.toString().replace(/\s/g, ""), 'Audit', newObjArray[0], oldObj);
      this.ngxService.stop();
    } else {
      //new record
      this.ngxService.start();
      this.commonService.createActivityTracker(value, newObjArray.ID, this.templateData.menuURL.toString().replace(/\s/g, ""), 'Audit', newObjArray, {});
      this.ngxService.stop();
    }
  }
  handleListingScreen(val: any) {
    if (val == 'closeList') {
      this.ViewScreen = true
    } else if (val = 'openList') {
      this.ViewScreen = false
    }
    this.changeDetectorRef.detectChanges();
  }

  #editStarting
  newEditMethod(editObj: any) {
    this.openEditScreen(editObj.rowData, editObj.event);
  }

  newDeleteMethod(deleteObj: any) {
    this.deleteReasonRow(deleteObj.event, deleteObj.rowData)
  }

  recieveObjFromListing(object: any) {
    this.saveReviseReasons(object)
  }

  saveReviseReasons(data) {
    let clonedData = data;
    data.newData.CaseType = null;

    if (data.newData.Action == 'Create') {
      delete data.newData.Action
      const newObj = {};
      Object.entries(data.newData).forEach(([key, value]) => {
        newObj[key.toLowerCase()] = value;
      });
      this.insertRevReasonsTemplates(newObj);
    }
    else {
      delete data.newData.Action
      data.newData['Id'] = data.oldData['Id'];
      data.newData = { ...data.newData, ...{ 'Flag': 1 } };
      const newObj = {};
      Object.entries(data.newData).forEach(([key, value]) => {
        newObj[key.toLowerCase()] = value;
      });
      this.editMethodTemplate(newObj, clonedData)
    }
  }

  insertRevReasonsTemplates(data) {
    this.ngxService.start();
    this.vitalHttpServices.InsertRevReasons([data],this.dbName).subscribe(response => {
      if(data.templatetype === 'CorrectionReason') this.noteMessage = 'Correction Reason '
      else if(data.templatetype === 'AddendumReason') this.noteMessage = 'Addendum Reason '
      else if(data.templatetype === 'AmendmentReason') this.noteMessage = 'Amendment Reason '
      else this.noteMessage = 'Revision Reason '
      if (response[0].Success == true) {
        if (response[0].Data[0].notes.toString().includes('already exist')) {
          this._snackbar.open(this.noteMessage + response[0].Data[0].notes, "Close")
          this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
          this.ngxService.stop();
          return
        }
        else {
          this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
          this._snackbar.open(this.noteMessage + response[0].Data[0].notes, "Close")
          this.commonService.auditDetails('Id', 'templatename', [response[0].Data[0]], [data], 'Create', this.templateData, this.auditableColumns);
          this.refreshGrid();
        }
      }
      else {
        this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
        this._snackbar.open(this.noteMessage + "update failed!", "Close")
        this.ngxService.stop();
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    })
  }

  editMethodTemplate(saveData, oldObject) {
    this.ngxService.start();
    let dropdownVal = this.selectHeader.substring(0, this.selectHeader.length - 1);
    this.vitalHttpServices.editRevTemplates([saveData],this.dbName).subscribe(response => {
      if (response[0].Success == true) {
        this.refreshGrid();
        this._snackbar.open(dropdownVal + " updated successfully", "Close")
        this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
        this.commonService.auditDetails('Id', 'templatename', [oldObject.oldData], [saveData], 'Edit', this.templateData, this.auditableColumns);
      }
      else {
        this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
        this._snackbar.open(dropdownVal + " update failed!", "Close")
      }
      this.ngxService.stop();
    }, error => {
      this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
      this.ngxService.stop();
      console.error(error);
    })
  }

  deleteReasonRow(flex, rowData): any {
    if (!this.deleteBtn) {
      let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
        disableClose: true,
        width: '400px',
        panelClass: 'admin-custom-popup',
        autoFocus: false,
        data: { header: "", message: "Are you sure you want to delete this record?", continue: "Delete", cancel: "Cancel" }
      })
      return dialogRef.afterClosed().toPromise().then(result => {
        if (result) {
          this.ngxService.start();
          this.vitalHttpServices.deleteRevtemplate(rowData.Id, sessionStorage.getItem("DeploymentKey")).subscribe(data => {
            this.ngxService.stop();
            this.commonService.auditDetails('Id', 'TemplateName', [rowData], [], 'Delete', this.templateData, this.auditableColumns);
            this._snackbar.open('Deleted successfully!', "Close");
            this.refreshGrid();
            this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            this.templateData.submenuData = this.templateData.submenuData.filter(va => va.Id != rowData.Id);
          }, error => {
            console.error(error)
            this.ngxService.stop();
          });
        }
      })
    }
  }

  getAuditableDetails(location: any) {
    this.VitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }

  filterGridData(type: any) {
    this.getData(type);
  }

  trimFieldValue(controlName: any){
    this.commonService.trimFieldValue(this.copyRevisionReasonsForm, controlName ,this.copyRevisionReasonsForm.value.frmOrganization)
  }
}

class ColDef {
  constructor(public binding?: string, public header?: string) { }
}
