import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GridContainerWithCheckBoxComponent } from 'src/app/client/Templates/GridContainerWithCheckBox/GridContainerWithCheckBox.component';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
//import 'src/assets/css/styles.css';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { HomeLayoutComponent } from '../../../common/layout/home-layout.component';
import { response } from 'express';
import { ThemePalette, MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatCheckbox } from '@angular/material/checkbox';


declare var $: any;
declare var jQuery: any;
declare var window: any;

export interface Task {
  color: ThemePalette;
}

@Component({
  selector: 'app-replicate-organization',
  templateUrl: 'replicate-organization.component.html',
  styleUrls: ['./replicate-organization.component.scss']
})

export class ReplicateOrgComponent {
  task: Task = {
    color: 'primary',
  };


  @Input() selecteddefaultdeploymentkey: any;
  @ViewChild('matSelectOptionRoles') matSelectOption: MatSelect;


  public templateData;
  public ChangeStatus: any[];
  public deploymentKeys = [];
  public organizationList = [];
  public roleList = []

  replicateOrgForm = this._fb.group({
    deploymentKey: ['', Validators.required],
    groupName: ['', Validators.required],
    groupId: [0, Validators.required],
    copyLiveUsers : false,
    isAutoOrg : false,
    roles : [new Array<string>(),Validators.required],
  })

  searchType = "";
  searchValue = "";

  showGrid = false;
  ShowUpdateHl7 = false;
  SelectedGridItems: any[] = [];
  subMenuCardModel: SubMenuCardModel
  defaultdeploymentkey: string;
  isbuttonenabled: boolean = false;
  dxUserId: any;
  errorFlag: boolean = false;
  errorMessage: string;
  failedMess: string;

  async ngOnInit() {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    //this.defaultdeploymentkey = this.commonService.getDefaultDeployment()
    if(this.selecteddefaultdeploymentkey && !this.selecteddefaultdeploymentkey.match(/thx/i) && this.selecteddefaultdeploymentkey.toString().toLowerCase() !='all'){
      this.replicateOrgForm.patchValue({
        deploymentKey : this.selecteddefaultdeploymentkey.toString().toUpperCase(),
      })
      try{
        this.ngxService.start();
        await this.fetchOrganizationsForDep(this.selecteddefaultdeploymentkey);
        await this.fetchRoles(this.selecteddefaultdeploymentkey);
        await this.getDxUserid(this.selecteddefaultdeploymentkey);
      }
      catch(error) {
        console.error(error);
        this.replicateOrgFormClose();
      }
      finally{
        this.ngxService.stop();
      }
    }
    else {
      this.replicateOrgForm.controls.groupName.disable();
    }
  }

  constructor(private ngxService: NgxUiLoaderService, public vaservices: VitalHttpServices, private _fb: FormBuilder,
    private commonService: CommonService, private activatedroute: ActivatedRoute, private router: Router,
    public GridwithCB: GridContainerWithCheckBoxComponent, public _snackBar: MatSnackBar, private datashare: DataShareService,
    private homeLayout : HomeLayoutComponent) {
    this.replicateOrgForm.patchValue(
      {
        deploymentKey: "",
      });
    this.deploymentKeys = this.vaservices.DeploymentKeys
    this.deploymentKeys = this.deploymentKeys ? this.deploymentKeys.filter(va=> !va.toString().match(/thx/i)) : [];
    this.subMenuCardModel = new SubMenuCardModel(commonService, vaservices, datashare);
  }

  async fetchOrganizationsForDep(deploymentKey){
    let query = this.vaservices.GetQuery('getLabPOLOrgs');
    let queryVariables = {
      orgType : "Org_Type = 'POL' or Org_Type = 'Laboratories'",
      isLive : "1",
      isActive : "1"
    };
    let queryResult = this.commonService.GetQueryResult(queryVariables,query);
    await this.vaservices.GetData(queryResult,deploymentKey).toPromise().then(response=>{
      if(!response.errors && response.data){
        this.organizationList = response.data.submenuData;
      }
      else {
        this.replicateOrgFormClose();
        this._snackBar.open('Error occured in fetching groups','Close');  
      }
    },error=> {
      console.error(error);
      this.replicateOrgFormClose();
      this._snackBar.open('Error occured in fetching groups','Close');
    });
  }

  async fetchRoles(deploymentKey){
    let query = this.vaservices.GetQuery('getuserrolesforassociationtype');
    let queryVariable = { "associationtype": "associationtype = 'Both'" };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.vaservices.GetData(queryResult, deploymentKey).toPromise().then(async res => {
      if (!res.errors && res.data.vwGetRolesBasedOnAccountAssociation_list) {
          this.roleList = res.data.vwGetRolesBasedOnAccountAssociation_list.map(va=> ({...va, selected : false }))
      }
      else {
        this.replicateOrgFormClose();
        this._snackBar.open('Error occured in fetching roles','Close');  
      }
    },error=> {
      console.error(error);
      this.replicateOrgFormClose();
      this._snackBar.open('Error occured in fetching roles','Close');
    });
  }

   //on changes deployment key
   async onChangeDeployment(e, deploymentKey) {
    if (e.source.selected) {
      this.clearSearch();
      this.clearSearch(1);
      this.replicateOrgForm.patchValue({
        copyLiveUsers : false ,
        isAutoOrg : false,
        roles : new Array<string>(),
      })
      this.errorFlag = false;
      try{
        this.ngxService.start();
        this.replicateOrgForm.controls.groupName.enable();
        await this.fetchOrganizationsForDep(deploymentKey);
        await this.fetchRoles(deploymentKey);
        await this.getDxUserid(deploymentKey);
      }
      catch(error) {
        console.error(error);
        this.replicateOrgFormClose();
      }
      finally{
        this.ngxService.stop();
      }
    }
  }


  async assignGroupId(e,organization){
    if (e.source.selected) {
      this.replicateOrgForm.patchValue({
        groupId : organization.Org_ID
      })
      this.errorFlag = false;
    }
  }

  clearSearch(rolesDrpDwn?,event?){
    if(event)
    event.stopPropagation();
    
    if(rolesDrpDwn){
      this.replicateOrgForm.patchValue({
        roles : new Array<string>()
      })
    }
    else {
      this.replicateOrgForm.patchValue({
        groupName : '',
        groupId : 0
      })
    }
  }


  filterGroups(searchVal){
    if(!searchVal)
    return this.organizationList;
    else 
    return this.organizationList.filter(organization => organization.Org_Name.toString().toLowerCase().trim().includes(searchVal.toString().toLowerCase().trim()));
  }


  filterRoles(searchVal){
    if(!searchVal)
    return this.roleList;
    else 
    return this.roleList.filter(role => role.displayname.toString().toLowerCase().trim().includes(searchVal.toString().toLowerCase().trim()));
  }



  allRolesChecked(){
    return this.roleList.length === this.replicateOrgForm.value.roles.length;
  }

  someRolesChecked(){
    return this.replicateOrgForm.value.roles.length ? true : false;
  }


  toggleAllSelection(event : MatCheckbox){
    this.errorFlag = false;
    let checked = event.checked;
    if (checked) {
        this.matSelectOption.options.forEach((item: MatOption) =>{
          item.select();
        });
      } else {
        this.matSelectOption.options.forEach((item: MatOption) => item.deselect());
      }
  }

  async replicateOrg(){
    if(this.errorFlag){
      return;
    }
    if(!this.dxUserId)
    {
      this._snackBar.open("User is not authorized to perform this operation.","Close");
      return; 
    }

    const {
      deploymentKey,
      groupId,
      copyLiveUsers,
      roles,
      isAutoOrg
    } = this.replicateOrgForm.value;


    if(deploymentKey == '' || !groupId || (copyLiveUsers && !roles.length))
    return;
    	let inputParameters = {
        sourceOrgId : groupId.toString(),
        createdBy : this.dxUserId.toString(),
        copyLiveUsers : copyLiveUsers ? "1" : "0",
        rolesList : roles.join(','),
        isTestOrg : isAutoOrg ? "0" : "1"
      }

    let inputJson = 
    {
      "deploymentKey" : deploymentKey,
      "jobType": "CopyOrg",
      "inputParameters": JSON.stringify(inputParameters),
      "emailTo" : localStorage.getItem("UserEmail"),
      "createdBy": localStorage.getItem("UserEmail")
    }

    this.failedMess = "";
    try{
      this.ngxService.start();
      await this.vaservices.saveVitalAdminScheduledJobDataRepo(inputJson,'configdb').toPromise().then(response=> {
        if(response.content){
          if(response.content.status)
          {
            this._snackBar.open('Request to replicate a group has been initiated.','Close');
            this.replicateOrgFormClose();
          }
          else {
            this.errorFlag = true;
            if(response.content.errorReason.toString().toLowerCase().includes('exists'))
              this.errorMessage = "Request to replicate the selected group is in the queue";
            else if (response.content.errorReason.toString().toLowerCase().includes('progress'))
              this.errorMessage = "Request to replicate the selected group is being processed";
            else 
              {
                this.failedMess = "Denied!"
                this.errorMessage = "Replica of selected group is already available, it cannot be replicated more than once.";
              }
          }
        }
        else { 
          this._snackBar.open('Request to replicate a group failed','Close');
        }
      });
    }
    catch (error) {
      console.error(error);
    }
    finally{
      this.ngxService.stop();
    }
  }

  replicateOrgFormClose() {
    this.replicateOrgForm.reset();
    // $('#HL7LogsModel').modal('hide');
    $('#genericModal').modal('hide');
    this.replicateOrgForm.patchValue(
      {
        deploymentKey: "",
      });
    this.homeLayout.SelectedMenuModel = false;
  }


  async getDxUserid(deploymentKey){
    let query = this.vaservices.GetDxUseridBasedOnDeployment();
    // this.ngxservice.start();
    await this.vaservices.GetData(query,deploymentKey).toPromise().then(res=> {
      if(res.data.submenuData && res.data.submenuData.length){
        this.dxUserId = res.data.submenuData[0].userid;
      }
    },error=> {
      console.error(error);
    });
  }
 
  checkFieldSelection(): boolean {
    const { deploymentKey, groupName, roles, copyLiveUsers } = this.replicateOrgForm.controls;
  
    if (copyLiveUsers.value) {
      return !deploymentKey.value || !groupName.value || !roles.value.length;
    } else {
      return !deploymentKey.value || !groupName.value;
    }
  }
  
  copyCheck(event : MatCheckbox){
    if(!event.checked){
      this.replicateOrgForm.patchValue(
        {
          roles: new Array<string>()
        });
    }
  }


}
