import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { LabUsersService } from 'src/app/core/utility/lab-users.service';
import { searchFilterPipetemplate } from 'src/app/core/utility/tripfilter.pipe';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import * as XLSX from 'xlsx';
import { MatDialog } from '@angular/material/dialog';
import { LockUnlockUserDialogComponent } from 'src/app/base/popup/lock-unlock-user-dialog/lock-unlock-user-dialog.component';
import { relative } from 'path';
import { LabadminComponent } from '../../../labadmin.component'
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { ExtAttributeConstants, Scope } from 'src/app/labadmin/components/share/utility/ex_attribute _constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from 'src/app/core/services/dialog.service';
import { userStatusUpdateMessage, userStatusUpdateSnackbarMessage, StaticMessages } from 'src/app/labadmin/components/share/utility/MessageConstants';
import { convertLocalDateTime, ConvertStatus, getStateName, menuUrl, toPascalCase, toYesNo } from 'src/app/labadmin/components/Utility Functions';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { UtilityServiceService } from 'src/app/labadmin/components/share/service/utility-service.service';
@Component({
  selector: 'app-labusersgrid',
  templateUrl: './labusersgrid.component.html',
  styleUrls: ['./labusersgrid.component.scss'],
  providers: [searchFilterPipetemplate],
})
export class LabusersgridComponent {
  templatefilter: object = {};

  @Input() SelectValue;
  @Input() gridData;
  @Input() gridHeaders;
  @Input() searchtext;
  @Input() type: string = '';
  @Input() noDataAvaliable:false;

  @Input() fromClientUser = false;
  @Input() showNPIStatus = false;
  //@Input() showloader = true;
  loginType: string = '';
  @Output() selectedCard = new EventEmitter<any>();
  @Output() reloadData = new EventEmitter<boolean>();

  filename: string = '';
  userSessionDetails: any;
  tooltip: string = '';
  showloader: boolean = false;
  activityEntity: any;

  gridHeaderList: {
    templateName: string;
    headers: { Name: string; type: string; key: string; colWidth: string }[];
  }[];
  isEmailDisabled: boolean = false;
  isLockDisabled: boolean = false;
  isGeneratePwd: boolean = false;
  isUnlockDisabled: boolean = false;
  isApprovePassword: boolean = false
  sourceApp = 'VitalDx';
  toolTipText: any;
  ActiveImage: string = '/assets/icons/Role_Active.svg'
  InactiveImage: string = '/assets/icons/Role_Inactive.svg'
  loggedInUserOrganizationName: any;
  loggedInUserOrganizationGUID: any;
  ApplicationDomain: any;
  loggedInUserOrgId: any;
  deploymentKey: any;
  loggedInUserName: string
  // isNPIValidationEnabled: boolean
  staticMessages = StaticMessages

  constructor(
    public templateHeaders: LabUsersService,
    public VitalHttpServices: VitalHttpServices,
    public labAdminService: LabadminService,
    private searchfiltersTemplate: searchFilterPipetemplate,
    private labAdminSessionService: LabadminSessionService,
    private LabadminComponent: LabadminComponent,
    private _router: Router,
    public dialog: MatDialog,
    private _snackbar: MatSnackBar,
    private _dailogService: DialogService,
    public activityService: ActivityTrackerService,
    private _utilityService: UtilityServiceService,
  ) {
  }

  ngOnInit(): void {
    this.labAdminSessionService.getLabAdminSession.subscribe((session: any) => {
      if (session && session['userDetails']) {
        this.userSessionDetails = session['userDetails'];
        this.loginType = this.gridData[0]?.logintype?.toLowerCase() || '';
        this.loggedInUserOrganizationName = this.userSessionDetails.orgName;
        this.loggedInUserOrganizationGUID = this.userSessionDetails.userOrgGUID;
        this.ApplicationDomain = JSON.parse(session.labAminSession.data).domainUrl.split('//')[1]
        this.loggedInUserOrgId = this.userSessionDetails.organizationId
        this.deploymentKey = this.userSessionDetails.deploymentKey ?? this.deploymentKey
        this.loggedInUserName = this.userSessionDetails.formattedDisplayName
        // if (this.type == 'Users' || this.type == 'Providers' || this.type == 'Referring Physicians' || this.type == 'labusers') {
        //   this.isEnableNPIValidationEnabled(this.loggedInUserOrgId.toString(), ExtAttributeConstants.EnableNPIvalidation, Scope.Scope_2)
        // }
      } else {
        this.LabadminComponent.logOut();
      }
    });
    this.GetButtonAccess(this.labAdminService.rbacPermissions);
    this.setActivitySession()
  }

  ngOnChanges() {
    this.filtertemplates();
  }

  // RBAC
  GetButtonAccess(actionButtonDetails) {

    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      if (this.type == 'labusers') {
        seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == "Lab")["SubMenu"]
        seletedMenuPermissions = seletedMenuPermissions.find(e => e.URL == "Users")["ActionButton"]
      }
      else if (this.type == 'Users' || this.type == 'Providers' || this.type == 'Referring Physicians') {
        seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == "Clients")["SubMenu"]
        seletedMenuPermissions = seletedMenuPermissions.find(e => e.URL == this.type)["ActionButton"]
      }
      else if (this.type == 'Clients') {
        seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == "Clients")["ActionButton"]
      }
      try {
        for (let i = 0; i < seletedMenuPermissions?.length; i++) {
          switch (seletedMenuPermissions[i].Button.toLowerCase()) {
            case 'emailsent':
              this.isEmailDisabled = seletedMenuPermissions[i].IsPermitted === "false"
              break;
            case 'lock':
              this.isLockDisabled = seletedMenuPermissions[i].IsPermitted === "false"
              break;
            case 'unlock':
              this.isUnlockDisabled = seletedMenuPermissions[i].IsPermitted === "false"
              break;
            case 'generatepassword':
              this.isGeneratePwd = seletedMenuPermissions[i].IsPermitted === "false"
              break;
            case 'approvepassword':
              this.isApprovePassword = seletedMenuPermissions[i].IsPermitted === "false"
              break;
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  filtertemplates() {
    if (this.gridHeaders != undefined && this.gridHeaders != null && this.gridHeaders.length > 0) {
      for (let i = 0; i < this.gridHeaders.length; i++) {
        if (this.fromClientUser) {
          this.templatefilter[this.gridHeaders[i].key] = this.searchtext;

        }
        else {
          this.templatefilter[this.gridHeaders[i].key] = this.searchtext;
        }
      }
      // this.gridData=this.searchfiltersTemplate.transform(this.gridData,this.templatefilter)
    }
  }

  displayedColumns(item: any, header: any) {

    if (header.key.trim().toLowerCase() == 'npi' && item[header.key].trim().toLowerCase() == 'unknown') {
      return '';
    }
    return item[header.key]
  }

  /**
   * grid data export
   */
  ExportExcel() {
    if (this.labAdminService.fromGlobalSearch) {
      let downloadExcelObj = {
        "Clients": "Clients Details",
        "Users": "Clients Users",
        "labusers": "Lab Users",
        "Providers": "Clients Providers",
        "Pathologist": "Pathologists",
        "Referring Physicians": "Clients Referring Physician"
      }
      this.filename = downloadExcelObj[this.labAdminService.selectedDropdown];
    }
    else {
      let excelFileName = {
        'Clients': 'Clients Details',
        'Users': 'Clients Users',
        'Providers': 'Clients Providers',
        'Referring Physicians': 'Clients Referring Physician',
        'labusers': 'Lab Users'
      }
      this.filename = excelFileName[this.type];

    }
    let expotData=this.searchfiltersTemplate.transform(this.gridData, this.templatefilter);
    if(this.filename=='Clients Details')
    {
      expotData=expotData.map((i:any)=>{
        let obj={
          // 'OF Organization ID':i.organizationId,
          // 'OF Account Id':i.ofAccountId,
          'Ordering Facility Name':i.facilityName,
          'Address':i.address,
          'Owner Lab':i.ownerlab,
          'City':i.city,
          'State':getStateName(i.state),
          'Phone Number':i.phoneNumber,
          'Fax Number':i.faxNumber,
          'Location Code':i.locationCode.toString(),
          'Status':ConvertStatus(i.status),
          'NPI':i.npi,
          'NPI Status':i.npiStatus,
          'Modified By':i.modifiedBy,
          'Modified Date':convertLocalDateTime(i.modifiedDate),
          'Created By':i.createdBy,
          'Created Date':convertLocalDateTime(i.createdDate)
        }
        return obj

      })
    }
    else
    {
      expotData=expotData.map((i:any)=>{
        let obj={
          //'User ID':i.userid.toString(),
          'Name':i.name,
          'Login ID':i.loginId,
          'Email ID':i.emailId,
          'Phone Number':i.phoneNumber,
          'Roles':i.roles,
          'Primary Role':i.roles?.split(',')[0],
          'Account Locked Out':toYesNo(i.isAccountLockedOut), //
          'Workgroups':i.departments, 
          'Auth Status':i.authstatus,
          'Status':ConvertStatus(i.status),
          'Allow Login':toYesNo(i.allowlogin),
          'NPI':i.npi,
          'NPI Status':i.npiStatus,
          'Modified By':i.modifiedBy,
          'Modified Date':convertLocalDateTime(i.modifiedDate),
          'Created By':i.createdBy,
          'Created Date':convertLocalDateTime(i.createdDate),
          'Last Logged In Date':convertLocalDateTime(i.lastLoggedInDate)
        }
        return obj

      })
    }
    let filename = this.filename + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(expotData);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, this.filename);
    XLSX.writeFile(wb, filename);
  }

  // RedirectToEditOrg(item, header){
  //   if(this.fromClientUser){
  //     if(header.key.toLowerCase() === 'facilityname'){
  //       //return item['orderingFacilityName']
  //     }
  //     if(header.key.toLowerCase() === 'ownerlab'){
  //       //return item['ownerlab']
  //       this._router.navigate(['labadmin','editclient',item['OfOrgId']])
  //     }

  //   }
  // }

  // lock and unlock user account
  openDialog(item: any) {

    // lock and unlock should not allow for inactive users
    if (item.status.trim().toLowerCase() !== 'active') {
      this._dailogService.openWarningPopup(item.isAccountLockedOut ? 'Unlock User Account' : 'Lock User Account', 'Activate the user to make the changes.', item.isAccountLockedOut ? '../../../../assets/icons/user_icons/Unlocked.svg':'../../../../assets/icons/user_icons/Locked.svg')
      // this.dialog.open(LockUnlockUserDialogComponent, {
      //   width: '30vw',
      //   data: {
      //     imagePath: item.isAccountLockedOut ? '../../../../assets/icons/user_icons/Unlocked.svg' : '../../../../assets/icons/user_icons/Locked.svg',
      //     header: item.isAccountLockedOut ? 'Unlock User Account' : 'Lock User Account',
      //     message: 'Activate the user to make the changes.',
      //     buttonname: 'OK',
      //     type: "mail-sent"
      //   }
      // })
      return;
    }

    this.dialog.open(LockUnlockUserDialogComponent, {
      width: '30vw',
      disableClose: false,
      autoFocus: false,
      data: {
        imagePath: item.isAccountLockedOut ? '../../../../assets/icons/user_icons/Unlocked.svg' : '../../../../assets/icons/user_icons/Locked.svg',
        header: item.isAccountLockedOut ? 'Unlock User Account' : 'Lock User Account',
        row1value: item.name,
        row2value: item.isAccountLockedOut ? "Locked" : "Unlocked",
        loginid: item.loginId,
        userid: item.userid,
        commonkey: item.commonKey,
        row1text: 'User Name',
        row2text: 'Status',
        buttonname: item.isAccountLockedOut ? "Unlock User" : "Lock User",
        type: 'lockunlock-user',
        status: item.isAccountLockedOut,
        message: 'Activate the user to make the changes.',
        loggedInUserId:this.userSessionDetails.userId
        
      }
    }).afterClosed().subscribe((result) => {
      if (result && result.userid) {
        // Find the index of the item in gridData with the matching loginId
        const indexToUpdate = this.gridData.findIndex(e => e.userid == result.userid);
        if (indexToUpdate !== -1) {
          // Update the isAccountLockedOut property of the found item
          this.gridData[indexToUpdate].isAccountLockedOut = result.status;
          this._utilityService.LockUnlockUser(item.userid, item.name, this.loggedInUserName, this.type, result.status)
        }
      }
    });
  }
  // find npi number is number or not
  isNpiKnown(item: any): boolean {
    return item.npi.toLowerCase() !== 'unknown' && item.npi !== '' && item.npi !== null && item.npi != undefined;
  }

  passwordGenerateDialog(item: any) {

    // inactive user should not be allowed to send mail
    if (item.status.trim().toLowerCase() !== 'active') {
      this._dailogService.openWarningPopup('Reset Password', 'Activate the user to reset password.')
      // this.dialog.open(LockUnlockUserDialogComponent, {
      //   width: '30vw',
      //   data: {
      //     imagePath: '../../../../assets/icons/user_icons/warning.svg',
      //     header: 'Reset Password',
      //     message: 'Activate the user to reset password.',
      //     buttonname: 'OK',
      //     type: "mail-sent"
      //   }
      // })
      return;
    }

    // unlock user should not be allowed to reset password
    if (item.isAccountLockedOut) {
      this._dailogService.openWarningPopup('Reset Password', 'Unlock the user to reset password.')
      // this.dialog.open(LockUnlockUserDialogComponent, {
      //   width: '30vw',
      //   data: {
      //     imagePath: '../../../../assets/icons/user_icons/warning.svg',
      //     header: 'Reset Password',
      //     message: 'Unlock the user to reset password.',
      //     buttonname: 'OK',
      //     type: "mail-sent"
      //   }
      // })
      return;
    }

    this.showloader = true;
    // login id is already verified need to send reset password link
    // if (this.emailVerify(item)) {

    //   let obj = {
    //     email: item.emailId,
    //   }
    //   this.labAdminService.sendResetPwdLink(obj).subscribe((res) => {
    //     this.showloader = false;
    //     this.dialog.open(LockUnlockUserDialogComponent, {
    //       width: '30vw',
    //       data: {
    //         imagePath: '../../../../assets/icons/user_icons/warning.svg',
    //         header: 'Reset Password',
    //         message: 'Reset Password link sent. check your email to proceed.',
    //         buttonname: 'OK',
    //         type: "mail-sent"
    //       }
    //     });
    //   }, (error) => {
    //     this.showloader = false;
    //     console.error(error);
    //   });
    //   return;
    // }

    // login id is not verified will renerate the new password
    this.labAdminService.generatePassword(item.loginId, this.userSessionDetails.userId, this.labAdminService.deploymentKey).subscribe((data) => {
      this.showloader = false;
      this._utilityService.generatePassword(item.userid, item.name, this.loggedInUserName, this.type)
      this.dialog.open(LockUnlockUserDialogComponent, {
        width: '30vw',
        autoFocus: false,
        data: {
          imagePath: '../../../../assets/icons/user_icons/Regenerate_Password2.svg',
          header: 'Reset Password',
          row1value: item.loginId,
          row2value: data[0],
          loginid: item.name,
          userid: item.userid,
          row1text: 'User Name',
          row2text: 'Password',
          buttonname: 'Copy',
          type: "generate-password",
          message: 'Reset Password link sent. check your email to proceed.',
          loggedInUserId:this.userSessionDetails.userId
        }
      });
    }, (error) => {
      this.showloader = false;
      console.error(error);
    });
  }

  emailType(item: any): boolean {
    if (this.loginType?.trim().toLowerCase() == 'email' && !['verified','autoverified'].includes(item?.authstatus?.trim().toLowerCase()) && item.allowlogin) {
      this.tooltip = 'Send Invitation';
      return true;
    }
    this.tooltip = '';
    return false;
  }

  sendResetPassswordLink(item: any): boolean {     
      return !item.isadenabled && item.authstatus && ['verified','autoverified'].includes(item?.authstatus?.trim().toLowerCase()) && this.loginType && this.loginType.trim().toLowerCase() == 'email' && item.allowlogin
  }

  // mail send for invitation
  mailSent(item: any) {

    // inactive user should not be allowed to send mail
    if (item.status.trim().toLowerCase() !== 'active') {
      this._dailogService.openWarningPopup('Send Invitation', 'Activate the user to send invitation mail.', '/assets/icons/user_icons/email_invitation.svg')
      // this.dialog.open(LockUnlockUserDialogComponent, {
      //   width: '30vw',
      //   data: {
      //     imagePath: '../../../../assets/icons/user_icons/email_invitation.svg',
      //     header: 'Send Invitation',
      //     message: 'Activate the user to send invitation mail.',
      //     buttonname: 'OK',
      //     type: "mail-sent"
      //   }
      // })
      return;
    }

    // unlock user should not be allowed to send mail
    if (item.isAccountLockedOut) {
      this._dailogService.openWarningPopup('Send Invitation', 'Unlock the user to send invitation mail.', '/assets/icons/user_icons/email_invitation.svg')
      // this.dialog.open(LockUnlockUserDialogComponent, {
      //   width: '30vw',
      //   data: {
      //     imagePath: '../../../../assets/icons/user_icons/email_invitation.svg',
      //     header: 'Send Invitation',
      //     message: 'Unlock the user to send invitation mail.',
      //     buttonname: 'OK',
      //     type: "mail-sent"
      //   }
      // })
      return;
    }

    if (!item.emailId) {
      this._dailogService.openWarningPopup('Email Required', StaticMessages.CONFIGURE_EMAIL_MESSAGE)
      // this.dialog.open(LockUnlockUserDialogComponent, {
      //   width: '30vw',
      //   data: {
      //     imagePath: '../../../../assets/icons/user_icons/warning.svg',
      //     header: 'Configure Email',
      //     message: StaticMessages.CONFIGURE_EMAIL_MESSAGE,
      //     buttonname: 'OK',
      //     type: "mail-sent"
      //   }
      // })
      return;
    }

    this.showloader = true;
    this.labAdminService.getAllowAndAutoRegisterDomains(item.orgGUID, this.labAdminService.deploymentKey).subscribe({
      next: ((res : {allowdomains: string, autoregisterdomains: string}) => {
        try {            
        const domain = item.emailId.split('@')[1]
        if(res.autoregisterdomains && res.autoregisterdomains.split(';').includes(domain)){
          this._dailogService.openWarningPopup('Invalid Email ID', StaticMessages.AUTO_REGISTER_DOMAIN)
          this.showloader = false
          return
        }
        else if(!res.allowdomains || res.allowdomains.split(';').includes(domain)){
          this.migrateUser(item.emailId, item.userid, item.name)
        }
        else{
          this._dailogService.openWarningPopup('Invalid Email ID', StaticMessages.ALLOW_DOMAINS+' '+res.allowdomains.split(';').join(', '))
          this.showloader = false
          return
        }
        } catch (error) {
          this.onError(error)
        }
      }),
      error: ((err) => {
        this.onError(err)
      })
    })
    
    //}
  }

  RedirectToView(value) {
    this.selectedCard.emit(value);
  }

  enableToolTip(e, item: any = '', header: any = '') {
    let title: any;
    if (item || header) {
      title = this.displayedColumns(item, header);
    }
    this.toolTipText = e.scrollWidth > e.clientWidth ? title : '';
  }
  ActivateOrInactivateUsers(userId: any) {
    const primaryRoleIndex = this.gridData.findIndex(i => i.userid == userId)
    if (primaryRoleIndex != -1) {
      var SavedUserId = userId,
        modifiedStatus = this.gridData[primaryRoleIndex].status.toLowerCase() == 'active' ? 'inactive' : 'active',
        RoleDisplayName = this.gridData[primaryRoleIndex].name,
        rolename = this.gridData[primaryRoleIndex].roles.split(',')[0]

      const status = modifiedStatus === 'active' ? 'Activate' : 'Deactivate'
      this._dailogService.openLabadminConfimationdialog(
        '',
        userStatusUpdateMessage(status, rolename, RoleDisplayName),
        status,
        'Cancel'
      ).afterClosed().subscribe((res) => {
        if (res) {
          const obj1 = {
            deleteUser: [{
              UserID: String(SavedUserId),
              VFrom: 'user',
              AccountID: '',
              MedicalDirectorToUpdate: '',
              Status: modifiedStatus,
              ModifiedBy:this.userSessionDetails?.userId?.toString()
            }],
            sessionData: {
              ApplicationDomain: this.ApplicationDomain,
              LoggedInUserOrgName: this.loggedInUserOrganizationName,
              LoggedInUserOrgGuid: this.loggedInUserOrganizationGUID,
              LoggedInUserOrdId: this.loggedInUserOrgId,
              accountsOrgGuid: '',
            }

          }
          this.showloader = true;
          this.labAdminService.ActiveInActiveDeleteUsers(obj1, Number(this.loggedInUserOrgId), this.deploymentKey).subscribe({
            next: (data) => {
              this.reloadData.emit(true);
              //var status = modifiedStatus === 'active' ? 'Activated' : 'Inactivated'
              this._snackbar.open(userStatusUpdateSnackbarMessage(`${status}d`, rolename, true), StaticMessages.CLOSE)
              this.gridData[primaryRoleIndex] = { ...this.gridData[primaryRoleIndex], status: modifiedStatus }
              this.filtertemplates();
              this._utilityService.activateInactivateUser(SavedUserId, RoleDisplayName, this.loggedInUserName, this.type, status, rolename, true)
              this.showloader = false;
            },
            error: (err) => {
              console.error(err)
              this.showloader = false;
              this._snackbar.open(StaticMessages.ON_ERROR, StaticMessages.CLOSE)
            }
          })
        }
      })
    }
  }

  approvePassword(user) {
    this.showloader = true;
    this.labAdminService.GetUserDetails(user.userid, this.deploymentKey).subscribe({
      next: ((res) => {
        if(!this.isEnableResetPassword(res.AuthStatus ?? '', res.AllowLogin, user.isadenabled)){
          this.showloader = false;
          const message = user.isadenabled ? `This user should use their ${user.vendorname} password to login.` : StaticMessages.LOGIN_THROUGH_DOMAIN_PASSWORD
          this._dailogService.openWarningPopup('Approve Password', message, '/assets/icons/user_icons/email_invitation.svg')
          return;
        }
        try {
          this.showloader = false;
          let obj = { loginId: res.LoginName, userId: user.userid, facilityAddress: res.Address1 ? res.Address1 : res.Address2, username: user.name, firstName: res.FirstName, lastName: res.LastName, type: this.type, loggedInUserName: this.loggedInUserName}
          this._dailogService.openApprovePasswordPopup(obj, this.userSessionDetails.userId).afterClosed().subscribe({
            next: ((res) => {
              console.log('closed')
            }),
            error: ((err) => console.error(err))
          })
        } catch (error) {
          this.onError(error)
        }
      }),
      error: ((err) => {
        this.onError(err)
      })
    })
  }

  isEnableResetPassword(authStatus: string, allowlogin: boolean, isadenabled: boolean) : boolean{
    return !isadenabled && (this.loginType?.trim()?.toLowerCase() !== 'email' || (!['verified','autoinitiated','autoreinitiated','autoverified'].includes(authStatus.trim().toLowerCase()) && allowlogin))
  }

  sendResetLink(item : any){
    if(item.emailId && item.emailId.trim().length > 0){
      this.sendResetLinkToEmail(item.emailId, item.name, item.userid)
    }
    else{
      this._dailogService.openWarningPopup('Email Required', StaticMessages.CONFIGURE_EMAIL_MESSAGE)
      // this.dialog.open(LockUnlockUserDialogComponent, {
      //   width: '30vw',
      //   data: {
      //     imagePath: '../../../../assets/icons/user_icons/warning.svg',
      //     header: 'Email Link to Reset Password',
      //     message: StaticMessages.CONFIGURE_EMAIL_MESSAGE,
      //     buttonname: 'OK',
      //     type: "mail-sent"
      //   }
      // })
      return;
    }
  }

  sendResetLinkToEmail(emailId: string, username: string, userid: string){
    this.showloader = true
       let obj = {
        email: emailId,
      }
      this.labAdminService.sendResetPwdLink(obj).subscribe({
        next: ((res) => {
        this.showloader = false;
        if(res.Success){
          var content = JSON.parse(res.Message)
          this._dailogService.openWarningPopup('Reset Password', content.message, '/assets/icons/user_icons/email_invitation.svg', content.email, content.phone)
          this._utilityService.sendResetPasswordLink(userid, username, this.loggedInUserName, this.type)
        }
        else this.onError(res.Message)
      }),
      error: (error) => {
        this.showloader = false;
        console.error(error);
      }
      });
      return;
    }

    onError(err){
      console.error(err)
      this.showloader = false
      this._snackbar.open(StaticMessages.ON_ERROR, 'Close', {
        horizontalPosition: 'end'
      });
    }

    migrateUser(emailId: string, userid: string, username: string){
      let requestData = [];
    let data = {
      Email: emailId,
      UserID: userid,
      OrganizationID: this.userSessionDetails.organizationId
    }
    requestData.push(data);
    this.labAdminService.migrateUser(requestData, this.labAdminService.deploymentKey).subscribe({
      next: (d) => {
      this.showloader = false;
      this._dailogService.openWarningPopup('Send Invitation', 'Email Invitation sent successfully to ' + emailId, '/assets/icons/user_icons/email_invitation.svg')
      this._utilityService.migrateUser(userid, username, this.loggedInUserName, this.type, emailId)
      }, 
      error: (error) => {
        this.showloader = false;
        console.error(error);
      }
    });
    }

    setActivitySession(){
      const menuurl = menuUrl(this.type)
      this.activityService.setActivitySession({ 'entityId': '', 'entityType': menuurl, 'context': [{ 'key': 'parentMenu', 'value': menuurl }] })
      this.activityService.getActivitySession.subscribe(res => this.activityEntity = res)
    }

    // isEnableNPIValidationEnabled(entityId: string, extAttrName: string, extAttrtype: string) {
    //   this.labAdminService.GetVAExtAttrValue(entityId, extAttrName, extAttrtype, this.deploymentKey).subscribe({
    //     next: (data: any) => {
    //       this.isNPIValidationEnabled = data === 'true' ? true : false;
    //     }
    //     , error: ((err: any) => {
    //       console.error(err)
    //     }
    //     )
    //   })
    // }
}
