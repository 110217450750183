<div class=" row container-fluid py-2 px-3">
  <app-shimmerui *ngIf="showloader" [line]="2" class="w-100" ></app-shimmerui>
  <ng-container *ngIf="!showloader">
    <div class="col-sm-2 pr-0" *ngIf=" ViewType !== 'small'">
      <div class="brand_logo">
        <img src="../../../../assets/images/idealsLabs.png" alt="" width="90" height="60">

        <div class="action_btns align-center">
          <div class="actions_icon"> <i class="fa fa-download" aria-hidden="true"></i> </div>
          <!-- <div class="actions_icon"> <i class="fa fa-exchange" aria-hidden="true"></i> </div>
          <div class="actions_icon"> <i class="fa fa-trash" aria-hidden="true"></i> </div> -->
        </div>

      </div>
    </div>
    <div [ngClass]="  ViewType !== 'small' ?'col-sm-10':'col-sm-12'">
      <div class="row">
        <div class="col-sm-7">
          <!-- <span class="text-sm"> Provider</span>  -->
          <span class="roleborder mx-2"></span>
          <strong class="username"> {{userDetials['FormattedDisplayName']}}</strong>
        </div>
        <div class="col-sm-5 d-flex justify-content-end align-items-center" style="gap: 13px">
          <app-shimmerui *ngIf="loadActionButtons" [line]="1" class="w-100" ></app-shimmerui>
          <ng-container *ngIf="!loadActionButtons">   
             
           <!-- Email Sent -->
           <span class="cursor  action-icons" (click)="mailSent(userDetials)"
             *ngIf="emailType(userDetials)">
             <img class="email-invite"
               [ngClass]="{'action-button-disabled': isEmailSent,'':true}"
               src="../../../../../assets/icons/lab_users_icons/email.svg" matTooltip='Send Invitation'>
            </span>

            <span class="cursor action-icons" [ngClass]="{'action-button-disabled':showloader || isApprovePassword, '':true}" (click)="approvePassword(userDetials);$event.stopPropagation();" *ngIf="isEnableResetPassword(userDetials.AuthStatus ?? '', userDetials.AllowLogin)">
              <img src="/assets/images_vla/approve_password.svg" matTooltip="Approve Password" height="23px">
            </span>

            <!-- Reset Password -->
           <span class="action-icons" style="width: 33px" *ngIf="isEnableResetPassword(userDetials.AuthStatus ?? '', userDetials.AllowLogin)">
             <img src="../../../../../assets/icons/lab_users_icons/generate_password.svg" style="cursor: pointer;"
             [ngClass]="{'action-button-disabled': isGeneratePwd,'':true}" (click)="passwordGenerateDialog(userDetials)"
             matTooltip="Reset Password">
           </span>

          <span *ngIf="sendResetPassswordLink(userDetials)" class="cursor action-icons" (click)="sendResetLink(userDetials);$event.stopPropagation();" [ngClass]="{'action-button-disabled':showloader || isGeneratePwd, '':true}">
              <img class="email-invite" src="../../../../../assets/icons/lab_users_icons/email.svg" matTooltip="Send Reset Password Link"></span>

           <!-- Lock User -->
           <span class="action-icons cursor"  *ngIf="islocked">
             <img style="transform: scale(0.9);"
             [ngClass]="{'action-button-disabled': isLockDisabled,'':true} "
             src="../../../../../assets/icons/lab_users_icons/lock_user.svg"
             (click)="openDialog(userDetials)" matTooltip="Locked User">
           </span>

          <!-- UnLock User -->
          <span class="action-icons cursor" *ngIf="!islocked">
            <img style="transform: scale(0.9);"
             [ngClass]="{'action-button-disabled':isUnlockDisabled,'':true}" (click)="openDialog(userDetials)" src="../../../../../assets/icons/lab_users_icons/Unlock.svg" matTooltip="Unlocked User">
          </span>
          
          <span class=" cursor-pointer" *ngIf="userRoles.length" [matTooltip]="isUserActive()? staticMessages.ACTIVATE_USER : staticMessages.DEACTIVATE_USER">
            <img (click)="ActivateOrInactivateUsers()" matRipple [src]="isUserActive()?ActiveImage:InactiveImage">
          </span>

          <button 
            class="Vdm-btn cursor" (click)="editUser()">
            <span> <i class="fa fa-pencil mr-1" aria-hidden="true"></i></span> Edit</button>
          </ng-container>
        </div>
      </div>
      <div class="mb-1 ml-2">
        <strong class="text-uppercase _600 pl-3" style="font-size: 11px;"> {{userDetials['Address']}}</strong>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="row ml-1 px-4">
            <div class="icon_label">
              <mat-icon class="vdm_icon">mail</mat-icon>
              <strong class="icon-value ml-1">{{userDetials['Email'] || '-'}} </strong>
            </div>
          </div>

          <div class="row ml-1 d-flex justify-content-between px-4 mt-2">
            <div class="icon_label">
              <mat-icon class="vdm_icon">phone</mat-icon>
              <strong class="icon-value ml-1">{{userDetials['Phone'] || '-'}} </strong>
            </div>
            <div class="icon_label">
              <img src="/assets/images_vla/Fax.svg" alt="fax" class="vdm_icon mr-1">
              <strong class="icon-value ml-1">{{userDetials['Fax'] || '-'}}</strong>              
              
            </div>
          </div>

        </div>

        <div class="col-sm-6 text-right" style="font-size: 10px;">
          <!-- <div>
            <span> Verified By</span>
          </div>
          <div>
            <span class="text-muted"> 10-27-2023 10:38 PM</span>
          </div> -->
        </div>

      </div>
    </div>
</ng-container>


</div>