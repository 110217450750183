import { Component, Input, OnInit } from '@angular/core';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcCore from '@grapecity/wijmo';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
import { concatMap, of, take, filter } from 'rxjs';
import { Store } from '@ngrx/store';
import { DialogService } from 'src/app/core/services/dialog.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';


@Component({
  selector: 'app-clinical-information',
  templateUrl: './clinical-information.component.html',
  styleUrls: ['./clinical-information.component.scss']
})
export class ClinicalInformationComponent implements OnInit {

  @Input() templateData: any;
  attributeList: any
  listedGridHeader: any = [];
  resultMessage: string = ''
  organizationId: number = 0;
  loggedinUserId: number = 0;
  deploymentKey: string = '';
  createBtn: boolean = false;
  editBtn: boolean = false;
  exportBtn: boolean = false;
  copyBtn: boolean = false;
  deleteBtn: boolean = false;
  sourceApp: string = '';
  saveOrEditCompleted: any;
  userSessionDetails: any;
  gridData: wjcCore.CollectionView<any>;
  auditableColumns: any;
  activityEntity: any;
  loading:boolean = false;
  exportExcelFields = {
    Attribute_Name: { displayName: "Attribute Name", isDate: false },
    Attribute_Description: { displayName: "Attribute Description", isDate: false },
    Sequence_Order: { displayName: "Sequence", isDate: false },
    Created_By: { displayName: "Created By", isDate: false },
    CreatedDate: { displayName: "Created Date", isDate: true },
    Modified_By: { displayName: "Modified By", isDate: false },
    Modified_Date: { displayName: "Modified Date", isDate: true },
  }

  constructor(
    private _vitalHttpServices: VitalHttpServices,
    private _labadminService: LabadminService,
    private _templateHeaders: TemplateHeadersService,
    private _ngxService: NgxUiLoaderService,
    private _snackbar: MatSnackBar,
    private _labAdminSessionService: LabadminSessionService,
    private _dailogService: DialogService,
    private store: Store<{ breadcrum: [] }>,
    public commonService: CommonService,
    private _activityService: ActivityTrackerService
  ) {

  }

  ngOnInit(): void {
    let checkLaunchUrl = window.location.href.split('/#/')[1];
    if (checkLaunchUrl.toString().toLowerCase() !== 'home') {
      this._labAdminSessionService.getLabAdminSession
        .pipe(
          concatMap((session: any) =>
            this.store.select('breadcrum').pipe(
              take(1),
              concatMap((i: any) => {
                this.userSessionDetails = session['userDetails'];
                this.sourceApp = 'VitalDx';
                return this.commonService.getCommmonTemplateData(i[i.length-1], this.userSessionDetails,'submenu', true).then(data=>data);
              })
            )
          )
        )
        .subscribe((data) => {
          this.templateData = this._labadminService.templateData;
          this.deploymentKey = this._labadminService.deploymentKey;
          this.loggedinUserId = this.userSessionDetails.userId;
          this.organizationId=this.userSessionDetails.organizationId;
          this.getclinicalInformationValues()
          this.GetButtonAccess(this._labadminService.subMenuPermissions)
          this.getAuditableDetails(this.templateData.menuURL)
          this._activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': 'Lab' }, { 'key': 'Lab Management', 'value': this.templateData['menuURL'] }] })
          this._activityService.getActivitySession.subscribe(res => this.activityEntity = res);
          this.activityEntity.entityId = '';
          this._activityService.setActivitySession(this.activityEntity);
        });
      }
    else{
      this.setLocalVariables()
      this.getclinicalInformationValues()
      this.GetButtondetails();
      this.getAuditableDetails(this.templateData.menuURL)
      this._activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': this.templateData['menuURL'] }] })
      this._activityService.getActivitySession.subscribe(res => this.activityEntity = res);
    }
  }

  GetButtondetails() {
    this.GetButtonAccess(this._vitalHttpServices.SubmenuAction);
  }

  GetButtonAccess(actionButtonDetails:any) {
    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == "Templates").SubMenu.find(va=> va.URL =='Clinical Information')["ActionButton"]
    }
    else {
      seletedMenuPermissions = this._vitalHttpServices.SubmenuAction.find(
        (e) => e.Htext == this.templateData.headerText
      )['SubMenu'].find((ele) => ele.URL == this.templateData.menuURL)[
        'ActionButton'
      ];
    }
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      if (seletedMenuPermissions[i].Button === "Export") {
        this.exportBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
      else if (seletedMenuPermissions[i].Button === "Create") {
        this.createBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
      else if (seletedMenuPermissions[i].Button === "Edit") {
        this.editBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
      else if (seletedMenuPermissions[i].Button === "Delete") {
        this.deleteBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
    }
  }

  setLocalVariables() {
    this.organizationId = Number(sessionStorage.getItem('org_id'))
    this.loggedinUserId = Number(sessionStorage.getItem('Userid'))
    this.deploymentKey = sessionStorage.getItem('DeploymentKey')
  }

  listData(templateData: any[]) {
    let primary = {}
    let headerData = this._templateHeaders.templateList.find(i => i.templateName == "Clinical Information")
    if (headerData) {
      if (headerData) {
        this.listedGridHeader = headerData.headers
      }
    }
    let gridarray = [];
    let gridheader = ['Attribute_ID', 'Attribute_Name', 'Attribute_Description', 'Sequence_Order','Created_By','CreatedDate','Modified_By','Modified_Date']
    for (let i = 0; i < templateData?.length; i++) {
      primary = {};
      for (let [key, value] of Object.entries(templateData[i])) {
        if (key == 'Attribute_Name' || key == 'Attribute_Description') {
          if (value == '' || value == null) {
            value = 'Not Specified';
            primary[key] = value;
          }
          primary[key] = value;
        }
        for (let j = 0; j < gridheader.length; j++) {
          if (key === gridheader[j]) {
            primary[key] = value;
          }
        }
      }
      gridarray.push(primary);

    }
    gridarray.sort((a, b) => a.Attribute_Name.localeCompare(b.Attribute_Name))
    this.gridData = new CollectionView(gridarray)
  }

  newDeleteMethod(event: any) {
    this._ngxService.stop();
    this._dailogService.openLabadminConfimationdialog(
      '',
      'Are you sure you want to delete this record?',
      'Delete',
      'Cancel'
    ).afterClosed().subscribe((result)=>{
      if (result) {
        let  obj =  {
          "AttributeName" :null,
          "AttributeDescription": null,
          "OrganizationId": this.organizationId,
          "AttributeType": "ClinicalInformationTemplate",
          "AttributeID": event.rowData.Attribute_ID
      }
        this.AddEditDelClinicalInfoTemplate(obj, "2",this.loggedinUserId, event)
      }
      else return

    })
  }

  recieveObjFromListing(event: any) {
    let obj = {}
    let ioper = ''
    if (event.newData.Action == 'Edit') {
      obj =  {
        "AttributeName" :event.newData.Attribute_Name,
        "AttributeDescription": event.newData.Attribute_Description,
        "OrganizationId": this.organizationId,
        "AttributeType": "ClinicalInformationTemplate",
        "AttributeID": event.oldData.Attribute_ID,
        "Sequence":event.newData.Sequence_Order
    }

      ioper = '1'
    }
    else {
      obj =  {
        "AttributeName" :event.newData.Attribute_Name,
        "AttributeDescription": event.newData.Attribute_Description,
        "OrganizationId": this.organizationId,
        "AttributeType": "ClinicalInformationTemplate",
        "AttributeID": 0
    }
      ioper = '0'
    }
    this.AddEditDelClinicalInfoTemplate(obj, ioper,this.loggedinUserId,event)

  }

  AddEditDelClinicalInfoTemplate(TemplateDetails: any, iOper: string,loggedinUserID: number,event: any = {}) {
    this._ngxService.start()
    let sessionId = this.commonService.generateGuid();
    this._labadminService.AddEditDelClinicalInfoTemplate(TemplateDetails, iOper,loggedinUserID,this.deploymentKey).subscribe({
      next: (result: any) => {
        this._ngxService.stop()
        if(result)
        {
          if (!result.includes('Duplicate')) {
            this.getclinicalInformationValues()
            if (event?.newData?.Action === 'Create') {
              const seq = this.attributeList.filter((i)=>i.Attribute_Name ==TemplateDetails.AttributeName)
              event.newData = {
                ...event.newData,
                TemplateSequence: seq[0]?.Sequence_Order
            };

              let successObj = [{ 'Attribute_Name': TemplateDetails.AttributeName }]
              this.commonService.createActivityObject(`${TemplateDetails.AttributeName}`,`${TemplateDetails.AttributeName}`, this.templateData.menuURL, 'Create', event.newData, {}, sessionId,this.auditableColumns)
            }
            else if (event?.newData?.Action === 'Edit') {
              this.commonService.createActivityObject(`${TemplateDetails.AttributeName}`,`${TemplateDetails.AttributeName}`, this.templateData.menuURL, 'Edit', event.newData, event.oldData, sessionId,this.auditableColumns)
            }
            else if (event?.action === "delete")
              this.commonService.createActivityObject(`${TemplateDetails.AttributeName}`,event.rowData.Attribute_Name, this.templateData.menuURL, 'Delete', {}, event.rowData, sessionId,this.auditableColumns)



            this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            this._snackbar.open(result, "Close")
          }
          else {
            this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
            this._snackbar.open(result, "Close")
          }
        }
      },
      error: (err) => {
        console.error(err)
        this._snackbar.open('Something went wrong.', "Close")
        this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
        this._ngxService.stop()
      }
    })

  }

  getclinicalInformationValues() {
    this.loading=true;
    let obj = {
      organizationid: this.organizationId,
      attributetype: ['ClinicalInformationTemplate'],
      casetype: '',
      tablename: 'OrganizationAttributes',
    };
    this._vitalHttpServices
      .getOrganizationAttributes(obj, this.deploymentKey)
      .subscribe({
        next: (data: any) => {
          const jsonData = JSON.parse(data.Message);
          this.attributeList = jsonData.map((item: any) => ({
            Attribute_ID: item.Attribute_ID,
            Attribute_Name: item.Attribute_Name,
            Attribute_Type: item.Attribute_Type,
            Attribute_Description: item.Attribute_Description,
            Sequence_Order: item.Sequence_Order,
            Created_By:item.Created_By,
            CreatedDate:item.CreatedDate,
            Modified_By:item.Modified_By,
            Modified_Date:item.Modified_Date
          })
          );
          this.attributeList = this.attributeList.filter((i)=>i.Attribute_Name !== 'N/A')
          this.listData(this.attributeList)
          this.loading = false
        },
        error: (err) => {
          console.error(err);
          this.loading = false;
        },
      });
  }

  getAuditableDetails(location: any) {
    this._vitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }

}
