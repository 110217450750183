<div class="col-sm-12 p-0 m-0 mt-2 {{openedLocation =='new customer' ? 'customerLocation' : 'orgLocation'}}">
    <div class="col-sm-12 pl-0" *ngIf="isShowAssign && !showAddNewForm">

        <div class="assignFormContainer m-2" *ngIf="openedLocation">
            <div class="col-sm-12 contanerHeader assign-border"> Assign </div>
            <div class="contanerContent row">
                <div class="col-sm-3 viewAutoCompleteContainer pl-0 mt-3">
                    <mat-form-field class="w-100 viewAutoComplete" [ngClass]="{ 'valuedField': autoCompleteSelectedUser }">
                        <mat-label> User </mat-label>
                        <em class="fa fa-chevron-down chevron-align"></em>

                        <input trim type="text" [(ngModel)]="autoCompleteSelectedUser"
                            matInput [aria-label]="autoCompleteSelectedUser" [matAutocomplete]="auto"
                            [ngModelOptions]="{standalone: true}" (input)="filteredUsers()" (blur)="checkUsers(autoCompleteSelectedUser)">


                        <mat-autocomplete #auto="matAutocomplete" class="customCEOverlayPanelClass listingDropdownPanel">
                            <mat-option class="mat-opt-align"
                                *ngFor="let item of usersList"
                                [value]="item?.RepresentativeName"
                                (onSelectionChange)="selectUser($event,item)" >
                                <div class="truncate-text" [matTooltip]="toolTipText" (mouseenter)="enableToolTip(auto)" #auto> {{ item?.RepresentativeName | titlecase }}  {{ item?.EmployeeId ? '(' + item?.EmployeeId + ')' : '' }}  </div>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div class="col-sm-3 viewAutoCompleteContainer mt-3">
                    <mat-form-field  class="col-sm-12 p-0 viewAutoComplete" [ngClass]="{ 'valuedField': autoCompleteSelectedRole }">
                        <mat-label> Role </mat-label>
                        <em class="fa fa-chevron-down chevron-align"></em>

                        <input type="text" #txtInput matInput [(ngModel)]="autoCompleteSelectedRole" [ngModelOptions]="{standalone: true}" class="margin-width" (input)="filteredRoles(autoCompleteSelectedRole)"
                        [matAutocomplete]="sourceRole" #roleTrigger="matAutocompleteTrigger" (blur)="checkRoles(autoCompleteSelectedRole, 'assign')"/>

                        <mat-autocomplete #sourceRole="matAutocomplete" class="customCEOverlayPanelClass listingDropdownPanel">
                          <mat-option *ngFor="let item of rolesList" class="mat-opt-align" (onSelectionChange)="selectRole($event,item)"
                          [value]="item"
                          [title]="item">
                          <div class="truncate-text" [matTooltip]="toolTipText" (mouseenter)="enableToolTip(drop)" #drop> {{  item }} </div>
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div class="col-sm-12 p-0 button-wrap">
                    <button type = 'button' mat-raised-button class="admin-btn-success-outline cancel-btn mr-4" type="reset"
                    (click)="closeAssign()">
                        Cancel
                    </button>

                    <button type = 'button' mat-raised-button class="admin-btn-success approve-btn" (click)="addAssignedUser()">
                        Assign
                    </button>
                </div>
            </div>
        </div>

    </div>

    <div class="col-sm-12 pl-0" *ngIf="!isShowAssign && showAddNewForm">

        <div class="assignFormContainer m-2" *ngIf="openedLocation">
            <div class="col-sm-12 contanerHeader add-border"> Add </div>
            <div class="contanerContent row">
                <div class="col-sm-3" class="createNewFieldWidth viewInputContainer mt-3">
                    <mat-form-field class="w-100" [ngClass]="{ 'valuedField': newUserForm.RepresentativeName }">
                        <mat-label> Name </mat-label>
                        <input required matInput maxlength="250" type="text" autocomplete="off" [(ngModel)]="newUserForm.RepresentativeName" [ngModelOptions]="{standalone: true}" (blur)="trimField('RepresentativeName')"/>
                    </mat-form-field>
                </div>

                <div class="col-sm-3" class="createNewFieldWidth autoComplete viewAutoCompleteContainer mt-3">
                    <mat-form-field class="col-sm-12 p-0 viewAutoComplete" [ngClass]="{ 'valuedField': newUserForm.RoleName }">
                        <mat-label> Role </mat-label>
                        <em class="fa fa-chevron-down chevron-align"></em>

                        <input required type="text" #txtInput matInput [(ngModel)]="newUserForm.RoleName" [ngModelOptions]="{standalone: true}" class="margin-width" (input)="filteredRoles(newUserForm.RoleName)"
                            [matAutocomplete]="sourceRole" #roleTrigger="matAutocompleteTrigger" (blur)="checkRoles(newUserForm.RoleName)"/>

                        <mat-autocomplete #sourceRole="matAutocomplete" class="customCEOverlayPanelClass listingDropdownPanel">
                            <mat-option *ngFor="let item of rolesList" class="mat-opt-align"
                            [value]="item"
                            [title]="item">
                            <div class="truncate-text" [matTooltip]="toolTipText" (mouseenter)="enableToolTip(drop1)" #drop1> {{ item }} </div>
                        </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div class="col-sm-3" class="createNewFieldWidth viewInputContainer mt-3">
                    <mat-form-field class="w-100" [ngClass]="{ 'valuedField': newUserForm.Email }">
                        <mat-label> Email </mat-label>
                        <input required matInput maxlength="500" type="text" autocomplete="off" [(ngModel)]="newUserForm.Email" [ngModelOptions]="{standalone: true}" (blur)="trimField('Email')"
                        pattern="^[a-zA-Z0-9._%+\-]+@(vitalaxis\.com|starmarkit\.com)$"/>
                    </mat-form-field>
                </div>

                <div class="col-sm-3" class="createNewFieldWidth viewInputContainer mt-3">
                    <mat-form-field class="w-100" [ngClass]="{ 'valuedField': newUserForm.Phone }">
                        <mat-label> Phone </mat-label>
                        <!-- <input  [(ngModel)]="newUserForm.Phone" [ngModelOptions]="{standalone: true}" trim matInput (input)="formatPhoneNumber($event)" (blur)="trimField('Phone')" placeholder="976.532.2222x1122222223"/> -->
                        <input  [(ngModel)]="newUserForm.Phone" [ngModelOptions]="{standalone: true}" trim matInput (input)="formatPhoneNumber()" (keypress)="validateKeypress($event)" (blur)="checkPhone(); trimField('Phone')"  placeholder="976.532.2222x1122222223" maxlength="23" (paste)="preventPaste($event)">

                    </mat-form-field>
                </div>

                <div class="col-sm-3" class="createNewFieldWidth viewInputContainer">
                    <mat-form-field class="w-100" [ngClass]="{ 'valuedField': newUserForm.Department }">
                        <mat-label> Department </mat-label>
                        <input matInput maxlength="50" type="text" autocomplete="off" [(ngModel)]="newUserForm.Department" [ngModelOptions]="{standalone: true}" (blur)="trimField('Department')"/>
                    </mat-form-field>
                </div>

                <!-- <div class="col-sm-3" class="createNewFieldWidth viewInputContainer">
                    <mat-form-field class="w-100" [ngClass]="{ 'valuedField': newUserForm.WorkLocation }">
                        <mat-label> Work Location </mat-label>
                        <input matInput maxlength="150" type="text" autocomplete="off" [(ngModel)]="newUserForm.WorkLocation" [ngModelOptions]="{standalone: true}"/>
                    </mat-form-field>
                </div> -->

                <div class="col-sm-3" class="createNewFieldWidth viewSelectContainer">
                    <mat-form-field class="w-100" [ngClass]="{ 'valuedField': newUserForm.WorkLocation }">
                        <mat-label> Work Location </mat-label>
                        <mat-select [(ngModel)]="newUserForm.WorkLocation" name="WorkLocation"
                            [panelClass]="'customCEOverlayPanelClass listingDropdownPanel'">
                            <mat-option value="Wings"> Wings </mat-option>
                            <mat-option value="Camelot"> Camelot </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-sm-3" class="createNewFieldWidth viewInputContainer">
                    <mat-form-field class="w-100" [ngClass]="{ 'valuedField': newUserForm.EmployeeId }">
                        <mat-label> Employee Id </mat-label>
                        <input  [(ngModel)]="newUserForm.EmployeeId" [ngModelOptions]="{standalone: true}" trim matInput type="text" maxLength="8" (keypress)="allowOnlyNumber($event)" (blur)="trimField('EmployeeId')"/>
                    </mat-form-field>
                </div>


                <div class="col-sm-12 p-0 button-wrap">
                    <button type = 'button' mat-raised-button class="admin-btn-success-outline cancel-btn mr-4" type="reset" (click)="hideNewUserForm()">
                        Cancel
                    </button>

                    <button type = 'button' mat-raised-button class="admin-btn-success approve-btn" (click)="addNewUserForCreation()">
                        Add
                    </button>
                </div>
            </div>
        </div>

    </div>

    <div class="col-sm-12 vitalaxis-card-wrapper {{  finalAssignedUsers?.length >= 3 ? 'alignCenter' : 'alignNormal' }}"
        *ngIf="finalAssignedUsers?.length && openedLocation !== 'new customer'">

        <div class="col-sm-12 contanerHeader mt-2 subHeading" *ngIf="checkRepresentativesList(true)"> <div class="borderUnderline"> Representatives </div> </div>


        <div class="p-0" style="width: 33% !important;" *ngFor="let cardInfo of finalAssignedUsers; let i = index;" [ngStyle]="{'display': cardInfo?.IsAssociationActive ? 'block' : 'none'}">
            <app-representative-card
                                [cardInfo]="cardInfo"
                                [openedLocation]="openedLocation"
                                (toggleEventClicked)="toggleEventClicked($event)"
                                [finalAssignedUsers]="finalAssignedUsers"
                                (deleteEventClicked)="deleteAssociation($event, i)"></app-representative-card>
        </div>

        <div class="col-sm-12 contanerHeader mt-2 subHeading" *ngIf="checkRepresentativesList(false)"> <div class="borderUnderline"> Previous Representatives </div> </div>


        <div class="p-0" style="width: 33% !important;" *ngFor="let cardInfo of finalAssignedUsers; let i = index;" [ngStyle]="{'display': !cardInfo?.IsAssociationActive ? 'block' : 'none'}">
            <app-representative-card
                                *ngIf="!cardInfo?.IsAssociationActive"
                                [cardInfo]="cardInfo"
                                [openedLocation]="openedLocation"
                                (toggleEventClicked)="toggleEventClicked($event)"
                                [finalAssignedUsers]="finalAssignedUsers"
                                (deleteEventClicked)="deleteAssociation($event, i)"></app-representative-card>
        </div>
    </div>

    <div class="col-sm-12 vitalaxis-card-wrapper {{  finalAssignedUsers?.length >= 3 ? 'alignCenter' : 'alignNormal' }}"
        *ngIf="finalAssignedUsers?.length && openedLocation === 'new customer'">
        <div class="p-0" style="width: 33% !important;" *ngFor="let cardInfo of finalAssignedUsers; let i = index;" [ngStyle]="{'display': cardInfo?.IsAssociationActive ? 'block' : 'none'}">
            <app-representative-card
                                [cardInfo]="cardInfo"
                                [openedLocation]="openedLocation"
                                (toggleEventClicked)="toggleEventClicked($event)"
                                [finalAssignedUsers]="finalAssignedUsers"
                                (deleteEventClicked)="deleteAssociation($event, i)"></app-representative-card>
        </div>
    </div>

    <div class="col-sm-12 pl-0 d-flex" *ngIf="!isShowAssign && !showAddNewForm">
        <div class="col-sm-12 p-0 button-wrap mainButtonContainer">
            <button type = 'button' mat-raised-button class="admin-btn-success-outline approve-btn mr-4" type="reset" (click)="showNewUserForm()" *ngIf="currentRole?.includes?.('Admin_SuperAdmin')">
                Add
            </button>

            <button type = 'button' mat-raised-button class="admin-btn-success approve-btn" (click)="assignNewUser()">
                Assign
            </button>
        </div>
    </div>
</div>
