import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, filter } from 'rxjs';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { LabadminService } from 'src/app/labadmin/labadmin.service';

@Component({
  selector: 'app-admin-contact-info-pop-up',
  templateUrl: './admin-contact-info-pop-up.component.html',
  styleUrls: ['./admin-contact-info-pop-up.component.scss']
})
export class AdminContactInfoPopUpComponent implements OnInit {
  loggedinUserName: string = '';
  
  constructor(
    private _labadminservice: LabadminService,
    private _vitalHttpServices: VitalHttpServices,
    private el: ElementRef,
    private _snackbar: MatSnackBar,
    private _fb: FormBuilder,
    public _dialogRef: MatDialogRef<AdminContactInfoPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  detailsForm = this._fb.group({
    Name: ['',[Validators.required,Validators.minLength(2)]],
    Phone: ['', [Validators.required,Validators.pattern(/\d{3}[\.]?\d{3}[\.]?\d{4}(x\d{10}?)?$/)]],
    Email: [ '',[Validators.required,Validators.pattern( /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/)]],
  })

  ngOnInit(): void {
    this.changeLoadingState('formDetails', this.getLoaderObj(true))
    this._labadminservice.GetUserDetails(this.data.loggedinUserId, this.data.deploymentKey).subscribe({
      next:(res)=>{
        this.changeLoadingState('formDetails', this.getLoaderObj(false))
        if(res)
          {
            this.loggedinUserName = res.FormattedDisplayName
            this.detailsForm.patchValue({
              Name:res.FormattedDisplayName,
              Email:res.Email,
              Phone:res.Phone
            })
          }
      },
      error:(err)=>
        {
          console.error(err)
          this.changeLoadingState('formDetails', this.getLoaderObj(false))
        }
    })
  }

  customErrorMessages: any = {
    required: {
      Name: 'Please enter the Contact Person.',
      Phone: 'Please enter the Phone Number.',
      Email: 'Please enter the Email.',
    },
    minlength: {
      Name: 'Please the minimum of 2 characters for Contact Person.',
    },
    pattern: {
      Phone: 'Please enter the valid Phone Number.',
      Email: 'Please enter the valid Email ID.',
    },
  };

  getFormValidationErrors(): boolean {
    for (const controlName in this.detailsForm.controls) {
      const control = this.detailsForm.get(controlName);
      if (control?.errors) {
        const errorMessages = [];
        for (const errorKey in control?.errors) {
          const errorMessage = this.customErrorMessages[errorKey][controlName];
          errorMessages.push(errorMessage);
          const invalidControl = this.el.nativeElement
            .querySelector(`[formcontrolname="${controlName}"]`)
            ?.querySelector('input');
          invalidControl?.focus();
          this.detailsForm.get(controlName).markAsDirty()
          this.detailsForm.get(controlName).markAsTouched()
          this.detailsForm.get(controlName).updateValueAndValidity()
          this._snackbar.open(
            errorMessages.join(' '),
            'Close',
            {
              horizontalPosition: 'start',
            }
          );

          break;
        }
        return false;
      }
    }
    return true;
  }

  sendEmail()
  {
    if(!this.getFormValidationErrors()) return 
    let obj = this.data.EmailDetails
    obj.ContactNumber=  this.detailsForm.controls.Phone.value
    obj.ContactPerson=this.detailsForm.controls.Name.value
    obj.EmailID =this.detailsForm.controls.Email.value
    obj.UserName = this.loggedinUserName
    obj.FtpUserNameAndPwd = this.data.EmailDetails.FtpUserNameAndPwd
    this.changeLoadingState('entireLoader', this.getLoaderObj(true))
    this._labadminservice.RequestMailToSupport(obj,this.data.deploymentKey).subscribe({
      next:(res)=>{
        this._dialogRef.close('')
        this.changeLoadingState('entireLoader', this.getLoaderObj(false))
        this._snackbar.open('Email Sent Successfully.')
      },
      error:(err)=>
        {
          this.changeLoadingState('entireLoader', this.getLoaderObj(false))
          this._snackbar.open('Something Went Wrong.')
          this._dialogRef.close('')
          console.error(err)
        }
    })
  }


  loaderSections = {
    formDetails: { loading: false, error: null },
    entireLoader: {
      loading: false,
      error: null
    }
  }
  loader$ = new BehaviorSubject<any>(this.loaderSections)
  getLoaderObj = (loading: boolean = false, error: string | null = null) => ({ loading, error })
  changeLoadingState(sectionKey, value: { loading: boolean, error: string | null }) {
    let result = this.loader$.value
    result[sectionKey] = value
    this.loader$.next(result)
  }
}




