import { Component, Input, ViewChild } from '@angular/core';
import { LabadminService } from '../../labadmin.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LabadminSessionService } from '../../services/labadmin-session.service';
import { ExtAttributeConstants, Scope } from '../share/utility/ex_attribute _constants';
import { DialogService } from 'src/app/core/services/dialog.service';
import { userStatusUpdateMessage, userStatusUpdateSnackbarMessage, StaticMessages } from 'src/app/labadmin/components/share/utility/MessageConstants';
import { menuUrl } from '../Utility Functions';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { UtilityServiceService } from '../share/service/utility-service.service';
import { MatTooltip } from '@angular/material/tooltip';
import { forkJoin } from 'rxjs';
@Component({
  selector: 'app-infodetails',
  templateUrl: './infodetails.component.html',
  styleUrls: ['./infodetails.component.scss']
})

export class InfodetailsComponent {

  @ViewChild(MatTooltip) tooltip: MatTooltip;
  @Input() userDetials: any;
  @Input() userRoles = [];
  @Input() type: string
  ActiveImage : string = '/assets/icons/Role_Active.svg'
  InactiveImage : string = '/assets/icons/Role_Inactive.svg'
  deploymentKey=this._labadminservice.deploymentKey
  sign: string = '';
  ApplicationDomain:string=''
  loggedInUserOrganizationName:string=''
  loggedInUserOrganizationGUID:string=''
  loggedInUserOrgId:string=''
  loggedInUserName: string = ''
  loggedInUserId:string=''
  sessionunSub:any
  copyStatus = 'Copy';
  isLoading=true //used during update of Roles.lo
  activityEntity: any
  staticMessages = StaticMessages
  signatureEnabled=false
  tooltipHideDelay = 3000;
  @Input() statusChanged:boolean[]=[false] //to push this to store
  isManagePathologistPrivilegesForLabAdmin: boolean = false
  EnableGrossingRoles: boolean = false
  
  constructor(
    private _snackbar:MatSnackBar,
    private _labadminservice:LabadminService,
    private _session: LabadminSessionService,
    private _dailogService: DialogService,    
    public activityService: ActivityTrackerService,
    private _utilityService: UtilityServiceService,
  ) {

  }

  ngOnInit() {
    const rolesToCheck=['pathologist',
      'cytotechnologist supervisor',
      'cytotechnologist',
      'physician']
    if (this.userRoles && this.userRoles.length > 0) {
      this.userRoles.sort((a, b) => a.RoleDisplayName.localeCompare(b.RoleDisplayName));
      this.signatureEnabled=this.userRoles.reduce((p,c)=>(rolesToCheck.includes(c.Role.toLowerCase())?true:p),false)
      
    }
    this.sessionunSub = this._session.getLabAdminSession.subscribe((data: any) => {
      if (data) {
        this.loggedInUserOrganizationName = data.userDetails.orgName;
        this.loggedInUserOrganizationGUID = data.userDetails.userOrgGUID;
        this.ApplicationDomain = JSON.parse(data.labAminSession.data).domainUrl.split('//')[1]
        this.loggedInUserOrgId = data.userDetails.organizationId
        this.loggedInUserName = data.userDetails.formattedDisplayName
        this.loggedInUserId = data?.userDetails?.userId?.toString()
        // this.loggedInUserAccountId = data.userDetails.userAccountIDs.map((ids: any) => ids.accountId).join(',');
        this.deploymentKey = data.userDetails.deploymentKey ?? this.deploymentKey
        this.manageFlags()
      }
    })
    this.setActivitySession()
  }

  ngOnChanges() {
    this.getOrganizationDetails(this.userDetials);
  }

  getOrganizationDetails(userDetails) {
    if (userDetails["SignatureFile"]) {
      this.sign = "data:image/jpg;base64," + userDetails["SignatureFile"];
    }
  }
 
  copyContent(text, tooltip: MatTooltip) {
    const content = text;
    navigator.clipboard.writeText(content).then(() => {
      this.copyStatus = 'Copied';
      tooltip?.show();
      setTimeout(() => {       
        tooltip?.hide(); 
        this.copyStatus = 'Copy';
      }, 1000); 
    });
  }

  checkUserDetails() {
    return Object.keys(this.userDetials)?.length && this.isLoading;
  }
  ActivateOrInactivateUsers(roleName:string) {
    var SavedUserId = '',modifiedStatus = '',RoleDisplayName  = '', isprimary = false;
    for (let i = 0; i < this.userRoles.length; i++) {
      if (roleName === this.userRoles[i].RoleDisplayName) {
        SavedUserId = this.userRoles[i].UserID;
        RoleDisplayName = this.userRoles[i].RoleDisplayName;
        isprimary= this.userRoles[i].IsPrimary
        modifiedStatus=this.userRoles[i].Usrstatus.toLowerCase() == 'active'?'inactive':'active' 
      }
    }
    const status = modifiedStatus === 'active' ? 'Activate' : 'Deactivate'
    const username = this.userDetials['FormattedDisplayName']
    this._dailogService.openLabadminConfimationdialog(
      '',
      userStatusUpdateMessage(status, RoleDisplayName, username),
      status,
      'Cancel'
    ).afterClosed().subscribe((res) => {
      if(res){
    const obj1 = {
      deleteUser: [{
        UserID: String(SavedUserId),
        VFrom: 'user',
        AccountID: '',
        MedicalDirectorToUpdate: '',
        Status: modifiedStatus,
        ModifiedBy:this.loggedInUserId
      }],
      sessionData: {
        ApplicationDomain: this.ApplicationDomain,
        LoggedInUserOrgName: this.loggedInUserOrganizationName,
        LoggedInUserOrgGuid: this.loggedInUserOrganizationGUID,
        LoggedInUserOrdId: this.loggedInUserOrgId,
        accountsOrgGuid: '',
      }
    }
    this.isLoading=false
    this._labadminservice.ActiveInActiveDeleteUsers(obj1, Number(this.loggedInUserOrgId), this.deploymentKey).subscribe({
      next: (data) => {
        //var status = modifiedStatus === 'active' ? 'Activated' : 'Inactivated'
        //this._snackbar.open(`${RoleDisplayName} Role ${status} Successfully `, 'Close')
        this._snackbar.open(userStatusUpdateSnackbarMessage(`${status}d`, RoleDisplayName, isprimary), 'Close')
        this._utilityService.activateInactivateUser(SavedUserId, username, this.loggedInUserName, this.type, status, RoleDisplayName, isprimary)
        for (let i = 0; i < this.userRoles.length; i++) {
          if (roleName === this.userRoles[i].RoleDisplayName) {
             this.userRoles[i].Status=!this.userRoles[i].Status;
             this.userRoles[i].Usrstatus=modifiedStatus           
          }
        }
        this.isLoading=true
        this.statusChanged[0]=true
        
      
      },
      error: (err) => {
        console.error(err)
        this.isLoading=true
        this._snackbar.open("Something went wrong.", 'Close')
      }
    })
  }
  })
  }
  
  setActivitySession(){
    const menuurl = menuUrl(this.type)
    this.activityService.setActivitySession({ 'entityId': '', 'entityType': menuurl, 'context': [{ 'key': 'parentMenu', 'value': menuurl }] })
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res)
  }

  manageFlags() {
    const organizationId = this.userDetials['labLocationList'][0]?.OrganizationId;
    forkJoin([
      this._labadminservice.GetVAExtAttrValue(organizationId, ExtAttributeConstants.ManagePathologistPrivilegesForLabAdmin, '2', this.deploymentKey),
      this._labadminservice.GetVAExtAttrValue(this.loggedInUserOrgId, ExtAttributeConstants.EnableGrossingRoles, '2', this.deploymentKey)
    ]).subscribe((data)=>{
      this.isManagePathologistPrivilegesForLabAdmin = data[0].toString() == 'true' ? true : false;
      this.EnableGrossingRoles = data[1].toString() == 'true' ? true : false;
  })
    }
  }