<hr class="separation-line m-0 p-0">
<div class="col-sm-12 p-0 mt-0">
    <div class="col-sm-12 title-wrap d-flex py-1 mb-1 ">
        <div class="col-sm-12 header-wrap p-0 pr-3 px-2">
            <span [ngClass]="header.colWidth" *ngFor="let header of gridHeaders;let i =index;">
              <div *ngIf="fromClientUser">
                <div style="color:black!important" *ngIf="i !== gridHeaders.length-1">{{header.Name}}</div>
                <div *ngIf="i === gridHeaders.length -1" class="d-flex justify-content-between">
                  <span>{{header.Name}}</span>
                  <span (click)="ExportExcel()"><img *ngIf="gridData?.length > 0"
                  src=".../../../../assets/icons/lab_users_icons/excel_download_green.svg" title="Export"
                  class="export-icon"></span></div>
              </div>
      <div style="color:black!important" *ngIf="!fromClientUser">{{header.Name}}</div>
            </span>

      <div class="col-sm-2 pr-0 edit-wraps" *ngIf="!fromClientUser">
                <span (click)="ExportExcel()"><img *ngIf="gridData?.length > 0"
                        src=".../../../../assets/icons/lab_users_icons/excel_download_green.svg" title="Export"
                        class="export-icon"></span>
            </div>
          </div>
    </div>

    <div class="header-wrap pr-0 px-2">
        <div class="col-sm-12 pl-0 pr-1 table-scroll" [style.height]="fromClientUser ? 'calc(100vh - 150px)' :  'calc(100vh - 220px)'">
            <div class="col-sm-12 nodata-header-wrap"
                *ngIf="gridData.length == 0 && !noDataAvaliable">
                <span class="col-sm-12 nodata-wrapper">No Results</span>
            </div>
            <app-spinner-loader  *ngIf="showloader"></app-spinner-loader>
            <span *ngIf="gridData.length > 0 && !noDataAvaliable">
                <cdk-virtual-scroll-viewport itemSize="15" class="tableScroll cstcdkscroll">
                    <div [ngClass]="(item?.status?.trim().toLowerCase() === 'active') ? 'row grid-data mt-1 ml-0 mr-3 p-2 mb-1 cursor':'row grid-data mt-1 ml-0 mr-3 p-2 mb-1 inactive-class cursor'"
                    *cdkVirtualFor="let item of gridData ;let i = index;" (click)="RedirectToView(item)" >
                    <div [ngClass]="header.colWidth"
                        appTooltipEllipsis #isElipsed
                        *ngFor="let header of gridHeaders">
                        {{displayedColumns(item,header) }}
                        <span *ngIf="header.key== 'npi' && isNpiKnown(item) && showNPIStatus" style="display: inline-flex;">
                            <span class="ml-2" *ngIf="item?.npiStatus?.toLowerCase()">
                                <div [matTooltip]="item?.npiStatus"  [class]="item?.npiStatus?.toLowerCase()+'-circle'">
                                </div>
                            </span>
                            <!-- <span *ngIf="item?.npiStatus?.toLowerCase() !== 'blacklisted'">
                              <div  class="inactive-circle ml-2">
                              </div>
                          </span>
                          <span *ngIf="item?.npiStatus?.toLowerCase() !== 'deactivated'">
                            <div  class="inactive-circle ml-2">
                            </div>
                        </span> -->
                        </span>

                    </div>

                    <div class="col-sm-2 button-wrap pr-0" *ngIf="!fromClientUser">
                        <span *ngIf="loginType != 'email'"></span>
                        <span class="cursor-pointer active-inactive-user" (click)="ActivateOrInactivateUsers(item.userid);$event.stopPropagation();" *ngIf="item.status" [ngClass]="{'action-button-disabled':showloader}">
                            <img style="height: 15px!important;width: 27px!important;"  [src]="item.status.toLowerCase() ==='active'?ActiveImage:InactiveImage"
                                [matTooltip]="item.status.toLowerCase() ==='active'?staticMessages?.ACTIVATE_USER : staticMessages?.DEACTIVATE_USER"></span>

                      <span (click)="openDialog(item);$event.stopPropagation();" *ngIf="item.isAccountLockedOut" class="cursor" style="min-width: 11px;" [ngClass]="{'action-button-disabled':showloader || isLockDisabled}"><img style="transform: scale(0.9);" src="../../../../../assets/icons/lab_users_icons/lock_user.svg" matTooltip="Locked User"></span>

                      <span (click)="openDialog(item);$event.stopPropagation();" *ngIf="!item.isAccountLockedOut" class="cursor" style="min-width: 11px;" [ngClass]="{'action-button-disabled':showloader || isUnlockDisabled}"><img style="width: 18px;" src="../../../../../assets/icons/lab_users_icons/Unlock.svg" matTooltip="Unlocked User"></span>

                     <span *ngIf="sendResetPassswordLink(item)" class="cursor" (click)="sendResetLink(item);$event.stopPropagation();" [ngClass]="{'action-button-disabled':showloader || isGeneratePwd}">
                        <img style="height: 15px!important;width: 27px!important;" src="../../../../../assets/icons/lab_users_icons/email.svg" matTooltip="Send Reset Password Link">
                    </span>

                    <span class="cursor" *ngIf="isEnableResetPassword(item.authstatus ?? '', item.allowlogin, item.isadenabled)" (click)="passwordGenerateDialog(item);$event.stopPropagation();" [ngClass]="{'action-button-disabled':showloader || isGeneratePwd}">
                            <img src="../../../../../assets/icons/lab_users_icons/generate_password.svg" matTooltip="Reset Password">
                    </span>

                     <span class="cursor" [class.approve-pass]="loginType != 'email'" [ngClass]="{'action-button-disabled':showloader || isApprovePassword}" (click)="approvePassword(item);$event.stopPropagation();" *ngIf="isEnableResetPassword(item.authstatus ?? '', item.allowlogin, item.isadenabled)">
                        <img src="/assets/images_vla/approve_password.svg" matTooltip="Approve Password" width="23px">
                    </span>                    

                    <span class="cursor" (click)="mailSent(item);$event.stopPropagation();" *ngIf="emailType(item)" [ngClass]="{'action-button-disabled':showloader || isEmailDisabled}">
                        <img style="height: 15px!important;width: 27px!important;" src="../../../../../assets/icons/lab_users_icons/email.svg"  matTooltip='Send Invitation'>
                    </span>
                  </div>
                 
                </div>
            </cdk-virtual-scroll-viewport>
            </span>
           
            <div class="col-sm-12 nodata-header-wrap"
            *ngIf=" !labAdminService.fromGlobalSearch && noDataAvaliable">
            <span class="col-sm-12 nodata-wrapper">No Data Available</span>
            </div>
        <!-- <div class="col-sm-12 nodata-header-wrap"
        *ngIf="gridData.length === 0 && labAdminService.fromGlobalSearch">
        <span class="col-sm-12 nodata-wrapper">No Results</span></div>
        </div> -->
    </div>
</div>

