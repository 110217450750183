<div *ngIf="uploadClicked">
  <div class="col-md-12 p-0">
    <div class="modal-header admin-model-header mt-2 listingModalHeader">
      <h3 class="modal-title w-100 admin-model-header-txt">
        <strong class="header-View"> {{ filename }} </strong>
      </h3>
    </div>
  </div>

  <div class="button-field-wrap m-1 p-0">
    <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
      <mat-label class="d-flex">
        Templates
      </mat-label>
      <mat-select disableOptionCentering class="ml-2">
        <mat-option class="temp-font-size" *ngFor="let item of configureSheets" (click)="downloadTemplate(item)">
          <mat-icon class="pr-2 get-app">get_app</mat-icon>{{item}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="button-field-wrap">
    <span class="instr-size mb-2">
      <img aria-hidden="true" class="mb-1 mr-1" alt="help" src="/assets/icons/help.svg" width="15px" height="auto" />
      You can download the sample template file to upload the .</span>
  </div>

  <div class="col-sm-12 p-0" *ngIf="gridWidth == 0">
    <div class="image-height" (fileDropped)="onFileDropped($event)" appDnd>
      <input type="file" id="fileDropRef" #fileDropRef click="value = null" value="" (change)="onFileChange($event)"
        hidden />
      <span class="align-center img-wrap">
        <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
      </span>
      <span>Drag & Drop excel files here</span>
      <span class="align-center">Or</span>
      <label class="Expbtn btn-sm rounded btn mt-2 browse ml-5" style="background-color: #3874ff;"
        for="fileDropRef">Browse file</label>
    </div>
    <button mat-raised-button class="Expbtn mt-4" (click)="backToParent()">
      Back
    </button>
  </div>
  <div *ngIf="gridWidth > 0">
    <!-- <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" #grid1
        (initialized)="initGrid(grid1)" [headersVisibility]="'Column'" (formatItem)="formatItem(grid1, $event)"
        [frozenColumns]="3">
        <div *ngFor="let columnname of sheetHeader">
            <wj-flex-grid-column *ngIf="!postUpload" [binding]="columnname?.toString().toLowerCase()"
              [header]="columnname" [visible]="!(columnname == 'Status')" [allowResizing]="true" [width]="'*'"
              [allowDragging]="false" [allowSorting]="false" [format]="'d'">
        </wj-flex-grid-column>
          <wj-flex-grid-column *ngIf="postUpload" [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
            [visible]="!(columnname == 'AttrId')" [allowResizing]="true" [width]="'*'" [allowDragging]="false"
            [allowSorting]="false" [format]="'d'">
          </wj-flex-grid-column>
        </div>
        <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="120" [isReadOnly]="true">
          <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
            <button class="edit-delete-btn" (click)="deleteRow(grid1, row)"><em id="deleteButton" [title]="'Delete'"
                class="cursor p-0 fa fa-trash"></em></button>
          </ng-template>
        </wj-flex-grid-column>
        <wj-flex-grid-filter #filter></wj-flex-grid-filter>
      </wj-flex-grid> -->
    <div class="global-search-panel d-flex col-sm-4 p-0">
      <div style="display: flex; margin-right: 3px;margin-left: 4px;">
        <mat-icon class="search-icon">search</mat-icon>
      </div>
      <div class="standing-line">
      </div>
      <input type="text" class="search-input" [placeholder]="'Search ' + templateData.menuURL" [(ngModel)]="searchText"
        (keyup)="filtertemplates()">
      <ng-container *ngIf="searchText">
        <div class="standing-line">
        </div>
        <div style="display: flex; margin-right: 3px;margin-left: 4px; cursor: pointer;">
          <mat-icon class="search-icon" (click)="clearSearch()">close</mat-icon>
        </div>
      </ng-container>
    </div>
    <div class="parent-div mt-2 table-align">
      <div class="header d-flex p-0 mb-1 table-content" *ngIf="gridData?.length" >
        <div class="header-wrap" style="width: auto !important;">
          <span class="buttonClass" *ngIf="!postUpload">
            <div class="dataTable"></div>
          </span>
          <span class="widthClass pl-1" *ngFor="let header of sheetHeader">
            <div class="dataTable valueDiv">{{header}}</div>
          </span>
        </div>
      </div>
      <div class="container-fluid header-wrap p-0">
        <div cdkDropList cdkDropListOrientation="vertical" class="tableScroll" style="width: fit-content !important;">
          <span *ngIf="gridData?.length > 0">
            <div cdkDrag [cdkDragDisabled]="true" [cdkDragBoundary]="'.tableScroll'"
              [ngClass]="(item.IsActive === 'Inactive' || item.Active === 'Inactive' || item.Status === 'Inactive')?'card-data mt-1 ml-0  mb-1 inactive-class':'card-data mt-1 ml-0  mb-1'"
              *ngFor="let item of gridData | searchfiltersTemplate: templatefilter">
              <mat-icon *ngIf="!postUpload" class="actions buttonClass pl-2" matTooltip="Delete"
                (click)="deleteRow(item)">delete</mat-icon>
              <span (click)="RedirectToView(item)" class="text-muted text-ellipsis dataTable widthClass"
                
                *ngFor="let header of sheetHeader">                 
                <div *ngIf="header != 'ResultMessage' && header != 'Result'" class="valueDiv" [matTooltip]="toolTipText" (mouseenter)="enableToolTip(isElipsed)" #isElipsed>
                  {{ displayedColumns(item,header?.toLowerCase()) | stripHtml }}</div>
                <div *ngIf="header == 'ResultMessage' || header == 'Result'"
                  [ngClass]="item['isvalid']==1 || item['result']=='Success' ? 'text-success pl-1': item['isvalid']==-1 ||['isvalid']==0 ? 'text-danger pl-1':null" class="valueDiv" [matTooltip]="toolTipText" (mouseenter)="enableToolTip(isElipsed)" #isElipsed>
                  {{ displayedColumns(item,header?.toLowerCase()) | stripHtml }}</div>
                </span>
            </div>
          
          </span>
          <div class="col-sm-12 nodata-header-wrap" *ngIf="GridData?.length === 0">
            <span class="col-sm-12 nodata-wrapper"> No Data Available </span>
          </div>
          <div class="col-sm-12 nodata-header-wrap"
            *ngIf="(GridData | searchfiltersTemplate: templatefilter)?.length === 0 && searchText && GridData?.length !== 0">
            <span class="col-sm-12 nodata-wrapper"> No Results </span>
          </div>
        </div>
      </div>
    </div>
    <div>


      <div class="row pl-3 mt-4">
        <div class="col-sm-2 p-0">
          <button mat-raised-button class="Expbtn" (click)="removeGrid()">
            Back
          </button>
        </div>
        <div class="col-sm-10 button-field-wrap p-0">
          <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
            <button mat-raised-button *ngIf="postUpload" class="Expbtn mr-4" (click)="backToParent()">Finish</button>
            <button mat-raised-button *ngIf="!postUpload" class="Expbtn mr-4" (click)="backToParent()">Cancel</button>
            <button mat-raised-button *ngIf="!postUpload" [disabled]="disableApprovebtn()" class="Expbtn mr-4"
              (click)="uploadData()">Approve</button>
            <button mat-raised-button *ngIf="postUpload" class="Expbtn" (click)="ExportExcel()">Download
              Result</button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
      <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
    </div>
  </div>