<div class="associationFormContainer">
    <div class="actionButtonsContainer d-flex justify-content-end">
        <div class="d-flex">

            <div class="ml-2 justify-content-center cursor">
                <a class="reset-btn mr-3" (click)="resetClickedInForm()">Reset</a>
            </div>

            <div class="ml-2 justify-content-center cursor" (click)="returnClicked()">
                <button type="button" class="btn btn-return">
                    Return
                </button>
            </div>
    
            <div class="ml-2 justify-content-center cursor" *ngIf="isPreviewOpened">
                <button type="button" class="btn btn-return primaryButtonColor" (click)="save()">
                    Save
                </button>
            </div>
        </div>
    </div>

    <ng-container>
        <div class="formContainer">
            <div class="panelHeader">
               <div class="heading"> Associations </div>
               <div class="selectAll" *ngIf="accountsList?.Accounts"> 
                    <input type="checkbox" id="input_EnableSendOutCheckBox" [(ngModel)]="isCurrentLocation"
                    #currLoc [disabled]="checkForDisabled()" (change)="fetchCurrentLocationAssociations(currLoc.checked)"> <span> Same as current location </span>
               </div>
            </div>
    
            <div class="formBody" [formGroup]="associationForm">
                <div class="col-md-12 row p-0">
                    <div class="col-md-3 viewSelectContainer p-0">
                        <mat-form-field class="col-sm-12 p-0" [ngClass]="associationForm?.value?.AssociationType ? 'valuedField' : ''">
                            <mat-label> Association Type </mat-label>
                            <mat-select formControlName="AssociationType" name=""
                                [disabled]="isCurrentLocation"
                                (selectionChange)="onTypeSelectChange()"
                                [panelClass]="'customCEOverlayPanelClass listingDropdownPanel'">
                                <mat-option *ngFor="let dropdownOption of associationTypeList"
                                    [value]="dropdownOption.item">
                                    <div class="truncate-text">
                                        {{ dropdownOption?.item }}
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
    
                    <div class="col-md-4 viewAutoCompleteContainer p-0" *ngIf="associationForm?.value?.AssociationType && !isCurrentLocation">
                        <mat-form-field class="col-sm-12 viewAutoComplete ml-2 p-0" [ngClass]="{'valuedField': associationForm?.value?.AssociatedAccountid}">
                            <mat-label> Group </mat-label>
                            <em class="fa fa-chevron-down chevron-align"></em>
                            <!-- for autocomplete as we need some value we are sorting that in default value as object from there we are taking for value field. -->
                            <input trim type="text" formControlName="AssociatedAccountid" maxlength="200"
                                matInput aria-label="Group" [matAutocomplete]="auto" (input)="locationFilter($event)" />
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option class="mat-opt-align" id="drop-down-mat" *ngFor="let org of organizationsList" value="{{
                                        org.organizationname}} ({{ org.organizationid }})"
                                      (onSelectionChange)="getOrganizationName($event); onOrganizationChange($event,associationForm?.value?.AssociationType, org)">
                                      <span>{{ org.organizationname }}({{org.organizationid}})</span>
                                    </mat-option>
                                </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>
    
                <div class="col-md-12 row p-0" style="padding: 0px 10px !important" *ngIf="selectedGroupAccountsList?.Accounts?.length">
                    <div class="col-md-12 row p-0 d-flex justify-content-between">
                        <div class="col-md-6 p-0">
                          <mat-checkbox [color]="task.color" [checked]="checkedAllAccounts(selectedGroupAccountsList)"
                            [indeterminate]="!checkedAllAccounts(selectedGroupAccountsList) && notAllUnchecked(selectedGroupAccountsList)"
                            (change)="checkAll(selectedGroupAccountsList,$event.checked)" class="casetype-margin check-box">
                            <strong class="head-font currentOrganization">{{ selectedGroupAccountsList?.Organization }}</strong>
                          </mat-checkbox>
                        </div>

                        <div class="d-flex justify-content-center align-item-center searchContainer accountSearch">
                            <div class="global-search-panel d-flex p-0 col-md-12">
                                <div style="display: flex; margin-right: 3px;margin-left: 4px;">
                                    <mat-icon class="search-icon">search</mat-icon>
                                </div>
                                <div class="standing-line">
                                </div>
                                <input type="text" class="search-input" [placeholder]="'Search'" [(ngModel)]="accountSearchText" [ngModelOptions]="{standalone: true}">
                                <ng-container *ngIf="accountSearchText">
                                    <div class="standing-line">
                                    </div>
                                    <div  style="display: flex; margin-right: 3px;margin-left: 4px; cursor: pointer;"> 
                                        <mat-icon class="search-icon" (click)="accountSearchText = ''">close</mat-icon>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
            
                        <!-- <div class="row col-sm-3 error-msg" style="display: flex;align-items: center;justify-content: flex-end;"
                          *ngIf="checkNoAccountSelected(selectedGroupAccountsList)">
                          <mat-icon matSuffix><i class="fa fa-exclamation-circle error-msg" aria-hidden="true"></i></mat-icon>
                          Please select at least one location
                        </div> -->
                    </div>
    
                    <div class="col-md-12 row">
                        <div class="m-0 checkbox-wrap col-md-12 row" *ngIf="selectedGroupAccountsList?.Accounts?.length">
                            <div *ngFor="let value of getAccountsList(selectedGroupAccountsList, accountSearchText)" class="col-sm-3 p-0 m-0">
                              <mat-checkbox [color]="task.color" [checked]="value.checked" [disabled]="value.disabled"
                                (change)="checkAccount(value, $event.checked, selectedGroupAccountsList)">
                                <div [matTooltip]="value.Name"> {{ value.Name }} </div>
                              </mat-checkbox>
                            </div>
                          </div>
                    </div>
                </div>
    
                <div class="col-md-12 row p-0 mt-3 d-flex justify-content-between align-item-center" *ngIf="accountsList?.Accounts">
                    <div class="modal-header admin-model-header mt-2 listingModalHeader col-md-6 p-0">
                        <h3 class="modal-title w-100 admin-model-header-txt">
                            <strong class="header-View"> Associate to </strong>
                        </h3>
                    </div>
    
                    <div class="d-flex justify-content-center align-item-center searchContainer">
                        <div class="global-search-panel d-flex p-0 col-md-12">
                            <div style="display: flex; margin-right: 3px;margin-left: 4px;">
                                <mat-icon class="search-icon">search</mat-icon>
                            </div>
                            <div class="standing-line">
                            </div>
                            <input type="text" class="search-input" [placeholder]="'Search'" [(ngModel)]="currentSearchText" [ngModelOptions]="{standalone: true}">
                            <ng-container *ngIf="currentSearchText">
                                <div class="standing-line">
                                </div>
                                <div  style="display: flex; margin-right: 3px;margin-left: 4px; cursor: pointer;"> 
                                    <mat-icon class="search-icon" (click)="currentSearchText = ''">close</mat-icon>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
    
                <div class="col-md-12 row p-0" style="padding: 0px 10px !important" *ngIf="accountsList?.Accounts">
                    <div class="row p-0">
                        <div class="p-0">
                          <mat-checkbox [color]="task.color" [checked]="checkedAllAccounts(accountsList)"
                            [indeterminate]="!checkedAllAccounts(accountsList) && notAllUnchecked(accountsList)"
                            (change)="checkAll(accountsList,$event.checked)" class="casetype-margin check-box">
                            <strong class="head-font currentOrganization">{{ accountsList?.Organization }}</strong>
                          </mat-checkbox>
                        </div>
            
                        <div class="row col-sm-3 error-msg" style="display: flex;align-items: center;justify-content: flex-end;"
                          *ngIf="checkNoAccountSelected()">
                          <mat-icon matSuffix><i class="fa fa-exclamation-circle error-msg" aria-hidden="true"></i></mat-icon>
                          Please select at least one location
                        </div>
                    </div>
    
                    <div class="col-md-12 row">
                        <div class="m-0 checkbox-wrap col-md-12 row" *ngIf="accountsList?.Accounts?.length">
                            <div *ngFor="let value of getAccountsList(accountsList, currentSearchText)" class="col-sm-3 p-0 m-0">
                              <mat-checkbox [color]="task.color" [checked]="value.checked" [disabled]="value.disabled"
                                (change)="checkAccount(value, $event.checked)">
                                <div [matTooltip]="value.Name"> {{ value.Name }} </div>
                              </mat-checkbox>
                            </div>
                          </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-end col-md-12 mt-2" *ngIf="isPreviewOpened">
            <div class="justify-content-center" (click)="addNewAccounts()">
                <button type="button" class="btn btn-return">
                    Add
                </button>
            </div>
        </div>
    
        <div class="col-md-12 p-0 mt-4" [formGroup]="associationForm" *ngIf="selectedGroupAccountsList?.Organization && !['Pathologists'].includes(associationForm?.value?.AssociationType)">
            <div class="modal-header admin-model-header mt-2 listingModalHeader mb-2">
                <h3 class="modal-title w-100 admin-model-header-txt p-0">
                    <strong class="header-View" style="color: #0364B4 !important;"> Configuration </strong>
                </h3>
            </div>
    
            <div class="col-md-12 row p-0 mb-2" *ngIf="!['Pathologists', 'Send In Lab', 'Send-out Laboratories'].includes(associationForm?.value?.AssociationType)">
                <div class="viewSelectContainer col-md-3">

                    <mat-form-field class="col-sm-12 p-0" [ngClass]="associationForm?.value?.serviceType ? 'valuedField' : ''">
                        <mat-label> Service Type </mat-label>
                        <mat-select formControlName="serviceType" name="serviceType"
                            [disabled]="isCurrentLocation"
                            [panelClass]="'customCEOverlayPanelClass listingDropdownPanel'">
                            <mat-option *ngFor="let dropdownOption of servicesList"
                                [value]="dropdownOption.item">
                                <div class="truncate-text">
                                    {{ dropdownOption?.item }}
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
        
                <div class="viewInputContainer col-md-3">
                    <mat-form-field class="col-sm-12 p-0" [ngClass]="{'valuedField': associationForm?.value?.AccountCode}">
                        <mat-label> Account Code </mat-label>
                        <input  formControlName="AccountCode"
                            name="accountCode" trim matInput />
                    </mat-form-field>
                </div>
    
                <div class="viewInputContainer col-md-3">
                    <mat-form-field class="col-sm-12 p-0" [ngClass]="{'valuedField': associationForm?.value?.LocationCode}">
                        <mat-label> Location Code </mat-label>
                        <input  formControlName="LocationCode"
                            name="locationCode" trim matInput />
                    </mat-form-field>
                </div>
    
                <div class="viewInputContainer col-md-3">
                    <mat-form-field class="col-sm-12 p-0" [ngClass]="{'valuedField': associationForm?.value?.ClientNumber}">
                        <mat-label> Client Number/Code </mat-label>
                        <input  formControlName="ClientNumber"
                            name="clientCode" trim matInput />
                    </mat-form-field>
                </div>
            </div>

            <div class="col-md-12 row p-0 mb-2" *ngIf="['Send In Lab', 'Send-out Laboratories'].includes(associationForm?.value?.AssociationType)">
                <div class="viewInputContainer col-md-3">
                    <mat-form-field class="col-sm-12 p-0" [ngClass]="{'valuedField': associationForm?.value?.L2LConfigId}">
                        <mat-label> L2L Config Id </mat-label>
                        <input  formControlName="L2LConfigId"
                            name="L2LConfigId" trim matInput type="number"/>
                    </mat-form-field>
                </div>
            </div>
    
            <div class="col-md-12 row p-0 checkboxRow" *ngIf="!['Pathologists', 'Send In Lab', 'Send-out Laboratories'].includes(associationForm?.value?.AssociationType)">
                <div class="col-md-12 row">
                    <div class="col-md-3" style="position: relative; right: 10px;">
                        <mat-checkbox formControlName="AllowLabToView" [color]="task.color">
                            Allow lab to view reports
                        </mat-checkbox>
                    </div>
    
                    <div class="col-md-3" style="position: relative; right: 4px;">
                        <mat-checkbox formControlName="PCEnabled" [color]="task.color">
                            PC Enabled
                        </mat-checkbox>
                    </div>
    
                    <div class="col-md-6">
                        <mat-checkbox formControlName="DiagnosisReporting" [color]="task.color">
                            Diagnosis Reporting done using VitalDx(VitalOffice)
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="col-md-12 p-0 row mt-4" [formGroup]="associationForm" *ngIf="selectedGroupAccountsList?.Organization && !['Pathologists', 'Send In Lab', 'Send-out Laboratories'].includes(associationForm?.value?.AssociationType)">
            <div class="col-md-6">
                <div class="modal-header admin-model-header mt-2 listingModalHeader mb-2">
                    <h3 class="modal-title w-100 admin-model-header-txt p-0">
                        <strong class="header-View"> Accession Instructions </strong>
                    </h3>
                </div>
    
                <div class="editor">
                    <ngx-editor-menu [editor]="accessEditor" [toolbar]="toolbar">
                    </ngx-editor-menu>
                    <ngx-editor class="rtf-editor" [editor]="accessEditor" [(ngModel)]="accessIns" [ngModelOptions]="{standalone: true}"
                        [disabled]="false">
                    </ngx-editor>
                </div>
            </div>
    
            <div class="col-md-6">
                <div class="modal-header admin-model-header mt-2 listingModalHeader mb-2">
                    <h3 class="modal-title w-100 admin-model-header-txt p-0">
                        <strong class="header-View"> Diagnosis Custom Instructions </strong>
                    </h3>
                </div>
    
                <div class="editor">
                    <ngx-editor-menu [editor]="diagEditor" [toolbar]="toolbar">
                    </ngx-editor-menu>
                    <ngx-editor class="rtf-editor" [editor]="diagEditor" [(ngModel)]="diagIns" [ngModelOptions]="{standalone: true}"
                        [disabled]="false">
                    </ngx-editor>
                </div>
            </div>
        </div>

        <div class="col-md-12 d-flex justify-content-end mt-2" *ngIf="!isPreviewOpened && selectedGroupAccountsList?.Organization">
            <div class="ml-2 justify-content-center" (click)="preview()">
                <button type="button" class="btn btn-return">
                    Preview
                </button>
            </div>
        </div>

    </ng-container>
    

    <ng-container *ngIf="isPreviewOpened">
        <div class="modal-header admin-model-header mt-2 listingModalHeader mb-2">
            <h3 class="modal-title w-100 admin-model-header-txt p-0">
                <strong class="header-View" style="color: #0364B4 !important;"> Locations to be Associated </strong>
            </h3>
        </div>

        <hr class="separation-line m-0 p-0">
        <div class="container-fluid list-container col-sm-12 p-0 mt-0 text-center">
            <div class="col-sm-12 header d-flex py-1 mb-1" *ngIf="GridData?.length">
              <div class="col-sm-12 header-wrap">
                <span [ngStyle]="{width: header.colWidth}" *ngFor="let header of gridHeader; trackBy: trackByHeader">
                  <div class="dataTable" [ngStyle]="{'text-align': header?.textAlign ? header?.textAlign : 'left', 'padding-right': '10px'}">{{header.Name}}</div>
                </span>
                <div class="col-sm-1 pr-0 edit-wraps dataTable">
                </div>
              </div>
            </div>
            <div class="container-fluid col-sm-12 header-wrap p-0">
                <cdk-virtual-scroll-viewport itemSize="15" class="example-viewport">
                <div *cdkVirtualFor="let item of GridData; let i = index;">
                    <div [ngClass]="(item.IsActive === 'Inactive' || item.Active === 'Inactive' || item.Status === 'Inactive') ? 'col-sm-12 card-data mt-1 ml-0 mb-1 inactive-class' : 'col-sm-12 card-data mt-1 ml-0 mb-1'">
                    <div [ngStyle]="{width: header.colWidth, 'text-align': header?.textAlign ? header?.textAlign : 'left', 'padding-right': '10px'}" class="text-muted text-ellipsis dataTable dataTableRowFont"
                    *ngFor="let header of gridHeader; trackBy: trackByHeader" [matTooltip]="toolTipText" (mouseenter)="enableToolTip(isElipsed)"
                    #isElipsed>
                        <span *ngIf="header.Name.toLowerCase() != 'pc enabled' && header.Name.toLowerCase() != 'reporting viewing' && header.Name.toLowerCase() != 'services'">
                            {{ item[header['key']] | stripHtml }}
                        </span>
                        <!-- Uncomment if needed -->
                        <span *ngIf="header.Name.toLowerCase() =='services'">
                            <!-- <ng-container *ngIf="item['AssociationType'] === 'Ordering Facilities'; else otherContent">
                                <select class="form-control deployment-wrap" [(ngModel)]="item[header['key']]" style="height: 25px !important; border: none !important;font-size: 12px;padding: 0px 5px;">
                                    <option *ngFor="let option of servicesList" value="{{option?.item}}">
                                      {{ option?.item }}
                                    </option>
                                </select>
                            </ng-container> -->

                            <span  *ngIf="item['AssociationType'] === 'Ordering Facilities'; else otherContent">
                                {{ item[header['key']] | stripHtml }}
                            </span>

                            <ng-template #otherContent>
                                NA
                            </ng-template>
                            
                        </span>

                        <span *ngIf="header.Name.toLowerCase() =='pc enabled' || header.Name.toLowerCase() =='reporting viewing'">
                            <ng-container *ngIf="item['AssociationType'] === 'Ordering Facilities'; else otherContent">
                                <!-- <input type="checkbox" id="input_EnableSendOutCheckBox" [(ngModel)]=" item[header['key']]"> -->
                                <img src="../../../../assets/icons/{{ item[header['key']] ? 'Active_Green.svg' : 'Inactive_Red.svg' }}" style="width: 15px;"/>
                            </ng-container>

                            <ng-template #otherContent>
                                NA
                            </ng-template>
                        </span>
                    </div>
                    <div class="col-sm-1 btn-wraps d-flex" style="justify-content: end;">
                        <mat-icon  class="pr-4 actions" matTooltip="Edit" (click)="showConfigPopup(item, i)" *ngIf="['Ordering Facilities', 'Send In Lab', 'Send-out Laboratories'].includes(item['AssociationType'])">create</mat-icon>
                        <mat-icon class="actions ml-3" matTooltip="Delete" (click)="deleteMethod(i, $event)">delete</mat-icon>
                    </div>
                    </div>
                </div>
                </cdk-virtual-scroll-viewport>
                <div class="col-sm-12 nodata-header-wrap" *ngIf="GridData?.length === 0">
                <span class="col-sm-12 nodata-wrapper"> No Data Available </span>
                </div>
            </div>
        </div>
    </ng-container>
    
</div>
