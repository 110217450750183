<div class="modal-dialog SetupNewCustomer">
  <div class="modal-content header-wraps">
    <!-- Modal Header -->
    <form [formGroup]="SetupNewCustomerform">
      <div class="modal-header admin-model-header header-freeze">
        <h3 class="mainTitle modal-title w-100 admin-model-header-txt">
          Quick Create New Customer
        </h3>
        <span type="button" data-dismiss="modal" (click)="SetupNewCustomerClose()" class="mr-2">
          <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24 cursor"
            title="Close">
        </span>
      </div>
      <br />
      <!-- Modal body -->
      <div class="modal-body" *ngIf="!FormDataEntry">
        <div class="container-fluid p-0 m-0">
          <div class="row mb-1 mx-0">
            <div class="col-lg-12 p-0 m-md-1 m-lg-0">
              <div class="row m-0 p-0">
                <div class="col-md-6 float-left">
                  <div class="col-md-4 p-0 m-0 pr-1 centeralign float-left">
                    <label for="DKeys3" class="centeralign">
                      <strong>
                        <!-- <span class="text-danger pr-1">*</span> -->
                        Deployment:<span class="error-msg">*</span>&nbsp;&nbsp;</strong></label>
                  </div>
                  <div class="col-md-8 p-0 m-0 float-right">
                    <!-- (change)="onChangeDep($event.target.value)"  -->
                    <select name="DeploymentKey" placeholder="Select" class="form-control radius-change"
                      formControlName="frmDeploymentKey" (change)="onChangeDep($event.target.value)">
                      <option value="" disabled selected>Select</option>
                      <option *ngFor="let i of DeploymentKeys" value="{{i}}">
                        {{ i }}
                      </option>
                    </select>
                    <!-- <div *ngFor="let dep of DeploymentKeys">
                      {{dep}}
                    </div> -->
                  </div>
                </div>
               </div>
              <div class="w-100 mx-auto mt-4 back-button">
                <button mat-raised-button class="admin-btn-success" (click)="newCustomerSetupForm()" [disabled]="
                    SetupNewCustomerform.value.frmDeploymentKey == '' ||
                    SetupNewCustomerform.value.selectLabType == 'null'
                  ">
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="modal-body FormDataEntry" *ngIf="UserEnteredDataDiv">
      <form [formGroup]="UserEnteredData" autocomplete="off" class="form-height">
        <div class="row">
          <div class="col-sm-6">

           <!-- NPI Field -->
           <div class="row">
            <div class="labelalignment col-sm-5">
              <label class="label-font">NPI
                <span class="error-msg"></span>
              </label>
            </div>
            <div class="col-sm-7">
              <div class="row">
                <div class="col-sm-12" id="input-container">
                  <button (click)="NPIValidation()"
                    [disabled]="UserEnteredData.value.npi.toString().trim() == '' || UserEnteredData.value.npi.toString().toLowerCase() == 'unknown'"><em
                      class="fa fa-check" title="Validate"></em></button>
                  <input formControlName="npi" maxlength="10" type="text "
                    class="form-control radius-change form-font" (input)="captureNPI(UserEnteredData.value.npi)" />
                </div>

              </div>
              <div *ngIf="NPIWarning">
                <span class="text-danger">NPI is not valid!</span>
              </div>
              <div *ngIf="validationWarning">
                <span class="text-danger">Please validate the NPI!</span>
              </div>
            </div>
          </div>

           <!-- Name Field -->
            <div class="row mt-3 p-0">
              <div class="labelalignment col-sm-5">
                <label class="label-font">Name
                  <span class="error-msg">*</span>
                </label>
              </div>
              <div class="col-sm-7 word-wrap">
                <input id="name" class="form-control radius-change form-font" formControlName="name"
                  (input)="generateMnemonic(UserEnteredData.value.name)" maxlength="174" />
                <div *ngIf="submitted && formcontrol.name.invalid">
                  <span class="text-danger"> Name is required!</span>
                </div>
              </div>
            </div>

            <!-- Search NPI / Registry Button  -->
            <div class="row mt-3 p-0 pr-3 justify-content-end">
              <button mat-raised-button class="admin-btn-success" (click)="openSearchNPIPopup()">
                Search NPI
              </button>
             </div>

            <div class="row mt-3 p-0 row">
              <div class="labelalignment col-sm-5">
                <label class="label-font">Display Name
                  <span class="error-msg">*</span>
                </label>
              </div>

              <div class="col-sm-7 word-wrap">
                <input id="displayname" class="form-control radius-change form-font" formControlName="displayname"
                  maxlength="200" />
                <div *ngIf="submitted && formcontrol.displayname.invalid">
                  <span class="text-danger"> Display Name is required!</span>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="labelalignment col-sm-5">
                <label class="label-font">Short Name
                  <span class="error-msg">*</span>
                </label>
              </div>
              <div class="col-sm-7">
                <input type="text" formControlName="shortname" maxlength="10"
                  class="form-control radius-change form-font" />
                <div *ngIf="submitted && formcontrol.shortname.invalid">
                  <span class="text-danger"> Short Name is required!</span>
                </div>
              </div>
            </div>

            <div class="row mt-3 p-0 row">
              <div class="labelalignment col-sm-5">
                <label class="label-font">CLIA
                  <span class="error-msg"></span>
                </label>
              </div>
              <div class="col-sm-7 async-align">
                <input formControlName="clia" maxlength="10" type="text" class="form-control radius-change form-font" />
              </div>
            </div>
            <div class="row mt-3 p-0 row">
              <div class="labelalignment col-sm-5">
                <label class="label-font">EIN</label>
              </div>
              <div class="col-sm-7">
                <input formControlName="ein" type="text" maxlength="10" class="form-control radius-change form-font" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-5">
                <label class="label-font">Primary Contact Name</label>
              </div>
              <div class="col-sm-7">
                <input formControlName="primarycontactname" maxlength="100" type="text"
                  class="form-control radius-change form-font" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="labelalignment col-sm-5">
                <label class="label-font">Primary Contact Number</label>
              </div>
              <div class="col-sm-7">
                <input formControlName="primarycontactnumber" (keypress)="AllowOnlyNumber($event)" maxlength="25"
                  type="number" class="form-control radius-change form-font" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="labelalignment col-sm-5">
                <label class="label-font">Primary Contact Email</label>
              </div>
              <div class="col-sm-7">
                <input formControlName="primarycontactemail" maxlength="50" type="email" (input)="emailValid = true"
                  class="form-control radius-change form-font" />
                <div *ngIf="!emailValid">
                  <span class="text-danger">Email is not valid!</span>
                </div>
              </div>

            </div>

            <div class="row mt-3">
              <div class="labelalignment col-sm-5">
                <label class="label-font">Active</label>
              </div>
              <div class="ml-2">
                <input type="checkbox" class="active-checkbox" formControlName="Status" disabled (change)="
                    checkboxChange(
                      UserEnteredData.value.Status,
                      $event.target.checked
                    )
                  " />
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="labelalignment col-sm-5">
                <label class="label-font">Address 1</label>
              </div>
              <div class="col-sm-7">
                <input formControlName="Address1" type="text" maxlength="100"
                  class="form-control radius-change form-font" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="labelalignment col-sm-5">
                <label class="label-font">Address 2</label>
              </div>
              <div class="col-sm-7">
                <input formControlName="Address2" type="text" maxlength="100"
                  class="form-control radius-change form-font" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="labelalignment col-sm-5">
                <label class="label-font">Zip</label>
              </div>
              <div class="col-sm-7">
                <input formControlName="zip" type="text" maxlength="10" (input)="zipValid = true"
                  class="form-control radius-change form-font" />
                <div *ngIf="ZipValidation && !zipValid">
                  <span class="text-danger">Zip is not valid!</span>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="labelalignment col-sm-5">
                <label class="label-font">Phone</label>
              </div>
              <div class="col-sm-7">
                <input formControlName="phone" (keypress)="AllowOnlyNumber($event)" maxlength="20"
                  class="form-control radius-change form-font" />
              </div>
            </div>
            <div class="row mt-3 p-0 row">
              <div class="labelalignment col-sm-5">
                <label class="label-font">State</label>
              </div>

              <div class="col-sm-7">
                <select class="form-control radius-change form-font" formControlName="state">
                  <option value="" selected>Select</option>
                  <option *ngFor="let state of StateList" value="{{ state.item }}">{{ state.item }}</option>
                </select>
              </div>
            </div>

            <div class="row mt-3 p-0 row">
              <div class="labelalignment col-sm-5">
                <label class="label-font">City</label>
              </div>

              <!-- <div class="col-sm-7">
                  <input
                    formControlName="ConnectionName"
                    type="text"
                    class="form-control radius-change form-font"
                  />
                </div> -->
              <div class="col-sm-7">
                <input formControlName="city" type="text" maxlength="25" class="form-control radius-change form-font" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="labelalignment col-sm-5">
                <label class="label-font">Country</label>
              </div>
              <div class="col-sm-7">
                <input formControlName="country" type="text" maxlength="25"
                  class="form-control radius-change form-font" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="labelalignment col-sm-5">
                <label class="label-font">Fax</label>
              </div>
              <div class="col-sm-7">
                <input formControlName="fax" (keypress)="AllowOnlyNumber($event)" (input)="faxValid = true"
                  maxlength="50" class="form-control radius-change form-font" />
                <div *ngIf="submitted && !faxValid">
                  <span class="text-danger">Fax is not valid!</span>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="labelalignment col-sm-5">
                <label class="label-font">Timezone</label>
              </div>
              <div class="col-sm-7">
                <select class="form-control radius-change form-font" formControlName="timezone">
                  <option value="" selected>Select</option>
                  <option *ngFor="let timezone of Timezones" value="{{ timezone.item }}">{{ timezone.item }}</option>
                </select>
              </div>
            </div>
            <div class="row mt-3">
              <div class="labelalignment col-sm-5">
                <label class="label-font">Billing Phone Number</label>
              </div>
              <div class="col-sm-7">
                <input formControlName="billingphonenumber" (keypress)="AllowOnlyNumber($event)" maxlength="20"
                  class="form-control radius-change form-font" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="labelalignment col-sm-5">
                <label class="label-font">Logo</label>
              </div>
              <div class="col-sm-7">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="row grid-fix">
                      <div class="col-sm-7 float-left">
                        <input #imageInput ID="myFileInputID" type="file" formControlName="logo"
                          (change)="getuploadedfile($event)" accept="image/*" class="outlinecontainer" />
                        <p class="subFont col-sm-12 p-0">Supported formats : .png, .jpg, .jpeg</p>
                      </div>
                      <div *ngIf="imageUploaded" class="col-sm-5 imagePreviewDiv float-right">
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="row">
                              <div class="col-sm-6 float-left">
                                <img alt="image" src="{{imagePreview}}" class="previewImage">
                              </div>
                              <div class="col-sm-6 float-right">
                                <a class="text-danger clearImage" (click)="clearImage()">x</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3 add-form-align">
          <div class="col-sm-12">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header class="accordianheader">
                  <mat-panel-title class="mainTitle">
                    Additional Details
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="mt-2 col-6 p-0 m-0 pull-left">
                  <div class="row p-0 m-0">
                    <div class="labelalignment col-sm-5 p-0 m-0">
                      <!-- <div class="labelalignment"> -->
                      <label class="label-font ">Mnemonic<span class="text-danger pr-1">*</span></label>
                    </div>
                    <div class="col-sm-6 p-0 m-0">
                      <input [(ngModel)]="UserEnteredData.value.mnemonic" formControlName="mnemonic" maxlength="25"
                        type="text" class="form-control radius-change form-font" />
                      <div *ngIf="submitted && formcontrol.mnemonic.invalid">
                        <span class="text-danger"> Mnemonic is required!</span>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3 row p-0 m-0">
                    <div class="labelalignment col-sm-5 p-0 m-0">
                      <!-- <div class="labelalignment"> -->
                      <label class="label-font ">Batching Mnemonic</label>
                    </div>
                    <div class="col-sm-6 p-0 m-0">
                      <input formControlName="batchingmnemonic" maxlength="200" type="text"
                        class="form-control radius-change form-font" />
                    </div>
                  </div>
                  <div class="mt-3 row p-0 m-0">
                    <div class="labelalignment col-sm-5 p-0 m-0">
                      <!-- <div class="labelalignment"> -->
                      <label class="label-font ">Taxonomy Code</label>
                    </div>
                    <div class="col-sm-6 p-0 m-0">
                      <input formControlName="taxonomycode" maxlength="10" type="text"
                        class="form-control radius-change form-font" />
                    </div>
                  </div>
                  <div class="mt-3 row p-0 m-0">
                    <div class="labelalignment col-sm-5 p-0 m-0">
                      <!-- <div class="labelalignment"> -->
                      <label class="label-font ">Support ID</label>
                    </div>
                    <div class="col-sm-6 p-0 m-0">
                      <input formControlName="supportid" maxlength="10" type="text"
                        class="form-control radius-change form-font" />
                    </div>
                  </div>
                  <div class="mt-3 row p-0 m-0">
                    <div class="labelalignment col-sm-5 p-0 m-0">
                      <!-- <div class="labelalignment"> -->
                      <label class="label-font ">Claim Options</label>
                    </div>
                    <div class="col-sm-6 p-0 m-0">
                      <select class="form-control radius-change form-font selectheight" formControlName="claimoptions">
                        <option selected value="Track Claim">Track Claim</option>
                        <option value="Submit For Billing">Submit For Billing</option>
                      </select>
                    </div>
                  </div>
                  <div class="mt-3 row p-0 m-0">
                    <div class="labelalignment col-sm-5 p-0 m-0">
                      <!-- <div class="labelalignment"> -->
                      <label class="label-font ">Claim Mnemonics</label>
                    </div>
                    <div class="col-sm-6 p-0 m-0">
                      <input formControlName="claimmnemonics" maxlength="2" type="text"
                        class="form-control radius-change form-font" />
                    </div>
                  </div>
                  <div class="mt-3 row p-0 m-0">
                    <div class="labelalignment col-sm-5 p-0 m-0">
                      <!-- <div class="labelalignment"> -->
                      <label class="label-font ">CLPOL</label>
                    </div>
                    <div class="col-sm-6 p-0 m-0">
                      <input formControlName="clpol" maxlength="50" type="text"
                        class="form-control radius-change form-font" />
                    </div>
                  </div>
                  <div class="mt-3 row p-0 m-0">
                    <div class="labelalignment col-sm-5 p-0 m-0">
                      <!-- <div class="labelalignment"> -->
                      <label class="label-font ">Report Data</label>
                    </div>
                    <div class="col-sm-6 p-0 m-0">
                      <input formControlName="reportdata" maxlength="100" type="text"
                        class="form-control radius-change form-font" />
                    </div>
                  </div>
                  <div class="mt-3 col-sm-12">
                  </div>
                  <div class="mt-3 col-sm-12">
                  </div>
                  <div class="mt-3  row p-0 m-0">
                    <div class="col-sm-5 p-0 m-0">
                      <!-- <div class="labelalignment"> -->
                      <p><u><strong>Payment Address Details</strong></u></p>
                    </div>
                    <div class=" col-sm-6 p-0 m-0"></div>
                  </div>
                  <div class="mt-3  row p-0 m-0">
                    <div class="labelalignment col-sm-5 p-0 m-0">
                      <label class="label-font ">Address 1</label>
                    </div>
                    <div class=" col-sm-6 p-0 m-0">
                      <input formControlName="payaddress1" maxlength="100" type="text"
                        class="form-control radius-change form-font" />
                    </div>
                  </div>
                  <div class="mt-3  row p-0 m-0">
                    <div class="labelalignment col-sm-5 p-0 m-0">
                      <!-- <div class="labelalignment"> -->
                      <label class="label-font ">Address 2</label>
                    </div>
                    <div class=" col-sm-6 p-0 m-0">
                      <input formControlName="payaddress2" maxlength="100" type="text"
                        class="form-control radius-change form-font" />
                    </div>
                  </div>
                  <div class="mt-3  row p-0 m-0">
                    <div class="labelalignment col-sm-5 p-0 m-0">
                      <!-- <div class="labelalignment"> -->
                      <label class="label-font ">City</label>
                    </div>
                    <div class=" col-sm-6 p-0 m-0">
                      <input formControlName="paycity" maxlength="100" type="text"
                        class="form-control radius-change form-font" />
                    </div>
                  </div>
                  <div class="mt-3  row p-0 m-0">
                    <div class="labelalignment col-sm-5 p-0 m-0">
                      <!-- <div class="labelalignment"> -->
                      <label class="label-font ">State</label>
                    </div>
                    <div class=" col-sm-6 p-0 m-0">
                      <input formControlName="paystate" maxlength="100" type="text"
                        class="form-control radius-change form-font" />
                    </div>
                  </div>
                  <div class="mt-3  row p-0 m-0">
                    <div class="labelalignment col-sm-5 p-0 m-0">
                      <!-- <div class="labelalignment"> -->
                      <label class="label-font ">Zip</label>
                    </div>
                    <div class=" col-sm-6 p-0 m-0">
                      <input formControlName="payzip" maxlength="100" type="text"
                        class="form-control radius-change form-font" (input)="payZipValid = true" />
                      <div *ngIf="payZipValidation && !payZipValid">
                        <span class="text-danger">Zip is not valid!</span>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="mt-2 col-6 p-0 m-0 pull-right">
                  <div class="pl-3 row p-0 m-0">
                    <div class="labelalignment col-sm-5 p-0 m-0">
                      <!-- <div class="labelalignment"> -->
                      <label class="label-font ">State License Number</label>
                    </div>
                    <div class="pl-4 col-sm-6 p-0 m-0">
                      <input formControlName="statelicensenumber" maxlength="50" type="text"
                        class="form-control radius-change form-font" />
                    </div>
                  </div>
                  <div class="mt-3 pl-3 row p-0 m-0">
                    <div class="labelalignment col-sm-5 p-0 m-0">
                      <!-- <div class="labelalignment"> -->
                      <label class="label-font ">Username Display Format (Web Pages)</label>
                    </div>
                    <div class="pl-4 col-sm-6 p-0 m-0">
                      <select class="form-control radius-change form-font selectheight"
                        formControlName="usernameDisplayFormat">
                        <option value="" selected>Select</option>
                        <option *ngFor="let usernameformat of UsernameWebFormat" value="{{ usernameformat.item }}">{{
                          usernameformat.item }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="mt-3 pl-3 row p-0 m-0 mt-2">
                    <div class="labelalignment col-sm-5 p-0 m-0">
                      <!-- <div class="labelalignment"> -->
                      <label class="label-font ">Username Display Format (Reports)</label>
                    </div>
                    <div class="pl-4 col-sm-6 p-0 m-0">
                      <select class="form-control radius-change form-font selectheight"
                        formControlName="usernameReportDisplayFormat">
                        <option value="" selected>Select</option>
                        <option *ngFor="let usernamereportformat of UsernameWebFormat"
                          value="{{ usernamereportformat.item }}">{{
                          usernamereportformat.item }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="mt-3 pl-3 row p-0 m-0 mt-2">
                    <div class="labelalignment col-sm-5 p-0 m-0">
                      <!-- <div class="labelalignment"> -->
                      <label class="label-font ">Collection Date Format</label>
                    </div>
                    <div class="pl-4 col-sm-6 p-0 m-0">
                      <select class="form-control radius-change form-font selectheight"
                        formControlName="collectiondateformat">
                        <option value="" selected>Select</option>
                        <option *ngFor="let i of dateFormat" value="{{i}}">
                          {{ i }}
                        </option>
                      </select>
                      <!-- <input formControlName="collectiondateformat" maxlength="12" type="text" class="form-control radius-change form-font" /> -->
                    </div>
                  </div>
                  <div class="mt-3 pl-3 row p-0 m-0 mt-2">
                    <div class="labelalignment col-sm-5 p-0 m-0">
                      <!-- <div class="labelalignment"> -->
                      <label class="label-font ">Package Date Format</label>
                    </div>
                    <div class="pl-4 col-sm-6 p-0 m-0">
                      <select class="form-control radius-change form-font selectheight"
                        formControlName="packagedateformat">
                        <option value="" selected>Select</option>
                        <option *ngFor="let i of dateFormat" value="{{i}}">
                          {{ i }}
                        </option>
                      </select>
                      <!-- <input formControlName="packagedateformat" maxlength="12" type="text" class="form-control radius-change form-font" /> -->
                    </div>
                  </div>
                  <div class="mt-3 pl-3 row p-0 m-0 mt-2">
                    <div class="labelalignment col-sm-5 p-0 m-0">
                      <!-- <div class="labelalignment"> -->
                      <label class="label-font ">ETA Date Format</label>
                    </div>
                    <div class="pl-4 col-sm-6 p-0 m-0">
                      <select class="form-control radius-change form-font selectheight" formControlName="etadateformat">
                        <option value="" selected>Select</option>
                        <option *ngFor="let i of dateFormat" value="{{i}}">
                          {{ i }}
                        </option>
                      </select>
                      <!-- <input formControlName="etadateformat" maxlength="12" type="text" class="form-control radius-change form-font" /> -->
                    </div>
                  </div>
                  <div class="mt-3 pl-3 row p-0 m-0">
                    <div class="labelalignment col-sm-5 p-0 m-0">
                      <!-- <div class="labelalignment"> -->
                      <label class="label-font ">Enabled Fax</label>
                    </div>
                    <div class="radioalignment pl-4 p-0 m-0">
                      <input formControlName="enabledfax" type="checkbox" class="float-left" (change)="
                            checkboxChange(
                              UserEnteredData.value.enabledfax,
                              $event.target.checked
                            )
                          " />
                    </div>
                  </div>
                  <div class="mt-3 pl-3 row p-0 m-0">
                    <div class="labelalignment col-sm-5 p-0 m-0">
                      <!-- <div class="labelalignment"> -->
                      <label class="label-font ">Shared Surgical Center</label>
                    </div>
                    <div class="radioalignment pl-4 p-0 m-0">
                      <input formControlName="sharedsurgicalcenter" type="checkbox" class="float-left" (change)="
                            checkboxChange(
                              UserEnteredData.value.sharedsurgicalcenter,
                              $event.target.checked
                            )
                          " />
                    </div>
                  </div>
                  <div class="mt-3 pl-3 row p-0 m-0">
                    <div class="labelalignment col-sm-5 p-0 m-0">
                      <!-- <div class="labelalignment"> -->
                      <label class="label-font ">Enabled Send Out</label>
                    </div>
                    <div class="radioalignment pl-4 p-0 m-0">
                      <input formControlName="enabledsendout" type="checkbox" class="float-left" (change)="
                            checkboxChange(
                              UserEnteredData.value.enabledsendout,
                              $event.target.checked
                            )
                          " />
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <div class="col-sm-12">
              <div class="row mt-4">
                <div class="col-sm-6 mx-auto">
                  <button mat-raised-button class="admin-btn-success mr-4 float-right" (click)="deploymentSelection()">
                    Back
                  </button>
                </div>
                <div class="col-sm-6 w-75 mx-auto create-button">
                  <button mat-raised-button class="admin-btn-success" (click)="quicksetupnewlab(UserEnteredData.value)">
                    Quick Create
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </form>
    </div>
    <div class="setupSummary" *ngIf="resultPage">
      <div class="col-sm-12 align-items-sm-center">
        <mat-card class="position-relative">
          <mat-header class="mat-header">
            Lab Group Details
          </mat-header>
          <div class="row mt-3">
            <div class="col-sm-4 logodiv">

              <div class="col-sm-9 action_btns align-center test1"  *ngIf="Logo != ''" style="background: none;">
                <div>
                  <img alt="image" class="fitImage" id="logo" [src]="Logo" />
                </div>



                <div class="iconsContainer">
                  <div class="lineSeparator"></div>
                  <img src="/assets/icons/user_icons/download.svg" alt="" height="20" (click)="downloadLogo()" class="cursor">
                </div>
                <!-- <div (click)="downloadLogo()">
                  <img src="/assets/icons/user_icons/download.svg" alt="" height="20">
                </div> -->
              </div>

              <!-- <div *ngIf="Logo != ''" style="background: none;">
                <img alt="image" class="fitImage" id="logo" [src]="Logo" />
                <mat-icon class="cloud-download" (click)="downloadLogo()" matTooltip="Download">cloud_download</mat-icon>
              </div> -->
              <div *ngIf="Logo == ''">
                <p>No Image Found</p>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">ID </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="Lab_Organization_ID">{{
                      Lab_Organization_ID
                      }}</label>
                    <img alt="copy" class="copyIcon cursor" (click)="copy(Lab_Organization_ID)"
                      src="/assets/images/copy.svg" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">Name </label>
                  </div>
                  <div class="col-sm-8 word-wrap">
                    <label value="Lab_Organization_Name">{{
                      Lab_Organization_Name
                      }}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">Mnemonic </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="LabMnemonic">{{ LabMnemonic }}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">Address 1 </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="Address1">{{ Address1 }}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">Address 2 </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="Address2">{{ Address2 }}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">City </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="City">{{ City }}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">State </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="State">{{ State }}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">Zip </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="Zip">{{ Zip }}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">Phone </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="Phone">{{ Phone }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">Display Name </label>
                  </div>
                  <div class="col-sm-8 word-wrap">
                    <label value="DisplayName">{{ DisplayName }}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">GUID </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="Lab_Organization_GUID">{{
                      Lab_Organization_GUID
                      }}</label>
                    <img alt="copy" class="copyIcon cursor" (click)="copy(Lab_Organization_GUID)"
                      src="/assets/images/copy.svg" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">NPI </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="LabOrgNPI">{{ LabOrgNPI }}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">CLIA# </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="LabOrgClia">{{ LabOrgClia }}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">Location Count </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="Number_of_locations">{{
                      LabNo_of_Locations
                      }}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">User Count </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="LabNo_of_users">{{ LabNo_of_users }}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">Case Type </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="CaseType">{{ CaseType }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
        <mat-card class="mt-3">
          <mat-header class="mat-header">
            Ordering Facility Group Details
          </mat-header>
          <div class="row mt-3">
            <div class="col-sm-4 logodiv">

              <!-- <div class="col-sm-12 action_btns align-center"  *ngIf="Logo != ''" style="background: none;">
                <img alt="image" class="fitImage" id="logo" [src]="Logo" />
                <div class="col-sm-3" (click)="downloadLogo()">
                  <img src="/assets/icons/user_icons/download.svg" alt="" width="100" height="20" class="dropdown-wrap">
                </div>
              </div> -->


              <div class="col-sm-9 action_btns align-center test1"  *ngIf="Logo != ''" style="background: none;">
                <div>
                  <img alt="image" class="fitImage" id="logo" [src]="Logo" />
                </div>



                <div class="iconsContainer">
                  <div class="lineSeparator"></div>
                  <img src="/assets/icons/user_icons/download.svg" alt="" height="20" (click)="downloadLogo()" class="cursor">
                </div>
                <!-- <div (click)="downloadLogo()">
                  <img src="/assets/icons/user_icons/download.svg" alt="" height="20">
                </div> -->
              </div>

              <!-- <div *ngIf="Logo != ''" style="background: none;">
                <img alt="image" class="fitImage" id="logo" [src]="Logo" />
                <mat-icon class="cloud-download" (click)="downloadLogo()" matTooltip="Download">cloud_download</mat-icon>

              </div> -->
              <div *ngIf="Logo == ''">
                <p>No Image Found</p>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">ID </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="OF_Organization_ID">{{
                      OF_Organization_ID
                      }}</label>
                    <img alt="copy" class="copyIcon cursor" (click)="copy(OF_Organization_ID)"
                      src="/assets/images/copy.svg" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">Name </label>
                  </div>
                  <div class="col-sm-8 word-wrap">
                    <label value="OF_Organization_Name">{{
                      OF_Organization_Name
                      }}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">Mnemonic </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="OFMnemonic">{{ OFMnemonic }}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">Address 1 </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="Address1">Not Specified</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">Address 2 </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="Address2">Not Specified</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">City </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="City">Not Specified</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">State </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="State">Not Specified</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">Zip </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="Zip">Not Specified</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">Phone </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="Phone">Not Specified</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">Display Name </label>
                  </div>
                  <div class="col-sm-8 word-wrap">
                    <label value="DisplayName">{{ DisplayName }}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">GUID </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="OF_Organization_GUID">{{
                      OF_Organization_GUID
                      }}</label>
                    <img alt="copy" class="copyIcon cursor" (click)="copy(OF_Organization_GUID)"
                      src="/assets/images/copy.svg" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">NPI </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="OFOrgNPI">{{ OFOrgNPI }}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">CLIA# </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="OFOrgClia">{{ OFOrgClia }}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">Location count </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="Number_of_locations">{{
                      LabNo_of_Locations
                      }}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">User count </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="OFNo_of_users">{{ OFNo_of_users }}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">Case Type </label>
                  </div>
                  <div class="col-sm-8">
                    <label value="CaseType">{{ CaseType }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
        <div class="row mt-3">
          <div class="col-sm-6">
            <mat-card>
              <mat-header class="mat-header">
                Laboratory Details
              </mat-header>
              <div class="col-sm-12 mt-3">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-4">
                            <label class="label-font">ID </label>
                          </div>
                          <div class="col-sm-8">
                            <span value="Lab_Account_ID">{{
                              Lab_Account_ID
                              }}</span>
                            <img alt="copy" class="copyIcon cursor" (click)="copy(Lab_Account_ID)"
                              src="/assets/images/copy.svg" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-4">
                            <label class="label-font">GUID </label>
                          </div>
                          <div class="col-sm-8">
                            <span value="Lab_Account_GUID">{{
                              Lab_Account_GUID
                              }}</span>
                            <img alt="copy" class="copyIcon cursor" (click)="copy(Lab_Account_GUID)"
                              src="/assets/images/copy.svg" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-4">
                            <label class="label-font">Name </label>
                          </div>
                          <div class="col-sm-8 word-wrap">
                            <span value="Lab_Account_Name">{{
                              Lab_Account_Name
                              }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row">
                      <div class="col-sm-12 m-0 p-0">
                        <div class="row">
                          <div class="col-sm-5">
                            <label class="label-font">Address 1 </label>
                          </div>
                          <div class="col-sm-7">
                            <span vaue="Address1">{{ Address1 }}</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-5">
                            <label class="label-font">Address 2 </label>
                          </div>
                          <div class="col-sm-7">
                            <span value="Address2">{{ Address2 }}</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-5">
                            <label class="label-font">City </label>
                          </div>
                          <div class="col-sm-7">
                            <span value="City">{{ City }}</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-5">
                            <label class="label-font">State </label>
                          </div>
                          <div class="col-sm-7">
                            <span value="State">{{ State }}</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-5">
                            <label class="label-font">Zip </label>
                          </div>
                          <div class="col-sm-7">
                            <span value="Zip">{{ Zip }}</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-5">
                            <label class="label-font">Phone </label>
                          </div>
                          <div class="col-sm-7">
                            <span value="Phone">{{ Phone }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
          <div class="col-sm-6">
            <mat-card>
              <mat-header class="mat-header">
                Ordering Facility Details
              </mat-header>
              <div class="col-sm-12 mt-3">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-4">
                            <label class="label-font">ID </label>
                          </div>
                          <div class="col-sm-8">
                            <span value="OF_Account_ID">{{
                              OF_Account_ID
                              }}</span>
                            <img alt="copy" class="copyIcon cursor" (click)="copy(OF_Account_ID)"
                              src="/assets/images/copy.svg" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-4">
                            <label class="label-font">GUID </label>
                          </div>
                          <div class="col-sm-8">
                            <span value="OF_Account_GUID">{{
                              OF_Account_GUID
                              }}</span>
                            <img alt="copy" class="copyIcon cursor" (click)="copy(OF_Account_GUID)"
                              src="/assets/images/copy.svg" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-4">
                            <label class="label-font">Name </label>
                          </div>
                          <div class="col-sm-8 word-wrap">
                            <span value="OF_Account_Name">{{
                              OF_Account_Name
                              }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row">
                      <div class="col-sm-12  m-0 p-0">
                        <div class="row">
                          <div class="col-sm-5">
                            <label class="label-font">Address 1 </label>
                          </div>
                          <div class="col-sm-7">
                            <span value="Address1">Not Specified</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-5">
                            <label class="label-font">Address 2 </label>
                          </div>
                          <div class="col-sm-7">
                            <span value="Address2">Not Specified</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-5">
                            <label class="label-font">City </label>
                          </div>
                          <div class="col-sm-7">
                            <span value="City">Not Specified</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-5">
                            <label class="label-font">State </label>
                          </div>
                          <div class="col-sm-7">
                            <span value="State">Not Specified</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-5">
                            <label class="label-font">Zip </label>
                          </div>
                          <div class="col-sm-7">
                            <span value="Zip">Not Specified</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-5">
                            <label class="label-font">Phone </label>
                          </div>
                          <div class="col-sm-7">
                            <span value="Phone">Not Specified</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
        <div class="mb-3 row mt-3">
          <div class="col-sm-6">
            <mat-card>
              <mat-header class="mat-header">
                Lab User Details
              </mat-header>
              <div class="col-sm-12 mt-3">
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">Name </label>
                  </div>
                  <div class="col-sm-8 word-wrap">
                    <span value="Lab_UserName">{{ Lab_UserName }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">Roles </label>
                  </div>
                  <div class="col-sm-8">
                    <span value="Lab_UserRoles">{{ Lab_UserRoles }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">Email </label>
                  </div>
                  <div class="col-sm-8">
                    <span value="Lab_UserEmail">{{ Lab_UserEmail }}</span>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
          <div class="col-sm-6">
            <mat-card>
              <mat-header class="mat-header">
                Ordering Facility User Details
              </mat-header>
              <div class="col-sm-12 mt-3">
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">Name </label>
                  </div>
                  <div class="col-sm-8 word-wrap">
                    <span value="OF_UserName">{{ OF_UserName }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">Role </label>
                  </div>
                  <div class="col-sm-8">
                    <span value="OF_UserRole">{{ OF_UserRole }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="label-font">Email </label>
                  </div>
                  <div class="col-sm-8">
                    <span value="OF_UserEmail">{{ OF_UserEmail }}</span>

                  </div>
                </div>
              </div>
            </mat-card>
          </div>
          <div class="w-75 mx-auto mt-4 back-button">
            <button mat-raised-button class="admin-btn-success mr-4" (click)="downloadSetupDetails()">Download</button>
            <button mat-raised-button class="admin-btn-success" (click)="SetupNewCustomerClose()">
              Finish Quick Create
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
