<div class="p-2 infodetails">
  <mat-spinner class="labadmin-spinner loader-aligned" *ngIf="!checkInfoPreference()" [diameter]="50">
  </mat-spinner>
  <div class="preference_contianer">
    <!-- // show only for Pathologist,  Cytotechnologist Supervisor & Cytotechnologist role -->
    <div >
      <div class="mb-3" *ngIf="(isPathologistChecked || isCTAndCTSRoleExis) && isPathologistPrivilageExists">
        <span class="label_border _600">Threshold</span>
        <div class="thresold_table">
          <div class="thresold_header">
            <div class="threshold-design ml-2 p-1 row mx-0">
              <div>Work</div>
              <div>Threshold</div>
              <div>Duration</div>
              <div>Compute on</div>
            </div>
          </div>
          <div class="thresold_body p-3 ml-2">
            <div class="row  threshold-design" *ngIf="isPathologistChecked">
              <div class="_600 ">Reading</div>
              <div>
                <span class="value-box text-sm  duration-computeon-width">
                  {{userDetails['Threshold']}}</span>
              </div>
              <div>
                <span class="duration-style">
                  <span>Per</span>
                  <span class="value-box  text-sm duration-computeon-width">
                    {{userDetails['ThresholdDuration']|| '-'}}</span>
                </span>
              </div>
              <div>
                <span class="value-box  text-sm duration-computeon-width">
                  {{userDetails['ThresholdComputeOn']}}</span>
              </div>
            </div>
            <div class="row threshold-design" *ngIf="isPathologistChecked">
              <div class=" _600 ">Reviewing</div>
              <div>
                <span class="value-box text-sm  duration-computeon-width">
                  {{userDetails['ReviewThreshold']}}</span>
              </div>
              <div>
                <span class="duration-style">
                  <span>Per</span>
                  <span class="value-box  text-sm duration-computeon-width">
                    {{userDetails['ReviewThresholdDuration'] || '-'}}</span>
                </span>
              </div>
              <div>
                <span class="value-box  duration-computeon-width"> {{userDetails['ReviewComputeOn']}}</span>
              </div>
            </div>
            <div class="row threshold-design" *ngIf="isCTAndCTSRoleExis">
              <div class=" _600 ">Screening</div>
              <div>
                <span class="value-box text-sm  duration-computeon-width">
                  {{userDetails['ScreenThreshold']}}</span>
              </div>
              <div>
                <span class="duration-style">
                  <span>Per</span>
                  <span class="value-box  text-sm duration-computeon-width">
                    {{userDetails['ScreenThresholdDuration']|| '-'}}</span>
                </span>
              </div>
              <div>
                <span class="value-box  duration-computeon-width "> {{userDetails['ScreenComputeOn']}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3" *ngIf="isPathologistChecked">
        <div class="col-sm-6">
          <span class="label_border _600">Licensure</span>
          <div class="thresold_table w-100">
            <div class="thresold_header">
              <div class="row mx-0 ml-2 p-1">
                <div class="col-sm-6">States</div>
                <div class="col-sm-6">Expiration Date</div>
              </div>
            </div>
            <div class="thresold_body p-3 ml-2">
              <div class="lic-OOO-overlay" *ngIf="LicensureList?.length>0">
                <div class="row px-3 mb-2" *ngFor="let item of LicensureList">
                  <div class=" col-sm-6 pl-1">
                    <span class="value-box duration-computeon-width text-sm" style="width: auto;">{{item.Statefullname}}</span>
                  </div>
                  <div class="col-sm-6">
                    <span class="value-box duration-computeon-width text-sm">{{ item?.Expirydate | date:'MM/dd/yyyy' }}
                    </span>
                  </div>
                </div>
              </div>
              <div *ngIf="LicensureList.length ==0" class="align-center no-data-founds">
                No Data Available
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6" *ngIf="isPathologistPrivilageExists">
          <span class="label_border _600">Out of Office</span>
          <div class="thresold_table w-100">
            <div class="thresold_header">
              <div class="row mx-0 ml-2 p-1">
                <div class="col-sm-6">Start Date</div>
                <div class="col-sm-6">End Date</div>
              </div>
            </div>

            <div class="thresold_body p-3 ml-2">
              <div class="lic-OOO-overlay" *ngIf="outOfoffice?.length>0">
                <div class="row px-3 mb-2" *ngFor="let item of outOfoffice">
                  <div class=" col-sm-6 pl-1">
                    <span class="value-box duration-computeon-width text-sm">
                      {{ item?.StartDate }}
                    </span>
                  </div>
                  <div class="col-sm-6">
                    <span class="value-box duration-computeon-width text-sm"> {{item.EndDate}}</span>
                  </div>
                </div>
              </div>
              <div *ngIf="outOfoffice.length ==0" class="align-center no-data-founds">
                No Data Available
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class=" text-sm">
      <span class="label_border _600 " *ngIf="showSettings || (isLabAdministratorChecked && isManagePathologistPrivilegesForLabAdmin )">Settings</span>
      <div>
        <div class="settingStyle mb-2" *ngIf="isPhysicianChecked || isPathologistChecked">
          <span>Show my Cases only</span>
          <app-toggle-switch type="admin-primary" isDisable=true [isChecked]='ShowMyCaseOnly'
            class="">
          </app-toggle-switch>
        </div>

        <div class="settingStyle mb-2" *ngIf="isPathologistChecked">
          <span>Allow to Edit Accession, Site, Grossing, and Processing information </span>
          <app-toggle-switch type="admin-primary" isDisable=true [isChecked]='editAccession' class="">
          </app-toggle-switch>
        </div>

        <div class="settingStyle mb-2" *ngIf="isPathologistChecked">
          <span>Allow Review Pathologist to Sign-out Review Cases </span>
          <app-toggle-switch type="admin-primary" isDisable=true
            [isChecked]='AllowReviewPathCanSignOut'
            class=""></app-toggle-switch>
        </div>

        <div class="settingStyle mb-2" *ngIf="isCTAndCTSRoleExis || isPathologistChecked">
          <span>Notify users to provide Diagnostic ICD Codes </span>
          <app-toggle-switch type="admin-primary" isDisable=true
            [isChecked]='DiagICDcodeReminder' class="">
          </app-toggle-switch>
        </div>

        <div class="settingStyle mb-2" *ngIf="isCTAndCTSRoleExis || isPathologistChecked">
          <span>Show Interpretation as Tooltip in Diagnosis Drop-Down </span>
          <app-toggle-switch type="admin-primary" isDisable=true [isChecked]=' userDetails.ShowInterpretationHelp' class="">
          </app-toggle-switch>
        </div>

        <div class="settingStyle mb-2" *ngIf="isLabAdministratorChecked && isManagePathologistPrivilegesForLabAdmin">
          <span>Manage Pathologist Privileges</span>
          <app-toggle-switch type="admin-primary"  isDisable=true
            [isChecked]='ManagePathologistPrivilagesvalue'
            class=""></app-toggle-switch>
        </div>
        <div class="settingStyle mb-2" *ngIf="isCTAndCTSRoleExis || isPathologistChecked || isPathologistAssistantChecked">
        <span>Default action upon searching for a slide ID of another case</span>
        <span class="font-size-weight">{{userDetails.CaseRedirectionAction ? userDetails.CaseRedirectionAction:'Save' }} </span>
      </div>

        <div class="col-sm-12 p-0 settingStyle mb-2" *ngIf="isLabManagerChecked || isPathologistChecked || isCTAndCTSRoleExis">
          <span class="col-sm-6 p-0">Hold Release Duration</span>
          <span class="font-size-weight">{{HoldrealseDuration}} <span>&nbsp;minute(s)<br /></span></span>
        </div>
      </div>
      <div class="col-sm-12 nodata-header-wrap" *ngIf="!showSettings && !(isLabAdministratorChecked && isManagePathologistPrivilegesForLabAdmin)">
        <span class="col-sm-12 nodata-wrapper">No Data Available</span>
      </div>
    </div>
  </div>

</div>