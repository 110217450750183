<div *ngIf="gridShow">
    <div class="row">
        <div class="col-md-12">
            <div class="modal-header admin-model-header mt-3">
                <h3 class="modal-title w-100 admin-model-header-txt">
                    <strong>Screening Types</strong>
                </h3>
            </div>
        </div>
    </div>
    <br>
    <div class="col-lg-12 p-0 m-md-1 m-lg-0">
        <wj-flex-grid [headersVisibility]="'Column'" #grid [isReadOnly]="true" (initialized)="initGrid(grid)" [itemsSource]="gridData">
            <wj-flex-grid-column [binding]="columnname" *ngFor="let columnname of gridheader" [width]="'*'" [fixed]="true"
                [allowResizing]="true" [allowDragging]="false" [format]="'d'" [visible]="!(columnname == 'AttributeId') && !(columnname == 'Procedure')">
                <!-- && !(columnname == 'CPTCodes') && !(columnname == 'TestName') -->
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="'Actions'" align="center" [freeze]="true" [width]="80" [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button (click)="editClicked(row)" class="edit-delete-btn" [disabled]="editBtn">
                        <em id="editButton" [title]="'Edit'" class="edit-template cursor p-0 fa fa-edit"></em>
                    </button>
                    <button (click)="deleteScreeningType(row)" class="edit-delete-btn" [disabled]="removeBtn">
                        <em id="deleteButton" [title]="'Delete'" class="p-0 fa fa-trash"></em>
                    </button>
                </ng-template>
            </wj-flex-grid-column>

            <!-- <wj-flex-grid-column [header]="'Action'" align="center" [freeze]="true" [width]="80" [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button (click)="deleteScreeningType(row)" class="edit-delete-btn">
                        <em id="deleteButton" [title]="'Delete'" class="p-0 fa fa-trash"></em>
                    </button>
                </ng-template>
            </wj-flex-grid-column> -->
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>
        <div class="align-center">
            <wj-collection-view-navigator *ngIf="showPaginationMainGrid" class="mr-2" headerFormat="Page {currentPage:n0} of {pageCount:n0}"
                [byPage]="true" [cv]="gridData">
            </wj-collection-view-navigator>
            <wj-menu [(value)]="gridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
                <wj-menu-item [value]="0">No Paging</wj-menu-item>
                <wj-menu-item [value]="10">10</wj-menu-item>
                <wj-menu-item [value]="15">15</wj-menu-item>
                <wj-menu-item [value]="30">30</wj-menu-item>
                <wj-menu-item [value]="50">50</wj-menu-item>
            </wj-menu>
        </div>
    </div>
    <div class="col-12 mt-4 align-center">
        <div class="row button-align">
            <button mat-raised-button class="admin-btn-success mr-4" type="button" [disabled]="exportBtn" (click)="ExportExcel(grid)">Export</button>
            <button mat-raised-button class="admin-btn-success mr-4" type="button" [disabled]="createBtn" (click)="insertClicked()">Create</button>
            <button mat-raised-button class="admin-btn-success mr-4" type="button" [disabled]="uploadBtn" (click)="loadUploadScreen()">Upload</button>
            <button title="Copy From Other Entity" mat-raised-button class="admin-btn-success" [disabled]="copyBtn"
                (click)="loadCopyScreen()">Copy</button>
        </div>
    </div>
</div>
<!-- No data if data not present -->
<div class="col-sm-12" *ngIf="noDataFound">
    <div class="modal-header admin-model-header mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt">
            <strong>Screening Types</strong>
        </h3>
    </div>
    <div class="container create-btn col-sm-12 align-center" *ngIf="noDataFound">
        <div class="col-sm-10 nodata-wrap no-data-wraps mt-4">
            <div class="nodata-design">No Data Found</div>
        </div>
    </div>
    <div class="col-12 mt-4 align-center">
        <div class="row button-align">
            <!-- <button mat-raised-button class="admin-btn-success mr-4" [disabled]="exportBtn" type="button" (click)="ExportExcel(grid)">Export</button> -->
            <button mat-raised-button class="admin-btn-success mr-4" type="button" [disabled]="createBtn" (click)="insertClicked()">Create</button>
            <button mat-raised-button class="admin-btn-success mr-4" type="button" [disabled]="uploadBtn" (click)="loadUploadScreen()">Upload</button>
            <button title="Copy From Other Entity" mat-raised-button class="admin-btn-success" [disabled]="copyBtn"
                (click)="loadCopyScreen()">Copy</button>
        </div>
    </div>
</div>
<!-- Insert form -->
<div *ngIf="enableInsert" class="col-sm-12 m-0 p-0">
    <div class="modal-header admin-model-header mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt">
            <strong>Create Screening Types</strong>
        </h3>
    </div>
    <div>
        <form [formGroup]="addScreeningType" autocomplete="off">
            <div class="text-wrap-icdcode row col-sm-12 p-0 ml-0">
                <div class="col-sm-12 mt-2">
                    <mat-form-field *ngIf="isPapCasetype" appearance="outline" class="col-sm-4 ext-height">
                        <mat-label><strong>Extraction Procedure</strong></mat-label>
                        <mat-select placeholder="Select" disableOptionCentering #matSelectOptionExn formControlName="frmExnprocedure"
                            [ngModelOptions]="{standalone: true}" multiple>
                            <div class="select-container">
                                <mat-form-field class="w-100">
                                    <input #search autocomplete="off" placeholder="Search" aria-label="Search" matInput
                                        formControlName="frmExnprocedure" />
                                    <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear"
                                        (click)="clearSearch($event, 'Extn')">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </mat-form-field>
                                <mat-optgroup *ngIf="filterExtnProcedure(search.value).length == 0">
                                    <div>No result found!</div>
                                </mat-optgroup>
                                <div class="select-all">
                                    <mat-checkbox [color]="task.color" [(ngModel)]="allSelectedExnProcedure" class="chk-box-align"
                                        [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection('Extn')">
                                        Select All</mat-checkbox>
                                </div>
                                <mat-option (click)="optionClick('Extn')" class="mat-opt-align"
                                    *ngFor="let option of filterExtnProcedure(search.value)" [value]="option.extnprocedurename"
                                    title="{{option.extnprocedurename}}({{option.extnprocedureid}})">
                                    {{option.extnprocedurename}}({{option.extnprocedureid}})
                                </mat-option>
                            </div>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-sm-4 ext-height">
                        <mat-label><strong>Name</strong></mat-label>
                        <input matInput formControlName="Name" maxlength="255">
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-sm-4">
                        <mat-label><strong>Test Name</strong></mat-label>
                        <input matInput formControlName="TestName" maxlength="200">
                        <mat-hint class="pt-2"> <img src="../../../assets/icons/home_icons/help.svg" alt="Help" class="material-icons img-h"
                                style="margin-top: 3px;margin-right:5px" title="Help"><strong>Urovision FISH, BE
                                FISH,Pro FISH</strong></mat-hint>
                    </mat-form-field>

                    <!-- <mat-form-field class="col-sm-4" appearance="outline">
                        <mat-label><strong>Test Name</strong></mat-label>
                        <em class="fa fa-chevron-down chevron-align"></em>
                        <input formControlName="Procedure" type="text" matInput #searchbar (keyup)="fetchProcedureName(addScreeningType.value.TestName)"
                            [matAutocomplete]="orgauto" placeholder="Search here ..." style="width: 95%;">
                        <mat-autocomplete #orgauto="matAutocomplete">
                            <div *ngFor="let show of searchProcedureResult">
                                <mat-option title="{{show}}" value="{{show}}">
                                    <span>{{show}}</span>
                                </mat-option>
                            </div>
                        </mat-autocomplete>
                    </mat-form-field> -->

                    <mat-form-field *ngIf="!isPapCasetype" appearance="outline" class="col-sm-4">
                        <mat-label><strong>CPT Code</strong></mat-label>
                        <input matInput formControlName="CPTCode">
                    </mat-form-field>
                </div>

                <div class="col-sm-12 d-flex mt-4">
                    <mat-form-field *ngIf="isPapCasetype" appearance="outline" class="col-sm-4 ext-height">
                        <mat-label><strong>CPT Code</strong></mat-label>
                        <input matInput formControlName="CPTCode" maxlength="2000">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-sm-4 ext-height">
                        <mat-label><strong>Sequence</strong></mat-label>
                        <input appBlockCopyPaste (keypress)="AllowOnlyNumber($event)" matInput formControlName="Sequence" maxlength="5" >
                    </mat-form-field>

                    <div class="col-sm-4 d-flex mt-2 p-0">
                        <mat-checkbox [color]="task.color" class="col-sm-2 m-0 p-0" formControlName="IsDefault"
                            [checked]="false" value="false">Default
                        </mat-checkbox>

                        <mat-checkbox [color]="task.color" class="col-sm-2 ml-4" formControlName="IsActive"
                            [checked]="true" value="true">Active
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-sm-3">
                    <button mat-raised-button class="admin-btn-success mr-4" (click)="backSelect()">
                        Back
                    </button>
                </div>
                <div class="col-sm-9 button-wrap" *ngIf="enableInsert">
                    <button mat-raised-button class="admin-btn-success mr-4" (click)="saveAndClose()">
                        Save & Close
                    </button>
                    <button mat-raised-button class="admin-btn-success" (click)="saveAndCreate()">
                        Save & Create
                    </button>
                </div>
                <div class="col-sm-9 button-wrap" *ngIf="enableEdit">
                    <button mat-raised-button class="admin-btn-success mr-4" (click)="editSave()">
                        Save
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- Edit form -->
<div *ngIf="enableEdit" class="col-sm-12 m-0 p-0">
    <div class="modal-header admin-model-header mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt">
            <strong>Edit Screening Types</strong>
        </h3>
    </div>
    <div>
        <form [formGroup]="addScreeningType" autocomplete="off">
            <div class="text-wrap-icdcode row col-sm-12 p-0 ml-0">
                <div class="row col-sm-12 mt-2">
                    <mat-form-field *ngIf="isPapCasetype" appearance="outline" class="col-sm-4 ext-height">
                        <mat-label><strong>Extraction Procedure</strong></mat-label>
                        <input matInput formControlName="frmExnprocedure" readonly="true">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-sm-4 ext-height">
                        <mat-label><strong>Name</strong></mat-label>
                        <input matInput formControlName="Name" maxlength="2000" readonly="true">
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-sm-4 ext-height">
                        <mat-label><strong>Test Name</strong></mat-label>
                        <input matInput formControlName="TestName" maxlength="2000" readonly="true">
                    </mat-form-field>

                    <mat-form-field *ngIf="!isPapCasetype" appearance="outline" class="col-sm-4 ext-height">
                        <mat-label><strong>CPT Code</strong></mat-label>
                        <input matInput formControlName="CPTCode">
                    </mat-form-field>
                </div>

                <div class="row col-sm-12 mt-4">
                    <mat-form-field *ngIf="isPapCasetype" appearance="outline" class="col-sm-4 ext-height">
                        <mat-label><strong>CPT Code</strong></mat-label>
                        <input matInput formControlName="CPTCode">
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-sm-4 ext-height">
                        <mat-label><strong>Sequence</strong></mat-label>
                        <input appBlockCopyPaste (keypress)="AllowOnlyNumber($event)" matInput formControlName="Sequence" maxlength="5">
                    </mat-form-field>

                    <div class="col-sm-4 d-flex mt-2 p-0">
                        <mat-checkbox [color]="task.color" class="col-sm-2 m-0 p-0" formControlName="IsDefault"
                            [checked]="false" value="false">Default
                        </mat-checkbox>

                        <mat-checkbox [color]="task.color" class="col-sm-2 ml-4" formControlName="IsActive"
                            [checked]="true" value="true">Active
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-sm-3">
                    <button mat-raised-button class="admin-btn-success mr-4" (click)="backSelect()">
                        Back
                    </button>
                </div>
                <div class="col-sm-9 button-wrap">
                    <button mat-raised-button class="admin-btn-success mr-4" (click)="editSave()">
                        Save and Close
                    </button>

                </div>
            </div>
        </form>
    </div>
</div>
<!-- Copy From Other Entity -->
<div *ngIf="copyDataClicked">
    <div class="modal-header admin-model-header mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong>Copy Screening Type</strong>
        </h3>
    </div>
    <form [formGroup]="addScreeningType" class="copy-collection">
        <div class="p-0 ml-0">
            <div class="col-sm-12 text-wrap-icdcode pb-1">
                <mat-form-field class="col-sm-4 example-additional-selection mt-2 ext-height" appearance="outline">
                    <mat-label>Deployment</mat-label>
                    <mat-select disableOptionCentering type="text" matInput #searchbar formControlName="frmDepKey">
                        <mat-option class="temp-font-size" *ngFor="let i of DeploymentKeys" value="{{i}}"
                            (onSelectionChange)="onChangeDeployment($event,i)">
                            <span>{{ i }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="col-sm-4 example-additional-selection mt-2 ext-height" appearance="outline">
                    <mat-label>Group  <span class="error-msg">*</span></mat-label>
                    <em class="fa fa-chevron-down chevron-align"></em>
                    <input formControlName="frmOrganization" type="text" matInput #searchbar (keyup)="fetchOrgSeries(addScreeningType.value.frmOrganization); trimFieldValue('frmOrganization')"
                        [matAutocomplete]="orgauto" placeholder="Search here ..." style="width: 95%;" [readonly]="postUpload">
                    <mat-autocomplete #orgauto="matAutocomplete">
                        <div *ngFor="let show of searchResult">
                            <mat-option class="mat-opt-align"
                                title="{{ show.organizationname }} ({{show.organizationid}})" value="{{ show.orgname }}"
                                (onSelectionChange)="selectedCopyDataOrganization($event, show)">
                                <span>{{ show.organizationname }} ({{show.organizationid}})</span>
                            </mat-option>
                        </div>
                    </mat-autocomplete>
                </mat-form-field>

                <!-- <mat-form-field class="col-sm-4" appearance="outline">
                    <mat-label><span class="error-msg">*</span><strong>Test Name</strong></mat-label>
                    <em class="fa fa-chevron-down chevron-align"></em>
                    <input formControlName="TestName" type="text" matInput #searchbar (keyup)="fetchTestName(addScreeningType.value.TestName)"
                        [matAutocomplete]="orgauto" placeholder="Search here ..." style="width: 95%;">
                    <mat-autocomplete #orgauto="matAutocomplete">
                        <div *ngFor="let show of searchProcedureResult">
                            <mat-option title="{{show}}" value="{{show}}">
                                <span>{{show}}</span>
                            </mat-option>
                        </div>
                    </mat-autocomplete>
                </mat-form-field> -->
            </div>

            <div *ngIf="gridwidth > 0" class="col-sm-12 mt-4">
                <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" (initialized)="initGrid(flexgrid,1)"
                    #flexgrid (formatItem)="formateItem(flexgrid, $event)">
                    <!-- Status -->
                    <wj-flex-grid-column [header]="'Status'" [binding]="status" *ngIf="postUpload" [allowResizing]="true"
                        [width]="170" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                            <span [ngStyle]="{color: cell.item.status=='Success' ?'green':cell.item.status=='Ignored' ? '#ff9800' : 'red' }">{{cell.item.status}}
                            </span>
                        </ng-template>
                    </wj-flex-grid-column>

                    <!-- Notes -->
                    <!-- <wj-flex-grid-column [header]="'Notes'" [binding]="notes" [allowResizing]="true" [width]="477"
                            [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                                <span [ngStyle]="{color: cell.item.notes=='Mandatory field is missing!' || cell.item.notes=='Null values exist!' ?'red': '#ff9800' }">{{cell.item.notes}}
                                </span>
                            </ng-template>
                        </wj-flex-grid-column> -->

                    <div *ngFor="let columnname of sheetHeader">
                        <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                            [visible]="!(columnname == 'organizationid') && !(columnname == 'AttributeId') && !(columnname == 'OrganizationId') && !(columnname == 'notes') && !(columnname == 'slno') && !(columnname == 'tablename') && !(columnname == 'casetype')"
                            [allowResizing]="true" [width]="'*'" [allowDragging]="false" [allowSorting]="false"
                            [format]="'d'">
                        </wj-flex-grid-column>
                    </div>
                    <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                </wj-flex-grid>
                <br />
                <div *ngIf="gridwidth > 0 && enableActive" class="row align-center pl-3 mt-2 ml-5">
                    <mat-checkbox [color]="task.color" class="font-style col-sm-2 m-0 p-0 ml-1" [checked]='checkStatus'
                        ng-init='checkStatus' ng-model='checkStatus' (change)='checkValue($event)'>
                        Copy as Inactive
                    </mat-checkbox>
                </div>
                <div class="col-sm-12 pl-0 mt-4">
                    <div *ngIf="gridwidth > 0" class="m-1 p-0">
                        <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridwidth > 0" (click)="gridRemove()">Back</button>
                        <span style="float: right;">
                            <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4" (click)="backSelect()">Cancel</button>
                            <!-- <div *ngIf="!btnDisable"> -->
                            <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4" (click)="backSelect()">Finish</button>
                            <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4" [disabled]="selectedData.length==0"
                                (click)="approveScreeningType(true)">Approve</button>
                            <button mat-raised-button *ngIf="postUpload" class="admin-btn-success" (click)="ExportExcel(flexgrid)">Download
                                Result</button>
                        </span>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 pl-0 mt-4">
                <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridwidth == 0" (click)="backSelect()">Back</button>
            </div>

            <div class="col-sm-12" *ngIf="gridwidth == 0">
                <div class="container create-btn col-sm-12 align-center" *ngIf="noData">
                    <div class="col-sm-10 nodata-wrap no-data-wraps mt-4">
                        <div class="nodata-design">No Data Found</div>
                    </div>
                </div>
                <!-- <button mat-raised-button class="admin-btn-success mt-4" (click)="backSelect()">Back</button> -->
            </div>
        </div>
    </form>
</div>
<!-- Upload  -->
<div *ngIf="uploadClicked" class="my-manage-form">
    <div class="modal-header admin-model-header mb-2 mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong>Upload {{templateData.menuURL.toString().trim()}}</strong>
        </h3>
    </div>
    <div class="button-field-wrap m-1 p-0">
        <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
            <mat-label class="d-flex">
                Templates
            </mat-label>
            <!-- <em class="fa fa-chevron-down chevron-align"></em> -->
            <mat-select disableOptionCentering class="ml-2 temp-mat-align">
                <mat-option class="temp-font-size" (click)="downloadTemplate('mandatoryFieldsCheck')">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>Minimal Fields
                </mat-option>
                <mat-option class="temp-font-size" (click)="downloadTemplate('allFieldsCheck')">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields
                </mat-option>
                <mat-option class="temp-font-size" (click)="downloadTemplate('updateCheck')">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>Bulk Update
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="button-field-wrap">
        <span class="instr-size mb-2">
            <img aria-hidden="true" alt="help" class="mb-1" src="/assets/icons/help.svg" width="15px" height="auto" />
            You can download the sample template file to upload {{templateData.menuURL}}.</span>
    </div>

    <div class="col-sm-12 p-0" *ngIf="gridwidth == 0">
        <div class="image-height" (fileDropped)="onFileChange($event)" appDnd>
            <input type="file" id="fileDropRef" #fileDropRef click="value = null" value="" (change)="onFileChange($event)"
                hidden />
            <span class="align-center img-wrap">
                <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
            </span>
            <span>Drag & Drop excel files here</span>
            <span class="align-center">Or</span>
            <label class="btn-sm btn rounded browse ml-5" for="fileDropRef">Browse File</label>
        </div>
        <button mat-raised-button class="admin-btn-success mt-4" (click)="backSelect()">
            Back
        </button>
    </div>

    <div *ngIf="gridwidth > 0">
        <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" (initialized)="initGrid(grid)"
            #grid [headersVisibility]="'Column'" (formatItem)="formateItem(grid, $event)">
            <!-- Status -->
            <wj-flex-grid-column [header]="'Status'" [binding]="status" *ngIf="postUpload" [allowResizing]="true"
                [width]="170" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <span [ngStyle]="{color: cell.item.status=='Success' ?'green':cell.item.status=='Ignored' ? '#ff9800' : 'red' }">{{cell.item.status}}
                    </span>
                </ng-template>
            </wj-flex-grid-column>

            <!-- Notes -->
            <!-- <wj-flex-grid-column [header]="'Notes'" [binding]="notes" [allowResizing]="true" [width]="477"
                [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <span [ngStyle]="{color: (cell.item.notes=='Mandatory field is missing!' || cell.item.notes=='Null values exist!' || cell.item.notes=='Sequence only takes numbers' || cell.item.notes =='Zero is not allowed for Sequence' || cell.item.notes =='Record not found in casetype') ? 'red': cell.item.notes == '{{templateData.menuURL.toString()}} added' ? 'green' : '#ff9800' }">{{cell.item.notes}}
                    </span>
                </ng-template>
            </wj-flex-grid-column> -->

            <!-- Action -->
            <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="126" [isReadOnly]="true"
                [format]="'d'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button class="edit-delete-btn" (click)="deleteRow(grid, row)">
                        <em id="deleteButton" [title]="'Delete'" class="p-0 fa fa-trash"></em>
                    </button>
                </ng-template>
            </wj-flex-grid-column>
            <!-- [visible]="!(columnname == 'Notes') && !(columnname == 'Status')" -->
            <div *ngFor="let columnname of sheetHeader">
                <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                    [allowResizing]="true" [width]="'*'" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                </wj-flex-grid-column>
            </div>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>


        <div class="row pl-3">
            <div class="col-sm-2 p-0 mt-4">
                <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridwidth > 0" (click)="gridRemove()">Back</button>
                <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridwidth == 0" (click)="backSelect()">Back</button>
            </div>
            <div class="col-sm-10 button-wrap p-0 mt-4">
                <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4" (click)="backSelect()">Cancel</button>
                <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4" [disabled]="approveDisable(true)"
                    (click)="approveScreeningType()">Approve</button>
                <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4" (click)="backSelect()">Finish</button>
                <button mat-raised-button *ngIf="postUpload" class="admin-btn-success" (click)="ExportExcel(grid)">Download
                    Result</button>

            </div>
        </div>

    </div>
    <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
        <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
    </div>
</div>

