import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  viewProviders:[{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class InputComponent {
  @Input() type : string = 'text'
  @Input() labelValue : string = ''
  @Input() required : boolean = false
  @Input() formcontrolname : string | null = null
  @Input() maxLength : number = 50
  @Input() mask : string = ''
  @Input() prefix : string = ''
  @Input() id : string = ''
  @Input() placeholder : string = ''
  @Input() InvalidMessage : string = ''
  @Input() containerClass : string = 'mb-3 me-3'
  @Input() selectedValue:string=''
  @Input() isFormValid:boolean|null=true
  @Input() isDisabled:boolean=false
  @Input() isReadOnly:boolean=false
  @Input() subscript:string=''
  @Output() onFocusOut = new EventEmitter()
  @Output() onFocus = new EventEmitter()
  @Input() className : string = ''
  isDateValid : boolean = true
  constructor
  (
    private _form : FormGroupDirective
  ){}
  ngOnInit(): void {
    this.maxLength = this.mask.length > 0 ?  524288 : this.maxLength

    if(this.formcontrolname){
      if(this.isDisabled) this._form.control.get(this.formcontrolname).disable()
      else this._form.control.get(this.formcontrolname).enable()
    }
  }
  allowNumberfunction(event:any)
  {
    let e = <KeyboardEvent>event;
    if ([46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {

        return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  }
  onfocus=(ref:any)=>this.onFocus.emit(ref)
  focusout = (ref : any) => this.onFocusOut.emit(ref)
  focusoutInput=(ref)=>this.onFocusOut.emit(ref.value)
  focus(ref : any){
    if(ref.value === '' && this.prefix != ''){
      ref.value = this.prefix
    }
  }

  validateDate(date){
    this.isDateValid = moment(date.value, "MM/DD/YYYY", true).isValid()
  }
}
