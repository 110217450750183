<ng-container>
    <div class="col-md-12 p-0">
        <div class="modal-header admin-model-header mt-2 listingModalHeader pl-2">
            <h3 class="modal-title w-100 admin-model-header-txt">
                <strong class="header-View"> {{ data?.header }} </strong>
            </h3>
        </div>
    </div>
    <mat-dialog-content class="configPopup">
        <ng-container *ngIf="data?.rowData?.AssociationType === 'Ordering Facilities'">
            <div class="col-md-12 row p-0 mb-2">
                <div class="viewSelectContainer col-md-3">
    
                    <mat-form-field class="col-sm-12 p-0" [ngClass]="data?.rowData?.services ? 'valuedField' : ''">
                        <mat-label> Service Type </mat-label>
                        <mat-select name="serviceType"
                            [panelClass]="'customCEOverlayPanelClass listingDropdownPanel'" [(ngModel)]="data.rowData.services"
                            [ngModelOptions]="{standalone: true}" #popupField>
                            <mat-option *ngFor="let dropdownOption of data?.servicesList"
                                [value]="dropdownOption.item">
                                <div class="truncate-text">
                                    {{ dropdownOption?.item }}
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
        
                <div class="viewInputContainer col-md-3">
                    <mat-form-field class="col-sm-12 p-0" [ngClass]="{'valuedField': data?.rowData?.AccountCode}">
                        <mat-label> Account Code </mat-label>
                        <input  [(ngModel)]="data.rowData.AccountCode" [ngModelOptions]="{standalone: true}"
                            name="accountCode" trim matInput />
                    </mat-form-field>
                </div>
    
                <div class="viewInputContainer col-md-3">
                    <mat-form-field class="col-sm-12 p-0" [ngClass]="{'valuedField': data?.rowData?.LocationCode}">
                        <mat-label> Location Code </mat-label>
                        <input  [(ngModel)]="data.rowData.LocationCode" [ngModelOptions]="{standalone: true}"
                            name="locationCode" trim matInput />
                    </mat-form-field>
                </div>
    
                <div class="viewInputContainer col-md-3">
                    <mat-form-field class="col-sm-12 p-0" [ngClass]="{'valuedField': data?.rowData?.ClientNumber}">
                        <mat-label> Client Number/Code </mat-label>
                        <input  [(ngModel)]="data.rowData.ClientNumber" [ngModelOptions]="{standalone: true}"
                            name="clientCode" trim matInput />
                    </mat-form-field>
                </div>
            </div>
    
            <div class="col-md-12 row p-0 checkboxRow">
                <div class="col-md-12 row">
                    <div class="col-md-3" style="position: relative; right: 10px;">
                        <mat-checkbox [color]="data?.task?.color" [(ngModel)]="data.rowData.AllowReportViewing" [ngModelOptions]="{standalone: true}">
                            Allow lab to view reports
                        </mat-checkbox>
                    </div>
    
                    <div class="col-md-3" style="position: relative; right: 4px;">
                        <mat-checkbox [color]="data?.task?.color" [(ngModel)]="data.rowData.isPcEnabled" [ngModelOptions]="{standalone: true}">
                            PC Enabled
                        </mat-checkbox>
                    </div>
    
                    <div class="col-md-6">
                        <mat-checkbox [color]="data?.task?.color" [(ngModel)]="data.rowData.DiagnosisReporting" [ngModelOptions]="{standalone: true}">
                            Diagnosis Reporting done using VitalDx(VitalOffice)
                        </mat-checkbox>
                    </div>
                </div>
            </div>
    
            <div class="col-md-12 p-0 row mt-4">
                <div class="col-md-6">
                    <div class="modal-header admin-model-header mt-2 listingModalHeader mb-2">
                        <h3 class="modal-title w-100 admin-model-header-txt p-0">
                            <strong class="header-View" style="border-bottom: 2px solid #0364B4;"> Accession Instructions </strong>
                        </h3>
                    </div>
        
                    <div class="editor">
                        <ngx-editor-menu [editor]="data?.accessEditor" [toolbar]="data?.toolbar">
                        </ngx-editor-menu>
                        <ngx-editor class="rtf-editor" [editor]="data?.accessEditor" [(ngModel)]="data.rowData.AccessionInstructions" [ngModelOptions]="{standalone: true}"
                            [disabled]="false">
                        </ngx-editor>
                    </div>
                </div>
        
                <div class="col-md-6">
                    <div class="modal-header admin-model-header mt-2 listingModalHeader mb-2">
                        <h3 class="modal-title w-100 admin-model-header-txt p-0">
                            <strong class="header-View" style="border-bottom: 2px solid #0364B4;"> Diagnosis Custom Instructions </strong>
                        </h3>
                    </div>
        
                    <div class="editor">
                        <ngx-editor-menu [editor]="data?.diagEditor" [toolbar]="data?.toolbar">
                        </ngx-editor-menu>
                        <ngx-editor class="rtf-editor" [editor]="data?.diagEditor" [(ngModel)]="data.rowData.DiagnosisCustomInstructions" [ngModelOptions]="{standalone: true}"
                            [disabled]="false">
                        </ngx-editor>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="['Send In Lab', 'Send-out Laboratories']?.includes(data?.rowData?.AssociationType)">
            <div class="col-md-12 row p-0 mb-2">
                <div class="viewInputContainer col-md-3">
                    <mat-form-field class="col-sm-12 p-0" [ngClass]="{'valuedField': data?.rowData?.L2LConfigId}">
                        <mat-label> L2L Config Id </mat-label>
                        <input  [(ngModel)]="data.rowData.L2LConfigId" [ngModelOptions]="{standalone: true}"
                            name="accountCode" trim matInput />
                    </mat-form-field>
                </div>
            </div>
        </ng-container>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button [mat-dialog-close]="false" class="closeButton"> Cancel </button>
        <button mat-button [mat-dialog-close]="true" class="successButton"> Update </button>
    </mat-dialog-actions>
</ng-container>