import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
export interface Task {
  color: ThemePalette;
}
@Component({
  selector: 'app-case-tags-filter',
  templateUrl: './case-tags-filter.component.html',
  styleUrls: ['./case-tags-filter.component.scss']
})

export class CaseTagsFilterComponent implements OnInit {

  task: Task = {
    color: 'primary',
  };

  @Input() placeholder: string = ''
  @Input() displayName: any;
  @Input() DataList: any;
  @Input() isEditable: any;
  @Output() myObjectChange: EventEmitter<any> = new EventEmitter<any>();

  dataListBackup: any[] = []
  searchcaselist: any[];
  dataList: any[];
  listCasetype = [];
  listStatus = [];
  listRoles = [];
  closeIcon: boolean = false;
  updatedlist = [];

  constructor(private _fb: FormBuilder) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (!this.isEditable && this.DataList !== undefined) {
      this.DataList.forEach((element) => {
        element.checked = false;
      });
    }
    this.dataListBackup = this.DataList;
  }

  formGroupSearch = this._fb.group({
    frmInput: ''
  });

  selectedData(event?, item?: any, ischecked?: boolean, displayName?, selectList?) {
    if (!displayName)
      displayName = this.displayName;
    const itemId = item[displayName] ?? '';
    this.updatedlist = this.dataListBackup.filter(va => va.checked == true)
    if (itemId.toLowerCase() === 'all') {
      this.updatedlist.forEach((va) => {
        va['checked'] = false;
      });
      if (event.checked) {
        item.checked=event.checked;
        this.updatedlist.push(item);
      }
      this.myObjectChange.emit(this.updatedlist);
      return;
    } else {
      this.updatedlist.forEach((va) => {
        if (va[displayName].toLowerCase() === 'all' && va['checked'] === true) {
          va['checked'] = false;
          item['checked'] = false;
        }
      });
      if (!event.checked) {
        let index = this.updatedlist.findIndex(va => va[displayName] == item[displayName])
        index != -1 ? this.updatedlist.splice(index, 1) : null;
      }
    }
    if (ischecked === false) {
      item['checked'] = false;
      if (event) {
        event.checked = false;
      }
    } else if (event) {
      item['checked'] = event.checked;
    }

    if (event.checked) {
      if (!this.updatedlist.some((va) => va[displayName] === itemId)) {
        this.updatedlist.push(item);
      }
    } else {
    }
    this.myObjectChange.emit(this.updatedlist);
  }

  fetchUserSeries(value: string, placeholder: string) {
    this.DataList = this.dataListBackup.filter(va => va[this.displayName].toLowerCase().includes(value.toLowerCase()))
  }

  getDisplayName(data) {
    if (data) {
      return data[this.displayName]
    }
  }

  clearSearch() {
    this.formGroupSearch.patchValue({
      frmInput: ''
    })
    this.DataList = this.dataListBackup;
  }

}
