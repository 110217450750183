<div class=" p-4" *ngIf="!noDataFoundFlag && userDetials['faxEnable'] && fromLocation != 'location' && !showLoader">
  <div class="row text-sm mb-1">
    <div class="col-sm-1 font-size-color">Fax: </div>
    <div class="col-sm-8 d-flex font-size-weight">
      <div class="mr-2 cursor on-hover" [matTooltip]="faxDetailsCaseTypes.join('\n')" matTooltipClass="tooltip-list"> CaseType : {{ faxDetailsCaseTypes?.length ? faxDetailsCaseTypes?.length : 0 }} |</div>
      <div class="mr-2 cursor on-hover" [matTooltip]="faxDetailsServices.join('\n')" matTooltipClass="tooltip-list">Service : {{ faxDetailsServices?.length  ? faxDetailsServices?.length  : 0}} |</div>
      <div class="mr-2 cursor on-hover" [matTooltip]="faxDetailsLocations.join('\n')" matTooltipClass="tooltip-list">Locations : {{ faxDetailsLocations?.length ? faxDetailsLocations?.length : 0 }} </div>
    </div>
  </div>
</div>

<!-- from location part -->
<div class="p-3 location-fax" *ngIf="fromLocation == 'location' && !showLoader">
  <mat-accordion>
    <!-- Fax -->
    <ng-container *ngIf="faxList.length > 0" >
      <div class="d-flex">
        <div [ngClass]="{'label-heading-hide-grid':isHideLocationGrid, 'label-heading-show-grid':!isHideLocationGrid}">Fax:</div>
          <div>
            <mat-expansion-panel *ngFor="let item of faxList" class="mb-2" (mouseleave)="onLeaveMouse()">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{item.labname | uppercase}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="d-flex">
              <span class="mr-1" (mouseenter)="onMouseEnter(item.casetype,'case')">CaseTypes: {{count(item.casetype)}}</span> |
              <span class="ml-1 mr-1" (mouseenter)="onMouseEnter(item.service,'service')">Services: {{count(item.service)}}</span> |
              <span class="ml-1" (mouseenter)="onMouseEnter(item.physician,'phy')">Physicians: {{count(item.physician)}}</span>
            </div>
            <span class="tooltip-show" *ngIf="currentType == 'case' || currentType == 'service' || currentType == 'phy'">
              <div *ngFor="let item of toolTipContent || []">{{ item | titlecase }}</div>
            </span>
            </mat-expansion-panel>
        </div>
    </div>
    </ng-container>

    <!-- Printing at Lab -->
    <ng-container *ngIf="labList.length > 0">
      <div class="d-flex mt-2">
        <div [ngClass]="{'label-heading-hide-grid':isHideLocationGrid, 'label-heading-show-grid':!isHideLocationGrid}">Printing at Lab:</div>
          <div>
            <mat-expansion-panel *ngFor="let item of labList" class="mb-2" (mouseleave)="onLeaveMouse()">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{item.labname | uppercase}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="d-flex">
                <span class="mr-1" (mouseenter)="onMouseEnter(item.casetype,'case')">CaseTypes: {{count(item.casetype)}}</span> |
                <span class="ml-1 mr-1" (mouseenter)="onMouseEnter(item.service,'service')">Services: {{count(item.service)}}</span> |
                <span class="ml-1" (mouseenter)="onMouseEnter(item.physician,'phy')">Physicians: {{count(item.physician)}}</span>
              </div>
              <span class="tooltip-show" *ngIf="currentType == 'case' || currentType == 'service' || currentType == 'phy'">
                <div *ngFor="let item of toolTipContent || []">{{ item | titlecase }}</div>
              </span>
            </mat-expansion-panel>
        </div>
    </div>
    </ng-container>

    <!-- Printing at Location -->
    <ng-container *ngIf="locationList.length > 0">
      <div class="d-flex mt-2">
        <div [ngClass]="{'label-heading-hide-grid':isHideLocationGrid, 'label-heading-show-grid':!isHideLocationGrid}">Printing at Location:</div>
          <div>
            <mat-expansion-panel *ngFor="let item of locationList" class="mb-2" (mouseleave)="onLeaveMouse()">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{item.labname | uppercase}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="d-flex">
                <span class="mr-1" (mouseenter)="onMouseEnter(item.casetype,'case')">CaseTypes: {{count(item.casetype)}}</span> |
                <span class="ml-1 mr-1" (mouseenter)="onMouseEnter(item.service,'service')">Services: {{count(item.service)}}</span> |
                <span class="ml-1" (mouseenter)="onMouseEnter(item.physician,'phy')">Physicians: {{count(item.physician)}}</span>
              </div>
              <span class="tooltip-show" *ngIf="currentType == 'case' || currentType == 'service' || currentType == 'phy'">
                <div *ngFor="let item of toolTipContent || []">{{ item | titlecase }}</div>
              </span>
            </mat-expansion-panel>
        </div>
      </div>
    </ng-container>
  </mat-accordion>
</div>


<div class="col-sm-12 nodata-header-wrap" *ngIf="noDataFoundFlag && !showLoader">
  <span class="col-sm-12 nodata-wrapper">No Data Available</span>
</div>

<mat-spinner class="labadmin-spinner"  style="margin: 20vh 0vw 0vh 30vw;" *ngIf="showLoader && !noDataFoundFlag && fromLocation != 'location'" [diameter]="50">
</mat-spinner>


<!-- for location fax and printing -->
<mat-spinner *ngIf="showLoader && fromLocation == 'location'" class="labadmin-spinner" [ngClass]="{'spinner-margin-hide-grid': isHideLocationGrid, 'spinner-margin-show-grid': !isHideLocationGrid}" [diameter]="50">
</mat-spinner>

