<div class=" attachment" (appFileDragDrop)="onDrop($event)" height="103px">
    <div id="addAttachment" class="d-flex align-items-center cursor-pointer" (click)="selectAttachment.click()">
        <span>Upload or drag-drop attachments</span>
        <img class="ml-auto" src="/assets/images_vla/Upload.svg" matTooltip="Upload" draggable="false" alt="upload" />
        <input type="file" class="d-none" multiple #selectAttachment (change)="GetAttachmentToUpload($event)" />
    </div>
    <ng-container *ngIf="attachments$| asyncloader |async as attachments">
        <div *ngIf="attachments.loading" id="loader">
            <app-shimmerui [line]="1"></app-shimmerui>
        </div>
        <ng-container *ngIf="!attachments.loading">
            <div id="container" class="d-flex align-items-center" *ngFor="let documents of attachments.data">
                <span appTooltipEllipsis class="attachment-width-11vw">{{documents.Name}}</span>
                <div class="ml-auto">
                    <span>{{documents.DateAdded | formatDate : 'MM-dd-yyyy, h:mm a'}}</span>
                    <img src="/assets/images_vla/View Eye.svg" alt="view" class="ml-3 icon-view"
                        [ngClass]="documents.disableView ? 'fa-disabled' : ''" draggable="false"
                        (click)="documents.disableView ? null : viewAttachment(documents.FileId, documents.Name)"
                        matTooltip="View" [matTooltipDisabled]="documents.disableView"/>
                    <img src="/assets/images_vla/Download.svg" draggable="false"
                        (click)="downloadAttachments(documents.FileId, documents.Name)" class="ml-3" alt="download"
                        matTooltip="Download" />
                    <img src="/assets/icons/delete-red.svg" draggable="false"
                        (click)="deleteAttachment(documents.Id, documents.Name)" class="ml-3 icon-delete" alt="delete"
                        matTooltip="Delete" />
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
<div *ngIf="url" class="show-image">
    <img src="/assets/icons/Close_black.svg" alt="image" (click)="url = ''" id="cross">
    <img [src]="url" alt="image" id="uploaded-image">
</div>