<div class="row" *ngIf="showHeaderModule && listScreen && isAdmin3">
    <div class="col-md-12 p-0">
        <div class="modal-header admin-model-header mt-2 listingModalHeader">
            <h3 class="modal-title w-100 admin-model-header-txt">
                <strong class="header-View"> {{ listContext==''?filename:listContext }} </strong>
            </h3>
        </div>
    </div>
</div>

<div *ngIf="listScreen" [ngClass]="{'vitalDx': !isAdmin3, 'autoCompleteList': isAutoCompleteList}">
    <div class="col-sm-12 common-div d-flex">
        
        <!-- container for tabs -->
        <div class="col-sm-6 px-0 d-flex">
            <button id="All" [class.selected]="selectedButton === 'All'" (click)="selectButton('All')"
                [disabled]="AllCount == 0">All
                ({{AllCount}})</button>
            <div class="seperator-filter" *ngIf="isShowActiveTabs || customListActiveTabs">
            </div>
            <button id="Active" [ngClass]="(ActiveCount === 0) ? 'disable-btn' : 'button'"
                [class.selected]="selectedButton === 'Active'" (click)="selectButton('Active')"
                [disabled]="ActiveCount === 0" *ngIf="isShowActiveTabs || customListActiveTabs">Active
                ({{ActiveCount}})</button>

            <button id="InActive" [ngClass]="(InactiveCount === 0) ? 'disable-btn' : 'button'"
                class="m-0" [class.selected]="selectedButton === 'InActive'" (click)="selectButton('InActive')"
                [disabled]="InactiveCount === 0
                " *ngIf="isShowActiveTabs || customListActiveTabs">Inactive ({{InactiveCount}})</button>

            <div class="col-sm-6 ml-2 d-flex" style="height: 24px;"
                [ngStyle]="templateCustomObject?.length ? {'border-left': '2px solid #FB8500'} : null"
                *ngIf="!isUserLevel">
                <ng-container *ngFor="let field of templateCustomObject">
                    <div class="p-0 mr-1" *ngIf="field.dataType === 'staticDropdown'" [ngClass]="field['manageClassName']">
                        <mat-form-field class="col-sm-12 p-0"  [ngClass]="!isAdmin3 ? 'listFilterDropdownLabAdmin' : 'listFilterDropdown'"  appearance="outline">
                            <mat-select placeholder="Select Reason" (selectionChange)="filtersChanged(field, $event)"
                                [(ngModel)]="field.value" [panelClass]="'customCEOverlayPanelClass listingDropdownPanel'">
                                <mat-option *ngFor="let dropdownOptions of field['properties']?.['source']?.['options']"
                                    [value]="dropdownOptions[field['properties']?.['source']?.['keyToBeSentToBackend']]">
                                    <div> {{ dropdownOptions[field['properties']?.['source']?.['dataValueToDisplay']] }}
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="p-0" *ngIf="field.dataType === 'dropdown' && !isAdmin3" [ngClass]="field['manageClassName']">                                
                        <mat-form-field class="col-sm-12 p-0" [ngClass]="!isAdmin3 ? 'listFilterDropdownLabAdmin' : 'listFilterDropdown'" appearance="outline">
                            <mat-select [placeholder]="field['properties']?.['placeholder']"
                            (selectionChange)="filtersChanged(field, $event)"
                            [(ngModel)]="field.value" [panelClass]="'customCEOverlayPanelClass listingDropdownPanel'">
                                <mat-option *ngFor="let dropdownOptions of field['properties']?.['source']?.['options']"
                                [value]="dropdownOptions[field['properties']?.['source']?.['keyToBeSentToBackend']]">
                                <div class="truncate-text" matTooltip="{{ dropdownOptions[field['properties']?.['source']?.['dataValueToDisplay']]}}"> {{ dropdownOptions[field['properties']?.['source']?.['dataValueToDisplay']] | shortentext:field?.properties?.source?.truncateLength }} </div>
                            </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="p-0" *ngIf="field.dataType === 'autocomplete' && (!field.properties.hideInAdmin || !isAdmin3)" [ngClass]="field['manageClassName']">                                
                        <mat-form-field appearance="outline" class="w-100 bringToTop" [ngClass]="!isAdmin3 ? 'listFilterDropdownLabAdmin' : 'listFilterDropdown'" (click)="clearFilterClicked($event, field)">
                            <!-- <mat-label>{{ field['displayName'] }} </mat-label> -->
                            <em class="fa fa-chevron-down chevron-align" [ngClass]="!isAdmin3 ? 'em-LabAdmin' : '' "></em>

                            <!-- <em class="fa fa-times chevron-align mr-2" [ngClass]="!isAdmin3 ? 'em-LabAdmin' : '' " (click)="clearFilterClicked($event, field)" *ngIf="field?.['dummyValue']?.['itemName']"></em> -->
                            <!-- for autocomplete as we need some value we are sorting that in default value as object from there we are taking for value field. -->
                            <input trim type="text" [(ngModel)]="field?.['dummyValue']['itemName']" maxlength="200"
                                matInput [aria-label]="field['displayName']" [matAutocomplete]="auto"
                                [ngModelOptions]="{standalone: true}"
                                [placeholder]="field['properties']?.['placeholder']" #autoComopleteText>
                            <mat-autocomplete #auto="matAutocomplete" class="customCEOverlayPanelClass listingDropdownPanel" (closed)="autoCompletePanelClosed(field)">
                                <mat-option class="mat-opt-align pl-2" [ngClass]="{'mdc-list-item--selected':  field['listBackupValue'] === autoCompleteOption[field?.properties?.source?.dataValueToDisplay[0]] }"
                                    *ngFor="let autoCompleteOption of  filterAutomCompleteOptions(field?.dummyValue['itemName'], field?.properties?.source?.options, field?.properties?.source?.dataValueToDisplay?.[0])"
                                    
                                    [disabled]="field['properties']?.['isDisableInEdit'] && editViewClicked"
                                    (onSelectionChange)="onAutoCompleteSelectionChange($event, field, autoCompleteOption)">
                                    <div class="truncate-text" [matTooltip]="optionToolTipText" (mouseenter)="enableToolTip(isOptionElipsed, 'dropdown')" #isOptionElipsed> {{ autoCompleteOption[field?.properties?.source?.dataValueToDisplay[0]] | shortentext:field?.properties?.source?.truncateLength}}
                                    </div>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </ng-container>
            </div>

            <div class="col-sm-6 ml-2 p-0" style="height: 24px;"
            [ngStyle]="templateCustomObject?.length ? {'border-left': '2px solid #FB8500'} : null"
            *ngIf="isUserLevel && caseTypeUserRoles">
                <ng-container *ngIf="caseTypeUserRoles?.length">
                    <mat-form-field appearance="outline" class="listFilterDropdown col-sm-8">
                        <mat-select disableOptionCentering [(ngModel)]="selectedRole" placeholder="Select Role" [panelClass]="'customCEOverlayPanelClass'">
                            <mat-option (onSelectionChange)="setUserRole($event,user)" value="{{user.RoleName}}"
                                *ngFor="let user of caseTypeUserRoles" class="dropdown-opt-wrap">
                                {{user.RoleName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
            </div>
        </div>


        <!-- container for the search bar and other buttons -->
        <div class="col-sm-6 d-flex p-0 justify-content-end">
            <div class="global-search-panel d-flex col-sm-7 p-0">
                <div style="display: flex; margin-right: 3px;margin-left: 4px;">
                    <mat-icon class="search-icon">search</mat-icon>
                </div>
                <div class="standing-line">
                </div>
                <input type="text" class="search-input" [placeholder]="'Search ' + (addEditContext==''?context:addEditContext)" [(ngModel)]="searchText"
                    (keyup)="filtertemplates()">
                <ng-container *ngIf="searchText">
                    <div class="standing-line">
                    </div>
                    <div  style="display: flex; margin-right: 3px;margin-left: 4px; cursor: pointer;"> 
                        <mat-icon class="search-icon" (click)="clearSearch()">close</mat-icon>
                    </div>
                </ng-container>
            </div>
            <div *ngIf="(selectedCaseUser == 'Group Case Comments' || selectedCaseUser == 'Group Level' || selectedCaseUser == 'Group ( Group Level )') && !hideManage && !isUserLevel && isAdmin3"
                class="create-upload-btn d-flex ml-2 justify-content-center">
                <div class="create-only" (click)="manageClicked()">
                    Manage
                </div>
            </div>

            <div *ngIf="(selectedCaseUser !== 'Group Case Comments' || selectedCaseUser !== 'All Users'|| selectedCaseUser == 'Group Level' || selectedCaseUser == 'Group ( Group Level )' || isMigrateForPayers == 'Payers') && !hideMigrate && enableMigrate && gridwidth == 0"
                class="create-upload-btn d-flex ml-2 justify-content-center">
                <div class="create-only" matTooltip='Migrate group level data to User level' (click)="migrateClicked()">
                    Migrate
                </div>
            </div>
            <div *ngIf="context == 'Sites' && isAdmin3"
                class="site-Order-button ml-2">
                <div class="create-only"  (click)="migrateClicked()">
                    Site Order
                </div>
            </div>
            <div *ngIf="hideSort" class="ml-2">
                <span class="cpy-drg-Icon_sort">
                    <img src=".../../../../assets/icons/Reorder Blue.svg" matTooltip="Enable sorting" class="sort_blue" />
                    <img src=".../../../../assets/icons/Reorder White.svg" matTooltip="Enable sorting" class="sort_white" />
                </span>
            </div>

            <div *ngIf="!hideCopy && selectedCaseUser !== 'All Users'" class="ml-2">
                <span class="cpy-drg-Icon-globe" (click)="copyTemplate()">
                    <img src=".../../../../assets/icons/Globe_Blue.svg" matTooltip="Copy" class="globe_blue" />
                    <img src=".../../../../assets/icons/Global_white.svg" matTooltip="Copy" class="globe_white" />
                </span>
            </div>

            <div *ngIf="!hideCreate" class="create-upload-btn d-flex ml-2 justify-content-center">
                <div *ngIf="!hideCreate" class="create-only" (click)="createClicked()">
                    Create
                </div>

                <div class="Standing-line-create" *ngIf="!hideUpload">
                </div>

                <div *ngIf="!hideUpload" class="upload-icon" (click)="uploadTemplate()"><img
                        src=".../../../../assets/icons/Bulk Upload_White.svg" matTooltip="Upload"
                        style="height: 15px; width: 15px;">

                </div>
            </div>

        </div>
    </div>
    <div *ngIf="note && isAdmin3" class="row col-sm-12 button-field-wrap">
        <div class="float-left">
            <span><strong>{{note}}</strong></span>
        </div>
    </div>
    <hr class="separation-line m-0 p-0">
    <div class="container-fluid list-container col-sm-12 p-0 mt-0 text-center">
        <div class="col-sm-12 header d-flex py-1 mb-1" *ngIf="GridData?.length">
            <div class="col-sm-12 header-wrap">
                <span [ngClass]="header.colWidth" *ngFor="let header of gridHeader">
                    <div class="dataTable">{{header.Name}}</div>
                </span>

                <div class="col-sm-1 pr-0 edit-wraps dataTable">
                    <span *ngIf="!hideExport && GridData?.length > 0" (click)="ExportExcel()"><img
                            src=".../../../../assets/icons/Excel_Download.svg" matTooltip="Export"
                            class="export-icon"></span>
                </div>
            </div>
        </div>

        <div class="container-fluid col-sm-12 header-wrap p-0">
            <div class="col-sm-12 p-0">
                <div class="col-sm-12">
                    <span *ngIf="GridData?.length > 0 && (GridData | searchfiltersTemplate: templatefilter)?.length >0 ">
                <cdk-virtual-scroll-viewport itemSize="15" class="tableScroll cstcdkscroll" *ngIf="GridData?.length > 0 ">
                 <!-- <div cdkDrag
                    [cdkDragDisabled]="true"
                    [cdkDragBoundary]="'.tableScroll'" > -->
                    <div 
                        [ngClass]="(item.IsActive === 'Inactive' || item.isActive === 'Inactive' || item.Active === 'Inactive' || item.Status === 'Inactive' || item.payersstatus === 'Inactive')?'col-sm-12 card-data mt-1 ml-0  mb-1 inactive-class':'col-sm-12 card-data mt-1 ml-0  mb-1'"
                        *cdkVirtualFor="let item of GridData | searchfiltersTemplate: templatefilter" >
                        <div (click)="RedirectToView(item)" [ngClass]="header.colWidth"
                            class="text-muted   text-ellipsis dataTable"
                              #isElipsed
                            *ngFor="let header of gridHeader">
                            <div *ngIf="header?.Name?.toLowerCase() !='default' && header?.Name?.toLowerCase() !='expiry date'" appTooltipEllipsis  #isElipsed  class="text-muted   text-ellipsis dataTable">
                                {{ displayedColumns(item,header) | stripHtml }}</div>
                            <mat-icon
                                *ngIf="header?.Name?.toLowerCase() =='default' && displayedColumns(item,header)== 'Yes'"
                                style="position: relative;left: 35%;color: #0B9601; height: 17px;">check</mat-icon>

                            <span *ngIf="header?.Name?.toLowerCase() =='expiry date'" [ngClass]="displayedColumns(item,header) == 'Expired' ? 'expiry-date' : ''">
                                {{ displayedColumns(item,header)}}</span>
                        </div>
                        <div class="col-sm-1 btn-wraps d-flex" style="justify-content: end;">
                            <mat-icon *ngIf="!hideFormCopy" class="pr-2 actions" matTooltip="Copy"
                                (click)="formCopyMethod(item)">content_copy</mat-icon>
                            <mat-icon *ngIf="!hideEdit" class="actions ml-2" matTooltip="Edit"
                                (click)="editMethod(item)" [ngClass]="{'btnDisabled': isBtnDisabled}">create</mat-icon>
                            <mat-icon *ngIf="!hideDelete" class="actions ml-2" matTooltip="Delete"
                                (click)="deleteMethod(item)">delete</mat-icon>
                        </div>
                    </div>
                <!-- </div> -->
                </cdk-virtual-scroll-viewport>
                    </span>
                    <div class="col-sm-12 nodata-header-wrap" *ngIf="GridData?.length === 0">
                        <span class="col-sm-12 nodata-wrapper"> No Data Available </span>
                    </div>
                    <div class="col-sm-12 nodata-header-wrap" *ngIf="(GridData | searchfiltersTemplate: templatefilter)?.length === 0 && searchText && GridData?.length !== 0">
                        <span class="col-sm-12 nodata-wrapper"> No Results </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="!listScreen">
    <app-templates-view [HeaderName]="addEditContext==''?filename:addEditContext" [hideEdit]="hideEdit" [viewRecord]="ViewRowData"
        [templateData]="templateData" [createNewClicked]="createChanged" [editExistingClicked]="editChanged"
        [dynamicFormData]="customisedJsonData" [subMenuCardModel]="subMenuCardModel"
        [selectedListOption]="selectedListOption" [saveOrEditCompleted]="saveOrEditCompleted"
        (saveOrEditEventTriggered)="saveOrEditEventTriggered($event)" (closeViewTemplate)="handleViewScreen($event)"
        [isUserLevel]="isUserLevel" [isAdmin3]="isAdmin3" [enableorDisableSeqOnDefault]="enableorDisableSeqOnDefault"
        (editTemplateClickedFromView)="editTemplateClickedFromView($event)" [isHideTopSectionInCreate]="isHideTopSectionInCreate">
    </app-templates-view>
</div>
