import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TemplateHeadersService {
  templateList = [{
    templateName: "Case Comments",
    headers: [
      { Name: 'Case Type', type: 'input', key: 'CaseType', colWidth: 'col-sm-2' }
      , { Name: 'Sites', type: 'input', key: 'SiteName', colWidth: 'col-sm-1' }
      , { Name: 'Template Name', type: 'input', key: 'TemplateName', colWidth: 'col-sm-2' }
      , { Name: 'Description', type: 'input', key: 'Description', colWidth: 'col-sm-5' }
      , { Name: 'User', type: 'text', key: 'User', colWidth: 'col-sm-2' }
    ]
  },
  {
    templateName: "Grossing template",
    headers: [
      { Name: 'Case Type', type: 'text', key: 'CaseType', colWidth: 'col-sm-2' }
      , { Name: 'Sites', type: 'input', key: 'SiteName', colWidth: 'col-sm-1' }
      , { Name: 'Template Name', type: 'input', key: 'TemplateName', colWidth: 'col-sm-2' }
      , { Name: 'Description', type: 'input', key: 'Description', colWidth: 'col-sm-4' }
      , { Name: 'Default', type: 'text', key: 'IsDefault', colWidth: 'col-sm-1' }
    ]
  },
  {
    templateName: "Rejection template",
    headers: [
      { Name: 'Template Name', type: 'input', key: 'AttributeName', colWidth: 'col-sm-3' }
      , { Name: 'Description', type: 'input', key: 'AttributeDescription', colWidth: 'col-sm-7' }
    ]
  },
  {
    templateName: "Screening Comments",
    headers: [
      { Name: 'Case Type', type: 'text', key: 'CaseType', colWidth: 'col-sm-2' }
      , { Name: 'Template Name', type: 'input', key: 'TemplateName', colWidth: 'col-sm-3' }
      , { Name: 'Description', type: 'input', key: 'Description', colWidth: 'col-sm-4' }
      , { Name: 'Default', type: 'text', key: 'IsDefault', colWidth: 'col-sm-1' }
    ]
  },
  {
    templateName: "RevisionNotes",
    headers: [
      { Name: 'Case Type', type: 'text', key: 'CaseType', colWidth: 'col-sm-3' }
      , { Name: 'Template Name', type: 'input', key: 'TemplateName', colWidth: 'col-sm-2' }
      , { Name: 'Description', type: 'input', key: 'Description', colWidth: 'col-sm-5' }
    ]
  },
  {
    templateName: "RevisionReasons",
    headers: [
      { Name: 'Template Name', type: 'input', key: 'TemplateName', colWidth: 'col-sm-3' }
      , { Name: 'Description', type: 'input', key: 'Description', colWidth: 'col-sm-7' }
    ]
  },
  {
    templateName: "AdequacyCodes",
    headers: [
      { Name: 'Case Type', type: 'input', key: 'CaseType', colWidth: 'col-sm-2' }
      , { Name: 'Adequacy Code', type: 'input', key: 'Name', colWidth: 'col-sm-3' }
      , { Name: 'Description', type: 'text', key: 'Description', colWidth: 'col-sm-5' }
    ]
  },
  {
    templateName: "Manage Payers",
    headers: [
      { Name: 'Payer Name', type: 'text', key: 'PayerName', colWidth: 'col-sm-3' }
      , { Name: 'Electronic Payer ID', type: 'input', key: 'ElectronicPayerID', colWidth: 'col-sm-2' }
      , { Name: 'Payer Family', type: 'input', key: 'PayerFamily', colWidth: 'col-sm-2' }
      , { Name: 'Address', type: 'input', key: 'Address', colWidth: 'col-sm-2' }
      , { Name: 'State', type: 'input', key: 'State', colWidth: 'col-sm-2' }
    ]
  },
  {
    templateName: "MicroscopicCodes",
    headers: [
      { Name: 'Case Type', type: 'input', key: 'CaseType', colWidth: 'col-sm-2' }
      , { Name: 'Microscopic Code', type: 'input', key: 'Name', colWidth: 'col-sm-3' }
      , { Name: 'Description', type: 'text', key: 'Description', colWidth: 'col-sm-5' }
    ]
  },
  {
    templateName: "DeletionReasons",
    headers: [
      { Name: 'Template Name', type: 'input', key: 'TemplateName', colWidth: 'col-sm-3' }
      , { Name: 'Description', type: 'input', key: 'Description', colWidth: 'col-sm-7' }
    ]
  },
  {
    templateName: "Categories",
    headers: [
      { Name: 'Category Type', type: 'input', key: 'CategoryType', colWidth: 'col-sm-3' }
      , { Name: 'Category Name', type: 'input', key: 'CategoryName', colWidth: 'col-sm-7' }
    ]
  },
  {
    templateName: "DiagnosisReferences",
    headers: [
      { Name: 'Case Type', type: 'input', key: 'CaseType', colWidth: 'col-sm-3' },
      { Name: 'Template Name', type: 'input', key: 'TemplateName', colWidth: 'col-sm-3' },
      { Name: 'Description', type: 'input', key: 'Description', colWidth: 'col-sm-4' }
    ]
  },
  {
    templateName: "CustomAbbreviations",
    headers: [
      { Name: 'Case Type', type: 'input', key: 'caseType', colWidth: 'col-sm-3' },
      { Name: 'Abbreviation', type: 'input', key: 'Abbreviation', colWidth: 'col-sm-3' },
      { Name: 'Phrase', type: 'input', key: 'Phrase', colWidth: 'col-sm-4' }
    ]
  },
  {
    templateName: "UserDefaultRoles",
    headers: [
      { Name: 'Email', type: 'input', key: 'UserEmail', colWidth: 'col-sm-3' }
      , { Name: 'Role Name', type: 'input', key: 'RoleName', colWidth: 'col-sm-3' }
      , { Name: 'Deployment', type: 'input', key: 'DeploymentKey', colWidth: 'col-sm-2' }
      , { Name: 'Entity', type: 'input', key: 'Entity', colWidth: 'col-sm-2' }
    ]
  },
  {
    templateName: "SequesterReasons",
    headers: [
      { Name: 'Template Name', type: 'input', key: 'TemplateName', colWidth: 'col-sm-3' }
      , { Name: 'Description', type: 'input', key: 'Description', colWidth: 'col-sm-7' }
    ]
  },
  {
    templateName: "Correlation Templates",
    headers: [
      { Name: 'Case Type', type: 'text', key: 'CaseType', colWidth: 'col-sm-3' }
      , { Name: 'Name', type: 'input', key: 'AttributeValue', colWidth: 'col-sm-3' }
      , { Name: 'Summary', type: 'input', key: 'AttributeDescription', colWidth: 'col-sm-4' }
      // , { Name: 'Default', type: 'text', key: 'IsDefault', colWidth: 'col-sm-1' }
    ]
  },
  {
    templateName: "Tubetype Templates",
    headers: [
      { Name: 'Case Type', type: 'text', key: 'CaseType', colWidth: 'col-sm-3' }
      , { Name: 'Name', type: 'input', key: 'AttributeValue', colWidth: 'col-sm-3' }
      , { Name: 'Summary', type: 'input', key: 'AttributeDescription', colWidth: 'col-sm-4' }
      // , { Name: 'Default', type: 'text', key: 'IsDefault', colWidth: 'col-sm-1' }
    ]
  },
  {
    templateName: "Nomenclature Templates",
    headers: [
      { Name: 'Case Type', type: 'text', key: 'CaseType', colWidth: 'col-sm-3' }
      , { Name: 'Name', type: 'input', key: 'TemplateName', colWidth: 'col-sm-3' }
      , { Name: 'Description', type: 'input', key: 'Description', colWidth: 'col-sm-4' }
    ]
  },
  {
    templateName: "AutoAssignmentRule-path",
    headers: [
      { Name: 'Pathologist', type: 'input', key: 'Name', colWidth: 'col-sm-2' }
      , { Name: 'Ordering Facility', type: 'input', key: 'OrderingFacility', colWidth: 'col-sm-2' }
      , { Name: 'Ordering Physician', type: 'input', key: 'OrderingPhysician', colWidth: 'col-sm-2' }
      , { Name: 'Casetype', type: 'input', key: 'CaseType', colWidth: 'col-sm-1' }
      , { Name: 'Effective Date', type: 'input', key: 'EffectiveDate', colWidth: 'col-sm-2' }
      , { Name: 'Expiry Date', type: 'input', key: 'DisplayExpiryDate', colWidth: 'col-sm-2' }
    ]
  },
  {
    templateName: "AutoAssignmentRule-cyto",
    headers: [
      { Name: 'Cytotechnologist', type: 'input', key: 'Name', colWidth: 'col-sm-2' }
      , { Name: 'Ordering Facility', type: 'input', key: 'OrderingFacility', colWidth: 'col-sm-2' }
      , { Name: 'Ordering Physician', type: 'input', key: 'OrderingPhysician', colWidth: 'col-sm-2' }
      , { Name: 'Casetype', type: 'input', key: 'CaseType', colWidth: 'col-sm-1' }
      , { Name: 'Effective Date', type: 'input', key: 'EffectiveDate', colWidth: 'col-sm-2' }
      , { Name: 'Expiry Date', type: 'input', key: 'DisplayExpiryDate', colWidth: 'col-sm-2' }
    ]
  },
  {
    templateName: "Disclaimers",
    headers: [
      { Name: 'Disclaimer', type: 'input', key: 'Disclaimer', colWidth: 'col-sm-9' }
      , { Name: 'Type', type: 'input', key: 'Type', colWidth: 'col-sm-2' }
    ]
  },
  {
    templateName: "Sendout Reasons",
    headers: [
       { Name: 'Reason Name', type: 'input', key: 'TemplateName', colWidth: 'col-sm-4' }
      , { Name: 'Description', type: 'input', key: 'Description', colWidth: 'col-sm-6' }
    ]
  },

    // workgroup
    {
      templateName: "Workgroups",
      headers: [
        { Name: 'Department Name', type: 'input', key: 'departmentName', colWidth: 'col-sm-5' }
        , { Name: 'Worklists', type: 'input', key: 'customWorklist', colWidth: 'col-sm-5' }
      ]
    },

  {
    templateName: "Report Signatories",
    headers: [
      { Name: 'Entity Type', type: 'input', key: 'EntityType', colWidth: 'col-sm-3' }
      , { Name: 'Entity Name', type: 'input', key: 'EntityName', colWidth: 'col-sm-4' }
      , { Name: 'User Name', type: 'input', key: 'UserName', colWidth: 'col-sm-3' }
    ]
  },
  {
    templateName: "Sites",
    headers: [
      { Name: "Case Type", type: "text", key: "CaseType", colWidth: "col-sm-2" },
      { Name: 'Body Site', type: 'input', key: 'SubSite', colWidth: 'col-sm-5' },
      { Name: 'Site Order', type: 'input', key: 'SiteOrder', colWidth: 'col-sm-4' }
    ]
  },
  {
    templateName: "Extraction Procedures",
    headers: [
      { Name: 'Name', type: 'input', key: 'ExtnProcedureName', colWidth: 'col-sm-8' }
      // ,{ Name: 'Sequence', type: 'text', key: 'Sequence', colWidth: 'col-sm-3' }
      , { Name: 'Default', type: 'text', key: 'IsDefault', colWidth: 'col-sm-1' }
    ]
  },
  {
    templateName: "Extraction Procedures Lab",
    headers: [
      { Name: 'Name', type: 'input', key: 'ExtnProcedureName', colWidth: 'col-sm-6' }
      // ,{ Name: 'Sequence', type: 'text', key: 'Sequence', colWidth: 'col-sm-3' }
      , { Name: 'Case Type', type: 'text', key: 'CaseType', colWidth: 'col-sm-3' } 
      , { Name: 'Default', type: 'text', key: 'IsDefault', colWidth: 'col-sm-1' }
    ]
  },
  {
    templateName: "Medications",
    headers: [
       { Name: 'Medication Code', type: 'input', key: 'MedicationCode', colWidth: 'col-sm-3' }
      , { Name: 'Medication Brand', type: 'input', key: 'MedicationBrand', colWidth: 'col-sm-3' }
      , { Name: 'Medication Description', type: 'input', key: 'MedicationDescription', colWidth: 'col-sm-4' }
    ]
  },
    {
    templateName: "Observation & Quantitation Templates",
    headers: [
      { Name: 'Name', type: 'input', key: 'templateName', colWidth: 'col-sm-3' }
      , { Name: 'Description', type: 'input', key: 'templateDescription', colWidth: 'col-sm-7' }
    ]
  },
  {
    templateName: "Clinical Information",
    headers: [
      { Name: 'Name', type: 'input', key: 'Attribute_Name', colWidth: 'col-sm-3' }
      , { Name: 'Description', type: 'input', key: 'Attribute_Description', colWidth: 'col-sm-7' }
    ]
  },
  {
    templateName: "Cassette Templates",
    headers: [
      { Name: 'Case Type', type: 'text', key: 'CaseType', colWidth: 'col-sm-2' }
      , { Name: 'Template Name', type: 'input', key: 'TemplateName', colWidth: 'col-sm-3' }
      , { Name: 'Description', type: 'input', key: 'Description', colWidth: 'col-sm-4' }
      , { Name: 'Default', type: 'text', key: 'IsDefault', colWidth: 'col-sm-1' }
    ]
  },

  ]
  constructor() { }
}
