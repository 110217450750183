export enum routeTypes {
  clients = 'clients',
  clientUser = 'clientUser',
  labUser = 'labUser',
  providerUser = 'providerUser',
  refferingphysicianUser = 'refferingphysicianUser',
  location = 'location',
}

export const editUrlObj = Object.freeze({
  clients: {
    Htext: 'Edit Client',
    routerLink: 'labadmin/clients/edit/',
    Level: 1,
    queryParams: null,
    globalRouterLink:"/labadmin/globalsearch/search-records/results/edit/",
  },

  clientUser: {
    Htext: 'Edit User',
    routerLink: 'labadmin/clients/users/edit/',
    Level: 3,
    queryParams: { type: 'of' },
    globalRouterLink:"/labadmin/globalsearch/search-records/users/edit/",
    URL:"Users"
  },

  labUser: {
    Htext: 'Edit User',
    routerLink: 'labadmin/lab/users/edit/',
    Level: 2,
    queryParams: { type: 'lab' },
    globalRouterLink:"/labadmin/globalsearch/search-records/users/edit/",
    URL:"Users"
  },

  providerUser: {
    Htext: 'Edit User',
    routerLink: 'labadmin/clients/orders/edit/',
    Level: 3,
    queryParams: null,
    globalRouterLink:"/labadmin/globalsearch/search-records/orders/edit/",
    URL:"Providers"
  },

  refferingphysicianUser: {
    Htext: 'Edit User',
    routerLink: 'labadmin/clients/referring-physicians/edit/',
    Level: 3,
    queryParams: null,
    globalRouterLink:"/labadmin/globalsearch/search-records/referring-physicians/edit/",
    URL:"Referring Physicians"
  },

  location: {
    Htext: 'Edit Location',
    routerLink: 'labadmin/clients/results/edit/',
    Level: 3,
    queryParams: null,
    globalRouterLink:"/labadmin/globalsearch/search-records/results/edit/",
    URL:"Locations"
  },
});

