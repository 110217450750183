import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
//import 'src/assets/css/styles.css';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { Component, Input, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { take } from 'rxjs';
@Component({
  selector: 'app-new-diagnosis-templates',
  templateUrl: './new-diagnosis-templates.component.html',
  styleUrls: ['./new-diagnosis-templates.component.scss']
})
export class NewDiagnosisTemplatesComponent implements OnInit {
  @Input() public templateData: any
  timeZone: any;
  deplomentUrl: any;
  sourceApp: string;
  queryParams: any;
  dxtemplateurl: any;
  constructor(public sanitizer: DomSanitizer, private ngxService: NgxUiLoaderService, public _snackbar: MatSnackBar,private vitalHttpService:VitalHttpServices,
    private labAdminSessionService : LabadminSessionService,
  ) { }

  deployment: string;
  iframeSrc: SafeResourceUrl;


  async ngOnInit() {
    this.ngxService.start();
    this.deployment = sessionStorage.getItem('deploymentKey');
    await this.deploymentURL();
    let checkLaunchUrl=window.location.href.split('/#/')[1]
    if(checkLaunchUrl.toString().toLocaleLowerCase()!="home"){
      this.sourceApp='LabAdmin';
      let _this=this;
      await this.labAdminSessionService.getLabAdminSession.pipe(take(1)).subscribe(async session => {
        if (session["userDetails"] && Object.keys(session["userDetails"]).length > 1) {
          await _this.getTimeZone(session["userDetails"].userId);
          _this.queryParams = {
            emailId: localStorage.getItem("UserEmail"),
            organizationId: session["userDetails"].organizationId,
            accountID:session["userDetails"].userAccountIDs?.map(account => account.accountId).join(','),
            userID: session["userDetails"].userId,
            userRole: session["userDetails"].defaultRole,
            deploymentKey: _this.deployment,
            sourceApp: "LabAdmin",
            TimeZoneDetails:_this.timeZone,
            DeploymentURL:_this.deplomentUrl.toString()
          };
        }
        this.calculateIframeSrc(this.queryParams);
      })
    }
    else{
      this.sourceApp='VitalAdmin';
      await this.getTimeZone(sessionStorage.getItem("Userid") || "-100");
       this.queryParams = {
        emailId: localStorage.getItem('UserEmail'),
        caseType: this.templateData.secondarykeys.casetype,
        organizationId: sessionStorage.getItem("org_id"),
        accountID: sessionStorage.getItem("Locationid"),
        userID: sessionStorage.getItem("Userid") ? sessionStorage.getItem("Userid") : "-100",
        deploymentKey: this.deployment,
        userRole: sessionStorage.getItem("RoleName"),
        sourceApp: "VitalAdmin3",
        TimeZoneDetails:this.timeZone,
        DeploymentURL:this.deplomentUrl.toString()
      };
      this.calculateIframeSrc(this.queryParams);
    }
    this.ngxService.stop();
  }
  private calculateIframeSrc(queryParams): void {
    this.vitalHttpService.GetAllUrls().subscribe(
      result => {
        this.ngxService.stop();
        if (!result.errors) {
          this.dxtemplateurl = result.DxTemplateUrl;
          const baseUrl = this.dxtemplateurl + 'TemplatesController/sso'
          let finalurl = `${baseUrl}?${this.serialize(queryParams)}`;
          this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(finalurl);
        } else {
          this._snackbar.open(
            'An error occurred while processing your request',
            'Failed'
          );
        }
      },
      error => {
        console.error(error);
      }
    );
  }
  private serialize(obj: { [key: string]: string }): string {
    return Object.keys(obj)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      .join('&');
  }
  async getTimeZone(userId) {
    this.ngxService.start();
    await this.vitalHttpService.getTimeZone(userId, this.deployment).toPromise().then(async result => {
      this.ngxService.stop();
      if (result.content.Success) {
        this.timeZone = result.content.Message;
      }
      else {
        this.timeZone = ""
      }
    }, error =>{
      console.error(error)
    this.ngxService.stop();
  })
  }
  async deploymentURL() {
    let obj={
      "entityid":sessionStorage.getItem("Locationid") || 0,
      "name": "System.DeploymentID",
      "entytitype": 3,
      "ConfigName": "System.DeploymentID",
      "scope": 3,
      "tableName": "VaEntityExtAttributes"
    }
    this.ngxService.start();
    await this.vitalHttpService.getVAEntityAttribute(obj, this.deployment).toPromise().then(async res => {
      this.ngxService.stop();
      if (res && res.Success) {
        let data = JSON.parse(res.Message);
        this.deplomentUrl = data[0].Deployment_Level_Value_2;
      }
    },  error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", "Failed");
    });
  }
}
