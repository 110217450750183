<div>
    <div class="px-4" *ngIf="sourceApp =='VitalDx' && loading">
        <app-shimmerui [line] = "8"></app-shimmerui>
    </div>

    <div *ngIf="!loading" class="col-lg-12 p-0 m-md-1 m-lg-0">
        <app-templates-listing  [gridHeader]="listedGridHeader" [gridData]="gridData"
        [context]="'Observation & Quantitation Templates'" [hideEdit]="editBtn" [hideCreate]="createBtn" [hideUpload]="true"
        [hideExport]="exportBtn" [hideCopy]="true"
        [hideDelete]="deleteBtn"
        (deleteTemplateClicked)="newDeleteMethod($event)"
        (saveOrEditEventFromListingTriggered)="recieveObjFromListing($event)" [templateData]="templateData"
        [subMenuCardModel]="SubMenuCardModel" [saveOrEditCompleted]="saveOrEditCompleted" [showHeaderModule]="true"
        [isAdmin3]="sourceApp =='VitalDx'? false : true" [exportExcelFields]="sourceApp =='VitalDx' ? exportExcelFields : null">
    </app-templates-listing>
    </div>
</div>
