import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {  MatDialog } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import * as XLSX from 'xlsx';
import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjCore from '@grapecity/wijmo';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { ResultNestedComponent } from './../ResultNested/resultnested.component';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-bulk-upload-order-result-code',
  templateUrl: './bulk-upload-order-result-code.component.html',
  styleUrls: ['./bulk-upload-order-result-code.component.scss']
})
export class BulkUploadOrderResultCodeComponent implements OnInit {
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  @Input()
  public templateData: any;
  public hideupload: boolean;
  gridPage: boolean = true;
  SubMenuCardModel: SubMenuCardModel;
  AllFields = [{}]
  MandatoryFields = [{}]
  BulkUpdate = [{}]
  ResultCode = [{}]
  Associatedcode= [{}]
  OrderCodescoloumname:any
  ResultCodescoloumname:any
  gridWidth: number = 0;
  sheetsToSelect: any;
  selectedSheet: any;
  worksheet: any;
  workBook: any;
  gridDisplay: boolean = false;
  gridEmpty: boolean = false;
  excelDataArray = [];
  gridArray = [];
  selectedrowData: any = {};
  sheetHeader: string[];
  disableApprove: boolean = false;
  isProperfile: boolean;
  invalidColumns: string = "";
  showInvalidColumns: boolean = false;
  highlightNotes = true;
  showDelete: boolean = true;
  gridData: wjcCore.CollectionView<any>;
  stainGridData: wjcCore.CollectionView<any>;
  postDownload: boolean = false;
  postUpload: boolean = false;
  noDataFound: boolean = false;
  showPaginationMainGrid: boolean = false;
  gridheader: any = [];
  length: number;
  panelCtrl: FormControl;
  panelDropdown = [];
  action: string = "";
  currentData: any = {};
  uploadBtn: boolean = true;
  createBtn: boolean = true;
  editBtn: boolean = true;
  exportBtn: boolean = true;
  readonlyTestSequence: boolean = false;
  bulkUpload: boolean = false;
  resultcode: boolean = false;
  AssociatedResultCodes: boolean = false;
  SampleDataAllfields=[{
     Name:'DummyTest'
    ,OrderCodeType:'Profile/Panel/Test'
   	,CPTCodes: '01234'
    ,IsOrderable:	'0'
    ,ParentName:	'Name(Profile/Panel/Test)'
    ,Testname:	'Testname '
    ,resultcodename:'testresultcode1(panel/test)'
    ,resultordercodename:'DummyTest'
    ,OrderCode:	'test1'
    , Aliases:'Comprehensive PGx Panel'
    ,Resultcode:	'raushan1'
    ,Units:	'ng/mL'
    ,ReferenceRangeRules: '50 ng/mL'
    ,MachineName:	'Mass Spectrometer'
    ,Numberofdecimal:'1'
    , TubeType: "abc"
    , NUMBER_DRAWLABELS: "abc"
    , NUMBER_ALIQUOTLABELS: "abc"
    , SpecimenType: "dummytest"
    , TransportTemparature: "abc"
    , TurnArroundTime: "dummy"
    , MinimumVolume: "dummydata"
    , Abbreviation: "dummy"
    , ReportDisplay: "dummy"
    , RequisitionDisplay: "dummy"
    , LoincId: "dummy"
    , Sequence: "100"
    , Description: "dummy"
    , HeaderName: "dummy"
    , IsActive: "0/1"
    , PatientRequirementProperties: "dummy"
    , GenderProperties: "dummy"
    , EthinicityProperties: "dummy"
    , AgeProperties: "dummy"
    , OrderableType: "dummy"
    , IsOrderableOnlyOnce: "0"
    , MessageProperties: "dummy"
    , Workgroups: "dummy"
    , AutoReleaseNegatives: "0"
    , AutoReleaseNormals: "0"
    , IsReportable: "0"
    , DisplayName: "displayname"
    , Comments: "dummy comments"
    , ApplicableCriteria: "dummy criteria"
    , AllowDuplicates: "1"
    , TestCategory: "dummy test"
    , PanelBehaviour: "dummy panel"
    , ClientJson: "dummy client"
    , AutoReleasePrevNormalsDuration: "dummy test prev"
    , AutoReleasePrevNegativesDuration: "dummy test prev negative"
    , AreaORSpeciality: "dummy special"
    , OrderableOnlyOnceType: "dummy orderable"
    , EffectiveDate: "12-02-2023"
    , IsReviewRequired: "1"
    , CaseType: "casename"
    , EntityCode: "dummy code"
    , SpecimenCategoryId: "1"
    , AccessionUiProfileId: "1"
    , SpecimenUiProfileId: "1"
    , RequisitionPrefix: "dummy test"
    , VOAliases: "dummy va"
    , VOIsOrderable: "1"
    , Mnemonic: "abc"
    , PossibleICDCodes: "124"
    , IsQC: "abc"
    , AOETemplateData: "abc"
    , TurnArroundTimeUnits: "1"
    , IsPatientOrderable: "1"
    , PatientCharge: "dummy"
    , IsEditable: "1"
    , DisableResultNotification: "abc"
    , GrossingUIProfileID: "1"
    , VOIsEditable: "1"
    , IsInternalized: "1"
    , ResultCode: "name or sampleName"
    , Abbrivation: "number or 0125"
    , CodeName: "name or Quetiapine"
    , RptMsg: "name or abc"
    , InHouse: "0 or 1"
    , Report: "0 or 1"
    , DfltRes: "name or abc"
    , AskatOE: "0 or 1"
    , ValidationKey: "name or sampleData"
    , CodeSets: "name or sampleData"
    , ResultClass: "name or sampleData"
    , Status: "Active"
    , MachineTestCode: "name or sampleData"
    , PreviousResultDurationInDays: "name or 54750"
    , AlphaNormals: "name or sampleData"
    , AlphaAbnormalFlag: "name or sampleData"
    , AbnormalFlagComments: "name or sampleData"
    , CommentCode: "name or sampleData"
    , DilutionVolume: "name or sampleData"
    , DilutionComments: "name or sampleData"
    , Billable: "0 or 1"
    , DeltaCheck: "name or sampleData"
    , ShowPrevResultsonReport: "name or sampleData"
    , FootNotes: "name or sampleData"
    , EnableFootNotes: "name or sampleData"
    , DefaultValue: "name or sampleData"
    , PreviousReportResultsDuration: "name or sampleData"
    , ResultValueType: "name or sampleData"
    , PossibleValues: "name or sampleData"
    , SensitivityHighValue: "name or sampleData"
    , SensitivityLowValue: "name or sampleData"
    , ResultType: "name or sampleData"
    , LockStatus: "0 or 1"
    , LockReason: "name or sampleData"
    , ResultAttribute: "name or sampleData"
  }]
  SampleDataMinfields=[{
     Name:'DummyTest'
    ,OrderCodeType:'Profile/Panel/Test'
   	,CPTCodes: '01234'
    ,IsOrderable:	'0'
    ,ParentName:	'Name(Profile/Panel/Test)'
    ,Testname:	'Testname '
    ,resultcodename:'testresultcode1(panel/test)'
    ,resultordercodename:'DummyTest'
    ,OrderCode:	'test1'
    , Aliases:'Comprehensive PGx Panel'
    ,Resultcode:	'raushan1'
    ,Units:	'ng/mL'
    ,ReferenceRangeRules: '50 ng/mL'
    ,MachineName:	'Mass Spectrometer'
    ,Numberofdecimal:'1'
  }]
  sampleDataBulkUpdateResultCodes = [{
    Resultcodeid: "id or 1234"
    , ResultCode: "name or sampleName"
    , EffectiveDate: "date or 2023-07-18 19:47:30.530"
    , Abbrivation: "number or 0125"
    , CodeName: "name or Quetiapine"
    , Units: "ng/mL"
    , RptMsg: "name or abc"
    , InHouse: "0 or 1"
    , Report: "0 or 1"
    , DfltRes: "name or abc"
    , AskatOE: "0 or 1"
    , LoincId: "name or sampleData"
    , ValidationKey: "name or sampleData"
    , DisplayName: "name or sampleData"
    , ReferenceRangeRules: "name or sampleData"
    , CodeSets: "name or sampleData"
    , ResultClass: "name or sampleData"
    , Status: "Active"
    , Description: "name or sampleData"
    , MachineTestCode: "name or sampleData"
    , Sequence: "number or 123"
    , MachineName: "name or AU680"
    , PreviousResultDurationInDays: "name or 54750"
    , Numberofdecimal: "number"
    , ReportDisplay: "name or sampleData"
    , AlphaNormals: "name or sampleData"
    , AlphaAbnormalFlag: "name or sampleData"
    , AbnormalFlagComments: "name or sampleData"
    , CommentCode: "name or sampleData"
    , Comments: "name or sampleData"
    , DilutionVolume: "name or sampleData"
    , DilutionComments: "name or sampleData"
    , Billable: "0 or 1"
    , AllowDuplicates: "number or 123"
    , DeltaCheck: "name or sampleData"
    , ShowPrevResultsonReport: "name or sampleData"
    , FootNotes: "name or sampleData"
    , EnableFootNotes: "name or sampleData"
    , DefaultValue: "name or sampleData"
    , PreviousReportResultsDuration: "name or sampleData"
    , ResultValueType: "name or sampleData"
    , PossibleValues: "name or sampleData"
    , SensitivityHighValue: "name or sampleData"
    , SensitivityLowValue: "name or sampleData"
    , ResultType: "name or sampleData"
    , LockStatus: "0 or 1"
    , LockReason: "name or sampleData"
    , ResultAttribute: "name or sampleData"
  }]
  sampleDataAssociatedordercodes = [{
     OrderCodeID:"663"
    ,ResultCodeID:"1011"
    ,Sequence	:"100"
    ,IsReportable:"1"

  }]
  sampleDataBulkUpdateorderCodes = [{
    OrderCodeID: "number or 123"
    , OrderCode: "name or sampledata"
    , Name: "name or sampledata"
    , Aliases: "name or sampledata"
    , Notes: "name or sampledata"
    , IsOrderable: "0 or 1"
    , TubeType: "name or sampledata"
    , NUMBER_DRAWLABELS: "number"
    , NUMBER_ALIQUOTLABELS: "number"
    , SpecimenType: "name or sampledata"
    , TransportTemparature: "name or sampledata"
    , TurnArroundTime: "name or sampledata"
    , MinimumVolume: "name or sampledata"
    , Abbreviation: "name or sampledata"
    , CPTCodes: "name or sample123"
    , ReportDisplay: "name or sampledata"
    , RequisitionDisplay: "name or sampledata"
    , LoincId: "name or sampledata"
    , Sequence: "number"
    , Description: "name or sampledata"
    , HeaderName: "name or sampledata"
    , IsActive: "0 or 1"
    , PatientRequirementProperties: "name or sampledata"
    , GenderProperties: "name or sampledata"
    , EthinicityProperties: "name or sampledata"
    , AgeProperties: "name or sampledata"
    , OrderableType: "name or sampledata"
    , IsOrderableOnlyOnce: "name or sampledata"
    , MessageProperties: "name or sampledata"
    , Workgroups: "name or sampledata"
    , AutoReleaseNegatives: "name or sampledata"
    , AutoReleaseNormals: "name or sampledata"
    , IsReportable: "0 or 1"
    , DisplayName: "name or sampledata"
    , Comments: "name or sampledata"
    , ApplicableCriteria: "name or sampledata"
    , AllowDuplicates: "number"
    , TestCategory: "name or sampledata"
    , PanelBehaviour: "name or sampledata"
    , ClientJson: "name or sampledata"
    , AutoReleasePrevNormalsDuration: "name or sampledata"
    , AutoReleasePrevNegativesDuration: "name or sampledata"
    , AreaORSpeciality: "name or sampledata"
    , OrderableOnlyOnceType: "name or sampledata"
    , EffectiveDate: "date or 2019-10-30 00:00:00.000"
    , IsReviewRequired: "0 or 1"
    , CaseType: "name or sampledata"
    , EntityCode: "name or sampledata"
    , SpecimenCategoryId: "number"
    , AccessionUiProfileId: "number"
    , SpecimenUiProfileId: "number"
    , RequisitionPrefix: "name or sampledata"
    , VOAliases: "name or sampledata"
    , VOIsOrderable: "name or sampledata"
    , Mnemonic: "name or sampledata"
    , PossibleICDCodes: "name or sampledata"
    , IsQC: "0 or 1"
    , AOETemplateData: "name or sampledata"
    , TurnArroundTimeUnits: "name or sampledata"
    , IsPatientOrderable: "name or sampledata"
    , PatientCharge: "name or sampledata"
    , IsEditable: "0 or 1"
    , DisableResultNotification: "0 or 1"
    , GrossingUIProfileID: "number"
    , VOIsEditable: "0 or 1"
    , IsInternalized: "0 or 1"
  }]

  constructor(public _snackbar: MatSnackBar, private _fb: FormBuilder, private dialog: MatDialog,
    private datashare: DataShareService,
    public ResultNestedComponent: ResultNestedComponent,
    private commonService: CommonService, private vitalHttpServices: VitalHttpServices, public ngxService: NgxUiLoaderService) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      vitalHttpServices,
      datashare,
    ); }

  ngOnInit(): void {
    this.loadUploadScreen();
  }

   // Upload screen
   loadUploadScreen() {
    this.hideupload = true;
    this.gridPage = false;
    let queryVariable = { tablename: 'Organizationordercodes', identity: "false", nullable: "false" };
    let query = this.SubMenuCardModel.GetQuery("getTableColumns");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        //nested subscribe
        this.OrderCodescoloumname=data;
        let queryVariable = { tablename: 'ResultCodes', identity: "false", nullable: "false" };
       let query = this.SubMenuCardModel.GetQuery("getTableColumns");
       let queryResult = this.commonService.GetCardRequest(queryVariable, query);
       this.vitalHttpServices.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        this.ResultCodescoloumname=data;
          if (data) {
          let allFields = {}
          let bulkupdate = {}
          let resultcode={}
          allFields['Testname'] = ''
          allFields['Name'] = ''
          allFields['Cptcodes']=''
          allFields['OrderCode'] = ''
          allFields['OrderCodeType'] = ''
          allFields['IsOrderable'] = ''
          allFields['ParentName'] = ''
          allFields['Resultcode'] = ''
          allFields['resultcodename'] = ''
          allFields['resultordercodename'] = ''
          allFields['Aliases'] = ''
          allFields['Units'] = ''
          allFields['MachineName'] = ''
          allFields['Numberofdecimal'] = ''
          bulkupdate['OrderCodeID']=''
          resultcode['Resultcodeid'] = ''

          for (let i = 0; i < this.OrderCodescoloumname.data.allFields.length; i++) {
            allFields[this.OrderCodescoloumname.data.allFields[i]["Column"]] = ''
            bulkupdate[this.OrderCodescoloumname.data.allFields[i]["Column"]] =''
          }

          for (let i = 0; i < this.ResultCodescoloumname.data.allFields.length; i++) {
            allFields[this.ResultCodescoloumname.data.allFields[i]["Column"]] = ''
            resultcode[this.ResultCodescoloumname.data.allFields[i]["Column"]] = ''
          }
            this.AllFields[0] = allFields
            delete this.AllFields[0]['OrderCode']
            delete this.AllFields[0]['OrganizationID']
            delete this.AllFields[0]['OrderCodeGUID']
            delete this.AllFields[0]['icd9codes']
            delete this.AllFields[0]['CreatedDate']
            delete this.AllFields[0]['Createdby']
            delete this.AllFields[0]['ModifiedBy']
            delete this.AllFields[0]['ModifiedDate']
            delete this.AllFields[0]['ResultCodeGUID']
            delete this.AllFields[0]['Notes']
            delete this.AllFields[0]['Cptcodes']



           //bulk update for order code
            this.BulkUpdate[0]=bulkupdate;
            delete  this.BulkUpdate[0]['OrganizationID']
            delete  this.BulkUpdate[0]['OrderCodeGUID']
            delete  this.BulkUpdate[0]['CreatedDate']
            delete  this.BulkUpdate[0]['Createdby']
            delete  this.BulkUpdate[0]['ModifiedBy']
            delete  this.BulkUpdate[0]['ModifiedDate']
            delete  this.BulkUpdate[0]['OrderCodeType']

            // bulk update for result code
            this.ResultCode[0]=resultcode;
            delete  this.ResultCode[0]['OrganizationID']
            delete  this.ResultCode[0]['CreatedDate']
            delete  this.ResultCode[0]['Createdby']
            delete  this.ResultCode[0]['ModifiedBy']
            delete  this.ResultCode[0]['ModifiedDate']
            delete  this.ResultCode[0]['ResultCodeGUID']


            this.MandatoryFields[0]['Testname'] = ''
            this.MandatoryFields[0]['Name'] = ''
            this.MandatoryFields[0]['Cptcodes']=''
            this.MandatoryFields[0]['OrderCode'] = ''
            this.MandatoryFields[0]['OrderCodeType'] = ''
            this.MandatoryFields[0]['IsOrderable'] = ''
            this.MandatoryFields[0]['ParentName'] = ''
            this.MandatoryFields[0]['Resultcode'] = ''
            this.MandatoryFields[0]['resultcodename'] = ''
            this.MandatoryFields[0]['resultordercodename'] = ''
            this.MandatoryFields[0]['Aliases'] = ''
            this.MandatoryFields[0]['Units'] = ''
            this.MandatoryFields[0]['MachineName'] = ''
            this.MandatoryFields[0]['Numberofdecimal'] = ''

            this.Associatedcode[0]['OrderCodeID']=''
            this.Associatedcode[0]['ResultCodeID'] = ''
            this.Associatedcode[0]['Sequence'] = ''
            this.Associatedcode[0]['IsReportable'] = ''

          }
      }
    },error => {
        console.error(error);
      });



      }
    },error => {
        console.error(error);
      });
  }
   //Download Bulk Update order code
   downloadBulkUpdateOrderCode() {
    let filename = 'Order Codes_'+'Bulk Update_'+ this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(this.BulkUpdate);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'OrderandResultCodes');
    ws = XLSX.utils.json_to_sheet(this.sampleDataBulkUpdateorderCodes)
    XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
    XLSX.writeFile(wb, filename);
  }

    //Download Bulk Update Result Code
    downloadBulkUpdateResultCode() {
      let filename = 'Result Code_'+'Bulk Update_'+ this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
      var ws = XLSX.utils.json_to_sheet(this.ResultCode);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "" + 'OrderandResultCodes');
      ws = XLSX.utils.json_to_sheet(this.sampleDataBulkUpdateResultCodes)
      XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
      XLSX.writeFile(wb, filename);
    }

      //Download Bulk Update
   downloadBulkUpdateAssociatedResultCodes() {
    let filename = 'Associated Result Codes_'+'Bulk Update_'+ this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(this.Associatedcode);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'OrderandResultCodes');
    ws = XLSX.utils.json_to_sheet(this.sampleDataAssociatedordercodes)
    XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
    XLSX.writeFile(wb, filename);
  }

  // Download All Fields
  downloadAllFields() {
    let filename ='Order and Result Codes_'+'All Fields_'+ this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(this.AllFields);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'OrderandResultCodes');
    ws = XLSX.utils.json_to_sheet(this.SampleDataAllfields)
    XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
    XLSX.writeFile(wb, filename);
  }

  // Download Mandatory Fields
  downloadMandatoryFields() {
    let filename = 'Order and Result Codes_'+'Minimal Fields__'+ this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(this.MandatoryFields);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'OrderandResultCodes');
    ws = XLSX.utils.json_to_sheet(this.SampleDataMinfields)
    XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
    XLSX.writeFile(wb, filename);
  }

  // Back to upload
  refreshGrid() {
      this.showDelete = true
      this.bulkUpload=false;
      this.resultcode=false;
      this.AssociatedResultCodes=false;
      this.gridWidth = 0;
      this.excelDataArray = [];
      this.postUpload = false;
      this.postDownload = false;
      this.showInvalidColumns = false;
      this.invalidColumns = '';
      this.ResultNestedComponent.uploadClicked = false;
      this.ResultNestedComponent.detailsDisplay=false;
      this.ResultNestedComponent.gridDisplay = true;
     // this.loadUploadScreen();
      this.ResultNestedComponent.refreshBackData();

  }
  //upload ya drag and drop
  onFileDropped($event) {
    this.onFileChange($event);
  }

  // Method to hit once a file is dragged to or uploaded
  onFileChange(ev) {
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect.filter(va => va.toLowerCase() != 'sampledata');
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == 'OrderandResultCodes')
            validSheet.length > 0 ? this.convertToJson(validSheet[0]) : this.openModal();
          }
          else {
            this.convertToJson(this.sheetsToSelect[0])
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }

  //Method to validate sheet data.
  convertToJson(sheetname) {
    var worksheet;
    let tablename='OrganizationOrderCodes';
    this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    });
    this.excelDataArray = [];
    let tempExcelArr = [];
    tempExcelArr = XLSX.utils.sheet_to_json(worksheet, { defval: null });
    if (tempExcelArr.length == 0) {
      this._snackbar.open("The uploaded excel does not contain any data", "Close");
      tempExcelArr = [];
      return;
    }
    let primary = {}
    let tempArray = []
    for (let i = 0; i < tempExcelArr.length; i++) {
      for (let [key, value] of Object.entries(tempExcelArr[i])) {
        if (!key.toString().match(/empty/i)) {
          primary[key] = value;
        }
      }
      tempArray.push(primary)
      primary = {}
    }
    tempExcelArr = tempArray;
    this.sheetHeader = [];
    let temp = [];
    temp = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
    temp[0].reverse()

    temp[0].push("Notes")
    this.sheetHeader = temp[0];



    //use for upload and update
    if (this.sheetHeader.filter(element => element.toLowerCase() == 'ordercodeid' || element.toLowerCase() == 'resultcodeid' ).length > 0) {
      this.bulkUpload = true;


      for(let i = 0; i < this.sheetHeader.length; i++) {
        if(this.sheetHeader[i].toLowerCase() == 'ordercodeid')
        {
          tablename='OrganizationOrderCodes'
          break;
        }
        else if(this.sheetHeader[i].toLowerCase() == 'resultcodeid' && this.sheetHeader[i+1].toLowerCase() != 'ordercodeid')
        {
          tablename='ResultCodes'
          this.resultcode=true;
           break;
        }
        else if(this.sheetHeader[i].toLowerCase() == 'resultcodeid' && this.sheetHeader[i+1].toLowerCase() == 'ordercodeid')
        {
          tablename='AssociatedResultCodes'
           this.AssociatedResultCodes=true;
           break;
        }

      }
    }
    else {
      this.bulkUpload = false;
    }
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
  //  this.excelDataArray.sort((a, b) => a.groupname < b.groupname ? -1 : a.groupname > b.groupname ? 1 : 0)
    let i = 1;
    if(!this.bulkUpload)
    {
      this.excelDataArray.find(d => {
        Object.assign(d, { slno: i, tablename:tablename , organizationid: this.templateData.secondarykeys.OrganizationId, casetype: this.templateData.cardtype.toString(),type:'Upload'
        });
        i++;
      });
    }
    else {
      this.excelDataArray.find(d => {
        Object.assign(d, { slno: i, tablename:tablename , organizationid: this.templateData.secondarykeys.OrganizationId, casetype: this.templateData.cardtype.toString(),
        });
        i++;
      });
    }
    this.validateExcelData(this.excelDataArray);
  }


  // Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    //let result=this.commonService.selectdSheet;
    if(result){
      this.convertToJson(result)
    }
  }
   // Convert Obj to lower
  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        let value = ""
        value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
        newobj[key.replace(/\s/g, "").toLowerCase()] = value
      }
      array.push(newobj)
    }
    return array;
  }


  // Validate Excel
  validateExcelData(excelArr) {
    this.ngxService.start();
    let dataArray = [];
    dataArray = this.convertObjKeysToLower(excelArr);
   this.invalidColumns = '';
    this.showInvalidColumns = false;

     if (!this.bulkUpload) {
      this.vitalHttpServices.ValidateBulkUploadOrderandResultcodes(dataArray).subscribe(result => {
        this.ngxService.stop();
        if (!result.errors) {
          if (result.length > 0) {
            this.ngxService.stop();
            if ( this.invalidColumns && this.invalidColumns.length > 0) {
              this.showInvalidColumns = true;
              this.invalidColumns = result[0].InvalidColumns;
            }

            if (!result[0]['NoteMessage'] && result[0]["InvalidColumns"]) {
              // this.showInvalidColumns = false;
              this._snackbar.open("Uploaded template is invalid.Please try again with the correct template", 'Failed');
            // this._snackbar.open('Please upload a valid excel sheet','Close')
              return;
            }
            else {
              dataArray.filter(e => {
                result.filter(r => {
                  if (r.SlNo === e.slno) {
                    e.notes = r.NoteMessage;
                  }
                });
              });
              this.ngxService.stop();
              this.AddGridData(dataArray);
            }
            //  else {
            //    this.ngxService.stop();
            //  this._snackbar.open("Uploaded template is invalid.Please try again with the correct template", 'Failed');
            // }

          }
          else{
            this.ngxService.stop();
            this._snackbar.open("Uploaded template is invalid.Please try again with the correct template", 'Failed');
          }

        }
      }, error => {
        this.ngxService.stop();
        this._snackbar.open("An error occurred while processing your request", 'Failed');
      });
     }
    //for bulk update
    else {
      this.vitalHttpServices.ValidateBulkupdateOrderandResultcodes(dataArray).subscribe(result => {
        this.ngxService.stop();
        if (!result.errors) {
          if (result.length > 0) {
            this.ngxService.stop();
            if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
              this.showInvalidColumns = true;
              this.invalidColumns = result[0].InvalidColumns;
            }
            dataArray.filter(e => {
              result.filter(r => {
                if (r.SlNo === e.slno) {
                  e.notes = r.NoteMessage;
                }
              });
            });
            this.ngxService.stop();
          }
          this.AddGridData(dataArray);
        }
      }, error => {
        this.ngxService.stop();
        this._snackbar.open("An error occurred while processing your request", 'Failed');
      });
    }
  }

  // Grid Data
   AddGridData(data) {
    this.ngxService.start();
    this.sheetHeader;
    this.gridDisplay = true;
    this.gridArray = [];
    this.gridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          let mandatoryFieldEmpty = false
          for (let [key, value] of Object.entries(data[i])) {
            let flag = false;
            if (key.toString().toLowerCase() != 'name' && key.toString().toLowerCase() != 'ordercodetype' ) {
              if (value != null) {
                if (value.toString().toLowerCase().trim() == 'null') {
                  flag = true;
                }
                primary[key] = value;
              }
            }
            flag ? primary['notes'] = 'Null values exist!' : data[i]['notes'] == '' ? primary['notes'] = 'Valid' : null

            if (key.toString().toLowerCase() == 'name' || key.toString().toLowerCase() == 'ordercodetype' || key.toString().toLowerCase() == 'isorderable'

             ) {
              if (value == null || value.toString().toLowerCase().trim() == 'null' || (!/\S/.test(value.toString()))) {
                mandatoryFieldEmpty = true;
              }
              primary[key] = value;
            }
            if (mandatoryFieldEmpty) {
              value = 'Mandatory field is missing';
              this.excelDataArray[i]["notes"] = value;
              primary['notes'] = value;
            }
          }
          this.gridArray.push(primary);
        }
        this.excelDataArray = this.gridArray;
        this.gridData = new CollectionView(this.gridArray);
        this.gridWidth = (170 * this.sheetHeader.length) + 37;
        if (this.gridWidth > 1300) {
          this.gridWidth = 1300;
        }
      }

    }
    this.ngxService.stop();

    return false;
   }

  disableApprovebtn() {
    let existsCount = 0;
    for (let i = 0; i < this.excelDataArray.length; i++) {
      if (this.gridArray[i]['notes'] && (this.gridArray[i]['notes'].toString().includes('already exists for this Entity.') || this.gridArray[i]['notes'].toString().match(/Mandatory field is missing/i)) || this.gridArray[i]['notes'].toString().match(/Order Codes already exists for this Entity./i)) {
        existsCount++;
      }
    }
    if (existsCount == this.excelDataArray.length) {
      return true
    }
    return false;
  }

   // Delete Row
   deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    if (grid.rows.length == 0) {
      this.removeGrid();
    }
    this._snackbar.open('Deleted successfully', 'Close');
  }

    // Cancel
    removeGrid() {
      this.gridWidth = 0;
      this.excelDataArray = [];
      this.postUpload = false;
      this.postDownload = false;
      this.showInvalidColumns = false;
      this.invalidColumns = '';
      this.showDelete = true;
      this.loadUploadScreen();
    }
  // Export Grid data
  ExportExcel(flex) {
    let excel = [];
    flex.rows.find(e => {
      delete e._data["notemessage"];
      delete e._data['slno'];
      excel.push(e._data);
    });
    let filename = 'Order and Results Codes_' +  this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(
      excel.reverse()
    );
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'OrderandResultCodes');
    XLSX.writeFile(wb, filename);
  }

  //#regin to show notes
  formatItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      // if(e.panel.rows[0].dataItem && (e.panel.rows[0].dataItem=='notes'||e.panel.rows[0].dataItem=='status')){
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value == 'Mandatory field is missing' || value == 'Null' || value =='Order and Result codes already exists for this Entity.' ||value.toString().includes('Already Exists') ||value.toString().includes('exits')
          || value.toString().includes('Invalid') || value.toString().includes('accepts only')) {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value == 'Valid' || value.toString().includes('Order and Result codes inserted successfully') || value == 'Success' || value.toString().includes('Order and Result codes inserted successfully') || value.toString().includes('Successfully')) {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
        else if (value.toString().includes('already exist')) {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
      //  }
    }
  }
  //#endregion

   // Upload test Data
   uploadTestData() {
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if (!this.excelDataArray[i]["notes"]) {
          this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
        }
        else if (!this.excelDataArray[i]["notes"].toString().match(/approval/i) && !this.excelDataArray[i]["notes"].toString().match(/already exists/i) && !this.excelDataArray[i]["notes"].toString().match(/valid/i)) {
          errorcount++
        }
      }
      if (errorcount > 0) {
        this._snackbar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
        return
      }
      if ((this.invalidColumns != "")) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          for (let [key, value] of Object.entries(this.excelDataArray[i])) {
            if (value === "null" || value === "" || value === null) {
              this.excelDataArray[i][key] = null;
            }
            else {
              this.excelDataArray[i][key] = value.toString();
            }
          }
          if (!this.bulkUpload) {
            for (let i = 0; i < this.excelDataArray.length; i++) {
              this.excelDataArray[i]["createdby"] = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : sessionStorage.getItem("Userid")
              this.excelDataArray[i]["organizationid"] = this.templateData.secondarykeys.OrganizationId.toString()
              this.excelDataArray[i]["casetype"] =this.templateData.cardtype.toString();
              this.excelDataArray[i]["accountid"] = sessionStorage.getItem("AccountID");

            }
           }
          //bulk update
          else {
            if(!this.AssociatedResultCodes && !this.resultcode && this.bulkUpload==true)
            {
            for (let i = 0; i < this.excelDataArray.length; i++) {
              this.excelDataArray[i]["modifiedby"] = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : sessionStorage.getItem("Userid")
              this.excelDataArray[i]["organizationid"] = this.templateData.secondarykeys.OrganizationId.toString()
              this.excelDataArray[i]["casetype"] = this.templateData.cardtype.toString();
              this.excelDataArray[i]["type"]="ordercodes";
            }
            }
            else if(!this.AssociatedResultCodes && this.resultcode==true && this.bulkUpload==true){
              for (let i = 0; i < this.excelDataArray.length; i++) {
                this.excelDataArray[i]["modifiedby"] = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : sessionStorage.getItem("Userid")
                this.excelDataArray[i]["organizationid"] = this.templateData.secondarykeys.OrganizationId.toString()
                this.excelDataArray[i]["casetype"] = this.templateData.cardtype.toString();
                this.excelDataArray[i]["type"]="resultcodes";
              }
            }
            else if(!this.resultcode && this.AssociatedResultCodes==true && this.bulkUpload==true){
              for (let i = 0; i < this.excelDataArray.length; i++) {
                this.excelDataArray[i]["modifiedby"] = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : sessionStorage.getItem("Userid")
                this.excelDataArray[i]["organizationid"] = this.templateData.secondarykeys.OrganizationId.toString()
                this.excelDataArray[i]["casetype"] = this.templateData.cardtype.toString();
                this.excelDataArray[i]["type"]="associated";
              }
            }
          }
        }
      }
      let dataArray = [];
      this.ngxService.start();

      dataArray = this.convertObjKeysToLower(this.excelDataArray);
      if (!this.bulkUpload) {
        this.vitalHttpServices.BulkUploadOrderandresultCodes(dataArray).subscribe(result => {
          this.ngxService.stop();
          if (!result.errors) {
            if (result.length > 0) {
              this.postUpload = true;
              this.postDownload = true;
              this.showDelete = false;
              //this.inData=null;
              this.commonService.createActivityTracker('Created', -1, 'Bulk Upload OrderandResult codes', 'Audit', dataArray, {});
              //--start
              this.excelDataArray = [];
              this.excelDataArray = this.convertObjKeysToLower(result);
              for (let i = 0; i < result.length; i++) {
                this.excelDataArray[i]["notes"] = result[i]["NoteMessage"];
                if (result[i]["NoteMessage"].match(/already exists/)) {
                  this.excelDataArray[i]["status"] = "Ignored"
                }
                else {
                  this.excelDataArray[i]["status"] = result[i]["STATUS"]
                }
              }
              //--end
              this.ngxService.start();
             // this.bulkUpload = false;
              this.AddGridData(this.excelDataArray);
              this.ngxService.stop();
              let dataExistsCount = 0;
              for (let i = 0; i < result.length; i++) {
                if (Object.values(result).every(function (item: any) {
                  return item.STATUS == "Ignored" ? true : false
                })) {
                  dataExistsCount++;
                }
              }

            }
            else {
              this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close');
            }
          }
        }, error => {
          this.ngxService.stop();
          this._snackbar.open('Something went wrong.Please try again', 'Close');
          console.error(error)
        })
      }
      //bulk update
      else {
        this.vitalHttpServices.BulkUpdateOrderandresultCodes(dataArray).subscribe(result => {
          this.ngxService.stop();
          if (!result.errors) {
            if (result.length > 0) {
              this.postUpload = true;
              this.postDownload = true;
              this.showDelete = false;
              this.bulkUpload=false;
              this.resultcode=false;
              this.AssociatedResultCodes=false;
              //this.inData=null;
              this.commonService.createActivityTracker('Update', -1, 'Bulk Update OrderandResult codes', 'Audit', dataArray, {});
              //--start
              this.excelDataArray = dataArray;
              //this.excelDataArray = this.convertObjKeysToLower(result);
              for (let i = 0; i < result.length; i++) {
                this.excelDataArray[i]["notes"] = result[i]["NoteMessage"];
                if (result[i]["NoteMessage"].match(/already exists/)) {
                  this.excelDataArray[i]["status"] = "Ignored"
                }
                else {
                  this.excelDataArray[i]["status"] = result[i]["STATUS"]
                }
              }
              //--end

              this.excelDataArray = this.convertObjKeysToLower(dataArray);
              this.ngxService.start();
              this.bulkUpload = false;
              this.AddGridData(this.excelDataArray);
              this.ngxService.stop();
              // let dataExistsCount = 0;
              // for (let i = 0; i < result.length; i++) {
              //   if (Object.values(result).every(function (item: any) {
              //     return item.STATUS == "Ignored" ? true : false
              //   })) {
              //     dataExistsCount++;
              //   }
              // }

            }
            else {
              this._snackbar.open('Data Update failed! Please check the data for type mismatch.', 'Close');
            }
          }
        }, error => {
          this.ngxService.stop();
          this._snackbar.open('Something went wrong.Please try again', 'Close');
          console.error(error)
        })
      }
    }
  }
}
