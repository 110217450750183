import { Component, Input, OnInit, SimpleChanges, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { CommonService } from 'src/app/core/services/commonservices';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcCore from '@grapecity/wijmo';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { Validators } from '@angular/forms';
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';
import { DataShareService } from '../../../core/services/datashare.service';
import * as XLSX from 'xlsx';
import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjCore from '@grapecity/wijmo';
import { DialogService } from '../../../core/services/dialog.service';
import { Operations, CreateActivityTrackerInput } from '../../../model/activity-tracker';
import { ActivityTrackerService } from '../../../core/services/activity-tracker.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-manage-tab-sequence2',
  templateUrl: './manage-tab-sequence2.component.html',
  styleUrls: ['./manage-tab-sequence2.component.scss']
})
export class ManageTabSequence2Component {

    @Input() page;
    @Input() templateData;
    requisitionFields: any;
    patientInformationFields: any;
    HCmoreHide: boolean = false;
    insuranceHide: boolean = false;
    supplimentalInfo: boolean = false
    enableHorizontal: boolean = false;
    enableVertical: boolean = false;
    horizontalTabSequence: any = [];
    verticalTabSequence: any = [];
    json: any = [];
    caseInformationFields: any;
    destDeployment: string;
    organizationId: string;
    insuranceFields: void;
    primaryInsuranceFields: any;
    secondaryInsuranceFields: any;
    tertiaryInsuranceFields: any;
    guarantorFields: any;
    suplimentalInformation: any;
    patientInfo: boolean = false;
    patientInformation: any;
    buttonData: any;
    buttonInfo: boolean = false;
    @Input() tabindexFlag;
    task: any = { color: 'primary' };
    excelDataArray: any;
    showDelete: boolean;
    postUpload: any;
    selector: Selector;
    selectedData: any[];
    gridData: any;
    tabSequenceForm: any;
    uploadClicked: boolean;
    noData: boolean;
    showInvalidColumns: boolean;
    bulkUpload: boolean;
    gridShow: boolean;
    uploadBtn: boolean = false;
    noDataFound: boolean;
    gridwidth: number;
    allFields: any = [{}];
    SubMenuCardModel: SubMenuCardModel;
    //templateData: any;
    checkStatus: boolean;
    enableActive: boolean;
    sheetsToSelect: any[];
    workBook: any;
    @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
    invalidColumns: any;
    sheetHeader: any[];
    @Output() uploadFlags = new EventEmitter<any>();
    displaytype: any;
    fieldsupdateIndexValue: any[];
    fieldsToUpdateIndexValue: any[];
    notes: any;
  isDatachanged:boolean=false;
  newAuditObj:any;
  oldAuditDataauditobj:any=[];
  finalAuditObj: any=[];
  createActivityOperations = new Operations();
  createActivityInput = new CreateActivityTrackerInput();
  isNewDataCreated: boolean;
  @Input() rbacObject:any;

    constructor(private ngxService: NgxUiLoaderService, private datashare: DataShareService, private vitalHttpServices: VitalHttpServices,public datePipe: DatePipe,
      private commonService: CommonService, private _snackbar: MatSnackBar, private opendialog: DialogService,public activityService: ActivityTrackerService) {
      this.SubMenuCardModel = new SubMenuCardModel(
        commonService,
        vitalHttpServices,
        datashare
      );
    }

    async ngOnInit() {
      this.destDeployment = sessionStorage.getItem('deploymentKey').toUpperCase();
      this.organizationId = sessionStorage.getItem('org_id')
      await this.getFields();
      this.activityService.setActivitySession({'entityId': '','entityType': this.templateData.menuURL, 'context':[{'key':'parentMenu','value':this.templateData.menuURL}]})
    }

    ngOnChanges(changes: SimpleChanges) {
      //if(!this.enableHorizontal && !this.enableVertical){
        this.getFields();
      //}
    }
    isButtonEnable(tabindexflag,btnFlag){
      if(tabindexflag && btnFlag){
        return false
      }
      return true
    }
    async getFields() {
      this.ngxService.start();
      sessionStorage.setItem('deploymentKey', 'configdb');
      let query = this.vitalHttpServices.GetQuery('CategoryDropDown');
      let dbKeyword;
      if (this.page == 'Requisition') {
        dbKeyword = "requisionPage"
      } else if (this.page == 'SendOut') {
        dbKeyword = "SendOut"
      } else if (this.page == 'CP Requisition') {
        dbKeyword = "cpRequisition"
      } else if(this.page == 'Accession'){
        dbKeyword = "Accession"
      }
      let queryVariable = { context: 'TabIndex', Keyword: dbKeyword };
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      await this.vitalHttpServices.GetData(queryResult).subscribe((Resdata) => {
        sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
        if (!Resdata.errors) {
          let configuredJson;
          Resdata.data.submenuData.forEach(element => {
            if (element.Keyword == dbKeyword) {
              configuredJson = JSON.parse(element.Items_JSON);
              if (this.page == 'Requisition') {
                this.requisitionFields = configuredJson.Requisition;
              } else if (this.page == 'SendOut') {
                this.requisitionFields = configuredJson.SendOut;
              } else if (this.page == 'CP Requisition') {
                this.requisitionFields = configuredJson.CPRequisition;
              }
              else if( this.page == 'Accession'){
                this.requisitionFields = configuredJson.Accession;
              }
            }

          });
          //this.requisitionFields = configuredJson.Requisition;
          this.caseInformationFields = this.requisitionFields.CaseInformation.sort((a, b) => a.order - b.order)
          this.patientInformation = this.requisitionFields.PatientInformation.sort((a, b) => a.order - b.order)
          let primaryInsuranceData = this.requisitionFields.Insurance[0].primaryInsurance;
          let secondaryInsuranceData = this.requisitionFields.Insurance[0].secondaryInsurance;
          let tertiaryInsuranceData = this.requisitionFields.Insurance[0].tertiaryInsurance;
          let guarantorData = this.requisitionFields.Insurance[0].guarantor;
          this.suplimentalInformation = (this.requisitionFields.SupplementalInformation && this.requisitionFields.SupplementalInformation.length > 0) ? this.requisitionFields.SupplementalInformation.sort((a, b) => a.order - b.order) : []
          this.buttonData = (this.requisitionFields.Button && this.requisitionFields.Button.length > 0) ? this.requisitionFields.Button.sort((a, b) => a.order - b.order) : []
          this.primaryInsuranceFields = primaryInsuranceData.sort((a, b) => a.order - b.order);
          this.secondaryInsuranceFields = secondaryInsuranceData.sort((a, b) => a.order - b.order);
          this.tertiaryInsuranceFields = tertiaryInsuranceData.sort((a, b) => a.order - b.order);
          this.guarantorFields = guarantorData.sort((a, b) => a.order - b.order) // need to optimize

          // this.patientInformationFields = configuredJson.PatientInformation;
          this.getIndexValue();
          this.enableHorizontal=false;
          this.enableVertical=false;
          this.ngxService.stop();
        }
        else {
          this.ngxService.stop();
        }
      }, error => {
        console.error(error);
        this.ngxService.stop();
        sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
      });
    }
    async getIndexValue(afterSaved?: boolean) {
      let query = this.vitalHttpServices.GetQuery('getmanagetabsequencelevel');
      let queryVariable = {
        organizationid: sessionStorage.getItem('org_id').toString(),
        pagetype: this.page
      };
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      this.ngxService.start();
      try {
        const data = await this.vitalHttpServices.GetData(queryResult).toPromise();
        if (!data.errors) {
          let configuredData = data.data.submenuData;
          let fieldsToUpdateIndexValue = [
            ...this.caseInformationFields,
            ...this.tertiaryInsuranceFields,
            ...this.primaryInsuranceFields,
            ...this.secondaryInsuranceFields,
            ...this.guarantorFields,
            ...this.suplimentalInformation,
            ...this.buttonData,
            ...this.patientInformation
          ];

          fieldsToUpdateIndexValue.forEach(field => {
            const controlId = field.controlid;
            const matchedElement = configuredData.find(element => element.controlid === controlId);
            if (matchedElement) {
              field.id = matchedElement.id;
              field.indexValue = matchedElement.configuredtabindex;
            }
          });

          this.fieldsToUpdateIndexValue = fieldsToUpdateIndexValue.filter(va => va.controlid !== '');

          if (!afterSaved) {
            this.oldAuditDataauditobj = this.getAuditObj(configuredData, afterSaved);
          } else {
            this.finalAuditObj = this.getAuditObj(configuredData, afterSaved);
          }

          this.getDefaultConfiguration();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.ngxService.stop();
      }
    }




  getAuditObj(oldobj,aftersaved?) {
    let newobj = {};

    oldobj.forEach(item => {
        const { pagetype, sectionname, subsectionname, controllabelname, configuredtabindex } = item;

        if (!newobj[pagetype]) {
            newobj[pagetype] = {};
        }

        if (!newobj[pagetype][sectionname]) {
            newobj[pagetype][sectionname] = {};
        }

        if (subsectionname && !newobj[pagetype][sectionname][subsectionname]) {
            newobj[pagetype][sectionname][subsectionname] = {};
        }

        const targetObj = subsectionname ? newobj[pagetype][sectionname][subsectionname] : newobj[pagetype][sectionname];

        if (!targetObj[controllabelname]) {
            targetObj[controllabelname] = [];
        }

        if(aftersaved){
          targetObj[controllabelname].push({
            oldvalue: configuredtabindex?configuredtabindex:0,
            newvalue: configuredtabindex
        });
        }else{
          targetObj[controllabelname].push({
            oldvalue: configuredtabindex?configuredtabindex:0,
            newvalue: null
        });
        }

    });

    return newobj;
}


    toggle(data: string) {
      if(data === 'horiz'){
        this.displaytype='Horizontal'
        this.enableHorizontal=true
        this.enableVertical=false
        this.notes='Tab order sequence is set horizontally'
      }else if(data === 'vert'){
        this.displaytype='Vertical'
        this.enableHorizontal=false
        this.enableVertical=true
        this.notes = 'Tab order sequence is set vertically'

      }else{
        this.enableHorizontal=false
        this.enableVertical=false
      }
      let fieldsArrays = [
        this.caseInformationFields,
        this.primaryInsuranceFields,
        this.secondaryInsuranceFields,
        this.tertiaryInsuranceFields,
        this.guarantorFields,
        this.patientInformation,
        this.suplimentalInformation,
        this.buttonData
      ];
      this.isDatachanged=true

      let obj = {
        uploadbtn: false,
        isDataChanged:true,
        notes:this.notes
      }
      this.uploadFlags.emit(obj)
      let enableHorizontal = (data === 'horiz');
      //let fieldsArrays=this.fieldsupdateIndexValue;
      let sequenceMap = {
        'horiz': this.horizontalTabSequence,
        'vert': this.verticalTabSequence
      };


      for (let fields of fieldsArrays) {
        for (let item = 0; item < fields.length; item++) {
          let field = fields[item];
          let tabIndex = (enableHorizontal) ? field.horizontalvalue : field.verticalvalue;
          field.indexValue = tabIndex;

          let sequence = sequenceMap[data];
          sequence.forEach(element => {
            if (element.controlid === field.controlid && element.pagetype === this.page) {
              element.configuredtabindex = tabIndex;
            }
          });

          this.horizontalTabSequence=sequence;
        }
      }

      this.enableHorizontal = enableHorizontal;
      this.enableVertical = !enableHorizontal;
    }

    save1(value: any, event: any, type: any) {
      if (type === 'Horizontal') {
        let configuredtabindex = event.target.value === "0" ? null : event.target.value;
        configuredtabindex = configuredtabindex === '' ? 0 : configuredtabindex;

        let previousItem = this.horizontalTabSequence.filter(va=>va.controlid==value.controlid);

        this.horizontalTabSequence.forEach(v => {
          if (v.controlid.toString() === value.controlid.toString()) {
            if (previousItem && previousItem[0].controlid.toString() === v.controlid.toString()) {
              // Swap configuredtabindex values
              let temp = v.configuredtabindex;
              v.configuredtabindex = configuredtabindex;
              configuredtabindex = temp;
            } else {
              v.configuredtabindex = configuredtabindex;
            }
            previousItem = v;
          }
        });
      } else if (type === 'Vertical') {
        let configuredtabindex = event.target.value === "0" ? null : event.target.value;
        configuredtabindex = configuredtabindex === '' ? 0 : configuredtabindex;

        let previousItem = this.verticalTabSequence.filter(va=>va.controlid==value.controlid);

        this.verticalTabSequence.forEach(v => {
          if (v.controlid.toString() === value.controlid.toString()) {
            if (previousItem && previousItem[0].controlid.toString() === v.controlid.toString()) {
              // Swap configuredtabindex values
              let temp = v.configuredtabindex;
              v.configuredtabindex = previousItem[0].configuredtabindex;
              previousItem[0].configuredtabindex = temp;
            } else {
              v.configuredtabindex = configuredtabindex;
            }
            previousItem = v;
          }
        });
      } else {
        let onevalue = this.horizontalTabSequence.find(v => v.controlid.toString() === value.controlid.toString());

        if (onevalue) {
          let configuredtabindex = event.target.value === "0" ? null : event.target.value;
          configuredtabindex = configuredtabindex === '' ? null : configuredtabindex;

          onevalue.id = value.id;
          onevalue.configuredtabindex = configuredtabindex;

          this.json.push(onevalue);
        }
      }
    }



    save(value: any, event: any, type: any) {
      this.isDatachanged=true
      if (type === 'Horizontal') {
        // var onevalue;
        // onevalue = this.horizontalTabSequence.filter(v => v.configuredtabindex.toString() === event.target.value.toString());
        this.horizontalTabSequence.forEach(v => {
          if (v.controlid.toString() === value.controlid.toString()) {
            let configuredtabindex = event.target.value === "0" ? null : event.target.value;
            configuredtabindex = configuredtabindex === '' ? 0 : configuredtabindex;
            v.configuredtabindex = configuredtabindex;
          }
        });
      } else if (type === 'Vertical') {
        this.verticalTabSequence.forEach(v => {
          if (v.controlid.toString() === value.controlid.toString()) {
            let configuredtabindex = event.target.value === "0" ? null : event.target.value;
            configuredtabindex = configuredtabindex === '' ? null : configuredtabindex;
            //v.id = value.id;
            v.configuredtabindex = configuredtabindex;
          }
        });
      } else {
        var onevalue;
        onevalue = this.horizontalTabSequence.filter(v => v.controlid.toString() === value.controlid.toString());
        let configuredtabindex = event.target.value === "0" ? null : event.target.value;
        configuredtabindex = configuredtabindex === '' ? null : configuredtabindex;
        onevalue[0].id = value.id;
        onevalue[0].configuredtabindex = configuredtabindex;
        this.json.push(onevalue[0]);
      }
      let obj = {
        uploadbtn: false,
        isDataChanged:true,
        notes:this.notes
      }
      this.uploadFlags.emit(obj)
    }



    async updateTabIndexOrder() {
      let dbname = sessionStorage.getItem('deploymentKey').toUpperCase();
      let backupData = [];
      if (this.enableHorizontal && !this.enableVertical) {
        let tempfinaladata=[];
        this.horizontalTabSequence?.forEach(element => {
          this.fieldsToUpdateIndexValue?.forEach(element1 => {
           if( element.controlid==element1.controlid && element.pagetype==this.page){
            element.id=element1.id?element1.id:0;
            tempfinaladata.push(element)
           }
          });
        });
        for (var i = 0; i < tempfinaladata.length; i++) {
            var horizontal = {
            "organizationid": tempfinaladata[i].organizationid,
            "pagetype": tempfinaladata[i].pagetype,
            "controllabelname": tempfinaladata[i].controllabelname,
            "sectionname": tempfinaladata[i].sectionname,
            "subsectionname": tempfinaladata[i].sectionname?.toString().toLowerCase() == 'insurance' ? tempfinaladata[i].subsectionname : "NULL",
            "configuredtabindex": Number(tempfinaladata[i].configuredtabindex),
            "controlid": tempfinaladata[i].controlid,
            "id": tempfinaladata[i].id,
            "type": 'Add'
          }
          backupData.push(horizontal);
        };
      }
      else if (!this.enableHorizontal && this.enableVertical) {
        let tempfinaladata=[];
        this.verticalTabSequence.forEach(element => {
          this.fieldsToUpdateIndexValue?.forEach(element1 => {
           if( element.controlid==element1.controlid && element.pagetype==this.page){
            element.id=element1.id?element1.id:0;
            tempfinaladata.push(element)
           }
          });
        });
        for (var i = 0; i < tempfinaladata.length; i++) {
          var vertical = {
            "organizationid": tempfinaladata[i].organizationid,
            "pagetype": tempfinaladata[i].pagetype,
            "controllabelname": tempfinaladata[i].controllabelname,
            "sectionname": tempfinaladata[i].sectionname,
            "subsectionname": tempfinaladata[i].sectionname?.toString().toLowerCase() == 'insurance' ? tempfinaladata[i].subsectionname : "NULL",
            "configuredtabindex": Number(tempfinaladata[i].configuredtabindex),
            "controlid": tempfinaladata[i].controlid,
            "id": tempfinaladata[i].id,
            "type": 'Add'
          }
          backupData.push(vertical);
        };

      }
      else if (!this.enableHorizontal && !this.enableVertical) {
        let orgid = sessionStorage.getItem('org_id')
        for (var i = 0; i < this.json.length; i++) {
          var Default = {
            "organizationid": orgid,
            "pagetype": this.json[i].pagetype,
            "controllabelname": this.json[i].controllabelname,
            "sectionname": this.json[i].sectionname,
            "subsectionname": this.json[i].sectionname?.toString().toLowerCase() == 'insurance' ? this.json[i].subsectionname : "NULL",
            "configuredtabindex": Number(this.json[i].configuredtabindex),
            "controlid": this.json[i].controlid,
            "id": this.json[i].id,
            "type": 'Add'
          }
          backupData.push(Default);
        };
      }
       if(backupData.find(va=> !va['configuredtabindex'])){
        return this._snackbar.open('Invalid! Please enter a number greater than 0', 'Close');
       }
       else{
        this.ngxService.start();
        if (backupData.length > 0) {
          this.vitalHttpServices.updateTabIndex(backupData, dbname).subscribe(
            async result => {
              if (!result.errors) {
                this.ngxService.stop();
                backupData = [];
                this.json = [];
                // this.horizontalTabSequence = [];
                // this.verticalTabSequence = [];
                if (result[0].NoteMessage.toString().match(/Duplicate/i)) {
                  this._snackbar.open(result[0].NoteMessage, 'Close');
                  return;
                  }
                  if (result[0].status.toString().match(/failure/i)) {
                    this._snackbar.open(result[0].NoteMessage, 'Close');
                    return;
                  }
                  this._snackbar.open(result[0].NoteMessage, 'Close');
                  this.isDatachanged=false;
                  this.displaytype='default'
                  this.enableHorizontal=false;
                  this.enableVertical=false;
                  await this.getIndexValue(true)
                  let updated=this.updateValues(this.oldAuditDataauditobj,this.finalAuditObj,this.page)
                  let transactionid=this.commonService.generateGuid();
                  let tempType=this.isNewDataCreated?"Created":"Edit"
                  if(updated && updated[this.page] && Object.keys(updated[this.page]).length > 0){
                    await this.createActivityObject('',this.page,"Tab Focus",tempType,updated,transactionid)
                  }
                  await this.getIndexValue(false)
                let obj = {
                  uploadbtn: false,
                  isDataChanged:false,
                  notes:null
                }
                this.uploadFlags.emit(obj)
              }
            },
            error => {
              backupData = [];
              this.json = [];
              // this.horizontalTabSequence = [];
              // this.verticalTabSequence = [];
              this._snackbar.open('Error updating tab sequence', 'Close');
              this.ngxService.stop();
              console.error(error);
            }
          );
        }
        else {
          this.ngxService.stop();
        }
      }
    }

    updateValues(oldobj, newobj, page) {
      if(page !== 'CP Requisition'){
        if (oldobj && Object.keys(oldobj).length > 0) {
          this.isNewDataCreated=false
          for (const pagetype in newobj[page]) {
            if (oldobj[page].hasOwnProperty(pagetype) && newobj[page].hasOwnProperty(pagetype)) {
              for (const section in newobj[page][pagetype]) {
                if (oldobj[page][pagetype].hasOwnProperty(section) && newobj[page][pagetype].hasOwnProperty(section)) {
                  for (const field in newobj[page][pagetype][section]) {
                    if (oldobj[page][pagetype][section].hasOwnProperty(field) && newobj[page][pagetype][section].hasOwnProperty(field)) {
                      const oldFieldArray = oldobj[page][pagetype][section][field];
                      const newFieldArray = newobj[page][pagetype][section][field];

                      for (let i = 0; i < oldFieldArray.length; i++) {
                        if (oldFieldArray[i].newvalue !== newFieldArray[i].oldvalue) {
                          oldFieldArray[i].newvalue = newFieldArray[i].oldvalue;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          let tempobj = this.filterEqualValues(oldobj, page)
          return tempobj;
        } else {
          this.isNewDataCreated=true
          let tempobj = this.filterEqualValues(newobj, page,this.isNewDataCreated)
          return tempobj;
        }
      }else{
        if (oldobj && Object.keys(oldobj).length > 0) {
          this.isNewDataCreated = false
          for (const pagetype in newobj[page]) {
            if(pagetype =='Insurance'){
              if (oldobj[page].hasOwnProperty(pagetype) && newobj[page].hasOwnProperty(pagetype)) {
                for (const section in newobj[page][pagetype]) {
                  if (oldobj[page][pagetype].hasOwnProperty(section) && newobj[page][pagetype].hasOwnProperty(section)) {
                    for (const field in newobj[page][pagetype][section]) {
                      if (oldobj[page][pagetype][section].hasOwnProperty(field) && newobj[page][pagetype][section].hasOwnProperty(field)) {
                        if (oldobj[page][pagetype][section].length > 1) {
                          for (let j = 0; j < oldobj[page][pagetype][section].length; j++) {
                            for (let k = 0; k < newobj[page][pagetype][section].length; k++) {

                              const oldFieldArray = oldobj[page][pagetype][section];
                              const newFieldArray = newobj[page][pagetype][section];

                              for (let i = 0; i < oldFieldArray.length; i++) {
                                if (oldFieldArray[i].newvalue !== newFieldArray[i].oldvalue) {
                                  oldFieldArray[i].newvalue = newFieldArray[i].oldvalue;
                                }
                              }

                            }

                          }

                        }
                      }
                    }
                  }
                }
              }
            }else{
              if (oldobj[page].hasOwnProperty(pagetype) && newobj[page].hasOwnProperty(pagetype)) {
                for (const section in newobj[page][pagetype]) {
                  if (oldobj[page][pagetype].hasOwnProperty(section) && newobj[page][pagetype].hasOwnProperty(section)) {
                    for (const field in newobj[page][pagetype][section]) {
                      if (oldobj[page][pagetype][section].hasOwnProperty(field) && newobj[page][pagetype][section].hasOwnProperty(field)) {
                        const oldFieldArray = oldobj[page][pagetype][section][field];
                        const newFieldArray = newobj[page][pagetype][section][field];

                        for (let i = 0; i < oldFieldArray.length; i++) {
                          if (oldFieldArray[i].newvalue !== newFieldArray[i].oldvalue) {
                            oldFieldArray[i].newvalue = newFieldArray[i].oldvalue;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          let tempobj = this.filterEqualValues(oldobj, page)
          return tempobj;
        } else {
          this.isNewDataCreated = true
          let tempobj = this.filterEqualValues(newobj, page, this.isNewDataCreated)
          return tempobj;
        }
      }
    }



  createActivityObject(entityid, changedValue, entityType, actionType, obj, transactionId, uploadedFile?: any) {
    try {
      let temptype=actionType=='Edit'?"Modified":"Created"
      let internalUserRegex = /^\w+([\.-]?\w+)@starmarkit|@vitalaxis(\.\w{2,3})+$/;
      let date = new Date();
      let entityTypeContext: any
      let deployid = sessionStorage.getItem('DeploymentKey')
      this.activityService.getActivitySession.subscribe(activityTab => entityTypeContext = activityTab);

      this.createActivityInput.productId = this.vitalHttpServices.GetDxurlBasedOnDeployment(deployid).replace(/(^\w+:|^)\/\//, '');
      this.createActivityInput.product = 'VitalDx';
      this.createActivityInput.customerId = sessionStorage.getItem('org_id');
      this.createActivityInput.customerName = sessionStorage.getItem('Org_Name');
      this.createActivityInput.entityType = entityType;
      this.createActivityInput.actionType = 'audit';
      this.createActivityInput.userName = localStorage.getItem('user_name');
      this.createActivityInput.userId = localStorage.getItem('UserEmail');
      this.createActivityInput.entityId = entityid;
      this.createActivityInput.locationId = sessionStorage.getItem('Locationid');
      this.createActivityInput.locationName = sessionStorage.getItem('locationName');
      this.createActivityInput.isInternal = internalUserRegex.test(localStorage.getItem('UserEmail').toLowerCase());
      this.createActivityInput.transactionId = transactionId;
      this.createActivityInput.ticketId = 'Tic' + this.datePipe.transform(date, 'dd-MM-yyyyHH:mm a');
      this.createActivityInput.ticketInfo = actionType + " " + entityType;
      this.createActivityOperations.extendedAttributes = obj;
      this.createActivityOperations.attachments = (uploadedFile == null ? null : [uploadedFile]);
      this.createActivityInput.operation = this.createActivityOperations;
      this.createActivityInput.context = [{'key':'parentMenu','value':"Tab Focus"}];
      this.createActivityOperations.info = temptype+" "+changedValue+ " Tab Focus";
      if (Object.keys(this.createActivityOperations.extendedAttributes).length != 0 || uploadedFile != null) {
        this.vitalHttpServices.CreateActivity(this.createActivityInput)
          .subscribe((res) => {
            console.log(res)
           });
      }
    } catch (error) {
      console.error("Error occured while creating activity");
    }
  }

 filterEqualValues(obj, page,isnewDataCreated?) {
    const filteredObj = {};

    if(page === 'CP Requisition'){
      if (obj[page]) {
        filteredObj[page] = [];

        for (const pagetype in obj[page]) {
          const pagetypeArray = [];

          if (pagetype != "Insurance") {
            for (const sectionKey in obj[page][pagetype]) {
              let sectionObj = {};

              for (const field in obj[page][pagetype][sectionKey]) {
                let fieldArray = {};

                fieldArray = obj[page][pagetype][sectionKey][field];
                // Check if fieldArray is an array before mapping over it
                if (Array.isArray(fieldArray)) {
                  const updatedValues = fieldArray.map(item => {
                    if (isnewDataCreated) {
                      return {
                        oldValue: "",
                        newValue: item.newvalue ? item.newvalue.toString() : "",
                      };
                    } else {
                      if (item.oldvalue && item.newvalue && item.oldvalue !== item.newvalue) {
                        return {
                          oldValue: item.oldvalue?item.oldvalue.toString():"",
                          newValue: item.newvalue ? item.newvalue.toString() : "",
                        };
                      }
                    }

                    return null;
                  }).filter(Boolean);

                  if (updatedValues.length > 0) {
                    sectionObj[field] = updatedValues[0];
                  }
                }


              }

              if (Object.keys(sectionObj).length > 0) {
                pagetypeArray.push(sectionObj);
              }
            }

          } else {
            for (const sectionKey in obj[page][pagetype]) {
              let sectionObj = {};

              for (const field in obj[page][pagetype][sectionKey]) {
                let fieldArray = {};
                fieldArray = obj[page][pagetype][sectionKey];
                if (Array.isArray(fieldArray)) {
                  fieldArray.forEach((item, index) => {
                    const key = `${sectionKey}(${index + 1})`;
                    if (isnewDataCreated) {
                      sectionObj[key] = {
                        oldValue: "",
                        newValue: item.newvalue ? item.newvalue.toString() : "",
                      };
                    } else {
                      if (item.oldvalue !== null && item.newvalue !== null && item.oldvalue !== item.newvalue) {
                        sectionObj[key] = {
                          oldValue: item.oldvalue ? item.oldvalue.toString() : "",
                          newValue: item.newvalue ? item.newvalue.toString() : "",
                        };
                      }
                    }
                  });
                }


              }

              if (Object.keys(sectionObj).length > 0) {
                pagetypeArray.push(sectionObj);
              }
            }
          }


          if (pagetypeArray.length > 0) {
            filteredObj[page].push({
              [pagetype]: pagetypeArray,
            });
          }
        }
      }
    }else{
      if (obj[page]) {
        filteredObj[page] = [];

        for (const pagetype in obj[page]) {
          const pagetypeArray = [];

          for (const sectionKey in obj[page][pagetype]) {
            let sectionObj = {};

            for (const field in obj[page][pagetype][sectionKey]) {
              let fieldArray = {};

              fieldArray = obj[page][pagetype][sectionKey][field];
              // Check if fieldArray is an array before mapping over it
              if (Array.isArray(fieldArray)) {
                const updatedValues = fieldArray.map(item => {
                  if (isnewDataCreated) {
                    return {
                      oldValue: "",
                      newValue: item.newvalue ? item.newvalue.toString() : "",
                    };
                  } else {
                    if (item.oldvalue && item.newvalue && item.oldvalue !== item.newvalue) {
                      return {
                        oldValue: item.oldvalue?item.oldvalue.toString():"",
                        newValue: item.newvalue ? item.newvalue.toString() : "",
                      };
                    }
                  }

                  return null;
                }).filter(Boolean);

                if (updatedValues.length > 0) {
                  sectionObj[field] = updatedValues[0];
                }
              }
            }

            if (Object.keys(sectionObj).length > 0) {
              pagetypeArray.push(sectionObj);
            }
          }

          if (pagetypeArray.length > 0) {
            filteredObj[page].push({
              [pagetype]: pagetypeArray,
            });
          }
        }
      }
    }

    return this.rearrangeData(filteredObj,page);
  }

  rearrangeData(data, page) {
    let keyOrder ;
  
    if(page != 'CP Requisition'){
           keyOrder = ["Case Information", "Patient Information", "Supplemental Information", "Insurance", "Button"];
    }else{
          keyOrder = ["Group 1", "Group 2", "Group 3", "Group 4", "null","Insurance"];
    }
    const keyMap = {
      "CaseInformation": "Case Information",
      "Patient Information": "Patient Information",
      "Supplemental Information": "Supplemental Information",
      "Insurance": "Insurance",
      "Button": "Button",
      "Group 1":"Group 1",
      "Group 2":"Group 2",
      "Group 3":"Group 3",
      "Group 4":"Group 4",
      "null":"null"
    };
    const rearrangedData = {};
    rearrangedData[page] = [];
    keyOrder.forEach((key) => {
      const originalKey = Object.keys(keyMap).find(original => keyMap[original] === key);
  
      if (originalKey) {
        const matchingObject = data[page].find(obj => obj[originalKey]);
        if (matchingObject) {
          rearrangedData[page].push({ [keyMap[originalKey]]: matchingObject[originalKey] });
        }
      }
    });
    return rearrangedData;
  }




    AllowOnlyNumber(event: KeyboardEvent) {
      const pattern = /[0-9]/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        event.preventDefault();
      }
    }

    getDefaultConfiguration() {
      let query = '';
      let queryVariable = {};
      query = this.vitalHttpServices.GetQuery('getmanagetabsequencelevel');
      queryVariable = {
        organizationid: "-1",
        pagetype: this.page
      };
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      this.ngxService.start();
      this.vitalHttpServices.GetData(queryResult).subscribe(
        data => {
          if (!data.errors) {
            this.verticalTabSequence = data.data.submenuData;
            this.horizontalTabSequence = data.data.submenuData;
            let setOrganizationId = (element: any) => {
              element.organizationid = this.organizationId;
            };
            this.verticalTabSequence.forEach(setOrganizationId);
            this.horizontalTabSequence.forEach(setOrganizationId);
          }
          this.ngxService.stop();
        },
        error => {
          console.error(error);
          this.ngxService.stop();
        }
      );
    }

    //Upload
    // back button
    backSelect() {
      this.uploadClicked = false;
      this.noData = false;
      this.excelDataArray = [];
      this.showInvalidColumns = false;
      this.postUpload = false;
      this.bulkUpload = false
      this.gridShow = false;
      let obj = {
        uploadbtn: false,
        isDataChanged:false,
        notes:null
      }
      this.uploadFlags.emit(obj)
      this.getFields();
      this.resetTabIndex()
      //this.resetFormErrors();
    }

    resetTabIndex(isYesCliked?) {

      if(!this.isDatachanged || isYesCliked){
        let obj = {
          uploadbtn: false,
          isDataChanged:false,
          notes:null
        }
        this.uploadFlags.emit(obj)
        this.getFields();
      }else{
        const popUpHeader = "Confirmation";
        const popUpMessage = `There are changes made on this page.\nIf you exit this page you will lose your changes!\nDo you want to continue?`;
        const continueBtn = "Yes";
        const dialogRef = this.opendialog.openDialog(popUpHeader, popUpMessage, '', continueBtn, "No");
        dialogRef.afterClosed().subscribe(res => {
          if (res) {
            this.resetTabIndex(true)
            this.isDatachanged = false;
          }
        });
      }
    }

    //form reset method
    resetFormErrors() {
      Object.keys(this.tabSequenceForm.controls).forEach(key => {
        this.tabSequenceForm.controls[key].setErrors(null)
      });
    }

    //Upload screen
    loadUploadScreen() {
      if (!this.uploadBtn) {
        this.noDataFound = false;
        this.uploadClicked = true;
        this.gridShow = false;
        this.bulkUpload = true;
        this.gridwidth = 0
        this.isDatachanged=false;
        let obj = {
          uploadbtn: this.uploadClicked,
          isDataChanged:false,
          notes:this.notes
        }
        this.uploadFlags.emit(obj)
        let queryVariable = { tablename: 'OrganizationTabIndex', identity: "false", nullable: "false" };
        let query = this.SubMenuCardModel.GetQuery("getTableColumns");
        let queryResult = this.commonService.GetCardRequest(queryVariable, query);
        this.vitalHttpServices.GetData(queryResult).subscribe(data => {
          if (!data.errors) {
            if (data) {
              let allFields = {}
              for (let i = 0; i < data.data.allFields.length; i++) {
                allFields[data.data.allFields[i]['Column']] = ''
              }
              this.allFields[0] = allFields
              delete this.allFields[0]["ID"];
              delete this.allFields[0]["OrganizationID"];
            }
          }
        }, error => {
          console.error(error);
        });
      }
    }
    sampleDataAllFields = [{
      PageType: 'Accession',
      ControlLabelName: 'First name',
      SectionName: 'Insurance',
      SubSectionName: 'primary Insurance',
      ConfiguredTabIndex: '1',
      ControleId: 'txtControlId'
    }]
    //Upload templates
    downloadTemplate(type) {
      let filename = ""
      let excel = [{}];
      if (type == 'allFieldsCheck') {
        filename = 'Tab Focus_' + 'All Fields_' + '.xlsx';
        excel = this.allFields
        var ws = XLSX.utils.json_to_sheet(excel);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "" + 'OrganizationTabOrderIndex');
        ws = XLSX.utils.json_to_sheet(this.sampleDataAllFields)
        XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
        XLSX.writeFile(wb, filename);
      }
    }




    // Grid
    initGrid(grid, hitCount?) {
      if (hitCount) {
        if (!this.postUpload) {
          this.selector = new Selector(grid, {
            itemChecked: () => {
              this.selectedData = grid.rows.filter(r => r.isSelected);
            }
          });
        }
      }
      const tt = new wjcCore.Tooltip();
      grid.formatItem.addHandler((s, e) => {
        if (e.panel.cellType !== wjcGrid.CellType.Cell) {
          return;
        }
        const col = e.getColumn(),
          row = e.getRow();
        tt.hideDelay = 9999999;
        if (s.getCellData(e.row, e.col) != null) {
          tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
        }
      });
    }

    // grid remove button
    gridRemove() {
      this.checkStatus = false;
      this.postUpload = false;
      this.showInvalidColumns = false;
      this.noData = false;
      this.noDataFound = false;
      this.enableActive = true;
      this.gridwidth = 0
      this.tabSequenceForm.patchValue({
        frmOrganization: ''
      })
      this.gridData = new CollectionView([]);
      // this.approveDisable();
    }

    //message validation color
    formateItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
      if (e.panel == flexGird.cells) {
        var value = e.panel.getCellData(e.row, e.col, false);
        if (value) {
          if (value.toString().includes('Mandatory')) {
            wjCore.toggleClass(e.cell, 'error-msg ', true);
          }
          else if (value.toString().includes('Valid') || value.toString().includes('Successfully') || value.toString().includes('success') || value.toString().includes('approval')) {
            wjCore.toggleClass(e.cell, 'high-value', true);
          }
          else if (value.toString().includes('exist') || (value.toString().includes('Ignored'))) {
            wjCore.toggleClass(e.cell, 'warn-value', true);
          }
        }
      }
    }

    ExportExcel(gridData) {
      let excel = [];
      let flex = gridData;
      const view: any = flex.collectionView;
      let oldPgSize = view.pageSize
      view.pageSize = 0;
      flex.beginUpdate();
      let rows = flex.rows;
      flex.rows.find((e: any): any => {
        let primary = {}
        delete e._data['Action'];
        delete e._data['casetype'];
        delete e._data['type'];
        delete e._data['tablename'];
        delete e._data['organizationid'];
        delete e._data['copyflag'];
        delete e._data['slno'];
        for (let [key, value] of Object.entries(e._data)) {
          primary[key] = value;
        }
        excel.push(primary);
      });
      let filename = 'Tab Focus_' + sessionStorage.getItem('org_id').toString() + '.xlsx';
      var ws = XLSX.utils.json_to_sheet(excel);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, '' + 'Tab Order Index');
      XLSX.writeFile(wb, filename);
      view.pageSize = oldPgSize;
      flex.endUpdate();
    }

    //approve button
    approveTabIndex(copyData?) {
      this.postUpload = false;
      if (this.excelDataArray && this.excelDataArray.length > 0) {
        let errorcount = 0;
        for (let i = 0; i < this.excelDataArray.length; i++) {
          if (this.excelDataArray[i]["notes"] == "") {
            this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
            return;
          }
          else if (!this.excelDataArray[i]["notes"].toString().match(/approval/i) && (this.excelDataArray[i]["notes"].toString().match(/already exists/i) || this.excelDataArray[i]["notes"].toString().match(/missing/i))) {
            errorcount++
          }
        }
        if ((this.showInvalidColumns)) {
          this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
          return
        }
        else {
          for (let i = 0; i < this.excelDataArray.length; i++) {
            for (let [key, value] of Object.entries(this.excelDataArray[i])) {
              if (value === "null" || value === "" || value === null) {
                this.excelDataArray[i][key] = null;
              }
              else {
                this.excelDataArray[i][key] = value.toString();
              }
            }
          }
        }

        if (this.excelDataArray.some(va => !va.controlid || !va.controllabelname || !va.pagetype || !va.sectionname)) {
          this._snackbar.open('Some of the mandatory data is missing!', 'Close');
          return
        }
        let dataArray = [];
        dataArray = this.convertObjKeysToLower(this.excelDataArray);
        // copyData ? dataArray.some(va => va.notes.toString().toLowerCase().match(/already exists/i)) ? this._snackbar.open("Some selected " + this.templateDatafordefault.menuURL.toString().toLowerCase() + "s already exist", "Close") : null : null
        let sendjson = dataArray
        let context = copyData ? null : ''
        this.addUploadTabIndex(dataArray, sendjson, this.bulkUpload, context)
      }
    }
    //method for bulk upload
    addUploadTabIndex(dataArray: object[], sendjson, bulkUpload: boolean, context?) {
      let dbname = sessionStorage.getItem('deploymentKey').toUpperCase();
      this.ngxService.start();
      this.vitalHttpServices.updateTabIndex(sendjson, dbname).subscribe(result => {
        this.noDataFound = false;
        this.ngxService.stop();
        if (!result.errors) {
          //this.auditDetails([], sendjson, 'saved');
          this.commonService.auditDetails('Tab Focus','Tab Focus',null,sendjson, 'Upload', this.templateData, this.allFields);
          this.noData = false;
          dataArray.filter((e: any) => {
            result.filter(r => {
              if (r.slno.toString() === e.slno.toString()) {
                e.status = r.status
                e.notes = r.NoteMessage;
              }
            });
          });
          dataArray.forEach((va: any) => {
            if (va.notes.toString().toLowerCase().match(/already exists/i)) {
              va.status = 'Ignored'
            }
          })
          if (result.length > 0) {
            this.postUpload = true;
            let uploadresult = this.convertObjKeysToLower(dataArray);
            let dataExistsCount = 0;
            for (let i = 0; i < result.length; i++) {
              if (Object.values(result).every(function (item: any) {
                return item.status == "Ignored" ? true : false
              })) {
                dataExistsCount++;
              }
            }
            this.AddGridData(uploadresult, 1);
          }
          else {
            bulkUpload ? this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close') : this._snackbar.open('Tab Order Index creation failed Please try again.', 'Close');
          }
        }
      }, error => {
        this.ngxService.stop();
        this._snackbar.open('Something went wrong.Please try again', 'Close');
        console.error(error)
      })
    }
    //delete row in the grid in upload
    deleteRow(grid, row) {
      grid.collectionView.remove(row.dataItem);
      this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
      this.selectedData = this.excelDataArray
      this._snackbar.open('Deleted successfully', 'Close');
      if (grid.rows.length == 0) {
        this.gridRemove();
      }
    }
    //flag for copy as inactive
    checkValue(event) {
      if (event.checked) {
        this.checkStatus = true;
      } else {
        this.checkStatus = false;
      }
    }

    //Upload file
    onFileChange(ev) {
      this.workBook = {};
      this.sheetsToSelect = [];
      const reader = new FileReader();
      let file = ev.target ? ev.target.files[0] : ev[0];
      let filename = file.name;
      let splitarry = filename.split('.');
      if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
        this._snackbar.open('Please upload an excel file only.', 'Close');
      }
      else {
        reader.onload = (event) => {
          const data = reader.result;
          this.workBook = XLSX.read(data, { type: 'binary' });
          this.sheetsToSelect = this.workBook.SheetNames;
          if (this.sheetsToSelect) {
            if (this.sheetsToSelect.length > 1) {
              let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == 'organizationtabindex')
              validSheet.length > 0 ? this.convertToJson(validSheet[0]) : this.openModal();

            }
            else {
              this.convertToJson(this.sheetsToSelect[0])
            }
          }
        };
        reader.readAsBinaryString(file);
      }
      this.fileDropRef.nativeElement.value = "";
    }
    // validation in grid
    validateExcel(excelArr) {
      this.ngxService.start();
      let dataArray = [];
      let dbname = sessionStorage.getItem('deploymentKey').toUpperCase();
      dataArray = this.convertObjKeysToLower(excelArr);
      for (let k = 0; k < dataArray.length; k++) {
        if (!dataArray[k].controlid && !dataArray[k].controllabelname && !dataArray[k].sectionname && !dataArray[k].pagetype) {
          dataArray[k].notes = 'Mandatory field(s) is missing'
        }
      }
      this.vitalHttpServices.validateTabIndex(dataArray, dbname).subscribe(result => {
        if (!result.errors) {
          this.noData = false
          if (result.length > 0) {
            if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
              this.showInvalidColumns = true;
              this.invalidColumns = result[0].InvalidColumns;
            }
            dataArray.filter(e => {
              result.filter(r => {
                if (r.slno === e.slno) {
                  e.notes = r.NoteMessage;
                }
              });
            });
            this.AddGridData(dataArray, 1);
            this.ngxService.stop();
          }
          else {
            this._snackbar.open("Please provide valid data", 'Failed');
          }
        }
        this.noData = true;
      }, error => {
        this.ngxService.stop();
        this._snackbar.open("An error occurred while processing your request", 'Failed');
        console.log(error);
      });
    }
    //Append validation notes in the grid
    convertToJson(sheetname) {
      var worksheet;
      this.workBook.SheetNames.find(e => {
        if (e.toLowerCase() == sheetname.toLowerCase()) {
          worksheet = this.workBook.Sheets[e];
        }
      });
      this.excelDataArray = [];
      let tempExcelArr = [];
      tempExcelArr = XLSX.utils.sheet_to_json(worksheet, { defval: null });
      if (tempExcelArr.length == 0) {
        this._snackbar.open("The uploaded excel does not contain any data", "Close");
        tempExcelArr = [];
        return;
      }
      let primary = {}
      let tempArray = []
      for (let i = 0; i < tempExcelArr.length; i++) {
        for (let [key, value] of Object.entries(tempExcelArr[i])) {
          if (!key.toString().match(/empty/i)) {
            if (key.toString().match(/sequence/i)) {
              if (value && value.toString() == '0') {
                value = 0;
              }
              else {
                value = value;
              }
            }
            else {
              if (value == null) {
                value = null;
              }
              else if (value === '') {
                value = ''
              }
              else if (value.toString().match(/null/i)) {
                value = 'null'
              }
            }
            primary[key] = value;
          }
        }
        tempArray.push(primary)
        primary = {}
      }
      tempExcelArr = tempArray;
      this.sheetHeader = [];
      let temp = [];
      temp = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      temp[0].reverse()
      temp[0].push("Notes")
      this.sheetHeader = temp[0];
      for (let i = 0; i < this.sheetHeader.length; i++) {
        if (this.sheetHeader[i]) {
          this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
        }
      }
      this.excelDataArray = []
      this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
      let i = 1;
      this.excelDataArray.find(d => {
        Object.assign(d, { slno: i, notes: '', tablename: 'OrganizationTabIndex', organizationid: sessionStorage.getItem('org_id').toString(), type: 'Upload' });
        i++;
      });
      this.validateExcel(this.excelDataArray);
    }
    convertObjKeysToLower(inputArr) {
      let array = [];
      for (let i = 0; i < inputArr.length; i++) {
        var key, keys = Object.keys(inputArr[i]);
        var n = keys.length;
        var newobj = {}
        while (n--) {
          key = keys[n];
          let value = ""
          value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
          newobj[key.replace(/\s/g, "").toLowerCase()] = value
        }
        array.push(newobj)
      }
      return array;
    }
    // Close Modal

    // Open Modal
    async openModal() {
      let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
      //let result=this.commonService.selectdSheet;
      if (result) {
        this.convertToJson(result)
      }
    }
    //add grid in upload and copy screen
    AddGridData(data, hitCount) {
      this.ngxService.start()
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if (!this.excelDataArray[i]["status"] || this.excelDataArray[i]["status"] == '') {
          this.excelDataArray[i]["status"] = 'Ignored'
        }
      }
      this.noDataFound = false
      let gridArray = [];
      this.gridData = new CollectionView([]);
      let primary = {}
      if (data) {
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            primary = {};
            for (let [key, value] of Object.entries(data[i])) {
              if (!key.toString().toLowerCase().match(/empty/i) && hitCount == 1) {
                if (key == 'sequence') {
                  if (value == 0) {
                    if (primary['notes'] != 'Mandatory field is missing!' && primary['notes'] != 'Null values exist!') {
                      primary['notes'] = 'Zero is not allowed for Sequence';
                    }
                  }
                  else {
                    let pattern = /[0-9]+/
                    if (primary['notes'] != 'Mandatory field is missing!' && primary['notes'] != 'Null values exist!') {
                      primary['notes'] = value && !pattern.test(value.toString()) ? 'Sequence only takes numbers' : primary['notes'];
                    }
                  }
                }
              }
              primary[key] = value;
            }
            gridArray.push(primary);
          }
          this.excelDataArray = gridArray;
          this.showDelete = true
          if (this.postUpload) {
            this.showDelete = false
            this.selector ? this.selector.column.grid.headersVisibility = HeadersVisibility.Column : null
          }
          this.selectedData = gridArray;
          this.gridData = new CollectionView(gridArray);
          this.gridwidth = (170 * this.sheetHeader.length) + 37;
          if (this.gridwidth > 1300) {
            this.gridwidth = 1300;
          }
        }
        this.ngxService.stop()
      }
    }

    //disbaled approved button
    disableApprovebtn() {
      let existsCount = 0;
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if (!this.excelDataArray[i]['notes'] && (this.excelDataArray[i]['notes'].toString().match(/Mandatory field is missing/i)) || !this.excelDataArray[i]['notes'].toString().match(/valid/i)) {
          existsCount++;
        }
      }
      if (existsCount == this.excelDataArray.length) {
        return true
      }
      return false;
    }





  }


