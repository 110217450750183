<div *ngIf="!isEdit && gridPage && !sourceApp && !isView" class="row">
    <div class="col-md-12 p-0">
        <div class="modal-header admin-model-header mt-2 listingModalHeader p-0">
            <h3 class="modal-title w-100 admin-model-header-txt p-0">
                <strong class="header-View">{{stainHeading}}</strong>
            </h3>
        </div>
    </div>
</div>

<div class="px-4" *ngIf="sourceApp && !(gridPage && (filteredStainData$|async)?.['data'])">
    <app-shimmerui [line]=8></app-shimmerui>
</div>

<section class="ang-mat-section">
    <app-spinner-loader *ngIf="sourceApp == 'VitalDx' && showLoader "></app-spinner-loader>
    <section *ngIf="!isEdit && !isView && gridPage">
        <div *ngIf="filteredStainData$|async as stainData ">
            <div *ngIf="!sequenceEditMode" class="d-flex align-items-center mt-3 mx-2 mb-2 filters">
                <span class="not-selectable" tabindex="0"
                    [ngClass]="[filtercondition.value.status=='all' ? 'filterselected' : '',stainData.isNoDataPresent?'disabled':'']"
                    (click)="!stainData.isNoDataPresent?toggleButton({status:'all'},searchText):null">All
                    ({{stainData.totalCount}})</span>
                <span id="seperator"></span>
                <div class="filters d-flex not-selectable flex-wrap">
                    <span class="not-selectable" tabindex="0"
                        [ngClass]="[filtercondition.value.status=='active'? 'filterselected' : '',stainData.isNoDataPresent?'disabled':'']"
                        (click)="!stainData.isNoDataPresent?toggleButton({status:'active'},searchText):null">
                        Active ({{stainData.activeCount}})
                    </span>
                    <span class="not-selectable" tabindex="0"
                        [ngClass]="[filtercondition.value.status=='inactive'? 'filterselected' : '',stainData.isNoDataPresent?'disabled':'']"
                        (click)="!stainData.isNoDataPresent?toggleButton({status:'inactive'},searchText):null">
                        Inactive ({{stainData.totalCount-stainData.activeCount}})
                    </span>

                </div>
                <span id="seperator" *ngIf="sourceApp"></span>
                <div *ngIf="sourceApp" class="col-sm-3  filterDropdowns d-flex">
                    <app-auto-complete-select placeholder="Locations" [defaultValue]="accountId"
                        [dropDownValue]="labList" key="accountName" value="accountId"
                        (onClearValue)="clearSearch('Locations')"
                        (onSelection)="getCaseTypes($event.selectedValue)"></app-auto-complete-select>
                    <app-auto-complete-select placeholder="Case Types" class="ml-2" [defaultValue]="caseTypeDisplayName"
                        [dropDownValue]="caseTypeList"  [isDisabled]="!accountId" key="displayname" value="displayname" (onClearValue)="clearSearch('Case Types')"
                        (onSelection)="setCaseType($event)"></app-auto-complete-select>
                </div>
                <app-search-box placeHolder="Search Tests/Stains" [searchText]="searchText"
                    (enteredValue)="toggleButton({searchText:$event})" class="search-box-width ml-auto"
                    [isDisabled]="stainData.isNoDataPresent"></app-search-box>
                <app-button *ngIf="!CopyToOrg" matTooltip="Copy" [matTooltipDisabled]="CopyToOrg"
                    (click)="loadCopyScreen()" image="icons/Global_Icon.svg" class="ml-3"
                    [isDisabled]="stainData.isNoDataPresent || CopyToOrg"></app-button>
                <app-button *ngIf="!editBtn" matTooltip="Reorder"
                    [matTooltipDisabled]="editBtn || isFilterEnabledChanged()" image="icons/Reorder_Blue.svg"
                    class="ml-3" (click)="enableSortEdit()"
                    [isDisabled]="stainData.isNoDataPresent || editBtn || isFilterEnabledChanged() || !stainData.data.length || (sourceApp && !caseTypeSelectionCheck())"></app-button>
                <app-button buttonHoverText=":Bulk Upload" [hideRightBtn]="uploadBtn" [hideLeftBtn]="createBtn"
                    [isDisabled]="createBtn || (sourceApp && !caseTypeSelectionCheck())"
                    [isRightButtonDisabled]="uploadBtn" multiple="true" image=":icons/Excel-Bulk Upload_white.svg"
                    buttonText="Create" class="ml-3" (leftBtnClick)="openAddProcedure('Panel',null)"
                    (rightBtnClick)="loadUploadScreen()"></app-button>
            </div>
            <div *ngIf="sequenceEditMode" class="mt-3 mb-2 d-flex">
                <app-button buttonclass="secondary" class="ml-auto edit-page-button"
                    (click)="returnToList()">Return</app-button>
                <app-button buttonclass="primary" class="edit-page-button ml-2" [isDisabled]="!isSortDataChanged"
                    (click)="saveSequence()">Save</app-button>
            </div>
            <div class="content-slab">
                <span>Tests/Stains</span>
                <img *ngIf="sequenceEditMode && groupLevelSort === 'DESC'&&!stainData.isNoDataPresent" matTooltip="Sort"
                    matTooltipPosition="right" class="ml-2 cursor" height="17px"
                    (click)="sequenceTracking('group','ASC', stainData.data)" src="/assets/icons/Reorder_Descending.svg"
                    alt="Descending">

                <img *ngIf="sequenceEditMode && groupLevelSort === 'ASC'&&!stainData.isNoDataPresent" matTooltip="Sort"
                    matTooltipPosition="right" class="ml-2 cursor" height="17px"
                    (click)="sequenceTracking('group','DESC', stainData.data)" src="/assets/icons/Reorder_Asceding.svg"
                    alt="Ascending">

                <img *ngIf="sequenceEditMode && groupLevelSort === 'UNS'&&!stainData.isNoDataPresent" matTooltip="Sort"
                    matTooltipPosition="right" class="ml-2 cursor" height="17px"
                    (click)="sequenceTracking('group','ASC', stainData.data)" src="/assets/icons/Mix - Sort.svg"
                    alt="Un-Ordered">

                <img *ngIf="areAllCollapsed(stainData.data) " src="../../../../assets/icons/tableExpandAll.svg"
                    [attr.disabled]="" (click)="expandAll()" height="17px" class="ml-2 cursor" matTooltip="Expand All"
                    alt="Expand" />

                <img *ngIf="!areAllCollapsed(stainData.data) " src="../../../../assets/icons/tableCollapse.svg"
                    [attr.disabled]="" (click)="collapseAll(stainData.data)" height="17px" class="ml-2 cursor"
                    matTooltip='Collapse All' alt="Collapse" />


                <span *ngIf="!sequenceEditMode" class="right-elements cursor"
                    [class.disabled]="stainData.isNoDataPresent || stainData.data.length === 0"
                    (click)="!stainData.isNoDataPresent && (stainData.data && stainData.data.length ?openPreview():null)">Preview</span>
                <img *ngIf="!sequenceEditMode&&!exportBtn"
                    [class.disabled]="stainData.isNoDataPresent || stainData.data.length === 0 || exportBtn || (sourceApp && !caseTypeSelectionCheck())"
                    class="excel" src="/assets/icons/Excel_Download.svg" matTooltip="Export" alt="Download As Excel"
                    (click)="!stainData.isNoDataPresent && stainData.data ? exportexcel():null">
            </div>
            <div class="quality-attributes" *ngIf="!stainData.isNoDataPresent"
                [ngClass]="{'vitalDx': sourceApp === 'VitalDx'}">
                <div *ngIf="stainData.data.length  && caseTypeDisplayName">
                    <div #scrollableDiv cdkDropList [cdkDropListData]="stainData.data"
                        (cdkDropListDropped)="drop_stains($event, 'group')" cdkDropListGroup
                        class="search-results main-list mt-2" (scroll)="onScroll($event)">
                        <div class="quality-attribute-main" *ngFor="let item of stainData.data; let i = index"
                            cdkDragBoundary=".main-list" cdkDragLockAxis="y"
                            [cdkDragDisabled]="editBtn || !sequenceEditMode" cdkDrag>
                            <div class="d-flex">
                                <div *ngIf="sequenceEditMode" class="drag-handle margin-left-10" cdkDragHandle>
                                    <img src="/assets/icons/Drag_handle.svg" width="14px" />
                                </div>
                                <!-- <div class="seperator"></div> -->
                                <span class="ml-1 mr-2">{{ item.panel}}
                                    <img class="ml-2" (click)="sequenceTracking(item.panelId,'ASC', item)"
                                        matTooltip="Sort" matTooltipPosition="right"
                                        [class]="sequenceEditMode && item.isAscending === 'DESC' ? 'show-sort' : 'hide-sort'"
                                        height="17px" src="/assets/icons/Reorder_Descending.svg" alt="Descending">

                                    <img class="ml-2" height="17px" matTooltip="Sort" matTooltipPosition="right"
                                        (click)="sequenceTracking(item.panelId,'DESC', item)"
                                        [class]="sequenceEditMode && item.isAscending === 'ASC' ? 'show-sort' : 'hide-sort'"
                                        src="/assets/icons/Reorder_Asceding.svg" alt="Ascending">

                                    <img class="ml-2" height="17px" matTooltip="Sort" matTooltipPosition="right"
                                        (click)="sequenceTracking(item.panelId,'ASC', item)"
                                        [class]="sequenceEditMode && item.isAscending === 'UNS' ? 'show-sort' : 'hide-sort'"
                                        src="/assets/icons/Mix - Sort.svg" alt="Un-Ordered">

                                    <img class="ml-2" height="17px" matTooltip="Expand" matTooltipPosition="right"
                                        *ngIf="isCollapsed(item) && item.procedures?.length > 0"
                                        src="../../../../assets/icons/tableExpandAll.svg" (click)="toggleCollapse(item)"
                                        alt="Expand" />

                                    <img class="ml-2" height="17px" matTooltipPosition="right"
                                        *ngIf="!isCollapsed(item) && item.procedures?.length > 0"
                                        src="../../../../assets/icons/tableCollapse.svg" (click)="toggleCollapse(item) "
                                        matTooltip='Collapse' alt="Collapse" />
                                </span>
                                <span class="f-12" *ngIf="!sequenceEditMode"
                                    [appCountOfGroupItems]="item.procedures.length"></span>
                                <div class="ml-auto mr-2 d-flex align-items-center justify-content-center gap-20">
                                    <div *ngIf="!sequenceEditMode">
                                        <button *ngIf="!isOtherAttribute && !editBtn"
                                            [disabled]="editBtn || (sourceApp && !caseTypeSelectionCheck())"
                                            matTooltipPosition="left" (click)=" openAddProcedure('Add Procedure',item) "
                                            class="card-action-button" matTooltip="Add Test/Stain"
                                            [matTooltipDisabled]="editBtn || (sourceApp && !caseTypeSelectionCheck())">
                                            <img [class.disabled]="editBtn || (sourceApp && !caseTypeSelectionCheck())"
                                                src="../../../../assets/images/add_circle.png" class="img-20" />
                                        </button>
                                        <button [disabled]="editBtn || (sourceApp && !caseTypeSelectionCheck())"
                                            *ngIf="isOtherAttribute && !editBtn" class="card-action-button"
                                            [matMenuTriggerFor]="create " matTooltipPosition="left"
                                            matTooltip="Add Test/Stain"
                                            [matTooltipDisabled]="editBtn || (sourceApp && !caseTypeSelectionCheck())">
                                            <img [class.disabled]="editBtn || (sourceApp && !caseTypeSelectionCheck())"
                                                src="../../../../assets/images/add_circle.png" class="img-20" />
                                        </button>
                                        <mat-menu #create="matMenu" xPosition="before">
                                            <button mat-menu-item class="f-12 sort-menu-option"
                                                (click)="openAddProcedure('Add Procedure',item)">
                                                <span>Create Test/Stain</span>
                                            </button>
                                            <button mat-menu-item class="f-12 sort-menu-option" *ngIf="isOtherAttribute"
                                                (click)="openAddProcedure('Others',item)">
                                                <span>Create Others</span>
                                            </button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="item.procedures.length > 0 && !isCollapsed(item)" cdkDropList
                                [cdkDropListData]="item.procedures" class="items-list"
                                (cdkDropListDropped)="drop_stains($event, 'procedure', item)"
                                [style]="item.procedures.length > 0? 'min-height: 60px; padding: 10px;':''">
                                <div class="items " *ngFor="let item of item.procedures" cdkDragBoundary=".items-list"
                                    cdkDragLockAxis="y" [cdkDragDisabled]="editBtn || !sequenceEditMode" cdkDrag>
                                    <div (click)="(editBtn || (sourceApp && !caseTypeSelectionCheck()))?openViewProcedure(item):null"
                                        [class.cursor-pointer]="editBtn" [ngClass]="item.Status.toLowerCase()">
                                        <div *ngIf="sequenceEditMode" class="drag-handle" cdkDragHandle
                                            [class.disabled]="editBtn || (sourceApp && !caseTypeSelectionCheck())">
                                            <img src="/assets/icons/Drag_handle.svg" width="14px" />
                                        </div>
                                        <!-- <div class="seperator"></div> -->
                                        <span class="ml-1">{{ item.Procedure_Name }} ({{item.CPT_Code}})</span>
                                        <div class="ml-auto" *ngIf="!sequenceEditMode&&!editBtn"
                                            (click)="!editBtn?openEditProcedure(item):null"><img
                                                [class.disabled]="editBtn || (sourceApp && !caseTypeSelectionCheck())"
                                                src="/assets/images/edit.png" class="img-20" matTooltip="Edit"
                                                matTooltipPosition="left" [matTooltipDisabled]="editBtn" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="nodata-header-wrap" *ngIf="!stainData.data.length || caseTypeDisplayName==''">
                    <span class="col-sm-12 nodata-wrapper"> No Results </span>
                </div>
            </div>
            <div class="allign-center  nodata-wrap no-data-wraps mt-4" *ngIf="stainData.isNoDataPresent">
                <p class="nodata-design">No Records Found To Display</p>
            </div>
        </div>
    </section>
    <section *ngIf="isEdit && !isView && gridPage">
        <form autocomplete="off" [formGroup]="formAdditionalTests">
            <div>
                <div class="p-2 mt-2 m-1 d-flex align-items-center">
                    <div class="mt-1">
                        <strong class="heading-border">{{stainHeading}}</strong>
                    </div>
                    <span class="ml-auto"></span>
                    <span class="ml-auto cusor-pointer align-self-end form-reset mr-3"
                        *ngIf="!isNewPanelOrProcedure? isStainsDataChanged() : null" (click)="onReset()">Reset</span>
                    <app-button class="mr-3 edit-page-button" buttonclass="secondary"
                        (click)="!isNewPanelOrProcedure? onReturn() : onCreateReturn()">Return</app-button>
                    <app-button buttonclass="primary" class="edit-page-button" (click)="onSave()">Save</app-button>
                </div>
                <div class="panel-section">
                    <div class="toggle-style">
                        <app-toggle-switch class="ml-auto mr-3" formcontrolname="isactive" labelActivetext='Status:'
                            labelInactivetext="Status:" type="secondary"></app-toggle-switch>
                    </div>
                    <div class="stain-section  m-2">
                        <app-input class="f-12 mb-3" labelValue="Panel" placeholder="Enter Panel"
                            formcontrolname="panel" [required]="true" id="panel"
                            [isFormValid]="!((getters.panel.touched || getters.panel.dirty) && getters.panel.invalid)"
                            [isReadOnly]="isPanelDisable"></app-input>
                        <app-input class="f-12 mb-3" labelValue="Test/Stain" placeholder="Enter Test/Stain"
                            formcontrolname="procedurename" [required]="true" id="procedurename"
                            [isFormValid]="!((getters.procedurename.touched||getters.procedurename.dirty) &&getters.procedurename.invalid)"
                            [isReadOnly]="isOthersInput"></app-input>
                        <app-input class="f-12 mb-3" labelValue="CPT Code" placeholder="Enter CPT Code"
                            formcontrolname="cptcodes" [required]="true" id="cptcodes"
                            [isFormValid]="!((getters.cptcodes.touched||getters.cptcodes.dirty) &&getters.cptcodes.invalid)"></app-input>
                        <div class="d-flex mb-2" *ngIf="!isCompendiumCaseType && !sourceApp">
                            <span class="stain-result-span">Result at Stain Level</span>
                            <app-toggle-switch type="secondary" class="mb-2 ml-2"
                                formcontrolname="StainLevelResult"></app-toggle-switch>
                        </div>
                        <app-input *ngIf="!isNewPanelOrProcedure" class="f-12 mb-3" labelValue="Panel Sequence"
                            placeholder="Enter Panel Sequence" formcontrolname="sequence" mask="0000000000"></app-input>
                        <app-input *ngIf="!isNewPanelOrProcedure" class="f-12 mb-3" labelValue="Test/Stain Sequence"
                            placeholder="Enter Test/Stain Sequence" formcontrolname="tsequence"
                            mask="0000000000"></app-input>
                        <app-input class="f-12  mb-3" *ngIf="!sourceApp" labelValue="Test/Stain Code"
                            placeholder="Enter Test/Stain Code" formcontrolname="procedurecode"></app-input>
                        <app-input class="f-12  mb-3" *ngIf="!sourceApp" labelValue="Machine Test Code"
                            placeholder="Enter Machine Test Code" formcontrolname="machinetestcode"></app-input>
                    </div>
                    <div class=" d-flex m-2 mt-3 more-flags " *ngIf="!sourceApp">
                        <span class="flag-chip mb-2"
                            [ngClass]="[formAdditionalTests.get(Flag.formControlName).value ? 'flag-chip-selected' : '']"
                            (click)="patchFlagValue(Flag.formControlName)" *ngFor="let Flag of MoreFlags;">
                            {{Flag.label}} </span>
                    </div>

                </div>
                <div class="panel-section" *ngIf="!sourceApp">
                    <div class="toggle-style">
                        <span>Send Out Configurations</span>
                        <span class="ml-2">
                            <img (click)="isCollapse =!isCollapse"
                                [src]="isCollapse ? '../../../../assets/images/expandMore(1).svg' : '../../../../assets/images/expandLess (2).svg'"
                                class="image" data-toggle="collapse" href="#sendOutSection" role="button"
                                aria-expanded="false" aria-controls="collapseExample" />
                        </span>
                    </div>
                    <div class="collapse" id="sendOutSection">
                        <div class="stain-section  m-2 ">
                            <app-input-auto-complete labelValue="Default Send Out Service Type" class="mb-3"
                                [dropDownValue]="SendOutServiceType" [value]="getters.defaultsendoutservicetype.value"
                                (onSelected)="getters.defaultsendoutservicetype.patchValue($event)"
                                [isFormValid]="!(getters.defaultsendoutservicetype.invalid)"
                                placeholder="Enter Default Send Out Service Type"
                                [enableClear]="true"></app-input-auto-complete>

                            <app-input-auto-complete labelValue="Send Out Full Filled Test Action" class="mb-3"
                                [dropDownValue]="SendOutFullfilledTestActionDropdownvalue"
                                [value]="getters.sendoutfullfilledtestaction.value"
                                (onSelected)="getters.sendoutfullfilledtestaction.patchValue($event)"
                                [isFormValid]="!(getters.sendoutfullfilledtestaction.invalid)"
                                placeholder="Enter Send Out Full Filled Test Action"
                                [enableClear]="true"></app-input-auto-complete>

                            <app-input-auto-complete labelValue="Default send Out Bill To" class="mb-3"
                                [dropDownValue]="sendOutBillToDropdownvalue"
                                [value]="getters.defaultsendoutbillto.value"
                                (onSelected)="getters.defaultsendoutbillto.patchValue($event)"
                                placeholder="Enter Default send Out Bill To"
                                [isFormValid]="!(getters.defaultsendoutbillto.invalid)"
                                [enableClear]="true"></app-input-auto-complete>

                            <app-input-auto-complete labelValue="Default Send Out Lab" class="mb-3"
                                [dropDownValue]="sendOutLabIdDropdownvalue" [value]="getters.defaultsendoutlab.value"
                                (onSelected)="[getters.defaultsendoutlab.patchValue($event),bindSelectedoption($event,'sendOutLabId')]"
                                placeholder="Enter Default Send Out Lab"
                                [isFormValid]="!(getters.defaultsendoutlab.invalid)"
                                [enableClear]="true"></app-input-auto-complete>

                            <app-input-auto-complete labelValue="Spawn Casetype" class="mb-3"
                                [dropDownValue]="accountCaseTypesDropdownValue" [value]="getters.spawncasetype.value"
                                (onSelected)="[getters.spawncasetype.patchValue($event),bindSelectedoption($event)]"
                                placeholder="Enter Spawn Casetype" [isFormValid]="!(getters.spawncasetype.invalid)"
                                [enableClear]="true"></app-input-auto-complete>

                            <app-input-auto-complete labelValue="Spawn Status" class="mb-3"
                                [dropDownValue]="spawnStatus" [value]="getters.spawnstatus.value"
                                (onSelected)="getters.spawnstatus.patchValue($event)"
                                [isFormValid]="!(getters.spawnstatus.invalid)" placeholder="Select Spawn Status"
                                [enableClear]="true"></app-input-auto-complete>

                            <app-input-auto-complete labelValue="Spawn Test on Service Type" class="mb-3"
                                [dropDownValue]="spawnsendoutServiceType" [value]="getters.spawntestonservicetype.value"
                                (onSelected)="getters.spawntestonservicetype.patchValue($event)"
                                placeholder="Enter Spawn Test on Service Type"
                                [isFormValid]="!(getters.spawntestonservicetype.invalid)"
                                [enableClear]="true"></app-input-auto-complete>

                            <app-input-auto-complete labelValue="Send Out Flag" class="mb-3"
                                [dropDownValue]="SendOutFlagList" [value]="getters.sendoutflag.value"
                                (onSelected)="getters.sendoutflag.patchValue($event)" placeholder="Enter Send Out Flag"
                                [isFormValid]="!(getters.sendoutflag.invalid)"
                                [enableClear]="true"></app-input-auto-complete>

                            <app-input class="mb-3" labelValue="Spawn Casetype Mnemonic"
                                placeholder="Enter Spawn Casetype Mnemonic" formcontrolname="spawncasetypemnemonic"
                                id="Spawn Casetype Mnemonic"
                                [isFormValid]="!(getters.spawncasetypemnemonic.invalid)"></app-input>
                        </div>
                        <!-- <div class=" d-flex m-2 mb-3 more-flags ">
                            <span class="flag-chip"
                                [ngClass]="[formAdditionalTests.get(Flag.formControlName).value ? 'flag-chip-selected' : '']"
                                (click)="patchFlagValue(Flag.formControlName)" *ngFor="let Flag of AdditionalFlags;">
                                {{Flag.label}} </span>
                        </div> -->
                    </div>
                </div>
                <div *ngIf="getters.StainLevelResult.value && !sourceApp" [formGroup]="molecularFormGroup">
                    <div class="panel-section">
                        <div class="toggle-style">
                            <span>Stain Level Results</span>
                        </div>
                        <div class="molecular-section p-1">
                            <div class="m-2" formArrayName="formArray">
                                <ng-container *ngFor="let ctrl of Licensure.controls; let i = index ">
                                    <div class="mb-2" [formGroupName]="i">
                                        <app-input-auto-complete labelValue="Select Result" [id]="'resultName' + i"
                                            [isFormValid]="!ctrl.get('resultName').invalid"
                                            [dropDownValue]="molecularResults" [value]="ctrl.value.resultName"
                                            (onSelected)="[ctrl.get('resultName').patchValue($event)]"
                                            placeholder="Select Result" [enableClear]="true">
                                        </app-input-auto-complete>
                                        <div [id]="'remove_btn'+i">
                                            <img *ngIf="i != 0" class="image delete-btn ml-3 cursor-pointer"
                                                src="../../../../assets/images/deletebin.svg"
                                                (click)=" removeMolecularSection(i)" />
                                            <img *ngIf="showAddIocn(i)"
                                                (click)="Licensure.value[i].resultName.trim()=='' ?null:addMolecularSection()"
                                                class="image ml-3 cursor-pointer"
                                                src="../../../../assets/images/addCircle.svg" />
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </section>
    <section *ngIf="isView && gridPage" class="mx-4 mt-3">
        <div class="view-procedure-header">
            <div class="heading">
                <b>View Test/Stain</b>
            </div>
            <span class="ml-auto"></span>
            <app-button buttonclass="secondary" class="edit-page-button" (click)="returnFromView()">Return</app-button>
        </div>
        <div class="view-page">
            <div class="d-flex align-items-center view-page-header mb-3">
                <span>Panel Name : <span>{{viewPageData.Panel}}</span></span>
                <span class="ml-auto">Status : <span
                        [class]="viewPageData.Status.toLowerCase()">{{viewPageData.Status}}</span></span>
            </div>
            <div class="view-body-general">
                <div>
                    <span class="sub-heading-border">Test/Stain Name</span>
                    <p>{{viewPageData.Procedure_Name}}</p>
                </div>
                <div>
                    <span class="sub-heading-border">CPT Code</span>
                    <p [appNotAvailable]="viewPageData.CPT_Code"></p>
                </div>
                <div>
                    <span class="sub-heading-border">Panel Sequence</span>
                    <p [appNotAvailable]="viewPageData.Sequence"></p>
                </div>
                <div>
                    <span class="sub-heading-border">Test/Stain Sequence</span>
                    <p [appNotAvailable]="viewPageData.Procedure_Sequence"></p>
                </div>
                <div>
                    <span class="sub-heading-border">Test/Stain Code</span>
                    <p [appNotAvailable]="viewPageData.Procedure_Code"></p>
                </div>
                <div>
                    <span class="sub-heading-border">Machine Test Code</span>
                    <p [appNotAvailable]="viewPageData.Machine_Test_Code"></p>
                </div>
                <div>
                    <span class="sub-heading-border">Result At Stain Level</span>
                    <p>{{viewPageData.StainLevelResult ? 'Enabled' : 'Disabled'}}</p>
                </div>
            </div>
            <p class="my-2 font-size-14-weight-500">Flags</p>
            <div class="d-flex align-items-center">
                <ng-container *ngFor="let flags of MoreFlags">
                    <p [class]="viewPageData[flags.propertyName] ? 'procedure-chip-selected' : 'procedure-chip'">
                        {{flags.label}}</p>
                </ng-container>
            </div>
            <p class="my-2 font-size-14-weight-500">Send Out Configurations</p>
            <div class="view-body-send-out-config">
                <div>
                    <span class="sub-heading-border">Default Send Out Service Type</span>
                    <p [appNotAvailable]="viewPageData.Default_Send_Out_Service_Type"></p>
                </div>
                <div>
                    <span class="sub-heading-border">Send Out Full Filled Test Action</span>
                    <p [appNotAvailable]="viewPageData.Send_Out_Fullfilled_Test_Action"></p>
                </div>
                <div>
                    <span class="sub-heading-border">Default Send Out Bill To</span>
                    <p [appNotAvailable]="viewPageData.Default_Send_Out_Bill_To"></p>
                </div>
                <div>
                    <span class="sub-heading-border">Default Send Out Lab</span>
                    <p [appNotAvailable]="viewPageData.Default_Send_Out_Lab"></p>
                </div>
                <div>
                    <span class="sub-heading-border">Spawn Casetype</span>
                    <p [appNotAvailable]="viewPageData.SpawnCaseType"></p>
                </div>
                <div>
                    <span class="sub-heading-border">Spawn Status</span>
                    <p [appNotAvailable]="viewPageData.SpawnStatus"></p>
                </div>
                <div>
                    <span class="sub-heading-border">Spawn Test on Service Type</span>
                    <p [appNotAvailable]="viewPageData.SpawnTestOnServiceType"></p>
                </div>
                <div>
                    <span class="sub-heading-border">Send Out Flag</span>
                    <p [appNotAvailable]="viewPageData.Send_Out_Flag"></p>
                </div>
                <div>
                    <span class="sub-heading-border">Spawn Casetype Mnemonic</span>
                    <p [appNotAvailable]="viewPageData.SpawnCaseTypeMnemonic"></p>
                </div>
            </div>
            <p *ngIf="Licensure.value.length > 0 && Licensure.value[0].resultName" class="my-2 font-size-14-weight-500">
                Stain Level Result</p>
            <div class="d-flex align-items-center">
                <ng-container *ngFor="let stainsLvlResult of Licensure.value">
                    <span class="procedure-chip-selected"
                        *ngIf="stainsLvlResult.resultName">{{stainsLvlResult.resultName}}</span>
                </ng-container>
            </div>
        </div>
    </section>
</section>

<!-- Copy From Other Entity -->
<div *ngIf="copyDataClicked">
    <div class="modal-header admin-model-header mb-2 mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <!-- <strong>{{action}}</strong> -->
            <strong>Copy Stains</strong>
        </h3>
    </div>
    <form [formGroup]="copyStainsMethodForm" class="copy-collection">
        <div class="p-0 ml-0">
            <div class="row d-flex col-sm-12 text-wrap-icdcode">
                <mat-form-field class="col-sm-4 w-100 mt-2 deploy-wrap" appearance="outline">
                    <mat-label><strong>Deployment</strong></mat-label>
                    <mat-select disableOptionCentering type="text" matInput #searchbar formControlName="frmDepKey">
                        <mat-option class="mat-opt-align" *ngFor="let i of DeploymentKeys" value="{{i}}"
                            (onSelectionChange)="onChangeDeployment($event,i)">
                            <span>{{ i }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="col-sm-4 example-additional-selection mt-2" appearance="outline">
                    <mat-label><strong>Group (Location) <span class="text-danger">*</span> </strong></mat-label>
                    <em class="fa fa-chevron-down chevron-align"
                        (click)="fetchOrgSeries(copyStainsMethodForm.value.frmOrganization)"></em>
                    <input formControlName="frmOrganization" matInput #searchbar
                        (click)="fetchOrgSeries(copyStainsMethodForm.value.frmOrganization)"
                        (keyup)="fetchOrgSeries(copyStainsMethodForm.value.frmOrganization)" [matAutocomplete]="orgauto"
                        placeholder="Search here ..." class="upload-input">
                    <mat-autocomplete #orgauto="matAutocomplete">
                        <div *ngFor="let show of searchResult">
                            <mat-option class="mat-opt-align pl-2" title="{{ show.accountname }} ({{show.accountid}})"
                                (onSelectionChange)="selectedCopyDataOrganization($event, show)">
                                <span>{{ show.accountname }} ({{show.accountid}})</span>
                            </mat-option>
                        </div>
                    </mat-autocomplete>

                    <!-- <button class="Expbtn" *ngIf="copyStainsMethodForm.value.frmOrganization" matSuffix mat-icon-button
                        aria-label="Clear" title="Reset Search" (click)="clearOrganization()">
                        <mat-icon>close</mat-icon>
                    </button> -->
                </mat-form-field>
            </div>

            <div *ngIf="gridWidth > 0" class="col-sm-12 mt-4">
                <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" (initialized)="initialisedGrid(grid,1)" #grid
                    (formatItem)="formatItem(grid, $event)" [frozenColumns]="1">
                    <!-- Status -->
                    <wj-flex-grid-column [header]="'Status'" [binding]="status" *ngIf="postUpload"
                        [allowResizing]="true" [width]="170" [allowDragging]="false" [allowSorting]="false"
                        [format]="'d'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                            <span
                                [ngStyle]="{color: cell.item.status=='Success' ?'green':cell.item.status=='Ignored' ? '#ff9800' : 'red' }">{{cell.item.status}}
                            </span>
                        </ng-template>
                    </wj-flex-grid-column>

                    <!-- Notes -->
                    <!-- <wj-flex-grid-column [header]="'Notes'" [binding]="notes" [allowResizing]="true" [width]="477"
                      [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                      <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                          <span [ngStyle]="{color: cell.item.notes=='Mandatory field is missing!' || cell.item.notes=='Null values exist!' ?'red': '#ff9800' }">{{cell.item.notes}}
                          </span>
                      </ng-template>
                  </wj-flex-grid-column> -->

                    <div *ngFor="let columnname of sheetHeader">
                        <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()"
                            [header]="columnname.replace('_','')"
                            [visible]="!(columnname == 'tablename') && !(columnname == 'AccountId') && !(columnname == 'ProcedureId') && !(columnname == 'CaseType') && !(columnname == 'TestId') && !(columnname == 'Id') && !(columnname == 'AccountId') && !(columnname == 'PId') && !(columnname == 'notes') && !(columnname == 'slno') && !(columnname == 'casetype')"
                            [allowResizing]="true" [width]="120" [allowDragging]="false" [allowSorting]="false"
                            [format]="'d'">
                        </wj-flex-grid-column>
                    </div>
                    <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                </wj-flex-grid>
                <br />
                <div class="row pl-3 mt-4">
                    <div class="col-sm-2 p-0">
                        <button mat-raised-button class="mr-4 Expbtn" *ngIf="excelDataArray.length > 0"
                            (click)="removeGrid()">Back</button>
                        <!-- <button mat-raised-button class="admin-btn-success mr-4"
                          *ngIf="excelDataArray.length == 0 || gridWidth == 0" (click)="refreshGrid()">Back</button> -->
                    </div>
                    <div class="col-sm-10 button-wrap p-0 m-0">
                        <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
                            <button mat-raised-button *ngIf="postDownload" class="Expbtn mr-4"
                                (click)="refreshGrid()">Finish</button>
                            <button mat-raised-button *ngIf="!postDownload" class="Expbtn mr-4"
                                (click)="refreshGrid()">Cancel</button>
                            <button mat-raised-button [disabled]="disableApprovebtn()" *ngIf="!postUpload"
                                class="Expbtn mr-4" (click)="uploadTestData()">Approve</button>
                            <button mat-raised-button *ngIf="postUpload" class="Expbtn"
                                (click)="ExportExcelCopy(grid)">Download</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pl-3 mt-4">
                <div class="col-sm-2 p-0">
                    <button mat-raised-button class="Expbtn mr-4" *ngIf="excelDataArray.length == 0"
                        (click)="refreshGrid()">Back</button>
                </div>
            </div>


            <div class="col-sm-12" *ngIf="gridWidth == 0 && noData">
                <div class="container create-btn col-sm-12 align-center">
                    <!-- <div class="col-sm-10 nodata-wrap no-data-wraps justify-content-center">
                        <div>No Result</div>
                    </div> -->
                    <div class="col-sm-10 nodata-header-wrap">
                        <span class="col-sm-12 nodata-wrapper"> No Results </span>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>


<div *ngIf="uploadClicked" class="my-bulk-form">
    <div class="modal-header admin-section-header mb-2 mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong>Upload Stains</strong>
        </h3>
    </div>
    <div class="button-field-wrap m-1 p-0">
        <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
            <mat-label class="d-flex">
                Templates
            </mat-label>
            <!-- <em class="fa fa-chevron-down chevron-align"></em> -->
            <mat-select disableOptionCentering class="ml-2">
                <mat-option class="temp-font-size" (click)="downloadMandatoryFields()">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>Minimal
                    Fields
                </mat-option>
                <mat-option class="temp-font-size" (click)="downloadAllFields()">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>All
                    Fields
                </mat-option>
                <mat-option class="temp-font-size" (click)="downloadUpdateFields()">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>Bulk Update
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="button-field-wrap">
        <span class="instr-size mb-2">
            <img aria-hidden="true" class="mb-1" src="/assets/icons/help.svg" width="15px" height="auto" />
            You can download the sample template file to upload the Stain List.</span>
    </div>

    <div class="col-sm-12 p-0" *ngIf="gridWidth == 0">
        <div class="image-height" (fileDropped)="onFileDropped($event)" appDnd>
            <input type="file" id="fileDropRef" #fileDropRef click="value = null" value=""
                (change)="onFileChange($event)" hidden />
            <span class="align-center img-wrap">
                <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
            </span>
            <span>Drag & Drop excel files here</span>
            <span class="align-center">Or</span>
            <label class="btn-sm btn rounded browse ml-5 upload-color" for="fileDropRef">Browse
                File</label>
        </div>
        <button mat-raised-button class="Expbtn mt-4" (click)="refreshGrid()">
            Back
        </button>
    </div>

    <div *ngIf="gridWidth > 0">
        <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" #grid [headersVisibility]="'Column'"
            (formatItem)="formatItem(grid, $event)" [frozenColumns]="2">

            <!-- Action -->
            <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="208"
                [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button class="edit-delete-btn" (click)="deleteRow(grid, row)"><em id="deleteButton"
                            [title]="'Delete'" class="p-0 fa fa-trash"></em></button>
                </ng-template>
            </wj-flex-grid-column>

            <!-- Status -->
            <wj-flex-grid-column [header]="'Status'" [binding]="STATUS" *ngIf="postUpload" [allowResizing]="true"
                [width]="170" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <span
                        [ngStyle]="{color: cell.item.status=='Success' ?'green':cell.item.status=='Ignored' ? '#ff9800' : 'red' }">{{cell.item.status}}
                    </span>
                </ng-template>
            </wj-flex-grid-column>

            <!-- Notes -->
            <!-- <wj-flex-grid-column [header]="'Notes'" [binding]="notes" [allowResizing]="true" [width]="477"
              [allowDragging]="false" [allowSorting]="false" [format]="'d'">
              <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                  <span [ngStyle]="{color: highlightNotes? (cell.item.notes !='Mandatory field is missing' && cell.item.notes !='Null values exist!' && cell.item.notes !='Special chars exists in CPTCode' && cell.item.notes !='Stain created for this case type' && cell.item.notes !='Valid' ? '#ff9800': (cell.item.notes == 'Valid' || cell.item.notes == 'Stain created for this case type') ? 'green' : 'red'):''}">{{cell.item.notes}}
                  </span>
              </ng-template>
          </wj-flex-grid-column> -->


            <wj-flex-grid-column *ngFor="let columnname of sheetHeader" [binding]="columnname?.toString().toLowerCase()"
                [header]="columnname" [visible]="!(columnname == 'Status')" [allowResizing]="true" [width]="220"
                [allowDragging]="false" [allowSorting]="false" [format]="'d'">
            </wj-flex-grid-column>




            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>

        <div class="row pl-3 mt-4">
            <div class="col-sm-2 p-0">
                <button mat-raised-button class="Expbtn mr-4" *ngIf="excelDataArray.length > 0"
                    (click)="removeGrid()">Back</button>
                <button mat-raised-button class="Expbtn mr-4" *ngIf="excelDataArray.length == 0"
                    (click)="refreshGrid()">Back</button>
            </div>
            <div class="col-sm-10 button-wrap p-0 m-0">
                <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
                    <button mat-raised-button *ngIf="postDownload" class="Expbtn mr-4"
                        (click)="refreshGrid()">Finish</button>
                    <button mat-raised-button *ngIf="!postDownload" class="Expbtn mr-4"
                        (click)="refreshGrid()">Cancel</button>
                    <button mat-raised-button [disabled]="disableApprovebtn()" *ngIf="!postUpload" class="Expbtn mr-4"
                        (click)="uploadTestData()">Approve</button>
                    <button mat-raised-button *ngIf="postUpload" class="Expbtn"
                        (click)="ExportExcel(grid)">Download</button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
        <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
    </div>

</div>