<div class="p-2 infodetails">
  <mat-spinner class="labadmin-spinner loader-aligned"
    *ngIf="!checkInfoAssociation() && !locationsList?.length && !isProvidersMenu && !noDataFoundFlag && fromLocation !='location'"
    [diameter]="50">
  </mat-spinner>

  <div class="px-3 parent_container " *ngIf="checkInfoAssociation() && !noDataFoundFlag">

    <div class="mb-3" *ngIf="locationsList">
      <div class="label_border _600">
        {{locationText}}
      </div>
      <mat-accordion *ngFor="let organization of locationKeys" >
        <mat-expansion-panel class="mb-2"  [expanded]="true">
          <mat-expansion-panel-header class="cursor">
            <mat-panel-title> <strong>{{ organization }} </strong><span class="count-style ml-2">(Location(s): {{
                locationsList[organization]?.length }})</span> </mat-panel-title>
          </mat-expansion-panel-header>
          <p class="my-2 location-value" *ngFor="let item of locationsList[organization]">{{ item.DisplayName }} <span *ngIf="item.isReadingLocationEnabled" class="primary_role ml-2"> <i>Reading Location</i>  </span></p>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="mb-3" *ngIf="facilityLocations?.length>0"> <span class="label_border _600">Facility Location(s)</span>
      <mat-accordion> <mat-expansion-panel *ngFor="let item of facilityLocations" class="mb-2">
          <mat-expansion-panel-header> <mat-panel-title> <strong>{{item.orderingFacilityName}}</strong> <span class="count-style ml-2">
                (Facility Locations: {{item.selectedObjects.length}})</span> </mat-panel-title>
          </mat-expansion-panel-header>
          <p class="my-2" *ngFor="let list of item.selectedObjects">{{list.accountname}}</p>
        </mat-expansion-panel> </mat-accordion>
    </div>

    <div class="mb-3" *ngIf="workGroupList?.length>0"> <span class="label_border  _600">Workgroup(s)</span>
      <div class="d-flex">
        <div class="label_button" *ngFor="let item of workGroupList">{{item.DepartmentName}}</div>
      </div>
    </div>

    <div class="mb-3" *ngIf=" enableuserassociation && pathologistRoleAssociationData?.length>0">
      <span class="label_border _600">Pathologist</span>
      <div class="d-flex flex-wrap height-scroll">
        <ng-container *ngFor="let item of pathologistRoleAssociationData">
          <div class="p-1">
            <div appTooltipEllipsis class="label_button">{{item.FormattedDisplayName}}</div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="mb-3" *ngIf="enableuserassociation && PhysicianRoleAssociationData?.length > 0">
      <span class="label_border _600">Physician</span>
      <div class="d-flex flex-wrap height-scroll">
        <ng-container *ngFor="let item of PhysicianRoleAssociationData">
          <div class="p-1">
            <div appTooltipEllipsis class="label_button">{{item.FormattedDisplayName}}</div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="px-3 " *ngIf="isProvidersMenu && surgiCenterDetails?.length>0">
    <span class=" _600 label_border">Surgicenter</span>
      <div class=" col-sm-12 m-0 p-0 d-flex">
        <div class="w-100 mb-3">
          <div class="header_title px-2 grid_view">
            <div class="row col-sm-12 m-0 p-0">
              <div class="col-sm-6 _600 padding-surgigrid">Name (NPI)</div>
              <div class="col-sm-6 _600 padding-surgigrid">Location (City)</div>
            </div>
          </div>
          <div class="grid-data-content gridDetails-view">
            <div class="row col-sm-12 m-0 p-0" *ngFor="let item of surgiCenterDetails">
              <div class="col-sm-6  padding-surgigrid">{{item.DispalyNameNPI}}</div>
              <div class="col-sm-6  padding-surgigrid">{{item.LocationCity}}</div>
            </div>
          </div>
        </div>
      </div>
  </div>

  <div class="col-sm-12 nodata-header-wrap" *ngIf="noDataFoundFlag"> <span class="col-sm-12 nodata-wrapper">No Data
      Available</span> </div>
</div>
<div class="pl-3 pr-3 parent_container" *ngIf="fromLocation =='location'">
  <mat-spinner *ngIf="showLocationLoader" class="labadmin-spinner"
    [ngClass]="{'spinner-margin-hide-grid': isHideLocationGrid, 'spinner-margin-show-grid': !isHideLocationGrid}"
    [diameter]="50">
  </mat-spinner>

  <div *ngIf="!showLocationLoader">
    <div class="mb-3" *ngIf="LabOrganizations?.length>0">
      <h5 class="mt-2">
        <strong class="header-View">Laboratory</strong>
      </h5>
      <mat-accordion>
        <mat-expansion-panel *ngFor="let item of LabOrganizations" class="mb-2" [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>  <strong>{{item.labGroupName}}</strong>
               <span class="count-style ml-2"> (Location(s):
                {{item.labGroupData.length}})</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p class="my-2 " *ngFor="let list of item.labGroupData">{{list.DisplayName}}</p>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <h5 class="mt-2" *ngIf="casetypeList?.length>0">
      <strong class="header-View">Case Types</strong>
    </h5>
    <div class="casetype-box row" *ngIf="casetypeList?.length>0">
      <div *ngFor="let val of casetypeList" class="casetypes-display m-1" style="position: relative;"
        (mouseenter)="onMouseEnter(val)" (mouseleave)="onLeaveMouse()">

        <span class="caseTypeClass">{{val.Category}}({{caseTypeTitleArr?.[val['Category']]?.length}})</span>
        <div *ngIf="showToolTip && currentCategory === val['Category']" class="tooltip-show">
          <div *ngFor="let item of toolTipContent">{{ item }}</div>
        </div>
      </div>
    </div>
    <h5 class="mt-2" *ngIf="seUserList?.length>0">
      <strong class="header-View">Sales Executive</strong>
    </h5>
    <div class="se-Courier-parent">
      <div class="se-Courier-box" *ngFor="let ele of seUserList">{{ele.name}}</div>
    </div>
    <h5 class="mt-2" *ngIf="courierUserList?.length>0">
      <strong class="header-View">Courier</strong>
    </h5>
    <div class="se-Courier-parent">
      <div class="se-Courier-box" *ngFor="let ele of courierUserList">{{ele.name}}</div>
    </div>
    <h5 class="mt-2">
      <strong class="header-View"
        *ngIf="associateSurgicenterSavedData?.length>0 || associatePhysiciaSavedData?.length>0 || associateOFSavedData?.length>0">Surgicenter</strong>
    </h5>
    <div  *ngIf="associatePhysiciaSavedData?.length>0">
      <span class=" _600 label_border">Physician</span>
      <div class=" col-sm-12 m-0 p-0 d-flex">
        <div class=" w-100 mb-3">
          <div class="header_title  grid_view">
            <div class="row col-sm-12 m-0 p-0">
              <div class="col-sm-6 _600 padding-surgigrid">Name (NPI)</div>
              <div class="col-sm-6 _600 padding-surgigrid">Location (City)</div>
            </div>
          </div>
          <div class="grid-data-content gridDetails-view">
            <div class="row col-sm-12 m-0 p-0" *ngFor="let item of associatePhysiciaSavedData">
              <div class="col-sm-6  padding-surgigrid">{{item.PhysicianNPI}}</div>
              <div class="col-sm-6  padding-surgigrid">{{item.LocationCity}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div  *ngIf="associateSurgicenterSavedData?.length>0">
      <span class=" _600 label_border">Ordering Facility</span>
      <div class=" col-sm-12 m-0 p-0   d-flex">
        <div class="w-100 mb-3">
          <div class="header_title  grid_view">
            <div class="row col-sm-12 m-0 p-0">
              <div class="col-sm-6 _600 padding-surgigrid">Name (NPI)</div>
              <div class="col-sm-6 _600 padding-surgigrid">Location (City)</div>
            </div>
          </div>
          <div class="grid-data-content gridDetails-view">
            <div class="row col-sm-12 m-0 p-0" *ngFor="let item of associateSurgicenterSavedData">
              <div class="col-sm-6 padding-surgigrid">{{item.DispalyNameNPI}}</div>
              <div class="col-sm-6  padding-surgigrid">{{item.LocationCity}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div  *ngIf="associateOFSavedData?.length>0">
      <div class=" col-sm-12 m-0 p-0 d-flex">
        <div class="w-100 mb-3">
          <div class="header_title px-2 grid_view">
            <div class="row col-sm-12 m-0 p-0">
              <div class="col-sm-6 _600 padding-surgigrid">Name (NPI)</div>
              <div class="col-sm-6 _600 padding-surgigrid">Location (City)</div>
            </div>
          </div>
          <div class="grid-data-content gridDetails-view">
            <div class="row col-sm-12 m-0 p-0" *ngFor="let item of associateOFSavedData">
              <div class="col-sm-6  padding-surgigrid">{{item.DispalyNameNPI}}</div>
              <div class="col-sm-6  padding-surgigrid">{{item.LocationCity}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>