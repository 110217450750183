import { ViewportRuler } from '@angular/cdk/scrolling';
import {  ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { ClientRectObject } from '@popperjs/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  viewProviders:[{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class CustomSelectComponent implements OnInit, OnChanges,OnDestroy{
  @Input() labelValue : string = ''
  @Input() required : boolean = false
  @Input() formcontrolname : string | null = null
  @Input() isFormValid:boolean|null=true
  @Input() isDisabled:boolean=false
  @Input() dropDownValues : any = []
  @Input() dropDownKeyProp : string = ''
  @Input() dropDownValueProp : string = ''
  @Input() defaultOption : string = 'Select Option'
  @Input() sorted:boolean=true //sort's data in alphabetical order
  @ViewChild('customSelect') select
  @ViewChild('cstmpopup') cstmpopupEle;
  @ViewChild('maindivct') maindiv;

  protected readonly _destroy = new Subject<void>();
  value : any
  showDropDown : boolean = false
  selectedItem : string = ''
  contentWidth: ClientRectObject
  incrementValue :number = 0;

  constructor(
    private fcd:  FormGroupDirective,protected _viewportRuler: ViewportRuler, protected _changeDetectorRef: ChangeDetectorRef
  ){}
  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.dropDownValues && this.fcd.control.get(this.formcontrolname).value){
      this.selectionHelper()   
    }
    if(changes.defaultOption)
      {
        this.incrementValue=this.defaultOption.length?1:0
      }
  }

  ngOnInit(): void {
    if(this.dropDownValues?.length)
      this.dropDownValues=this.sorted? this.dropDownValues.sort((i:any,j:any)=>i[this.dropDownKeyProp].trim()>j[this.dropDownKeyProp].trim()?1:-1) : this.dropDownValues

    this._viewportRuler
      .change()
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        if (this.showDropDown) {
          this.contentWidth = this.select.nativeElement.getBoundingClientRect();
          this._changeDetectorRef.markForCheck();
        }
      });
    if(!this.fcd.control.get(this.formcontrolname).value){
      this.selectedItem = this.defaultOption
    }
    else {
      this.selectionHelper()
    }

    this.fcd.control.get(this.formcontrolname).valueChanges.subscribe((value) => {
      if(!value) this.selectedItem = this.defaultOption
      else this.selectionHelper()
    })
  }
  
  addValue(drp){
    this.showDropDown = false
    this.selectedItem = drp[this.dropDownKeyProp]
    this.fcd.control.get(this.formcontrolname).markAsDirty()
    this.fcd.control.get(this.formcontrolname).setValue(drp[this.dropDownValueProp])
    this.select.nativeElement.blur();
    this.maindiv.nativeElement.focus();
  }

  showHideDropDown(){
    if(!this.fcd.control.get(this.formcontrolname).disabled)
      {
        this.showDropDown = !this.showDropDown
        this.contentWidth = this.select.nativeElement.getBoundingClientRect()
      }
  }

  setToDefault(){
    this.showDropDown = false
    this.selectedItem = this.defaultOption
    this.fcd.control.get(this.formcontrolname).markAsDirty()
    this.fcd.control.get(this.formcontrolname).setValue('')
    this.maindiv.nativeElement.focus();
  }

  get form(){
    return this.fcd.control.get(this.formcontrolname)
  }
  arrowkeyLocation=0
  keydown(event){
    if(event.key=="Enter")
      {
          this.showDropDown = !this.showDropDown
          this.contentWidth = this.select.nativeElement.getBoundingClientRect()
          this.arrowkeyLocation=0
          this.maindiv.nativeElement.focus();
      }  
  }
  focusOnElement()
  {
    if(this.fcd.control.get(this.formcontrolname).value){
    this.arrowkeyLocation=this.dropDownValues.findIndex(i=>i[this.dropDownValueProp]==this.form.value)==-1?0:this.dropDownValues.findIndex(i=>i[this.dropDownValueProp]==this.form.value)
    this.arrowkeyLocation=this.arrowkeyLocation+this.incrementValue
    this.cstmpopupEle? this.cstmpopupEle.nativeElement.children[this.arrowkeyLocation].focus():''
    }
    }
  pressed(event:any){
    if(event.key=='Tab')
      {
        this.showDropDown=false;
        this.maindiv.nativeElement.focus();
      }
    else if(event.key=="ArrowDown" )
      {
        if(this.arrowkeyLocation<this.dropDownValues.length){
        this.cstmpopupEle? this.cstmpopupEle.nativeElement.children[++this.arrowkeyLocation]?.focus():''
      }
        event.preventDefault();
      }
    else if(event.key=="ArrowUp")
      {
        if(this.arrowkeyLocation>0){
        this.cstmpopupEle? this.cstmpopupEle.nativeElement.children[--this.arrowkeyLocation]?.focus():'' 
        }
        event.preventDefault();
      }
    
  }

  private selectionHelper(){

    if(this.dropDownValues.length){  
      this.dropDownValues = [...this.dropDownValues]
     // this.dropDownValues=this.sorted? this.dropDownValues.sort((i:any,j:any)=>i[this.dropDownKeyProp].trim()>j[this.dropDownKeyProp].trim()?1:-1) : this.dropDownValues
      const value = this.dropDownValues.find((j) => j[this.dropDownValueProp] === this.fcd.control.get(this.formcontrolname).value)
      this.selectedItem = value[this.dropDownKeyProp]
    }
  }
  
}
