<div *ngIf="allow">
  <form [formGroup]="myForm">
    <div class="row sign-wrap">
      <div class="col-md-12">
        <div class="modal-header admin-model-header mt-2">
          <h3 class="modal-title w-100 admin-model-header-txt">
            <strong>Signature</strong>
          </h3>
        </div>
      </div>

      <div *ngIf="userFlag" class="col-sm-12 button-wrap">
        <mat-form-field appearance="outline" class="col-sm-3 p-0">
            <mat-label>Role</mat-label>
            <mat-select disableOptionCentering formControlName="selectedRole">
                <mat-option class="mat-opt-align" (onSelectionChange)="setUserRole($event,user)"
                    value="{{user.RoleName.toString().trim() }}" *ngFor="let user of userRoles">
                    {{user.RoleName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
     </div>

      <div class="col-sm-12 upload-wrap">
        <div class="col-sm-6">
          <div class="row">
            <label class="upload-sign ml-2">Existing signature</label>
          </div>
          <div class="col-sm-12 image-heights nodata-header-wrapp" *ngIf="noSign">
            <span class="col-sm-12 nodata-wrapper"> No Data Available </span>
          </div>
          <div class="image-heights" style="width: 100%;" *ngIf="!noSign">
            <img style="max-width: 100%;max-height: 100%;" alt="nosign" [src]="imageSrc">
          </div>
          <!-- <button class="btn m-2 blue btn-rounding remove-btn" title="sign remove" *ngIf="!noSign && !ifStatusDelete"
            (click)="hideRemoveBtn ||removeExistingSign()">
            Remove
          </button> -->
        </div>
        <div class="col-sm-6 p-0">
          <div class="col-sm-6">
            <label class="upload-sign">Upload new signature</label>
          </div>
          <div class="col-sm-12 pr-0">
            <div class="custom-dropzone image-heights" formControlName="sign_Upload" appDnd
              (fileDropped)="onFileDropped($event)" *ngIf="imagePath">
              <!-- <ng-container *ngIf="!hideUploadBtn && !ifStatusDelete;else falseUpload">
                <input type="file" #fileDropRef id="fileDropRef" (change)="onFileChange($event)" [multiple]="false"
                  hidden />
              </ng-container>
              <ng-template #falseUpload>
                <ng-container>
                  <input type="text" #fileDropRef id="fileDropRef" (click)="onFileChange($event)" [multiple]="false"
                    hidden />
                </ng-container>
              </ng-template> -->

              <input *ngIf="!hideUploadBtn && !ifStatusDelete" type="file" #fileDropRef id="fileDropRef"
                (change)="onFileChange($event)" [multiple]="false" hidden />
              <input *ngIf="hideUploadBtn || ifStatusDelete" type="text" #fileDropRef id="fileDropRef"
                (click)="onFileChange($event)" [multiple]="false" hidden />
              <span class="mb-2">Drag and drop file here</span>&nbsp;<span class="mb-2">or</span>&nbsp;
              <label class="btn-sm btn rounded browse" for="fileDropRef">Browse File</label>
            </div>
            <div class="image-heights" *ngIf="!imagePath">
              <img style="max-width: 100%;max-height: 100%;" alt="image" [src]="NewimageSrc" alt="" srcset="">
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 p-0 m-0">
        <div class="row p-0 m-0">
          <div *ngIf="hideUploadBtn || ifStatusDelete" class="col-sm-6 p-0 m-0">&nbsp;</div>
          <div *ngIf="hideUploadBtn || ifStatusDelete" class="col-sm-6 p-0 m-0">
            <div class="pt-1 text-center  text-danger">
              <small>
                You don't have the required privileges' to upload signature!
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 w-100 button-wrap">
        <div class="col-sm-6 button-wrap">
          <!-- <ng-container *ngIf="!noSign && !hideRemoveBtn && !ifStatusDelete;else falseRemove">
            <button class="btn m-2 blue btn-rounding remove-btn d-block m-auto" title="sign remove"
              (click)="removeExistingSign()">
              Remove
            </button>
          </ng-container>
          <ng-template #falseRemove>
            <ng-container>
              <button class="btn m-2 blue btn-rounding remove-btn d-block m-auto" title="sign remove"
                [disabled]="hideRemoveBtn">
                Remove
              </button>
            </ng-container>
          </ng-template> -->
          <button mat-raised-button class="admin-btn-success mr-4" title="sign remove"
            *ngIf="!noSign && (hideRemoveBtn || ifStatusDelete)" [disabled]="hideRemoveBtn || ifStatusDelete">
            Remove
          </button>
          <button mat-raised-button class="admin-btn-success" title="sign remove"
            *ngIf="!noSign && !hideRemoveBtn && !ifStatusDelete" (click)="removeExistingSign()">
            Remove
          </button>
        </div>
        <div class="col-sm-6 button-wrap p-0">
          <button *ngIf="!imagePath" mat-raised-button class="admin-btn-success mr-4" title="sign cancel"
            (click)="removeNewSign()" [disabled]="imagePath">
            Cancel
          </button>
          <button *ngIf="!imagePath && !hideUploadBtn && !ifStatusDelete" mat-raised-button
            class="admin-btn-success" title="sign update" (click)="ConfirmUploadSign()">Update
          </button>
          <button *ngIf="!imagePath && (hideUploadBtn || ifStatusDelete) " mat-raised-button class="admin-btn-success"
            title="sign update" [disabled]=" hideUploadBtn || ifStatusDelete || imagePath">Update
          </button>
        </div>
      </div>
    </div>
  </form>
</div>


<div class="col-md-12" *ngIf="!allow">
  <div class="modal-header admin-model-header mt-3">
    <h3 class="modal-title w-100 admin-model-header-txt">
      <strong>Signature</strong>
    </h3>
  </div>
</div>

<div *ngIf="!allow">
  <div class="row">
    <div class="col-12 pt-5 nodata-wrap">
      <span class="p-3" class="nodata-design">
        Rolename isn't Physician or Pathologist
      </span>
    </div>
  </div>
</div>