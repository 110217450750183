import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-config-fields-popup',
  templateUrl: './config-fields-popup.component.html',
  styleUrls: ['./config-fields-popup.component.scss']
})

export class ConfigFieldsPopupComponent {

  @ViewChild('popupField') popupField: ElementRef;

  constructor(public dialogRef: MatDialogRef<ConfigFieldsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
}
