<!-- Edit -->
<app-spinner-loader *ngIf="!isAdmin3 && isSaveDisabled" ></app-spinner-loader>
<div *ngIf="editViewClicked || createViewClicked" class="row col-sm-12 m-0 p-0" [class]="!isAdmin3?'px-2':''">
   
    <div class="col-sm-6 mt-2 mb-3 d-flex justify-content-between">
        <span class="header-View">
            <strong>{{context}} {{ HeaderName }} </strong>
        </span>
    </div>
    <form class="float-right col-sm-6 p-0 mt-2 mb-2">
        <div class="p-0 button-wrap align-items-center">
            <a class="reset-btn mr-3" (click)="resetDynamicForm()" *ngIf="isFormChanged">Reset</a>
            <button class="mr-3 align-center buttonHover" (click)="returnBtn(true)">Return</button>
            <button class="align-center primaryButtonColor d-flex justify-content-space-evenly" (click)="saveDynamicForm()" >
                Save  
                <!-- <mat-spinner [diameter]="14" class="ml-1" *ngIf="isSaveDisabled"></mat-spinner> -->
            </button>
        </div>
    </form>

    <div autocomplete="off" class="col-sm-12 p-0">
        <form #myForm="ngForm" (ngSubmit)="saveBtn()" autocomplete="off" class="row col-sm-12 p-3 form-wrap-view" style="padding: 0px !important">
            <div class="row col-sm-12" style="padding-left: 0px !important;padding-right: 0px !important;">

                <div class="col-sm-12" style="border-bottom: 1px solid #d7d7d7; padding: 5px 20px;display: flex; justify-content: flex-end;" *ngIf="(dynamicFormData | htmlfilter: {dataType: ['inputSequence', 'badge']})?.length && !isHideTopSectionInCreate">
                    <ng-container *ngFor="let field of dynamicFormData | htmlfilter: {dataType: ['inputSequence', 'badge']}">
    
                        <div *ngIf="editViewClicked && field.dataType === 'inputSequence'"
                            [ngClass]="field['manageClassName']" class="viewInputContainer sequenceField d-flex justify-content-end p-0">
                            
                            <div class="d-flex justify-content-center align-items-center mr-2" style="font-size: 16px;" >Sequence:</div>
                            
                            <div style="width: 50px;">
                                <mat-form-field class="col-sm-12 p-0" [ngClass]="field.value ? 'valuedField' : ''">
                                    <!-- <mat-label> {{field['displayName']}} </mat-label> -->
                                    <input #dynamicFields [required]="field.inputValidations['required']"
                                        [maxlength]="field.inputValidations['maxLength']"
                                        [minlength]="field.inputValidations['minLength']"
                                        [pattern]="fieldPatterns?.[field?.inputValidations?.['inputDataType']] || ''"
                                        [(ngModel)]="field.value" name="{{ field.columnName }}"
                                        (blur)="onBlurOfInput($event, field)" trim matInput (input)="isFormChanged = true" [disabled]="enableorDisableSeqOnDefault && myForm?.value?.['isdefault'] === 1"/>
                                </mat-form-field>
                            </div>
                        </div>
    
                        <div *ngIf="field.dataType === 'badge'" class="badgeAlignment"
                            [ngClass]="field['manageClassName']"
                            [ngStyle]="{'flex': field?.properties?.width ? '0 0 ' + field?.properties?.width : null}">
                            <span class="mr-2"> {{field['displayName']}}: </span>
                            <label class="ct-tag-toggle" [ngClass]="{'disabledBadge': HeaderName === 'Workgroups'}">
                                <input type="checkbox" [(ngModel)]="field.value" name="{{ field.columnName }}"
                                    (change)="onBadgeChange($event, field['columnName'],field?.dependentFields); isFormChanged = true"
                                    [value]="field['value'] == ('Inactive' || 'No' || false || 0 || '') ? 0 : 1">
                                <span class="slider"></span>
                            </label>
                        </div>

                    </ng-container>
                </div>

                <div class="col-sm-12 row" style="padding: 20px;">
                    <ng-container *ngFor="let field of dynamicFormData">
    
                        <div *ngIf="field.dataType === 'input'" [ngClass]="field['manageClassName']" class="viewInputContainer"
                            [ngStyle]="{'flex': field?.properties?.width ? '0 0 ' + field?.properties?.width : null}">
                            <mat-form-field class="col-sm-12 p-0" [ngClass]="field.value ? 'valuedField' : ''">
                                <mat-label> {{field['displayName']}} </mat-label>
                                <input #dynamicFields [required]="field.inputValidations['required']"
                                    [maxlength]="field.inputValidations['maxLength']"
                                    [disabled]="field['properties']?.['isDisableInEdit'] && editViewClicked"
                                    [minlength]="field.inputValidations['minLength']" [(ngModel)]="field.value"
                                    name="{{ field.columnName }}" (blur)="onBlurOfInput($event, field)" trim matInput
                                    (input)="isFormChanged = true" (blur)="trimField(field)" />
                            </mat-form-field>
                        </div>
    
                        <!-- <div *ngIf="editViewClicked && field.dataType === 'inputSequence'"
                            [ngClass]="field['manageClassName']"
                            [ngStyle]="{'flex': field?.properties?.width ? '0 0 ' + field?.properties?.width : null}" class="viewInputContainer">
                            <mat-form-field class="col-sm-12 p-0" [ngClass]="field.value ? 'valuedField' : ''">
                                <mat-label> {{field['displayName']}} </mat-label>
                                <input [required]="field.inputValidations['required']"
                                    [maxlength]="field.inputValidations['maxLength']"
                                    [minlength]="field.inputValidations['minLength']"
                                    [pattern]="fieldPatterns?.[field?.inputValidations?.['inputDataType']] || ''"
                                    [(ngModel)]="field.value" name="{{ field.columnName }}"
                                    (blur)="onBlurOfInput($event, field)" trim matInput (input)="isFormChanged = true" />
                            </mat-form-field>
                        </div> -->
    
                        <div *ngIf="(field.dataType === 'dropdown' && !(field['properties']?.['isDisabledInAdmin'] && isAdmin3))"
                            [ngClass]="{'hidden': (field.properties?.isHideInLabAdmin && !isAdmin3)}" [class]="field.manageClassName"
                            [ngStyle]="{'flex': field?.properties?.width ? '0 0 ' + field?.properties?.width : null}" class="viewSelectContainer">
                            <mat-form-field class="col-sm-12 p-0" [ngClass]="field.value ? 'valuedField' : ''">
                                <mat-label> {{ field?.['displayName'] }} </mat-label>
                                <mat-select [(ngModel)]="field.value" name="{{ field.columnName }}"
                                    [required]="field.inputValidations['required']"
                                    (selectionChange)="onSelectChange(field); isFormChanged = true"
                                    [disabled]="(field['properties']?.['isDisableInEdit'] && editViewClicked && !isUserLevel) || (field?.properties?.['isDisabledInAdmin'] && isAdmin3)"
                                    [panelClass]="'customCEOverlayPanelClass listingDropdownPanel'" #dynamicFields>
                                    <mat-option value="All"
                                        *ngIf="field['properties']?.['source']?.['hasAllOption']">All</mat-option>
                                    <mat-option *ngFor="let dropdownOptions of field['properties']?.['source']?.['options']"
                                        [value]="dropdownOptions[field['properties']?.['source']?.['keyToBeSentToBackend']]">
                                        <div class="truncate-text">
                                            <span *ngFor="let userDisplayname of field?.properties?.source?.dataValueToDisplay; let i = index;">
                                                <span *ngIf="i === 0" matTooltip="{{ dropdownOptions[userDisplayname] }}">
                                                    {{ dropdownOptions[userDisplayname] | shortentext:field?.properties?.source?.truncateLength
                                                    }}
                                                </span>
                                                <span *ngIf="i !== 0">{{ '( ' + dropdownOptions[userDisplayname] + ' )'}}</span>
                                            </span>
                                        </div>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
    
    
                        <div *ngIf="field.dataType === 'staticDropdown'" [ngClass]="field['manageClassName']"
                            [ngStyle]="{'flex': field?.properties?.width ? '0 0 ' + field?.properties?.width : null}" class="viewSelectContainer">
                            <mat-form-field class="col-sm-12 p-0" [ngClass]="field.value ? 'valuedField' : ''">
                                <mat-label>{{field?.['displayName']}}</mat-label>
                                <mat-select [(ngModel)]="field.value" name="{{ field.columnName }}"
                                    (selectionChange)="onSelectChange(field); isFormChanged = true"
                                    [required]="field.inputValidations['required']"
                                    [disabled]="(field['properties']?.['isDisableInEdit'] && editViewClicked) || (field?.properties?.['isDisabledInAdmin'] && isAdmin3)"
                                    [panelClass]="'customCEOverlayPanelClass'" #dynamicFields>
                                    <mat-option *ngFor="let dropdownOptions of field['properties']?.['source']?.['options']"
                                        [value]="dropdownOptions[field['properties']?.['source']?.['keyToBeSentToBackend']]">
                                        <div> {{ dropdownOptions[field['properties']?.['source']?.['dataValueToDisplay']] }}
                                        </div>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
    
                        <!-- <div *ngIf="field.dataType === 'badge'" class="mt-2 badgeAlignment"
                            [ngClass]="field['manageClassName']"
                            [ngStyle]="{'flex': field?.properties?.width ? '0 0 ' + field?.properties?.width : null}">
                            <span class="mr-2"> {{field['displayName']}}: </span>
                            <label class="ct-tag-toggle">
                                <input type="checkbox" [(ngModel)]="field.value" name="{{ field.columnName }}"
                                    (change)="onBadgeChange($event, field['columnName']); isFormChanged = true"
                                    [value]="field['value'] == ('No' || false || 0 || '') ? 0 : 1">
                                <span class="slider"></span>
                            </label>
                        </div> -->
    
                        <div *ngIf="(field.dataType === 'multiselectDropdown' && !(field['properties']?.['isDisabledInAdmin'] && isAdmin3))" [ngClass]="field['manageClassName']">
                            <mat-label class="multicheckLable" *ngIf="field?.['hasLable']"> {{ field?.['hasLable'] }} <span class="text-danger" *ngIf="field.inputValidations['required']">* </span> </mat-label>
                            <label class="mb-1 d-flex multiselectLabel font-size" *ngIf="field['displayName'] && !field?.['hideLabel']">
                                {{field['displayName']}} <span *ngIf="field.inputValidations['required']" class="ml-1" style="color: #FF0000;"> * </span></label>
                            <ng-multiselect-dropdown class="col-sm-12 text-xs pr-0 p-0" [ngClass]="{'valuedDropDown': field?.value?.length}"
                                [placeholder]="field['properties']?.['placeholder']" [(ngModel)]="field.dummyValue"
                                [settings]="field['properties']?.['source']?.['dropdownSettings']"
                                [data]="field['properties']?.['source']?.['options']" [subMenuCardModel]="SubMenuCardModel"
                                [ngModelOptions]="{standalone: true}" class="col-sm-12" name="{{ field.columnName }}"
                                [disabled]="field['properties']?.['isDisableInEdit'] && editViewClicked"
                                (onSelect)="onMultiDropdownSelect($event, field)"
                                (onDeSelect)="onMultiDropdownDeSelect($event, field)"
                                (onSelectAll)="onMultiDropdownSelectAll($event, field)"
                                (onDeSelectAll)="onMultiDropdownDeSelectAll(field)">
                            </ng-multiselect-dropdown>
                        </div>
    
                        <div *ngIf="field.dataType === 'textarea'" [ngClass]="field['manageClassName']" class="viewTextAreaContainer">
                            <mat-form-field class="col-sm-12 p-0 ceTextArea" [ngClass]="field.value ? 'valuedField' : ''">
                                <mat-label> {{field['displayName']}} </mat-label>
                                <textarea #textareaElement type="textarea" trim matInput class="CETextarea"
                                    [(ngModel)]="field.value"
                                    [maxlength]="field['inputValidations']?.['maxLength']"
                                    [required]="field.inputValidations['required']"
                                    style="min-height: 45px !important;"
                                    name="{{ field.columnName }}" (input)="isFormChanged = true" rows="5" (blur)="trimField(field)" #dynamicFields>
                                        </textarea>
                            </mat-form-field>
                        </div>
    
                        <div *ngIf="field.dataType === 'chip' && field['properties']?.['source']?.['options']?.length"
                            [ngClass]="field['manageClassName']" class="mt-2">
                            <div class="col-sm-12 chipsetContainer p-2">
                                <mat-chip-list aria-label="Fish selection">
                                    <mat-chip class="mat-chip-border"
                                        (click)="appendToTextarea(chipValue, field); isFormChanged = true"
                                        value="{{chipValue}}"
                                        *ngFor="let chipValue of field['properties']?.['source']?.['options']">{{chipValue}}</mat-chip>
                                </mat-chip-list>
                            </div>
                        </div>
    
                        <div *ngIf="(field.dataType === 'autocomplete' && !(field['properties']?.['isDisabledInAdmin'] && isAdmin3))" [ngClass]="field['manageClassName']" class="viewAutoCompleteContainer">
                            <mat-form-field class="w-100 viewAutoComplete" [ngClass]="{
                                'valuedField': field?.value,
                                'saveButtonClicked': ((!field?.value && field.inputValidations['required']) && saveButtonClicked)
                                }" (click)="clearFilterClicked($event, field)">
                                <mat-label>{{ field['displayName'] }} </mat-label>
                                <em class="fa fa-chevron-down chevron-align"></em>
                                <!-- for autocomplete as we need some value we are sorting that in default value as object from there we are taking for value field. -->
                                <input #dynamicFields trim type="text" [(ngModel)]="field?.['dummyValue']['itemName']" maxlength="200"
                                    matInput [aria-label]="field['displayName']" [name]="field['columnName']" [matAutocomplete]="auto"
                                    [required]="field.inputValidations['required']"
                                    [ngModelOptions]="{standalone: true}" (input)="onAutoCompleteInputChange($event, field)"
                                    [disabled]="field['properties']?.['isDisableInEdit'] && editViewClicked">
                                <mat-autocomplete #auto="matAutocomplete" class="customCEOverlayPanelClass listingDropdownPanel" (closed)="autoCompletePanelClosed(field)">
                                    <mat-option class="mat-opt-align"
                                        *ngFor="let autoCompleteOption of  filterAutomCompleteOptions(field?.dummyValue['itemName'], field?.properties?.source?.options, field?.properties?.source?.dataValueToDisplay?.[0])"
                                        [value]="autoCompleteOption[field?.properties?.source?.dataValueToDisplay[0]]"
                                        [disabled]="field['properties']?.['isDisableInEdit'] && editViewClicked"
                                        (onSelectionChange)="onAutoCompleteSelectionChange($event, field, autoCompleteOption); isFormChanged = true" >
                                        <div class="truncate-text" [matTooltip]="optionToolTipText" (mouseenter)="enableToolTip(isOptionElipsed, 'dropdown')" #isOptionElipsed> {{ autoCompleteOption[field?.properties?.source?.dataValueToDisplay[0]] | shortentext:field?.properties?.source?.truncateLength }}
                                        </div>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
    
                        <div *ngIf="field.dataType === 'groupedTextArea'" [ngClass]="field['manageClassName']"
                            style="padding: 12px;">
                            <div class="groupedField" [ngClass]="field['groupedField'].value ? 'valuedField' : ''">
                                <mat-form-field class="col-sm-12 p-0 groupedTextArea" [ngClass]="field.value ? 'valuedField' : ''">
                                    <mat-label> {{field?.['groupedField']?.['displayName']}} </mat-label>
                                    <textarea #textareaElement type="textarea" trim matInput style="min-height: 45px !important;"
                                        [(ngModel)]="field['groupedField'].value"
                                        [maxlength]="field['groupedField']?.['inputValidations']?.['maxLength']"
                                        [required]="field['groupedField']?.inputValidations['required']"
                                        name="{{ field?.['groupedField']?.columnName }}" rows="5" class="CETextarea"
                                        (input)="isFormChanged = true; onTextAreaChange(field);" (blur)="trimField(field)">
                                            </textarea>
                                </mat-form-field>
    
                                <div class="col-sm-12 chipsetContainer p-2">
                                    <mat-label style="padding: 5px 10px; display: block; color: #00000099;">{{
                                        field['displayName']
                                        }}</mat-label>
                                    <mat-chip-list aria-label="Fish selection"
                                        *ngIf="field?.['properties']?.['source']?.['options']?.length">
                                        <mat-chip class="mat-chip-border"
                                            (click)="appendToTextarea(chipValue, field); isFormChanged = true"
                                            value="{{chipValue}}"
                                            *ngFor="let chipValue of field?.['properties']?.['source']?.['options']">{{chipValue}}</mat-chip>
                                    </mat-chip-list>
                                    <div *ngIf="!field?.['properties']?.['source']?.['options']?.length"
                                        class="noPlaceholdersContainer"> No Placeholders Found </div>
                                </div>
                            </div>
                        </div>
    
    
                        <div *ngIf="field.dataType === 'richTextArea'" [ngClass]="field['manageClassName']"
                            style="padding: 12px;">
                            <mat-label style="padding: 5px 0px; display: block; color: #000;">
                                {{ field['displayName'] }} <span style="color: #FF0000"> * </span>
                            </mat-label>
                            <div class="editor">
                                <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
                                </ngx-editor-menu>
                                <ngx-editor required class="rtf-editor" [editor]="editor" [(ngModel)]="field.value" [ngModelOptions]="{standalone: true}"
                                    [disabled]="false" [required]="field.inputValidations['required']">
                                </ngx-editor>
                            </div>
                        </div>
    
                    </ng-container>
                </div>
            </div>


        </form>
    </div>
   
</div>
<!-- View -->
<div *ngIf="!editViewClicked && !createViewClicked" class="col-sm-12" [class]="!isAdmin3?'px-2':''">
    <div class="d-flex mt-2">
       
        <div class="col-sm-6 mb-3 d-flex justify-content-between">
            <span class="header-View">
                <strong>View {{ HeaderName }} </strong>
            </span>
        </div>

        <div class="col-sm-6">
            <button type="button" class="btn btn-return float-right" (click)="backView()">Return</button>
            <button type="button" class="btn btn-return float-right mr-3" (click)="editDynamicForm()"
                *ngIf="!hideEdit">Edit</button>
        </div>
    </div>
    <div class="row view-Card">
        <ng-container *ngFor="let key of temp">
            <div [ngClass]="key.viewClassName">
                <span class="key-headers">
                    {{key['displayName']}}
                </span>
                <div class="key-values mt-1">
                    {{key['value'] | stripHtml }}
                </div>
            </div>
        </ng-container>
    </div>
</div>  