import { Component, Input, OnInit } from '@angular/core';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcCore from '@grapecity/wijmo';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
import { concatMap, of, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { DialogService } from 'src/app/core/services/dialog.service';

@Component({
  selector: 'app-observation-and-quantitation-template',
  templateUrl: './observation-and-quantitation-template.component.html',
  styleUrls: ['./observation-and-quantitation-template.component.scss']
})
export class ObservationAndQuantitationTemplateComponent implements OnInit {

  @Input() templateData: any;
  attributeList: observationAndQuantitationtemplate[] = []
  listedGridHeader: any = [];
  organizationId: number = 0;
  loggedinUserId: number = 0;
  deploymentKey: string = '';
  createBtn: boolean = false;
  editBtn: boolean = true;
  exportBtn: boolean = false;
  copyBtn: boolean = false;
  deleteBtn: boolean = true;
  sourceApp: string = '';
  saveOrEditCompleted: any;
  userSessionDetails: any;
  auditableColumns: any;
  activityEntity: any;
  loading:boolean = false;
  exportExcelFields = {
    templateName: { displayName: "Template Name", isDate: false },
    templateDescription: { displayName: "Template Description", isDate: false },
    TemplateSequence: { displayName: "Sequence", isDate: false },
    CreatedBy: { displayName: "Created By", isDate: false },
    CreatedDate: { displayName: "Created Date", isDate: true },
    ModifiedBy: { displayName: "Modified By", isDate: false },
    ModifiedDate: { displayName: "Modified Date", isDate: true },
  }

  gridData: wjcCore.CollectionView<any>;
  constructor(
    private _vitalHttpServices: VitalHttpServices,
    private _labadminService: LabadminService,
    private _templateHeaders: TemplateHeadersService,
    private _ngxService: NgxUiLoaderService,
    private _snackbar: MatSnackBar,
    private _labAdminSessionService: LabadminSessionService,
    private _dailogService: DialogService,
    private store: Store<{ breadcrum: [] }>,
    public commonService: CommonService,
    private _activityService: ActivityTrackerService
  ) {

  }

  ngOnInit(): void {
    let checkLaunchUrl = window.location.href.split('/#/')[1];
    if (checkLaunchUrl.toString().toLowerCase() !== 'home') {
      this._labAdminSessionService.getLabAdminSession
        .pipe(
          concatMap((session: any) =>
            this.store.select('breadcrum').pipe(
              take(1),
              concatMap((i: any) => {
                this.userSessionDetails = session['userDetails'];
                this.sourceApp = 'VitalDx';
                return this.commonService.getCommmonTemplateData(i[i.length-1], this.userSessionDetails,'submenu', true).then(data=>data);
              })
            )
          )
        )
        .subscribe((data) => {
          this.templateData = this._labadminService.templateData;
          this.deploymentKey = this._labadminService.deploymentKey;
          this.loggedinUserId = this.userSessionDetails.userId;
          this.organizationId = this.userSessionDetails.organizationId;
          let obj = {
            "templateId": 0,
            "OrganizationId": this.organizationId,
            "templateType": "Observation And Quantitation",
            "templateName": null,
            "templateDescription": null,
            "caseType": "ClinicalChemistry_V2",
            "TemplateSequence": null
          }
          this.AddEditDelObservationAndQuantitationTemplate(obj, "3", this.loggedinUserId)
          this.getAuditableDetails(this.templateData.menuURL)
          this.GetButtonAccess(this._labadminService.subMenuPermissions)
          this._activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': 'Lab' }, { 'key': 'Lab Management', 'value': this.templateData['menuURL'] }] })
          this._activityService.getActivitySession.subscribe(res => this.activityEntity = res);
          this.activityEntity.entityId = '';
          this._activityService.setActivitySession(this.activityEntity);
        });
    }
    else {
      this.setLocalVariables()
      let obj = {
        "templateId": 0,
        "OrganizationId": this.organizationId,
        "templateType": "Observation And Quantitation",
        "templateName": null,
        "templateDescription": null,
        "caseType": "ClinicalChemistry_V2",
        "TemplateSequence": null
      }
      this.AddEditDelObservationAndQuantitationTemplate(obj, "3", this.loggedinUserId)
      this.GetButtondetails();
      this.getAuditableDetails(this.templateData.menuURL)
      this._activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': this.templateData['menuURL'] }] })
      this._activityService.getActivitySession.subscribe(res => this.activityEntity = res);
    }
  }

  GetButtondetails() {
    this.GetButtonAccess(this._vitalHttpServices.CasemenuAction);
  }

  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == "Templates").SubMenu.find(va => va.URL == 'Observation & Quantitation Templates')["ActionButton"]
    }
    else {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == "Masters")['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    }
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      if (seletedMenuPermissions[i].Button === "Export") {
        this.exportBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
      else if (seletedMenuPermissions[i].Button === "Create") {
        this.createBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
      else if (seletedMenuPermissions[i].Button === "Edit") {
        this.editBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
      else if (seletedMenuPermissions[i].Button === "Delete") {
        this.deleteBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
    }
  }

  setLocalVariables() {
    this.organizationId = Number(sessionStorage.getItem('org_id'))
    this.loggedinUserId = Number(sessionStorage.getItem('Userid'))
    this.deploymentKey = sessionStorage.getItem('DeploymentKey')

  }

  AddEditDelObservationAndQuantitationTemplate(TemplateDetails: any, iOper: string, loggedinUserID: number, event: any = {}) {
    this._ngxService.start();
    let sessionId = this.commonService.generateGuid();
    if (iOper == '3') this.loading = true;
  
    this._labadminService.AddEditDelObservationAndQuantitationTemplate(TemplateDetails, iOper, loggedinUserID, this.deploymentKey).subscribe({
      next: (result: ObservationAndQuantitationtemplateDetails) => {
        this._ngxService.stop()
         if (iOper == '3') this.loading = false;
      
        if (result.AttributeList) {
          this.attributeList = result.AttributeList
        }
        this.listData(this.attributeList)
        if (result.Message) {
          if (!result.Message.includes('Duplicate')) {
            if (event?.newData?.Action === 'Create') {
              const seq = this.attributeList.filter((i)=>i.templateName ==TemplateDetails.templateName)
              event.newData = {
                ...event.newData,
                TemplateSequence: seq[0]?.TemplateSequence
            };
              this.commonService.createActivityObject(seq[0].templateId,event.newData.templateName, this.templateData.menuURL, 'Create', event.newData, {}, sessionId,this.auditableColumns);
            }
            else if (event?.newData?.Action === 'Edit') {
              this.commonService.createActivityObject(event.oldData.templateId,event.newData.templateName, this.templateData.menuURL, 'Edit', event.newData, event.oldData, sessionId,this.auditableColumns);
            }
            else if (event?.action === "delete")
              this.commonService.createActivityObject(event.rowData.templateId,event.rowData.templateName, this.templateData.menuURL, 'Delete', {}, event.rowData, sessionId,this.auditableColumns);


            this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            this._snackbar.open(result.Message, "Close")
          }
          else {
            this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
            this._snackbar.open(result.Message, "Close")
          }
        }
      },
      error: (err) => {
        console.error(err)
        this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
        this._snackbar.open('Something went wrong.', "Close")
        this._ngxService.stop()
        this.loading = false;
      }
    })

  }

  listData(templateData: observationAndQuantitationtemplate[]) {
    let primary = {}
    let headerData = this._templateHeaders.templateList.find(i => i.templateName == "Observation & Quantitation Templates")
    if (headerData) {
      if (headerData) {
        this.listedGridHeader = headerData.headers
      }
    }
    let gridarray = [];
    let gridheader = ['templateId', 'templateName', 'templateDescription', 'TemplateSequence', 'CreatedBy', 'CreatedDate', 'ModifiedBy', 'ModifiedDate']
    for (let i = 0; i < templateData?.length; i++) {
      primary = {};
      for (let [key, value] of Object.entries(templateData[i])) {
        if (key == 'templateName' || key == 'templateDescription') {
          if (value == '' || value == null) {
            value = 'Not Specified';
            primary[key] = value;
          }
          primary[key] = value;
        }
        for (let j = 0; j < gridheader.length; j++) {
          if (key === gridheader[j]) {
            primary[key] = value;
          }
        }
      }
      gridarray.push(primary);

    }
    gridarray.sort((a, b) => a.templateName.localeCompare(b.templateName))
    this.gridData = new CollectionView(gridarray)
  }

  newDeleteMethod(event: any) {
    this._ngxService.stop();
    this._dailogService.openLabadminConfimationdialog(
      '',
      'Are you sure you want to delete this record?',
      'Delete',
      'Cancel'
    ).afterClosed().subscribe((result) => {
      if (result) {
        let obj = {
          "templateId": event.rowData.templateId,
          "OrganizationId": this.organizationId,
          "templateType": "Observation And Quantitation",
          "templateName": null,
          "templateDescription": null,
          "caseType": "ClinicalChemistry_V2",
          "TemplateSequence": null
        }
        this.AddEditDelObservationAndQuantitationTemplate(obj, "2", this.loggedinUserId, event)
      }
      else return

    })
  }

  recieveObjFromListing(event: any) {
    let obj = {}
    let ioper = ''

    if (event.newData.Action == 'Edit') {
      obj = {
        "templateId": event.oldData.templateId,
        "OrganizationId": event.newData.OrganizationId,
        "templateType": "Observation And Quantitation",
        "templateName": event.newData.templateName,
        "templateDescription": event.newData.templateDescription,
        "caseType": "ClinicalChemistry_V2",
        "TemplateSequence": event.newData.TemplateSequence ? event.newData.TemplateSequence : null
      }
      ioper = '1'
    }
    else {
      obj = {
        "templateId": 0,
        "OrganizationId": event.newData.OrganizationId,
        "templateType": "Observation And Quantitation",
        "templateName": event.newData.templateName,
        "templateDescription": event.newData.templateDescription,
        "caseType": "ClinicalChemistry_V2",
        "TemplateSequence": null
      }
      ioper = '0'
    }
    this.AddEditDelObservationAndQuantitationTemplate(obj, ioper, this.loggedinUserId, event)

  }

  getAuditableDetails(location: any) {
    this._vitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }

}


export interface observationAndQuantitationtemplate {
  templateId: number,
  OrganizationId: number,
  templateType: string,
  templateName: string,
  templateDescription: string,
  caseType: string,
  IsActive: boolean,
  CreatedBy: string,
  ModifiedBy: string,
  CreatedDate: string,
  ModifiedDate: string,
  TemplateSequence: number
}
export interface ObservationAndQuantitationtemplateDetails {
  AttributeList: observationAndQuantitationtemplate[],
  Message: string
}
