import { Clipboard } from '@angular/cdk/clipboard';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateAdapter, MatOption, ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import { VitalAdminTabService } from 'src/app/client/tab.service';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { UserSession } from 'src/app/core/services/user.session';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { CommonService } from '../../../core/services/commonservices';
import { MainCardModel } from '../../DbModel/MainCardModel';
import * as XLSX from 'xlsx';

export interface Task {
  color: ThemePalette;
}
@Component({
  selector: 'app-cases',
  templateUrl: './cases.component.html',
  styleUrls: ['./cases.component.scss'],
  providers: [DatePipe]
})

export class CasesComponent implements OnInit {
  task: Task = {
    color: 'primary',
  };

  @ViewChild('matCaseType') matCaseType: MatSelect;
  @ViewChild('matOrderingFacility') matOrderingFacility: MatSelect;
  @ViewChild('matReadingLoc') matReadingLoc: MatSelect;
  @ViewChild('matSendOut') matSendout: MatSelect;
  @ViewChild('matOrderingPhysician') matOrderingPhysician: MatSelect;
  @ViewChild('matCaseStatus') matCaseStatus: MatSelect;
  @ViewChild('matServiceType') matServiceType: MatSelect;
  @ViewChild('matLab ') matLab: MatSelect;
  @ViewChild('startDate') startDateRef: ElementRef;
  @ViewChild('endDate') endDateRef: ElementRef;
  @ViewChild('matPatientDOB', { read: MatInput }) matPatientDOB: MatInput;
  public moreHide: boolean = true;
  public date: Date;
  tempExport: any[];

  columns;
  gridDisplay: boolean;
  gridEmpty: boolean;
  gridData: wjcCore.CollectionView<any>;
  gridwidth: number;
  showPaginationMainGrid: boolean;
  Data: any;

  public gridheader = [
    'ModifiedDate',
    'ModifiedBy',
    'CreatedDate',
    'CreatedBy',
    'Status',
    'Sendoutdate',
    'Reported_date',
    'Received_date',
    'Collection_date',
    'Pathologist_Name',
    'Ordering_Physician',
    'Ordering_Facility',
    'Patient_Name',
    'Service_Type',
    'Accession_Number',
    'Requisition_Number',
    'Case_Type',
    'Case_ID',
  ];
  @Input()
  public templateData: any;
  enddate: string;
  startdate: string;
  events: any;
  dropdownList = [];
  dropdownSettings: IDropdownSettings = {};
  StatusList: any[];
  CaseTypeList: any[];
  error: string;
  AccessionNumber: boolean = false;
  RequisitionNumber: boolean = false;
  date1: Date;
  date2: Date;
  CollectionDate: boolean = false;
  ReportedDate: boolean;
  ReceivedDate: boolean;
  SendOutDate: boolean;
  PatientName: boolean;
  EMRNumber: boolean;
  LabCaseNumber: boolean;
  OrderingFacility: any[];
  PathologistName: boolean;
  Accession: string = '';
  EMR: string = '';
  OrderingPhysician: boolean;
  Pathologist: string = '';
  Service: string = '';
  SendOut: string = '';
  LabCase: string = '';
  PathologistList: any[];
  OrderingPhysicianList: any[];
  CytotechnologistList: any[];
  SendoutlaboratoryList: any[];
  filteredOfPathologist: Observable<string[]>;
  filteredFacility: Observable<string[]>;
  filteredPhysician: Observable<string[]>;
  filteredCytotechnologist: Observable<string[]>;
  filteredSendoutlaboratory: Observable<string[]>;
  filteredOrderingPhycisian: Observable<string[]>;
  filterLaboratories: Observable<string[]>;
  filterFacilities: Observable<string[]>;
  filterReadingLocation: Observable<string[]>;
  labAccounnt = new FormControl([]);
  facilityAccount = new FormControl([]);
  PhysicianControl = new FormControl('');
  FacilityControl = new FormControl('');
  PathologistControl = new FormControl('');
  CytotechnologistControl = new FormControl('');
  SendoutlaboratoryControl = new FormControl([]);
  frmreadingLocation = new FormControl([]);
  orderingPhysician = new FormControl('');
  allSelected = false;
  allSelectedOf = false;
  allSelectedReadingloc = false;
  allSelectedOp = false;
  allSelectedCasestatus = false;
  allSelectedServiceType = false;
  btndisabled: boolean = false;
  btnExport: boolean;
  btnGetData: boolean;
  data: any;
  CaseStatus: any;
  SarviceType: any;
  labname: any = [];
  allSelectedLabName: boolean;
  gridColWidth: number;
  readingLocation: any;
  tempLabName: any;
  tempOrderingFacility: any;
  tempreadingLocation: any;
  tempOrderingPhysicianList: any[];
  tempPathologistList: any;
  tempCytotechnologistList: any;
  pathUserId: any;
  cytotechnicianId: any;
  sendOutLabId: any;
  locationContext: string;
  labId: any = [];
  ofLocationId: any;
  ReadingLocId: any;
  AutoLabname: any;
  autoFacility: any;
  autoReadingLocation: any;
  autoSendOutLabs: any;
  OrderingPhysicianId: any;
  autoPhycision: any;
  autoPathologist: any;
  autoCytoTech: any;
  contextvalue: string;

  constructor(public vitalHttpServices: VitalHttpServices,
    private clipboard: Clipboard,
    private dialog: MatDialog,
    public DataShare: DataShareService,
    private _snackbar: MatSnackBar,
    private ngxService: NgxUiLoaderService, public vaservices: VitalHttpServices,
    private commonService: CommonService, private VitalAdminTabService: VitalAdminTabService, public MainCardModel: MainCardModel, private usersession: UserSession, private activatedroute: ActivatedRoute, private router: Router,
    public _snackBar: MatSnackBar, public datepipe: DatePipe, private dateAdapter: DateAdapter<Date>) {
    this.columns = [
      new ColDef('ModifiedDate', 'ModifiedDate'),
      new ColDef('ModifiedBy', 'ModifiedBy'),
      new ColDef('CreatedDate', 'CreatedDate'),
      new ColDef('CreatedBy', 'CreatedBy'),
      new ColDef('Status', 'Status'),
      new ColDef('Sendoutdate', 'Sendout Date'),
      new ColDef('Reported_date', 'Reported Date'),
      new ColDef('Received_date', 'Received Date'),
      new ColDef('Collection_date', 'Collection Date'),
      new ColDef('Pathologist_Name', 'Pathologist Name'),
      new ColDef('Ordering_Physician', 'Ordering Physician'),
      new ColDef('Ordering_Facility', 'Ordering Facility'),
      new ColDef('Patient_Name', 'Patient Name'),
      new ColDef('Service_Type', 'Service Type'),
      new ColDef('Case_Type', 'Case Type'),
      new ColDef('Accession_Number', 'Accession Number'),
      new ColDef('Requisition_Number', 'Requisition Number'),
      new ColDef('Case_ID', 'Case ID'),
    ];
  }
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  range1 = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  range2 = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  range3 = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  public GetQuery(keyword: string) {
    let query: string = "";
    let mainQueryIndex: number = this.vaservices.mainQueryList.findIndex(mainQuery => mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase());
    if (mainQueryIndex > -1)
      query = this.vaservices.mainQueryList[mainQueryIndex].Query;
    return query;
  }
  ngOnInit() {
    this.getButtondetails();
     this.contextvalue = sessionStorage.getItem("context") == 'Laboratory' ? 'lab' : sessionStorage.getItem("context") == 'Facility' ? 'Facility' : sessionStorage.getItem("context") == 'Send Out' ? 'sendout' : sessionStorage.getItem("context") == 'EPG' ? 'EPG' : ''
    this.locationContext = sessionStorage.getItem('context');
    let dialogRef = this.dialog.open(ConfirmComponent, {
      disableClose: true,
      width: '500px',
      data: { header: "Alert !", message: "Exporting cases during working hours, you don't get proper user experience.", alert: "", continue: "Continue", cancel: "dontshow" }
    });
    return dialogRef.afterClosed().toPromise().then((result) => {
      if (result) {
        this.getListDistinctCases()
        // this.getListCases(); // After click on get button only the data should display. disabled to get the data
        this.settingInitData();
      }

    })
  }

  settingInitData() {
    if (this.locationContext == "Laboratory") {
      this.labAccounnt.setValue([Number(sessionStorage.getItem('Locationid'))]);

      this.selectedLab([Number(sessionStorage.getItem('Locationid'))]) ;
    }
    else if (this.locationContext == "Send Out") {

      this.selectedSendOutLab([Number(sessionStorage.getItem('Locationid'))]);
      this.SendoutlaboratoryControl.setValue([Number(sessionStorage.getItem('Locationid'))]);
    }
    else if (this.locationContext == "Facility") {

      this.selectedOf([Number(sessionStorage.getItem('Locationid'))]);
      this.facilityAccount.setValue([Number(sessionStorage.getItem('Locationid'))]);
    }
    else if (this.locationContext == "EPG") {

      this.selectedReadingLoc([Number(sessionStorage.getItem('Locationid'))]);
      this.frmreadingLocation.setValue([Number(sessionStorage.getItem('Locationid'))]);
    }
  }
  //get Pathologist
  public filterByPathologist(name) {
    return this.autoPathologist && this.autoPathologist.filter(
      (va) => va.UserName != null && va.UserName.toLowerCase().includes(name.toLowerCase()) || va.UserId == name);
  }


  //Cytotechnologist
  public filterByCytotechnologist(name) {
    return this.autoCytoTech && this.autoCytoTech.filter(
      (va) => va.UserName != null && va.UserName.toLowerCase().includes(name.toLowerCase()) || va.UserId == name);
  }

  //Sendoutlaboratory
  public filterBySendoutlaboratory(name) {
    return this.autoSendOutLabs && this.autoSendOutLabs.filter(
      (va) => va.AccountName != null && va.AccountName.toLowerCase().includes(name.toLowerCase()) || va.AccountId == name);

  }
  public filterBylaboratory(name) {
    return this.AutoLabname && this.AutoLabname.filter(
      (va) => va.AccountName != null && va.AccountName.toLowerCase().includes(name.toLowerCase()) || va.AccountId == name);

  }
  public filterByFacility(name) {
    return this.autoFacility && this.autoFacility.filter(
      (va) => va.AccountName != null && va.AccountName.toLowerCase().includes(name.toLowerCase()) || va.AccountId == name);
  }
  public filterByReadingLocation(name) {
    return this.autoReadingLocation && this.autoReadingLocation.filter(
      (va) => va.AccountName != null && va.AccountName.toLowerCase().includes(name.toLowerCase()) || va.AccountId == name);
  }
  public filterByphycisian(name) {
    return this.autoPhycision && this.autoPhycision.filter(
      (va) => va.UserName != null && va.UserName.toLowerCase().includes(name.toLowerCase()) || va.UserId == name);
  }


  //get the list of distinct status,casetype
  getListDistinctCases() {
    this.OrderingPhysicianList = [];
    this.tempOrderingPhysicianList = [];
    this.PathologistList = [];
    this.tempPathologistList = [];
    this.tempCytotechnologistList = [];
    this.CytotechnologistList = [];
    this.ngxService.start();
    let context = sessionStorage.getItem("context") == 'Laboratory' ? 'lab' : sessionStorage.getItem("context") == 'Facility' ? 'Facility' : sessionStorage.getItem("context") == 'Send Out' ? 'sendout' : sessionStorage.getItem("context") == 'EPG' ? 'EPG' : ''
    let orgid = sessionStorage.getItem('org_id');
    let deploymentKey = sessionStorage.getItem('DeploymentKey')
    this.vitalHttpServices.getCasesSearchDropdownDetails(context, orgid, deploymentKey).subscribe(casedata => {
      this.ngxService.stop();
      if (!casedata.errors) {
        this.CaseTypeList = casedata[0].CaseTypeList;
        //OrderingFacility
        this.OrderingFacility = casedata[0].FacilityData;
        this.tempOrderingFacility = casedata[0].FacilityData;
        this.readingLocation = casedata[0].ReadingLocation;
        this.tempreadingLocation = casedata[0].ReadingLocation;
        //let ofdata=casedata.data.submenuData2;
        //PathologistList
        // let data1 = casedata.data.submenuData1;
        if(casedata[0].ReadingLocation.length>0){
          casedata[0].ReadingLocation && casedata[0].ReadingLocation.forEach(element => {
            if (element.PathologistList && element.PathologistList.length > 0) {
              element.PathologistList.forEach(val => {
                this.PathologistList.push(val);
                this.tempPathologistList.push(val)
              });
              if(casedata[0].ReadingLocation.length == 1){
                this.allSelectedReadingloc = true;
              }


            }
            if (element.CytoTechnologistList && element.CytoTechnologistList.length > 0) {
              element.CytoTechnologistList.forEach(val => {
                this.CytotechnologistList.push(val);
                this.tempCytotechnologistList.push(val)
              });
            }
          });
        }


        // OrderingPhysicianList
        //let data2 = casedata.data.submenuData3;
        if(casedata[0].FacilityData.length > 0) {
        casedata[0].FacilityData && casedata[0].FacilityData.forEach(element => {
          if (element.PhysicianList && element.PhysicianList.length > 0) {
            element.PhysicianList.forEach(val => {
              this.OrderingPhysicianList.push(val);
              this.tempOrderingPhysicianList.push(val)
            });
            if(this.OrderingFacility.length == 1){
              this.allSelectedOf = true;
            }
          }
        });
        }

        //CytotechnologistList
        // let data3 = casedata.data.submenuData5;
        //SendoutlaboratoryList
        this.SendoutlaboratoryList = casedata[0].SendOutLabList;
        // caseStatus
        this.CaseStatus = casedata[0].CaseStatusList;
        //ServiceType
        this.SarviceType = casedata[0].ServiceTypeList;
        //labName
        if(casedata[0].LabData.length > 0)
        {
          this.labname = casedata[0].LabData;
          this.labname.forEach(ele => {
            if (ele.LabAccountId == sessionStorage.getItem('Locationid')) {
              this.matLab.options.forEach(item => {
                if (item.value == ele.LabAccountId) {
                  item.select();
                }
              });
              if(this.labname.length == 1){
                this.allSelectedLabName = true;
              }
            }
          })
        }


        this.tempLabName = casedata[0].LabData

        // //PathologistList
        // this.PathologistList = [...new Map(data1.map((item) => [item['Pathologist_Name'], item['Pathologist_Name']])).values(),];
        // this.PathologistList = this.PathologistList.filter((element): element is string => {
        //   return element != null || element != undefined;
        // });
        // //to get in auto filed


        // //Get Cytotechnologist List
        // this.CytotechnologistList = [...new Map(data3.map((item) => [item['Cytotechnologist'], item['Cytotechnologist']])).values(),];
        this.CytotechnologistList = this.CytotechnologistList.filter((element): element is string => {
          return element != null || element != undefined;
        });

        // // Get Sendoutlaboratory List
        // this.SendoutlaboratoryList = [...new Map(data4.map((item) => [item['SendOutAccountName'], item['SendOutAccountName']])).values(),];
        //this.tempSendoutlaboratoryList =[...this.SendoutlaboratoryList];




      }
    }, (error) => {
      this.ngxService.stop();
      console.log(error);
    });
  }
  //getting list of cases based on the accountid
  getListCases() {
    this.enddate = '';
    this.enddate = this.datepipe.transform(new Date(), 'MM/dd/yyyy');
    const sevenDaysAgo: Date = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
    this.startdate = this.datepipe.transform(sevenDaysAgo, 'MM/dd/yyyy');
    let id = sessionStorage.getItem('AccountID');
    let query = this.GetQuery('Exportcasesfiliter');
    let queryString = "collectiondateTofilter >= \"" + this.startdate + " 00:00:00\" and  collectiondateTofilter <= \"" + this.enddate + " 00:00:00\"";
    let queryVariable = { "accid": id.toString(), "filterParameter": queryString };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult).subscribe((casedata) => {
      this.ngxService.stop();
      if (!casedata.errors) {
        this.addGridData(casedata);
      }
    }, (error) => {
      this.ngxService.stop();
      console.log(error);
    });

  }
  //#region listing cases based on accid
  //added the data in grid
  addGridData(data) {
    if (data != null) {
      this.AccessionNumber = false;
      this.CollectionDate = false;
      this.ReceivedDate = false;
      this.SendOutDate = false;
      this.ReportedDate = false;
      this.RequisitionNumber = false;
      this.PatientName = false;
      this.EMRNumber = false;
      this.LabCaseNumber = false;
      this.Data = data.data.submenuData;
      let gridarray = [];
      let primary = {};
      if (this.Data != null) {
        if (this.Data.length > 0) {
          for (let i = 0; i < this.Data.length; i++) {
            primary = {};
            for (let [key, value] of Object.entries(this.Data[i])) {
              for (let j = 0; j < this.gridheader.length; j++) {
                if (key === this.gridheader[j]) {
                  if (value == '' || value == null || value == undefined) {
                    value = 'Not Specified';
                    primary[key] = value;
                  } else {
                    primary[key] = value;
                  }
                }
              }
            }
            gridarray.push(primary);
            this.gridDisplay = true;
            this.gridEmpty = false;
          }
        } else {
          this.gridData = new CollectionView(gridarray, { pageSize: 10 });
          this.gridwidth = 0;
          this.gridDisplay = false;
          this.gridEmpty = true;
        }
        if (gridarray.length > 10) {
          this.showPaginationMainGrid = true;
        } else {
          this.showPaginationMainGrid = false;
        }
        this.gridData = new CollectionView(gridarray, { pageSize: 10 });
        this.gridwidth = 500 * ((this.gridheader.length - 1) + 37);
        this.gridColWidth = this.gridwidth / (this.gridheader.length - 1)
        if (this.gridwidth > 1300) {
          this.gridwidth = 1300;
        }
      }
      else {
        this.gridEmpty = true;
        this.gridDisplay = false;
      }
    }
    else {
      this.gridEmpty = true;
      this.gridDisplay = false;
    }
  }
  //Tool tip for Grid
  initGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }

  //Export data
  exportExcel(flex) {
    let file_name = sessionStorage.getItem('locationName');
    if (file_name === 'undefined' || file_name === null) {
      file_name = sessionStorage.getItem('Org_Name');
      if (file_name === 'undefined') {
        file_name = sessionStorage.getItem('LabName');
      }
      if (file_name == 'undefined') {
        file_name = sessionStorage.getItem('FlabName');
      }
    }
    var filename = 'Cases_';

    if (file_name != 'undefined' && file_name) {
      filename = filename + file_name;
      if (filename.length > 31) {
        let fileName = filename.split('_')[0] + '_';
        let orgName = filename.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
        if (orgName.length > 31) {
          fileName = filename.split('_')[0].replace(' ', '') + '_';
          filename = fileName + orgName + '_';
        }
        else {
          filename = fileName + orgName + '_';
        }
      }
      else {
        filename = filename + '_';
      }
    }

    filename = filename + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    if (filename.length > 31) {
      let fileName = filename.split('_')[0] + '_';
      if(fileName.length>31){
        filename = fileName.split('_')[0] +'.xlsx';
      }else{
         if(this.templateData.secondarykeys){
        filename = fileName  + this.templateData.secondarykeys.OrganizationId.toString()+'.xlsx';
      }
      else{
        filename = fileName.split('_')[0] +'.xlsx';
      }
    }
    }
    const view = flex.collectionView;
    let oldPgSize = view.pageSize;
    flex.beginUpdate();
    view.pageSize = 0;
    let excel = [];
    flex.rows.find(e => {
      excel.push(e._data);
    });
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'Cases');
    XLSX.writeFile(wb, filename);
    view.pageSize = oldPgSize;
    flex.endUpdate();
  }
  //take a start date
  addEvent(start) {
    this.date1 = start
    this.startdate = this.datepipe.transform(start, 'MM/dd/yyyy');
    return this.startdate;
  }
  //take a end date
  addEvent2(end) {
    this.date2 = end;
    this.enddate = this.datepipe.transform(end, 'MM/dd/yyyy');
    return this.enddate;
  }
  //find the difference of date
  calculateDiff() {
    let days = Math.floor((this.date2.getTime() - this.date1.getTime()) / 1000 / 60 / 60 / 24);
    return days;
  }
  //All select and unselect
  toggleAllSelection() {
    if (this.allSelected) {
      this.matCaseType.options.forEach((item: MatOption) => item.select());
    } else {
      this.matCaseType.options.forEach((item: MatOption) => item.deselect());
    }
  }
  //check all the check box
  optionClick() {
    let newStatus = true;
    this.matCaseType.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }
  //Ordering Facility
  toggleAllSelectionOf() {
    this.ofLocationId = [];
    if (this.allSelectedOf) {
      this.matOrderingFacility.options.forEach((item: MatOption) => item.select());
      this.OrderingFacility.forEach(va => this.ofLocationId.push(va.OFAccountId))
    } else {
      this.matOrderingFacility.options.forEach((item: MatOption) => item.deselect());
    }
    this.OrderingPhysicianList = this.tempOrderingPhysicianList;
  }
  //epg/sendout
  toggleAllSelectionReading() {
    this.sendOutLabId = [];
    if (this.allSelectedReadingloc) {
      this.matReadingLoc.options.forEach((item: MatOption) => item.select());
      this.SendoutlaboratoryList.forEach(va => this.sendOutLabId(va.SendOutAccountId))
    } else {
      this.matReadingLoc.options.forEach((item: MatOption) => item.deselect());
    }
    this.PathologistList = this.tempPathologistList;
    this.CytotechnologistList = this.tempCytotechnologistList;
  }


  //Ordering Physician
  toggleAllSelectionOP() {
    if (this.allSelectedOp) {
      this.matOrderingPhysician.options.forEach((item: MatOption) => item.select());
    } else {
      this.matOrderingPhysician.options.forEach((item: MatOption) => item.deselect());
    }
  }
  optionClickOP() {
    let newStatus3 = true;
    this.matOrderingPhysician.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus3 = false;
      }
    });
    this.allSelectedOp = newStatus3;
  }
  //CaseStatus
  toggleAllSelectionCaseStatus() {
    if (this.allSelectedCasestatus) {
      this.matCaseStatus.options.forEach((item: MatOption) => item.select());
    } else {
      this.matCaseStatus.options.forEach((item: MatOption) => item.deselect());
    }
  }
  optionClickCaseStatus() {
    let newStatus3 = true;
    this.matCaseStatus.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus3 = false;
      }
    });
    this.allSelectedCasestatus = newStatus3;
  }

  //Service Type
  toggleAllSelectionServiceType() {
    if (this.allSelectedServiceType) {
      this.matServiceType.options.forEach((item: MatOption) => item.select());
    } else {
      this.matServiceType.options.forEach((item: MatOption) => item.deselect());
    }
  }
  optionClickServiceType() {
    let newStatus3 = true;
    this.matServiceType.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus3 = false;
      }
    });
    this.allSelectedServiceType = newStatus3;
  }
  //lab name
   toggleAllLabName() {
    this.labId = [];
    if (this.allSelectedLabName) {
      this.matLab.options.forEach((item: MatOption) => item.select());
      this.labname.forEach(va => this.labId.push(va.LabAccountId))
    } else {
      this.matLab.options.forEach((item: MatOption) => item.deselect());
    }
  }
  optionClickLabName() {
    this.labId = [];
    let newStatus3 = true;
    this.matLab.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus3 = false;
      }
      else {
        this.labId.push(item.value)
      }
    });
    this.allSelectedLabName = newStatus3;
    if(this.matLab.value.length==0  ) {this.labId=[]}
   else{
    this.labId=[];
    for (let i = 0; i < this.matLab.value.length; i++) {
      this.labId.push(this.matLab.value[i]);
      }
    }
  }
  //clear data on button click
  getClear(matCaseType, matPathologist, matCaseId, matAccession, matRequistion, matEMR, matPatientName, matSendoutlaboratory, matCytotechnologiest, matCaseStatus, matServiceType, matLab) {
    this.gridEmpty = true;
    this.btndisabled = false;
    this.allSelected = false;
    this.allSelectedOp = false;
    this.allSelectedServiceType = false;
    this.allSelectedServiceType = false;
    this.allSelectedLabName = false;
    this.allSelectedOf = false;
    this.allSelectedReadingloc = false;
    this.gridDisplay = false;
    this.CollectionDate = false;
    this.ReceivedDate = false;
    this.ReportedDate = false;
    this.SendOutDate = false;
    matCaseStatus.value = '';
    matServiceType.value = '';
    matCaseType.value = '';
    matPathologist.value = '';
    matCaseId.value = '';
    matAccession.value = '';
    matLab.value = '';
    matRequistion.value = '';
    matEMR.value = '';
    matPatientName.value = '';
    this.labId=[];
    this.range.reset();
    this.range1.reset();
    this.range2.reset();
    this.range3.reset();
    this.matPatientDOB.value = '';
    // this.getListCases();
    this.PathologistControl.setValue(null);
    this.SendoutlaboratoryControl.setValue(null);
    this.CytotechnologistControl.setValue(null);
    this.facilityAccount.setValue(null);
    this.ofLocationId=[];
    this.OrderingPhysicianId=''
    this.pathUserId=''
    this.ReadingLocId=''
    this.sendOutLabId=''
    this.cytotechnicianId=''
    this.PhysicianControl.setValue(null);
    this.FacilityControl.setValue(null);
    this.frmreadingLocation.setValue(null);
    this.orderingPhysician.setValue(null);
    let context = sessionStorage.getItem("context") == 'Laboratory' ? 'lab' : sessionStorage.getItem("context") == 'Facility' ? 'Facility' : sessionStorage.getItem("context") == 'Send Out' ? 'sendout' : sessionStorage.getItem("context") == 'EPG' ? 'EPG' : ''
    if(context!='lab')
    {
      this.labAccounnt.setValue(null);
    }
    this.settingInitData();
  }
  //fetch data on button click
  getData(matCaseType, matPathologist, matcollectiondate, matReportdate, matReceiveddate, matSendOutDate, matCaseId,
    matAccession, matRequistion, matEMR, matPatientName, matPatientDOB, matSendoutlaboratory,
    matCytotechnologiest, matCaseStatus, matServiceType, matLab) {
   // this.labAccounnt
    this.locationContext = sessionStorage.getItem('context');
    let dialogRef = this.dialog.open(ConfirmComponent, {
      disableClose: true,
      width: '500px',
      data: { header: "Alert !", message: "Exporting cases during working hours, you don't get proper user experience.", alert: "", continue: "Continue", cancel: "Cancel" }
    });
    return dialogRef.afterClosed().toPromise().then((result) => {
      if (result) {
        let tempCollectionDateFlag = false;
        let tempReceivedDatelag = false;
        let tempSendOutDateFlag = false;
        let tempReportdateFlag = false;

        if (matcollectiondate.value.start != null || matcollectiondate.value.end != null ||
          matReceiveddate.value.start != null || matReceiveddate.value.end != null ||
          matReportdate.value.start != null || matReportdate.value.end != null ||
          matSendOutDate.value.start != null || matSendOutDate.value.end != null) {

          //collection date
          if (matcollectiondate.value.start != null && matcollectiondate.value.end == null) {
            tempCollectionDateFlag = false;
          }
          else if (matcollectiondate.value.start == null && matcollectiondate.value.end == null) {
            tempCollectionDateFlag = true;
          }
          else {
            tempCollectionDateFlag = true;
          }
          //received date
          if (matReceiveddate.value.start != null && matReceiveddate.value.end == null) {
            tempReceivedDatelag = false;
          }
          else if (matReceiveddate.value.start == null && matReceiveddate.value.end == null) {
            tempReceivedDatelag = true;
          }
          else {
            tempReceivedDatelag = true;
          }
          //send out date
          if (matSendOutDate.value.start != null && matSendOutDate.value.end == null) {
            tempSendOutDateFlag = false;
          }
          else if (matSendOutDate.value.start == null && matSendOutDate.value.end == null) {
            tempSendOutDateFlag = true;
          }
          else {
            tempSendOutDateFlag = true;
          }
          //report date
          if (matReportdate.value.start != null && matReportdate.value.end == null) {
            tempReportdateFlag = false;
          }
          else if (matReportdate.value.start == null && matReportdate.value.end == null) {
            tempReportdateFlag = true;
          }
          else {
            tempReportdateFlag = true;
          }

          if (!tempCollectionDateFlag || !tempReceivedDatelag ||
            !tempSendOutDateFlag || !tempReportdateFlag) {
            this.funcSetFalseValidation();
            return;
          }
        }
        //other than date fields
        if ((matCaseType.value != "" && matCaseType.value != undefined) || matPathologist.value != "" || matCaseId.value != "" || matAccession.value != "" ||
          matRequistion.value != "" || matEMR.value != "" ||
          matPatientName.value != "" || matPatientDOB.value != "" || matSendoutlaboratory.value != "" ||
          matCytotechnologiest.value != "" || (matCaseStatus.value != "" && matCaseStatus.value != undefined) || (matServiceType.value != "" && matServiceType.value != undefined)
          || (matLab.value != "")||(this.ofLocationId.value!="")) {
          this.funcGetDataOnValidation(matCaseType, matcollectiondate, matReportdate, matReceiveddate, matSendOutDate, matCaseId,
            matAccession, matRequistion, matEMR, matPatientName, matPatientDOB,
            matCaseStatus, matServiceType);
        }
        else {
          if (tempCollectionDateFlag || tempReceivedDatelag ||
            tempSendOutDateFlag || tempReportdateFlag) {
            this.funcGetDataOnValidation(matCaseType,
              matcollectiondate, matReportdate, matReceiveddate, matSendOutDate, matCaseId,
              matAccession, matRequistion, matEMR, matPatientName, matPatientDOB,
              matCaseStatus, matServiceType);
          }
          else {
            this.funcSetFalseValidation();
            return;
          }

          this.getListDistinctCases()
          this.getListCases();
          this.settingInitData();
        }
      }
    })
  }

  //check dates



  funcGetDataOnValidation(matCaseType,
    matcollectiondate, matReportdate, matReceiveddate, matSendOutDate, matCaseId,
    matAccession, matRequistion, matEMR, matPatientName, matPatientDOB,
    matCaseStatus, matServiceType) {
      let enddate = this.datepipe.transform(new Date(), 'MM/dd/yyyy');
      const sevenDaysAgo: Date = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
      let startdate = this.datepipe.transform(sevenDaysAgo, 'MM/dd/yyyy');
    this.btndisabled = false;
    if (matCaseType.value === undefined) {
      matCaseType.value = '';
    }

    if (matCaseStatus.value === undefined) {
      matCaseStatus.value = '';
    }
    if (matServiceType.value === undefined) {
      matServiceType.value = '';
    }
    let id = sessionStorage.getItem('AccountID');
    let queryString = null;
    let CaseID = matCaseId.value != '' ? matCaseId.value : null;
    let CaseType = matCaseType.value != '' ? matCaseType.value.join(",") : null;
    let RequisitionNumber = matRequistion.value != '' ? matRequistion.value : null;
    let AccessionNumber = matAccession.value != '' ? matAccession.value : null;
    let PatientName = matPatientName.value != '' ? matPatientName.value : null;
    let OrderingFacility = this.ofLocationId != '' ? this.ofLocationId : null;
    let OrderingPhysician = this.OrderingPhysicianId != '' ? this.OrderingPhysicianId : null;
    let PathologistName = this.pathUserId != '' ? this.pathUserId : null;
    let EMR = matEMR.value != '' ? matEMR.value : null;
    let PatientDOB = matPatientDOB.value != '' ? matPatientDOB.value : null;
    PatientDOB = this.datepipe.transform(PatientDOB, 'MM/dd/yyyy');
    let Sendoutlaboratory = this.sendOutLabId != '' ? this.sendOutLabId : null;
    let CaseStatus = matCaseStatus.value != '' ? matCaseStatus.value.join(",") : null;
    let ServiceType = matServiceType.value != '' ? matServiceType.value.join(",") : null;
    let Lab = this.labId != '' ? this.labId : null;
    let readingLocation
    if(this.locationContext != "EPG")
    readingLocation = this.ReadingLocId;
    else
    readingLocation = this.frmreadingLocation.value && this.frmreadingLocation.value.length  ? this.frmreadingLocation.value.join(',') : null
    let Cytotechnologist = this.cytotechnicianId ? String(this.cytotechnicianId) : null;

    if (CaseType != null) {
      if (queryString != null)
        queryString += " and CaseType in \"" + CaseType + "\":string[]";
      else
        queryString = "CaseType in \"" + CaseType + "\":string[]";
    }
    if (OrderingFacility != null) {
      if (queryString != null) {
        queryString += " and OfID in \"" + OrderingFacility + "\":string[]";
      }
       else
       queryString = " OfID in \"" + OrderingFacility + "\":string[]" ;
  }
    if (OrderingPhysician != null) {
      if (queryString != null)
        queryString += " and OrderingPhysicianID in \"" + OrderingPhysician + "\":string[]";
      else
        queryString = "OrderingPhysicianID in \"" + OrderingPhysician + "\":string[]";
    }
    if (PatientName != null) {
      if (queryString != null)
        queryString += " and PatientName like \"%" + PatientName + "%\"";
      else
        queryString = "PatientName like \"%" + PatientName + "%\"";
    }
    if (CaseStatus != null) {
      if (queryString != null)
        queryString += " and CaseStatus in \"" + CaseStatus + "\":string[]";
      else
        queryString = " CaseStatus in \"" + CaseStatus + "\":string[]";
    }
    if (ServiceType != null) {
      if (queryString != null)
        queryString += " and ServiceType in \"" + ServiceType + "\":string[]";
      else
        queryString = "ServiceType in \"" + ServiceType + "\":string[]";
    }
    if (Lab != null) {
      if (queryString != null) {
        queryString += " and Labid in \"" + Lab + "\":string[]";
      }
     else
  queryString = "Labid in \"" + Lab + "\":string[]";
    }
    if (readingLocation) {
      if (queryString != null) {
        queryString += " and ReadingLocationId in \"" + readingLocation + "\":string[]";
      }
      else
     queryString = "ReadingLocationId in \"" + readingLocation + "\":string[]";
  }



    if (matcollectiondate.value.end != '' && matcollectiondate.value.end != undefined) {
      let startdate = this.addEvent(matcollectiondate.value.start);
      let enddate = this.addEvent2(matcollectiondate.value.end);
      var days = this.calculateDiff();
      if (days >= 31) {
        this.CollectionDate = true;
      }
      else {
        this.CollectionDate = false;
        if (queryString != null)
          queryString += " and collectiondateTofilter >= \"" + startdate + " 00:00:00\" and  collectiondateTofilter <= \"" + enddate + " 00:00:00\"";
        else
          queryString = "collectiondateTofilter >= \"" + startdate + " 00:00:00\" and  collectiondateTofilter <= \"" + enddate + " 00:00:00\"";
      }
    }
    if (matReceiveddate.value.end != '' && matReceiveddate.value.end != undefined) {
      let startdate = this.addEvent(matReceiveddate.value.start);
      let enddate = this.addEvent2(matReceiveddate.value.end);
      var days = this.calculateDiff();
      if (days >= 31) {
        this.ReceivedDate = true
      }
      else {
        this.ReceivedDate = false;
        if (queryString != null)
          queryString += "and ReceiveddateTofilter >= \"" + startdate + " 00:00:00\" and  ReceiveddateTofilter <= \"" + enddate + " 00:00:00\"";
        else
          queryString = "ReceiveddateTofilter >= \"" + startdate + " 00:00:00\" and  ReceiveddateTofilter <= \"" + enddate + " 00:00:00\"";
      }
    }
    if (matReportdate.value.end != '' && matReportdate.value.end != undefined) {
      let startdate = this.addEvent(matReportdate.value.start);
      let enddate = this.addEvent2(matReportdate.value.end);
      var days = this.calculateDiff();
      if (days >= 31) {
        this.ReportedDate = true
      }
      else {
        this.ReportedDate = false;
        if (queryString != null)
          queryString += " and ReporteddateTofilter >= \"" + startdate + " 00:00:00\" and  ReporteddateTofilter <= \"" + enddate + " 00:00:00\"";
        else
          queryString = "ReporteddateTofilter >= \"" + startdate + " 00:00:00\" and  ReporteddateTofilter <= \"" + enddate + " 00:00:00\"";
      }
    }
    if (matSendOutDate.value.end != '' && matSendOutDate.value.end != undefined) {
      let startdate = this.addEvent(matSendOutDate.value.start);
      let enddate = this.addEvent2(matSendOutDate.value.end);
      var days = this.calculateDiff();
      if (days >= 31) {
        this.SendOutDate = true
      }
      else {
        this.SendOutDate = false;
        if (queryString != null)
          queryString += " and sendoutdateTofilter >= \"" + startdate + " 00:00:00\" and  sendoutdateTofilter <= \"" + enddate + " 00:00:00\"";
        else
          queryString = "sendoutdateTofilter >= \"" + startdate + " 00:00:00\" and  sendoutdateTofilter <= \"" + enddate + " 00:00:00\"";
      }
    }
     if (!matReceiveddate.value.end && !matSendOutDate.value.end && !matReportdate.value.end && !matcollectiondate.value.end)
    {
      if (queryString != null)
        queryString +=  " and collectiondateTofilter >= \"" + startdate + " 00:00:00\" and  collectiondateTofilter <= \"" + enddate + " 00:00:00\"";
      else
        queryString =  "collectiondateTofilter >= \"" + startdate + " 00:00:00\" and  collectiondateTofilter <= \"" + enddate + " 00:00:00\"";


     }

    if (!this.CollectionDate && !this.ReceivedDate && !this.ReportedDate && !this.SendOutDate) { //if date range is less than 31 days run the
      let queryVariable = {
        "AccountID": /*id.toString()*/ null, "CaseID": CaseID, "RequisitionNumber": RequisitionNumber, "AccessionNumber": AccessionNumber,
        "PathologistID": PathologistName, "EMR": EMR,
        "PatientDOB": PatientDOB,
        "Cytotechnologist": Cytotechnologist, "Sendoutlaboratory": Sendoutlaboratory, "filterParameter": queryString
      };
      this.getFilterData(queryVariable);
    }


  }

  funcSetFalseValidation() {
    this.btndisabled = true;
    this.gridEmpty = true;
    this.gridDisplay = false;
  }
  getFilterData(querystring) {
    let query = this.GetQuery('Casesfiliterdata');
    let queryVariable = querystring;
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult).subscribe((casedata) => {
      this.ngxService.stop();
      if (!casedata.errors || casedata.data.submenuData == null) {
        this.addGridData(casedata);
      }
    }, (error) => {
      this.ngxService.stop();
      console.log(error);
    });
  }
  //RBAC Changes
  getButtondetails() {
    this.getButtonAccess(this.vitalHttpServices.SubmenuAction);
    // this.DataShare.SubmenuAction.subscribe((data) => {
    //   if (data) {
    //     this.(data);
    //   }
    // });
  }

  getButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case 'Export':
          this.btnExport = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'GetData':
          this.btnGetData = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
      }
    }
  }

  optionClickOf(data) {
    let newStatus2 = true;
    this.matOrderingFacility.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus2 = false;
      }
      else {
        this.ofLocationId.push(item.value)
      }
    });
   if(this.matOrderingFacility.value.length==0  ) {this.ofLocationId=[]}
   else{
    this.ofLocationId=[];
    for (let i = 0; i < this.matOrderingFacility.value.length; i++) {
      this.ofLocationId.push(this.matOrderingFacility.value[i]);
      }
    }
    this.allSelectedOf = newStatus2;
    this.OrderingPhysicianList = [];
    if (data && data.value && data.value.length > 0) {
      data.value.forEach(element => {
        const matchingFacility = this.OrderingFacility.find(ele => ele.OFAccountId === element);

        if (matchingFacility && matchingFacility.PhysicianList) {
          matchingFacility.PhysicianList.forEach(val => {
            // Ensure uniqueness based on the "id" property
            const existingItem = this.OrderingPhysicianList.find(item => item.OFUserId === val.OFUserId);

            if (!existingItem) {
              this.OrderingPhysicianList.push(val);
            }
          });
        }
      });
    }
    else {
      this.OrderingPhysicianList = this.tempOrderingPhysicianList
    }


}
  optionClickSendOut() {
    let newStatus2 = true;
    this.matSendout.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus2 = false;
      }
      else {
        this.sendOutLabId.push(item.value)
      }
    });
    this.allSelectedReadingloc = newStatus2;
  }

  optionClickReadingLoc(data) {
    let newStatus2 = true;
    this.matReadingLoc.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus2 = false;
      }
    });
    this.allSelectedReadingloc = newStatus2;
    this.PathologistList = [];
    this.CytotechnologistList = [];
    if (data && data.value && data.value.length > 0) {
      data.value.forEach(element => {
        const matchingReadingLoc = this.readingLocation.find(ele => ele.PathAccountId === element);

        if (matchingReadingLoc && matchingReadingLoc.PathologistList) {
          matchingReadingLoc.PathologistList.forEach(val => {
            // Ensure uniqueness based on the "id" property
            // const existingItem = this.PathologistList.find(item => item.OFUserId === val.OFUserId);

            // if (!existingItem) {
            this.PathologistList.push(val);

            // }
          });
        }
        if (matchingReadingLoc && matchingReadingLoc.CytoTechnologistList) {
          matchingReadingLoc.CytoTechnologistList.forEach(val => {
            // Ensure uniqueness based on the "id" property
            // const existingItem = this.PathologistList.find(item => item.OFUserId === val.OFUserId);

            // if (!existingItem) {
            this.CytotechnologistList.push(val);

            // }
          });
        }
      });
    }
    else {
      this.PathologistList = this.tempPathologistList;
      this.CytotechnologistList = this.tempCytotechnologistList;
    }
    this.PathologistControl.setValue(null);
    this.CytotechnologistControl.setValue(null);
  }
  selectedCytotecnician(cytoUserId) {
    this.cytotechnicianId = cytoUserId
  }
  selectedPathologists(pathUserId) {
    this.pathUserId = pathUserId;
  }
  selectedSendOutLab(sendOutLabId) {
    this.sendOutLabId = sendOutLabId
  }
  selectedLab(labAccountid) {
    this.labId = labAccountid
  }
  selectedOf(ofId) {
    let data = this.facilityAccount.value
    this.ofLocationId = ofId
  }
  selectedReadingLoc(locId) {
    this.ReadingLocId = locId
  }
  selectedPhycisian(phycianUserId) {
    this.OrderingPhysicianId = phycianUserId
  }


  async locationFilter(event, type) {
    this.filteredOrderingPhycisian = new Observable<string[]>();
    if (event.target.value.trim().length >= 3) {
      let ofAccounts = this.ofLocationId && this.ofLocationId.toString();
      let epgAccounts = this.ReadingLocId && this.ReadingLocId.toString();
      let context = sessionStorage.getItem("context") == 'Laboratory' ? 'lab' : sessionStorage.getItem("context") == 'Facility' ? 'Facility' : sessionStorage.getItem("context") == 'Send Out' ? 'sendout' : sessionStorage.getItem("context") == 'EPG' ? 'EPG' : ''
      let obj = {
        organizationId: sessionStorage.getItem("org_id"),
        orgType: context,
        type: type,
        seachData: event.target.value,
        AccountList: type == 'Pathologist' ? epgAccounts : type == 'OrderingPhysician' ? ofAccounts : null
      }
      this.ngxService.start();
      await this.vaservices.GetFilteredDropDown(obj).toPromise().then(
        (res) => {
          this.ngxService.stop();
          let result = JSON.parse(res.Message);
          if (type == 'lab') {
            this.AutoLabname = result
          }
          else if (type == 'Facility') {
            this.autoFacility = result;
          }
          else if (type == 'ReadingLocation') {
            this.autoReadingLocation = result;
          }
          else if (type == 'Send-out Laboratories') {
            this.autoSendOutLabs = result;
          }
          else if (type == 'OrderingPhysician') {
            this.autoPhycision = result;
          }
          else if (type == 'Pathologist') {
            this.autoPathologist = result;
          }
          else if (type == 'CytoTechnologist') {
            this.autoCytoTech = result;
          }

          if (this.locationContext != 'Laboratory') {
            this.filterLaboratories = this.labAccounnt.valueChanges.pipe(
              startWith(''),
              map((name) => name ? this.filterBylaboratory(name) : this.AutoLabname && this.AutoLabname.slice(0, 50)
              )
            );
          }
          if (this.locationContext != 'Facility') {
            this.filterFacilities = this.facilityAccount.valueChanges.pipe(
              startWith(''),
              map((name) => name ? this.filterByFacility(name) : this.autoFacility && this.autoFacility.slice(0, 50)
              )
            );
          }
          if (this.locationContext != 'EPG') {
            this.filterReadingLocation = this.frmreadingLocation.valueChanges.pipe(
              startWith(''),
              map((name) => name ? this.filterByReadingLocation(name) : this.autoReadingLocation && this.autoReadingLocation.slice(0, 50)
              )
            );
          }
          this.filteredSendoutlaboratory = this.SendoutlaboratoryControl.valueChanges.pipe(
            startWith(''),
            map((name) => name ? this.filterBySendoutlaboratory(name) : this.autoSendOutLabs && this.autoSendOutLabs.slice(0, 50)
            )
          );
          this.filteredOrderingPhycisian = this.orderingPhysician.valueChanges.pipe(
            startWith(''),
            map((name) => name ? this.filterBySendoutlaboratory(name) : this.autoPhycision && this.autoPhycision.slice(0, 50)
            )
          );
          this.filteredOfPathologist = this.PathologistControl.valueChanges.pipe(
            startWith(''),
            map((name) => name ? this.filterByPathologist(name) : this.autoPathologist && this.autoPathologist.length ?  this.autoPathologist.slice(0, 50) : []
            )
          );
          this.filteredCytotechnologist = this.CytotechnologistControl.valueChanges.pipe(
            startWith(''),
            map((name) => name ? this.filterByCytotechnologist(name) : this.autoCytoTech && this.autoCytoTech.slice(0, 50)
            )
          );
        }), error => {
          this.ngxService.stop();
          console.log(error)
        }
    }
    else if(event.target.value=='' || event.target.value.trim().length<=2)
    {

      if(this.contextvalue!='lab' && this.contextvalue!='EPG'){
        this.OrderingPhysicianId='';
        this.ReadingLocId='';
        this.labId=[];
        this.sendOutLabId='';
        this.pathUserId='';
        this.cytotechnicianId='';
      }
       if(this.contextvalue!='Facility' && this.contextvalue!='EPG'){
        this.OrderingPhysicianId='';
        this.ReadingLocId='';
        //this.labId=[];1
        this.ofLocationId=[];
        this.sendOutLabId='';
        this.pathUserId='';
        this.cytotechnicianId='';
      }
      if (this.contextvalue!='Facility' && this.contextvalue!='lab'){
        this.OrderingPhysicianId='';
      //  this.ReadingLocId='';
        this.labId=[];
        this.ofLocationId=[];
        this.sendOutLabId='';
        this.pathUserId='';
        this.cytotechnicianId='';
      }
    }
  }
  AllowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

}

class ColDef {
  constructor(public binding?: string, public header?: string) {
  }
}
