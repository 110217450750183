import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { cloneDeep } from 'lodash';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { Attachments, CreateActivityTrackerInput, Operations } from 'src/app/model/activity-tracker';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';

export interface Task {
  color: ThemePalette;
}

@Component({
  selector: 'app-case-tags-dialog',
  templateUrl: './case-tags-dialog.component.html',
  styleUrls: ['./case-tags-dialog.component.scss'],
})

export class CaseTagsDialogComponent implements OnInit {
  task: Task = {
    color: 'primary',
  };

  @Input() tagData: any = [];
  @ViewChild('myInput') myInput: ElementRef;

  selectedNCasestatusList: any = [];
  [x: string]: any;
  isGroupId: boolean = false
  isDirty: any;
  isEditEnabled: boolean = false;
  typeName: string;
  isGroupchanged = false;
  isbuttonEnable = false;
  isEditable: boolean = false;
  deletedIDs: any = []
  // deletedTags: any = []
  // deletetagValueIds:any=[]
  // deletetagValues:any=[]
  oldGroupName:string;
  isGroupNameChanged:boolean=false

  isValuesSectionVisible: boolean = false;

  xpandTags: boolean = true;
  xpandCategoroies: boolean = false;
  // tag side bar
  tagWrap: boolean = false;

  CaseNStatusList: any = [];

  RoleList: any = [];
  CasetypeList: any = [];
  CaseStatusList: any = [];
  isFlagName: string = "";
  caseTagData: any = [];
  isDialogShow: boolean = true;
  destOrgid: any = sessionStorage.getItem('org_id').toString();
  destDeployment = this.VitalHttpServices.deploymentKey;

  buttons: number[] = [];

  caseGroupForm: FormGroup;
  caseTagForm: FormGroup;
  caseTagsName: FormGroup;
  CreatedDate: any;
  ModifiedDate: any;
  currentData: string[] = [];

  // Initial font color & background color 
  defaultBackgroundColor: string = '#FFF853';
  defaultFontColor: string = '#000000';
  tagNameIndex: number = -1
  tagValueIndex: number = -1

  caseStatusDropdownSettings: {
    singleSelection: boolean;
    idField: string;
    textField: string;
    enableCheckAll: boolean;
    allowSearchFilter: boolean;
    limitSelection: number;
    clearSearchFilter: boolean;
    maxHeight: number;
    itemsShowLimit: number;
    closeDropDownOnSelection: boolean;
    showSelectedItemsAtTop: boolean;
    defaultOpen: boolean;
    selectAllText: string;
    unSelectAllText: string,
  };

  auditObj: any
  activityEntity: any;
  createActivityInput = new CreateActivityTrackerInput();
  createActivityOperations = new Operations();
  createActivityAttachments = new Attachments();

  constructor(private fb: FormBuilder, public _snackBar: MatSnackBar, public dialogRef: MatDialogRef<CaseTagsDialogComponent>,
    public VitalHttpServices: VitalHttpServices, public datePipe: DatePipe, private _snackbar: MatSnackBar, private vaservice: CommonService, public activityService: ActivityTrackerService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  async ngOnInit() {
    this.checkDisable();
    this.initializeForm(this.data.continue.toLowerCase() == 'update' ? false : true);
    this.patchData();
    this.caseStatusDropdownSettings = {
      singleSelection: false,
      idField: 'item',
      textField: 'item',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true,
      enableCheckAll: false,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 150,
      // itemsShowLimit: 44,
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
    };
  }

  patchNonEdit(temNonEditableCaseStatus) {
    let nonEditableCaseStatusData = []
    if (temNonEditableCaseStatus) {
      if (temNonEditableCaseStatus.length > 0) {
        let data = this.getUniqueCasetype(temNonEditableCaseStatus)
        data.forEach(element => {
          if (element) { nonEditableCaseStatusData.push({ item: element.toString().trim() }) }
        });
      } else {
        this.selectedNCasestatusList = [];
      }
    }
    this.selectedNCasestatusList = [...nonEditableCaseStatusData]
  }

  async getCasestatusList() {
    // sessionStorage.setItem('deploymentKey', 'configdb');
    let query = this.GetQuery('CategoryDropDown');
    let queryVariable = {
      keyword: 'CaseStatus',
      context: 'OrganizationTags',
    };
    let dbname = sessionStorage.getItem('deploymentKey').toUpperCase();
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    let Resdata = await this.VitalHttpServices.GetData(queryResult, dbname).toPromise();
    // sessionStorage.setItem('deploymentKey', this.VitalHttpServices.deploymentKey);
    if (!Resdata.errors) {
      if (Resdata.data) {
        if (Resdata.data.submenuData.length > 0) {
          let data = JSON.parse(Resdata.data.submenuData[0].Items_JSON);
          this.CaseStatusList = [...data.CaseStatus, { item: "ALL" }]
          this.CaseStatusList.sort((a, b) => a.item < b.item ? -1 : a.item > b.item ? 1 : 0);
          this.CaseNStatusList = cloneDeep(this.CaseStatusList);
          this.caseStatusDataList = [];
          this.nCaseStatusDataList = [];
          this.caseStatusDataList = [...this.CaseStatusList];
          this.nCaseStatusDataList = [...this.CaseNStatusList];

          this.caseStatusDropdownSettings = {
            singleSelection: false,
            idField: 'item',
            textField: 'item',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 4,
            allowSearchFilter: true,
            enableCheckAll: true,
            limitSelection: -1,
            clearSearchFilter: true,
            maxHeight: 150,
            // itemsShowLimit: 44,
            closeDropDownOnSelection: false,
            showSelectedItemsAtTop: false,
            defaultOpen: false,
          };
        }
      }
    }
    // sessionStorage.setItem('deploymentKey', this.VitalHttpServices.deploymentKey);
  }

  getUniqueCasetype(array) {
    var flags = [], output = [], l = array.length, i;
    for (i = 0; i < l; i++) {
      if (flags[array[i]]) continue;
      flags[array[i]] = true;
      output.push(array[i]);
    }
    return output;
  }

  focusInput() {
    if (this.myInput !== undefined) {
      this.myInput.nativeElement.focus();
    }
  }

  patchData() {
    if (this.data.continue.toLowerCase() == 'update') {
      this.isEditable = true
      this.setGroup(this.data.currentData);
      this.patchTagDataToControls(this.data.currentData.childList);
      let editedTrackerData = this.data.currentData;
      editedTrackerData['groupfontcolor'] = this.data.currentData['fontcolor']? this.data.currentData['fontcolor']: this.defaultFontColor;
      delete editedTrackerData['isdefault'];
      for (let i = 0; i < this.data.currentData.childList.length; i++) {
        let editedTagBgColor = this.data.currentData.childList[i].groupcolor ?  this.data.currentData.childList[i].groupcolor : this.defaultBackgroundColor;
        editedTrackerData.childList[i]['tagbgcolor']= editedTagBgColor;
      }
      this.modifyInitDataForAudit(editedTrackerData);
      this.categoryList = {
        caseType: cloneDeep(this.data.CasetypeDataList),
        caseStatus: cloneDeep(this.data.caseStatusDataList),
        roles: cloneDeep(this.data.RoleList),
      }
      this.data.CasetypeDataList.forEach(va => va.checked = false)
      this.data.caseStatusDataList.forEach(va => va.checked = false)
      this.data.RoleList.forEach(va => va.checked = false)
    } else {
      this.data.CasetypeDataList.forEach(va => va.checked = false)
      this.data.caseStatusDataList.forEach(va => va.checked = false)
      this.data.RoleList.forEach(va => va.checked = false)
      this.modifyInitDataForAudit();
      this.categoryList = {
        caseType: cloneDeep(this.data.CasetypeDataList),
        caseStatus: cloneDeep(this.data.caseStatusDataList),
        roles: cloneDeep(this.data.RoleList),
      }
    }
  }

  // bg color
  updateColor(value) {
    const bgcolorDisplay = document.getElementById('bgcolorDisplay');
    if (bgcolorDisplay != undefined) {
      bgcolorDisplay.style.backgroundColor = value ? value : this.defaultBackgroundColor;
    }
  }

  updateColorPicker(value) {
    const bgcolorDisplay = document.getElementById('bgcolorDisplay');
    const bgcolorInput = document.getElementById('bgcolorInput');
    bgcolorDisplay.style.backgroundColor = value ? value : this.defaultBackgroundColor;
    bgcolorInput['value'] = value ? value : this.defaultBackgroundColor;
  }

  openColorPicker() {
    const colorPicker = document.getElementById('bgpicker');
    colorPicker.click(); // Trigger the click event of the color picker input
  }

  // font color
  updateFontColor(value) {
    const fontcolorDisplay = document.getElementById('fontcolorDisplay');
    if (fontcolorDisplay != undefined) {
      fontcolorDisplay.style.backgroundColor = value ? value : this.defaultFontColor;
    }
  }

  updateFontColorPicker(value) {
    const fontcolorDisplay = document.getElementById('fontcolorDisplay');
    const fontcolorInput = document.getElementById('fontcolorInput');
    fontcolorDisplay.style.backgroundColor = value ? value : this.defaultFontColor;
    fontcolorInput['value'] = value ? value : this.defaultFontColor;
  }

  openFontColorPicker() {
    const colorPicker = document.getElementById('fontpicker');
    colorPicker.click(); // Trigger the click event of the color picker input
  }

  setGroup(groupData) {
    let temNonEditableCaseStatus = groupData.noteditablecasestatus ? groupData.noteditablecasestatus.toString().split(',') : "";
    this.patchNonEdit(temNonEditableCaseStatus);
    let groupBgColor = (groupData.groupcolor ? groupData.groupcolor : null) ?? this.defaultBackgroundColor;
    this.CreatedDate =groupData.createddate;
    this.ModifiedDate = groupData.modifieddate;

    this.caseGroupForm.patchValue({
      frmTagGroupName: groupData.groupname,
      frmGroupBgColor: groupBgColor,
      frmGroupFontColor: groupData.fontcolor ?? this.defaultFontColor,
      frmGroupId: groupData.organizationtagid,

      frmTagName: groupData.tagname,
      frmTagBgColor: groupData.color ?? this.defaultBackgroundColor,
      frmTagFontColor: groupData.fontcolor ?? this.defaultFontColor,

      frmTagvaluename: groupData.tagvalue,
      frmTagValueNameBgColor: groupData.color ?? this.defaultBackgroundColor,
      frmTagValueNameFontColor: groupData.tagvaluefontcolor ?? this.defaultFontColor,

      frmActive: groupData.isactive ?? true,
      frmTagSequence: groupData.sequenceorder,
      frmAllowBulkEdit: groupData.allowbulkedit ?? true,
      frmNonEditableCaseStatus: groupData.noteditablecasestatus ? groupData.noteditablecasestatus.toString().split(',').map(item => item.trim()): ''
    })
  }


  patchTagDataToControls(childList) {
    for (let i = 0; i < childList.length; i++) {
      const tagFormGroup = <FormGroup>(<FormArray>this.caseTagForm.get('tags')).at(i);
      this.tempgroupid = childList[0].groupid;
      tagFormGroup.patchValue({
        frmTagName: childList[i].tagname,
        frmTagBgColor: childList[i].groupcolor ?? this.defaultBackgroundColor,
        frmTagFontColor: childList[i].fontcolor ?? this.defaultFontColor,
        frmTagValueId: childList[i].organizationcasetagvalueid,
        frmGroupId: childList[i].groupid,
        frmTagId: childList[i].organizationtagid,
        frmTagvaluename: childList[i].tagvalue,
        frmTagValueNameBgColor: childList[i].color ?? this.defaultBackgroundColor,
        frmTagValueNameFontColor: childList[i].tagvaluefontcolor ?? this.defaultFontColor,
        frmTagActive: childList[i].isactive ?? false,
        frmTagSequence: childList[i].sequenceorder
      });

      this.createtagForm(childList[i], i);

      if (i !== childList.length - 1) {
        this.addTag(true);
      }
    }
  }

  onActiveChange(event, type,i?) {
    if (type === 'group') {
      this.caseGroupForm.patchValue({
        frmActive: event,
      })
      this.isbuttonEnable = true
      this.isGroupchanged = true
    } else if (type === 'tag') {
      var item = (<FormArray>this.caseTagForm.get('tags')).at(i);
      item.patchValue({
        frmTagActive: event,
      })
    } else {
      this.caseGroupForm.patchValue({
        frmAllowBulkEdit: event,
      })
    }
    this.isGroupchanged = true
    this.isbuttonEnable = true
  }

  handleDataChange(key: string, updatedData: any[]) {
    switch (key) {
      case 'listCasetype':
        this.listCasetype = updatedData;
        this.isbuttonEnable = true
        break;
      case 'listStatus':
        this.listStatus = updatedData;
        this.isbuttonEnable = true
        break;
      case 'listRoles':
        this.listRoles = updatedData;
        this.isbuttonEnable = true
        break;
    }
  }

  setgroupName(event, i) {
    let flagName = this.isFlagName
    event.target.value = event.target.value.replace(',', '');
    switch (flagName) {
      case 'Group Name':
        this.caseGroupForm.patchValue({
          frmTagGroupName: event.target.value.trim()
        })
        this.isGroupchanged = true
        this.isbuttonEnable = true
        break;

      case 'Tag Name':
        var item = (<FormArray>this.caseTagForm.get('tags')).at(this.tagNameIndex);
        item.patchValue({
          frmTagName: event.target.value.trim()
        })
        this.isbuttonEnable = true
        break;

      case 'Tag Value Name':
        var item = (<FormArray>this.caseTagForm.get('tags')).at(this.tagNameIndex);
        let data = (<FormArray>item.get('frmtagValues')).at(this.tagValueIndex);
        data.patchValue({
          frmTagvaluename: event.target.value.trim()
        })
        this.isbuttonEnable = true
        break;

      default:
        break;
    }
  }

  setBgColor(event, i) {
    event.target.value = event.target.value.replace(',', '');
    let flagName = this.isFlagName
    let item
    const bgcolorDisplay = document.getElementById('bgcolorDisplay');
    const bgcolorInput = document.getElementById('bgcolorInput');
    bgcolorDisplay.style.backgroundColor = event.target.value;
    bgcolorInput['value'] = event.target.value;
    switch (flagName) {
      case 'Group Name':
        this.caseGroupForm.patchValue({
          frmGroupBgColor: event.target.value
        })
        this.caseTagsName.controls['frmTagBgColor'].setValue(event.target.value);
        this.isGroupchanged = true;
        this.isbuttonEnable = true
        break;

      case 'Tag Name':
        item = (<FormArray>this.caseTagForm.get('tags')).at(this.tagNameIndex);
        item.patchValue({
          frmTagBgColor: event.target.value
        })
        this.caseTagsName.controls['frmTagBgColor'].setValue(event.target.value);
        this.isbuttonEnable = true
        break;

      case 'Tag Value Name':
        item = (<FormArray>this.caseTagForm.get('tags')).at(this.tagNameIndex);
        let data = (<FormArray>item.get('frmtagValues')).at(this.tagValueIndex);
        data.patchValue({
          frmTagValueNameBgColor: event.target.value
        })
        this.caseTagsName.controls['frmTagBgColor'].setValue(event.target.value);
        this.isbuttonEnable = true
        break;

      default:
        break;
    }
  }

  setFontColor(event, i) {
    event.target.value = event.target.value.replace(',', '');
    let flagName = this.isFlagName
    let item
    const fontcolorDisplay = document.getElementById('fontcolorDisplay');
    const fontcolorInput = document.getElementById('fontcolorInput');
    fontcolorDisplay.style.backgroundColor = event.target.value;
    fontcolorInput['value'] = event.target.value;
    switch (flagName) {
      case 'Group Name':
        this.caseGroupForm.patchValue({
          frmGroupFontColor: event.target.value
        })
        this.caseTagsName.controls['frmTagFontColor'].setValue(event.target.value);
        this.isGroupchanged = true
        this.isbuttonEnable = true
        break;

      case 'Tag Name':
        item = (<FormArray>this.caseTagForm.get('tags')).at(this.tagNameIndex);
        item.patchValue({
          frmTagFontColor: event.target.value
        })
        this.caseTagsName.controls['frmTagFontColor'].setValue(event.target.value);
        this.isbuttonEnable = true
        break;

      case 'Tag Value Name':
        item = (<FormArray>this.caseTagForm.get('tags')).at(this.tagNameIndex);
        let data = (<FormArray>item.get('frmtagValues')).at(this.tagValueIndex);
        data.patchValue({
          frmTagValueNameFontColor: event.target.value
        })
        this.caseTagsName.controls['frmTagFontColor'].setValue(event.target.value);
        this.isbuttonEnable = true
        break;

      default:
        break;
    }
  }

  // toggle value for modify
  toggleValuesSection() {
    this.isValuesSectionVisible = !this.isValuesSectionVisible;
    this.isGroupchanged = true
  }

  checkDisable() {
    if (this.CasetypeList.length > 0 || this.CaseStatusList.length > 0 || this.RoleList.length > 0) {
      return false
    } else {
      return true
    }
  }

  onNoClick() {
    this.isDirty = false;
    this.dialogRef.close('true');
    this.canDeactivate()
  }

  canDeactivate(): boolean {
    return true
  }

  // undo name,bg and font color

  undoTagNames() {
    let flagName = this.isFlagName
    switch (flagName) {
      case 'Group Name':
        this.undoGroupName();
        break;

      case 'Tag Name':
        this.undoTagName();
        break;

      case 'Tag Value Name':
        this.undoTagValueName();
        break;

      default:
        break;
    }
  }

  undoBackColor(event, i) {
    let flagName = this.isFlagName
    switch (flagName) {
      case 'Group Name':
        this.undoGroupBgColor();
        break;

      case 'Tag Name':
        this.undoTagBgColor();
        break;

      case 'Tag Value Name':
        this.undoTagValueBgColor();
        break;

      default:
        break;
    }
  }

  undoFontsColor(event, i) {
    let flagName = this.isFlagName
    switch (flagName) {
      case 'Group Name':
        this.undoGroupFontColor();
        break;

      case 'Tag Name':
        this.undoTagFontColor();
        break;

      case 'Tag Value Name':
        this.undoTagValueFontColor();
        break;

      default:
        break;
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  undoGroupName() {
    this.currentData = { ...this.data.currentData }
    let patchGroupTagName = this.currentData['groupname']
    this.caseGroupForm.patchValue({
      frmTagGroupName: patchGroupTagName
    })
    this.caseTagsName.patchValue({
      frmTagName: patchGroupTagName
    })
  }

  undoTagName() {
    this.currentData = { ...this.data.currentData }
    let patchTagName = this.currentData['childList'][this.tagNameIndex].tagname.includes(',') ? this.currentData['childList'][this.tagNameIndex].tagname.split(',')[this.tagValueIndex] : this.currentData['childList'][this.tagNameIndex].tagname;
    var item = (<FormArray>this.caseTagForm.get('tags')).at(this.tagNameIndex);
    item.patchValue({
      frmTagName: patchTagName
    })
    this.caseTagsName.patchValue({
      frmTagName: patchTagName
    })
  }

  undoTagValueName() {
    this.currentData = { ...this.data.currentData }
    let patchTagValueName = this.currentData['childList'][this.tagNameIndex].tagvalue.includes(',') ? this.currentData['childList'][this.tagNameIndex].tagvalue.split(',')[this.tagValueIndex] : this.currentData['childList'][this.tagNameIndex].tagvalue;
    var item = (<FormArray>this.caseTagForm.get('tags')).at(this.tagNameIndex);
    let data = (<FormArray>item.get('frmtagValues')).at(this.tagValueIndex);
    data.patchValue({
      frmTagvaluename: patchTagValueName
    })
    this.caseTagsName.patchValue({
      frmTagName: patchTagValueName
    })
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  undoGroupBgColor() {
    this.currentData = { ...this.data.currentData }
    let patchBgColor = this.currentData['groupcolor']
    var item = this.caseGroupForm.value.frmTagGroupName
    this.caseGroupForm.patchValue({
      frmTagBgColor: patchBgColor ? patchBgColor : this.defaultBackgroundColor
    })
    this.caseTagsName.patchValue({
      frmTagBgColor: patchBgColor ? patchBgColor : this.defaultBackgroundColor
    })
    let updateGroupbackColor = this.caseGroupForm.value.frmTagBgColor ? this.caseGroupForm.value.frmTagBgColor : this.defaultBackgroundColor
    this.updateColor(updateGroupbackColor)
  }

  undoGroupFontColor() {
    this.currentData = { ...this.data.currentData }
    let patchFontColor = this.currentData['fontcolor'];
    var item = this.caseGroupForm.value.frmTagGroupName
    this.caseGroupForm.patchValue({
      frmGroupFontColor: patchFontColor ? patchFontColor : this.defaultFontColor
    })
    this.caseTagsName.patchValue({
      frmTagFontColor: patchFontColor ? patchFontColor : this.defaultFontColor
    })
    let updateGroupfontColor = this.caseGroupForm.value.frmGroupFontColor ? this.caseGroupForm.value.frmGroupFontColor : this.defaultFontColor
    this.updateFontColor(updateGroupfontColor)
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  undoTagBgColor() {
    this.currentData = { ...this.data.currentData }
    let patchBgColor = this.defaultBackgroundColor
    if (this.currentData['childList'][this.tagNameIndex])
      patchBgColor = this.currentData['childList'][this.tagNameIndex].groupcolor.includes(',') ? this.currentData['childList'][this.tagNameIndex].groupcolor.split(',')[this.tagValueIndex] : this.currentData['childList'][this.tagNameIndex].groupcolor;
    var item = (<FormArray>this.caseTagForm.get('tags')).at(this.tagNameIndex);
    item.patchValue({
      frmTagBgColor: patchBgColor
    })
    this.caseTagsName.patchValue({
      frmTagBgColor: patchBgColor
    })
    let updateTagbackColor = this.caseTagsName.value.frmTagBgColor ? this.caseTagsName.value.frmTagBgColor : this.defaultBackgroundColor
    this.updateColor(updateTagbackColor)
  }

  undoTagFontColor() {
    this.currentData = { ...this.data.currentData }
    let patchFontColor = this.defaultFontColor
    if (this.currentData['childList'][this.tagNameIndex])
      patchFontColor = this.currentData['childList'][this.tagNameIndex].fontcolor.includes(',') ? this.currentData['childList'][this.tagNameIndex].fontcolor.split(',')[this.tagValueIndex] : this.currentData['childList'][this.tagNameIndex].fontcolor;
    var item = (<FormArray>this.caseTagForm.get('tags')).at(this.tagNameIndex);
    item.patchValue({
      frmTagFontColor: patchFontColor
    })
    this.caseTagsName.patchValue({
      frmTagFontColor: patchFontColor
    })
    let updateTagfontColor = this.caseTagsName.value.frmTagFontColor ? this.caseTagsName.value.frmTagFontColor : this.defaultFontColor
    this.updateFontColor(updateTagfontColor)
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  undoTagValueBgColor() {
    this.currentData = { ...this.data.currentData }
    let patchBgColor = this.defaultBackgroundColor;
    if (this.currentData['childList'][this.tagNameIndex])
    patchBgColor = this.currentData['childList'][this.tagNameIndex].color.includes(',') ? this.currentData['childList'][this.tagNameIndex].color.split(',')[this.tagValueIndex] : this.currentData['childList'][this.tagNameIndex].color;
    var item = (<FormArray>this.caseTagForm.get('tags')).at(this.tagNameIndex);
    let data = (<FormArray>item.get('frmtagValues')).at(this.tagValueIndex);
    data.patchValue({
      frmTagValueNameBgColor: patchBgColor
    })
    this.caseTagsName.patchValue({
      frmTagBgColor: patchBgColor
    })
    let updateTagvaluebackColor = this.caseTagsName.value.frmTagBgColor ? this.caseTagsName.value.frmTagBgColor : this.defaultBackgroundColor
    this.updateColor(updateTagvaluebackColor)
  }

  undoTagValueFontColor() {
    this.currentData = { ...this.data.currentData }
    let patchFontColor = this.defaultFontColor
    if (this.currentData['childList'][this.tagNameIndex])
      patchFontColor = this.currentData['childList'][this.tagNameIndex].tagvaluefontcolor.includes(',') ? this.currentData['childList'][this.tagNameIndex].tagvaluefontcolor.split(',')[this.tagValueIndex] : this.currentData['childList'][this.tagNameIndex].tagvaluefontcolor;
    var item = (<FormArray>this.caseTagForm.get('tags')).at(this.tagNameIndex);
    let data = (<FormArray>item.get('frmtagValues')).at(this.tagValueIndex);
    data.patchValue({
      frmTagValueNameFontColor: patchFontColor
    })
    this.caseTagsName.patchValue({
      frmTagFontColor: patchFontColor
    })
    let updateTagvaluefontColor = this.caseTagsName.value.frmTagFontColor ? this.caseTagsName.value.frmTagFontColor : this.defaultFontColor
    this.updateFontColor(updateTagvaluefontColor)
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // tag value bg and font color
  tagColor(data, item) {
    if (data.value.frmTagvaluename) {
      return {
        'background-color': data.value.frmTagValueNameBgColor !== null && data.value.frmTagValueNameBgColor !== undefined && data.value.frmTagValueNameBgColor.includes(',') ? data.value.frmTagValueNameBgColor.split(',')[item] : data.value.frmTagValueNameBgColor || this.defaultBackgroundColor,
        'color': data.value.frmTagValueNameFontColor ?? this.defaultFontColor
      };
    }
  }

  // tag method
  initializeForm(isCreate) {
    this.caseGroupForm = this.fb.group({
      frmTagGroupName: '',
      frmGroupFontColor: [''],
      frmGroupBgColor: [''],
      frmGroupId: '',

      frmTagName: [''],
      frmTagBgColor: [''],
      frmTagFontColor: [''],

      frmTagvaluename: [''],
      frmTagValueNameBgColor: [''],
      frmTagValueNameFontColor: [''],

      frmActive: true,
      frmDefault: true,
      frmAllowBulkEdit: false,
      frmNonEditableCaseStatus: ['']
      // frmContinueBtn: ''
    });

    this.caseTagsName = this.fb.group({
      frmTagName: [''],
      frmTagBgColor: [''],
      frmTagFontColor: [''],
      tags: [],
      frmTagvaluename: [''],
      frmTagValueNameBgColor: [''],
      frmTagValueNameFontColor: [''],
      frmDefault: ['']
    })

    // Initialize your main form
    this.caseTagForm = this.fb.group({
      tags: this.fb.array([this.fb.group({
        frmTagName: '', // Add any necessary form controls for the tag
        frmTagBgColor: '',
        frmTagFontColor: '',
        frmGroupId: '',
        frmTagId: '',

        frmtagValues: isCreate ? this.fb.array([this.newtagValue()]) : this.fb.array([]),// Use FormArray to store tag values
        frmTagValueNameBgColor: '',
        frmTagValueNameFontColor: '',

        frmTagActive: true, // Example control, modify based on your requirements // add icon
        frmDefault: false,
        frmTagSequence: ''
      })]) // Use FormArray to store multiple form groups
    });
  }

  tagsFormArray() {
    return (this.caseTagForm.get('tags') as FormArray);
  }

  // drag and drop tag values 
  tagDrop(event: CdkDragDrop<string[]>) {
    this.isbuttonEnable = true;
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.caseTagForm.patchValue({ tags: event.container.data })
      this.caseTagForm.value.tags[event.currentIndex].frmTagSequence = event.currentIndex + 1;
      this.caseTagForm.value.tags[event.previousIndex].frmTagSequence = event.previousIndex + 1;
      for (let index = event.currentIndex + 1; index < this.caseTagForm.value.tags.length; index++) {
        this.caseTagForm.value.tags[index].frmTagSequence = index + 1;
      }
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.tagWrap = false;
  }

  tagValueDrop(event: CdkDragDrop<string[]>, index) {
    this.isbuttonEnable = true;
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    var item: FormGroup = <FormGroup>(<FormArray>this.caseTagForm.get('tags')).at(index);
    item.patchValue({ frmtagValues: event.container.data })
    for (let index = 0; index < (item.value["frmtagValues"]).length; index++) {
      item.controls["frmtagValues"]["controls"][index].patchValue({ frmTagSequence: String(index + 1) });
    }
    this.tagWrap = false;
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }

  // tooltip for groupname, tagname and tag value

  getGroupTitle() {
    return this.caseGroupForm.value.frmTagGroupName
  }

  getTitleValue(i) {
    var item = (<FormArray>this.caseTagForm.get('tags')).at(i);
    return item.value.frmTagName
  }

  getTagValueTitle(i, index) {
    var item = (<FormArray>this.caseTagForm.get('tags')).at(i);
    let data = (<FormArray>item.get('frmtagValues')).at(index);
    return data.value.frmTagvaluename
  }

  isDefaultchecked(event) {
    var item = (<FormArray>this.caseTagForm.get('tags')).at(this.tagNameIndex);
    let data = (<FormArray>item.get('frmtagValues')).at(this.tagValueIndex);
    for (let i = 0; i < item.value.frmtagValues.length; i++) {
      let obj = (<FormArray>item.get('frmtagValues')).at(i);
      obj.patchValue({
        frmDefault: false
      })
    }
    data.patchValue({
      frmDefault: event.checked
    })
    this.isbuttonEnable = true;
  }

  groupNameClicked() {
    this.tagWrap = true;
    this.isFlagName = 'Group Name';
    this.oldGroupName=this.caseGroupForm.value.frmTagGroupName
    this.caseTagsName.patchValue({
      frmTagName: this.caseGroupForm.value.frmTagGroupName,
      frmTagBgColor: this.caseGroupForm.value.frmGroupBgColor ? this.caseGroupForm.value.frmGroupBgColor : this.defaultBackgroundColor,
      frmTagFontColor: this.caseGroupForm.value.frmGroupFontColor ? this.caseGroupForm.value.frmGroupFontColor : this.defaultFontColor,
      frmGroupId: this.caseGroupForm.value.frmGroupId
    })
    setTimeout(() => {
      this.updateColor(this.caseTagsName.value.frmTagBgColor);
      this.updateFontColor(this.caseTagsName.value.frmTagFontColor);
    });
  }

  tagnameClicked(i) {
    this.tagWrap = true;
    this.tagNameIndex = i;
    this.tagValueIndex = -1
    this.isFlagName = 'Tag Name'
    var item = (<FormArray>this.caseTagForm.get('tags')).at(i);
    // this.caseTagsName.patchValue(item.value)
    this.caseTagsName.patchValue({
      frmTagName: item.value.frmTagName,
      frmTagBgColor: item.value.frmTagBgColor?.includes(',') ? item.value.frmTagBgColor.split(',') : item.value.frmTagBgColor ? item.value.frmTagBgColor : this.defaultBackgroundColor,
      frmTagFontColor: item.value.frmTagFontColor?.includes(',') ? item.value.frmTagFontColor.split(',') : item.value.frmTagFontColor ? item.value.frmTagFontColor : this.defaultFontColor,
    })
    setTimeout(() => {
      let tagupdateBgColor = item.value.frmTagBgColor ? item.value.frmTagBgColor : this.defaultBackgroundColor
      this.updateColor(tagupdateBgColor);
      let tagupdateFntColor = item.value.frmTagFontColor ? item.value.frmTagFontColor : this.defaultFontColor
      this.updateFontColor(tagupdateFntColor);
    });
  }

  tagNameValueClicked(tagNameIndex, tagValueIndex) {
    this.tagWrap = true;
    this.tagNameIndex = tagNameIndex;
    this.tagValueIndex = tagValueIndex;
    this.isFlagName = 'Tag Value Name'
    var item = (<FormArray>this.caseTagForm.get('tags')).at(tagNameIndex);
    this.caseTagsName.patchValue({
      frmTagName: item.value.frmtagValues[tagValueIndex].frmTagvaluename,
      frmTagBgColor: item.value.frmtagValues[tagValueIndex].frmTagValueNameBgColor?.includes(',') ? item.value.frmtagValues[tagValueIndex].frmTagValueNameBgColor.split(',')[tagValueIndex] : item.value.frmtagValues[tagValueIndex].frmTagValueNameBgColor ? item.value.frmtagValues[tagValueIndex].frmTagValueNameBgColor : this.defaultBackgroundColor,
      frmTagFontColor: item.value.frmtagValues[tagValueIndex].frmTagValueNameFontColor?.includes(',') ? item.value.frmtagValues[tagValueIndex].frmTagValueNameFontColor.split(',')[tagValueIndex] : item.value.frmtagValues[tagValueIndex].frmTagValueNameFontColor ? item.value.frmtagValues[tagValueIndex].frmTagValueNameFontColor : this.defaultFontColor,
      frmDefault: item.value.frmtagValues[tagValueIndex].frmDefault
    })
    setTimeout(() => {
      let tagValueBgColor = item.value.frmtagValues[tagValueIndex].frmTagValueNameBgColor ? item.value.frmtagValues[tagValueIndex].frmTagValueNameBgColor : this.defaultBackgroundColor
      this.updateColor(tagValueBgColor)
      let tagValuefontColor = item.value.frmtagValues[tagValueIndex].frmTagValueNameFontColor ? item.value.frmtagValues[tagValueIndex].frmTagValueNameFontColor : this.defaultFontColor
      this.updateFontColor(tagValuefontColor)
    });
  }

  // add tag for case tags
  addTag(patchData?: Boolean) {
    // Create a new form group for the tag
    const newTag = this.fb.group({
      frmTagName: '', // Add any necessary form controls for the tag
      frmTagBgColor: '',
      frmTagFontColor: '',
      frmGroupId: '',
      frmTagId: '',

      frmtagValues: patchData ? this.fb.array([]) : this.fb.array([this.newtagValue()]),// Use FormArray to store tag values
      frmTagValueNameBgColor: '',
      frmTagValueNameFontColor: '',

      frmTagActive: true, // Example control, modify based on your requirements // add icon
      frmDefault: false,
      frmTagSequence: ''
    });

    let tagArray = this.caseTagForm.get('tags') as FormArray;
    // Add the new form group to the FormArray
    if (tagArray.at(tagArray.length - 1).get('frmTagName').value !== '') {
      this.tagsFormArray().push(newTag);
    } else {
      this._snackBar.open('Please provide the valid value.', 'Close');
    }
  }

  // tag value method
  createtagForm(tagValues?, tagIndex?): any {
    if (!tagValues || !tagValues.tagvalue) {
      return [];
    }
    for (let i = 0; i < tagValues.tagvalue.split(',').length; i++) {
      let frmgrp = this.fb.group({
        frmTagvaluename: tagValues.tagvalue.split(',')[i] ?? '',
        frmTagValueNameBgColor: tagValues.color && tagValues.color.length > 1 ? tagValues.color.split(',')[i] : this.defaultBackgroundColor,
        frmTagValueNameFontColor: tagValues.tagvaluefontcolor && tagValues.tagvaluefontcolor.length > 1 ? tagValues.tagvaluefontcolor.split(',')[i] : this.defaultFontColor,
        frmDefault: tagValues.isdefault.split(',')[i] == '1' ? true : false,
        frmTagValueId: tagValues.organizationcasetagvalueid.split(',')[i],
        frmTagSequence: tagValues.tagvaluesequence && tagValues.tagvaluesequence.length > 1 ? tagValues.tagvaluesequence.split(',')[i] : '',
      });
      (<FormArray>this.caseTagForm.get('tags')['controls'].at(tagIndex).get('frmtagValues')).push(frmgrp);
    }
  }

  gettagValueArray(empIndex: number): FormArray {
    return this.tagsFormArray()
      .at(empIndex)
      .get('frmtagValues') as FormArray;
  }

  newTag(): FormGroup {
    return this.fb.group({
      frmTagvaluename: '',
      frmTagValueNameBgColor: '',
      frmTagValueNameFontColor: '',
      frmDefault: false
    });
  }

  newtagValue(): FormGroup {
    return this.fb.group({
      frmTagvaluename: '',
      frmTagValueNameBgColor: '',
      frmTagValueNameFontColor: '',
      frmDefault: false
    });
  }

  addTagValue(empIndex: number) {
    let previousData = this.gettagValueArray(empIndex);
    if (previousData.value.length == 0 || previousData.value[previousData.value.length - 1].frmTagvaluename) {
      this.gettagValueArray(empIndex).push(this.newTag());
    }
    else {
      this._snackBar.open('Please provide the valid value.', 'Close');
    }
  }

  // remove tag value for case tags
  removeTagValue(tag: FormGroup, index: number) {
    this.isbuttonEnable = true;
    const tagValuesFormArray = tag.get('frmtagValues') as FormArray;
    // this.deletetagValueIds.push(tagValuesFormArray.value[index].frmTagValueId);
    // this.deletetagValues.push(tagValuesFormArray.value[index].frmTagvaluename);
    tagValuesFormArray.removeAt(index);
  }

  // remove tag for case tags
  removeTag(index: number) {
    this.isbuttonEnable = true;
    const tagFormArray = this.caseTagForm.get('tags') as FormArray;
    // this.deletedTags.push(tagFormArray.value[index].frmTagName)
    this.deletedIDs.push(tagFormArray.value[index].frmTagId)
    tagFormArray.removeAt(index);
  }

  // in create clear the input data
  inputDataClear() {
    this.caseTagsName.get('frmTagName')?.setValue('');
    let flagName = this.isFlagName;
    switch (flagName) {
      case 'Group Name':
        this.caseGroupForm.patchValue({
          frmTagGroupName: ''
        })
        break;

      case 'Tag Name':
        var item = (<FormArray>this.caseTagForm.get('tags')).at(this.tagNameIndex);
        item.patchValue({
          frmTagName: ''
        })
        break;

      case 'Tag Value Name':
        var item = (<FormArray>this.caseTagForm.get('tags')).at(this.tagNameIndex);
        let data = (<FormArray>item.get('frmtagValues')).at(this.tagValueIndex);
        data.patchValue({
          frmTagvaluename: ''
        })
        break;

      default:
        break;
    }
  }

  getUniqData(caseTagsName, caseGroupForm, type) {
    if (!this.isEditEnabled) {
      if (!this.validateGroupName(this.data.templateData.submenuData, caseGroupForm.value.frmTagGroupName)) {
        if (!this.validateTagName(this.caseTagForm.value.tags)) {
          let array = [];
          let tempTagVal = [], temTagValColor = [], tagvalfontcolor = [], tempTagSequence = [], tagvalIsDefault = [];
          this.caseTagForm.controls['tags'].value.forEach(element => {
            element.frmtagValues.forEach(element => {
              tempTagVal.push(element.frmTagvaluename)
              temTagValColor.push(element.frmTagValueNameBgColor ? element.frmTagValueNameBgColor : this.defaultBackgroundColor)
              tagvalfontcolor.push(element.frmTagValueNameFontColor ? element.frmTagValueNameFontColor : this.defaultFontColor)
              tempTagSequence.push(element.frmTagSequence)
              tagvalIsDefault.push(element.frmDefault);
            });
            let object = {
              frmTagType: type,
              frmTagGroupName: caseGroupForm.value.frmTagGroupName,
              frmGroupBgColor: caseGroupForm.value.frmGroupBgColor ? caseGroupForm.value.frmGroupBgColor: this.defaultBackgroundColor,
              frmTagFontColor: caseGroupForm.value.frmGroupFontColor ? caseGroupForm.value.frmGroupFontColor : this.defaultFontColor,
              frmTagName: element.frmTagName,
              frmGroupColor: element.frmTagBgColor ? element.frmTagBgColor : this.defaultBackgroundColor,
              frmFontColor: element.frmTagFontColor ? element.frmTagFontColor : this.defaultFontColor,
              frmTagSequence: '',
              frmTagValueGroup: [{
                frmTagValue: tempTagVal,
                frmTagValueSequence: tempTagSequence,
                frmTagValueColor: temTagValColor,
                frmTagValueFontColor: tagvalfontcolor,
                frmTagValueIsDefault: tagvalIsDefault,
              }],
              frmGroupId: null,
              frmTagId: element.frmTagId,
              frmIsActive: caseGroupForm.value.frmActive,
              frmAllowBulkEdit: caseGroupForm.value.frmAllowBulkEdit,
              frmAccountName: "ALL",
              frmCasetype: this.listCasetype && this.listCasetype?.length > 0 ? this.getDataFromOldList(this.listCasetype, 'listCasetype') : [{ casetype: "ALL" }],
              frmCaseStatus: this.listStatus && this.listStatus?.length > 0 ? this.getDataFromOldList(this.listStatus, 'listStatus') : [{ item: "ALL" }],
              frmRoles: this.listRoles && this.listRoles?.length > 0 ? this.getDataFromOldList(this.listRoles, 'listRoles') : [{ displayname: "ALL" }],
              frmNonEditableCaseStatus: caseGroupForm.value.frmNonEditableCaseStatus !== null ? caseGroupForm.value.frmNonEditableCaseStatus : '',
            };
            array.push(object);
            tempTagVal = [], temTagValColor = [], tagvalfontcolor = [], tempTagSequence = [];

          });
          return array;
        } else {
          this._snackbar.open("Duplicate tag names configured", "Close")
          return
        }
      } else {
        this._snackbar.open("Group name already exists", "Close")
        return
      }
    }
    else {
      if (!this.validateTagName(this.caseTagForm.value.tags)) {
        let array = [];
        let tempTagVal = [], temTagValColor = [], tempdefalultval = [], tagvalfontcolor = [], tagvalid = [], tempTagSequence = [];
        if (this.isGroupchanged) {
          this.isGroupNameChanged = false;
          if (this.oldGroupName?.toLowerCase() == caseGroupForm.value.frmTagGroupName.toLowerCase()) {
            this.isGroupNameChanged = true
          }
          let object = {
            frmTagType: 'Group',
            frmTagGroupName: caseGroupForm.value.frmTagGroupName,
            frmGroupBgColor: caseGroupForm.value.frmGroupBgColor ? caseGroupForm.value.frmGroupBgColor : this.defaultBackgroundColor,
            frmTagFontColor: caseGroupForm.value.frmGroupFontColor ? caseGroupForm.value.frmGroupFontColor : this.defaultFontColor,
            frmTagName: caseGroupForm.value.frmTagGroupName,
            frmGroupColor: caseGroupForm.value.frmGroupBgColor ? caseGroupForm.value.frmGroupBgColor :this.defaultBackgroundColor ,
            frmFontColor: caseGroupForm.value.frmGroupFontColor ? caseGroupForm.value.frmGroupFontColor : this.defaultFontColor,
            frmTagSequence: caseGroupForm.value.frmTagSequence,
            frmTagValueGroup: null,
            frmGroupId: caseGroupForm.value.frmGroupId,
            frmIsActive: caseGroupForm.value.frmActive,
            frmAllowBulkEdit: caseGroupForm.value.frmAllowBulkEdit,
            frmAccountName: "ALL",
            frmCasetype: this.listCasetype && this.listCasetype?.length > 0 ? this.getDataFromOldList(this.listCasetype, 'listCasetype') : this.getDataFromOldList(this.categoryList.caseType, 'listCasetype'),
            frmCaseStatus: this.listStatus && this.listStatus?.length > 0 ? this.getDataFromOldList(this.listStatus, 'listStatus') : this.getDataFromOldList(this.categoryList.caseStatus, 'listStatus'),
            frmRoles: this.listRoles && this.listRoles?.length > 0 ? this.getDataFromOldList(this.listRoles, 'listRoles') : this.getDataFromOldList(this.categoryList.roles, 'listRoles'),
            frmNonEditableCaseStatus: caseGroupForm.value.frmNonEditableCaseStatus !== null ? caseGroupForm.value.frmNonEditableCaseStatus : '',
          };
          array.push(object);
        }
        this.caseTagForm.controls['tags'].value.forEach(element => {
          element.frmtagValues.forEach(element => {
            tempTagVal.push(element.frmTagvaluename);
            tempdefalultval.push(element.frmDefault);
            temTagValColor.push(element.frmTagValueNameBgColor ? element.frmTagValueNameBgColor : this.defaultBackgroundColor);
            tagvalfontcolor.push(element.frmTagValueNameFontColor ? element.frmTagValueNameFontColor : this.defaultFontColor);
            tagvalid.push(element.frmTagValueId);
            tempTagSequence.push(element.frmTagSequence);
          });
          let object = {
            frmTagType: 'tag',
            frmTagGroupName: caseGroupForm.value.frmTagGroupName,
            frmGroupBgColor: caseGroupForm.value.frmGroupBgColor ? caseGroupForm.value.frmGroupBgColor : this.defaultBackgroundColor ,
            frmTagFontColor: caseGroupForm.value.frmGroupFontColor ? caseGroupForm.value.frmGroupFontColor : this.defaultFontColor,
            frmTagName: element.frmTagName,
            frmGroupColor: element.frmTagBgColor ?  element.frmTagBgColor : this.defaultBackgroundColor,
            frmFontColor: element.frmTagFontColor ? element.frmTagFontColor : this.defaultFontColor,
            frmTagSequence: element.frmTagSequence,
            frmTagValueGroup: [{
              frmTagValue: tempTagVal,
              frmTagValueSequence: tempTagSequence,
              frmTagValueColor: temTagValColor,
              frmTagValueFontColor: tagvalfontcolor,
              frmTagValueIsDefault: tempdefalultval,
              frmTagValueId: tagvalid.length > 0 ? tagvalid.filter(item => item) : ''
            }],
            frmGroupId: this.tempgroupid,
            frmTagId: element.frmTagId,
            frmIsActive: element.frmTagActive,
            frmAllowBulkEdit: caseGroupForm.value.frmAllowBulkEdit,
            frmAccountName: "ALL",
            frmCasetype: this.listCasetype && this.listCasetype?.length > 0 ? this.getDataFromOldList(this.listCasetype, 'listCasetype') : this.getDataFromOldList(this.categoryList.caseType, 'listCasetype'),
            frmCaseStatus: this.listStatus && this.listStatus?.length > 0 ? this.getDataFromOldList(this.listStatus, 'listStatus') : this.getDataFromOldList(this.categoryList.caseStatus, 'listStatus'),
            frmRoles: this.listRoles && this.listRoles?.length > 0 ? this.getDataFromOldList(this.listRoles, 'listRoles') : this.getDataFromOldList(this.categoryList.roles, 'listRoles'),
            frmNonEditableCaseStatus: caseGroupForm.value.frmNonEditableCaseStatus !== null ? caseGroupForm.value.frmNonEditableCaseStatus : '',
          };
          array.push(object);
          tempTagVal = [], temTagValColor = [], tagvalfontcolor = [], tagvalid = [], tempTagSequence = [];

        });
        return array;
      }
      else {
        this._snackbar.open("Duplicate tag names configured", "Close")
        return
      }
    }
  }

  getDataFromOldList(data, value) {
    let validData = data.filter(d => d['checked'] === true);
    if (validData.length > 0) {
      return validData
    } else {
      switch (value) {
        case 'listCasetype':
          return [{ casetype: "ALL" }]
          break;
        case 'listStatus':
          return [{ item: "ALL" }]
          break;
        case 'listRoles':
          return [{ displayname: "ALL" }]
          break;
      }
    }
  }

  validateTagName(data) {
    let valCount = {};
    for (let ele = 0; ele < data.length; ele++) {
      const value = data[ele].frmTagName.toLowerCase(); // Assuming frmTagName returns the tag name
      valCount[value] = (valCount[value] || 0) + 1;
    }
    for (let value in valCount) {
      if (valCount[value] > 1) {
        return true;
      }
    }
    // No duplicates found
    return false;
  }

  validateGroupName(data, groupname) {
    if (groupname.trim() == '') {
      this._snackbar.open("Please enter Group Name", "Close");
      return
    }
    let grpName = data ? data.some((va) => va.groupname == groupname.trim()) : false;
    if (grpName) {
      return true
    }
    return false
  }

  onCreateTags(type, casetagform, caseGroupForm) {
    if (type.toLowerCase() == 'create') {
      this.isEditEnabled = false;
      this.isEditable = false;
    } else {
      this.isEditEnabled = true
      this.isEditable = true
    }
    let getUniqData = this.getUniqData(casetagform, caseGroupForm, 'tag');
    this.addEditClicked = false;
    this.saveCloseCasetag(false, getUniqData)
  }

  validateTagval(data) {
    for (let l = 0; l < data.length; l++) {
      if (data[l] == '') {
        this._snackbar.open("tag value should not be empty", "Close");
        return true
      } else {
        return false
      }
    }
  }

  saveCloseCasetag(action, data) {
    let val = action ? 1 : 0;
    let temp = [];
    let selectedNCasestatusList = [];
    let CaseStatus = [];
    let CaseType = [];
    let Roles = [];
    let userid = (sessionStorage.getItem('Userid') == null || sessionStorage.getItem('Userid') == '') ? -100 : sessionStorage.getItem('Userid');

    if (this.validateFormData(data)) {
      return
    } else {
      for (let j = 0; j < data?.length; j++) {
        if (data[j].frmRoles && data[j].frmRoles.toString().toLowerCase() == 'all') {
          Roles.push('ALL');
        }
        else if (data[j].frmRoles && data[j].frmRoles.length > 0) {
          for (let i = 0; i < data[j].frmRoles.length; i++) {
            Roles.push(data[j].frmRoles[i].displayname)
          }
        }
        if (data[j].frmCaseStatus && data[j].frmCaseStatus.toString().toLowerCase() == 'all') {
          CaseStatus.push('ALL');
        }
        else if (data[j].frmCaseStatus && data[j].frmCaseStatus.length > 0) {
          for (let i = 0; i < data[j].frmCaseStatus.length; i++) {
            CaseStatus.push(data[j].frmCaseStatus[i].item)
          }
        }
        if (data[j].frmCasetype && data[j].frmCasetype.toString().toLowerCase() == 'all') {
          CaseType.push('ALL');
        }
        else if (data[j].frmCasetype && data[j].frmCasetype.length > 0) {
          for (let i = 0; i < data[j].frmCasetype.length; i++) {
            CaseType.push(data[j].frmCasetype[i].casetype)
          }
        }
        selectedNCasestatusList = [];
        if (data[j].frmNonEditableCaseStatus && data[j].frmNonEditableCaseStatus.toString().toLowerCase() == 'all') {
          selectedNCasestatusList.push('ALL');
        }
        else if (data[j].frmNonEditableCaseStatus && data[j].frmNonEditableCaseStatus.length > 0) {
          selectedNCasestatusList = selectedNCasestatusList.length ? selectedNCasestatusList : data[j].frmNonEditableCaseStatus?.map(va=> typeof(va) != 'string' ? va.item : va);
        }

        Roles = [...new Set(Roles)];
        CaseStatus = [...new Set(CaseStatus)];
        CaseType = [...new Set(CaseType)];
        if (data[j].frmTagValueGroup) {
          if (data[j].frmTagValueGroup.length > 0) {
            let ids = [], tagvaluesequenceorders = [];
            data[j].frmTagValueGroup.forEach(va => {
              if (va.frmTagValueId) {
                ids.push(va.frmTagValueId)
              }
              if (va.frmTagValueSequence.sort()) { tagvaluesequenceorders.push(va.frmTagValueSequence) }
            })
            if (data[j].frmTagValueGroup[0].frmTagValue.some(tag => !tag?.trim().length)) {
              this._snackbar.open("Tag value should not be empty", "Close");
              return;
            }
            for (let i = 0; i < data[j].frmTagValueGroup[0].frmTagValue.length; i++) {
              let isgroupVal = (data[j].frmTagType && data[j].frmTagType.toString().toLowerCase() == 'tag') ? null : 1
              let obj = {
                "slno": i + 1
                , "casetype": (CaseType && CaseType.length > 0) ? CaseType.toString() : 'ALL'
                , "accountid": ((!data[j].frmAccountName) || (data[j].frmAccountName && data[j].frmAccountName.toString().toLowerCase() == 'all')) ? -1 : this.selectedAccountid
                , "organizationid": this.destOrgid
                , "organizationtagid": data[j].frmTagId ? data[j].frmTagId : null
                , "casestatus": (CaseStatus && CaseStatus.length > 0) ? CaseStatus.toString() : "ALL"
                , "groupcolor": ((!data[j].frmGroupBgColor) || (data[j].frmGroupBgColor && !data[j].frmGroupBgColor.toString().trim())) ? null : data[j].frmGroupBgColor.toString().trim()
                , "groupfontcolor": ((!data[j].frmTagFontColor) || (data[j].frmTagFontColor && !data[j].frmTagFontColor.toString().trim())) ? null : data[j].frmTagFontColor.toString().trim()
                , "tagname": ((!data[j].frmTagName) || (data[j].frmTagName && !data[j].frmTagName.toString().trim())) ? null : data[j].frmTagName.toString().trim()
                , "groupid": data[j].frmGroupId ? data[j].frmGroupId : null
                , "isactive": data[j].frmIsActive ? true : false
                , "isgroup": isgroupVal
                , "sequenceorder": ((!data[j].frmTagSequence) || (data[j].frmTagSequence && !data[j].frmTagSequence.toString().trim())) ? null : data[j].frmTagSequence
                , "tagbgcolor": ((!data[j].frmGroupColor) || (data[j].frmGroupColor && !data[j].frmGroupColor.toString().trim())) ? null : data[j].frmGroupColor.toString().trim()
                , "fontcolor": ((!data[j].frmFontColor) || (data[j].frmFontColor && !data[j].frmFontColor.toString().trim())) ? null : data[j].frmFontColor.toString().trim()
                , "allowbulkedit": data[j].frmAllowBulkEdit ? true : false
                , "noteditablecasestatus": (selectedNCasestatusList && selectedNCasestatusList.length > 0) ? selectedNCasestatusList.toString() : ""
                , "groupname": (isgroupVal == 1) ? data[j].frmTagName.toString().trim() : ((!data[j].frmTagGroupName) || (data[j].frmTagGroupName && !data[j].frmTagGroupName.toString().trim())) ? null : data[j].frmTagGroupName.toString().trim()
                , "tagvalue": ((!data[j].frmTagValueGroup[0].frmTagValue[i]) || (data[j].frmTagValueGroup[0].frmTagValue[i] && !data[j].frmTagValueGroup[0].frmTagValue[i].toString().trim())) ? null : data[j].frmTagValueGroup[0].frmTagValue[i].toString().trim()
                , "isdefault": data[j].frmTagValueGroup[0].frmTagValueIsDefault[i] ? true : false
                , "tagvaluesequenceorder": ((!data[j].frmTagValueGroup[0].frmTagValueSequence[i]) || (data[j].frmTagValueGroup[0].frmTagValueSequence[i] && !data[j].frmTagValueGroup[0].frmTagValueSequence[i].toString().trim())) ? null : data[j].frmTagValueGroup[0].frmTagValueSequence[i].toString().trim()
                , "color": ((!data[j].frmTagValueGroup[0].frmTagValueColor[i]) || (data[j].frmTagValueGroup[0].frmTagValueColor[i] && !data[j].frmTagValueGroup[0].frmTagValueColor[i].toString().trim())) ? null : data[j].frmTagValueGroup[0].frmTagValueColor[i].toString().trim()
                , "tagvaluefontcolor": ((!data[j].frmTagValueGroup[0].frmTagValueFontColor[i]) || (data[j].frmTagValueGroup[0].frmTagValueFontColor[i] && !data[j].frmTagValueGroup[0].frmTagValueFontColor[i].toString().trim())) ? null : data[j].frmTagValueGroup[0].frmTagValueFontColor[i].toString().trim()
                , "createdby": userid
                , "roles": (Roles && Roles.length > 0) ? Roles.toString() : "ALL"
                , "organizationcasetagvalueids": ids.toString()
                , "copyflag": this.copyDataClicked ? 1 : 0
                , "tagvaluesequenceorders": tagvaluesequenceorders.toString()
                , "isgroupnamechanged":this.isGroupNameChanged?true:false
              }
              if (this.isEditEnabled) {
                obj = {
                  ...obj, ...{
                    "deletedids": this.deletedIDs && this.deletedIDs.length > 0 ? this.deletedIDs.toString() : null,
                    "organizationcasetagvalueid": data[j].frmTagValueGroup.length > 0 ? data[j].frmTagValueGroup[0].frmTagValueId[i] ? data[j].frmTagValueGroup[0].frmTagValueId[i] : null : null
                  }
                }
                if (this.validateTagval(data[j].frmTagValueGroup[0].frmTagValue)) {
                  return
                }
              }
              temp.push(obj)
              // let deletedObjs = {
              //   "deletedids": this.deletedTags && this.deletedTags.length > 0 ? this.deletedTags.toString() : null,
              //   "organizationcasetagvalueid": data[j].frmTagValueGroup.length > 0 ? data[j].frmTagValueGroup[0].frmTagValueId[i] ? data[j].frmTagValueGroup[0].frmTagValueId[i] : null : null,
              //   "deletetagValueIds":this.deletetagValues && this.deletetagValues.length >0 ? this.deletetagValues.toString() :null
              // }
              // temp.push(obj,deletedObjs)
            }
          }
        } else {
          let isgroupVal = (data[j].frmTagType && data[j].frmTagType.toString().toLowerCase() == 'tag') ? null : 1

          temp.push({
            "slno": 1
            , "casetype": (CaseType && CaseType.length > 0) ? CaseType.toString() : 'ALL'
            , "accountid": ((!data[j].frmAccountName) || (data[j].frmAccountName && data[j].frmAccountName.toString().toLowerCase() == 'all')) ? -1 : this.selectedAccountid
            , "organizationid": this.destOrgid
            , "organizationtagid": data[j].frmGroupId ? data[j].frmGroupId : null
            , "casestatus": (CaseStatus && CaseStatus.length > 0) ? CaseStatus.toString() : "ALL"
            , "groupcolor": ((!data[j].frmGroupBgColor) || (data[j].frmGroupBgColor && !data[j].frmGroupBgColor.toString().trim())) ? null : data[j].frmGroupBgColor.toString().trim()
            , "groupfontcolor": ((!data[j].frmTagFontColor) || (data[j].frmTagFontColor && !data[j].frmTagFontColor.toString().trim())) ? null : data[j].frmTagFontColor.toString().trim()
            , "groupid": null
            , "isactive": data[j].frmIsActive ? true : false
            , "isgroup": isgroupVal
            , "sequenceorder": ''
            , "tagbgcolor": ((!data[j].frmGroupColor) || (data[j].frmGroupColor && !data[j].frmGroupColor.toString().trim())) ? null : data[j].frmGroupColor.toString().trim()
            , "fontcolor": ((!data[j].frmFontColor) || (data[j].frmFontColor && !data[j].frmFontColor.toString().trim())) ? null : data[j].frmFontColor.toString().trim()
            , "allowbulkedit": data[j].frmAllowBulkEdit ? true : false
            , "noteditablecasestatus": (selectedNCasestatusList && selectedNCasestatusList.length > 0) ? selectedNCasestatusList.toString() : ""
            , "groupname": data[j].frmTagGroupName
            , "organizationcasetagvalueid": null
            , "tagvalue": null
            , "isdefault": data[j].frmDefault ? true : false
            , "isgroupnamechanged":this.isGroupNameChanged?true:false
          })
        }
      }
    }
    this.excelDataArray = [];
    this.excelDataArray = temp;
    if (this.excelDataArray.length > 0) {
      if (this.isEditEnabled) {
        this.updateOrganizationTags(this.excelDataArray)
      }
      else {
        this.addOrganizationTags(this.excelDataArray);
      }
    }
  }

  // remove tag
  removeFormControl(i) {
    let tempArray = this.caseTagForm.controls.frmTagValueGroup as FormArray;
    tempArray.removeAt(i);
  }

  async updateOrganizationTags(dataArray) {
    //this.ngxService.start();
    let userid = (sessionStorage.getItem('Userid') == null || sessionStorage.getItem('Userid') == '') ? -100 : sessionStorage.getItem('Userid');
    for (let i = 0; i < dataArray.length; i++) {
      dataArray[i]['createdby'] = userid;
      dataArray[i]['associationtype'] = this.data.Orgtype[0].associationtype;
    }
    let dataArray1 = dataArray.filter(a => !a.tagvaluesequenceorder)
    dataArray = dataArray.filter(a => a.tagvaluesequenceorder)
    dataArray = [...dataArray, ...dataArray1]

    let res = await this.VitalHttpServices.updateOrganizationTags(dataArray, this.destDeployment).toPromise();
    //this.ngxService.stop();
    if (res[0].status.toLowerCase() =='success') {
      this.modifyResForAudit(dataArray);
      this.auditObj
      this.dialogRef.close({ data: res });
      this.createCaseTagActivityObject(dataArray[0].groupid, dataArray[0].groupname, 'Case Tags', 'Edit', this.auditObj)
      this._snackbar.open("Update successful", "Success");
    }
    else {
      if (res[0].status.toLowerCase() == 'failed') {
        this._snackbar.open(res[0].notemessage, "Failure");
        return
      } else {
        this._snackbar.open("Update failed", "Failure");
        return
      }
    }
  }

  validateFormData(data) {
    for (let i = 0; i < data?.length; i++) {
      if ((!data[i].frmAccountName) || (!data[i].frmTagType) || (!data[i].frmTagName) ||
        (!data[i].frmCasetype || !data[i].frmCaseStatus)) {
        this._snackbar.open("Please enter mandatory fields to proceed further.", "Close");
        return true
      }
      else if (((data[i].frmTagType && data[i].frmTagType.toString().toLowerCase() != 'group') && ((data[i].frmRoles) && !(data[i].frmRoles.length > 0)))) {
        this._snackbar.open("Please select role to proceed further.", "Close");
        return true
      }
      else if ((data[i].frmTagType && data[i].frmTagType.toString().toLowerCase() != 'group') && (!data[i].frmTagGroupName)) {
        this._snackbar.open("Please enter group name", "Close");
        return true
      }
      else if (((data[i].frmCasetype) && !(data[i].frmCasetype.length > 0))
        || ((data[i].frmCaseStatus) && !(data[i].frmCaseStatus.length > 0))) {
        this._snackbar.open("Please enter madatory fields to proceed further.", "Close");
        return true
      }
      else if ((data[i].frmAccountName && data[i].frmAccountName.toString().toLowerCase() != "all") && ((data[i].frmAccountName) && (!this.AccountsList.some(e => e.accountname &&
        e.accountname.toString().toLowerCase().trim() == data[i].frmAccountName.toString().toLowerCase().trim())))) {
        this._snackbar.open("Please select valid location name from dropdown.", "Close");
        return true
      }
      else if (data[i].frmTagValueGroup) {
        if ((data[i].frmTagValueGroup.length > 0) && (this.toFindDuplicates(data[i].frmTagValueGroup, 'tagval'))) {
          this._snackbar.open("Multiple tagvalues with same name are present.", "Close");
          return true
        }
      }
      else if (this.toFindDuplicates(data[i], 'tagname')) {
        this._snackbar.open("Duplicate tag name are configured!", "Close");
        return true
      } else {
        return false
      }
    }
  }

  // Create/Add Case Tags
  async addOrganizationTags(dataArray: object[]) {
    let userid = (sessionStorage.getItem('Userid') == null || sessionStorage.getItem('Userid') == '') ? -100 : sessionStorage.getItem('Userid');
    for (let i = 0; i < dataArray.length; i++) {
      if (!this.addEditClicked) {
        dataArray[i]['associationtype'] = this.data.Orgtype[0].associationtype;
        dataArray[i]['createdby'] = userid;
        dataArray[i]['accountid'] = this.copyDataClicked ? -1 : dataArray[i]['accountid'];
        delete dataArray[i]['accountname']
        delete dataArray[i]['organizationcasetagvalueid']
        delete dataArray[i]['tagvaluesequence']
      }
      else {
        dataArray[i]['createdby'] = userid;
        dataArray[i]['associationtype'] = this.data.Orgtype[0].associationtype;
      }
    }
    let result = await this.VitalHttpServices.InsertOrganizationTags(dataArray, this.destDeployment).toPromise()//.subscribe(result => {
    this.noData = false;
    if (!result.errors) {
      if (this.addEditClicked) {
        this.noData = false;
        dataArray.filter((e: any) => {
          result.filter(r => {
            if (r.slno.toString() === e.slno.toString()) {
              e.status = r.status
              e.notes = r.notemessage;
            }
          });
        });
      }
      else {
        if (result.length > 0) {
          this.modifyResForAudit(dataArray, 1);
          let resultObj = {
            auditObj: this.auditObj,
            action: 'Create',
          }
          this.dialogRef.close({ data: result });
          this.createCaseTagActivityObject(result[0].groupid, result[0].groupname, 'Case Tags', 'Create', this.auditObj)
          this._snackbar.open('Created successfully', 'Success')
        }
        else {
          this._snackbar.open('Create failed', 'Failure')
        }
      }
    } else {
      this._snackbar.open('Create failed', 'Failure')
    }
  }

  closeModel() {
    this.isDialogShow = false;
  }

  toFindDuplicates(arr, key) {
    if (key == 'tagval') {
      let valCount = {};
      arr[0].frmTagValue.forEach(value => {
        valCount[value] = (valCount[value] || 0) + 1;
      });

      for (let value in valCount) {
        if (valCount[value] > 1) {
          return true;
        }
      }
      return false;
    }
  }

  // not editable case status
  onSelectAll(items: any, type) {
    if (type = 'ncasestatus') {
      this.isGroupchanged=true;
      this.selectedNCasestatusList = [];
      this.selectedNCasestatusList.push({ item: 'ALL' });
      this.caseGroupForm.patchValue({
        frmNonEditableCaseStatus: this.selectedNCasestatusList
      })
    }
  }

  public onDeSelectAll(type) {
    if (type == 'ncasestatus') {
      this.isGroupchanged=true;
      this.selectedNCasestatusList = [];
    }
  }

  onCheckChange(item: any, type) {
    this.isbuttonEnable = true
    this.isGroupchanged = true
    if (type = 'ncasestatus') {
      if (item.item.toString().toLowerCase() == 'all') {
        this.onDeSelectAll('ncasestatus')
        this.selectedNCasestatusList.push({ item: 'ALL' })
      } else {
        this.selectedNCasestatusList.push({ item: item.item.toString().trim() })
        this.selectedNCasestatusList = this.selectedNCasestatusList.filter(
          obj => !(obj && Object.keys(obj).length === 0)
        );
        this.selectedNCasestatusList = [...new Map(this.selectedNCasestatusList.map(item => [item["item"], item])).values()]

        for (let i = 0; i < this.selectedNCasestatusList.length; i++) {
          if (this.selectedNCasestatusList[i]['item'] && this.selectedNCasestatusList[i]['item'].toString().toLowerCase().trim() == 'all') {
            delete this.selectedNCasestatusList[i]
          }
        }
        this.selectedNCasestatusList = this.selectedNCasestatusList.filter(
          obj => !(obj && Object.keys(obj).length === 0)
        );
      }

      if (this.selectedNCasestatusList.some(a => a.item && a.item.toString().toLowerCase().trim() == 'all')) {
        this.selectedNCasestatusList = [];
        this.selectedNCasestatusList = [{ item: "ALL" }];
        this.caseGroupForm.patchValue({
          frmNonEditableCaseStatus: this.selectedNCasestatusList
        })
      } else {
        this.caseGroupForm.patchValue({
          frmNonEditableCaseStatus: this.selectedNCasestatusList
        })
      }
    }
  }

  onItemDeSelect(items: any, type) {
    this.isbuttonEnable = true;
    this.isGroupchanged=true;
    if (type = 'ncasestatus') {
      const index3: number = this.selectedNCasestatusList.indexOf(items.item);
      var indexNum = this.selectedNCasestatusList.findIndex((element) => {
        return (element.item === items.item);
      });
      if (indexNum != -1) {
        this.selectedNCasestatusList.splice(indexNum, 1);
      }
    }
  }

  // case tag activity tracker for audit
  modifyInitDataForAudit(inputData?) {
    let auditObj = this.getAuditObj(0);
    const assignoldObj = (va) => {
      return { "oldValue": va }
    }
    if (!inputData) {
      this.auditObj = auditObj;
      return
    }
    let auditColumns = this.getMappedColumnsForAudit(0).auditColumns;
    let resultColumns = this.getMappedColumnsForAudit(0).resultColumns;

    for (let i = 0; i < auditColumns.length; i++) {
      if(resultColumns[i].toLowerCase().trim() === 'roles')
      auditObj[auditColumns[i]] = assignoldObj(inputData.childList[0][resultColumns[i]]);
      else
      auditObj[auditColumns[i]] = assignoldObj(inputData[resultColumns[i]]);
    }
    auditObj["Tags"] = this.assignTagData(inputData.childList, assignoldObj);
    this.auditObj = auditObj;
  }


  checkForRole(data,column){
    if(!this.isEditEnabled){
      return data;
    }
    if(column.toLowerCase().trim() == 'roles' && data)
     return data.toString().toLowerCase().trim() == 'all' ? this.categoryList.roles.slice(1).map(va=> va.displayname).join(',') : data;
    else 
    return data;
  }

  modifyResForAudit(resData, create?) {
    let auditColumns = this.getMappedColumnsForAudit(0).auditColumns;
    let resultColumns = this.getMappedColumnsForAudit(0).resultColumns;
    if (this.isEditEnabled && resData.length > 0) {
      resData[0].roles = resData[0].roles || (resData[1]?.roles || 'All');
    }
    for (let [key, value] of Object.entries(this.auditObj)) {
      let index = auditColumns.indexOf(key);
      if (index != -1) {
        let data =  this.checkForRole(resData[0][resultColumns[index]],resultColumns[index]);
        value["newValue"] = this.assignNewValue(value["oldValue"], data, create);
        if (!create && typeof (value["newValue"]) == 'object') {
          delete this.auditObj[key]
          continue;
        }
        if (create && value["newValue"] == null) {
          delete this.auditObj[key]
          continue;
        }
      }
      else if (key.toString().toLowerCase() == "tags") {
        let tagNames = [], valueArr = [], tagIds = [];
        for (let i of resData) {
          if (!tagNames.some(va => va == i.tagname) && !i.isgroup) {
            tagNames.push(i.tagname);
            if (!create)
              tagIds.push(i.organizationtagid);
          }
        }
        tagNames.forEach(name => {
          let tagData = resData.filter(val => (val["tagname"] == name && val.isgroup != 1))
          if (tagData && tagData.length)
            valueArr = [...valueArr, ...this.mapAuditColForTags(tagData, tagIds, create)];
        })
        this.auditObj[key] = valueArr;
      }
    }
  }

  mapAuditColForTags(tagObj, tagIds?, create?) {
    let auditColumns = this.getMappedColumnsForAudit(1).auditColumns;
    let resultColumns = this.getMappedColumnsForAudit(1).resultColumns;
    let tagAuditList = [];
    let tagAuditObj = {}
    let oldObj = this.auditObj["Tags"].filter(va => va['TagID']["oldValue"] == tagObj[0]["organizationtagid"]);
    for (let i = 0; i < auditColumns.length; i++) {
      tagObj[0][resultColumns[i]] ? tagAuditObj[auditColumns[i]] = { "newValue": this.assignNewValue((oldObj && oldObj[0]) ? oldObj[0][auditColumns[i]]["oldValue"] : null, tagObj[0][resultColumns[i]], create) } : delete tagObj[0][resultColumns[i]];
      if (oldObj && oldObj[0]) {
        tagAuditObj[auditColumns[i]] = { ...tagAuditObj[auditColumns[i]], ...oldObj[0][auditColumns[i]] }
      }
      if (create && (tagAuditObj[auditColumns[i]] && tagAuditObj[auditColumns[i]]["newValue"] == null)) {
        delete tagAuditObj[auditColumns[i]];
        continue;
      }
      if (!create && (!tagAuditObj[auditColumns[i]] || !tagAuditObj[auditColumns[i]]["newValue"] || typeof (tagAuditObj[auditColumns[i]]["newValue"]) == 'object')) {
        delete tagAuditObj[auditColumns[i]];
        continue;
      }
    }
    let tabNameTracker = tagObj[0].tagname;
    
    // if(!create && this.mapAuditColForTagValues(tagObj, oldObj, create)?.length > 0 && !tagAuditObj['Tag Name'] && oldObj?.[0]?.['Tag Name']) 
    //   tagAuditObj['Tag Name'] = { oldValue : oldObj[0]['Tag Name'].oldValue , newValue : oldObj[0]['Tag Name'].oldValue} 
    
    if(this.mapAuditColForTagValues(tagObj, oldObj, create)?.length){
    tagAuditObj[tabNameTracker] = this.mapAuditColForTagValues(tagObj, oldObj, create);
    tagAuditList.push(tagAuditObj);
    }
    return tagAuditList;
  }

  mapAuditColForTagValues(tagObj, oldObj?, create?) {
    let auditColumns = this.getMappedColumnsForAudit(2).auditColumns;
    let resultColumns = this.getMappedColumnsForAudit(2).resultColumns;
    let tagValAuditList = [];
    if (create) {
      tagObj.forEach(tagvalue => {
        let tagValAuditObj = {};
        for (let i = 0; i < auditColumns.length; i++) {
          tagvalue[resultColumns[i]] ? tagValAuditObj[auditColumns[i]] = { "newValue": tagvalue[resultColumns[i]] } : null;
        }
        if (Object.keys(tagValAuditObj).length)
          tagValAuditList.push(tagValAuditObj)
      })
      return tagValAuditList;
    }
    else {
      tagObj.forEach(tagvalue => {
        let tagValAuditObj = {};
        let oldValObj = (oldObj && oldObj.length) ? oldObj[0]["TagValues"].filter(val => val["TagValueId"]["oldValue"] == tagvalue["organizationcasetagvalueid"]) : [];
        for (let i = 0; i < auditColumns.length; i++) {
          tagvalue[resultColumns[i]] ? tagValAuditObj[auditColumns[i]] = { "newValue": this.assignNewValue((oldValObj && oldValObj[0] && oldValObj[0][auditColumns[i]]) ? oldValObj[0][auditColumns[i]]["oldValue"] : null, tagvalue[resultColumns[i]], create) } : delete tagValAuditObj[resultColumns[i]];
          if (oldValObj && oldValObj[0]) {
            tagValAuditObj[auditColumns[i]] = { ...tagValAuditObj[auditColumns[i]], ...oldValObj[0][auditColumns[i]] }
          }
          if (create && (!tagValAuditObj[auditColumns[i]] || !tagValAuditObj[auditColumns[i]]["newValue"] || tagValAuditObj[auditColumns[i]]["newValue"] == null)) {
            delete tagValAuditObj[auditColumns[i]];
            continue;
          }
          if (!create && (!tagValAuditObj[auditColumns[i]] || !tagValAuditObj[auditColumns[i]]["newValue"] || typeof (tagValAuditObj[auditColumns[i]]["newValue"]) == 'object')) {
            delete tagValAuditObj[auditColumns[i]];
            continue;
          }
        }
        if (Object.keys(tagValAuditObj).length)
          tagValAuditList.push(tagValAuditObj)
      })
      return tagValAuditList;
    }
  }

  assignNewValue(oldValue, data, create?) {
    //Create
    if (create) {
      return data;
    }

    //Edit
    if (typeof (data) != 'boolean') {
      if (!oldValue) {
        oldValue = null
      }
      if (!data) {
        data = null
      }
    }

    if ((oldValue == data) || (oldValue?.toString().toLowerCase() == data?.toString().toLowerCase())) {
      return { val: "notChanged" };
    }
    else {
      return data;
    }
  }

  assignTagData(childList, assignoldObj) {
    let tagList = [];
    let auditColumns = this.getMappedColumnsForAudit(1).auditColumns;
    let resultColumns = this.getMappedColumnsForAudit(1).resultColumns;
    childList.forEach(tagData => {
      let tagObj = this.getAuditObj(1);
      for (let i = 0; i < auditColumns.length; i++) {
        tagObj[auditColumns[i]] = assignoldObj(tagData[resultColumns[i]]);
      }
      tagObj["TagValues"] = this.assignTagValues(tagData, assignoldObj);
      tagList.push(tagObj);
    })
    return tagList;
  }

  assignTagValues(tagData, assignOldObj) {
    let tagValueList = []
    let tagValueLength = tagData.tagvalue ? tagData.tagvalue.split(',').length : 0
    const splitStr = (str) => {
      return str ? str.split(',') : null;
    }
    let auditColumns = this.getMappedColumnsForAudit(2).auditColumns;
    let resultColumns = this.getMappedColumnsForAudit(2).resultColumns;
    for (let i = 0; i < tagValueLength; i++) {
      let tagValObj = this.getAuditObj(2);
      for (let j = 0; j < auditColumns.length; j++) {
        tagData[resultColumns[j]] ? tagValObj[auditColumns[j]] = assignOldObj(splitStr(tagData[resultColumns[j]])[i]) : null;
      }
      tagValueList.push(tagValObj);
    }
    return tagValueList;
  }

  getAuditObj(level) {
    if (!level)
      return {
        "Group Name": {},
        "Group Bg Color": {},
        "Group Font Color": {},
        "Status": {},
        "Allow Bulk Edit": {},
        "CaseType": {},
        "Case Status": {},
        "Roles": {},
        "Non Editable Case Status": {},
        "Tags": []
        // "Deleted Tag": []
      }
    else if (level == 1) {
      return {
        "Tag Name": {},
        "Tag Bg Color": {},
        "Tag Color": {},
        "Status": {},
        "Drag": {},
        "TagValues": []
      }
    }
    else {
      return {
        "Tag Value Name": {},
        "Tag Value Bg Color": {},
        "Tag Value Color": {},
        "Drag": {},
        "Default": {}
        // "Deleted Tag Value": []
      }
    }
  }

  getMappedColumnsForAudit(level) {
    if (!level) {
      return {
        auditColumns: ["Group Name", "Group Bg Color", "Group Font Color", "Status", "Allow Bulk Edit", "CaseType", "Case Status", "Roles", "Non Editable Case Status"],
        resultColumns: ["groupname", "groupcolor", "groupfontcolor", "isactive", "allowbulkedit", "casetype", "casestatus", "roles", "noteditablecasestatus"]
      }
    }
    else if (level == 1) {
      return {
        auditColumns: ["Tag Name", "Tag Bg Color", "Tag Color", "Status", "Drag", "TagID"], //,"Deleted Tag"
       resultColumns: ["tagname", "tagbgcolor", "fontcolor", "isactive", "sequenceorder", "organizationtagid"] //,"deletedids"
      }
    }
    else {
      return {
        auditColumns: ["Tag Value Name", "Tag Value Bg Color", "Tag Value Color", "Default", "Drag", "TagValueId"], //,"Deleted Tag Value"
       resultColumns: ["tagvalue", "color", "tagvaluefontcolor", "isdefault", "tagvaluesequence", "organizationcasetagvalueid"] //,"deletetagValueIds"
      }
    }
  }

  // sending data to activity tracker
  createCaseTagActivityObject(entityid, changedValue, entityType, actionType, extendedAttributesObj, uploadedFile?: any) {
    try {
      let internalUserRegex = /^\w+([\.-]?\w+)@starmarkit|@vitalaxis(\.\w{2,3})+$/;
      let date = new Date();
      let entityTypeContext: any
      let deployid = sessionStorage.getItem('DeploymentKey')
      this.activityService.getActivitySession.subscribe(activityTab => entityTypeContext = activityTab);
      this.createActivityInput.productId = this.VitalHttpServices.GetDxurlBasedOnDeployment(deployid).replace(/(^\w+:|^)\/\//, '');
      this.createActivityInput.product = 'VitalDx';
      this.createActivityInput.customerId = sessionStorage.getItem('org_id');
      this.createActivityInput.customerName = sessionStorage.getItem('Org_Name');
      this.createActivityInput.entityType = entityType;
      this.createActivityInput.actionType = 'audit';
      this.createActivityInput.userName = localStorage.getItem('user_name');
      this.createActivityInput.userId = localStorage.getItem('UserEmail');
      this.createActivityInput.locationId = sessionStorage.getItem('Locationid');
      this.createActivityInput.locationName = sessionStorage.getItem('locationName');
      this.createActivityInput.isInternal = internalUserRegex.test(localStorage.getItem('UserEmail').toLowerCase());
      this.createActivityInput.transactionId = this.vaservice.generateGuid();
      this.createActivityInput.ticketId = 'Tic' + this.datePipe.transform(date, 'dd-MM-yyyyHH:mm a');
      this.createActivityInput.ticketInfo = actionType + " " + entityType;
      // let attachments = {};
      this.createActivityInput.entityId = entityid;
      this.createActivityOperations.info = this.getActivityInfo(actionType, entityType, changedValue);
      this.createActivityOperations.extendedAttributes = extendedAttributesObj;
      this.createActivityOperations.attachments = null;
      this.createActivityInput.operation = this.createActivityOperations;
      this.createActivityInput.context = entityTypeContext.context;
      if (Object.keys(this.createActivityOperations.extendedAttributes).length != 0) {
        this.VitalHttpServices.CreateActivity(this.createActivityInput)
          .subscribe((res) => { });
      }
    } catch (error) {
      console.error("Error occured while creating activity");
    }
  }

  getActivityInfo(actionType: any, entityType: any, changedValue: any) {
    let activityTypeList = {
      'Edit': 'Modified ',
      'Delete': 'Deleted ',
      'Upload': 'Uploaded ',
      'Create': 'Created new ',
      'Add': 'Added ',
      'Copy': 'Copied ',
      'Migrate': 'Migrated ',
      'Export': 'Exported ',
      'Bulk Update': 'Bulk Update '
    }
    return activityTypeList[actionType] + entityType + ' "' + changedValue + '" ';
  }

  // fetch roles
  fetchRoles(existingRoles) {
    const roleList = [...this.data.RoleList.slice(1)];
    return this.data.currentData.childList[0].roles?.split(',').length == roleList.length ? ['ALL'] : existingRoles
  }
}

