<div class="row p-0 m-0">
    <div class="col-md-12 p-0 m-0">
        <div class="modal-header admin-model-header mt-3">
            <h3 class="modal-title w-100 admin-model-header-txt">
                <strong *ngIf="!editMode">Setup New {{ContextValue}}</strong>
                <strong *ngIf="editMode">Edit {{ContextValue}}</strong>
            </h3>
        </div>
    </div>
</div>
<!-- Tabs section -->
<div id="setupnewFacilityMatUi" class="row p-0 m-0 mt-3">
    <div class="col-12 p-0 m-0 mb-3" style="border: 1px solid #e5e5e5; padding: 10px !important; border-radius: 25px;">
        <mat-tab-group [selectedIndex]="selectedTab.value" class="stretched-tabs">
            <mat-tab [disabled]="hideFacilityTab" label="{{ContextValue}}">
                <div class="row p-0 m-0 mt-3 p-1 facilityTabHeight">
                    <div class="col-12 p-0 m-0">
                        <!-- Facility Details -->
                        <form [formGroup]="frm_SendData" autocomplete="off">
                            <div class="button-freeze">
                                <div class="row p-0 m-0" style="border-bottom: 1px solid #fcd2a2;">
                                    <div class="col-12 p-0 m-0">
                                        <span class="tabDetailsHeader"><strong>{{ContextValue}} Details</strong></span>
                                    </div>
                                </div>
                                <div class="row p-0 m-0 mt-3 pl-3 mb-5">
                                    <div class="col-12 p-0 m-0">
                                        <div class="row p-0 m-0">
                                            <div class="col-sm-7 p-0 m-0">
                                                <div class="row p-0 m-0 mb-2" id="input-container">
                                                    <div class="col-sm-7 p-0 m-0">
                                                        <!-- NPI Registry Lookup-->
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>NPI&nbsp;</mat-label><!-- autocomplete="off" -->
                                                            <input matInput placeholder="Enter Valid NPI" maxlength="10" type="text"
                                                                autocomplete="off" class="form-control radius-change form-font"
                                                                formControlName="frm_NPI" (keyup.enter)="ValidateNPIandgetData()"
                                                                (input)="captureNPI(frm_SendData.value.frm_NPI)" />
                                                            <!-- <mat-icon matSuffix>
                                                                                        <button type="button" (click)="ValidateNPIandgetData()"
                                                                                            style="position: relative;top: -5px;left: -15px;"
                                                                                            [disabled]="frm_SendData.value.frm_NPI.trim() == '' || frm_SendData.value.frm_NPI.toLowerCase() == 'unknown'">
                                                                                            <em class="fa fa-check" title="Validate"></em>
                                                                                        </button>
                                                                                    </mat-icon> -->
                                                            <!-- <mat-hint class="text-danger">Enter Valid NPI</mat-hint> -->
                                                        </mat-form-field>
                                                        <div *ngIf="NPIWarning" class="text-danger errorDisplayFont">
                                                            NPI is not valid !</div>
                                                        <div *ngIf="validationWarning" class="text-danger errorDisplayFont">
                                                            Please validate the NPI !
                                                        </div>
                                                        <br />
                                                    </div>
                                                </div>
                                                <div class="row p-0 m-0 mb-2">
                                                    <div class="col-sm-12 p-0 m-0 word-wrap">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Name&nbsp;
                                                                <!-- <span class="error-msg">*</span> -->
                                                            </mat-label>
                                                            <input matInput placeholder="Enter Name" id="name" class="form-control radius-change"
                                                                formControlName="frm_name" maxlength="174"
                                                                (input)="generateMnemonic(frm_SendData.value.frm_name)" />
                                                        </mat-form-field>
                                                        <div *ngIf="(submitted && formcontrol.frm_name.invalid) || (submitted && (!frm_SendData.value.frm_name || frm_SendData.value.frm_name == ''))"
                                                            style="display:table">
                                                            <span class="text-danger requiredError errorDisplayFont">
                                                                Name is required !</span>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                            
                                                <!-- Search NPI / Registry Button  -->
                                                <div class="row m-0 p-0 mt-2 justify-content-end">
                                                    <button type="button" mat-raised-button class="admin-btn-success" (click)=openSearchNPIPopup()>
                                                        Search NPI
                                                    </button>
                                                </div>
                            
                                            </div>
                                            <div class="col-sm-5 p-0 m-0 mb-2">
                                                <div class="row p-0 m-0">
                                                    <div class="col-12 p-0 m-0 padLeftDisplay" style="display: table">
                                                        <!-- Add Logo -->
                                                        <div style="display: table-cell;text-align: center; margin: auto; vertical-align: middle;"
                                                            *ngIf="!imagePath">
                                                            <div class="custom-dropzone image-height" appDnd (fileDropped)="onFileDropped($event)">
                                                                <input type="file" id="fileDropRef" (change)="onFileChange($event)" accept="image/*"
                                                                    [multiple]="false" hidden />
                                                                <span class="mb-2" style="font-size:12px">Drag & Drop
                                                                    Logo here</span>&nbsp;<span class="mb-2"><strong>or</strong></span>&nbsp;
                                                                <label class="btn-sm btn rounded browse" for="fileDropRef">Browse
                                                                    Logo</label>
                                                            </div>
                                                        </div>
                                                        <div class="image-heigh" *ngIf="imagePath" style="width: 60%;">
                                                            <img alt="image" style="max-width: 100%;max-height: 100%;width: 100%;height:100%;"
                                                                [src]="frm_logo" alt="" srcset="">
                                                        </div>
                                                        <!-- to delete logo -->
                                                        <div *ngIf="imagePath && !disbaleCreateBtn" title="Remove Logo" (click)="onRemove($event)"
                                                            style="position: absolute;top:40%;right:25%; cursor: pointer !important;">
                                                            <img alt="delete" src="\assets\images\delete.jpg" style="width: 15px;">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row p-0 m-0">
                                            <div class="col-sm-7 p-0 m-0">
                                                <div class="row p-0 m-0 mb-2">
                                                    <div class="col-sm-6 p-0 m-0 word-wrap pr-1">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Address 1</mat-label>
                                                            <input matInput placeholder="Enter Address 1" formControlName="frm_Address1" type="text"
                                                                maxlength="100" class="form-control radius-change form-font" />
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-sm-6 p-0 m-0 word-wrap pl-1">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Address 2</mat-label>
                                                            <input matInput placeholder="Enter Address 2" formControlName="frm_Address2" type="text"
                                                                maxlength="100" class="form-control radius-change form-font" />
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row p-0 m-0 mb-2">
                                                    <div class="col-sm-6 p-0 m-0 word-wrap pr-1">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>City</mat-label>
                                                            <input matInput placeholder="Enter City" formControlName="frm_city" type="text"
                                                                maxlength="25" class="form-control radius-change form-font" />
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-sm-6 p-0 m-0 word-wrap pl-1">
                                                        <mat-form-field appearance="outline" class="frmMatSelect w-100">
                                                            <mat-label>State</mat-label>
                                                            <mat-select disableOptionCentering class="form-control form-font"
                                                                formControlName="frm_state"
                                                                style="padding: 0.17rem 0px !important; border: none !important;">
                                                                <!-- <mat-option value="" selected disabled>Select
                                                                                          State</mat-option> -->
                                                                <!-- <mat-option value="" selected disabled></mat-option> -->
                                                                <mat-option>--Select--</mat-option>
                                                                <mat-option *ngFor="let state of StateList" value="{{state.itemValue}}">
                                                                    {{state.item}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row p-0 m-0 mb-2">
                                                    <div class="col-sm-6 p-0 m-0 word-wrap pr-1">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Country</mat-label>
                                                            <input matInput placeholder="Enter Country" formControlName="frm_country" type="text"
                                                                maxlength="25" class="form-control radius-change form-font" />
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-sm-6 p-0 m-0 pl-1">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Zip</mat-label>
                                                            <input matInput placeholder="Enter Zip" (keypress)="AllowOnlyNumberandSpclChar($event)"
                                                                formControlName="frm_zip" type="text" maxlength="10"
                                                                class="form-control radius-change form-font" />
                                                        </mat-form-field>
                                                        <div *ngIf="submitted && !zipValid">
                                                            <span class="text-danger errorDisplayFont">
                                                                Zip is not valid !</span>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row p-0 m-0 mb-2">
                                                    <div class="col-sm-6 p-0 m-0 word-wrap pr-1">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Fax</mat-label>
                                                            <input matInput placeholder="Enter Fax" (keypress)="AllowOnlyNumberandSpclChar($event)"
                                                                formControlName="frm_fax" type="text" maxlength="50"
                                                                class="form-control radius-change form-font" />
                                                        </mat-form-field>
                                                        <div *ngIf="submitted && !faxValid">
                                                            <span class="text-danger errorDisplayFont">
                                                                Fax is not valid !</span>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 p-0 m-0 pl-1">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Phone</mat-label>
                                                            <input matInput placeholder="Enter Phone"
                                                                (keypress)="AllowOnlyNumberandSpclChar($event)" formControlName="frm_phone"
                                                                maxlength="20" type="text" class="form-control radius-change form-font" />
                                                        </mat-form-field>
                                                        <div *ngIf="submitted && !phoneValid">
                                                            <span class="text-danger errorDisplayFont">
                                                                Phone is not valid !</span>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row p-0 m-0 mb-2">
                                                    <div class="col-sm-8 p-0 m-0 word-wrap pr-1">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Display Name&nbsp;
                                                                <!-- <span class="error-msg">*</span> -->
                                                            </mat-label>
                                                            <input [(ngModel)]="frm_SendData.value.frm_displayname" matInput
                                                                placeholder="Enter Display Name" id="displayname" class="form-control
                                                                       radius-change" formControlName="frm_displayname" maxlength="200" />
                                                        </mat-form-field>
                                                        <div *ngIf="submitted && formcontrol.frm_displayname.invalid">
                                                            <span class="text-danger errorDisplayFont">
                                                                Display Name is required !</span>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4 p-0 m-0 pl-1">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Short Name&nbsp;
                                                                <!-- <span class="error-msg">*</span> -->
                                                            </mat-label>
                                                            <input [(ngModel)]="frm_SendData.value.frm_shortname" matInput
                                                                placeholder="Enter Short Name" type="text" formControlName="frm_shortname"
                                                                maxlength="10" class="form-control radius-change form-font" />
                                                        </mat-form-field>
                                                        <div *ngIf="submitted && formcontrol.frm_shortname.invalid">
                                                            <span class="text-danger requiredError  text-center errorDisplayFont">
                                                                Short Name is required !</span>
                                                            <br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row p-0 m-0 ml-0 mb-2">
                                                    <!-- <div class="col-sm-6 p-0 m-0 word-wrap pr-1">
                                                                              <mat-form-field appearance="outline" class="frmMatSelect w-100"
                                                                                  *ngIf="salesUsersList && salesUsersList.length > 0">
                                                                                  <mat-label>Sales Executive</mat-label>
                                                                                  <mat-select disableOptionCentering
                                                                                      class="form-control form-font"
                                                                                      formControlName="frm_salesExecutive" multiple
                                                                                      style="padding: 0.17rem 0px !important; border: none !important;">
                                                                                      <mat-option *ngFor="let serviceUser of salesUsersList"
                                                                                          value="{{serviceUser.UserId}}">
                                                                                          {{serviceUser.Display_Name}}
                                                                                      </mat-option>
                                                                                  </mat-select>
                                                                              </mat-form-field>
                                                                              <mat-form-field appearance="outline" class="frmMatSelect w-100"
                                                                                  *ngIf="!(salesUsersList && salesUsersList.length > 0)">
                                                                                  <mat-label>Sales Executive</mat-label>
                                                                                  <mat-select disableOptionCentering
                                                                                      class="form-control form-font"
                                                                                      formControlName="frm_salesExecutive"
                                                                                      style="padding: 0.17rem 0px !important; border: none !important;">
                                                                                      <mat-option>--Select--</mat-option>
                                                                                  </mat-select>
                                                                              </mat-form-field>
                                                                          </div> -->
                                                    <div *ngIf="ContextValue != 'EPG'" class="col-sm-6 p-0 m-0 pl-1">
                                                        <mat-form-field appearance="outline" class="frmMatSelect w-80">
                                                            <mat-label>Default Service Type</mat-label>
                                                            <mat-select disableOptionCentering class="form-control form-font"
                                                                formControlName="frm_DefaultServiceType"
                                                                style="padding: 0.17rem 0px !important; border: none !important;">
                                                                <!-- <mat-option value="" selected disabled></mat-option> -->
                                                                <mat-option>--Select--</mat-option>
                                                                <mat-option *ngFor="let Service of ServiceList" value="{{Service.item}}">
                                                                    {{Service.item}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row p-0 m-0 mb-2">
                                                    <div class="col-sm-6 p-0 m-0 word-wrap pr-1">
                                                        <mat-form-field appearance="outline" class="frmMatSelect w-100">
                                                            <mat-label>Claim Options&nbsp;
                                                                <!-- <span class="error-msg">*</span> -->
                                                            </mat-label>
                                                            <mat-select disableOptionCentering class="form-control form-font"
                                                                formControlName="frm_ClaimOptions"
                                                                style="padding: 0.17rem 0px !important; border: none !important;">
                                                                <!-- <mat-option value="" selected disabled>Select
                                                                                          Claim Options</mat-option> -->
                                                                <!-- <mat-option value="" selected disabled></mat-option> -->
                                                                <mat-option *ngFor="let ClaimOptions of ClaimOptionsList"
                                                                    value="{{ClaimOptions.item}}">
                                                                    {{ClaimOptions.item}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-sm-6 p-0 m-0 pl-1">
                                                        <mat-form-field appearance="outline" class="frmMatSelect w-100"
                                                            *ngIf="CourierUsersList && CourierUsersList.length > 0">
                                                            <mat-label>Courier</mat-label>
                                                            <!-- <mat-select disableOptionCentering #matSelectOption
                                                                                      class="form-control form-font" formControlName="frm_Courier"
                                                                                      [ngModelOptions]="{standalone: true}" multiple
                                                                                      style="padding: 0.17rem 0px !important; border: none !important;">
                                                                                      <mat-option *ngFor="let CourierUsers of CourierUsersList"
                                                                                          [value]="CourierUsers.Display_Name">
                                                                                          {{CourierUsers.Display_Name}}
                                                                                      </mat-option>
                                                                                  </mat-select> -->
                                                            <mat-select disableOptionCentering #matSelectOption formControlName="frm_Courier"
                                                                [ngModelOptions]="{standalone: true}" multiple>
                                                                <mat-option (click)="optionClick()" *ngFor="let CourierUsers of CourierUsersList"
                                                                    [value]="CourierUsers.UserId">{{CourierUsers.Display_Name}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                        <mat-form-field appearance="outline" class="frmMatSelect w-100"
                                                            *ngIf="!(CourierUsersList && CourierUsersList.length > 0)">
                                                            <mat-label>Courier</mat-label>
                                                            <mat-select disableOptionCentering class="form-control form-font"
                                                                formControlName="frm_Courier"
                                                                style="padding: 0.17rem 0px !important; border: none !important;">
                                                                <mat-option>--Select--</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-5 p-0 m-0 padLeftDisplay">
                                                <div class="row p-0 m-0 mb-2">
                                                    <div class="col-md-10 p-0 m-0">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>EIN</mat-label>
                                                            <input matInput placeholder="Enter EIN" formControlName="frm_ein" type="text"
                                                                maxlength="10" class="form-control radius-change form-font" />
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row p-0 m-0 mb-2">
                                                    <div class="col-md-10 p-0 m-0">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>CLIA</mat-label>
                                                            <input matInput placeholder="Enter CLIA" formControlName="frm_clia" maxlength="10"
                                                                type="text" class="form-control radius-change form-font" />
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row p-0 m-0 mb-2">
                                                    <div class="col-md-10 p-0 m-0">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Account Code</mat-label>
                                                            <input matInput placeholder="Enter Account Code" formControlName="frm_AccountCode"
                                                                type="text" maxlength="25" class="form-control radius-change form-font" />
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row p-0 m-0 mb-2">
                                                    <div class="col-md-10 p-0 m-0">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Location Code</mat-label>
                                                            <input matInput placeholder="Enter Location Code" formControlName="frm_Location_Code"
                                                                maxlength="180" type="text" class="form-control radius-change form-font" />
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row p-0 m-0">
                                                    <div class="col-md-10 p-0 m-0">
                                                        <mat-form-field appearance="outline" class="w-100 mb-2">
                                                            <mat-label>Client Number/Code</mat-label>
                                                            <input matInput placeholder="Enter Client Number/Code" formControlName="frm_Client_Code"
                                                                maxlength="25" type="text" class="form-control radius-change form-font" />
                                                        </mat-form-field>
                                                        <mat-form-field appearance="outline" class="w-100 mb-2" *ngIf="editMode">
                                                            <mat-label>L2L config ID</mat-label>
                                                            <input matInput placeholder="Enter L2L config ID" (change)="checkval($event)"
                                                                formControlName="frm_L2LConfig_ID" maxlength="25" type="text"
                                                                class="form-control radius-change form-font" />
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row p-0 m-0">
                                                    <div class="col-md-10 p-0 m-0">
                                                        <span class="matCheckboxColor" *ngIf="ContextValue!='Send-In'">
                                                            <mat-checkbox [color]="task.color" style="cursor: pointer !important;"
                                                                [(checked)]="checkExternal" (change)="checkExternalFunc($event)">
                                                                <span class="text-ellipsis form-font" style="cursor: default !important;">
                                                                    External Report is not Mandatory</span>
                                                            </mat-checkbox>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Laboratory Association -->
                                <div class="row p-0 m-0 mt-3" style="border-bottom: 1px solid #fcd2a2;">
                                    <div class="col-6 p-0 m-0">
                                        <span class="tabDetailsHeader"><strong>Laboratory Association
                                                <span class="error-msg">&nbsp;*&nbsp;</span></strong></span>
                                    </div>
                                    <div class="col-6 p-0 m-0" *ngIf="submitted && NoLabAssociated">
                                        <span class="text-danger pull-right errorDisplayFont">Atleast one Location must be
                                            associated !</span>
                                    </div>
                                </div>
                                <div class="row p-0 m-0 mt-2 mb-5 padLeftDisplay" *ngIf="!hideLabLocList">
                                    <div class="col-10 p-0 m-0">
                                        <strong>{{orgName}}</strong>
                                    </div>
                                    <div class="col-2 p-0 m-0">
                                        <span class="matCheckboxColor pull-right mr-2">
                                            <mat-checkbox [color]="task.color" style="cursor: pointer !important;" [(checked)]="checkAllLab"
                                                class="mt-1" (change)="checkAllLabFunc()">
                                                <span>All</span>
                                            </mat-checkbox>
                                        </span>
                                    </div>
                                    <div class="col-12 row p-0 m-0">
                                        <div *ngFor="let LabLocation of LabLocationList"
                                            [title]="LabLocation.AccountName + '(' + LabLocation.AccountID + ')'"
                                            class="col-2 p-0 mr-2 mt-3 matCheckboxColor text-ellipsis">
                                            <mat-checkbox [color]="task.color" [(checked)]="LabLocation.Checked" style="cursor: pointer !important;"
                                                (change)="checkLabLocation(LabLocation.AccountID)">
                                                <span style="cursor: default !important;">{{LabLocation.AccountName}}</span>
                                            </mat-checkbox>
                            
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="hideLabLocList" class="row p-0 m-0 align-center mt-4">
                                    <div class="col-3 nodata-wrap">
                                        <span class="nodata-design">
                                            No Data Found</span>
                                    </div>
                                </div>
                                <!-- Primary Contact Details -->
                                <div class="row p-0 m-0 mt-3" style="border-bottom: 1px solid #fcd2a2;">
                                    <div class="col-12 p-0 m-0">
                                        <span class="tabDetailsHeader"><strong>Primary Contact Details</strong></span>
                                    </div>
                                </div>
                                <div class="row p-0 m-0 my-2 padLeftDisplay">
                                    <div class="col-4 p-0 m-0 pr-2">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Name</mat-label>
                                            <input matInput placeholder="Enter Name" formControlName="frm_PrimaryName" maxlength="100" type="text"
                                                class="form-control radius-change form-font" />
                                        </mat-form-field>
                                        <div *ngIf="submitted && formcontrol.frm_PrimaryName.invalid">
                                            <span class="text-danger errorDisplayFont">Name is not
                                                valid !</span>
                                            <br />
                                            <br />
                                        </div>
                                    </div>
                                    <div class="col-4 p-0 m-0 pr-2">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Phone</mat-label>
                                            <input matInput placeholder="Enter Phone" formControlName="frm_PrimaryPhone" maxlength="20"
                                                type="number" class="form-control radius-change form-font" />
                                        </mat-form-field>
                                        <div *ngIf="submitted && !primaryPhoneValid">
                                            <span class="text-danger errorDisplayFont">Phone is not
                                                valid !</span>
                                            <br />
                                            <br />
                                        </div>
                                    </div>
                                    <div class="col-4 p-0 m-0 pr-2">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Email ID</mat-label>
                                            <input matInput placeholder="Enter Email ID" formControlName="frm_PrimaryEmail" maxlength="50"
                                                type="email" class="form-control radius-change form-font" />
                                        </mat-form-field>
                                        <div *ngIf="submitted && !primaryEmailValid">
                                            <span class="text-danger errorDisplayFont">Email is not
                                                valid !</span>
                                            <br />
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Facility Buttons -->
                            <div class="row col-sm-12">
                                <div class="col-6 p-0 m-0">
                                    <button mat-raised-button class="admin-btn-success mr-4" type="button" (click)="openPopUp('facilityDetails','previous')">Previous</button>

                                    <button mat-raised-button class="admin-btn-success" type="submit" (click)="openPopUp('facilityDetails','next')">Next</button>
                                </div>
                                <div class="col-6 p-0 m-0 flex-right">
                                    <button mat-raised-button class="admin-btn-success mr-4" type="button" (click)="openPopUp('facilityDetails','cancel')">Cancel</button>
                                    <button mat-raised-button class="admin-btn-success" type="submit" (click)="SaveOFDetails('facilityDetails')">Save</button>
                                </div>
                            </div>
                            <div class="row p-0 m-0 mt-2">
                                <div class="col-md-10 d-block mx-auto">
                                    <div [innerHtml]="errorAlertDiv" class="text-danger text-center errorDisplayFont">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </mat-tab>
            <mat-tab [disabled]="hideCaseTypeTab" label="Case Type">
                <!-- <div class="row p-0 m-0 mt-3">
                  <div class="col-12 p-0 m-0">
                      <div class="row p-0 m-0" style="border-bottom: 1px solid #fcd2a2;">
                          <div class="col-12 p-0 m-0">
                              <span class="tabDetailsHeader"><strong>Associate Case Types&nbsp;</strong></span>
                              <span style="padding-inline: 2vw">
                                  <span [innerHtml]="errorAlertCasetypeOnNoLab"
                                      class="text-danger text-center errorDisplayFont pull-right"></span>
                              </span>
                          </div>
                      </div>
                      <div class="row p-0 m-0 my-3 pl-3" style="font-weight: 500;">
                          <div class="col-12 p-0 m-0">
                              <div class="row p-0 m-0" *ngIf="showCasetype">
                                  <div class="mt-2 col-8 p-0 m-0 pull-left">
                                      <strong>Speciality:</strong>
                                  </div>
                                  <div class="mt-2 col-4 p-0 m-0 pull-right matCheckboxColor">
                                      <mat-checkbox  style="cursor: pointer !important;" [disabled] = "editMode ? true : false" [(checked)]="checkAllCaseType"
                                          class="float-right" (change)="checkAllCasetypes()">
                                          <strong>All</strong>
                                      </mat-checkbox>
                                  </div>
                              </div>
                              <div class="row p-0 m-0 align-center mt-4" *ngIf="!showCasetype">
                                  <div class="col-3 nodata-wrap">
                                      <span class="nodata-design">
                                          No Data Found</span>
                                  </div>
                              </div>
                              <div *ngIf="showCasetype" class="row p-0 m-0 matCheckboxColor"
                                  style="max-height: 400px; overflow-y: auto;">
                                  <div class="col-12 p-0 m-0">
                                      <div class="row p-0 m-0" *ngFor="let item of filteredCategoryList">
                                          <div class="col-12 mt-2 p-0 m-0 pull-left">
                                              <div class="row p-0 m-0">
                                                  <div class="col-12 p-0 m-0">
                                                      <mat-checkbox [disabled] = "editMode ? true : false" style="cursor: pointer !important;"
                                                          class="casetype-margin" [(checked)]="item.checked"
                                                          (change)="checkCatergory(item.Category)">
                                                          <strong>{{item.Category}}</strong>
                                                      </mat-checkbox>
                                                  </div>
                                              </div>
                                              <div class="row p-0 m-0 pr-5 casetype-list-section">
                                                  <div *ngFor="let case of item.caseTypes" [title]="case.casetype"
                                                      class="col-4 p-0 m-0 pl-5 mt-3 text-ellipsis">
                                                      <mat-checkbox  [disabled] = "editMode ? true : false" [checked]="case.checked"
                                                          style="position: absolute;bottom: -25%;cursor: pointer !important;"
                                                          (change)="checkCasetypes(item.Category, case.casetype)">
                                                      </mat-checkbox>
                                                      <span class="pl-4"
                                                          style="cursor: default !important;">{{case.casetype}}</span>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="row col-sm-12 mt-4">
                                  <div class="col-6 p-0 m-0">
                                      <button type="button" mat-raised-button class="admin-btn-success mr-4"
                                          (click)="openPopUp('casesTypeDetails','previous')">Previous</button>
                                      <button type="button" mat-raised-button class="admin-btn-success"
                                          (click)="openPopUp('casesTypeDetails','next')">Next</button>
                                  </div>
                                  <div class="col-6 p-0 m-0 flex-right">
                                      <button type="button" mat-raised-button class="admin-btn-success mr-4"
                                          (click)="openPopUp('casesTypeDetails','cancel')">Cancel</button>
                                      <button type="button" mat-raised-button class="admin-btn-success"
                                          (click)="SaveOFDetails('casesTypeDetails')">Save</button>
                                  </div>
                              </div>
                              <div class="row p-0 m-0 mt-4">
                                  <div class="col-12 p-0 m-0 text-center">
                                      <span style="padding-inline: 2vw">
                                          <span [innerHtml]="errorAlertCasetype"
                                              class="text-danger text-center errorDisplayFont"></span>
                                      </span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div> -->
                <app-casetype-configuration [source]="'NewEntity'" [mode]="editMode ? 'edit' : 'create'"
                    [editOrganizationID]='editData.OrganizationID' [editAccountid]='editData.Associated_Account_Id'
                    [accountid]="null"></app-casetype-configuration>

            </mat-tab>
            <mat-tab [disabled]="hideSummaryTab" label="Summary">
                <div class="row p-0 m-0 mt-3 facilityTabHeight" *ngIf="summaryData && summaryData.hasOwnProperty('OrgLabAccData')">
                    <div class="col-12 p-0 m-0">
                        <!-- facility details preview-->
                        <mat-accordion>
                            <mat-expansion-panel [expanded]="true">
                                <mat-expansion-panel-header class="accordianheader">
                                    <mat-panel-title class="mainTitle">
                                        {{NameEntites}} Details
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div id="setupCwc" class="row p-0 m-0">
                                    <div class="col-12 p-0 m-0 mt-2 mb-5">
                                        <div class="row p-0 m-0" style="border-bottom: 1px solid #fcd2a2;">
                                            <div class="col-10 p-0 m-0" style="color: #095682;">
                                                <strong><span style="word-break: break-all;">{{summaryData.OrgLabAccData.organizationname}}</span>&nbsp;<span
                                                        style="word-break: break-all;">({{summaryData.OrgLabAccData.organizationid}})</span></strong>
                                            </div>
                                            <!-- individual facility details download -->
                                            <div class="col-2 p-0 m-0">
                                                <!-- <em title="Download PDF" class="fa fa-download float-right"
                                                  style="cursor: pointer !important;" (click)="downloadPDF()"></em> -->
                                            </div>
                                        </div>
                                        <div class="row p-0 m-0">
                                            <div class="col-8 p-0 m-0">
                                                <div class="row p-0 m-0 mt-2">
                                                    <div class="col-12 p-0 m-0">
                                                        <strong>Display
                                                            Name:</strong>&nbsp;&nbsp;<span style="word-break: break-all;">{{summaryData.OrgLabAccData.organizationname}}</span>&nbsp;&nbsp;&nbsp;<strong>|&nbsp;&nbsp;&nbsp;Short
                                                            Name:</strong>&nbsp;&nbsp;<span style="word-break: break-all;">{{summaryData.OrgLabAccData.mnemonic}}</span>
                                                    </div>
                                                </div>
                                                <div class="row p-0 m-0">
                                                    <div class="col-12 p-0 m-0 mt-1">
                                                        <strong>EIN #:</strong>&nbsp;&nbsp;{{summaryData.OrgLabAccData.ein
                                                        &&
                                                        summaryData.OrgLabAccData.ein != '' ?
                                                        summaryData.OrgLabAccData.ein :
                                                        'Not Specified'}}
                                                    </div>
                                                </div>
                                                <div class="row p-0 m-0">
                                                    <div class="col-12 p-0 m-0 mt-1">
                                                        <strong>Owner
                                                            Lab:</strong>&nbsp;&nbsp;<span style="word-break: break-all;">{{summaryData.OrgLabAccData.ownerLabName
                                                            &&
                                                            summaryData.OrgLabAccData.ownerLabName != '' ?
                                                            summaryData.OrgLabAccData.ownerLabName :
                                                            'Not Specified'}}</span>
                                                    </div>
                                                </div>
                                                <div class="row p-0 m-0">
                                                    <div class="col-12 p-0 m-0 mt-1">
                                                        <strong>No. of
                                                            Locations:</strong>&nbsp;&nbsp;{{summaryData.OrgLabAccData.noOfLocations
                                                        &&
                                                        summaryData.OrgLabAccData.noOfLocations != '' ?
                                                        summaryData.OrgLabAccData.noOfLocations :
                                                        'Not Specified'}}
                                                    </div>
                                                </div>
                                                <div class="row p-0 m-0">
                                                    <div class="col-12 p-0 m-0 mt-1">
                                                        <strong>No. of
                                                            Users:</strong>&nbsp;&nbsp;{{summaryData.OrgLabAccData.noOfUsers
                                                        &&
                                                        summaryData.OrgLabAccData.noOfUsers != '' ?
                                                        summaryData.OrgLabAccData.noOfUsers :
                                                        'Not Specified'}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-4 p-0 m-0 mt-2">
                                                <img alt="image" class="float-right fitImage" style="border: 1px solid #e5e5e5;"
                                                    *ngIf="summaryData.OrgLabAccData.Logo && summaryData.OrgLabAccData.Logo != ''"
                                                    [src]="'data:image/jpg;base64,'+ summaryData.OrgLabAccData.Logo" />
                                                <div class="fitImage float-right text-center" style="font-size: 12px;border: 1px solid #e5e5e5;"
                                                    *ngIf="!summaryData.OrgLabAccData.Logo || summaryData.OrgLabAccData.Logo == ''">
                                                    <div style="display: table;width: 100%; height: 100%;">
                                                        <div style="display: table-cell;vertical-align: middle;">No
                                                            Logo
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row p-0 m-0 mt-3" style="border-bottom: 1px solid #fcd2a2;">
                                            <div class="col-9 p-0 m-0" style="color:#095682"><strong>{{ContextValue}}
                                                    Location Details</strong>
                                            </div>
                                        </div>
                                        <div class="row p-0 m-0 mt-2">
                                            <div class="col-8 p-0 m-0">
                                                <strong>Name:</strong>&nbsp;&nbsp;<span style="word-break: break-all;">{{summaryData.AccDetails.accountname}}</span>
                                            </div>
                                            <div class="col-4 p-0 m-0 text-right">
                                                <strong>Location
                                                    Id:</strong>&nbsp;&nbsp;{{summaryData.AccDetails.accountid}}
                                            </div>
                                        </div>
                                        <div class="row p-0 m-0">
                                            <div class="col-8 p-0 m-0 mt-2">
                                                <strong>Display Name:</strong>&nbsp;&nbsp;<span style="word-break: break-all;">{{summaryData.AccDetails.displayname}}</span>&nbsp;&nbsp;&nbsp;<strong>|&nbsp;&nbsp;&nbsp;Short
                                                    Name:
                                                </strong>&nbsp;&nbsp;<span style="word-break: break-all;">{{summaryData.AccDetails.ShortName}}</span>
                                            </div>
                                            <div class="col-4 p-0 m-0 text-right">
                                                <div class="row p-0 m-0">
                                                    <div class="col-12 p-0 m-0">
                                                        <strong>NPI
                                                            #:</strong>&nbsp;&nbsp;{{summaryData.AccDetails.npi.toUpperCase()}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row p-0 m-0 mt-2">
                                            <div class="col-8 p-0 m-0">
                                                <div class="row p-0 m-0">
                                                    <div class="col-2 p-0 m-0">
                                                        <strong>Address:</strong>&nbsp;&nbsp;
                                                    </div>
                                                    <div class="col-10 p-0 m-0" *ngIf="(summaryData.AccDetails.address1 && summaryData.AccDetails.address1 != '')
                      || (summaryData.AccDetails.address2 && summaryData.AccDetails.address2 == '')
                      || (summaryData.AccDetails.city && summaryData.AccDetails.city != '')
                      || (summaryData.AccDetails.state && summaryData.AccDetails.state != '')
                      || (summaryData.AccDetails.zip && summaryData.AccDetails.zip != '')
                      || (summaryData.AccDetails.fax && summaryData.AccDetails.fax != '')
                      || (summaryData.AccDetails.phone && summaryData.AccDetails.phone != '') ">
                                                        <div class="row p-0 m-0">
                                                            <div class="col-12 p-0 m-0">
                                                                <span *ngIf="summaryData.AccDetails.address1 && summaryData.AccDetails.address1 != ''">
                                                                    <span style="word-break: break-all;">{{summaryData.AccDetails.address1}},</span>&nbsp;</span>
                                                                <span *ngIf="summaryData.AccDetails.address2 && summaryData.AccDetails.address2 != ''">
                                                                    <span style="word-break: break-all;">{{summaryData.AccDetails.address2}}</span>
                                                                </span>
                                                                <span *ngIf="!(summaryData.AccDetails.address1 && summaryData.AccDetails.address1 != '') && !(summaryData.AccDetails.address2 && summaryData.AccDetails.address2 != '')">
                                                                    <span style="word-break: break-all;">Not
                                                                        Specified</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 p-0 m-0" *ngIf="(summaryData.AccDetails.address1 && summaryData.AccDetails.address1 != '')
                                                  || (summaryData.AccDetails.address2 && summaryData.AccDetails.address2 == '')
                                                  || (summaryData.AccDetails.city && summaryData.AccDetails.city != '')
                                                  || (summaryData.AccDetails.state && summaryData.AccDetails.state != '')
                                                  || (summaryData.AccDetails.zip && summaryData.AccDetails.zip != '')
                                                  || (summaryData.AccDetails.fax && summaryData.AccDetails.fax != '')
                                                  || (summaryData.AccDetails.phone && summaryData.AccDetails.phone != '') ">
                                                        <strong>City:</strong>&nbsp;&nbsp;
                                                    </div>
                                                    <div class="col-10 p-0 m-0" *ngIf="(summaryData.AccDetails.address1 && summaryData.AccDetails.address1 != '')
                                                  || (summaryData.AccDetails.address2 && summaryData.AccDetails.address2 == '')
                                                  || (summaryData.AccDetails.city && summaryData.AccDetails.city != '')
                                                  || (summaryData.AccDetails.state && summaryData.AccDetails.state != '')
                                                  || (summaryData.AccDetails.zip && summaryData.AccDetails.zip != '')
                                                  || (summaryData.AccDetails.fax && summaryData.AccDetails.fax != '')
                                                  || (summaryData.AccDetails.phone && summaryData.AccDetails.phone != '') ">
                                                        <div class="row p-0 m-0">
                                                            <div class="col-12 p-0 m-0">
                                                                <span *ngIf="summaryData.AccDetails.city && summaryData.AccDetails.city != ''">
                                                                    <span style="word-break: break-all;">{{summaryData.AccDetails.city}}
                                                                    </span>&nbsp;
                                                                </span>
                                                                <span *ngIf="!(summaryData.AccDetails.city && summaryData.AccDetails.city != '')">
                                                                    <span style="word-break: break-all;">Not Specified
                                                                    </span>&nbsp;
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 p-0 m-0" *ngIf="(summaryData.AccDetails.address1 && summaryData.AccDetails.address1 != '')
                                                  || (summaryData.AccDetails.address2 && summaryData.AccDetails.address2 == '')
                                                  || (summaryData.AccDetails.city && summaryData.AccDetails.city != '')
                                                  || (summaryData.AccDetails.state && summaryData.AccDetails.state != '')
                                                  || (summaryData.AccDetails.zip && summaryData.AccDetails.zip != '')
                                                  || (summaryData.AccDetails.fax && summaryData.AccDetails.fax != '')
                                                  || (summaryData.AccDetails.phone && summaryData.AccDetails.phone != '') ">
                                                        <strong>State:</strong>&nbsp;&nbsp;
                                                    </div>
                                                    <div class="col-10 p-0 m-0" *ngIf="(summaryData.AccDetails.address1 && summaryData.AccDetails.address1 != '')
                                                  || (summaryData.AccDetails.address2 && summaryData.AccDetails.address2 == '')
                                                  || (summaryData.AccDetails.city && summaryData.AccDetails.city != '')
                                                  || (summaryData.AccDetails.state && summaryData.AccDetails.state != '')
                                                  || (summaryData.AccDetails.zip && summaryData.AccDetails.zip != '')
                                                  || (summaryData.AccDetails.fax && summaryData.AccDetails.fax != '')
                                                  || (summaryData.AccDetails.phone && summaryData.AccDetails.phone != '') ">
                                                        <div class="row p-0 m-0">
                                                            <div class="col-12 p-0 m-0">
                                                                <span *ngIf="summaryData.AccDetails.state && summaryData.AccDetails.state != ''">
                                                                    <span style="word-break: break-all;">{{summaryData.AccDetails.state}}</span>
                                                                </span>
                                                                <span *ngIf="!(summaryData.AccDetails.state && summaryData.AccDetails.state != '')">
                                                                    <span style="word-break: break-all;">Not
                                                                        Specified</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 p-0 m-0" *ngIf="(summaryData.AccDetails.address1 && summaryData.AccDetails.address1 != '')
                                                  || (summaryData.AccDetails.address2 && summaryData.AccDetails.address2 == '')
                                                  || (summaryData.AccDetails.city && summaryData.AccDetails.city != '')
                                                  || (summaryData.AccDetails.state && summaryData.AccDetails.state != '')
                                                  || (summaryData.AccDetails.zip && summaryData.AccDetails.zip != '')
                                                  || (summaryData.AccDetails.fax && summaryData.AccDetails.fax != '')
                                                  || (summaryData.AccDetails.phone && summaryData.AccDetails.phone != '') ">
                                                        <strong>Zip:</strong>&nbsp;&nbsp;
                                                    </div>
                                                    <div class="col-10 p-0 m-0" *ngIf="(summaryData.AccDetails.address1 && summaryData.AccDetails.address1 != '')
                                                  || (summaryData.AccDetails.address2 && summaryData.AccDetails.address2 == '')
                                                  || (summaryData.AccDetails.city && summaryData.AccDetails.city != '')
                                                  || (summaryData.AccDetails.state && summaryData.AccDetails.state != '')
                                                  || (summaryData.AccDetails.zip && summaryData.AccDetails.zip != '')
                                                  || (summaryData.AccDetails.fax && summaryData.AccDetails.fax != '')
                                                  || (summaryData.AccDetails.phone && summaryData.AccDetails.phone != '') ">
                                                        <div class="row p-0 m-0">
                                                            <div class="col-12 p-0 m-0">
                                                                <span *ngIf="summaryData.AccDetails.zip && summaryData.AccDetails.zip != ''">
                                                                    <span style="word-break: break-all;">{{summaryData.AccDetails.zip}}</span>&nbsp;
                                                                </span>
                                                                <span *ngIf="!(summaryData.AccDetails.zip && summaryData.AccDetails.zip != '')">
                                                                    <span style="word-break: break-all;">Not
                                                                        Specified</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 p-0 m-0" *ngIf="(summaryData.AccDetails.address1 && summaryData.AccDetails.address1 != '')
                                                  || (summaryData.AccDetails.address2 && summaryData.AccDetails.address2 == '')
                                                  || (summaryData.AccDetails.city && summaryData.AccDetails.city != '')
                                                  || (summaryData.AccDetails.state && summaryData.AccDetails.state != '')
                                                  || (summaryData.AccDetails.zip && summaryData.AccDetails.zip != '')
                                                  || (summaryData.AccDetails.fax && summaryData.AccDetails.fax != '')
                                                  || (summaryData.AccDetails.phone && summaryData.AccDetails.phone != '') ">
                                                        <strong>Fax:</strong>&nbsp;&nbsp;
                                                    </div>
                                                    <div class="col-10 p-0 m-0" *ngIf="(summaryData.AccDetails.address1 && summaryData.AccDetails.address1 != '')
                                                  || (summaryData.AccDetails.address2 && summaryData.AccDetails.address2 == '')
                                                  || (summaryData.AccDetails.city && summaryData.AccDetails.city != '')
                                                  || (summaryData.AccDetails.state && summaryData.AccDetails.state != '')
                                                  || (summaryData.AccDetails.zip && summaryData.AccDetails.zip != '')
                                                  || (summaryData.AccDetails.fax && summaryData.AccDetails.fax != '')
                                                  || (summaryData.AccDetails.phone && summaryData.AccDetails.phone != '') ">
                                                        <div class="row p-0 m-0">
                                                            <div class="col-12 p-0 m-0">
                                                                <span *ngIf="summaryData.AccDetails.fax && summaryData.AccDetails.fax != ''">
                                                                    <span style="word-break: break-all;">{{summaryData.AccDetails.fax}}</span>
                                                                </span>
                                                                <span *ngIf="!(summaryData.AccDetails.fax && summaryData.AccDetails.fax != '')">
                                                                    <span style="word-break: break-all;">Not
                                                                        Specified</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 p-0 m-0" *ngIf="(summaryData.AccDetails.address1 && summaryData.AccDetails.address1 != '')
                                                  || (summaryData.AccDetails.address2 && summaryData.AccDetails.address2 == '')
                                                  || (summaryData.AccDetails.city && summaryData.AccDetails.city != '')
                                                  || (summaryData.AccDetails.state && summaryData.AccDetails.state != '')
                                                  || (summaryData.AccDetails.zip && summaryData.AccDetails.zip != '')
                                                  || (summaryData.AccDetails.fax && summaryData.AccDetails.fax != '')
                                                  || (summaryData.AccDetails.phone && summaryData.AccDetails.phone != '') ">
                                                        <strong>Phone:</strong>&nbsp;&nbsp;
                                                    </div>
                                                    <div class="col-10 p-0 m-0" *ngIf="(summaryData.AccDetails.address1 && summaryData.AccDetails.address1 != '')
                                                  || (summaryData.AccDetails.address2 && summaryData.AccDetails.address2 == '')
                                                  || (summaryData.AccDetails.city && summaryData.AccDetails.city != '')
                                                  || (summaryData.AccDetails.state && summaryData.AccDetails.state != '')
                                                  || (summaryData.AccDetails.zip && summaryData.AccDetails.zip != '')
                                                  || (summaryData.AccDetails.fax && summaryData.AccDetails.fax != '')
                                                  || (summaryData.AccDetails.phone && summaryData.AccDetails.phone != '') ">
                                                        <div class="row p-0 m-0">
                                                            <div class="col-12 p-0 m-0">
                                                                <span *ngIf="summaryData.AccDetails.phone && summaryData.AccDetails.phone != ''">
                                                                    <span style="word-break: break-all;">{{summaryData.AccDetails.phone}}</span>
                                                                </span>
                                                                <span *ngIf="!(summaryData.AccDetails.phone && summaryData.AccDetails.phone != '')">
                                                                    <span style="word-break: break-all;">Not
                                                                        Specified</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-8 p-0 m-0" *ngIf="(!summaryData.AccDetails.address1 || summaryData.AccDetails.address1 == '')
                      && (!summaryData.AccDetails.address1 || summaryData.AccDetails.address1 == '')
                      && (!summaryData.AccDetails.city || summaryData.AccDetails.city == '')
                      && (!summaryData.AccDetails.state || summaryData.AccDetails.state == '')
                      && (!summaryData.AccDetails.zip || summaryData.AccDetails.zip == '')
                      && (!summaryData.AccDetails.fax || summaryData.AccDetails.fax == '')
                      && (!summaryData.AccDetails.phone || summaryData.AccDetails.phone == '') ">
                                                        <div class="row p-0 m-0">
                                                            <div class="col-12 p-0 m-0">
                                                                Not Specified
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-4 p-0 m-0 text-right">
                                                <strong>GUID #:</strong>&nbsp;&nbsp;{{summaryData.AccDetails.AccountGUID}}
                                            </div>
                                        </div>
                                        <div class="row p-0 m-0 mt-3" style="border-bottom: 1px solid #fcd2a2;">
                                            <div class="col-12 p-0 m-0" style="color: #095682;">
                                                <strong>Case Type Details</strong>
                                            </div>
                                        </div>
                                        <div class="row p-0 m-0 mt-3">
                                            <div class="col-12 p-0 m-0">
                                                <table>
                                                    <tr>
                                                        <th><strong>Case Type</strong></th>
                                                        <th><strong>Display Name</strong></th>
                                                        <th><strong>Case Type Category</strong></th>
                                                    </tr>
                                                    <tr *ngFor="let casetype of summaryData.CasetypeData">
                                                        <ng-container>
                                                            <td><span style="word-break: break-all;">{{casetype.casetype}}</span>
                                                            </td>
                                                            <td>
                                                                <span style="word-break: break-all;">{{casetype.CaseTypeDisplayname
                                                                    && casetype.CaseTypeDisplayname ?
                                                                    casetype.CaseTypeDisplayname : 'Not Specified'}}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span style="word-break: break-all;">
                                                                    {{casetype.Category && casetype.Category ?
                                                                    casetype.Category : 'Not Specified'}}</span>
                                                            </td>
                                                        </ng-container>
                                                    </tr>
                                                    <ng-container *ngIf="summaryData.CasetypeData && summaryData.CasetypeData == ''">
                                                        <tr>
                                                            <td [attr.colspan]="'3'" class="text-center mt-4" style="padding: 20px">
                                                                <span class="nodata-design">No Data Found</span>
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                </table>
                                            </div>
                                        </div>
                                        <!-- <div class="row p-0 m-0 mt-3" style="border-bottom: 1px solid #fcd2a2;">
                                          <div class="col-12 p-0 m-0" style="color:#095682">
                                              <strong>{{ContextValue}} User Details</strong>
                                          </div>
                                      </div> -->
                                        <!-- <div class="row p-0 m-0 my-1">
                                          <div class="col-10 p-0 m-0">
                                          </div> -->
                                        <!-- <div class="col-2 p-0 m-0" style="color:#095682">
                                              <em title="Add Users" class="fa fa-user-plus float-right"
                                                  style="cursor: pointer !important;font-size: 20px;"
                                                  (click)="addUsers()"></em>
                                          </div> -->
                                        <!-- </div> -->
                                        <!-- <div class="row p-0 m-0 mt-2">
                                          <div class="col-12 p-0 m-0">
                                              <table>
                                                  <tr>
                                                      <th><strong>Name</strong></th>
                                                      <th><strong>Role</strong></th>
                                                      <th><strong>Email ID</strong></th>
                                                  </tr>
                                                  <tr *ngFor="let user of summaryData.UsersData">
                                                      <ng-container>
                                                          <td><span
                                                                  style="word-break: break-all;">{{user.FormattedDisplayName}}</span>
                                                          </td>
                                                          <td><span
                                                                  style="word-break: break-all;">{{user.rolename}}</span>
                                                          </td>
                                                          <td>
                                                              <span style="word-break: break-all;">{{user.email &&
                                                                  user.email ? user.email : 'Not
                                                                  Specified'}}</span>
                                                          </td>
                                                      </ng-container>
                                                  </tr>
                                                  <ng-container
                                                      *ngIf="summaryData.UsersData && summaryData.UsersData == ''">
                                                      <tr>
                                                          <td [attr.colspan]="'3'" class="text-center mt-4"
                                                              style="padding: 20px">
                                                              <span  class="nodata-design">No Data Found</span>
                                                          </td>
                                                      </tr>
                                                  </ng-container>
                                              </table>
                                          </div>
                                      </div> -->

                                    </div>
                                    <br />
                                    <hr />
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <br />
                        <!-- Summary Buttons -->
                        <!-- <div class="row p-0 m-0 mt-4">
                          <div class="col-6 p-0 m-0 ">
                          </div>
                          <div class="col-6 p-0 m-0">-->
                        <!-- <button mat-raised-button type="button" class="mx-1 admin-btn-success pull-right" (click)="finishSetup()">Close</button>-->
                        <!-- <button mat-raised-button type="button" class="mx-1 admin-btn-success pull-right"
                                  (click)="finishSetup()">Finish Setup</button>
                          </div>
                      </div> -->
                    </div>
                </div>
                <div class="row p-0 m-0 mt-3">
                    <!-- *ngIf="!(summaryData && summaryData.hasOwnProperty('OrgLabAccData'))" -->
                    <div class="col-12 p-0 m-0">
                        <!-- Summary Buttons -->
                        <div class="row p-0 m-0 mt-4">
                            <div class="col-6 p-0 m-0 flex-left">
                                <button type="button" mat-raised-button class="admin-btn-success" (click)="exportData()">Export</button>
                            </div>
                            <div class="col-6 p-0 m-0 flex-right">
                                <!-- <button type="button" mat-raised-button class="admin-btn-success mx-1 pull-right" (click)="finishSetup()">Close</button> -->
                                <button *ngIf="!editMode" type="button" mat-raised-button class="bmx-1 admin-btn-success pull-right"
                                    (click)="finishSetup()">Finish Setup</button>
                                <button *ngIf="editMode" type="button" mat-raised-button class="bmx-1 admin-btn-success pull-right"
                                    (click)="finishSetup()">Finish</button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
<!-- Pop Up section -->
<!-- for confirm save -->
<div class="model-dialog row p-0 m-0 mt-2" *ngIf="setupNewFacilityModal"  data-backdrop="static" data-keyboard="false">
    <div class="col-12 p-0 m-0">
        <!-- The Modal -->
        <div class="col-sm-12 align-center modal" style="background: #393d406b;">
            <div class="modal-content setupnewfacility-model">
                <!-- Modal Header -->
                <div class="admin-model-header">
                    <h4 class="modal-title p-1">Setup New {{ContextValue}}</h4>
                </div>
                <!-- Modal body -->
                <div class="modal-body" style="text-align: center;font-size:16px">
                    <span class="title-card">
                      Save Changes ?
                    </span><br><span class="title-card">
                        If not saved, any modifications made will be lost.
                    </span><br>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer mb-4" style="display: block;margin: auto;">
                    <button type="button" mat-raised-button class="admin-btn-success mr-4" (click)="saveAndProceed()">Save
                        and
                        Proceed</button>
                        <button type="button" mat-raised-button class="admin-btn-success mr-4" (click)="proceedWithoutSave()">Proceed
                        without Saving</button>
                        <button type="button" mat-raised-button class="admin-btn-success" (click)="closePopUp()">Stay
                            in this
                            Page</button>
                    </div>
            </div>
        </div>
    </div>
</div>
<!-- for add users  -->
<div class="row p-0 m-0 mt-2" *ngIf="OrgId != '' && AccId != '' && showAddUsers ">
    <div class="col-12 p-0 m-0">
        <!-- adduserform -->
        <!-- The Modal -->
        <div class="modal AddUsersToOFModal" id="AddUsersToOFModal" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog" style="max-width: 90%;border-radius: 15px;">
                <div class="modal-content setupnewfacilityAddUsers-model">
                    <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" (click)="closeSummaryPopUpWithoutRefreshdata()"
                        class="material-icons md-24 cursor" title="Close">
                    <template-AddUserToOrg [fromComponent]="fromComponent" [Component]="Component" [OrgValue]='OrgId'
                        [AccValue]="AccId"></template-AddUserToOrg>
                    <!-- Modal footer -->
                    <!-- <div class="modal-footer my-3" style="display: block;margin: auto;"> -->
                    <!-- <button type="button" class="btn btn-info p-2" (click)="saveAndProceed()">Save and
                          Proceed</button>
                      <button type="button" class="btn btn-info p-2" (click)="proceedWithoutSave()">Proceed
                          without Saving</button> -->
                    <!-- <button type="button" class="btn btn-info p-2" (click)="closeSummaryPopUp()">Close</button> -->
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
