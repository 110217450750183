import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import * as wjcCore from "@grapecity/wijmo";
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
// //import 'src/assets/css/styles.css';
import { environment } from 'src/environments/environment';
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';

@Component({
  selector: 'app-vitalbridge',
  templateUrl: './vitalbridge.component.html',
  styleUrls: ['./vitalbridge.component.scss']
})

export class VitalbridgeComponent {
  @Input() templateData: any;
  columns;
  columnsJobDetail;
  gridwidth: number = 0;
  gridjobwidth: number = 0;
  gridData: CollectionView;
  gridVBJobData: CollectionView;
  public SubMenuCardModel;
  tabIndex = 0;
  public gridarray = [];
  public gridJobarray = [];
  public array = [];
  public array1 = [];
  public arr = [];
  public gridDisplay: boolean;
  public DataNotFound: boolean;
  public ShowGrid = false;
  public AgentID;
  public VBapiURL: string = environment.VitalBridgeRestApiURL;

  headings = ['jobArray', 'Agent ID', 'Display Name', 'Entity', 'createddate', 'IP', 'Status'];
  vbjobheadings = ['createddate', 'currentjobstatus', 'jobstatus', 'modifieddate', 'modifierid', 'JobName', 'DeviceName', 'Frequency', 'class']
  selector: any;
  // actionButtonDetails: any;
  hideExportBtn: any = true;

  constructor(private ngxService: NgxUiLoaderService, public commonService: CommonService, public vitalHttpServices: VitalHttpServices, public DataShare: DataShareService, public datepipe: DatePipe) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, vitalHttpServices, DataShare);
    this.columns = [
      //Outer grid
      new ColDef('AgentID', 'ID'),
      new ColDef('DisplayName', 'Name'),
      new ColDef('Entity', 'Location ID'),
      new ColDef('IP', 'IP'),
      new ColDef('createddate', 'Created Date'),
      new ColDef('Status', 'Status')
    ];

    this.columnsJobDetail = [
      //Inner grid
      new ColDef('JobName', 'Job Name'),
      new ColDef('DeviceName', 'Job Type'),
      new ColDef('Frequency', 'Frequency'),
      new ColDef('class', 'Class'),
      new ColDef('createddate', 'Created Date'),
      new ColDef('modifierid', 'Modified By'),
      new ColDef('modifieddate', 'Modified Date'),
      new ColDef('jobstatus', 'Status'),
      new ColDef('currentjobstatus', 'Current State')
    ];
  }

  ngOnInit(): void {
    this.gridData = new wjcCore.CollectionView(this.getVBData());
    this.GetButtondetails();
  }

  //Get Data from REST API
  getVBData():any {
    this.array = [];
    let OrganizationGUID = sessionStorage.getItem("OrganizationGUID")
    var myHeaders = new Headers();
    myHeaders.append("Cookie", "ARRAffinity=e777fde04dea4eb931d5e5f06e65b4fdf5b375aed60af41dd7b491cf5792e01b");
    var requestOptions: RequestInit = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    fetch(`${this.VBapiURL}` + OrganizationGUID, requestOptions)
      .then(response => response.json())
      .then(Data => {
        this.array = Data;
        this.ngxService.start();
        this.AddVbData()
        this.ngxService.stop();
      })
    return this.array;
  }

  //Add Data to grid
  AddVbData() {
    let primary = {}
    this.gridarray = [];
    if (this.array.length > 0) {
      this.gridDisplay = true;
      for (let i = 0; i < this.array.length; i++) {
        primary = {}
        for (let [key, value] of Object.entries(this.array[i])) {
          for (let j = 0; j < this.headings.length; j++) {
            if (key === this.headings[j].toString().replace(/ /g, "")) {
              if (value == undefined || value == null || value == '') {
                value = 'Not Specified';
              }
              else {
                if (value.toString().toLowerCase() == 'active') {
                  value = 'Active'
                }
                if (value.toString().toLowerCase() == 'passive') {
                  value = 'Passive'
                }
                if (key == 'createddate') {
                  var date = value.toString().split(' ')[0];
                  var dd = date.toString().split('-')[0];
                  var mm = date.toString().split('-')[1];
                  var yyyy = date.toString().split('-')[2];
                  value = mm + '/' + dd + '/' + yyyy;
                  primary[key] = value;
                }
                else {
                  primary[key] = value
                }
              }
            }
          }
        }
        this.gridarray.push(primary)
      }

      this.gridData = new CollectionView(this.gridarray, { pageSize: 10 })
      this.gridwidth = (500 * (this.headings.length - 1)) + 38;
      if (this.gridwidth > 1300) {
        this.gridwidth = 709;
      }
    }
    else {
      this.gridDisplay = false;
      this.DataNotFound = true;
    }
  }

  GetButtondetails() {
    this.GetButtonAccess(this.vitalHttpServices.SubmenuAction);
    // this.DataShare.SubmenuAction.subscribe(data => {
    //   if (data) {
    //     // this.actionButtonDetails = data;
    //     this.GetButtonAccess(data);
    //   }
    // });
  }
  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Export":
          this.hideExportBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
      }
    }
  }

  //Get VB Job details
  onClick(agentid, e) {
    let dataItem;
    this.gridJobarray = [];
    let secondary = {}
    let hti = agentid.hitTest(e);
    if (hti.panel === agentid.cells) {
      this.ngxService.start();
      let row = agentid.rows[hti.row]
      dataItem = row.dataItem;
      if (dataItem == null) {
        this.ngxService.stop();
        return;
      }
      else {
        this.ShowGrid = true;
        this.gridDisplay = false;
        this.AgentID = dataItem.AgentID;
        if (this.array != undefined) {
          for (let i = 0; i < this.array.length; i++) {
            for (let j = 0; j < this.array[i].jobArray.length; j++) {
              this.arr = [];
              if (this.array[i].jobArray[j].agentid == this.AgentID) {
                if (this.array[i].jobArray[j].json !== null) {
                  let vbjobJson = "";
                  vbjobJson = JSON.parse(this.array[i].jobArray[j].json);
                  this.array1.push(this.array[i].jobArray[j])
                  this.arr[j] = Object.assign(this.array1[j], vbjobJson)
                  secondary = {}
                  for (let [key, value] of Object.entries(this.arr[j])) {
                    for (let k = 0; k < this.vbjobheadings.length; k++) {
                      if (key === this.vbjobheadings[k].toString()) {
                        if (value == '' || value == null || value == undefined) {
                          value = 'Not Specified';
                        }
                        if (value == 'active') {
                          value = 'Active'
                        }
                        if (value == 'passive') {
                          value = 'Passive'
                        }
                        if (key == 'createddate' || key == 'modifieddate') {
                          var date = value.toString().split(' ')[0];
                          if (date == 'Not') {
                            value = 'Not Specified'
                            secondary[key] = value;
                          }
                          else {
                            var dd = date.toString().split('-')[0];
                            var mm = date.toString().split('-')[1];
                            var yyyy = date.toString().split('-')[2];
                            value = mm + '/' + dd + '/' + yyyy;
                            secondary[key] = value;
                          }
                        }
                        else {
                          secondary[key] = value
                        }
                      }
                    }
                  }
                }
                this.gridJobarray.push(secondary)
              }

            }
          }
        }
        else {
          this.gridVBJobData = new CollectionView(this.gridJobarray, { pageSize: 10 });
          this.gridjobwidth = 0;
        }
        this.gridVBJobData = new CollectionView(this.gridJobarray, { pageSize: 10 })
        this.gridjobwidth = (500 * (this.headings.length - 1)) + 38;
        if (this.gridjobwidth > 1300) {
          this.gridjobwidth = 1093;
        }
        this.ngxService.stop();
      }
    }
  }

  ExportExcel(flex) {
    let LabName = sessionStorage.getItem("LabName");
    var file_name = 'VitalBridge_';
    file_name =  file_name + LabName;
    if (LabName != 'undefined' && LabName) {
      file_name = file_name + LabName;
    if (file_name.length > 31) {
      let fileName = file_name.split('_')[0] + '_';
      let orgName = file_name.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
          if (orgName.length > 31) {
            fileName = file_name.split('_')[0].replace(' ','') + '_';
            file_name = fileName + orgName + '_';
          }
          else {
            file_name = fileName + orgName + '_';
          }
        }
        else {
          file_name = file_name + '_';
        }
      }
      file_name = file_name +  this.templateData.secondarykeys.OrganizationId.toString()+ '.xlsx';
      if(file_name.length > 31){
        let fileName = file_name.split('_')[0] + '_';
        if(fileName.length>31){
          file_name = fileName.split('_')[0] +'.xlsx';
        }else{
           if(this.templateData.secondarykeys){
            file_name = fileName  + this.templateData.secondarykeys.OrganizationId.toString()+'.xlsx';
        }
        else{
          file_name = fileName.split('_')[0] +'.xlsx';
        }
      }
      }
    const view = flex.collectionView;
    let oldPgSize = view.pageSize,
      oldPgIndex = view.pageIndex;
    flex.beginUpdate();
    view.pageSize = 0;
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeCellStyles: false,
        includeColumnHeaders: true
      },
      file_name,
      saved => {
        view.pageSize = oldPgSize;
        view.moveToPage(oldPgIndex);
        flex.endUpdate();
      },
      null
    );
  }

  initGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      tt.hideDelay = 9999999;
      tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }


  changeTab(event) {
    this.tabIndex = event.index;
  }
  back() {
    this.ShowGrid = false
    this.gridDisplay = true;
  }

}

class ColDef {
  constructor(public binding?: string, public header?: string) {
  }
}
