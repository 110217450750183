<div class="attachment-main-div" *ngIf="!isLoading">
  <div *ngIf="this.locationDetails?.CustomInstruction != '' && this.locationDetails?.CustomInstruction != null  && isEnableInstructions">
    <div class="label_border _600">Accession</div>
    <div [innerHtml]="accession" class="description_container p-1"></div>
  </div>
  <div class="mt-2" *ngIf="this.locationDetails?.CustomInstructionDiagnosis != '' && this.locationDetails?.CustomInstructionDiagnosis != null && isEnableInstructions ">
    <div class="label_border _600">Diagnosis</div>
    <div [innerHtml]="diagnosis" class="description_container p-1"></div>
  </div>

  <div class="mb-2 mt-2" *ngIf=" this.locationDetails?.Notes != '' && this.locationDetails?.Notes != null ">
    <div class="label_border _600">Notes</div>
    <div [innerHtml]="note" class="description_container p-1"></div>
  </div>
  <div *ngIf="attachmentList?.length > 0">
    <div *ngIf="from === 'location'" class="label_border _600">Documents</div>
    <div class="mb-2">
      <div [ngClass]="{'attachment_container p-3' : from === 'location', 'pt-2 pl-4' : from !== 'location' }">
        <div class="attachments-view" [class.pl-2]="from === 'location'">
          <div *ngFor="let item of attachmentList">
            <div
              class="attachments p-1 px-2 d-flex justify-content-between"
            >
              <div class="_600 attachment-width-11vw" appTooltipEllipsis>
                {{ item.Name | titlecase }}
              </div>
              <div class="ml-3 view-document-icons">
                <span>{{ item.DateAdded | formatDate : "MM-dd-yyyy, h:mm a" }}</span>
                <!-- <ng-container
                  *ngIf="isViewableFileType(item.MediaType); else download"
                > -->
                  <span>
                    <img (click)="viewAttachment(item)" [class.action-button-disabled]="item.disableView" class="icon-view" src="/assets/images_vla/View Eye.svg" matTooltip="View" [matTooltipDisabled]="item.disableView">
                  </span>
                <!-- </ng-container> -->
                <!-- <ng-template #download> -->
                  <span>
                    <img (click)="downloadFile(item)" [class.action-button-disabled]="isDownloadDisabled" class="icon-download" src="/assets/images_vla/Download.svg" matTooltip="Download">
                  </span>
                <!-- </ng-template> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- no data available -->
  <div class="col-sm-12 nodata-header-wrap" *ngIf="noDataFound">
    <span class="col-sm-12 nodata-wrapper">No Data Available</span>
  </div>
</div>

<!-- spinner / loader for show grid-->
<mat-spinner
  class="labadmin-spinner-grid center labadmin-spinner"
  *ngIf="showLoader && !isHideLocationGrid"
  style="margin: 20vh 0vw 0vh 40vw"
  [diameter]="50"
>
</mat-spinner>

<!-- spinner / loader for hide grid-->
<mat-spinner
  class="labadmin-spinner-grid center labadmin-spinner"
  *ngIf="showLoader && isHideLocationGrid"
  style="margin: 20vh 0vw 0vh 25vw"
  [diameter]="50"
>
</mat-spinner>



