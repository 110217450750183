<div id="case-tags">
    <form [formGroup]="caseGroupForm">
        <div class="row m-0 warning-model-header-tags case-tag-wrap">
            <div class="col-sm-2 create-casetag ml-2" (click)="groupNameClicked()">
                <div class="edit-wrap align-center">
                    <img src="/assets/icons/case_tags/edit.svg" alt="edit" width="15px">
                </div>

                <div class="input-wrap">
                    <input matInput type="text" formControlName="frmTagGroupName" title="{{getGroupTitle()}}"
                        placeholder="Group Name" class="col-sm-12 mt-1 pr-1 pl-1 value-wrap" readonly
                        (click)="focusInput()" maxlength="500" >
                </div>
            </div>

            <span class="col-sm-9">

            </span>

            <span class="button-wrap p-0" type="button" id="button_size" data-dismiss="modal">
                <span *ngIf="data.cancel !== 'dontshow'" class="col-sm-1 mt-2 ml-3 cursor"
                    [mat-dialog-close]="data.animal" cdkFocusInitial>
                    <img src="/assets/icons/case_tags/close.svg" alt="close" width="30px" height="30px"
                        title="Close">
                </span>
            </span>
        </div>
    </form>

    <span class="row m-2 p-0 case_tag_wrap">
        <div mat-dialog-content class="col-sm-12 d-flex p-0">
            <div [ngClass]="tagWrap ?'col-sm-9 m-0 pr-2 pl-0':'col-sm-12 m-0 pr-2 pl-0'">
                <div id="caseTagsDialog" class="row mr-1 ml-1">

                    <form [formGroup]="caseGroupForm" class="col-sm-12 p-0">
                        <div class="col-sm-12 m-0 mb-2 p-0 ct-button-wrap">
                            <div class="col-sm-12 button-wrap p-0">
                                <app-toggle-switch class="mr-2 toggle-font" formcontrolname="frmAllowBulkEdit"
                                    labelActivetext='Allow Bulk Edit:' labelInactivetext="Allow Bulk Edit:"
                                    (clicked)="onActiveChange($event,'bulkedit')" type="secondary">
                                </app-toggle-switch>

                                <app-toggle-switch class="toggle-font" formcontrolname="frmActive"
                                    labelActivetext='Active:' labelInactivetext="Inactive:"
                                    (clicked)="onActiveChange($event,'group')" type="secondary">
                                </app-toggle-switch>
                            </div>
                        </div>
                    </form>

                    <mat-expansion-panel [(expanded)]="xpandCategoroies" class="row col-sm-12 p-0 mt-2">
                        <mat-expansion-panel-header class="panel-header p-2">
                            <span class="w-100 d-flex m-0 p-0">
                                <div class="d-flex">
                                    Categories 
                                </div>
                            </span>
                        </mat-expansion-panel-header>

                        <span>
                            <span id="selected-chips ml-3" *ngIf="!isValuesSectionVisible && data.continue=='Update'">
                                <span class="w-100 d-flex p-0 mt-2">
                                    <span class="w-50 pr-0 pl-2 font-italic">
                                        <span class="mr-2">Created date &nbsp;:&nbsp;
                                            {{ CreatedDate && CreatedDate !=='' ? (CreatedDate | date:'dd/MM/yyyy') : 'N/A' }}
                                        </span>
                                        <span>Modified date &nbsp;:&nbsp;
                                            {{ ModifiedDate && ModifiedDate !== '' ? (ModifiedDate | date:'dd/MM/yyyy') : 'N/A' }}
                                        </span>
                                    </span>
                                    <span class="w-50 p-0 d-flex justify-content-end mr-3">
                                        <button mat-button class="cursor" (click)="toggleValuesSection()">
                                            <img src="/assets/icons/case_tags/vector.svg" alt="edit"
                                                width="18px" height="18px">
                                            <span class="underline-button">
                                                Modify
                                            </span>
                                        </button>
                                    </span>
                                </span>

                                <span>
                                    <div class="ml-2 mb-1">
                                        <div class="chip-name mb-2">Case Types</div>
                                        <mat-chip-list>
                                            <mat-chip *ngFor="let casetype of data.exixtingCaseType" selected="true">
                                                {{ casetype }}
                                            </mat-chip>
                                        </mat-chip-list>
                                    </div>

                                    <div class="ml-2 mb-1">
                                        <div class="chip-name mb-2">Case Status</div>
                                        <mat-chip-list>
                                            <mat-chip *ngFor="let status of data.exixtingCasestatus" selected="true">
                                                {{ status }}
                                            </mat-chip>
                                        </mat-chip-list>
                                    </div>

                                    <div class="ml-2 mb-1">
                                        <div class="chip-name mb-2">Roles</div>
                                        <mat-chip-list>
                                            <mat-chip *ngFor="let roles of fetchRoles(data.exixtingRoles)" selected="true">
                                                {{ roles }}
                                            </mat-chip>
                                        </mat-chip-list>
                                    </div>
                                </span>
                            </span>

                            <span id="selected-values" *ngIf="isValuesSectionVisible || data.continue =='Create'">
                                <span class="w-100 d-flex">
                                    <div class="case-tag-search">
                                        <app-case-tags-filter [DataList]="categoryList?.caseType"
                                            [displayName]="'casetype'" [placeholder]="'Search Case Type'"
                                            [isEditable]='isEditable'
                                            (myObjectChange)="handleDataChange('listCasetype',$event)">
                                        </app-case-tags-filter>
                                    </div>
                                    <div class="case-tag-search">
                                        <app-case-tags-filter [DataList]="categoryList?.caseStatus"
                                            [isEditable]='isEditable' [displayName]="'item'"
                                            [placeholder]="'Search Case Status'"
                                            (myObjectChange)="handleDataChange('listStatus',$event)">
                                        </app-case-tags-filter>
                                    </div>
                                    <div class="case-tag-search">
                                        <app-case-tags-filter [DataList]="categoryList?.roles"
                                            [displayName]="'displayname'" [isEditable]='isEditable'
                                            [placeholder]="'Search Roles'"
                                            (myObjectChange)="handleDataChange('listRoles',$event)">
                                        </app-case-tags-filter>
                                    </div>
                                </span>
                            </span>
                        </span>
                    </mat-expansion-panel>

                    <mat-expansion-panel [(expanded)]="xpandTags" class="row col-sm-12 p-0 mt-2">
                        <mat-expansion-panel-header class="panel-header p-2">
                            <span class="w-100 d-flex m-0 p-0">
                                <div class="d-flex">
                                    Tag(s) <span class="error-msg">*</span>
                                </div>
                            </span>
                        </mat-expansion-panel-header>

                        <form [formGroup]="caseTagForm" class="form-wrapper">
                            <div formArrayName="tags" cdkDropList [cdkDropListData]="tagsFormArray().controls"
                                (cdkDropListDropped)="tagDrop($event)">
                                <div *ngFor="let tag of tagsFormArray().controls; let i = index;" [formGroupName]="i"
                                    [cdkDragDisabled]="tagsFormArray().controls.length == 1" cdkDrag>

                                    <div class="tag-container m-2 pr-1 pt-2">
                                        <div class="row mx-2 tag-name-wrap">
                                            <mat-form-field class="col-sm-3 p-0 mb-1"
                                                (click)="tagnameClicked(i);focusInput()" appearance="outline">
                                                <mat-label>Tag Name</mat-label>
                                                <input matInput type="text" maxlength="500"  formControlName="frmTagName"
                                                    title="{{getTitleValue(i)}}" readonly>
                                            </mat-form-field>

                                            <div class="col-sm-7">
                                            </div>

                                            <div class="col-sm-2 button-wrap align-center p-0">
                                                <app-toggle-switch [isChecked]="tag.get('frmTagActive').value"
                                                    (clicked)="onActiveChange($event,'tag',i)" type="secondary">
                                                </app-toggle-switch>

                                                <span class="drag p-0" *ngIf="tagsFormArray().controls.length > 1">
                                                    <img src="/assets/icons/case_tags/drag.svg" alt="drag"
                                                        width="30px" height="30px" title="Drag" class="align-center">
                                                </span>

                                                <span class="cursor p-0" *ngIf="tagsFormArray().controls.length > 1">
                                                    <img src="/assets/icons/delete.svg"
                                                        alt="close" width="25px" height="25px" title="Delete"
                                                        class="align-center" (click)="removeTag(i)">
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row p-0 pl-0 pr-0 ml-0 mr-0 mt-2 mb-2 d-flex example-list"
                                            formArrayName="frmtagValues" cdkDropList
                                            [cdkDropListData]=" gettagValueArray(i).controls"
                                            (cdkDropListDropped)="tagValueDrop($event,i)">

                                            <button class="align-center btn btn-sm ct-border-color mr-2" type="button"
                                                (click)="tagWrap=true;isFlagName='Tag Value Name'"
                                                *ngFor="let data of gettagValueArray(i).controls;let inde= index;"
                                                [formGroupName]="inde" [ngStyle]="tagColor(data,inde)"
                                                [cdkDragDisabled]="gettagValueArray(i).controls.length == 1" cdkDrag>

                                                <img src="/assets/icons/case_tags/drag.svg" alt="drag"
                                                    width="20px" height="20px" title="Drag" class="drag"
                                                    *ngIf="gettagValueArray(i).controls.length > 1">
                                                <!-- formControlName="frmTagValueSequence" -->

                                                <!-- // tag value name -->
                                                <input class="input-width" #tagvalue placeholder="Tag Value Name"
                                                    (click)="tagNameValueClicked(i,inde);focusInput()"
                                                    title="{{getTagValueTitle(i,inde)}}"
                                                    maxlength="100"
                                                    formControlName="frmTagvaluename" readonly>

                                                <img src="/assets/icons/case_tags/cancel.svg" alt="cancel"
                                                    width="20px" height="20px" title="Cancel" class="cursor"
                                                    (click)="removeTagValue(tag, inde)"
                                                    *ngIf="gettagValueArray(i).controls.length > 1">
                                            </button>

                                            <div class="tag-addval-cont align-center ml-2 mt-1">
                                                <img src="/assets/icons/case_tags/add_tagvalue.svg"
                                                    alt="Add TagValue" width="25px" height="25px" title="Add TagValue"
                                                    class="cursor" (click)="addTagValue(i)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tag-add-cont align-center mt-2 mr-2 ml-2">
                                <img src="/assets/icons/case_tags/add_tag_blue.svg" alt="Add TagValue"
                                    width="25px" height="25px" title="Add Tag" class="cursor" (click)="addTag()"><span
                                    class="ct-tag-color">&nbsp;
                                    Add Tag
                                    &nbsp;</span>
                            </div>
                        </form>
                    </mat-expansion-panel>
                    <div class="col-sm-12 d-flex pl-0 mt-2 non-edit-color">
                        <label class="col-sm-3 p-0 ml-2 mr-2 non-edit"><strong>Not Editable Case Status</strong></label>
                        <form [formGroup]="caseGroupForm" class="col-sm-9 not-edit-wrap">
                            <ng-multiselect-dropdown class="col-sm-9 text-xs mt-2 p-0"
                                title="{{frmNonEditableCaseStatus}}" [placeholder]="'Not Editable Case Status'"
                                formControlName="frmNonEditableCaseStatus" [settings]="caseStatusDropdownSettings"
                                [data]="data.CaseNStatusList" (onSelect)="onCheckChange($event,'ncasestatus')"
                                (onDeSelect)="onItemDeSelect($event,'ncasestatus')"
                                (onSelectAll)="onSelectAll($event,'ncasestatus')"
                                (onDeSelectAll)="onDeSelectAll('ncasestatus')">
                            </ng-multiselect-dropdown>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-sm-3 ct-color-wrap mt-2" *ngIf="tagWrap">
                <form [formGroup]="caseTagsName">
                    <span>
                        <mat-form-field appearance="outline" class="col-sm-11 mb-2 mt-2 ml-2 p-0">
                            <mat-label>{{isFlagName}}<span class="error-msg">*</span></mat-label>
                            <input matInput type="text" formControlName="frmTagName" #myInput
                                (input)="setgroupName($event)" maxlength="500" >
                            <button matSuffix mat-icon-button aria-label="Clear" (click)="inputDataClear()"
                                *ngIf="this.caseTagsName.value.frmTagName && this.caseTagsName.value.frmTagName.length > 0 && this.data.continue == 'Create'">
                                <mat-icon>close</mat-icon>
                            </button>
                            <button matSuffix mat-icon-button aria-label="undo" class="p-0" (click)="undoTagNames()"
                                *ngIf="this.caseTagsName.value.frmTagName && this.caseTagsName.value.frmTagName.length > 0 && this.data.continue == 'Update'">
                                <mat-icon title="Undo">replay</mat-icon>
                            </button>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-sm-11 mb-2 mt-2 ml-2 p-0">
                            <mat-label>Background Color</mat-label>
                            <span class="w-100 col-sm-12 d-flex p-0" style="margin-top: 6px;">
                                <input type="color" id="bgpicker" #bgcolorspick class="color-pic-wrap" maxlength="50" 
                                    (input)="updateColorPicker($event.target.value);setBgColor($event)">

                                <div id="bgcolorDisplay" (click)="bgcolorspick.click()"
                                    class="col-sm-2 mt-1 p-0 color-picker">
                                </div>

                                <!-- [(ngModel)]="this.caseTagsName.value.frmTagBgColor" -->
                                <input matInput type="text" id="bgcolorInput" (input)="setBgColor($event)" maxlength="50" 
                                    class="col-sm-10 p-0 pl-2" formControlName="frmTagBgColor" autocomplete="off"
                                    [ngModelOptions]="{standalone: true}" (click)="$event.stopPropagation()">
                            </span>

                            <button matSuffix mat-icon-button aria-label="Clear" class="p-0" (click)="undoBackColor()"
                                *ngIf="this.caseTagsName.value.frmTagBgColor && this.caseTagsName.value.frmTagBgColor.length > 0  && this.data.continue == 'Update'">
                                <mat-icon title="Undo">replay</mat-icon>
                            </button>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-sm-11 mb-2 mt-2 ml-2 p-0">
                            <mat-label>Font Color</mat-label>
                            <span class="w-100 col-sm-12 d-flex p-0" style="margin-top: 6px;">
                                <input type="color" id="fontpicker" #fontcolorspick class="color-pic-wrap" maxlength="50" 
                                    (input)="updateFontColorPicker($event.target.value);setFontColor($event)">

                                <div id="fontcolorDisplay" (click)="fontcolorspick.click()"
                                    class="col-sm-2 mt-1 p-0 color-picker">
                                </div>
                                <input matInput type="text" id="fontcolorInput" (input)="setFontColor($event)" maxlength="50" 
                                    class="col-sm-10 p-0 pl-2" formControlName="frmTagFontColor" autocomplete="off"
                                    [(ngModel)]="this.caseTagsName.value.frmTagFontColor"
                                    [ngModelOptions]="{standalone: true}" (click)="$event.stopPropagation()">
                            </span>

                            <button matSuffix mat-icon-button aria-label="Clear" class="p-0" (click)="undoFontsColor()"
                                *ngIf="this.caseTagsName.value.frmTagFontColor && this.caseTagsName.value.frmTagFontColor.length > 0  && this.data.continue == 'Update'">
                                <mat-icon title="Undo">replay</mat-icon>
                            </button>
                        </mat-form-field>

                        <div *ngIf="isFlagName==='Tag Value Name'">
                            <mat-checkbox [color]="task.color" class="button-wrap mr-3" formControlName="frmDefault"
                                (change)="isDefaultchecked($event)">
                                Default
                            </mat-checkbox>
                            <nav [style.backgroundColor]="expression"></nav>
                        </div>
                    </span>
                </form>
            </div>
        </div>
    </span>

    <form [formGroup]="caseGroupForm">
        <div class="col-sm-12 row m-0 mb-2 button-wrap">
            <!-- <button mat-raised-button class="ct-admin-btn-success-outline" type="button">
                Return</button> -->

            <button mat-raised-button class="ct-admin-btn-success-outline" type="button" [disabled]="!isbuttonEnable"
                (click)="onCreateTags(data.continue,caseTagForm,caseGroupForm)">{{data.continue}}</button>
        </div>
    </form>

</div>