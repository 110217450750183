import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LockUnlockUserDialogComponent } from 'src/app/base/popup/lock-unlock-user-dialog/lock-unlock-user-dialog.component';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { LabadminService } from '../../labadmin.service';
import { LabadminSessionService } from '../../services/labadmin-session.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExtAttributeConstants, Scope } from '../share/utility/ex_attribute _constants';
import { DialogService } from 'src/app/core/services/dialog.service';
import { userStatusUpdateMessage, userStatusUpdateSnackbarMessage, StaticMessages } from 'src/app/labadmin/components/share/utility/MessageConstants';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { UtilityServiceService } from '../share/service/utility-service.service';
import { menuUrl } from '../Utility Functions';
import { Store } from '@ngrx/store';
import { setReloadingPage } from '../../store/actions/navbar.actions';
@Component({
  selector: 'app-userdetails',
  templateUrl: './userdetails.component.html',
  styleUrls: ['./userdetails.component.scss']
})
export class UserDetailsComponent {

  @Output() EditUser = new EventEmitter<string>();

  @Input() templateData: any;
  @Input() userid = ''
  @Input() userDetials: any
  @Input() ViewType = ''
  @Input() loginType = ''
  @Input() isADEnabled = ''
  @Input() vendorName = ''
  @Input() type = ''
  @Input() userRoles:any=[]
  @Input() statusChanged:boolean[]=[false]

  userSessionDetails: any;
  showloader: boolean = true;
  loadActionButtons:boolean=false;
  tooltip: string = '';
  commomkey: string | '';
  sourceApp: string = 'VitalDx';

  islocked: boolean = false;
  isApprovePassword: boolean = false
  isEditDisabled: boolean = true;
  isLockDisabled: boolean = true;
  isUnlockDisabled: boolean = true;
  isGeneratePwd: boolean = true;
  isEmailSent: boolean = true;
  loggedInUserOrganizationName: any;
  loggedInUserOrgId: any;
  ApplicationDomain: any;
  deploymentKey: any;
  loggedInUserOrganizationGUID: any;
  ActiveImage : string = '/assets/icons/Role_Active.svg'
  InactiveImage : string = '/assets/icons/Role_Inactive.svg'
  activityEntity: any
  loggedInUserName: string
  staticMessages = StaticMessages

  constructor(
    private labadminService: LabadminService, 
    public dialog: MatDialog,
    private _snackbar:MatSnackBar,
    private labAdminSessionService: LabadminSessionService, 
    public _vitalHttpService: VitalHttpServices,
    private _dailogService: DialogService,    
    public activityService: ActivityTrackerService,
    private _utilityService: UtilityServiceService,
    private _store:Store
  ) {
  }

  ngOnInit(): void {
    this.labAdminSessionService.getLabAdminSession.subscribe((session:any) => {
      this.userSessionDetails = session.userDetails;
      //this.loginType = this.userDetials.logintype || '';
      this.islocked = this.userDetials.IsAccountLockedout;
      this.loggedInUserOrganizationName = this.userSessionDetails.orgName;
      this.loggedInUserOrganizationGUID =  this.userSessionDetails.userOrgGUID;
      this.ApplicationDomain = JSON.parse( session.labAminSession.data).domainUrl.split('//')[1]
      this.loggedInUserOrgId =  this.userSessionDetails.organizationId
      this.loggedInUserName = this.userSessionDetails.formattedDisplayName
      this.deploymentKey =  this.userSessionDetails.deploymentKey ?? this.deploymentKey
      this.showloader=false
    });
   
    this.GetButtonAccess(this.labadminService.rbacPermissions);
  }

  ngOnChanges() {
    this.islocked = this.userDetials.IsAccountLockedout;
    if (this.userid !== '') {
    }
  }

  getUserDetails() {
    this.labadminService.getUserProfile(this.userid, 'LIS').subscribe(data => {
    })
  }

  // lock and unlock user account
  openDialog(item: any) {
    // lock and unlock should not allow for inactive users
    if (item?.Status.trim().toLowerCase() !== 'active') {
      this._dailogService.openWarningPopup(item.IsAccountLockedout ? 'Unlock User Account' : 'Lock User Account', 'Activate the user to make the changes.', item.IsAccountLockedout ? '../../../../assets/icons/user_icons/Unlocked.svg':'../../../../assets/icons/user_icons/Locked.svg')
      // this.dialog.open(LockUnlockUserDialogComponent, {
      //   width: '30vw',
      //   data: {
      //     imagePath:item.IsAccountLockedout ? '../../../../assets/icons/user_icons/Unlocked.svg':'../../../../assets/icons/user_icons/Locked.svg',
      //     header: item.IsAccountLockedout ? 'Unlock User Account' : 'Lock User Account',
      //     message: 'Activate the user to make the changes.',
      //     buttonname: 'OK',
      //     type: "mail-sent"
      //   }
      // })
      return;
    }

    this.dialog.open(LockUnlockUserDialogComponent, {
      width: '30vw',
      autoFocus: false,
      data: {
        imagePath:item.IsAccountLockedout ? '../../../../assets/icons/user_icons/Unlocked.svg':'../../../../assets/icons/user_icons/Locked.svg',
        header: item.IsAccountLockedout ? 'Unlock User Account' : 'Lock User Account',
        row1value: item.FormattedDisplayName,
        row2value: item.IsAccountLockedout ? "Locked" : "Unlocked",
        loginid: item.LoginName,
        userid: item.UserID,
        commonkey: item.CommonKey,
        row1text: 'User Name',
        row2text: 'Status',
        buttonname: item.IsAccountLockedout ? "Unlock User" : "Lock User",
        type: 'lockunlock-user',
        status: item.IsAccountLockedout,
        message: 'Activate the user to make the changes.',
        loggedInUserId:this.userSessionDetails.userId
      }
    }).afterClosed().subscribe((result) => {
      this.islocked = result.status;
      this.commomkey = item.CommonKey
      this.userDetials.IsAccountLockedout = result.status;
      this.statusChanged[0]=true //reload page
      this._utilityService.LockUnlockUser(this.userDetials.UserID, item.FormattedDisplayName, this.loggedInUserName, this.type, result.status)
    });
  }

  passwordGenerateDialog(item: any) {
    // inactive user should not be allowed to send mail
    if (item?.Status.trim().toLowerCase() !== 'active') {
      this._dailogService.openWarningPopup('Reset Password', 'Activate the user to reset password.')
      // this.dialog.open(LockUnlockUserDialogComponent, {
      //   width: '30vw',
      //   data: {
      //     imagePath:'../../../../assets/icons/user_icons/warning.svg',
      //     header: 'Reset Password',
      //     message: 'Activate the user to reset password.',
      //     buttonname: 'OK',
      //     type: "mail-sent"
      //   }
      // })
      return;
    }

    // unlock user should not be allowed to reset password
    if (item.IsAccountLockedout) {
      this._dailogService.openWarningPopup('Reset Password', 'Unlock the user to reset password.')
      // this.dialog.open(LockUnlockUserDialogComponent, {
      //   width: '30vw',
      //   data: {
      //     imagePath:'../../../../assets/icons/user_icons/warning.svg',
      //     header: 'Reset Password',
      //     message: 'Unlock the user to reset password.',
      //     buttonname: 'OK',
      //     type: "mail-sent"
      //   }
      // })
      return;
    }

    this.showloader = true;
    // login id is already verified need to send reset password link
    // if (this.emailVerify(item)) {

    //   let obj = {
    //     email: item.emailId,
    //   }
    //   this.labadminService.sendResetPwdLink(obj).subscribe((res) => {
    //     this.showloader = false;
    //     this.dialog.open(LockUnlockUserDialogComponent, {
    //       width: '30vw',
    //       data: {
    //         imagePath:'../../../../assets/icons/user_icons/warning.svg',
    //         header: 'Reset Password',
    //         message: 'Reset Password link sent. check your email to proceed.',
    //         buttonname: 'OK',
    //         type: "mail-sent"
    //       }
    //     });
    //   }, (error) => {
    //     this.showloader = false;
    //     console.error(error);
    //   });
    //   return;
    // }

    // login id is not verified will renerate the new password
    this.labadminService.generatePassword(item.LoginName, this.userSessionDetails.userId, this.labadminService.deploymentKey).subscribe((data) => {
      this.showloader = false;
      this._utilityService.generatePassword(this.userDetials.UserID, item.FormattedDisplayName, this.loggedInUserName, this.type)
      this.dialog.open(LockUnlockUserDialogComponent, {
        width: '30vw',
        autoFocus: false,
        data: {
          imagePath:'../../../../assets/icons/user_icons/warning.svg',
          row1value: item.LoginName,
          row2value: data[0],
          loginid: item.name,
          userid: item.UserID,
          row1text: 'User Name',
          row2text: 'Password',
          buttonname: 'Copy',
          type: "generate-password",
          header: 'Reset Password',
          message: 'Reset Password link sent. check your email to proceed.'
        }
      });
    }, (error) => {
      this.showloader = false;
      console.error(error);
    });
  }

  emailType(item:any):boolean{
    if(this.loginType?.trim().toLowerCase() == 'email' && !['verified','autoverified'].includes(item?.AuthStatus?.trim().toLowerCase()) && item.AllowLogin){
     this.tooltip = 'Send Invitation';
     return true;
    }
    this.tooltip = '';
    return false;
   }

  editUser() {
    this.EditUser.emit(this.userDetials.UserID)
  }


  // RBAC
  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == "Lab")["SubMenu"]
      seletedMenuPermissions = seletedMenuPermissions.find(e => e.URL == "Users")["ActionButton"]
      try {
        for (let i = 0; i < seletedMenuPermissions.length; i++) {
          switch (seletedMenuPermissions[i].Button.toLowerCase()) {
            case 'edit':
              this.isEditDisabled = seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
              break;
            case 'emailsent':
              this.isEmailSent =
                seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
              break;
            case 'lock':
              this.isLockDisabled =
                seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
              break;
            case 'unlock':
              this.isUnlockDisabled =
                seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
              break;
            case 'generatepassword':
              this.isGeneratePwd =
                seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
              break;
            case 'approvepassword':
              this.isApprovePassword = seletedMenuPermissions[i].IsPermitted === "false"
              break;
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
  }


  // mail send for invitation
  mailSent(item: any) {
    // inactive user should not be allowed to send mail
    if (item?.Status.trim().toLowerCase() !== 'active') {
      this._dailogService.openWarningPopup('Send Invitation', 'Activate the user to send invitation mail.', '/assets/icons/user_icons/email_invitation.svg')
      // this.dialog.open(LockUnlockUserDialogComponent, {
      //   width: '30vw',
      //   data: {
      //     imagePath:'../../../../assets/icons/user_icons/email_invitation.svg',
      //     header: 'Send Invitation',
      //     message: 'Activate the user to send invitation mail.',
      //     buttonname: 'OK',
      //     type: "mail-sent"
      //   }
      // })
      return;
    }

    // unlock user should not be allowed to send mail
    if (item.IsAccountLockedout) {
      this._dailogService.openWarningPopup('Send Invitation', 'Unlock the user to send invitation mail.', '/assets/icons/user_icons/email_invitation.svg')
      // this.dialog.open(LockUnlockUserDialogComponent, {
      //   width: '30vw',
      //   data: {
      //     imagePath:'../../../../assets/icons/user_icons/email_invitation.svg',
      //     header: 'Send Invitation',
      //     message: 'Unlock the user to send invitation mail.',
      //     buttonname: 'OK',
      //     type: "mail-sent",
      //   }
      // })
      return;
    }

    if (!item.Email) {
      this._dailogService.openWarningPopup('Email Required', StaticMessages.CONFIGURE_EMAIL_MESSAGE)
      // this.dialog.open(LockUnlockUserDialogComponent, {
      //   width: '30vw',
      //   data: {
      //     imagePath: '../../../../assets/icons/user_icons/warning.svg',
      //     header: 'Configure Email',
      //     message: StaticMessages.CONFIGURE_EMAIL_MESSAGE,
      //     buttonname: 'OK',
      //     type: "mail-sent"
      //   }
      // })
      return;
    }

    this.showloader = true;
    this.labadminService.getAllowAndAutoRegisterDomains(item?.labLocationList[0]?.OrgGUID, this.labadminService.deploymentKey).subscribe({
      next: ((res : {allowdomains: string, autoregisterdomains: string}) => {
        try {            
        const domain = item.Email.split('@')[1]
        if(res.autoregisterdomains && res.autoregisterdomains.split(';').includes(domain)){
          this._dailogService.openWarningPopup('Invalid Email ID', StaticMessages.AUTO_REGISTER_DOMAIN)
          this.showloader = false
          return
        }
        else if(!res.allowdomains || res.allowdomains.split(';').includes(domain)){
          this.migrateUser(item.Email, this.userDetials.UserID, item.name)
        }
        else{
          this._dailogService.openWarningPopup('Invalid Email ID', StaticMessages.ALLOW_DOMAINS+' '+res.allowdomains.split(';').join(', '))
          this.showloader = false
          return
        }
        } catch (error) {
          this.showloader = false
          this.onError(error)
        }
      }),
      error: ((err) => {
        this.showloader = false
        this.onError(err)
      })
    })
  }
  isUserActive(){
    return this.userRoles.filter((i:any)=>i.IsPrimary)[0].Status
  }

  ActivateOrInactivateUsers()
  { 
    const primaryRoleIndex=this.userRoles.findIndex(i=>i.IsPrimary)
    if(primaryRoleIndex!=-1)
      {
        var SavedUserId = this.userRoles[primaryRoleIndex].UserID,
        rolename = this.userRoles[primaryRoleIndex].Role,
        username = this.userDetials['FormattedDisplayName'],
        modifiedStatus =this.userRoles[primaryRoleIndex].Usrstatus.toLowerCase()== 'active'?'inactive':'active'

        const status = modifiedStatus === 'active' ? 'Activate' : 'Deactivate'
        this._dailogService.openLabadminConfimationdialog(
          '',
          userStatusUpdateMessage(status, rolename, username),
          status,
          'Cancel'
        ).afterClosed().subscribe((res) => {
          if(res){
            
        this.loadActionButtons=true
        const obj1 = {
          deleteUser: [{
            UserID: String(SavedUserId),
            VFrom: 'user',
            AccountID: '',
            MedicalDirectorToUpdate: '',
            Status: modifiedStatus,
            ModifiedBy:this.userSessionDetails?.userId?.toString()
          }],
          sessionData: {
            ApplicationDomain: this.ApplicationDomain,
            LoggedInUserOrgName: this.loggedInUserOrganizationName,
            LoggedInUserOrgGuid: this.loggedInUserOrganizationGUID,
            LoggedInUserOrdId: this.loggedInUserOrgId,
            accountsOrgGuid: '',
          }
        
        }
        this.labadminService.ActiveInActiveDeleteUsers(obj1, this.loggedInUserOrgId, this.deploymentKey).subscribe({
          next: (data) => {
            //var status = modifiedStatus === 'active' ? 'Activated' : 'Inactivated'
            this._snackbar.open(userStatusUpdateSnackbarMessage(`${status}d`, rolename, true), 'Close')
            //this._snackbar.open(`Primary Role ${status} Successfully `, 'Close')
            this.userRoles[primaryRoleIndex]={...this.userRoles[primaryRoleIndex],Usrstatus:modifiedStatus,Status:!this.userRoles[primaryRoleIndex].Status}  
            this._utilityService.activateInactivateUser(SavedUserId, username, this.loggedInUserName, this.type, status, rolename, true)
            this.loadActionButtons=false
            this.statusChanged[0]=true //reload page
          },
          error: (err) => {
            this.onError(err)
            this.loadActionButtons=false
          }
        })
    }  
    
  })
  }
}

isEnableResetPassword(authStatus: string, allowLogin: boolean) : boolean{
  return !this.isADEnabled && (this.loginType?.trim()?.toLowerCase() !== 'email' || (!['verified','autoinitiated','autoreinitiated','autoverified'].includes(authStatus.trim().toLowerCase()) && allowLogin))
}

sendResetLink(item : any){
  if(item.Email && item.Email.trim().length > 0){
    this.sendResetLinkToEmail(item.Email, item.FormattedDisplayName, item.UserID)
  }
  else{
    this._dailogService.openWarningPopup('Email Required', StaticMessages.CONFIGURE_EMAIL_MESSAGE)
    // this.dialog.open(LockUnlockUserDialogComponent, {
    //   width: '30vw',
    //   data: {
    //     imagePath: '../../../../assets/icons/user_icons/warning.svg',
    //     header: 'Email Link to Reset Password',
    //     message: StaticMessages.CONFIGURE_EMAIL_MESSAGE,
    //     buttonname: 'OK',
    //     type: "mail-sent"
    //   }
    // })
    return;
  }
}

sendResetLinkToEmail(emailId: string, username: string, userid: string){
  this.showloader = true
     let obj = {
      email: emailId,
    }
    this.labadminService.sendResetPwdLink(obj).subscribe({
      next: ((res) => {
      this.showloader = false;
      if(res.Success){
        this._dailogService.openWarningPopup('Reset Password', res.Message, '/assets/icons/user_icons/email_invitation.svg')
        this._utilityService.sendResetPasswordLink(userid, username, this.loggedInUserName, this.type)
      }
      else this.onError(res.Message)
    }),
    error: (error) => {
      this.showloader = false;
      this.onError(error)
    }
    });
    return;
  }

sendResetPassswordLink(item: any): boolean {     
  return !this.isADEnabled && item.AuthStatus && ['verified','autoverified'].includes(item?.AuthStatus?.trim().toLowerCase()) && this.loginType && this.loginType.trim().toLowerCase() == 'email' && item.AllowLogin
}

 migrateUser(emailId: string, userid: string, username: string){
      let requestData = [];
    let data = {
      Email: emailId,
      UserID: userid,
      OrganizationID: this.userSessionDetails.organizationId
    }
    requestData.push(data);
    this.labadminService.migrateUser(requestData, this.labadminService.deploymentKey).subscribe((d) => {
      this.showloader = false;
      this._dailogService.openWarningPopup('Send Invitation', 'Email Invitation sent successfully to ' + emailId, '/assets/icons/user_icons/email_invitation.svg')
      this._utilityService.migrateUser(userid, username, this.loggedInUserName, this.type, emailId)
    }, (error) => {
      this.showloader = false;
      this.onError(error)
    });
    }

    onError(err){
      console.error(err)
      this._snackbar.open(StaticMessages.ON_ERROR, 'Close', {
        horizontalPosition: 'end'
      });
    }

    setActivitySession(){
      const menuurl = menuUrl(this.type)
      this.activityService.setActivitySession({ 'entityId': '', 'entityType': menuurl, 'context': [{ 'key': 'parentMenu', 'value': menuurl }] })
      this.activityService.getActivitySession.subscribe(res => this.activityEntity = res)
    }

    approvePassword(user) {
      this.showloader = true;
      this.labadminService.GetUserDetails(user.UserID, this.deploymentKey).subscribe({
        next: ((res) => {
          if(!this.isEnableResetPassword(res.AuthStatus ?? '', res.AllowLogin)){
            this.showloader = false;
            const message = user.isadenabled ? `This user should use their ${this.vendorName} password to login.` : StaticMessages.LOGIN_THROUGH_DOMAIN_PASSWORD
            this._dailogService.openWarningPopup('Approve Password', message, '/assets/icons/user_icons/email_invitation.svg')
            return;
          }
          try {
            this.showloader = false;
            let obj = { loginId: res.LoginName, userId: user.UserID, facilityAddress: res.Address1 ? res.Address1 : res.Address2, username: user.FormattedDisplayName, firstName: res.FirstName, lastName: res.LastName, type: this.type, loggedInUserName: this.loggedInUserName}
            this._dailogService.openApprovePasswordPopup(obj, this.userSessionDetails.userId).afterClosed().subscribe({
              next: ((res) => {
                //console.log('closed')
              }),
              error: ((err) => console.error(err))
            })
          } catch (error) {
            this.onError(error)
          }
        }),
        error: ((err) => {
          this.onError(err)
        })
      })
    }
}
