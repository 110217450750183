import { Component, Input, OnInit } from '@angular/core';
import { LabadminService } from '../../labadmin.service';
import { ExtAttributeConstants } from '../share/utility/ex_attribute _constants';


@Component({
  selector: 'app-infopreference',
  templateUrl: './infopreference.component.html',
  styleUrls: ['./infopreference.component.scss'],
})
export class InfopreferenceComponent implements OnInit {
  @Input() userDetails = '';
  @Input() userRoles: any;
  settingList: any;
  LicensureList = [];
  outOfoffice = [];
  frm_isActive: any;
  isValidRole: boolean = false;
  isReadOnly: boolean = true;
  isCTAndCTSRoleExis: boolean = false;
  noDataFoundFlag: boolean = true;
  isPathologistChecked: boolean = false;
  isLabManagerChecked: boolean = false;
  isLabAdministratorChecked: boolean = false;
  isPathologistAssistantChecked: boolean = false;
  isPhysicianChecked: boolean = false;
  DiagICDcodeReminder:boolean = false
  AllowReviewPathCanSignOut:boolean = false
  editAccession:boolean= false
  ShowMyCaseOnly:boolean = false
  ManagePathologistPrivilagesvalue:boolean = false
  HoldrealseDuration:string=''
  showSettings: boolean = false;
  valuesToCheck = ['cytotechnologist supervisor', 'cytotechnologist'];
  rolesToCheck = [
    'lab manager',
    'pathologist',
    'cytotechnologist supervisor',
    'cytotechnologist',
    'physician',
    'pathologist assistant'
  ];
  isPathologistPrivilageExists: boolean = false;
  userId: number = 0;
  isManagePathologistPrivilegesForLabAdmin: boolean = false;

  constructor(private _labadminservice: LabadminService) {}

  ngOnInit() {
    this.ManagePathologistPrivilegesForLabAdmin(
      this.userDetails['labLocationList'][0]?.OrganizationId,
      ExtAttributeConstants.ManagePathologistPrivilegesForLabAdmin,
      '2'
    );
    this.setRolesDetails(this.userRoles);
  }

  ngOnChanges() {
    if (this.userDetails !== '' && this.userDetails !== undefined) {
      if (this.userDetails['userlicensure']?.length) {
        this.noDataFoundFlag = false;
        this.LicensureList = this.userDetails['userlicensure'];
      }
      if (this.userDetails['PathOOODetails']?.length) {
        this.noDataFoundFlag = false;
        this.outOfoffice = this.userDetails['PathOOODetails'];
      }
    }
  }

  checkInfoPreference() {
    return Object.keys(this.userDetails)?.length;
  }

  ManagePathologistPrivilegesForLabAdmin(
    entityId: string,
    extAttrName: string,
    extAttrtype: string
  ) {
    this._labadminservice
      .GetVAExtAttrValue(
        entityId,
        extAttrName,
        extAttrtype,
        this._labadminservice.deploymentKey
      )
      .subscribe({
        next: (data: any) => {
          this.isManagePathologistPrivilegesForLabAdmin =
            data.toString() == 'true' ? true : false;
        },
        error: (err: any) => {
          console.error(err);
        },
      });
  }

  setRolesDetails(userRoles) {
    userRoles.forEach((va) => {
      if (this.rolesToCheck.includes(va.Role.toLowerCase()))
        this.showSettings = true;
      if (this.valuesToCheck.includes(va.Role.toLowerCase()))
      {
        this.isCTAndCTSRoleExis = true;
        this.DiagICDcodeReminder = va.DiagICDcodeReminder
        this.HoldrealseDuration = va.HoldReleas
      }
      if (va.Role.toLowerCase() == 'pathologist')
      {
        this.DiagICDcodeReminder = va.DiagICDcodeReminder
        this.AllowReviewPathCanSignOut = va.AllowReviewPathCanSignOut
        this.editAccession = va.ModifyTechnicalData && va.IsModifyTechnicalDataAccession ? true : false,
        this.ShowMyCaseOnly = va.ShowMyCaseOnly
        this.isPathologistChecked = true;
        this.HoldrealseDuration = va.HoldReleas
      }
      if (va.Role.toLowerCase() == 'lab manager') {
        this.isLabManagerChecked = true
        this.HoldrealseDuration = va.HoldReleas
      }
      if (va.Role.toLowerCase() == 'pathologist assistant') {
        this.isPathologistAssistantChecked = true
      }
      if (va.Role.toLowerCase() == 'physician') {
        this.isPhysicianChecked = true
        this.ShowMyCaseOnly = va.ShowMyCaseOnly
      }
      if(va.Role.toLowerCase() == 'lab administrator')
      {
        this.isLabAdministratorChecked = true
        this.ManagePathologistPrivilagesvalue = va.AllowAdminAsPathologistPrivileges
      }

    });
    if(this.isPathologistChecked || this.isCTAndCTSRoleExis)  this.getPathologistManagementValue()
  }
  getPathologistManagementValue() {
    this._labadminservice.GetVAExtAttrValue(this.userDetails['labLocationList'][0]?.OrganizationId, ExtAttributeConstants.EnablePathologistManagement, '2',this._labadminservice.deploymentKey)
      .subscribe({
        next: (data: any) => {
          this.isPathologistPrivilageExists =
            data.toString() == 'true' ? true : false;
        },
        error: (err: any) => {
          console.error(err);
        },
      });
  }
}
