<div class="col-sm-12 mt-2" *ngIf="!showLoader" style="padding-bottom: 10px;">
  <div class="ml-3 vdm-heading">
    <span>Settings</span>
  </div>
  <div class="ml-3 font-size-color">

    <div class="settingStyle mb-2" *ngIf="isAllowLabAdminToViewReports">
      <span> Lab users can view the patient reports from this ordering facility</span>
      <app-toggle-switch type="admin-primary" [isDisable]="true"
        [isChecked]="locationInfo.AllowReportViewing"></app-toggle-switch>
    </div>
    <div class="settingStyle mb-2">
      <span>Is Listed as Ordering Facility</span>
      <app-toggle-switch type="admin-primary" [isDisable]="true"
        [isChecked]="locationInfo.IsListedAsOF"></app-toggle-switch>
    </div>
    <div class="settingStyle mb-2">
      <span>
        Diagnosis Reporting done using VitalDX (VitalOffice) <br>
        <span class="sub-text-style">
          (Select this option if the Ordering Facility uses VitalDx to sign-out patient
          diagnostic reports)
        </span>
      </span>
      <app-toggle-switch type="admin-primary" [isDisable]="true" [isChecked]="!isPcEnabled"></app-toggle-switch>
    </div>
  </div>
  <div class="mt-2" *ngIf="additionalSettings.length>0">
    <div class="ml-3 vdm-heading">
      <span>Additional Settings</span>
    </div>
    <div class="ml-3 mb-2 font-size-color additional-setting-Style" *ngFor="let item of additionalSettings">
      <span>{{item?.['Attribute']}} </span>
      <span>: </span>
      <strong>{{item?.['Value'] || '-'}}</strong>
    </div>
  </div>
</div>

<mat-spinner *ngIf="showLoader" class="labadmin-spinner"
  [ngClass]="{'spinner-margin-hide-grid': isHideLocationGrid, 'spinner-margin-show-grid': !isHideLocationGrid}"
  [diameter]="50">
</mat-spinner>