import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/base/popup/dialog/dialog.component';
import { PreviewDialogComponent } from './../../base/popup/preview-dialog/preview-dialog.component';
import { NpiComponent } from 'src/app/labadmin/components/share/components/npi/npi.component';
import { FaxComponent } from './../../labadmin/components/share/components/fax/fax.component';
import { ViewAttachmentComponent } from 'src/app/labadmin/components/share/components/view-attachment/view-attachment.component';
import { LabAdminDailogComponent } from 'src/app/base/popup/lab-admin-dailog/lab-admin-dailog.component';
import { BulkUploadPopUpComponent } from 'src/app/labadmin/components/share/components/bulk-upload-pop-up/bulk-upload-pop-up.component';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { LockUnlockUserDialogComponent } from 'src/app/base/popup/lock-unlock-user-dialog/lock-unlock-user-dialog.component';
import { AdminContactInfoPopUpComponent } from 'src/app/labadmin/components/share/components/admin-contact-info-pop-up/admin-contact-info-pop-up.component';
import { ApprovePasswordComponent } from 'src/app/labadmin/components/share/components/approve-password/approve-password.component';
import { ManagePayersLookupComponent } from 'src/app/client/Templates/manage-payers-lookup/manage-payers-lookup.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialog: MatDialog) { }
  //common alert
  openDialog(heading: string, message: string, subMessage: string, continueBtn: string, cancelBtn: string) {
    return this.dialog.open(DialogComponent, {
      disableClose: true,
      width: '500px',
      data: { dailogHeading: heading, message: message, alert: subMessage, continue: continueBtn, cancel: cancelBtn }
    });
  }

  openLargeDialog(heading: string, qualityattributes: any, preview: string, width = '72vw', height = '84vh') {
    return this.dialog.open(PreviewDialogComponent, {
      disableClose: true,
      width: width,
      height: height,
      data: { header: heading, qualityAttribueList: qualityattributes, preview: preview }
    });
  }
  openPayerDailog(prefilledData:any){
    return this.dialog.open(ManagePayersLookupComponent, {
      disableClose: true,
      autoFocus: false,
      width:'280vh',
      minHeight: '76vh',
      maxHeight: '80vh',
      data:{header : 'Payer Lookup',prefilledData : prefilledData }
    });
  }
  openNPIDailog(heading: string, organizationid : string, isDataPopulated : boolean, prefilledData : any, stateList : Lookup[]= [], mode : 'search' | 'view' = 'search', NPIFor : 'user' | 'location' = 'location', isNPIValidationEnabled : boolean = false, height = '79vh', width='80vw'){
    return this.dialog.open(NpiComponent, {
      disableClose: true,
      autoFocus: false,
      width: width,
      minHeight: height,
      maxHeight: '80vh',
      data: { header: heading, stateList, organizationid: organizationid, mode: mode, NPIFor: NPIFor, isDataPopulated: isDataPopulated, prefilledData: prefilledData, isNPIValidationEnabled: isNPIValidationEnabled }
    });
  }

  openFaxDailog(heading: string, services: any, value: string, accountid: number, labAccountDetails: any, loggedInUserId: any, labOrgid: number, faxsavedData: any, width = '80vw', height = '78vh') {
    return this.dialog.open(FaxComponent, {
      disableClose: true,
      autoFocus: false,
      width: width,
      height: height,
      maxHeight: '80vh',
      data: { header: heading, Services: services, ruleName: value, accountId: accountid, labAccountDetails: labAccountDetails, loggedInUserId, labOrgid: labOrgid, savedObjects: faxsavedData }
    });
  }

  openAttachmentsViewDailog(file: any, fileName: string, width = '72vw') {
    return this.dialog.open(ViewAttachmentComponent, {
      disableClose: true,
      width: width,
      height: '75vh',
      data: { file: file, fileName: fileName }
    });
  }

  openCommonDialog(heading: string, message: string, subMessage: string, continueBtn: string, cancelBtn: string) {
    return this.dialog.open(LabAdminDailogComponent, {
      disableClose: true,
      width: '500px',
      data: { dailogHeading: heading, message: message, alert: subMessage, continue: continueBtn, cancel: cancelBtn }
    });
  }

  openUserBulkUploadDailog(heading: string, type: 'users' | 'labusers' | 'referringphy' | 'physician' = 'users', height = 'auto', width = 'auto') {
    return this.dialog.open(BulkUploadPopUpComponent, {
      disableClose: true,
      width: width,
      minHeight: height,
      maxHeight: '80vh',
      data: { header: heading, type: type }
    });
  }

  // openLabAdminconfirmDialog(heading:string,message:string,continueText:string,cancelText:string) {
  //   return this.dialog.open(ConfirmLabadminComponent, {
  //      disableClose: true,
  //      width: '360px',
  //      data: { dailogHeading: heading, message: message, continue: continueText, cancel: cancelText }
  //    });
  //  }

  openLabadminConfimationdialog(header: string, message: string, continueBtn: string, cancelBtn: string) {
    return this.dialog.open(ConfirmLabadminComponent, {
      disableClose: true,
      autoFocus: false,
      width: '360px',
      panelClass: 'admin-custom-popup',
      data: { header: header, message: message, continue: continueBtn, cancel: cancelBtn }
    })
  }


  openUseridPasswordDialog(header: string, loginId: string, password: string, userId: string) {
    return this.dialog.open(LockUnlockUserDialogComponent, {
      width: '30vw',
      autoFocus: false,
      panelClass: 'admin-custom-reset-or-generate-popup',
      data: {
        imagePath: '../../../../assets/icons/user_icons/Regenerate_Password2.svg',
        header: header,
        row1value: loginId,
        row2value: password,
        loginid: loginId,
        userid: userId,
        row1text: 'Login ID',
        row2text: 'Password',
        buttonname: 'Copy',
        type: "generate-password"
      }
    });
  }

  openAdminContactInfoPopUp(EmailDetails: any, deploymentKey: string, loggedinuserId: number) {
    return this.dialog.open(AdminContactInfoPopUpComponent, {
      disableClose: true,
      autoFocus: false,
      width: '65%',
      height: "40.6%",
      data: { loggedinUserId: loggedinuserId, deploymentKey: deploymentKey, EmailDetails: EmailDetails }
    });
  }

  openApprovePasswordPopup(user: { userId: string, loginId: string, facilityAddress: string, username: string, firstName: string, lastName: string, type: string, loggedInUserName: string}, loggedInUserId : string, height = 'auto', width = 'auto') {
    return this.dialog.open(ApprovePasswordComponent, {
      disableClose: true,
      autoFocus: false,
      minWidth: '58vw',
      width: width,
      minHeight: height,
      maxHeight: '80vh',
      data: { user, loggedInUserId }
    }
    )
  }

  //email and phone is used with mail-sent type to display contact info in popup
  openWarningPopup(header: string, message: string, image: string = '/assets/icons/user_icons/warning.svg', email: string = '', phone: string = '', buttonName: string = 'OK'){
    return this.dialog.open(LockUnlockUserDialogComponent, {
      width: '30vw',
      autoFocus: false,
      data: {
        imagePath: image,
        header: header,
        message: message,
        disableClose: true,
        buttonname: 'OK',
        type: "mail-sent",
        email: email,
        phone: phone
      }
    })
  }
}
export interface Lookup {
  LookupID: string;
  LookupValue: string;
  Description: string;
  SequenceOrder: number;
}
