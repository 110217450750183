import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroupDirective, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatOption, ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import * as wjCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjGrid from '@grapecity/wijmo.grid';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { cloneDeep } from 'lodash';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject } from 'rxjs';
import { VitalAdminTabService } from 'src/app/client/tab.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { SubMenuCardModel } from '../../client/DbModel/SubMenuCard/Submenucardmodel';
import { ActivatedRoute } from '@angular/router';
import * as XLSX from 'xlsx';
import { error } from 'console';
import {ConfirmLabadminComponent} from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { LockUnlockUserDialogComponent } from 'src/app/base/popup/lock-unlock-user-dialog/lock-unlock-user-dialog.component';
import { VitalMenuComponent } from '../../../app/client/Vital Search Menu/vital-menu.component';

declare var $: any;
export interface Task {
  color: ThemePalette;
}

@Component({
  selector: 'app-common-bulk-functions',
  templateUrl: './common-bulk-functions.component.html',
  styleUrls: ['./common-bulk-functions.component.scss']
})

export class CommonBulkFunctionsComponent implements OnInit, OnChanges {
  task: Task = {
    color: 'primary',
  };

  @ViewChild('matSelectOption') matSelectOption: MatSelect;
  @ViewChild('inputAutoComplete') inputAutoComplete: any;
  arrowIconSubject = new BehaviorSubject('arrow_drop_down');

  @Input() templateData: any;
  @Input() selectedUser;
  @Input() cardContext;
  @Input() columnsToRemove;
  @Output() uploadBack = new EventEmitter();
  gridarray: any[];
  columns: ColDef[];
  gridwidth: number;
  gridData: any = [];
  gridPage: boolean = true;
  gridDisplay: boolean = false;
  public DeploymentKeys = [];
  showPaginationMainGrid: boolean = false;
  workBook: any;
  sheetsToSelect: any;
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  sheetHeader = [];
  excelDataArray: any = [];
  addEditClicked: boolean = false;
  gridWidth: number = 0;
  showInvalidColumns: boolean = false;
  invalidColumns: string = "";
  gridArray = [];
  selector: Selector;
  inData: any;
  AllFields = [{}]
  MandatoryFields = [{}]
  BulkUpdate = [{}]
  uploadBtn: boolean = false;
  createBtn: boolean = false;
  editBtn: boolean = false;
  exportBtn: boolean = false;
  copyBtn: boolean = false;
  Orgtype: any;
  backBtn: boolean = false;

  showDelete: boolean = true;
  postDownload: boolean = false;
  postUpload: boolean = false;
  destDeployment: string = "";
  RoleList: any = [];
  allSelected = false;
  editEnabled: boolean = false;
  categoryList: any = [];
  selectedrowData: any = {};
  action: string;
  userRoleList: any = [];
  RoleListname: any = [];
  srcOrgid: string;
  srcDeployment: string;
  searchInput: String = '';
  searchUserInput: String = '';
  usertoggle: boolean = false;
  public searchResult: Array<any> = [];
  searchRoleList: any[];
  orgList: any[];
  srcRole: string;
  selectedItems: any = [];
  checkStatus: boolean = false;
  destOrgid: string;
  @Input()
  templateChangedTime: string;
  bulkUpdateFieldsEmpty: boolean = false;
  dropdownSettings: {
    singleSelection: boolean;
    idField: string;
    textField: string;
    enableCheckAll: boolean;
    allowSearchFilter: boolean;
    limitSelection: number;
    clearSearchFilter: boolean;
    maxHeight: number;
    itemsShowLimit: number;
    closeDropDownOnSelection: boolean;
    showSelectedItemsAtTop: boolean;
    defaultOpen: boolean;
  };
  userDropdownList2: any;
  parentID: any;
  excelDownloadArray: any[];
  headerName: boolean = false;
  name: any;
  public SubMenuCardModel;
  auditableColumns: any;
  configuredData: any;
  configureSheets: any;
  sampleDataAttrAll: any;
  updateFlag: boolean;
  toolTipText: any;
  optionToolTipText: any;
  filename: any;
  searchText: any;
  uploadClicked: boolean;

  constructor(private ngxService: NgxUiLoaderService, private datashare: DataShareService,
    private _fb: FormBuilder, public tabService: VitalAdminTabService, public VitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar, private dialog: MatDialog, public commonService: CommonService,public VitalMenuComponent: VitalMenuComponent) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      VitalHttpServices,
      datashare
    );
    this.DeploymentKeys = this.VitalHttpServices.DeploymentKeys;
  }


  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  selectAll = new FormControl();


  copyFormGroup = this._fb.group({
    frmOrganization: ["", Validators.required],
    frmRole: ["", Validators.required],
    frmDepKey: ["", Validators.required],
  })

  //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
  //Add '${implements OnChanges}' to the class.
  childPage: boolean = false;
  ngOnChanges(changes: SimpleChanges): void {
    this.gridPage = false;
    this.editEnabled = false;
    // this.uploadClicked = false;
    this.addEditClicked = false;
    // this.copyDataClicked = false;
    //this.addGridData(this.templateData.submenuData);
  }

  ngOnInit(): void {
    this.childPage = true;
    this.destDeployment = sessionStorage.getItem('deploymentKey').toUpperCase();
    this.destOrgid = (!this.commonService.orgid) ? this.templateData.secondarykeys.OrganizationId : this.commonService.orgid;
    // this.GetButtondetails();
    this.loadUploadScreen(); 
    this.getConfigurations();
    this.getAuditableDetails(this.templateData.menuURL);
  }
  getConfigurations() {
    this.ngxService.start();
    let query = this.VitalHttpServices.GetQuery('CategoryDropDown');
    let queryVariable = { keyword: this.templateData.menuURL.toString(), context: 'bulkFunctionalities' };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult, 'configdb').subscribe(async (Resdata) => {
      this.ngxService.stop();
      if (!Resdata.errors) {
        this.configuredData = JSON.parse(Resdata?.data?.submenuData[0]?.Items_JSON);
        this.configureSheets = this.configuredData.sheets;
        // this.sampleDataAttrAll=structuredClone(this.sampleData);
      }
    }, error => {
      this.ngxService.stop()
      console.error(error);
    })
  }

  //#region Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    if (result) {
      this.convertToJson(result)
    }
  }
  //#endregion


  onFileDropped($event) {
    this.onFileChange($event);
  }
  //#region Method to hit once a file is dragged to or uploaded
  onFileChange(ev) {
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    this.updateFlag = filename?.toLowerCase()?.includes("update") ? true : false;
    let splitarry = filename.split('.');
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect.filter(va => va.toLowerCase() != 'sample data');
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == this.configuredData.tablename.toLowerCase())
            this.sheetsToSelect.length == 1 ? this.convertToJson(validSheet[0]) : this.openModal();
          }
          else {
            this.convertToJson(this.sheetsToSelect[0])
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }
  //#endregion

  //#region Method to validate sheet data.
  convertToJson(sheetname) {
    var worksheet;
    this.excelDataArray = [];
    let tempExcelArr = [];
    let primary = {}
    let tempArray = []
    this.sheetHeader = [];
    let temp = [];
    let i = 1;
    this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    });
    tempExcelArr = XLSX.utils.sheet_to_json(worksheet, { defval: null });
    if (tempExcelArr.length == 0) {
      this._snackbar.open("The uploaded sheet does not contain any data", "Close");
      tempExcelArr = [];
      return;
    }
    tempExcelArr.find(r => {
      for (let [key, value] of Object.entries(r)) {
        if (!key.toString().match(/empty/i)) {
          primary[key] = value;
        }
      }
      tempArray.push(primary)
      primary = {}
    });
    temp = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    // this.sheetHeader = temp[0];
    // temp[0].reverse();
    // temp[0].push("Notes");
    // 
    temp[0].reverse();
    temp[0].push("ResultMessage");
    temp[0].push("Result");
    temp[0].reverse();
    this.sheetHeader = temp[0];
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = this.convertObjKeysToLower(tempArray);
    this.excelDownloadArray = [...this.excelDataArray]
    if (this.updateFlag) { // for making empty if column removed from excel.
      this.excelDataArray.forEach(obj => {
        for (const key of this.configuredData.columns[0]["Bulk Update"]) {
          if (!obj.hasOwnProperty(key?.toLowerCase())) {
            obj[key?.toLowerCase()] = "";
          }
        }
      });
    }
    this.excelDataArray?.find(d => {
      if (this.cardContext == 'fromUsers') {
        Object.assign((d), {
          slno: Number(i), "resultmessage": '', type: this.updateFlag ? 'update' : 'insert', tablename: this.configuredData.tablename, organizationid: Number(this.commonService.orgid), userid: this.selectedUser
        });
      }
      else if (this.cardContext == 'fromCaseTypeUsers') {
        Object.assign((d), {
          slno: Number(i), "resultmessage": '', type: this.updateFlag ? 'update' : 'insert', tablename: this.configuredData.tablename, organizationid: Number(this.commonService.orgid), userid: this.selectedUser, casetype: this.templateData.cardtype.toString()
        });
      }
      else if (this.cardContext == 'fromCaseType') {
        Object.assign(d, {
          slno: Number(i), "resultmessage": '', type: this.updateFlag ? 'update' : 'insert', tablename: this.configuredData.tablename, organizationid: Number(this.commonService.orgid), casetype: this.templateData.cardtype.toString() ,accountid: sessionStorage?.getItem('AccountID')
        });
      }
      else if (this.cardContext == 'fromAccount') {
        Object.assign(d, {
          slno: Number(i), "resultmessage": '', type: this.updateFlag ? 'update' : 'insert', tablename: this.configuredData.tablename, organizationid: Number(this.commonService.orgid), accountid: sessionStorage?.getItem('AccountID')
        });
      }
      i++;
    });
    this.validateExcelData(this.excelDataArray);
    //this.excelGridData(this.excelDataArray)
  }
  //#endregion


  //#region Convert Obj to lower
  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        newobj[key.replace(/\s/g, "").toLowerCase()] = inputArr[i][key]
      }
      array.push(newobj)
    }
    return array;
  }
  //#endregion
  //#region Validate Excel/(Copy from other entity data)
  validateExcelData(excelArr) {
    this.ngxService.start();
    let validateObj = {
      "storedProcedureName": this.configuredData.sp[0]["validate"],
      "jsonData": excelArr,
      "isValidate": true,
      "columnsToRemove": this.columnsToRemove
    }
    this.VitalHttpServices.bulkUploadFunctionalities(validateObj, this.destDeployment).subscribe(data => {
      this.ngxService.stop();
      if (data.status.toLowerCase() == 'success') {
        // if (!result.errors) {
        let result = JSON.parse(data.content);
        this.postUpload = false;
        if (result.length > 0) {
          this.ngxService.stop();
          if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
            this.showInvalidColumns = true;
            this.invalidColumns = result[0].InvalidColumns;
          }
          this.excelDataArray.filter(e => {
            result.find(r => {
              if (r.slno === e.slno) {
                e["resultmessage"] = r.resultmessage;
                e.isvalid = r.isvalid;
                e.showpopup = r.showpopup;
                e.showinformation= r.showinformation
                e.alertMessage = r.alertmessage ;
                e.alertHeader = r.alertmessageheader
              }
            });
          });
          this.ngxService.stop();
          if (!this.addEditClicked) {
            this.excelGridData(this.excelDataArray);
          }
        }
        else {
          if (!this.addEditClicked) {
            this._snackbar.open("Please provide valid data", 'Failed');
          }
        }
        // }
      }
      else{
        this._snackbar.open("Something went wrong!","Close");
      }

      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", 'Failed');
      console.error(error);
    });
  }
  //#endregion

  //#region Grid Data : uploaded data with Notes column
  excelGridData(data) {
    this.ngxService.stop();
    this.gridDisplay = true;
    this.gridArray = [];
    this.gridData = [];
    let primary = {};
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(data[i])) {
            primary[key] = value;

          }
          this.gridArray.push(primary);
        }
      }
      this.gridData = this.gridArray
      if (!this.postUpload) {
        this.sheetHeader.includes("Result") && this.sheetHeader.splice(this.sheetHeader.indexOf("Result"), 1);
      } else {
        !this.sheetHeader.includes("Result") && this.sheetHeader.splice(0, 0, "Result")// this.sheetHeader.push("Status");
      }
      this.gridWidth = (170 * this.sheetHeader.length) + 37;
      if (this.gridWidth > 1300) {
        this.gridWidth = 1300;
      }
    }
    else {
      this.gridWidth = 0;
    }
  }
  //#endregion


  //#region Upload screen
  loadUploadScreen() {
    this.gridPage = false;
    this.uploadClicked = true;
    this.addEditClicked = false;
    this.gridDisplay = false;
    this.filename = "Bulk Upload " + this.templateData.menuURL;
  }
  //#endregion
  downloadTemplate(type) {
    let filename = ""
    let sample
    let excel = [{}];
    let sampleExcel = [{}];
    this.sampleDataAttrAll = structuredClone(this.configuredData?.sampledata);
    if (type == 'All Fields') {
      this.configuredData.columns[0]["All Fields"].forEach((element: string) => {
        this.AllFields[0][element] = null;
      });
      if (this.cardContext == 'fromUsers') {
        delete this.AllFields[0]["UserId"];
      }
      else if (this.cardContext == 'fromCaseType') {
        delete this.AllFields[0]["CaseType"];
      } else if (this.cardContext == 'fromCaseTypeUsers') {
        delete this.AllFields[0]["CaseType"];
        delete this.AllFields[0]["UserId"];
      }
      else if (this.cardContext == 'fromAccount') {

      }
      Object.keys(this.sampleDataAttrAll[0]).forEach(key => {
        if (!Object.keys(this.AllFields[0]).includes(key)) {
          delete this.sampleDataAttrAll[0][key];
        }
      });
      filename = this.configuredData.tablename + '_All Fields_' + this.destOrgid.toString() + '.xlsx';
      excel = this.AllFields,
        sample = true;
      sampleExcel = this.sampleDataAttrAll
    }
    else if (type == 'Minimum Fields') {
      this.configuredData.columns[0]["Minimum Fields"].forEach((element: string) => {
        this.MandatoryFields[0][element] = null;
      })
      if (this.cardContext == 'fromUsers') {
        delete this.MandatoryFields[0]["UserId"];
      }
      else if (this.cardContext == 'fromCaseType') {
        delete this.AllFields[0]["CaseType"];
      }
      else if (this.cardContext == 'fromCaseTypeUsers') {
        delete this.MandatoryFields[0]["CaseType"];
        delete this.MandatoryFields[0]["UserId"];
      }
      else if (this.cardContext == 'fromAccount') {

      }
      Object.keys(this.sampleDataAttrAll[0]).forEach(key => {
        if (!Object.keys(this.MandatoryFields[0]).includes(key)) {
          delete this.sampleDataAttrAll[0][key];
        }
      });
      filename = this.configuredData.tablename + '_Minimum Fields_' + this.destOrgid.toString() + '.xlsx';
      excel = this.MandatoryFields
      sample = true;
      sampleExcel = this.sampleDataAttrAll
    }
    else if (type == 'Bulk Update') {
      this.configuredData.columns[0]["Bulk Update"].forEach((element: string) => {
        this.BulkUpdate[0][element] = null;
      })
      if (this.cardContext == 'fromUsers') {
        delete this.BulkUpdate[0]["UserId"];
      }
      else if (this.cardContext == 'fromCaseType') {
        delete this.AllFields[0]["CaseType"];
      }
      else if (this.cardContext == 'fromCaseTypeUsers') {
        delete this.BulkUpdate[0]["CaseType"];
        delete this.BulkUpdate[0]["UserId"];
      }
      else if (this.cardContext == 'fromAccount') {

      }
      Object.keys(this.sampleDataAttrAll[0]).forEach(key => {
        if (!Object.keys(this.BulkUpdate[0]).includes(key)) {
          delete this.sampleDataAttrAll[0][key];
        }
      });
      filename = this.configuredData.tablename + '_Bulk Update_' + this.destOrgid.toString() + '.xlsx';
      excel = this.BulkUpdate;
      sample = true;
      sampleExcel = this.sampleDataAttrAll
    }
    excel.reverse();
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    let sheetName = this.configuredData.tablename.toLowerCase();
    XLSX.utils.book_append_sheet(wb, ws, "" + sheetName);
    sample ? ws = XLSX.utils.json_to_sheet(sampleExcel) : null;
    sample ? XLSX.utils.book_append_sheet(wb, ws, "" + 'Sample Data') : null;
    XLSX.writeFile(wb, filename);
  }


  disableApprovebtn() {

  }
  //#region Tooltip for Grid
  initGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
        // tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col) != true && s.getCellData(e.row, e.col) != false ? s.getCellData(e.row, e.col) : s.getCellData(e.row, e.col) == true ? 'Active' : 'InActive'}`);
      }
    });
  }
  //#endregion
  //#region

  //#endregion

  //#region
  removeGrid() {
    // this.gridWidth = this.copyDataClicked ? -1 : 0;
    this.gridWidth = 0;
    this.excelDataArray = [];
    this.postUpload = false;
    this.backBtn = true;
    this.postDownload = false;
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    this.showDelete = true;
  }
  //#endregion

  //#region Delete Row (soft-delete)
  deleteRow(row) {
    // grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.slno);
    this.excelGridData(this.excelDataArray) // after deleting refreshing the grid
    if (this.excelDataArray.length == 0) {
      this.removeGrid();
    }
    this._snackbar.open('Deleted successfully', 'Close');
  }
  //#endregion

  //#region Upload ExcelData to Insert API
  uploadData() {
    let alertFlag = this.excelDataArray.find(va => va.showpopup == 1)
    let showInformationPopUp = this.excelDataArray.find(va => va.showinformation == 1)
    if (alertFlag) {
      let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
        disableClose: true,
        autoFocus: false,
        width: '500px',
        data: { header: "", message:alertFlag.alertHeader, alert: alertFlag.alertMessage, continue: "Yes", cancel: "No" }
      });
      return dialogRef.afterClosed().toPromise().then(result => {
        if (result) {
          this.uploadBulk()
        }
        else {
          return;
        }
      }, error => {
        console.error(error);
      });
    } 
    else if(showInformationPopUp){
      this.dialog.open(LockUnlockUserDialogComponent, {
        width: '30vw',
        data: {
          imagePath: '../../../../assets/icons/user_icons/warning.svg',
          header: showInformationPopUp.alertHeader,
          message: showInformationPopUp.alertMessage,
          buttonname: 'OK',
          type: "mail-sent"
        }
      })
      .afterClosed().toPromise().then((result) => {
        this.uploadBulk()
      })
    }
    else {
      this.uploadBulk()
    }
  }
  //#endregion
  uploadBulk() {
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if (!this.excelDataArray[i]["resultmessage"]) {
          this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
          return;
        }
        else if (this.excelDataArray[i]["isvalid"] == 0 || this.excelDataArray[i]["isvalid"] == -1) {
          errorcount++;
        }
      }
      if (errorcount > 0) {
        this._snackbar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
        return
      }
      if ((this.invalidColumns != "")) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }

      let procName = this.updateFlag ? this.configuredData.sp[0]["update"] : this.configuredData.sp[0]["insert"]
      // this.excelDataArray?.attributeid == null || sendjson[0].attributeid == '' )
      this.ngxService.start();
      this.excelDataArray.filter(e => {
        e.createdby = sessionStorage.getItem("Userid") || -100;
      })
      let upoloadObj = {
        "storedProcedureName": procName,
        "jsonData": this.excelDataArray
      }
      this.VitalHttpServices.bulkUploadFunctionalities(upoloadObj, this.destDeployment).subscribe(res => {
        this.ngxService.stop();
        if (res.status.toLowerCase() == 'success') {
          let result = JSON.parse(res.content);
          this.postUpload = true;
          this.excelDataArray = [...result]
          this.excelGridData(this.excelDataArray);
          this.commonService.auditDetails('', '', [], this.excelDataArray, this.updateFlag?'Bulk Update':'Upload', this.templateData, this.auditableColumns);
          let showPopUpArray = result?.find((item?:any)=> item?.showpopupafterapprove);
          let showInfoArray = result?.find((item?:any)=> item?.showinfoafterapprove);
          if(showPopUpArray){
            let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
              disableClose: true,
              autoFocus: false,
              width: '500px',
              data: { header: "Extraction Procedure", message:showPopUpArray.alertheaderafterapprove, alert: showPopUpArray.alertmessageafterapprove, continue: "Default Stains", cancel: "Cancel" }
            });
            return dialogRef.afterClosed().toPromise().then(result => {
              if (result) this.VitalMenuComponent.changeComponent('Default Stains');
              else return;
            }, error => {
              console.error(error);
            });
          }
          else if (showInfoArray){
            let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
              disableClose: true,
              autoFocus: false,
              width: '500px',
              data: { header: "", message:showInfoArray.alertheaderafterapprove, alert: showInfoArray.alertmessageafterapprove, continue: "Okay", cancel: "dontshow" }
            });
            return dialogRef.afterClosed().toPromise().then(result => {
            }, error => {
              console.error(error);
            });        
          }  
        }
        else{
          this._snackbar.open("Something went wrong!","Close");
        }
      }, error => {
        this.ngxService.stop();
        console.error(error)
      })
    }
  }
  //#region Export Grid data
  ExportExcel() {
    let excel = [];
    
    this.excelDataArray.forEach(item => {
      const filteredObject = this.sheetHeader.reduce((acc, key) => {
        if (item.hasOwnProperty(key?.toLowerCase())) {
          acc[key.toLowerCase()] = item[key.toLowerCase()];
        }
        return acc;
      }, {});
      excel.push(filteredObject);
    });
    // excel.push(this.excelDataArray);
    let filename = this.templateData.menuURL + '_' + this.destOrgid.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + this.configuredData.tablename);
    XLSX.writeFile(wb, filename);

  }
  //#endregion
  filtertemplates() {
    // for (let i = 0; i < this.excelDataArray?.length; i++) {
    //   this.excelDataArray[i].key = this.searchText;
    // }
    let excelDataArray = this.excelDataArray.filter(obj =>
      Object.values(obj).some(value =>
        typeof value === 'string' ? value.toLowerCase().includes(this.searchText.toLowerCase()) :
          typeof value === 'number' ? value.toString().includes(this.searchText) : false
      ))
    this.excelGridData(excelDataArray);
  }
  clearSearch() {
    this.searchText = '';
    this.filtertemplates();
  }
  
  uploadCopyData(flexgrid) {
    let tempData = [];
    tempData = [...this.excelDataArray];
    this.selector = new Selector(flexgrid, {
      itemChecked: () => {
        this.selectedItems = flexgrid.rows.filter(r => r.isSelected);
      }
    });
    this.excelDataArray = [];
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.excelDataArray.push(this.selectedItems[i]._data);
    }
    // }
    this.uploadData();
  }

  //#endregion
  getAuditableDetails(location: any) {
    this.VitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);

    })
  }

  // //     //Called once, before the instance is destroyed.
  // //       //Add 'implements OnDestroy' to the class.
  ngOnDestroy(): void {
    this.gridData = new CollectionView([]);
  }


  backToParent() {
    this.uploadBack.emit(true);
  }

  trimFieldValue(controlName: any) {
    this.commonService.trimFieldValue(this.copyFormGroup, controlName, this.copyFormGroup.value.frmOrganization)
  }
  trimRoleFieldValue(controlName: any) {
    this.commonService.trimFieldValue(this.copyFormGroup, controlName, this.copyFormGroup.value.frmRole)
  }
  enableToolTip(e, type = '') {
    const text = e.scrollWidth > e.clientWidth ? e.textContent : '';
    type !== 'dropdown' ? this.toolTipText = text : this.optionToolTipText = text;
  }
  displayedColumns(item: any, header: any) {
    let data = item[header] ? item[header] : ""
    return data;
  }

}
class ColDef {
  constructor(public binding?: string, public header?: string) {
  }
}

