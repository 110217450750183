import { Clipboard } from "@angular/cdk/clipboard";
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ThemePalette } from "@angular/material/core";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as wjcCore from "@grapecity/wijmo";
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { ActivityTrackerService } from "src/app/core/services/activity-tracker.service";
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { VDInstallation } from "src/app/model/desktoptemplate";
//import 'src/assets/css/styles.css';
import * as XLSX from 'xlsx';
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';
import { InjectorService } from '../../../core/services/Injectorservices';
import { VitalAdminTabService } from '../../tab.service';
import { VitalAdminSearchConnectorComponent } from '../../vital-admin-search-connector/vital-admin-search-connector.component';

export interface Task {
  color: ThemePalette;
}
@Component({
  selector: 'app-gridguidcontainer',
  templateUrl: './gridguidcontainer.component.html',
  styleUrls: ['./gridguidcontainer.component.scss']
})

export class GridguidcontainerComponent implements OnInit, OnDestroy {
  task: Task = {
    color: 'primary',
  };

  @Input()
  public subMenudata: any;
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  labels: any;
  @Input()
  rowData: any;
  columnDefs: any = [];
  gridwidth: number = 0;

  labelFirstCount: number = 0;

  @Input()
  templateData: any;

  @Input()
  templateChangedTime: string;

  cvPaging: CollectionView;
  data: any;
  // dateFormat = "yyyy-MM-dd";
  // language = "en";
  // dateTime = new Date();

  recipientData = this._fb.group({
    ChooseDropdown: this._fb.array([]),
    ComputerName: ['', Validators.maxLength(50)],
    FormInputLocation: '',
    formInput_VDName: ['', [Validators.maxLength(50), Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    VD_Identifier: [''],
    VitalAxis_URL: [''],
    Customer_Type: [''],
    IsActive: true,
    AutoUpgradeCompleted: false,
    CustomerName: ['', Validators.maxLength(50)],
    SystemOS: [''],
    SystemIP: ['', Validators.maxLength(20)],
    formInput_VDVersion: ['', [Validators.maxLength(10), Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    SpecificConfig: ['', Validators.maxLength(100)],
    Comments: ['', Validators.maxLength(800)],
    OrganizationGUID: [''],
    formInput_VitalAxisDataURL: [''],
    formInput_LoginPage: ['', [Validators.minLength(2), Validators.maxLength(50),
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    formInput_LatestVersion: ['', Validators.maxLength(20)],
    // formInput_AutoUpgradeDate: new FormControl(this.dateTime.toISOString().substring(0, 16)),
    formInput_PointOfContact: ['', Validators.maxLength(50)],
    formInput_ContactNumber: ['', Validators.maxLength(50)],
    // formInput_ContactNumber: ['', [
    //   Validators.pattern("^[0-9]*$"),
    //   Validators.minLength(10), Validators.maxLength(15)]],
    formInput_RequestedBy: ['', Validators.maxLength(50)],
    formInput_OnTimeTicket: ['', Validators.maxLength(50)],
    formInput_orgid: [''],
    formInput_orgname: [''],
    formInput_ComputerLocation: ['', Validators.maxLength(50)],
    formInput_AdminLoginName: ['', Validators.maxLength(50)],
    formInput_InstallationStatus: ['', Validators.maxLength(50)],
    formInput_InstallationTime: [''],
    formInput_InstalledBy: ['', Validators.maxLength(50)],
    formInput_LabelsUsed: ['', Validators.maxLength(50)],
    formInput_PrinterType: ['', Validators.maxLength(50)],
    formInput_SenderAgentID: ['', Validators.maxLength(50)],
    formInput_APIKey: ['', Validators.maxLength(50)],
    formInput_EnvKey: ['', Validators.maxLength(50)],
    formInput_DeploymentKey: ['']
  })


  AddRecipientData = this._fb.group({
    formLocation: '',
    ChooseAccountName: this._fb.array([]),
    formInput_VitalAxisURL: '',
    formInput_IsActive: true,
    formInput_LoginPage: ['', [Validators.minLength(2), Validators.maxLength(50),
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    formInput_VitalAxisDataURL: [''],
    formInput_LatestVersionAvailable: ['', Validators.maxLength(20)],
    formInput_CustomerName: ['', Validators.maxLength(50)],
    formInput_PointOfContact: ['', Validators.maxLength(50)],
    formInput_ContactNumber: ['', Validators.maxLength(50)],
    formInput_IsFacility: [''],
    formInput_RequestedBy: ['', Validators.maxLength(50)],
    formInput_OnTimeTicket: ['', Validators.maxLength(50)],
    formInput_OrganizationID: [''],
    formInput_CustomerType: [''],
    formInput_ComputerLocation: ['', Validators.maxLength(50)],
    formInput_ComputerName: ['', Validators.maxLength(50)],
    formInput_SystemOS: [''],
    formInput_SystemIP: ['', Validators.maxLength(20)],
    formInput_AdminLoginName: ['', Validators.maxLength(50)],
    formInput_VDVersion: ['', [Validators.maxLength(10),
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    formInput_LabelsUsedOnThisComputer: ['', Validators.maxLength(50)],
    formInput_SpecificConfig: ['', Validators.maxLength(100)],
    formInput_Comments: ['', Validators.maxLength(800)],
    formInput_PrinterType: ['', Validators.maxLength(50)],
    formInput_TimeConsumedForInstallation: [''],
    formInput_InstallationStatus: ['', Validators.maxLength(50)],
    formInput_InstalledBy: ['', Validators.maxLength(50)],
    formInput_senderagentid: ['', Validators.maxLength(50)],
    formInput_apiKey: ['', Validators.maxLength(50)],
    formInput_environmentKey: ['', Validators.maxLength(50)],
    formInput_DeploymentKey: [''],
    formInput_VDName: ['', [Validators.maxLength(50), Validators.required,
    Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    formInput_OrganizationGUID: ['']
  })

  auditableColumns: any;
  activityEntity: any;

  columns;
  gridData: CollectionView;
  tabIndex = 0;
  public noData = false;
  tabs = ['All', 'Incidents', 'Audits', 'Comments'];
  public SubMenuCardModel;
  public notSpecified = true;
  public Req_notSpecified = true;
  public statsData;
  public OrgNameArray;
  public VDP_Array;
  checkbox
  AutoUpgradecheckbox
  public gridDisplay = true;
  public ShowGrid = false;
  public NoDataCreateNewPage = false;
  public VD_Identifier;
  public Customer_Type;
  public VitalAxisDataURL;
  public LoginPage;
  public AutoUpgradeCompleted;
  public AutoUpgradeCompletedDateTime;
  public Comments;
  public VDName;
  public ComputerName;
  public ComputerLocation
  public CustomerName;
  public IsActive;
  public IsFacility;
  public AdminLoginName;
  public LatestVersionAvailable;
  public TimeConsumedForInstallation;
  public InstalledBy;
  public OrganizationId;
  public PrinterType;
  public ContactNumber;
  public InstallationStatus
  public LabelsUsedOnThisComputer;
  public RequestedBy;
  public SpecificConfig;
  public SystemIP;
  public SystemOS;
  public ActivatedDate;
  public OnTimeTicket;
  public VDVersion;
  public saveUsername: boolean;
  public VitalAxis_URL;
  public apiKey;
  public environmentKey;
  public inputElement;
  public export: boolean = true;
  public Create: boolean = true;
  public Edit: boolean = true;
  public button: any[];
  public gridarray;
  public ShowEditPage: boolean = false;
  public ShowAddNewPage: boolean = false;
  public OrganizationGUID;
  public Validation_error;
  public ValidateOrg_error;
  public organizationname;
  public senderagentid;
  public apikey;
  public OrgName;
  public VitalDesktopID
  public OrganizationID
  public SettingsFileName
  public ProcessedDate
  public Add_VitalAxisURL
  public Add_VitalAxisDataURL
  public Add_IsFacility
  public DeploymentKey
  OS_DropdownItems: any[];
  OS_UpdateDropdownItems: any[];
  CustType_DropdownItems: any[];
  LocationList: any[];
  private getguidsSubscription: Subscription | undefined;
  gridHeader = ['Organization_GUID', 'Status', 'Association_Type', 'IP_Address', 'Computer_Name', 'Activated_Date', 'Name', 'ID']
  updateFlag: boolean = false;
  createFlag: boolean = false;
  updateRecordType: any;
  updatedVDIdentifier: any;
  NewVDIdentifier: any;
  hideChooseOrgOption: boolean;
  AxisDataURLs: any = [];
  AxisURLs: any = [];

  constructor(private ngxService: NgxUiLoaderService, public activityService: ActivityTrackerService, private injectorServiceGridContainer: InjectorService, private router: Router, private clipboard: Clipboard, public tabService: VitalAdminTabService, public vitalHttpServices: VitalHttpServices, public DataShare: DataShareService,
    private _snackbar: MatSnackBar, public commonService: CommonService, private _fb: FormBuilder, public vaservices: VitalHttpServices, public VitalAdminSearchConnectorComponent: VitalAdminSearchConnectorComponent) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, vitalHttpServices, DataShare);
    this.columns = [
      //************* Grid Customized Column names *********/
      new ColDef('ID', 'ID'),
      new ColDef('Name', 'Name'),
      new ColDef('Activated_Date', 'Activated Date'),
      new ColDef('Computer_Name', 'Computer Name'),
      new ColDef('IP_Address', 'IP Address'),
      new ColDef('Association_Type', 'Type'),
      new ColDef('Status', 'Status')
    ];
  }

  ngOnInit() {
    this.AddGridData();
    this.GetButtondetails();
    this.getAuditableDetails(this.templateData.menuURL);
    this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': 'parentMenu', 'value': this.templateData.menuURL }] })
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
  }

  ngOnChanges() {
    if (this.updateFlag == true) {
      this.updateOnClick();
    }
    if (this.createFlag == true) {
      this.updateOnClick();
    }
  }

  getAuditableDetails(location: any) {
    this.vitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }

  // formatFormDate(date: Date) {
  //   return formatDate(date, this.dateFormat, this.language);
  // }

  // setNow(timeTaken) {
  //   timeTaken = new Date();
  //   let hours = ("0" + timeTaken.getHours()).slice(-2);
  //   let minutes = ("0" + timeTaken.getMinutes()).slice(-2);
  //   let str = hours + ':' + minutes;
  //   return str;
  // }

  //*** RBAC */
  GetButtondetails() {
    this.GetButtonAccess(this.vitalHttpServices.SubmenuAction);
    // this.DataShare.SubmenuAction.subscribe(data => {
    //   if (data) {
    //     this.GetButtonAccess(data);
    //   }
    // });
  }

  //*** RBAC */
  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Export":
          this.export = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Create":
          this.Create = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.Edit = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
      }
    }
  }

  public onSaveUsernameChanged(value: boolean) {
    this.saveUsername = value;
  }

  copy(value: string) {
    this.clipboard.copy(value);
    this._snackbar.open("Copied Successfully", "Close");    
  }

  getStatus(isActive: any) {
    if (isActive == 'Yes' || isActive == 1) {
      return 'Active';
    } else {
      return 'Inactive';
    }
  }

  //***** Back button *****/
  back() {
    this.ngxService.start();
    this.ngOnInit();
    // this.AddGridData();
    this.ngxService.stop();
    this.recipientData.reset();
    this.AddRecipientData.reset();
    this.ShowGrid = false;
    this.NoDataCreateNewPage = false;
    this.ShowAddNewPage = false;
    this.ShowEditPage = false;

    this.activityEntity.entityId = "";
    this.activityService.setActivitySession(this.activityEntity);

    if (this.gridwidth != 0) {
      this.gridDisplay = true;
    }
    else {
      this.NoDataCreateNewPage = true;
    }
  }

  getpercentage(pixel: number) {
    // var pixels = 100;
    var screenWidth = window.screen.width;
    let per = (pixel / screenWidth) * 100
    if (per < 80) {
      per = per + 8
    }
    return per;
  }

  //***** View VD Grid *****/
  //#region
  AddGridData() {
    this.NoDataCreateNewPage = false;
    this.gridarray = [];
    let primary = {};
    if (this.templateData.submenuData) {
      if (this.templateData.submenuData.length > 0) {
        for (let i = 0; i < this.templateData.submenuData.length; i++) {
          primary = {}
          for (let [key, value] of Object.entries(this.templateData.submenuData[i])) {
            for (let j = 0; j < this.gridHeader.length; j++) {
              if (key === this.gridHeader[j]) {
                primary[key] = value
              }
            }
          }
          this.gridarray.push(primary)
        }
        this.gridData = new CollectionView(this.gridarray, { pageSize: 10 })
        this.gridwidth = (170 * this.gridHeader.length) + 37;
        if (this.gridwidth > 1300)
          this.gridwidth = 1300;
        if (this.gridwidth != 0) {
          this.gridwidth = this.getpercentage(this.gridwidth)
        }
      } else {
        this.gridwidth = 0;
        this.NoDataCreateNewPage = true;
      }
    } else {
      this.gridwidth = 0;
      this.NoDataCreateNewPage = true;
    }
  }
  //#endregion

  //**** View Page on updating the VD Details */
  updateOnClick() {
    this.ShowEditPage = false;
    this.ShowGrid = true;
    this.gridDisplay = false;
    sessionStorage.setItem('deploymentKey', 'configdb');
    let queryVariable = { "VDIdentifier": this.updatedVDIdentifier, "OrganizationGUID": this.OrganizationGUID };
    let query = this.SubMenuCardModel.GetQuery("vdguidonclickinstall");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        if (data.data.submenuData.length > 0) {
          this.statsData = data.data.submenuData;
          if (this.statsData.length > 0) {
            this.noData = false;
            this.notSpecified = false;
          } else {
            this.noData = true;
            this.notSpecified = true;
          }
          this.VD_Identifier = this.statsData[0].VD_Identifier
          this.Customer_Type = this.statsData[0].Customer_Type
          this.VitalAxis_URL = this.statsData[0].VitalAxis_URL ?? 'Not Specified';
          this.VitalAxisDataURL = this.statsData[0].VitalAxisDataURL ?? 'Not Specified';
          this.LoginPage = this.statsData[0].LoginPage ?? 'Not Specified';
          this.AutoUpgradeCompleted = this.statsData[0].AutoUpgradeCompleted
          this.AutoUpgradeCompletedDateTime = this.statsData[0].AutoUpgradeCompletedDateTime
          this.IsActive = this.statsData[0].IsActive
          this.ComputerLocation = this.statsData[0].ComputerLocation
          this.ComputerName = this.statsData[0].ComputerName
          this.SystemOS = this.statsData[0].SystemOS
          this.senderagentid = this.statsData[0].senderagentid
          this.apikey = this.statsData[0].apiKey
          this.ActivatedDate = this.statsData[0].ActivatedDate.replaceAll('T', ' ')
          this.SystemIP = this.statsData[0].SystemIP
          this.VDVersion = this.statsData[0].VDVersion
          this.AdminLoginName = this.statsData[0].AdminLoginName
          this.LabelsUsedOnThisComputer = this.statsData[0].LabelsUsedOnThisComputer
          this.PrinterType = this.statsData[0].PrinterType
          this.OnTimeTicket = this.statsData[0].OnTimeTicket
          this.InstallationStatus = this.statsData[0].InstallationStatus
          this.TimeConsumedForInstallation = this.statsData[0].TimeConsumedForInstallation
          this.InstalledBy = this.statsData[0].InstalledBy
          this.SpecificConfig = this.statsData[0].SpecificConfig
          this.CustomerName = this.statsData[0].CustomerName
          this.ContactNumber = this.statsData[0].ContactNumber
          this.RequestedBy = this.statsData[0].RequestedBy
          this.OrganizationId = !this.statsData[0].OrganizationId ? sessionStorage.getItem('org_id') : this.statsData[0].OrganizationId
          this.Comments = this.statsData[0].Comments
          this.VDName = this.statsData[0].VDName
          if (this.IsActive == "Yes") {
            this.checkbox = true;
          }
          else {
            this.checkbox = false;
          }
          if (this.AutoUpgradeCompleted == true) {
            this.AutoUpgradecheckbox = true;
          }
          else {
            this.AutoUpgradecheckbox = false;
          }
          if (this.VD_Identifier == "Not Specified" || this.SystemIP == "Not Specified") {
            this.notSpecified = false;
          }
          else {
            this.notSpecified = true;
          }
          if (this.OnTimeTicket == "Not Specified") {
            this.Req_notSpecified = false;
          }
          else {
            this.Req_notSpecified = true;
          }
        }
        if (data.data.submenuData1.length > 0) {
          this.VDP_Array = data.data.submenuData1;
          this.SettingsFileName = this.VDP_Array[0].SettingsFileName;
          this.ProcessedDate = this.VDP_Array[0].ProcessedDate;
        }
      }
      sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
      this.GetOrgName(this.OrganizationId);
    }, error => {
      console.error(error);
      sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
    });
  }

  //**** VD View Page *****/
  onClick(gridguid, e) {
    sessionStorage.setItem('deploymentKey', 'configdb');
    let dataItem;
    let hti = gridguid.hitTest(e);
    if (hti.panel === gridguid.cells) {
      let row = gridguid.rows[hti.row]
      dataItem = row.dataItem;
      if (dataItem == null) {
        return;
      }
      else {
        this.OrganizationGUID = null;
        this.ShowGrid = true;
        this.gridDisplay = false;
        let VDIdentifier = dataItem.ID;
        this.updateRecordType = dataItem.Type;
        this.OrganizationGUID = dataItem.Organization_GUID;
        let queryVariable = { "VDIdentifier": VDIdentifier, "OrganizationGUID": this.OrganizationGUID };
        let query = this.SubMenuCardModel.GetQuery("vdguidonclickinstall");
        let queryResult = this.commonService.GetCardRequest(queryVariable, query);
        this.vitalHttpServices.GetData(queryResult).subscribe(data => {
          if (!data.errors) {
            if (data.data.submenuData.length > 0) {
              this.statsData = data.data.submenuData;
              if (this.statsData.length > 0) {
                this.noData = false;
                this.notSpecified = false;
              } else {
                this.noData = true;
                this.notSpecified = true;
              }
              this.VD_Identifier = this.statsData[0].VD_Identifier
              this.Customer_Type = this.statsData[0].Customer_Type
              this.VitalAxis_URL = this.statsData[0].VitalAxis_URL
              this.VitalAxisDataURL = this.statsData[0].VitalAxisDataURL
              this.LoginPage = this.statsData[0].LoginPage
              this.AutoUpgradeCompleted = this.statsData[0].AutoUpgradeCompleted
              this.AutoUpgradeCompletedDateTime = this.statsData[0].AutoUpgradeCompletedDateTime
              this.IsActive = this.statsData[0].IsActive
              this.ComputerLocation = this.statsData[0].ComputerLocation
              this.ComputerName = this.statsData[0].ComputerName
              this.SystemOS = this.statsData[0].SystemOS
              this.senderagentid = this.statsData[0].senderagentid
              this.apikey = this.statsData[0].apiKey
              this.ActivatedDate = this.statsData[0].ActivatedDate.replaceAll('T', ' ')
              this.SystemIP = this.statsData[0].SystemIP
              this.VDVersion = this.statsData[0].VDVersion
              this.AdminLoginName = this.statsData[0].AdminLoginName
              this.LabelsUsedOnThisComputer = this.statsData[0].LabelsUsedOnThisComputer
              this.PrinterType = this.statsData[0].PrinterType
              this.OnTimeTicket = this.statsData[0].OnTimeTicket
              this.InstallationStatus = this.statsData[0].InstallationStatus
              this.TimeConsumedForInstallation = this.statsData[0].TimeConsumedForInstallation
              this.InstalledBy = this.statsData[0].InstalledBy
              this.SpecificConfig = this.statsData[0].SpecificConfig
              this.CustomerName = this.statsData[0].CustomerName
              this.ContactNumber = this.statsData[0].ContactNumber
              this.RequestedBy = this.statsData[0].RequestedBy
              this.OrganizationId = this.statsData[0].OrganizationId
              this.Comments = this.statsData[0].Comments
              this.VDName = this.statsData[0].VDName
              if (this.IsActive == "Yes") {
                this.checkbox = true;
              }
              else {
                this.checkbox = false;
              }
              if (this.AutoUpgradeCompleted == true) {
                this.AutoUpgradecheckbox = true;
              }
              else {
                this.AutoUpgradecheckbox = false;
              }
              if (this.VD_Identifier == "Not Specified" || this.SystemIP == "Not Specified") {
                this.notSpecified = false;
              }
              else {
                this.notSpecified = true;
              }
              if (this.OnTimeTicket == "Not Specified") {
                this.Req_notSpecified = false;
              }
              else {
                this.Req_notSpecified = true;
              }
            }
            if (data.data.submenuData1.length > 0) {
              this.VDP_Array = data.data.submenuData1;
              this.SettingsFileName = this.VDP_Array[0].SettingsFileName;
              this.ProcessedDate = this.VDP_Array[0].ProcessedDate;
            }
          }
          sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
          this.GetOrgName(this.OrganizationId);
        }, error => {
          console.error(error);
          sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
        });

      }
    }
  }

  //**** Export Details of selected Grid item  ****/
  exportdata() {
    let excel = []
    let filename = 'Installations_';
    let LabName = sessionStorage.getItem("LabName");
    if (this.statsData[0].VDName) {
      filename = filename + this.statsData[0].VDName;
      if (filename.length > 31) {
        let fileName = filename.split('_')[0] + '_';
        let orgName = filename.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
        if (orgName.length > 31) {
          fileName = filename.split('_')[0].replace(' ', '') + '_';
          filename = fileName + orgName + '_';
        }
        else {
          filename = fileName + orgName + '_';
        }
      }
      else {
        filename = filename + '_';
      }
    }

    else if (LabName != 'undefined' && LabName) {
      filename = filename + LabName;
      if (filename.length > 31) {
        let fileName = filename.split('_')[0] + '_';
        let orgName = filename.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
        if (orgName.length > 31) {
          fileName = filename.split('_')[0].replace(' ', '') + '_';
          filename = fileName + orgName + '_';
        }
        else {
          filename = fileName + orgName + '_';
        }
      }
      else {
        filename = filename + '_';
      }
    }
    else {
      filename = filename;
    }
    let primary = {}
    let obj = ['VD_Identifier', 'VDVersion', 'VitalAxis_URL', 'IsActive', 'AutoUpgradeCompleted',
      'AutoUpgradeCompletedDateTime', 'ComputerLocation', 'ComputerName', 'SystemIP', 'SystemOS',
      'ContactNumber', 'AdminLoginName', 'ActivatedDate', 'OnTimeTicket', 'senderagentid',
      'apiKey', 'LabelsUsedOnThisComputer', 'PrinterType', 'SpecificConfig',
      'CustomerName', 'CustomerName', 'VDName']

    for (let [key, value] of Object.entries(this.statsData[0])) {
      obj.find((e): any => {
        if (key === e) {
          primary[key] = value;
        }
      });
    }
    excel.push(primary);
    filename = filename + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    if (filename.length > 31) {
      let fileName = filename.split('_')[0] + '_';
      if(fileName.length>31){
        filename = fileName.split('_')[0] +'.xlsx';
      }else{
         if(this.templateData.secondarykeys){
        filename = fileName  + this.templateData.secondarykeys.OrganizationId.toString()+'.xlsx';
      }
      else{
        filename = fileName.split('_')[0] +'.xlsx';
      }
    }
    }
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + filename);
    XLSX.writeFile(wb, filename);
  }

  //****Export Grid data *****/
  ExportExcel(flex) {
    let LabName = sessionStorage.getItem("LabName")
    var file_name = 'Installations_';
    if (LabName != 'undefined' && LabName) {
      file_name = file_name + LabName;
      if (file_name.length > 31) {
        let fileName = file_name.split('_')[0] + '_';
        let orgName = file_name.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
        if (orgName.length > 31) {
          fileName = file_name.split('_')[0].replace(' ', '') + '_';
          file_name = fileName + orgName + '_';
        }
        else {
          file_name = fileName + orgName + '_';
        }
      }
      else {
        file_name = file_name + '_';
      }
    }

    file_name = file_name + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    if (file_name.length > 31) {
      let fileName = file_name.split('_')[0] + '_';
      if(fileName.length>31){
        file_name = fileName.split('_')[0] +'.xlsx';
      }else{
         if(this.templateData.secondarykeys){
          file_name = fileName  + this.templateData.secondarykeys.OrganizationId.toString()+'.xlsx';
      }
      else{
        file_name = fileName.split('_')[0] +'.xlsx';
      }
    }
    }
    const view = flex.collectionView;
    let oldPgSize = view.pageSize,
      oldPgIndex = view.pageIndex;
    flex.beginUpdate();
    view.pageSize = 0;
    wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
      flex,
      {
        includeCellStyles: false,
        includeColumnHeaders: true
      },
      file_name,
      saved => {
        view.pageSize = oldPgSize;
        view.moveToPage(oldPgIndex);
        flex.endUpdate();
      },
      null
    );
  }

  initGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      tt.hideDelay = 9999999;
      tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }

  goEdit() {
    this.ngxService.start();
    this.ShowGrid = false;
    this.gridDisplay = false;
    this.ShowEditPage = true;
    this.OrgName = this.organizationname;
    if (this.statsData[0].ContactNumber == 'Not Specified') {
      this.statsData[0].ContactNumber = null
    }
    if (this.statsData[0].VDVersion == 'Not Specified') {
      this.statsData[0].VDVersion = ''
    }
    this.activityEntity.entityId = this.statsData[0].VD_Identifier;
    this.activityService.setActivitySession(this.activityEntity);
    this.recipientData.patchValue(
      {
        VD_Identifier: this.statsData[0].VD_Identifier,
        OrganizationGUID: this.OrganizationGUID,
        formInput_VDName: this.statsData[0].VDName,
        CustomerName: this.statsData[0].CustomerName,
        formInput_VDVersion: this.statsData[0].VDVersion,
        VitalAxis_URL: this.statsData[0].VitalAxis_URL,
        formInput_VitalAxisDataURL: this.statsData[0].VitalAxisDataURL,
        formInput_LoginPage: this.statsData[0].LoginPage,
        IsActive: this.statsData[0].IsActive == 'Yes' ? true : false,
        formInput_LatestVersion: this.statsData[0].LatestVersionAvailable,
        formInput_PointOfContact: this.statsData[0].pointofcontact,
        formInput_ContactNumber: this.statsData[0].ContactNumber,
        formInput_RequestedBy: this.statsData[0].RequestedBy,
        formInput_OnTimeTicket: this.statsData[0].OnTimeTicket,
        formInput_orgid: !this.statsData[0].OrganizationId ? sessionStorage.getItem('org_id') : this.statsData[0].OrganizationId,
        formInput_orgname: this.organizationname,
        SpecificConfig: this.statsData[0].SpecificConfig,
        Customer_Type: this.statsData[0].Customer_Type,
        formInput_ComputerLocation: this.statsData[0].ComputerLocation,
        ComputerName: this.statsData[0].ComputerName,
        SystemOS: this.statsData[0].SystemOS,
        SystemIP: this.statsData[0].SystemIP,
        formInput_AdminLoginName: this.statsData[0].AdminLoginName,
        formInput_InstallationStatus: this.statsData[0].InstallationStatus,
        formInput_InstallationTime: this.statsData[0].TimeConsumedForInstallation,
        formInput_InstalledBy: this.statsData[0].InstalledBy,
        formInput_LabelsUsed: this.statsData[0].LabelsUsedOnThisComputer,
        formInput_PrinterType: this.statsData[0].PrinterType,
        formInput_SenderAgentID: this.statsData[0].senderagentid,
        formInput_APIKey: this.statsData[0].apiKey,
        formInput_EnvKey: this.statsData[0].environmentKey,
        formInput_DeploymentKey: this.statsData[0].DeploymentKey,
        Comments: this.statsData[0].Comments
      }
    );
    this.updatedVDIdentifier = this.statsData[0].VD_Identifier;
    this.ngxService.stop();
    this.getAxisURL();
  }

  //****API Call to UPDATE VitalDesktop Details****//
  updateVDData(updatedVitalDesktopData: any) {
    if (this.isMandatoryCheck(updatedVitalDesktopData, 'update') == true) {
      this._snackbar.open("Mandatory Field Missing", "Alert!");
      return
    }
    else {
      let DeploymentKey = sessionStorage.getItem('DeploymentKey')
      let orgid = updatedVitalDesktopData.formInput_orgid;
      let Guid = !sessionStorage.getItem('OrganizationGUID') ? this.OrganizationGUID : sessionStorage.getItem('OrganizationGUID')
      let UpdatedVDJson = new VDInstallation(updatedVitalDesktopData, DeploymentKey, orgid, Guid, true)
        for(let [key, value] of Object.entries(UpdatedVDJson)){
          if(key?.toString().toLowerCase() == 'isfacility' || key?.toString().toLowerCase() == 'isactive'){
            UpdatedVDJson[key]= UpdatedVDJson[key] == false ? 0 : 1;
          }
          else if(value == "Not Specified" || value == "" || value == undefined || value == null){
            UpdatedVDJson[key] = null;
          }
      }
      sessionStorage.setItem('deploymentKey', "configdb");
      this.vaservices.updateVDInstallations(UpdatedVDJson).subscribe(result => {
        sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
        if (result.status.toLowerCase() == 'success') {
          this.commonService.setBooleanToTrue(true)
          this._snackbar.open("Updated successfully", "Success");
          this.statsData[0]['IsActive'] = this.getStatus(this.statsData[0]['IsActive']);
          UpdatedVDJson['IsActive'] = UpdatedVDJson['IsActive'] ? 'Active' : 'Inactive';
          this.commonService.auditDetails("VD_Identifier", "VDName", this.statsData, [UpdatedVDJson], 'Edit', this.templateData, this.auditableColumns)
          this.updateFlag = true;
          this.updatedVDIdentifier = updatedVitalDesktopData.VD_Identifier;
          this.recipientData.reset();
        }
      }, error => {
        sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
        this._snackbar.open("Failed to update", error);
        this.recipientData.reset();
      });
      this.ShowGrid = false;
      this.ShowEditPage = true;
      this.gridDisplay = false;
    }
  }

  //****Setup New VitalDesktop****//
  AddVDInstallation(addVitalDesktopData) {
    if (this.isMandatoryCheck(addVitalDesktopData, 'add') == true) {
      this._snackbar.open("Mandatory Field Missing", "Alert!");
      return
    }
    else {
      let orgid;
      if (sessionStorage.getItem('search_context').toString().toLowerCase() == 'lab' ||
        this.cardtype.toString().toLowerCase() == 'lab') {
        orgid = addVitalDesktopData.formInput_OrganizationID;
        for (let i = 0; i < this.LocationList.length; i++) {
          if (this.LocationList[i].organizationid == orgid) {
            addVitalDesktopData.formInput_OrganizationGUID = this.LocationList[i].OrganizationGUID
          }
        }
      }
      else {
        orgid = this.commonService.getOrgid();
        addVitalDesktopData.formInput_OrganizationGUID = sessionStorage.getItem('OrganizationGUID');
      }
      orgid = !orgid ? addVitalDesktopData.formInput_OrganizationID : orgid;
      this.DeploymentKey = sessionStorage.getItem('DeploymentKey')
      this.GetValuesForAdd(this.DeploymentKey);
      let AddVDJson = new VDInstallation(addVitalDesktopData, this.DeploymentKey, orgid, addVitalDesktopData.formInput_OrganizationGUID,false)
      sessionStorage.setItem('deploymentKey', "configdb");
      this.vaservices.addVDInstallation(AddVDJson).subscribe(data => {
        sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
        if (data.status.toLowerCase() === 'success') {
          let result = data.content
          this.updatedVDIdentifier = null;
          this.OrganizationGUID = null;
          this.commonService.setBooleanToTrue(true)
          this._snackbar.open("Saved successfully", "Success");
          result['IsActive'] = this.commonService.getStatus(result['IsActive']);
          AddVDJson['isactive'] = this.commonService.getStatus(AddVDJson['IsActive']);
          this.commonService.auditDetails("VitalDesktopID", "VDName", [result], [AddVDJson], 'Create', this.templateData, this.auditableColumns)
          this.updatedVDIdentifier = result["VitalDesktopID"];
          this.OrganizationGUID = result["OrganizationGUID"];
          this.createFlag = true;
          this.recipientData.reset();
          this.ShowEditPage = false;
          this.ShowAddNewPage = false;
          this.ShowGrid = false;
          this.NoDataCreateNewPage = false;
          this.gridDisplay = false;
          this.AddRecipientData.reset();
        }
      }, error => {

        sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
        this._snackbar.open("Failed to save data.", error);
        this.AddRecipientData.reset();
      });
    }
  }

  //****Get Group for update page****//
  GetOrgName(orgid: any) {
    this.OrgNameArray = []
    let queryVariable = { "Orgid": orgid };
    let query = this.SubMenuCardModel.GetQuery("getorganizationname");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        if (data.data.submenuData.length > 0) {
          this.OrgNameArray = data.data.submenuData;
          this.organizationname = this.OrgNameArray[0].organizationname + "(" + this.OrgNameArray[0].GUID + ")";
        }
        sessionStorage.setItem('deploymentKey', "configdb");

        var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "CategoryDropDown");
        let drpqueryparams: any = {
          "OperationName": null,
          "Query": drpquery[0].Query,
          "Variables": { "keyword": "Installations" }
        };
        this.vaservices.GetData(drpqueryparams).subscribe(result => {
          sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
          if (!result.errors) {
            // this.recipientData.patchValue(
            //   {
            //     SystemOS: ""
            //   });
            let tempDropdownList = JSON.parse(result.data.submenuData[0].Items_JSON);
            this.OS_UpdateDropdownItems = tempDropdownList.SystemOS
            // this.getAxisURL();
          }
        }, error => {

          console.info(error);
          sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
          // this.getAxisURL();
        });
      }
    }, error => {
      console.info(error);
    });
  }

  //****Get Dropdown values to Choose Group for Setup New VitalDesktop page****//
  getVitalDesktopLocDetails() {
    this.NoDataCreateNewPage = false;
    this.ShowGrid = false;
    this.gridDisplay = false;
    this.ShowAddNewPage = true;
    let accountid = (this.templateData.cardIdentifier).toString();
    (<FormArray>this.AddRecipientData.get('ChooseAccountName')).clear();
    sessionStorage.setItem('deploymentKey', "configdb");
    var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "CategoryDropDown");
    let drpqueryparams: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "keyword": "Installations" }
    };
    this.ngxService.start();
    this.vaservices.GetData(drpqueryparams, 'configdb').subscribe(result => {
      sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
      if (sessionStorage.getItem('search_context').toString().toLowerCase() == 'lab' ||
        this.cardtype.toString().toLowerCase() == 'lab') {
        this.hideChooseOrgOption = false;

        var drpquery = this.vaservices.mainQueryList.filter(x => x.Keyword == "associatedorgnames");
        let drpqueryRequest: any = {
          "OperationName": null,
          "Query": drpquery[0].Query,
          "Variables": { "accid": accountid }
        };
        this.vaservices.GetData(drpqueryRequest, this.vitalHttpServices.deploymentKey.toString()).subscribe(result => {
          this.ngxService.stop();
          if (!result.errors) {
            this.LocationList = [];
            this.LocationList = result.data.submenudata;
            let selectedOrgname = this.LocationList.find(e => e.OrganizationGUID.toLowerCase().toLowerCase() == sessionStorage.getItem('OrganizationGUID').toLowerCase().toLowerCase())
            let selectedOrgid = this.LocationList.find(e => e.organizationid == sessionStorage.getItem('org_id'))
            this.AddRecipientData.patchValue({
              formInput_SystemOS: "",
              formInput_LoginPage: "VALogin.aspx",
              formInput_VitalAxisURL: this.VitalAdminSearchConnectorComponent.dxurl,
              formInput_IsActive: true,
              formInput_CustomerType: "",
              formInput_VitalAxisDataURL: this.VitalAdminSearchConnectorComponent.dxurl,
              formInput_OrganizationID: selectedOrgid.organizationid //selectedOrgname.organizationid //this.LocationList[0].organizationid,
            });
            this.getAxisURL();
          }
        }, error => {
          this.ngxService.stop();
          this.getAxisURL();
          console.info(error);
        });
      }
      else {
        this.hideChooseOrgOption = true;
      }
      if (!result.errors) {
        this.ngxService.stop();
        let tempDropdownList = JSON.parse(result.data.submenuData[0].Items_JSON);
        this.OS_DropdownItems = tempDropdownList.SystemOS
        this.CustType_DropdownItems = tempDropdownList.CustomerType
        this.AddRecipientData.patchValue({
          formInput_SystemOS: this.OS_DropdownItems[0].item,
          formInput_CustomerType: this.CustType_DropdownItems[0].item
        });
        this.getAxisURL();
      }
    }, error => {
      this.ngxService.stop();
      this.getAxisURL();
      sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
      console.info(error);
    });
  }

  //**** VitalAxis Data URLs based on Deployment *****/
  GetValuesForAdd(DeploymentKeyValue) {
    let deployKey = DeploymentKeyValue.toLowerCase();
    let axisUrls = this.vaservices.GetDxurlBasedOnDeployment(deployKey);
    this.Add_VitalAxisURL = axisUrls;
    this.Add_VitalAxisDataURL = axisUrls;
  }

  onChangeLoc(LocationValue) {
    this.AddRecipientData.patchValue({
      formLocation: LocationValue
    });
  }

  ngOnDestroy(): void {
    this.getguidsSubscription?.unsubscribe();
  }

  getAxisURL() {
    var drpquery = this.SubMenuCardModel.GetQuery("distinctAxisDataURLs")
    let drpqueryparams: any = { depkey: sessionStorage.getItem("deploymentKey") };
    this.AxisDataURLs = [];
    this.AxisURLs = [];
    let queryResult = this.commonService.GetCardRequest(drpqueryparams, drpquery);
    this.vaservices.GetData(queryResult, 'configdb').subscribe(result => {
      let data = result.data.submenuData
      data.forEach(element => {
        if (element.Keyword == 'VitalAxisDataURL') {
          this.AxisDataURLs.push(element.Value.trim())
        }
        else {
          this.AxisURLs.push(element.Value.trim())
        }
        this.AxisDataURLs = this.AxisDataURLs.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        })

        this.AxisURLs = this.AxisURLs.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        })
      });
    })
  }

  filterAxisDataURLs(value, fieldName?) {
    if (!value) {
      return this.AxisDataURLs
    }
    else {
      let data = []
      if (fieldName) {
        data = this.AxisURLs.filter(x => x.toString().toLowerCase().includes(value.toString().toLowerCase()))
      }
      else {
        data = this.AxisDataURLs.filter(x => x.toString().toLowerCase().includes(value.toString().toLowerCase()))
      }
      return data.filter((el, i, a) => i === a.indexOf(el))
    }
  }
  isMandatoryCheck(addVitalDesktopData, button) {
    if (button == "add") {
      if (addVitalDesktopData.status == 'INVALID' ||
        // !addVitalDesktopData.formInput_OrganizationID ||
        !addVitalDesktopData.formInput_VDName ||
        !addVitalDesktopData.formInput_VDVersion ||
        !addVitalDesktopData.formInput_LoginPage) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      if (!addVitalDesktopData.formInput_VDName ||
        !addVitalDesktopData.formInput_LoginPage ||
        addVitalDesktopData.status == 'INVALID' ||
        !addVitalDesktopData.formInput_VDVersion
      ) {
        return true;
      }
      else {
        return false;
      }
    }
  }
}
class ColDef {
  constructor(public binding?: string, public header?: string) {
  }
}
