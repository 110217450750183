<!-- <mat-spinner class="labadmin-spinner" *ngIf="(sourceApp === 'VitalDx') && !(gridPage && templateData) && !copyDataClicked"
        style="margin: 20vh 0vw 0vh 40vw;" [diameter]="50" ></mat-spinner> -->

<div class="px-4" *ngIf="sourceApp === 'VitalDx' && (!gridPage || !templateData)">
    <app-shimmerui [line] = 8></app-shimmerui>
</div>

    <!-- <div class="modal-header admin-model-header mb-1 mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong>Addendum Notes</strong>
        </h3>
    </div> -->

    <!-- <wj-flex-grid *ngIf="showGrid" class="align-center" #grid [isReadOnly]="true" (initialized)="initGrid(grid)"
        [headersVisibility]="'Column'" [itemsSource]="RevNotesGridData">

        <wj-flex-grid-column [header]="'Actions'" align="center" [freeze]="true" [width]="120" [isReadOnly]="true">
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                <button [disabled]="editBtn || masterLevel" class="edit-delete-btn"
                    (click)="openEditScreen(grid,$event)"><em id="editButton" [title]="'Edit'"
                        class="edit-template p-0 fa fa-edit"></em></button>
                <button [disabled]="deleteBtn" class="edit-delete-btn">
                    <em id="deleteButton" [title]="'Delete'" class="fa fa-trash"
                        (click)="deleteReasonRow(grid,row)"></em>
                </button>
            </ng-template>
        </wj-flex-grid-column>
        <wj-flex-grid-column *ngFor="let columnname of columns" [binding]="columnname.binding"
            [visible]="!(columnname.header == 'OrganizationID') && !(columnname.header == 'Id')"
            [header]="columnname.header" [width]="'*'" [fixed]="true" [allowResizing]="true" [format]="'d'">
        </wj-flex-grid-column>

        <wj-flex-grid-filter #filter></wj-flex-grid-filter>
    </wj-flex-grid>
    <br />
    <div class="align-center mt-4" *ngIf="showGrid">
        <wj-collection-view-navigator *ngIf="showPaginationMainGrid" class="align-center mr-2"
            headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="RevNotesGridData">
        </wj-collection-view-navigator>
        <wj-menu [(value)]="RevNotesGridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
            <wj-menu-item [value]="0">No Paging</wj-menu-item>
            <wj-menu-item [value]="10">10</wj-menu-item>
            <wj-menu-item [value]="15">15</wj-menu-item>
            <wj-menu-item [value]="30">30</wj-menu-item>
            <wj-menu-item [value]="50">50</wj-menu-item>
        </wj-menu>
    </div> -->

    <app-templates-listing *ngIf="gridPage && templateData" [gridHeader]="listedGridHeader" [gridData]="RevNotesGridData" [context]="'Revision Notes'"
        [hideEdit]="editBtn" [hideCreate]="createBtn" [hideUpload]="uploadBtn" [hideExport]="exportBtn"
        [hideCopy]="copyBtn" (closingListTemplate)="handleListingScreen($event)"  [hideDelete]="deleteBtn"
        (editTemplateClicked)="newEditMethod($event)" (deleteTemplateClicked)="newDeleteMethod($event)"
        [templateData]="templateData" [subMenuCardModel]="SubMenuCardModel" [saveOrEditCompleted]="saveOrEditCompleted"
        [showHeaderModule]="true" (saveOrEditEventFromListingTriggered)="recieveObjFromListing($event)"
        (copyTemplateEventTriggered)="loadCopyScreen()" (uploadTemplateEventTriggered)="loadUploadScreen()"
        (emitFilters)="onSelectionCasetype($event)" [isAdmin3]="sourceApp =='VitalDx' ? false : true">
    </app-templates-listing>

    <!-- <div class="align-center mt-4" *ngIf="showGrid && !ViewScreen"> -->
        <!-- <button mat-raised-button class="admin-btn-success mr-4" (click)="exportReviseTemplatesMethodList(RevNotesGridData)"
            [disabled]="exportBtn">Export</button> -->

        <!-- <button mat-raised-button class="admin-btn-success mr-4" (click)="getInputData('create',[])"
            [disabled]="createBtn">
            Create
        </button>
        <button mat-raised-button class="admin-btn-success mr-4" (click)="loadUploadScreen()" [disabled]="uploadBtn">
            Upload
        </button>
        <button title="Copy From Other Entity" mat-raised-button class="admin-btn-success" (click)="loadCopyScreen()"
            [disabled]="copyBtn">
            Copy
        </button>
    </div> -->

    <!-- <div class="align-center  mt-4" *ngIf="!showGrid">
        <div class="nodata-design font-size">No Data Found</div>
    </div> -->
    <!-- <div class="align-center  mt-4" *ngIf="!showGrid">
        <button mat-raised-button class="admin-btn-success mr-4" (click)="getInputData('create',[])"
            [disabled]="createBtn">
            Create
        </button>
        <button mat-raised-button class="admin-btn-success mr-4" (click)="loadUploadScreen()" [disabled]="uploadBtn">
            Upload
        </button>
        <button title="Copy From Other Entity" mat-raised-button class="admin-btn-success" (click)="loadCopyScreen()"
            [disabled]="copyBtn">
            Copy
        </button>
    </div> -->


<!-- Add/Edit Collection Method -->
<!-- <div *ngIf="addEditScreen">
    <form [formGroup]="revisionReasonsForm">
        <div class="row col-sm-12 p-0 m-0">
            <div class="col-sm-12 modal-header admin-model-header mt-3">
                <h3 class="modal-title w-100 admin-model-header-txt main-title">
                    <strong *ngIf="action!='edit'">Create Addendum Notes</strong>
                    <strong *ngIf="action=='edit'">Edit Addendum Notes</strong>
                </h3>
            </div>
            <div class="text-wrap-revise row col-sm-12 p-0 ml-0">
                <div class="row col-sm-12 mt-2 mb-4">
                    <mat-form-field appearance="outline" class="w-100 col-sm-4">
                        <mat-label>
                            <strong>
                                Template Name</strong>
                        </mat-label>
                        <input type="text" maxlength="50" formControlName="frmname" autoComplete='off' matInput
                            aria-label="TemplateName">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-100 col-sm-8 pr-0">
                        <mat-label>
                            <strong>
                                Description</strong>
                        </mat-label>
                        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" formControlName="frmdesc"
                            cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" maxlength="2000"></textarea>
                    </mat-form-field>
                    <label class="case-type"><span class="text-danger">* </span><strong>Case type</strong></label>
                    <ng-multiselect-dropdown class="col-sm-12 text-xs pr-0" [placeholder]="'Select'"
                        formControlName="frmcasetype" [settings]="dropdownSettings" [data]="caselist"
                        (onSelect)="onCheckChange($event)" (onDeSelect)="onItemDeSelect($event)"
                        (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll()">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-sm-3">
                <button mat-raised-button type="button" class="admin-btn-success mr-4" (click)="refreshGrid()">
                    Back
                </button>
            </div>
            <div class="col-sm-9 button-wrap">
                <button mat-raised-button class="admin-btn-success mr-4"
                    (click)="saveReviseReasons('close',revisionReasonsForm.value)">
                    Save & Close
                </button>
                <button *ngIf="action == 'create'" mat-raised-button class="admin-btn-success"
                    (click)="saveReviseReasons('create',revisionReasonsForm.value)">
                    Save & Create
                </button>
            </div>
        </div>
    </form>
</div> -->

<!-- Bulk Upload -->
<div *ngIf="uploadClicked" class="revise-form">
    <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong class="header-View">Upload Addendum Notes</strong>
        </h3>
    </div>
    <div class="button-field-wrap m-1 p-0">
        <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
            <mat-label class="d-flex">
                Templates
            </mat-label>
            <!-- <em class="fa fa-chevron-down chevron-align"></em> -->
            <mat-select disableOptionCentering class="ml-2 temp-mat-align">
                <mat-option class="dropdown-opt-wrap" (click)="downloadTemplate(true)">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields
                </mat-option>
                <mat-option class="dropdown-opt-wrap" (click)="downloadBulkUpdate()">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>Bulk Update
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="button-field-wrap">
        <span class="instr-size mb-2">
            <img aria-hidden="true" alt="help" class="mb-1" src="/assets/icons/help.svg" width="15px" height="auto" />
            You can download the sample template file to upload and update Addendum Notes</span>
    </div>

    <div class="col-sm-12 p-0" *ngIf="gridWidth == 0">
        <div class="image-height" (fileDropped)="onFileChange($event)" appDnd>
            <input type="file" id="fileDropRef" #fileDropRef click="value = null" value=""
                (change)="onFileChange($event)" hidden />
            <span class="align-center img-wrap">
                <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
            </span>
            <span>Drag & Drop excel files here</span>
            <span class="align-center">Or</span>
            <label class="btn-sm btn rounded browse ml-5" for="fileDropRef">Browse File</label>
        </div>
        <button mat-raised-button type="button" class="admin-btn-success mt-4" (click)="refreshGrid()">
            Back
        </button>
    </div>

    <div *ngIf="gridWidth > 0">
        <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" (initialized)="initGrid(grid)" #grid
            [headersVisibility]="'Column'" (formatItem)="formatItem(grid, $event)" [frozenColumns]="2" [format]="'d'">


            <!-- Action -->


            <div *ngFor="let columnname of sheetHeader">
                <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                    [visible]="!(columnname == 'templatetype') && !(columnname == 'organizationid')  "
                    [allowResizing]="true" [width]="'*'" [allowDragging]="false" [allowSorting]="false">
                </wj-flex-grid-column>
            </div>
            <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="126"
                [isReadOnly]="true">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button class="edit-delete-btn" (click)="deleteRow(grid, row)"><em id="deleteButton"
                            [title]="'Delete'" class="p-0 fa fa-trash"></em></button>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>

        <div class="row pl-3">
            <div class="col-sm-2 p-0 mt-4">
                <button mat-raised-button type="button" class="admin-btn-success mr-4" *ngIf="gridWidth == 0"
                    (click)="refreshGrid()">
                    Back
                </button>
                <button mat-raised-button class="admin-btn-success" *ngIf="gridWidth > 0"
                    (click)="removeGrid()">Back</button>
            </div>
            <div class="col-sm-10 button-wrap p-0 mt-4">
                <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
                        (click)="refreshGrid()">Finish</button>
                    <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                        (click)="refreshGrid()">Cancel</button>
                    <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                        (click)="approveRevNotesmethod()">Approve</button>
                    <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                        (click)="ExportExcel(grid)">Download Result</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
        <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
    </div>
</div>
<!-- end region -->

<!-- Copy From Other Entity -->
<div *ngIf="copyDataClicked">
    <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong class="header-View">Copy Addendum Notes</strong>
        </h3>
    </div>
    <form [formGroup]="copyRevisionReasonsForm" class="copy-collection">
        <div class="row col-sm-12 p-0 ml-0 mt-4">
            <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
                <mat-label>Deployment</mat-label>
                <mat-select disableOptionCentering type="text" matInput #searchbar formControlName="frmDepKey">
                    <mat-option class="temp-font-size" *ngFor="let i of DeploymentKeys" value="{{i}}"
                        (onSelectionChange)="onChangeDeployment($event,i)">
                        <span>{{ i }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
                <mat-label>Group </mat-label>
                <!-- <em class="fa fa-chevron-down chevron-align"></em> -->
                <input type="text" matInput formControlName="frmOrganization" #searchbar
                    (click)="fetchOrgSeries(copyRevisionReasonsForm.value.frmOrganization)"
                    (keyup)="fetchOrgSeries(copyRevisionReasonsForm.value.frmOrganization);  trimFieldValue('frmOrganization')" [matAutocomplete]="orgauto"
                    placeholder="Search here ..." style="width: 95%;">
                <mat-autocomplete #orgauto="matAutocomplete">
                    <div *ngFor="let show of searchResult">
                        <mat-option class="mat-opt-align"
                            *ngIf="show.orgname.toLowerCase().includes(searchInput.toLowerCase());"
                            title="{{ show.orgname }} ({{show.orgid}})"
                            (onSelectionChange)="selectedCopyDataOrganization($event, show)" value="{{ show.orgname }}">
                            <span>{{ show.orgname }} ({{show.orgid}})</span>
                        </mat-option>
                    </div>
                </mat-autocomplete>
                <button matSuffix mat-icon-button (click)="fetchOrgSeries(copyRevisionReasonsForm.value.frmOrganization)">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
                <mat-label>Casetypes</mat-label>
                <!-- <em class="fa fa-chevron-down chevron-align"></em> -->
                <input type="text" matInput formControlName="frmcasetype" [(ngModel)]="searchCaseInput" #searchuserbar
                    (click)="fetchCaseSeries(searchCaseInput)" [matAutocomplete]="userauto"
                    (keyup)="fetchCaseSeries(searchCaseInput); trimCasetypeFieldValue('frmcasetype')"
                    placeholder="Search here ..." style="width: 95%;">
                <mat-autocomplete #userauto="matAutocomplete">
                    <div *ngFor="let cases of searchCaselist">
                        <mat-option class="mat-opt-align" value="{{cases}}" title="{{ cases }}">
                            <span>{{ cases }}</span>
                        </mat-option>
                    </div>
                </mat-autocomplete>
                <button matSuffix mat-icon-button (click)="fetchCaseSeries(searchCaseInput)">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
            </mat-form-field>



            <div class="col-sm-12 mt-4 align-center" *ngIf="gridWidth == 0">
                <div class="container create-btn col-sm-12" *ngIf="noDataFound">
                    <div class="nodata-wrap no-data-wraps mt-4">
                        <div class="nodata-design">No Data Found</div>
                    </div>
                    <button mat-raised-button class="admin-btn-success mt-4 mr-2" type="button"
                        (click)="removeGrid()">Back</button>
                </div>
                <button mat-raised-button class="admin-btn-success mt-4 mr-4" *ngIf="!noDataFound" type="button"
                    (click)="refreshGrid()">Back</button>
                <button mat-raised-button *ngIf="!noDataFound" class="admin-btn-success mt-4" type="button"
                    [disabled]="disableGetDataBtn()" (click)="getDataToCopy()">Get
                    Data</button>
            </div>

            <div *ngIf="gridWidth > 0" class="col-sm-12 mt-3">
                <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" (initialized)="initGrid(flexgrid,1)"
                    #flexgrid (formatItem)="formatItem(flexgrid, $event)" [frozenColumns]="!postUpload ? 1 : 2 ">
                    <div *ngFor="let columnname of copyColumns">
                        <wj-flex-grid-column [binding]="columnname.binding" [header]="columnname.header"
                            [visible]="!(columnname.header == 'Template Type') && !(columnname.header == 'OrganizationID') && !(columnname.header == 'Id')"
                            [allowResizing]="true" [width]="'*'" [allowDragging]="false" [allowSorting]="false">
                        </wj-flex-grid-column>
                    </div>
                    <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                </wj-flex-grid>

                <div class="col-sm-12 pl-0 mt-4">
                    <button mat-raised-button type="button" class="admin-btn-success mr-4"
                        *ngIf="(gridWidth > 0 || noDataFound)" (click)="removeGrid()">Back</button>
                    <span style="float: right;">
                        <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
                            (click)="refreshGrid()">Cancel</button>
                        <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
                            (click)="refreshGrid()">Finish</button>
                        <button mat-raised-button *ngIf="!postUpload" [disabled]="selectedData.length == 0"
                            class="admin-btn-success mr-4" (click)="approveRevNotesmethod(true)">Approve</button>
                        <button mat-raised-button *ngIf="postUpload" class="admin-btn-success"
                            (click)="ExportExcel(flexgrid)">Download Result</button>
                    </span>
                </div>
            </div>
        </div>
    </form>
</div>
