<div class="row container-fluid py-2 px-3">

  <div class="col-sm-2 pr-0">
    <div *ngIf="logo==''" class="col-sm-12 no_logo">
        <img src="/assets/icons/NoLogo.svg" alt="" width="100" height="70">
    </div>

    <div *ngIf="logo!=''" class="col-sm-12 brand_logo">
        <img [src]="logo" alt="" width="100%" height="100%">
        <div class="col-sm-12 action_btns align-center">
            <div class="col-sm-3 actions_icon" (click)="downloadLogo()"> 
              <img src="/assets/icons/user_icons/download.svg" alt="" width="100" height="20">
            </div>
        </div>
    </div>
</div>


  <div class="col-sm-10">
    <div class="row">
      <div class="col-sm-7 tool-tip">
        <span class="roleborder mx-2"></span>
        <span class="username" matTooltip="{{ locationDetails?.DisplayName | uppercase }}">
          {{ locationDetails?.DisplayName || 'N/A' | uppercase }}
        </span>
      </div>
      <div
        class="col-sm-5 d-flex justify-content-end slide button-area" >
      <span>Active: <app-toggle-switch class="ml-auto mr-3" style="margin-left: -7px!important;" type="secondary" [isDisable]="isStatusDisabled" [isChecked] = "isChecked" (clicked)="toggleChange($event)"></app-toggle-switch>
      </span>
      <!-- Add -->
      <button class="Vdm-btn mr-3 cursor" [hidden] = "isAddDisabled" (click)="redirectToCreateOrEditPage()" *ngIf="isHideLocationGrid"><span> <img src="/assets/images/LA_Add_Icon.svg" alt="fax" class="plus_icon"/></span> Add</button>
      <!-- Edit -->
      <button class="Vdm-btn mr-0 cursor" [hidden] = "isEditDisabled" (click)="EditLocation()"> <span> <i class="fa fa-pencil mr-1" aria-hidden="true"></i></span> Edit</button>

      </div>
    </div>
    <div class="ml-4">
      <span
        class="text-uppercase _600 tool-tip"
        style="font-size: 11px"
        matTooltip="{{ locationDetails?.Address | uppercase }}"
        >{{ locationDetails?.Address || '-'}}</span
      >
    </div>

    <div class="row pl-2">
      <div class="col-sm-8 d-flex">
        <div class="row d-block justify-content-between px-4 mt-2">
          <div class="icon_label ml-1" >
            <mat-icon class="vdm_icon" >phone</mat-icon>
            {{ locationDetails?.Phone || '-'}}
          </div>
          <div class="d-flex icon_label ml-1">
            <img src="/assets/images_vla/Fax.svg" alt="fax" class="vdm_icon mr-1">
            <strong class="icon-value ml-1">{{ locationDetails?.Fax || '-'}}</strong>
        </div>
        </div>
        <div class="username surgicenter" *ngIf="locationDetails?.IsSharedSurgicenter">SURGICENTER</div>
      </div>
    </div>
  </div>
</div>
