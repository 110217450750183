<div class="input-container" #maindivct tabindex="0" (focus)="inp.focus()">
    <div (click)="[showHideDropDown()]" class="input-elements" #customSelect (keydown)="keydown($event)">
    <input [class.display-border]="form.value" type="text"  #inp cdkOverlayOrigin #trigger="cdkOverlayOrigin" [value]="selectedItem" [class.cform-in-valid]="form.invalid && form.touched" [disabled]="form.disabled" readonly="true"  />
      <label>{{labelValue}}
        <ng-container *ngIf="required">
        <span class="text-danger fw-bold fs-6">*</span>
        </ng-container>
    </label>
    <img class="cursor-pointer" [class.flip-icon]="showDropDown" src="/assets/images_vla/Arrow_for_dropdwon.svg" alt="dropdown"/>
</div>
<ng-template  
      cdkConnectedOverlay
      [cdkConnectedOverlayWidth]="contentWidth?.width!"
      [cdkConnectedOverlayOrigin]="trigger"  
      [cdkConnectedOverlayHasBackdrop]="true"  
      [cdkConnectedOverlayBackdropClass]="['cdk-overlay-transparent-backdrop', 'backdrop-z-index']"
      [cdkConnectedOverlayOpen]="showDropDown" (overlayKeydown)="pressed($event)"
      (backdropClick)="[showDropDown=false]" (detach)="showDropDown=false" (positionChange)="focusOnElement()"  >
      <div class="overlay-popup custom-dropdown" aria-label="true"  >
        <div class="dropdown-container"  #cstmpopup >
          <div *ngIf="defaultOption" (click)="setToDefault()" [tabindex]="0" (keydown.enter)="setToDefault()"  >
            {{defaultOption}}
          </div>
          <div (keydown.enter)="addValue(drp)" [class.show-selected]="drp[dropDownValueProp] === form.value && form.value" [tabindex]="0" *ngFor="let drp of dropDownValues;index as i" (click)="addValue(drp)">
              {{drp[dropDownKeyProp]}}
          </div>
        </div>
      </div>
 </ng-template>
</div>