<div class="row" *ngIf="!isPathalogistEdit">
    <div class="col-md-12 m-0 p-0" *ngIf="!sourceApp">
        <div class="modal-header admin-model-header mt-2 listingModalHeader">
            <h3 class="modal-title w-100 pl-0 admin-model-header-txt">
                <strong class="header-View">Pathologist Privileges</strong>
            </h3>
        </div>
    </div>

    <div class="col-sm-12 d-flex p-0 justify-content-end">
        <!-- <div class="m-1 align-items-center search-size" style="height: 24px;">
            <ng-container>
                <mat-form-field appearance="outline" class="userAutoComplete">
                    <em class="fa fa-chevron-down chevron-align"></em>
                    <input trim type="text" [(ngModel)]="selectedPathologist.itemName" (keyup)="getAllDataAfterFilter($event)" maxlength="200" matInput
                        [aria-label]="'Pathologist Name'" [matAutocomplete]="auto" [ngModelOptions]="{standalone: true}"
                        [placeholder]="'Search Pathologist Name'" #autoComopleteText>
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option class="mat-opt-align pl-3"
                            *ngFor="let autoCompleteOption of filterAutomCompleteOptions(selectedPathologist.itemName, cloneObj, 'PathologistName')"
                            [value]="autoCompleteOption.PathologistName"
                            (onSelectionChange)="onAutoCompleteSelectionChange($event, field, autoCompleteOption)">
                            <div> {{ autoCompleteOption.PathologistName }}
                            </div>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div> -->

        <div class="m-1 align-items-center search-size">
            <span><app-search-box className="search-padding" placeHolder="Search" [searchText]="searchSubject.value.searchtext"
                    (enteredValue)="filterStatus({searchtext:$event}, true)"></app-search-box></span>
        </div>
    </div>
</div>

<!-- <mat-spinner class="labadmin-spinner"
    *ngIf="sourceApp =='VitalDx' && !isPathalogistEdit && !(FilteredPatholologistPrivilageList$|async)"
    style="margin: 20vh 0vw 0vh 40vw;" [diameter]="50"></mat-spinner> -->

    <section *ngIf="!isPathalogistEdit && sourceApp =='VitalDx'">
        <ng-container *ngIf="!(FilteredPatholologistPrivilageList$|async)">
            <app-shimmerui [line] = 8></app-shimmerui>
        </ng-container>
    </section>


<section *ngIf="!isPathalogistEdit">
    <ng-container *ngIf="FilteredPatholologistPrivilageList$|async as pathologists">
        <div class="mb-1 mt-2">
            <div class="header">
                <div class="pp-header m-1 ">
                    <div class="col">Pathologist Name</div>
                    <div class="col m-left">DX Template Accessibility</div>
                    <div class="col">Subject Matter Expert</div>
                    <div class="justify-self-end">
                        <span class="mr-2" *ngIf="!isExportDisabled">
                            <img src="/assets/icons/Icon_Excel-Download.svg"
                                [class.disabled]="isExportDisabled || !pathologists.data.length" class="cusor-pointer"
                                alt="Export" [matTooltipDisabled]="isExportDisabled || !pathologists.data.length"
                                matTooltip="Export"
                                (click)="isExportDisabled || !pathologists.data.length? null : exportexcel(pathologists.data)">
                        </span>
                    </div>
                </div>
            </div>
            <div class=" item mt-2" [class.pp-body]="pathologists.data.length" *ngIf="!pathologists.isNodataPresent">
                <div class="col-sm-12 nodata-header-wrap" *ngIf="!pathologists.data.length">
                    <span class="col-sm-12 nodata-wrapper">No Results </span>
                </div>
                <div class="each-item m-1  p-2 align-items-center" *ngFor="let item of pathologists.data let i = index">
                    <div class=" md-design">
                        <div class=" col ellipsis pathname-width" [matTooltip]="tooTipText" #PathologistName
                            (mouseover)="toolTipshow(PathologistName)">
                            <span> {{item.PathologistName}}</span>
                        </div>
                        <div class="md-icon" *ngIf="item.IsMedicalDirector"
                            [matTooltip]="'Medical Director: ' +item.Location" mat-tooltip-panel-right>
                            <span>MD</span>
                        </div>
                    </div>
                    <div class="col">
                        <span>{{item.isUserlevel }}</span>
                        <span *ngIf="item.AllowEditingGroupTemplates" class="ml-">
                            <img src="/assets/icons/Icon_Edit.svg" alt="Edit">
                        </span>
                    </div>
                    <div class="col ellipsis ml-2 " [matTooltip]="tooTipText" #CaseType
                        (mouseover)="toolTipshow(CaseType)">
                        {{item.EditPrivilegesForCaseTypes ?item.EditPrivilegesForCaseTypes :'-' }}
                    </div>
                    <div class="justify-self-center" >
                        <span *ngIf="!isEditDisabled">
                            <img src="/assets/icons/Edit-fill.svg" [class.disabled]="isEditDisabled"
                                class="cusor-pointer" alt="Edit" width="14px" [matTooltipDisabled]="isEditDisabled"
                                matTooltip="Edit" (click)="isEditDisabled ? null : openEditPathologist(item)">
                        </span>
                    </div>
                </div>
            </div>
            
            <div *ngIf="pathologists.isNodataPresent">
                <div class="col-sm-12 text-center mt-2">
                    <!-- <span class="w-50 text-center">
                        No Data Available
                    </span> -->
                    <div class="col-sm-12 nodata-header-wrap">
                        <span class="col-sm-12 nodata-wrapper"> No Data Available </span>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</section>

<section *ngIf="isPathalogistEdit">
    <div class="body">
        <!-- Edit  pathologist  -->
        <div class="" [formGroup]="formPathalogistPrivilage">
            <div class="edit-header">
                <div class="heading">
                    <b>Edit Pathologist Privileges</b>
                </div>
                <span class="ml-auto"></span>
                <span class="ml-auto cusor-pointer reset-btn" *ngIf=" isPathalogistDetailsChanged()"
                    (click)="onReset()">Reset</span>
                <app-button buttonclass="secondary" class=" btn-size" (click)="returnToList()">Return</app-button>
                <app-button buttonclass="primary" class="btn-size" (click)="savePathologistData()">Save</app-button>

            </div>
            <div class="header-container-rounded d-flex ">
                <div class="font-head-size">
                    <span class=" text-dark">Name:</span>
                    <span class="ml-1 text-blue">{{formPathalogistPrivilage.get('pathologistName').value}}</span>
                </div>
                <div class="muted-seperator"></div>
                <div class="font-head-size">
                    <span class="text-dark">Medical Director:</span>
                    <span class="ml-1">
                        <app-toggle-switch type="secondary" [isDisable]="isAllLocationDisabled()"
                            [isChecked]="formPathalogistPrivilage.get('isMedicalDirector').value"
                            (clicked)="formPathalogistPrivilage.get('isMedicalDirector').patchValue($event)"></app-toggle-switch>
                    </span>
                </div>
            </div>
            <div class="mt-2 "
                [ngClass]="!isAllLocationDisabled()&&formPathalogistPrivilage.get('isMedicalDirector').value?'container-rounded':'container-rounded-inactive'">
                <div class="d-flex text-dark align-items-center font-head-size pl-edit-c-h ">
                    <!-- <span
                        [ngClass]="!isAllLocationDisabled()&&formPathalogistPrivilage.get('isMedicalDirector').value?'text-blue':'inactive-text-color'">Locations
                    </span> -->
                    <div>
                        <mat-checkbox class="custom-matcheckbox" color="primary"
                            [disabled]="isAllLocationDisabled()||!formPathalogistPrivilage.get('isMedicalDirector').value"
                            (click)="$event.stopPropagation()" (change)="$event?toggleAllLocation() : null"
                            [checked]="locationSelectionModel.hasValue()&&isAllLocationSelected()">
                            <span
                                [ngClass]="!isAllLocationDisabled()&&formPathalogistPrivilage.get('isMedicalDirector').value?' font-head-size text-blue':' font-head-size inactive-font'">
                                Locations
                            </span>
                        </mat-checkbox>
                    </div>
                </div>
                <div class=" pl-edit-c-b py-2">
                    <span *ngFor="let i of locations" class="mr-2">
                        <mat-checkbox class="custom-matcheckbox" color="primary"
                            [disabled]="i.isOtherMedicalDirector||!formPathalogistPrivilage.get('isMedicalDirector').value"
                            (click)="$event.stopPropagation()"
                            (change)="$event?[locationSelectionModel.toggle(i.Accountid)]:null"
                            [checked]="locationSelectionModel.hasValue()&&locationSelectionModel.isSelected(i.Accountid)">
                            <sapn [ngClass]="['font-body-size',!i.isOtherMedicalDirector?'':'inactive-font ']"
                                [class.text-blue]="locationSelectionModel.hasValue()&&locationSelectionModel.isSelected(i.Accountid)">
                                {{i.AccountName}}
                            </sapn>
                        </mat-checkbox>
                    </span>
                </div>
            </div>
            <div class="container-rounded mt-2">
                <div class="font-head-size text-dark pl-edit-h  d-flex align-items-center">
                  <span>DX Template Accessibility</span>
                </div>
                <div class="d-flex font-body-size pl-edit-h  py-3">
                    <div>
                        <div class="mb-3 font-body-size">Template Level</div>
                        <mat-button-toggle-group class="toggle-gp-btn" formControlName="allowEditingUserTemplates">
                            <mat-button-toggle [value]="true" class="btn-gp-1">User</mat-button-toggle>
                            <mat-button-toggle [value]="false" class="btn-gp-2">Group</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <div class="mx-5" *ngIf="formPathalogistPrivilage.get('allowEditingUserTemplates').value == false">
                        <div class="mb-3 font-body-size">Permission</div>
                        <mat-button-toggle-group class="toggle-gp-btn" formControlName="allowEditingDiagnosisTemplates">
                            <mat-button-toggle [value]="false" class="btn-gp-1">View</mat-button-toggle>
                            <mat-button-toggle [value]="true" class="btn-gp-2">Edit</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <div
                        *ngIf="formPathalogistPrivilage.get('allowEditingDiagnosisTemplates').value == true&&formPathalogistPrivilage.get('allowEditingUserTemplates').value == false">
                        <div class="mb-4 font-body-size">Subject Matter Expert</div>
                        <app-toggle-switch type="secondary" [isChecked]="formPathalogistPrivilage.get('isSME').value"
                            (clicked)="formPathalogistPrivilage.get('isSME').patchValue($event)"
                            [isDisable]="isAllMaserCasedisabled()"></app-toggle-switch>
                    </div>
                </div>
            </div>
            <div class="container-rounded mt-2"
                *ngIf="formPathalogistPrivilage.get('isSME').value && formPathalogistPrivilage.get('allowEditingUserTemplates').value == false && formPathalogistPrivilage.get('allowEditingDiagnosisTemplates').value == true">
                <div class="d-flex text-dark align-items-center font-head-size pl-edit-c-h ">
                    <!-- <span>Case Type</span> -->
                    <div *ngIf="caseTypes.length">
                        <mat-checkbox class="custom-matcheckbox" color="primary"
                            [checked]="isAllCategorySelected()&&isAtleastOnCaseSelcted()!=0&&caseTypes.length>0"
                            (click)="$event.stopPropagation()" (change)="$event?toggleAllCategory():null"
                            [disabled]="isAllCategoryDisabled()">
                            <span
                                [ngClass]="!isAllCategoryDisabled()?'text-dark font-head-size':'inactive-font font-head-size' ">Case Type  <!--Select All-->
                            </span> </mat-checkbox>
                    </div>
                </div>
                <div class=" py-2 mt-3 pl-edit-h" *ngIf="masterCasetypes.length">
                    <div class="d-flex align-items-center  ">
                        <span class="d-flex">
                            <img src="../../../../assets/icons/info_FILL0_wght400_GRAD0_opsz24.svg" class="mr-1"
                                height="20px">
                            <label class="info-font-size">
                                Allows the pathologist to modify the group level diagnosis
                                templates of the selected case types for which the pathologist is a Subject Matter
                                Expert</label>
                        </span>
                        <app-search-box class="search-box-width ml-auto" placeHolder="Search Case Types"  
                            (enteredValue)="casetypeSearch($event)"></app-search-box>
                    </div>
                    <div class="casetype-container-scroll mt-3" *ngIf="caseTypes.length">
                        <div class="mpp-grid-cols-3 ">
                            <div [ngClass]="isAllCasesDisabled(i)?'casetype-width container-rounded-inactive':'casetype-width container-rounded'"
                                *ngFor="let i of caseTypes">
                                <div>
                                    <mat-checkbox class="custom-matcheckbox" color="primary"
                                        [checked]="i.selectionModel.hasValue()&&isAllSelectedCase(i)"
                                        (click)="$event.stopPropagation()" (change)="$event?toggleAllCase(i):null"
                                        [disabled]="isAllCasesDisabled(i)">
                                        <span
                                            [ngClass]="['font-head-size',!isAllCasesDisabled(i)?'text-dark ':'inactive-font ']"
                                            [class.text-blue]="i.selectionModel.hasValue()&&isAllSelectedCase(i)">
                                            {{i.Category}}</span>
                                    </mat-checkbox>
                                </div>
                                <div class="py-2">
                                    <div class="casetype-height">
                                        <div *ngFor="let c of i.Cases">
                                            <mat-checkbox class="custom-matcheckbox" color="primary"
                                                [checked]="i.selectionModel.isSelected(c.DisplayName)"
                                                (click)="$event.stopPropagation()"
                                                (change)="$event?i.selectionModel.toggle(c.DisplayName):null"
                                                [disabled]="c.isOtherSMECaseType"> 
                                                <span
                                                    [ngClass]="!c.isOtherSMECaseType?'text-dark font-body-size _500':'inactive-font font-body-size'">{{c.DisplayName}}</span>
                                            </mat-checkbox>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="mt-3" *ngIf="!caseTypes.length">
                        <div class="col-sm-12 text-center mt-2 mb-2">
                            <!-- <span class="w-50 text-center">
                                No Results
                            </span> -->
                            <div class="col-sm-12 nodata-header-wrap">
                                <span class="col-sm-12 nodata-wrapper"> No Results </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="px-2 py-2 mt-3 mb-2" *ngIf="!masterCasetypes.length">
                    <div class="col-sm-12 text-center mt-2">
                        <span class="w-50 text-center">
                            No Data Available
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
