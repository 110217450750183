import { Component, Input, OnInit } from '@angular/core';
import { LabadminService } from '../../labadmin.service';
import { ExtAttributeConstants } from '../share/utility/ex_attribute _constants';

@Component({
  selector: 'app-location-info-details',
  templateUrl: './location-info-details.component.html',
  styleUrls: ['./location-info-details.component.scss']
})
export class LocationInfoDetailsComponent implements OnInit {

  @Input() locationDetails:any;
  @Input() userRoles=[];
  @Input() isHideLocationGrid:boolean = false;
  isEnableTimeZoneConversion: boolean = false
  constructor(
    private _labadminservice:LabadminService,
  ) {
   
  }

  ngOnInit(): void {
    this.GetVAExtAttrValueForTimeZoneConversion()
  }

  checkUserDetails(){
    if(this.locationDetails && this.locationDetails != null && this.locationDetails != ''){
    return Object.keys(this.locationDetails)?.length;
    }
 
  }  

  getCapitalLetters(timeZone: string | undefined): string {
    if (!timeZone) return '-';
    const capitalLetters = timeZone.match(/[A-Z]/g);
    return capitalLetters ? capitalLetters.join('') : '-';
  }

  GetVAExtAttrValueForTimeZoneConversion() {
    this._labadminservice.GetVAExtAttrValue(this.locationDetails.OrganizationID,ExtAttributeConstants.EnableTimeZoneConversion,'2',this._labadminservice.deploymentKey
      )
      .subscribe({
        next: (res: any) => {
          this.isEnableTimeZoneConversion = res.toString() == 'true' ? true : false;
        },
        error: (err) => console.error(err),
      });
  }
  ngOnChanges(){

  }
}
