
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-labadmin',
  templateUrl: './confirm-labadmin.component.html',
  styleUrls: ['./confirm-labadmin.component.scss']
})
export class ConfirmLabadminComponent {
  [x: string]: any;
  isDirty: any;

  constructor(public dialogRef: MatDialogRef<ConfirmLabadminComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick() {
    this.isDirty = false;
    this.dialogRef.close('true');
    this.canDeactivate()
  }

  canDeactivate(): boolean {
    return true
  }

  ifExists(alert) {
    let messages = ["Are you sure you want to delete this record?", "Are you sure to De-Associate the selected location(s)?"]
    return messages.includes(alert);
  }
}


