import { Injectable } from '@angular/core';
import { convertLocalDateTime, menuUrl } from '../../Utility Functions';
import { CommonService } from 'src/app/core/services/commonservices';

@Injectable({
  providedIn: 'root'
})
export class UtilityServiceService {

  constructor(
    private commonService: CommonService
  ) { }

  activateInactivateUser(entityid: string, username: string, loggedInUserName: string, type: string, action: string, role: string, isprimary: boolean){
    let transactionid=this.commonService.generateGuid();
    let info = `"${username}" ${action}d User.`
    let value = {'Status':{"newValue": `${action}d User Role ${role}${isprimary? ' (Primary)' : ''}.`}, 'Action By': {"newValue": loggedInUserName}, 'Action Date': {"newValue": convertLocalDateTime(new Date().toString())}}
    this.commonService.createActivityObject(entityid, username, menuUrl(type), 'Create', {}, {}, transactionid, '', {}, value, info)
  }

  migrateUser(entityid: string, username: string, loggedInUserName: string, type: string, email: string){
    let transactionid=this.commonService.generateGuid();
    let info = `"${username}" Email Invitation.`
    let value = {'Status':{"newValue": `Email Invitation sent successfully to ${email}`}, 'Action By': {"newValue": loggedInUserName}, 'Action Date': {"newValue": convertLocalDateTime(new Date().toString())}}
    this.commonService.createActivityObject(entityid, username, menuUrl(type), 'Create', {}, {}, transactionid, '', {}, value, info)
  }

  sendResetPasswordLink(entityid: string, username: string, loggedInUserName: string, type: string){
    let transactionid=this.commonService.generateGuid();
    let info = `"${username}" Reset Password link.`
    let value = {'Status':{"newValue": 'Reset Password link sent.'}, 'Action By': {"newValue": loggedInUserName}, 'Action Date': {"newValue": convertLocalDateTime(new Date().toString())}}
    this.commonService.createActivityObject(entityid, username, menuUrl(type), 'Create', {}, {}, transactionid, '', {}, value, info)
  }

  LockUnlockUser(entityid: string, username: string, loggedInUserName: string, type: string, status: boolean){
    let transactionid=this.commonService.generateGuid();
    let action = status ? 'Locked' : 'Unlocked'
    let info = `"${username}" User Account is ${action}.`
    let value = {'Status':{"newValue": `User Account is ${action}.`}, 'Action By': {"newValue": loggedInUserName}, 'Action Date': {"newValue": convertLocalDateTime(new Date().toString())}}
    this.commonService.createActivityObject(entityid, username, menuUrl(type), 'Create', {}, {}, transactionid, '', {}, value, info)
  }

  generatePassword(entityid: string, username: string, loggedInUserName: string, type: string){
    let transactionid = this.commonService.generateGuid();
    let info = `"${username}" Password Generation.`
    let value = {'Status':{"newValue": `New Password was generated.`}, 'Action By': {"newValue": loggedInUserName}, 'Action Date': {"newValue": convertLocalDateTime(new Date().toString())}}
    this.commonService.createActivityObject(entityid, username, menuUrl(type), 'Create', {}, {}, transactionid, '', {}, value, info)
  }
}
