import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import * as wjCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjGrid from '@grapecity/wijmo.grid';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
// import 'src/assets/css/styles.css';
import * as XLSX from 'xlsx';
import { ConfirmComponent } from '../../../base/popup/confirm/confirm.component';
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';
import { VitalAdminTabService } from '../../tab.service';
import { empty, take } from 'rxjs';
import { MultisheetPopupComponent } from 'src/app/base/popup/multisheet-popup/multisheet-popup.component';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { LabadminService } from '../../../labadmin/labadmin.service';
import { Store } from '@ngrx/store';
import { LabadminSessionService } from '../../../labadmin/services/labadmin-session.service';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { Subscription } from 'rxjs';

declare var $: any;

export interface Task {
  color: ThemePalette;
}
@Component({
  selector: 'app-manage-rejection-comments',
  templateUrl: './manage-rejection-comments.component.html',
  styleUrls: ['./manage-rejection-comments.component.scss']
})
export class ManageRejectionCommentsComponent implements OnInit {

  SubMenuCardModel: SubMenuCardModel;
  @Input() templateData: any;
  columns;
  gridheader: string[];
  listedGridHeader: any = [];
  gridData: wjcCore.CollectionView<any>;
  gridShow: boolean;
  noDataFound: boolean;
  showPaginationMainGrid: boolean = false;
  destDeployment: string = '';
  postUpload: any;
  selector: Selector;
  selectedData: any = [];
  searchTestResult: any[];
  noData: boolean;
  copyDataClicked: boolean;
  uploadClicked: boolean;
  enableInsert: boolean;
  enableEdit: boolean;
  excelDataArray: any[];
  showInvalidColumns: boolean;
  bulkUpload: boolean;
  searchResult: any[];
  enableActive: boolean = true;
  selectedrowData: any;
  id: any;
  allFields: any = [{}];
  mandatoryFields = [{}];
  updateFields = [{}];
  organizationList: any;
  orgList: any = [];
  gridwidth: number;
  DeploymentKeys: string[] = [];
  showDelete: boolean;
  sheetHeader: any;
  copyFlag: any;
  workBook: any;
  invalidColumns: any;
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  sheetsToSelect: any;
  checkStatus: boolean = false;
  selectedCopyData: any = [];
  uploadBtn: boolean;
  createBtn: boolean;
  editBtn: boolean;
  exportBtn: boolean;
  copyBtn: boolean;
  removeBtn: boolean = false;
  task: Task = {
    color: 'primary',
  };
  gridHeaderList: any;
  listHeaderbackup: any;
  ViewScreen: boolean = false;
  saveOrEditCompleted: any;
  auditableColumns: any;
  userSessionDetails: any;
  sourceApp: string;
  dbName: string;
  activityEntity: any;
  storeSub: Subscription = new Subscription();
  expectedHeaders: any = [];	
  uploadedHeaders: any = [];		
  UpdateColumnMisMatch: boolean = false;		
  missingColumn: any = [];		
  bulkUpdateFile: boolean = false;		
  minFieldsFile : boolean = false;


  constructor(
    private ngxService: NgxUiLoaderService,
    private datashare: DataShareService,
    private _fb: FormBuilder,
    public tabService: VitalAdminTabService,
    public VitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar,
    private dialog: MatDialog,
    public commonService: CommonService,
    private templateHeaders: TemplateHeadersService,
    private labAdminSessionService: LabadminSessionService,
    private store : Store<{ breadcrum : [] }>,
    private labAdminService: LabadminService,
    private activityService: ActivityTrackerService
  ) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      VitalHttpServices,
      datashare
    );
    this.columns = [
      new ColDef('AttributeId', 'AttributeId'),
      new ColDef('AttributeName', 'Name'),
      new ColDef('AttributeDescription', 'Description'),
      new ColDef('Sequence', 'Sequence'),
      new ColDef('IsActive', 'Active'),
      new ColDef('ModifiedBy', 'ModifiedBy'),
      new ColDef('CreatedBy', 'CreatedBy'),
      new ColDef('ModifiedDate', 'ModifiedDate'),
      new ColDef('CreatedDate', 'CreatedDate'),
      new ColDef('OrganizationId', 'Organizationid')
    ];
  }
  async ngOnInit() {
    let checkLaunchUrl=window.location.href.split('/#/')[1]
   if(checkLaunchUrl.toString().toLowerCase()!="home"){
     let _this = this;
    await _this.labAdminSessionService.getLabAdminSession.pipe(take(1)).subscribe(async session => {
      this.storeSub = _this.store.select("breadcrum").pipe(take(1)).subscribe(async result => {
        let menuObj = result[result.length -1];
        this.storeSub.unsubscribe();
      if (session["userDetails"] && Object.keys(session["userDetails"]).length > 1) {
        _this.userSessionDetails = session["userDetails"]
        _this.sourceApp = 'VitalDx'
        await _this.commonService.getCommmonTemplateData(menuObj,_this.userSessionDetails);
        _this.templateData=_this.labAdminService.templateData;
        _this.dbName = _this.labAdminService.deploymentKey;
        // console.log(_this.dbName, _this.OrganizationId);
        sessionStorage.setItem('deploymentKey', _this.dbName);
        sessionStorage.setItem('DeploymentKey',_this.dbName);
      }
      _this.destDeployment = sessionStorage.getItem('DeploymentKey').toUpperCase();
      // _this.getAllRejectionComments();
      this.addGridData(this.templateData.submenuData);
      this.GetButtonAccess(this.labAdminService.subMenuPermissions)
      this.getAuditableDetails(this.templateData.menuURL)
      this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': 'Lab' },{'key':'Lab Management','value':this.templateData['menuURL']}] })
      this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
    })
    });
    }
    else {
      this.destDeployment = this.VitalHttpServices?.deploymentKey?.toString();
      this.getAllRejectionComments();
      this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': this.templateData['menuURL'] }] })
      this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
      this.getAuditableDetails(this.templateData.menuURL)
      this.GetButtonAccess(this.VitalHttpServices.SubmenuAction)
    }
   

  }
  // Audit
  // auditDetails(oldObj, newObjArray, info) {
  //   let msgActivityTracker = ''
  //   msgActivityTracker = (info == 'updated') ? 'Updated' : (info == 'saved') ? 'Added' : (info == 'copied') ? 'Copy from other entity' : 'Bulk Upload';

  //   let oldObject = {};
  //   if (Object.keys(oldObj).length > 0) {
  //     /*Update record*/
  //     this.ngxService.start();
  //     this.commonService.createActivityTracker(msgActivityTracker, oldObj.attributeid, "Rejection Templates", 'Audit', newObjArray[0] == undefined ? newObjArray : newObjArray[0], oldObj);
  //     this.ngxService.stop();
  //   }
  //   else {
  //     /*New records*/
  //     this.ngxService.start();
  //     let orgid = sessionStorage.getItem('org_id');
  //     if (!newObjArray.length) {
  //       this.commonService.createActivityTracker(msgActivityTracker, orgid, 'Rejection Templates', 'Audit', newObjArray, {});
  //     }
  //     else {
  //       for (let i = 0; i < newObjArray.length; i++) {
  //         this.commonService.createActivityTracker(msgActivityTracker, orgid, 'Rejection Template ', 'Audit', newObjArray[i], {});
  //       }
  //     }
  //   }
  //   this.ngxService.stop();

  // }
  //RBAC audit
  GetButtonAccess(CasemenuAction) {
    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = CasemenuAction.find(e => e.Htext == "Templates").SubMenu.find(va=> va.URL == this.labAdminService.templateData.menuURL)["ActionButton"]
    }
    else {
      seletedMenuPermissions = CasemenuAction.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    }
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.uploadBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Create":
          this.createBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "CopyToOrg":
          this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
          case "Copy":
          this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Delete":
          this.removeBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
      }
    }
  }

  addRejectionCommentsForm = this._fb.group({
    AttributeId: '',
    AttributeName: ['', Validators.required],
    AttributeDescription: ['', Validators.required],
    Sequence: 0,
    IsActive: true,
    frmDepKey: [sessionStorage.getItem('deploymentKey').toUpperCase(), Validators.required],
    frmOrganization: [''],
    frmOrgID: [''],
  });
  //allows only number
  AllowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  // Grid
  initGrid(grid, hitCount?) {
    if (hitCount) {
      if (!this.postUpload) {
        this.selector = new Selector(grid, {
          itemChecked: () => {
            this.selectedData = grid.rows.filter(r => r.isSelected);
          }
        });
      }
    }
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      const col = e.getColumn(),
        row = e.getRow();
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }

  // ExportListExcel(grid) {
  //   let excel = [];
  //   let rows = grid._pgView
  //   rows.find((e: any): any => {
  //     let primary = {}
  //     for (let [key, value] of Object.entries(e)) {
  //       primary[key] = value;
  //     }
  //     excel.push(primary);
  //   });

  //   let filename = 'RejectionTemplate_' + this.templateData.secondarykeys.OrganizationId + '.xlsx';
  //   var ws = XLSX.utils.json_to_sheet(excel);
  //   var wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, '' + 'Rejection Template');
  //   XLSX.writeFile(wb, filename);
  //   // view.pageSize = oldPgSize;
  //   // flex.endUpdate();

  // }

  ExportExcel(gridData) {
    let excel = [];
    let flex = gridData;
    const view: any = flex.collectionView;
    let oldPgSize = view.pageSize
    view.pageSize = 0;
    flex.beginUpdate();
    let rows = flex.rows;
    flex.rows.find((e: any): any => {
      let primary = {}
      delete e._data['Action'];
      // delete e._data['AttributeId'];
      delete e._data['casetype'];
      // delete e._data['organizationid'];
      delete e._data['tablename'];
      delete e._data['copyflag'];
      delete e._data['slno'];
      delete e._data['modifiedby'];
      for (let [key, value] of Object.entries(e._data)) {
        primary[key] = value;
      }
      excel.push(primary);
    });
    let filename = 'RejectionTemplate_' + this.templateData.secondarykeys.OrganizationId + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '' + 'Rejection Template');
    XLSX.writeFile(wb, filename);
    view.pageSize = oldPgSize;
    flex.endUpdate();
  }
  getAllRejectionComments() {
    let query = '';
    let queryVariable = {};
    query = this.SubMenuCardModel.GetQuery('getallrejectioncomments');
    queryVariable = {
      organizationid: this.sourceApp ? this.labAdminService.organizationId.toString() : sessionStorage.getItem('org_id')
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult,this.destDeployment).subscribe(
      data => {
        if (!data.errors) {
          this.templateData.submenuData = data.data.submenuData;
          this.templateData.submenuData && this.templateData.submenuData.length > 0 ? this.addGridData(this.templateData.submenuData) : this.noDataFound = true;
          // this.templateData.submenuData.sort((a, b) => a.AttributeName < b.AttributeName ? -1 : 1).sort((a, b) => a.Sequence < b.Sequence ? -1 : 1)
          // this.gridData.sort((a, b) => a.Sequence < b.Sequence ? -1 : a.Sequence > b.Sequence ? 1 : 0)
          if (!this.templateData?.submenuData?.length) this.gridData = new CollectionView(...[]);
          this.gridShow = true;
        }
        this.ngxService.stop();
      },
      error => {
        this.gridShow = false;
        console.error(error);
        this.ngxService.stop();
      }
    );
  }
  addGridData(templateData: any) {
    let primary = {};
    this.gridHeaderList = this.templateHeaders.templateList;
    for (let i = 0; i < this.gridHeaderList.length; i++) {
      if (this.gridHeaderList[i].templateName == 'Rejection template') {
        this.listedGridHeader = this.gridHeaderList[i].headers;
        this.listHeaderbackup = JSON.parse(JSON.stringify(this.listedGridHeader))
      }

    }
    let gridarray = [];
    this.gridheader = ['IsActive', 'Sequence', 'AttributeDescription', 'AttributeName', 'AttributeId', 'CreatedBy', 'ModifiedBy', 'OrganizationId', 'ModifiedDate', 'CreatedDate']
    for (let i = 0; i < templateData.length; i++) {
      primary = {};
      for (let [key, value] of Object.entries(templateData[i])) {
        if (key == 'AttributeName' || key == 'AttributeDescription') {
          if (value == '' || value == null) {
            value = 'Not Specified';
            primary[key] = value;
          }
          primary[key] = value;
        }
        for (let j = 0; j < this.gridheader.length; j++) {
          if (key === this.gridheader[j]) {
            primary[key] = value;
          }
        }
      }
      gridarray.push(primary);
    }
    // gridarray.sort((a, b) => a.Name < b.Name ? -1 : 1).sort((a, b) => a.Sequence < b.Sequence ? -1 : 1)
    // this.gridData = new CollectionView(gridarray, { pageSize: 10 });
    gridarray.sort((a, b) => a.Sequence.toString().localeCompare(b.Sequence.toString()));
    this.gridData = new CollectionView(gridarray);
    this.gridShow = true;
    this.noDataFound = false;
    this.showPaginationMainGrid = gridarray.length > 10 ? true : false;
    return gridarray;
  }

  //button for insert
  // insertClicked() {
  //   if (!this.createBtn) {
  //     this.noDataFound = false;
  //     this.gridShow = false;
  //     this.enableInsert = true;
  //     this.enableEdit = false;
  //     this.addRejectionCommentsForm.reset()
  //     this.resetFormErrors();
  //     this.addRejectionCommentsForm.patchValue({
  //       IsActive: true
  //     })
  //     // this.addRejectionCommentsForm = this._fb.group({
  //     //   AttributeName: ['', Validators.required],
  //     //   AttributeDescription: ['', Validators.required],
  //     //   Sequence: 0,
  //     //   IsActive: true,
  //     //   frmDepKey: [sessionStorage.getItem('deploymentKey').toUpperCase(), Validators.required],
  //     //   frmOrganization: [''],
  //     //   frmOrgID: [''],
  //     // });
  //   }
  // }


  //button for edit
  // editClicked(row) {
  //   if (!this.editBtn) {
  //     this.noDataFound = false;
  //     this.gridShow = false;
  //     this.enableInsert = false;
  //     this.enableEdit = true;
  //     this.addRejectionCommentsForm.reset()
  //     this.id = row.AttributeId
  //     this.addRejectionCommentsForm.patchValue({
  //       AttributeName: row.AttributeName,
  //       AttributeDescription: row.AttributeDescription,
  //       Sequence: row.Sequence,
  //       IsActive: row.IsActive == 'Yes' ? true : false
  //     })
  //   }
  // }
  //edit button
  // editSave() {
  //   this.editRejectionComments('close')
  // }

  // back button
  backSelect() {
    this.addRejectionCommentsForm.controls.frmOrganization.enable()
    // this.addRejectionCommentsForm.patchValue({
    //   AttributeName: '',
    //   AttributeDescription: '',
    //   Sequence: '',
    //   IsActive: true,
    //   frmDepKey: sessionStorage.getItem('deploymentKey').toUpperCase(),
    //   frmOrganization: '',
    //   frmOrgID: '',
    // });
    this.searchTestResult = [];
    this.noData = false;
    this.copyDataClicked = false;
    this.uploadClicked = false;
    this.enableInsert = false;
    this.enableEdit = false;
    this.excelDataArray = [];
    this.showInvalidColumns = false;
    this.postUpload = false;
    this.bulkUpload = false
    this.searchResult = [];
    this.gridShow = false;
    this.getAllRejectionComments();
    this.resetFormErrors();
    this.enableActive = true;
  }

  //form reset method
  resetFormErrors() {
    Object.keys(this.addRejectionCommentsForm.controls).forEach(key => {
      this.addRejectionCommentsForm.controls[key].setErrors(null)
    });
  }

  // save And Create button
  // saveAndCreate() {
  //   this.insertRejectionComments('create');
  //   // this.resetFormErrors();
  // }
  // //save And Close button
  // saveAndClose() {
  //   this.insertRejectionComments('close');
  // }
  // grid remove button
  gridRemove() {
    this.checkStatus = false;
    this.postUpload = false;
    this.showInvalidColumns = false;
    this.noData = false;
    this.noDataFound = false;
    this.enableActive = true;
    this.searchResult = []
    this.gridwidth = 0
    this.addRejectionCommentsForm.patchValue({
      frmOrganization: ''
    })
    this.gridData = new CollectionView([]);
    this.selectedCopyData = 0
    this.selectedData = []
    this.approveDisable();
    if(this.copyDataClicked){
    if(!this.addRejectionCommentsForm.controls['frmDepKey'].value){
      this.addRejectionCommentsForm.controls['frmOrganization'].disable();
    }
    else{
    this.addRejectionCommentsForm.controls.frmOrganization.enable()
    }
  }
  }
  // insertRejectionComments(data): any {
  //   if (!this.addRejectionCommentsForm.value.AttributeName || !this.addRejectionCommentsForm.value.AttributeDescription) {
  //     this._snackbar.open('Please enter all the required fields', 'Close')
  //     return;
  //   }
  //   if (this.addRejectionCommentsForm.value.Sequence < 0) {
  //     this._snackbar.open('Please provide valid sequence', 'Close')
  //     return;
  //   }
  //   var obj = {
  //     "attributename": this.addRejectionCommentsForm.value.AttributeName,
  //     "attributedescription": this.addRejectionCommentsForm.value.AttributeDescription,
  //     "isactive": this.addRejectionCommentsForm.value.IsActive == true ? 1 : 0,
  //     "organizationid": sessionStorage.getItem('org_id').toString(),
  //     "sequenceorder": this.addRejectionCommentsForm.value.Sequence,
  //     "createdby": sessionStorage.getItem("Userid") == null ? '-100' : sessionStorage.getItem("Userid"),
  //   }
  //   this.ngxService.start();
  //   this.VitalHttpServices.InsertRejectionComments([obj], this.destDeployment).subscribe(
  //     result => {
  //       if (!result.errors) {
  //         this.ngxService.stop();
  //         this.auditDetails([], obj, 'saved');
  //         if (result[0].NoteMessage.toString().match(/Duplicate values found/i)) {
  //           this._snackbar.open(result[0].NoteMessage, 'Close');
  //           return;
  //         }
  //         if (result[0].status.toString().match(/failure/i)) {
  //           this._snackbar.open(result[0].NoteMessage, 'Close');
  //           return;
  //         }
  //         this._snackbar.open(result[0].NoteMessage, 'Close');
  //         if (data == 'close') {
  //           this.enableInsert = false;
  //           this.gridShow = true;
  //           this.getAllRejectionComments();
  //           return
  //         }
  //         else {
  //           this.addRejectionCommentsForm.reset();
  //           this.addRejectionCommentsForm.patchValue({
  //             IsActive: true
  //           })
  //           this.enableInsert = true;
  //           this.resetFormErrors();
  //         }
  //       }
  //     },
  //     error => {
  //       this.ngxService.stop();
  //       console.error(error);
  //     }
  //   );
  //   // }
  // }
  // editRejectionComments(data) {
  //   if (!this.addRejectionCommentsForm.value.AttributeName || !this.addRejectionCommentsForm.value.AttributeDescription) {
  //     this._snackbar.open('Please provide mandatory fields', 'Close')
  //     return;
  //   }
  //   if (this.addRejectionCommentsForm.value.Sequence == 0) {
  //     this._snackbar.open('Provide valid sequence', 'Close')
  //     return;
  //   }
  //   var obj = {
  //     "attributename": this.addRejectionCommentsForm.value.AttributeName,
  //     "attributedescription": this.addRejectionCommentsForm.value.AttributeDescription,
  //     "isactive": this.addRejectionCommentsForm.value.IsActive == true ? 1 : 0,
  //     "organizationid": sessionStorage.getItem('org_id').toString(),
  //     "sequenceorder": this.addRejectionCommentsForm.value.Sequence == 0 ? null : this.addRejectionCommentsForm.value.Sequence,
  //     "modifiedby": sessionStorage.getItem("Userid") == null ? '-100' : sessionStorage.getItem("Userid"),
  //     "attributetype": "RejectionTemplate",
  //     "attributeid": this.id
  //   }
  //   this.ngxService.start();
  //   this.VitalHttpServices.updateRejectionComments([obj], this.destDeployment).subscribe(
  //     result => {
  //       if (!result.errors) {
  //         this.ngxService.stop();
  //         this.auditDetails([], obj, 'updated');
  //         if (result[0].NoteMessage.toString().match(/Duplicate/i)) {
  //           this._snackbar.open(result[0].NoteMessage, 'Close');
  //           return;
  //         }
  //         if (result[0].status.toString().match(/failure/i)) {
  //           this._snackbar.open(result[0].NoteMessage, 'Close');
  //           return;
  //         }
  //         this._snackbar.open(result[0].NoteMessage, 'Close');
  //         this.backSelect();
  //       }
  //     },
  //     error => {
  //       this._snackbar.open('Error updating rejection template', 'Close');
  //       this.ngxService.stop();
  //       console.error(error);
  //     }
  //   );
  // }

  //method for bulk update
  bulkEditRejectionComments(data) {
    var copyObj = data;
    for (let i = 0; i < copyObj.length; i++) {
      delete copyObj[i]["tablename"]
      delete copyObj[i]["notes"]
    }
    this.ngxService.start();
    this.VitalHttpServices.updateRejectionComments(copyObj, this.destDeployment).subscribe(
      result => {
        if (!result.errors) {
          this.commonService.auditDetails('', '', [], copyObj, 'Bulk Update', this.templateData, this.auditableColumns);
          // this.auditDetails([], copyObj, 'copied');
          this.ngxService.stop();
          for (let i = 0; i < result.length; i++) {
            this.excelDataArray[i]["notes"] = result[i]["NoteMessage"];
            if (result[i]["NoteMessage"].match(/ exists/)) {
              this.excelDataArray[i]["status"] = result[i]["status"];
            }
            else {
              this.excelDataArray[i]["status"] = result[i]["status"]
            }
          }
          this.postUpload = true;
        }
      },
      error => {
        this._snackbar.open('Error updating rejection template', 'Close');
        this.ngxService.stop();
        console.error(error);
      }
    );
  }
  //method to load copy screen
  loadCopyScreen() {
    if (!this.copyBtn) {
      this.enableInsert = false;
      this.enableEdit = false;
      this.noDataFound = false;
      this.copyDataClicked = true;
      this.gridShow = false;
      this.gridwidth = 0;
      this.DeploymentKeys = this.VitalHttpServices.DeploymentKeys;
      this.addRejectionCommentsForm.patchValue({
        frmDepKey: sessionStorage.getItem('deploymentKey').toUpperCase(),
      })
      if(!this.addRejectionCommentsForm.controls['frmDepKey'].value){
        this.addRejectionCommentsForm.controls['frmOrganization'].disable();
      }
      this.getListOrg();
    }
  }
  //Deployment details in the copy from Entity
  onChangeDeployment(e, depKey) {
    this.selectedData = []
    this.excelDataArray = []
    this.searchResult = []
    this.addRejectionCommentsForm.patchValue({
      frmOrganization: '',
      frmOrgID: '',
    })
    this.gridwidth = 0;
    if (e.source.selected) {
      this.addRejectionCommentsForm.patchValue({
        frmDepKey: depKey,
        frmOrganization: '',
        frmOrgID: ''
      });
      this.getListOrg();
      this.addRejectionCommentsForm.controls['frmOrganization'].enable();
    }
  }
  //get data in copy from Entity
  getListOrg() {
    let queryVariable = {};
    let query = this.SubMenuCardModel.GetQuery("adduserfilterorg");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, this.addRejectionCommentsForm.value.frmDepKey).subscribe(data => {
      if (!data.errors) {
        this.resetFormErrors();
        if (data.data.Organizations_list && data.data.Organizations_list.length > 0) {
          this.organizationList = data.data.Organizations_list;
        }
      }
    }, error => {
      console.log(error);
    });
    this.ngxService.stop();
  }
  //filter org details based on casetype
  filterOrgName() {
    let orgSuggestion = [];
    this.orgList.foreach(va => va.orgSuggestion.push(va.organizationname));
    orgSuggestion = [...new Set(orgSuggestion)].sort();
    return orgSuggestion;
  }
  //Org data suggestion based on number or alphabet
  fetchOrgSeries(value: string) {
    value = value?.toString().trim();
    this.searchResult = [];
    if (!value.trim()) {
      let copyOrgList=this.organizationList.slice()
      return (this.searchResult = copyOrgList.splice(0,25));
    }
    let idValidate = /^[0-9]*$/;
    if (!idValidate.test(value)) {
      if (value.length > 2) {
        this.searchResult = this.organizationList.filter(function (series) {
          if (series && series.organizationname != null) {
            return series.organizationname.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchResult = this.searchResult.splice(0, 25);
      }
    } else {
      this.searchResult = this.organizationList.filter(function (series) {
        if (series && series.organizationid != null) {
          return series.organizationid.toString().toLowerCase().includes(value.toLowerCase());
        }
      });
      this.searchResult = this.searchResult.splice(0, 25);
    }
    this.addRejectionCommentsForm.value.frmOrgID = this.organizationList.filter(function (series: any) {
      if (series && series.organizationname != null) {
        return series.organizationid.toString().toLowerCase().includes(value.toLowerCase());
      }
    });
    this.resetFormErrors()
  }
  //Upload screen
  loadUploadScreen() {
    if (!this.uploadBtn) {
      this.noDataFound = false;
      this.uploadClicked = true;
      this.copyDataClicked = false;
      this.enableInsert = false;
      this.gridShow = false;
      this.bulkUpload = true;
      this.gridwidth = 0
      let queryVariable = { tablename: 'OrganizationAttributes', identity: "false", nullable: "false" };
      let query = this.SubMenuCardModel.GetQuery("getTableColumns");
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      this.VitalHttpServices.GetData(queryResult).subscribe(data => {
        if (!data.errors) {
          if (data) {
            let allFields = {}
            allFields['AttributeName'] = ''
            allFields['AttributeDescription'] = ''
            for (let i = 0; i < data.data.allFields.length; i++) {
              allFields[data.data.allFields[i]['Column']] = ''
            }
            this.allFields[0] = allFields
            delete this.allFields[0]["AttributeType"];
            delete this.allFields[0]["SequenceOrder"];
            delete this.allFields[0]["AttributeContext"];
            delete this.allFields[0]["CreatedBy"];
            delete this.allFields[0]["CreatedDate"];
            delete this.allFields[0]["ModifiedBy"];
            delete this.allFields[0]["ModifiedDate"];
            delete this.allFields[0]["Organizationid"];
            delete this.allFields[0]["AttributeContext"];
            delete this.allFields[0]["OrganizationAttributeGUID"];
            delete this.allFields[0]["AttributeContextId"];
            delete this.allFields[0]["SourceGUID"];
            this.mandatoryFields[0]["AttributeName"] = ''
            this.mandatoryFields[0]["AttributeDescription"] = ''
            // this.mandatoryFields[0]["IsActive"] = ''
            this.updateFields[0]["AttributeId"] = ''
            this.updateFields[0]["AttributeName"] = ''
            this.updateFields[0]["AttributeDescription"] = ''
            this.updateFields[0]["IsActive"] = ''
          }
        }
      }, error => {
        console.error(error);
      });
    }
  }

  //Upload templates
  downloadTemplate(type) {
    let filename = ""
    let excel = [{}];
    if (type == 'allFieldsCheck') {
      filename = 'Rejection Template_' + 'All Fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
      excel = this.allFields
      var ws = XLSX.utils.json_to_sheet(excel);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "" + 'RejectionTemplate');
      ws = XLSX.utils.json_to_sheet(this.sampleDataAllFields)
      XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
      XLSX.writeFile(wb, filename);
    }
    else if (type == 'mandatoryFieldsCheck') {
      filename = 'Rejection Template_' + 'Minimal Fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
      excel = this.mandatoryFields
      var ws = XLSX.utils.json_to_sheet(excel);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "" + 'RejectionTemplate');
      ws = XLSX.utils.json_to_sheet(this.sampleDataMandatoryFields)
      XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
      XLSX.writeFile(wb, filename);
    }
    else if (type == 'updateCheck') {
      filename = 'Rejection Template_' + 'Bulk Update_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx'
      excel = this.updateFields
      var ws = XLSX.utils.json_to_sheet(excel);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "" + 'RejectionTemplate');
      ws = XLSX.utils.json_to_sheet(this.smapleDataUpdateFields)
      XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
      XLSX.writeFile(wb, filename);
    }
  }

  //sample data for excel
  sampleDataAllFields = [{
    AttributeName: 'Attribute Name or Adequate'
    , AttributeDescription: 'Attribute Description'
    , IsActive: '1 or 0'
    , AttributeValue: 'Attribute Value or number'
    , AttributeServiceType: 'Service Type or newid'
    , AttributeContextId2: 'Attribute Context Id 2 or number'
    , AttributeContextId3: 'Attribute Context Id 3 or number'
  }]
  sampleDataMandatoryFields = [{
    AttributeName: 'Attribute Name or Adequate',
    AttributeDescription: 'Attribute Description'
    // , IsActive: 'True'
  }]
  smapleDataUpdateFields = [{
    AttributeId: 'Attribute Id or number'
    , AttributeName: 'Attribute Name or Adequate'
    , AttributeDescription: 'Attribute Description'
    , IsActive: '1 or 0'
  }]
  //Upload file
  onFileChange(ev) {
    this.bulkUpdateFile = false		
    this.minFieldsFile = false		
    this.UpdateColumnMisMatch = false		
    this.missingColumn = []
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    if(filename.toLowerCase().includes('bulk update')){
      this.bulkUpdateFile = true
     }else if(filename.toLowerCase().includes('minimal fields')){
      this.minFieldsFile = true
    }
    let splitarry = filename.split('.');
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect.filter(va => va.toLowerCase() != 'sampledata');
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == 'organizationattributes')
            this.sheetsToSelect.length == 1 ? this.convertToJson(validSheet[0]) : this.openModal();

          }
          else {
            this.convertToJson(this.sheetsToSelect[0])
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }
  // validation in grid
  validateExcel(excelArr) {
    this.ngxService.start();
    let dataArray = [];
    dataArray = this.convertObjKeysToLower(excelArr);
    this.VitalHttpServices.validateRejectionComments(dataArray, this.destDeployment).subscribe(result => {
      if (!result.errors) {
        this.noData = false
        if (!this.copyDataClicked) {
          if (this.bulkUpdateFile && this.missingColumn.some(e=> e.toLowerCase() == 'attributeid')) {
            this._snackbar.open("Please provide valid data", 'Failed');
            this.ngxService.stop();
            return;
          }
        } 
        if (result.length > 0) {
          if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
            this.showInvalidColumns = true;
            this.invalidColumns = result[0].InvalidColumns;
          }
          dataArray.filter(e => {
            result.filter(r => {
              if (r.slno === e.slno) {
                e.notes = r.NoteMessage;
              }
            });
          });

          this.AddGridData(dataArray, 1);
          this.ngxService.stop();
        }
        else {
          this._snackbar.open("Please provide valid data", 'Failed');
          this.ngxService.stop();
        }
      }
      this.noData = true;
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", 'Failed');
      console.log(error);
    });
  }
  //Append validation notes in the grid
  convertToJson(sheetname, copyfrmorg: boolean = false, copyData: object[] = []) {
    var worksheet;
    !copyfrmorg ? this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    }) : null;
    this.excelDataArray = [];
    let tempExcelArr = [];
    tempExcelArr = !copyfrmorg ? XLSX.utils.sheet_to_json(worksheet, { defval: null }) : copyData;
    if (tempExcelArr.length == 0) {
      !copyfrmorg ? this._snackbar.open("The uploaded excel does not contain any data", "Close") : null;
      tempExcelArr = [];
      return;
    }
    if(Object.keys(tempExcelArr[0]).length < 2){
     this._snackbar.open("Please Provide Valid Data", "close")
     return;
    }

    this.uploadedHeaders = Object.keys(tempExcelArr[0]);
    this.expectedHeaders = this.bulkUpdateFile ? Object.keys(this.updateFields[0]) : (this.minFieldsFile ? Object.keys(this.mandatoryFields[0]) : Object.keys(this.allFields[0]));
     if(this.bulkUpdateFile){
        // Check if arrays are of equal length
        if (this.expectedHeaders.length !== this.uploadedHeaders.length || !(this.expectedHeaders.sort().join(',') === this.uploadedHeaders.sort().join(','))) {
          this.UpdateColumnMisMatch = true;
       }
       this.missingColumn = this.expectedHeaders.filter(header => !this.uploadedHeaders.includes(header))
    }else if(!this.bulkUpdateFile && !copyfrmorg){
      if (this.expectedHeaders.length !== this.uploadedHeaders.length || !(this.expectedHeaders.sort().join(',') === this.uploadedHeaders.sort().join(','))) {
        this._snackbar.open("Invalid excel", "Close") 
        return;
      }
    }
    let primary = {}
    let tempArray = []
    if (!this.copyFlag) {
      for (let i = 0; i < tempExcelArr.length; i++) {
        for (let [key, value] of Object.entries(tempExcelArr[i])) {
          if (!key.toString().match(/empty/i)) {
            if (key.toString().match(/sequence/i)) {
              if (value && value.toString() == '0') {
                value = 0;
              }
              else {
                value = value;
              }
            }
            else {
              if (value == null) {
                value = null;
              }
              else if (value === '') {
                value = ''
              }
              else if (value.toString().match(/null/i)) {
                value = 'null'
              }
              else if (copyfrmorg && key == 'attributeid') {
                value = null;
              }
            }
            primary[key] = value;
          }
        }
        tempArray.push(primary)
        primary = {}
      }
      tempExcelArr = tempArray;
    }
    this.sheetHeader = [];
    let temp = [];
    temp = !copyData ? XLSX.utils.sheet_to_json(worksheet, { header: 1 }) : Object.keys(tempExcelArr[0]);
    if (!copyData) {
      temp[0].reverse()
      temp[0].push("Notes")
      this.sheetHeader = temp[0];
    }
    else {
      temp.reverse()
      temp.push("Notes")
      this.sheetHeader = temp;
    }
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }

	  // Add the key and value to the primary object in tempArray
    if(this.UpdateColumnMisMatch){
      for(let i=0; i<tempExcelArr.length; i++){
       for(let j=0; j<this.missingColumn.length; j++){
         tempExcelArr[i][this.missingColumn[j]] = '';
       }
      }
    }
    this.excelDataArray = []

    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
    let i = 1;
    this.excelDataArray.find(d => {
      Object.assign(d, { slno: i, notes: '', tablename: 'OrganizationAttributes', organizationid: this.templateData.secondarykeys.OrganizationId.toString() });
      i++;
    });
    this.validateExcel(this.excelDataArray);
  }
  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        let value = ""
        value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
        newobj[key.replace(/\s/g, "").toLowerCase()] = value
      }
      array.push(newobj)
    }
    return array;
  }
  // Close Modal

  // Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    //let result=this.commonService.selectdSheet;
    if (result) {
      this.convertToJson(result)
    }
  }



  //add grid in upload and copy screen
  AddGridData(data, hitCount) {
    this.ngxService.start()
    for (let i = 0; i < this.excelDataArray.length; i++) {
      if (!this.excelDataArray[i]["status"] || this.excelDataArray[i]["status"] == '') {
        this.excelDataArray[i]["status"] = 'Ignored'
      }
    }
    this.noDataFound = false
    this.copyFlag = null;
    let gridArray = [];
    this.gridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(data[i])) {
            if (!key.toString().toLowerCase().match(/empty/i) && hitCount == 1) {
              if (key == 'sequence') {
                if (value == 0) {
                  if (primary['notes'] != 'Mandatory field is missing!' && primary['notes'] != 'Null values exist!') {
                    primary['notes'] = 'Zero is not allowed for Sequence';
                  }
                }
                else {
                  let pattern = /[0-9]+/
                  if (primary['notes'] != 'Mandatory field is missing!' && primary['notes'] != 'Null values exist!') {
                    primary['notes'] = value && !pattern.test(value.toString()) ? 'Sequence only takes numbers' : primary['notes'];
                  }
                }
              }
              if (value && value.toString().match(/null/i) && key != 'attributeid') {
                value = "NULL"
                primary['notes'] = 'Null values exist!'
              }

              if (key == 'attributename' && (!value || value.toString().toLowerCase().trim() == '') || key == 'attributedescription' && (!value || value.toString().toLowerCase().trim() == '')) {
                // value = ''
                // primary['notes'] = 'Mandatory field is missing!'
                if(this.UpdateColumnMisMatch && this.missingColumn.length > 0){
                    if(this.missingColumn.find(e=> e.toLowerCase() == key.toString().toLowerCase())){
                     value = '';
                    }else{
                      value = ''
                      primary['notes'] = 'Mandatory fields are missing!'
                    }
                } 
                else{
                  value = ''
                  primary['notes'] = 'Mandatory fields are missing!'
                }
              }
              if (key == 'attributeid' && (!value || (value && value.toString().toLowerCase().trim() == ''))) {
                value = ''
                primary['notes'] = 'Attibuteid  Missing'
              }
            }
            primary[key] = value;
          }
          gridArray.push(primary);
        }
        this.excelDataArray = gridArray;
        this.showDelete = true
        if (this.postUpload) {
          this.showDelete = false
          this.selector ? this.selector.column.grid.headersVisibility = HeadersVisibility.Column : null
        }
        if (!this.copyDataClicked) {
          this.selectedData = gridArray;
        }
        this.gridData = new CollectionView(gridArray);
        this.gridwidth = (170 * this.sheetHeader.length) + 37;
        if (this.gridwidth > 1300) {
          this.gridwidth = 1300;
        }
      }
      this.ngxService.stop()
    }
  }
  //get data for the second time in copy from Entity (PATCHING)
  selectedCopyDataOrganization(event, data) {
    this.excelDataArray = []
    this.selectedData = []
    this.searchResult = []
    if (event.source.selected && Object.keys(data).length > 0) {
      this.addRejectionCommentsForm.patchValue({
        frmOrgID: data.organizationid,
        frmOrganization: data.organizationname
      })
      this.getDataToCopy();
    }
  }
  //Copy data from one org to another
  getDataToCopy() {
    if (typeof (this.addRejectionCommentsForm.value.frmOrgID) == 'object') {
      let frmorgid = null
      frmorgid = this.addRejectionCommentsForm.value.frmOrgID
      this.addRejectionCommentsForm.value.frmOrgID = frmorgid[0].orgid
    }
    let queryVariable = { "organizationid": this.addRejectionCommentsForm.value.frmOrgID.toString() };
    let query = this.SubMenuCardModel.GetQuery("getallrejectioncommentscopy");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, this.addRejectionCommentsForm.value.frmDepKey).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        this.checkStatus = false;
        data.data.submenuData.forEach(va => {
          delete va.AttributeId
          delete va.CaseType
          if (this.copyDataClicked) {
            delete va.Sequence
          }
          if (va.IsActive.toLowerCase() == "yes") {
            va.IsActive = true
          }
          else if (va.IsActive.toLowerCase() == "no") {
            va.IsActive = false
          }
        })
        let copyData = data.data.submenuData;
        if (copyData.length == 0) {
          this.gridwidth = 0;
          this.noData = true;
          return
        }
        this.gridwidth = 1;
        this.copyDataProcess(copyData);
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    });
  }

  // validation while copying
  copyDataProcess(copyData: []) {
    let dataForValidation = []
    for (let i = 0; i < copyData.length; i++) {
      let primary = {}
      for (let [key, value] of Object.entries(copyData[i])) {
        primary[key] = value;
      }
      primary['notes'] = "";
      primary['slno'] = i + 1;
      primary['tablename'] = "organizationattributes";
      primary['organizationid'] = this.templateData.secondarykeys.OrganizationId.toString();
      dataForValidation.push(primary);
    }
    this.convertToJson("", true, dataForValidation);
  }

  //message validation color
  formateItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value.toString().includes('exists') || value.toString().includes('Mandatory') || value.toString().includes('null') || value.toString().includes('Attibuteid  Missing')) {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value.toString().includes('Valid') || value.toString().includes('Successfully') || value.toString().includes('success') || value.toString().includes('approval')) {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
        else if ((value.toString().includes('Ignored'))) {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
    }
  }

  //approve disable flag
  approveDisable() {
    let data;
    if (this.copyDataClicked && this.selectedData.length == 0) {
      return true
    }
    return data;
  }
  //method for remove the data

  //approve button
  approveRejectionComment(copyData?) {
    this.postUpload = false;
    this.copyFlag = copyData;
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      if (!copyData) {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          if (this.excelDataArray[i]["notes"] == "") {
            this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
            return;
          }
          else if (this.excelDataArray[i]["notes"].toString().match(/Attributeid does not exists/i) || this.excelDataArray[i]["notes"].toString().match(/Attibuteid  Missing/i)) {
            errorcount++
          }
          else if (!(this.excelDataArray[i]["notes"].toString().match(/approval/i) || (this.excelDataArray[i]["notes"].toString().match(/Valid/i)) ) || this.excelDataArray[i]["notes"].toString().match(/already exists/i) || this.excelDataArray[i]["notes"].toString().match(/Mandatory fields/i)) {
            errorcount++
          }
        }
        if (errorcount > 0) {
          this._snackbar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
          return
        }
      }
      if ((this.showInvalidColumns)) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          for (let [key, value] of Object.entries(this.excelDataArray[i])) {
            if (value === "null" || value === "" || value === null) {
            if (value == "null" || value == null) {
              this.excelDataArray[i][key] = null;
            }else if(value == "" ){
              this.excelDataArray[i][key] = '';
            }
            else {
              this.excelDataArray[i][key] = value.toString();
            }
              this.excelDataArray[i]["isactive"] = (this.copyDataClicked) ? (this.checkStatus ? 0 : 1) : this.excelDataArray[i]["isactive"]
            }
            else {
              if (!('isactive' in this.excelDataArray[i])) {
                this.excelDataArray[i][key] = value.toString();
              }
            }
          }
          if(this.bulkUpdateFile){
            this.excelDataArray[i]["modifiedby"] = !sessionStorage.getItem("Userid") ? -100 : sessionStorage.getItem("Userid")
          }else{
            this.excelDataArray[i]["createdby"] = !sessionStorage.getItem("Userid") ? -100 : sessionStorage.getItem("Userid")
          }
        }
      }
      let selectedData = []
      for (let i = 0; i < this.selectedData.length; i++) {
        if (this.copyDataClicked) {
          selectedData.push(this.selectedData[i]._data);
        }
        else {
          selectedData.push(this.selectedData[i]);
        }
      }
      if (selectedData.some(va => !this.UpdateColumnMisMatch && (!va.attributedescription || !va.attributename))) {
        this._snackbar.open('Some of the mandatory data is missing!', 'Close');
        return
      }
      if (copyData) {
        this.enableActive = false;
        this.selectedCopyData = selectedData;
        for (let i = 0; i < this.selectedCopyData.length; i++) {
          this.selectedCopyData[i]["copyflag"] = true;
          this.selectedCopyData[i]["isactive"] = (this.copyDataClicked) ? (this.checkStatus ? 0 : 1) : 1
          this.bulkUpload = false;
        }
        this.excelDataArray = this.selectedCopyData
      }
      let dataArray = [];
      dataArray = this.convertObjKeysToLower(this.excelDataArray);
      // copyData ? dataArray.some(va => va.notes.toString().toLowerCase().match(/already exists/i)) ? this._snackbar.open("Some selected " + this.templateData.menuURL.toString().toLowerCase() + "s already exist", "Close") : null : null
      let sendjson = dataArray
      let context = copyData ? null : ''
      if (!sendjson[0].attributeid || copyData && this.bulkUpload) {
        this.addUploaRejectionComment(dataArray, sendjson, this.bulkUpload, context)
      }
      else {
        this.bulkEditRejectionComments(sendjson)
      }
    }
  }
  //method for bulk upload
  addUploaRejectionComment(dataArray: object[], sendjson, bulkUpload: boolean, context?) {
    this.ngxService.start();
    this.VitalHttpServices.InsertRejectionComments(sendjson, this.destDeployment).subscribe(result => {
      this.noDataFound = false;
      this.ngxService.stop();
      if (!result.errors) {
        if (this.copyDataClicked) {
          this.commonService.auditDetails('', '', [], sendjson, 'Copy', this.templateData, this.auditableColumns);
        }
        else {
          this.commonService.auditDetails('', '', [], sendjson, 'Upload', this.templateData, this.auditableColumns);
        }

        // this.auditDetails([], sendjson, 'saved');
        this.noData = false;
        dataArray.filter((e: any) => {
          result.filter(r => {
            if (r.slno.toString() === e.slno.toString()) {
              e.status = r.status
              e.notes = r.NoteMessage;
            }
          });
        });
        dataArray.forEach((va: any) => {
          if (va.notes.toString().toLowerCase().match(/already exists/i)) {
            va.status = 'Ignored'
          }
        })
        if (result.length > 0) {
          this.postUpload = true;
          this.showDelete = false;
          if (this.copyDataClicked && this.postUpload) {
            this.addRejectionCommentsForm.controls['frmOrganization'].disable();
          }
          this.showDelete = false;
          let uploadresult = this.convertObjKeysToLower(dataArray);
          let dataExistsCount = 0;
          for (let i = 0; i < result.length; i++) {
            if (Object.values(result).every(function (item: any) {
              return item.status == "Ignored" ? true : false
            })) {
              dataExistsCount++;
            }
          }
          if (dataExistsCount == result.length) {
            if (bulkUpload) { this._snackbar.open(this.templateData.menuURL.toString().trim() + '(s) already exist!', 'Close') }
            else { this._snackbar.open(this.templateData.menuURL.toString().trim() + ' already exists!', 'Close'); }
            return;
          }
          else {
            this.AddGridData(uploadresult, 1);
          }
        }
        else {
          bulkUpload ? this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close') : this._snackbar.open(this.templateData.menuURL.toString().trim() + ' creation failed! Please try again.', 'Close');
        }
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open('Something went wrong.Please try again', 'Close');
      console.error(error)
    })
  }
  //delete row in the grid in upload
  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    this.selectedData = this.excelDataArray
    this._snackbar.open('Deleted successfully', 'Close');
    if (grid.rows.length == 0) {
      this.gridRemove();
    }
  }
  //flag for copy as inactive
  checkValue(event) {
    if (event.checked) {
      this.checkStatus = true;
    } else {
      this.checkStatus = false;
    }
  }

  handleListingScreen(val: any) {
    if (val == 'closeList') {
      this.ViewScreen = true
    } else if (val = 'openList') {
      this.ViewScreen = false
    }
  }


  #editStarting
  // newEditMethod(editObj: any) {
  //   editObj.rowData.IsActive == "Active" ? editObj.rowData.IsActive = 'Yes' : editObj.rowData.IsActive = '';
  //   this.editClicked(editObj.rowData);
  // }

  newDeleteMethod(deleteObj: any) {
    this.deleteRejectionComment(deleteObj.rowData)
  }

  recieveObjFromListing(object: any) {
    if (object.newData.Action === 'Edit') {
      delete object.newData.Action
      delete object.newData.CaseType
      delete object.newData.CreatedBy
      object.newData['attributeid'] = object.oldData['AttributeId'];
      const newObj = {};
      Object.entries(object.newData).forEach(([key, value]) => {
        newObj[key.toLowerCase()] = value;
      });
      const oldObj = {};
      Object.entries(object.oldData).forEach(([key, value]) => {
        oldObj[key.toLowerCase()] = value;
      });
      this.editRejectionCommentsTemplate(newObj, oldObj)
    }
    else {
      delete object.newData.Action
      delete object.newData.CaseType
      delete object.newData.ModifiedBy
      const newObj = {};
      Object.entries(object.newData).forEach(([key, value]) => {
        newObj[key.toLowerCase()] = value;
      });
      this.insertRejectionCommentsTemplate(newObj)
    }
  }

  insertRejectionCommentsTemplate(data): any {
    this.ngxService.start();
    this.VitalHttpServices.InsertRejectionComments([data], this.destDeployment).subscribe(
      result => {
        if (!result.errors) {
          this.ngxService.stop();
          // this.auditDetails([], data, 'saved');
          if (result[0].NoteMessage.toString().match(/Duplicate values found/i)) {
            this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
            this._snackbar.open(result[0].NoteMessage, 'Close');
            return;
          }
          if (result[0].status.toString().match(/failure/i)) {
            this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
            this._snackbar.open(result[0].NoteMessage, 'Close');
            return;
          }
          this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
          data.isactive = data.isactive == 1 ? 'Active' : 'Inactive'
          delete data.sequenceorder;
          this.commonService.auditDetails('Id', 'attributename', result, [data], 'Create', this.templateData, this.auditableColumns);
          this._snackbar.open(result[0].NoteMessage, 'Close');
          this.getAllRejectionComments();
        }
      },
      error => {
        this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
        this.ngxService.stop();
        console.error(error);
      }
    );
    // }
  }
  editRejectionCommentsTemplate(newObj, oldObj) {
    this.ngxService.start();
    this.VitalHttpServices.updateRejectionComments([newObj], this.destDeployment).subscribe(
      result => {
        if (!result.errors) {
          this.ngxService.stop();
          // this.auditDetails([], newObj, 'updated');
          if (result[0].NoteMessage.toString().match(/Duplicate/i)) {
            this._snackbar.open(result[0].NoteMessage, 'Close');
            return;
          }
          if (result[0].status.toString().match(/failure/i)) {
            this._snackbar.open(result[0].NoteMessage, 'Close');
            return;
          }
          this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
          newObj.isactive = newObj.isactive == 1 ? 'Active' : 'Inactive';
          oldObj['sequenceorder'] = oldObj['sequence'];
          delete oldObj['sequence'];
          this.commonService.auditDetails('attributeid', 'attributename', [oldObj], [newObj], 'Edit', this.templateData, this.auditableColumns);
          this._snackbar.open(result[0].NoteMessage, 'Close');
          this.backSelect();
        }
      },
      error => {
        this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
        this._snackbar.open('Error updating rejection template', 'Close');
        this.ngxService.stop();
        console.error(error);
      }
    );
  }

  deleteRejectionComment(value) {
    if (!this.removeBtn) {
      let data = {
        "attributeid": value.AttributeId,
        "attributename": value.AttributeName,
        "organizationid": this.sourceApp  ? this.labAdminService.organizationId.toString() :  sessionStorage.getItem('org_id').toString()
      }
      let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
        disableClose: true,
          autoFocus: false,
        width: '400px',
        panelClass: 'admin-custom-popup',
        // data: { header: "", message: "This action will permanently delete the selected row.", alert: "Do you want to continue?", continue: "yes", cancel: "no" }
        data: { header: "", message: "Are you sure you want to delete this record?", continue: "Delete", cancel: "Cancel" }
      })
      return dialogRef.afterClosed().toPromise().then(result => {
        if (result) {
          this.VitalHttpServices.DeleteRejectionComments(data, this.destDeployment).subscribe(
            () => {
              this.commonService.auditDetails('attributeid', 'attributename', [data], [], 'Delete', this.templateData, this.auditableColumns);
              this._snackbar.open('Rejection Template deleted successfully', 'Close');
              this.getAllRejectionComments();
            },
            error => {
              console.error('Error deleting item:', error);
            }
          );
        }
      })
    }
  }

  getAuditableDetails(location: any) {
    this.VitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }

  trimFieldValue(controlName: any){
    this.commonService.trimFieldValue(this.addRejectionCommentsForm, controlName ,this.addRejectionCommentsForm.value.frmOrganization)
  }
}

class ColDef {
  constructor(public binding?: string, public header?: string) {
  }
}
