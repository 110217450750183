import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import {
  catchError,
  concatMap,
  filter,
  finalize,
  groupBy,
  map,
  mergeMap,
  startWith,
  take,
  tap,
  toArray,
} from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
  forkJoin,
  of,
} from 'rxjs';
import { CommonService } from 'src/app/core/services/commonservices';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { DialogService } from 'src/app/core/services/dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { qualityAttributeAndFlags } from 'src/app/common/constants/qualityAttributeAndFlags';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import * as XLSX from 'xlsx';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjCore from '@grapecity/wijmo';
import * as wjGrid from '@grapecity/wijmo.grid';
import { WjFlexGrid } from '@grapecity/wijmo.angular2.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import { cloneDeep } from 'lodash';
import { ShowEvent } from 'ngx-ui-loader/lib/utils/interfaces';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { Store } from '@ngrx/store';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { sequence } from '@angular/animations';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { convertLocalDateTime, ConvertStatus } from 'src/app/labadmin/components/Utility Functions';
import { ExcelService } from 'src/app/core/services/excel.service';

@Component({
  selector: 'app-new-bulk-upload-tests',
  templateUrl: './new-bulk-upload-tests.component.html',
  styleUrls: ['./new-bulk-upload-tests.component.scss'],
})
export class NewBulkUploadTestsComponent
  implements OnInit, AfterViewInit {
  showLoader: boolean=false;
  constructor(
    private _vitalHttpService: VitalHttpServices,
    private commonService: CommonService,
    private _fb: FormBuilder,   
    private excelService: ExcelService,
    private dialog: DialogService,
    private _ngxService: NgxUiLoaderService,
    private datashare: DataShareService,
    private _snackbar: MatSnackBar,
    private labAdminSessionService: LabadminSessionService,
    private store: Store<{ breadcrum: [] }>,
    private labadminService: LabadminService,
    public activityService: ActivityTrackerService,
    private matdialog: MatDialog
  ) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      _vitalHttpService,
      datashare
    );
  }


  @Input() public templateData: any;
  @Input() public accountId: any = 0
  @Input() public caseType: string = "";
  uploadClicked: boolean = false;
  gridheader: any = [];
  copyClickedSequence: boolean = false;
  noDataFound: boolean = false;
  showPaginationMainGrid: boolean = false;
  noData: boolean;
  copyDataClicked: boolean;
  addEditScreen: boolean = false;
  destDeployment: string;
  searchResult: any = [];
  collapsedGroups = new SelectionModel<any>(true, []);
  workBook: any;
  sheetsToSelect: any;
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  sheetHeader: string[];
  excelDataArray = [];
  gridWidth: number = 0;
  postUpload: boolean = false;
  postDownload: boolean = false;
  checkStatus: boolean = false;
  showInvalidColumns: boolean = false;
  invalidColumns: string = '';
  showDelete: boolean = true;
  //selector: Selector;
  gridDisplay: boolean = false;
  gridData: any;
  selectedData: any[];
  AllFields = [{}];
  MandatoryFields = [{}];
  BulkUpdateFields = [{}];
  selector: Selector;
  gridArray = [];
  deploymentKey: any;
  orgId: any;
  stainData$: any;
  stainHeading: any;
  gridPage: Boolean = true;
  copyDataClickedStains: Boolean = false;
  uploadClickedStains: Boolean = false;
  SubMenuCardModel: SubMenuCardModel;
  qualityAttributeSelectionModel = new SelectionModel(false, []);
  searchText: string = '';
  filteredStainData$: any;
  filtercondition = new BehaviorSubject({
    status: 'all',
    searchText: this.searchText,
  });
  filtercondition$ = this.filtercondition.asObservable();
  loggedInUserId: string = sessionStorage.getItem('Userid') != '' ? sessionStorage.getItem('Userid') : '-100';
  isEdit: Boolean = false;
  accountCaseTypes: any;
  sendOutBillTo: any;
  sendOutLabId: any;
  SpawnStatus: any;
  SendOutFullfilledTestAction: any;
  panelNames = [];
  isOtherAttribute = false;
  isOthersInput = false;
  isNewPanelOrProcedure: Boolean = false;
  tempAttributeTypes: any[];
  AttributeTypes = [];
  attrtype: any = '';
  DataForExcel: any[];
  DeploymentKeys: any;
  srcDeployment: string;
  organizationList: any;
  copyFlag: any;
  SelectedSpawnCasetypeValue: string = null;
  filterSendOutServiceType$: Observable<any>;
  filtersendoutfullfilledtestaction$: Observable<any>;
  filterdefaultsendoutbillto$: Observable<any>;
  filterdefaultsendoutlab$: Observable<any>;
  filterspawncasetype$: Observable<any>;
  filterspawnstatus$: Observable<any>;
  filterSpawnTestonServiceType$: Observable<any>;
  scrollHistory: number = 0;
  isCollapse = false
  isLoading = true;
  groupLevelSort: string
  sequenceEditMode: boolean = false
  @ViewChildren('scrollableDiv') maindiv;
  previousGroupLevelSort: string;
  tracking: any = {}
  stainsDataForSort: { previousValue: any, currentValue: any }
  isSortDataChanged: boolean = false;
  isView: boolean = false
  viewPageData: any
  labList: Array<any> = [];
  caseTypeList: Array<any> = [];
  hitCount: number = 0;
  caseTypeDisplayName: string = "";
  isCompendiumCaseType: boolean = false
  // isFishGenericCaseType : boolean = false
  isFishTestCasetype: boolean = false
  finalizeAfterTC: boolean = false
  auditableColumns: any;
  oldObject: any;
  procedureId:any;
  cptCodes:any;
  sortedProcData = [{ pname: '', sequence: '', type: '' }];
  sortedGroupData = [{ pname: '', sequence: '', type: '' }];
  sendOutBillToDropdownvalue: any
  SendOutFullfilledTestActionDropdownvalue: any
  sendOutLabIdDropdownvalue: any
  accountCaseTypesDropdownValue: any
  globalData: any[];
  savedMolecularValue: any = [];
  status: boolean = false
  selectedsendOutLabId: any
  molecularId = 0;
  myControl = new FormControl();
  expectedHeaders: any = [];
  uploadedHeaders: any = [];
  UpdateColumnMisMatch: boolean = false;
  missingColumn: any = [];
  bulkUpdateFile: boolean = false;
  minFieldsFile : boolean = false;
  public workbookData: any;
  
  MoreFlags = []

  SendOutFlagList = ["DEFAULT_CHECKED", "DEFAULT_UNCHECKED", 'ALWAYS']
  SendOutServiceType = ["Global", "Technical Only"]
  spawnsendoutServiceType = ['ALWAYS', "Global", "Technical Only"]
  spawnStatus = ['Diagnosis', 'Diagnosis - Pending Tests', 'Finish Case - Pending Tests']

  formAdditionalTests = this._fb.group({
    accountid: 0,
    casetype: [''],
    panel: ['', [Validators.required, Validators.minLength(1), noWhitespaceValidator()]],
    procedurename: ['', [Validators.required, Validators.minLength(1), noWhitespaceValidator()]],
    cptcodes: ['', [Validators.required, Validators.minLength(1), noWhitespaceValidator()]],
    procedureid: 0,
    testid: null,
    isactive: true,
    sequence: null,
    tsequence: null,
    modifiedby: -100,
    createdby: -100,
    billingtype: null,
    defaultsendoutservicetype: null,
    sendoutfullfilledtestaction: null,
    defaultsendoutbillto: null,
    spawntestonservicetype: null,
    spawncasetype: null,
    spawncasetypemnemonic: null,
    sendoutflag: null,
    spawnstatus: null,
    ordercodeid: null,
    defaultsendoutlab: null,
    procedurecode: null,
    machinetestcode: null,
    pid: null,
    summarydisplayname: null,
    procedureabbreviation: null,
    defaultsplitintlab: null,
    hasallsitesummary: false,
    isquicktest: false,
    isownsummary: false,
    hascustomresults: false,
    iskitlotrequired: false,
    billableonce: false,
    isdatasetrequired: false,
    isscreeningtype: false,
    splitteststatuspending: false,
    globalsendout: false,
    StainLevelResult: false,
    oldtestid: 0,
    MolecularResult: [''],
  });

  get getters() {
    return this.formAdditionalTests.controls;
  }

  molecularResults = [
    'Indeterminate',
    'Negative',
    'Positive',
    'See Comments',
    'See Interpretation',
    'Indeterminate, see comment',
    'Insufficient, see comment',
    'Negative, see comment',
    'DETECTED',
    'NOT DETECTED',
  ];

  filteredOptions: Observable<string[]>;
  subjectfilterOpition = new BehaviorSubject(this.molecularResults);
  ngxLoderObservable$: any;
  isPanelDisable: boolean = false;
  isProcedureDisable: boolean = false;
  savedAdditionalStainsDetails: any;
  userSessionDetails: any;
  sourceApp: string = "";
  activityEntity: any;
  labAdminSubscription = new Subscription();
  storeSub = new Subscription();
  // fishCaseTypes : any
  billableOnceCaseTypes: any

  ngOnInit() {
    let checkLaunchUrl = window.location.href.split('/#/')[1]
    if (checkLaunchUrl.toString().toLocaleLowerCase() != "home") {
      this.sourceApp = 'VitalDx';
      this.getLabIDs(true);
    }
    else {
      this.destDeployment = sessionStorage.getItem('deploymentKey');
      this.deploymentKey = sessionStorage.getItem('deploymentKey').toUpperCase();
      this.orgId = this.templateData.GroupData.OrganizationId;
      this.caseType = this.templateData.cardtype
      this.caseTypeDisplayName = this.templateData.cardtype
      this.accountId = this.templateData.cardIdentifier;
      this.getStainData();
      this.getDropDownData();
      this.GetButtondetails();
      this.getCaseTypesDetails()
      this.stainHeading = this.templateData.menuURL;
      this.commonService.createContext(this.templateData.secondarykeys, '', this.templateData.menuURL);
      this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
      this.getAuditableDetails(this.templateData.menuURL);
    }
  }

  getLabIDs(initHit: boolean = false) {
    this.searchText = "";
    this.toggleButton({ status: 'all' })
    this.labAdminSessionService.getLabAdminSession.pipe(take(1)).subscribe( session => {
      this.labList = session?.["userDetails"]?.["userAccountIDs"].map((j) => ({...j, accountId : j.accountId.toString()}))?.sort((a,b) => a.accountName.localeCompare(b.accountName));
      //if (this.labList.length == 1) {
        this.accountId = this.labList[0]["accountId"];
        this.getCaseTypes(this.accountId, initHit);
      /*}
      else {
        if (initHit) this.getDataForDx();
      }*/
    });
  }

  async getCaseTypes(labId, initHit: boolean = false) {
    try {
      this.accountId=labId.toString();
      this.caseType = "";
      this.caseTypeDisplayName = "";
      const depKey = this.labadminService.deploymentKey.toString().trim();
      const query = this._vitalHttpService.GetQuery('getSchemeCasetypes');
      const queryVariable = { accid: labId?.toString() };

      const queryResult = this.commonService.GetCardRequest(queryVariable, query);

      const res = await this._vitalHttpService.GetData(queryResult, depKey).toPromise();

      if (!res.errors) {
        this.caseTypeList = res.data.submenuData;
        if (this.caseTypeList.length == 1 || initHit) {
          if(this.caseTypeList?.length){
            this.caseType = this.caseTypeList[0].casetype;
            this.caseTypeDisplayName = this.caseTypeList[0].displayname;
           //this.templateData.cardtype = this.caseType;
          
            if (initHit) {
              this.getDataForDx();
            }
            else{
              this.templateData.cardtype = this.caseType;
              this.getStainData();
            }
          }
          else this.getDataForDx();
        }
        // if (initHit)
        //   this.getDataForDx();
      }
      else console.error(res.errors);
    } catch (error) {
      console.error(error);
    }
  }

  getListData(list, key) {
    return list != null ? list.reduce((p, c) => (
      [...p, c[key]]
    ), []) : []
  }

  CreateOrEditPage() {
    this.isEdit = true;
    this.qualityAttributeSelectionModel.clear();
    this.SetValidators();
  }

  medicalProcedures: string[] = [
    "FISH_HemOnc",
    "Anal PAP",
    "Male Urine CT/NG",
    "GYN Molecular",
    "GYN PAP"
  ];

  $getFishTestCaseTypes(): Observable<any> {
    let queryVariable = {
      value: "true",
    }
    let query = this.GetQuery('GetFISHTestCasetypes');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    return this._vitalHttpService.GetData(queryResult, this.deploymentKey)
  }

  $getBillableOnceCaseTypes(): Observable<any> {
    let queryVariable = {
      value: "true",
    }
    let query = this.GetQuery('GetBillableOnceCasetypes');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    return this._vitalHttpService.GetData(queryResult, this.deploymentKey)
  }

  $getCasetypeDetails(): Observable<any> {
    let queryVariable = {
      orgid: `${this.orgId}`,
      accid: `${this.accountId}`,
      casetype: `${this.templateData.cardtype}`,
    }
    let query = this.GetQuery('casetypeDetails');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    return this._vitalHttpService.GetData(queryResult, this.deploymentKey)
  }

  getCaseTypesDetails() {
    forkJoin([
      this.$getCasetypeDetails(),
      this.$getBillableOnceCaseTypes(),
      this.$getFishTestCaseTypes(),
    ]).subscribe((responses: any) => {
      const [caseTypeDetails, billableOnceData, fishTestData] = responses;

      const submenuData = caseTypeDetails?.data?.submenuData[0] || {};
      const { IsCompendium, TemplateID, finalizeafterTc } = submenuData;

      this.isCompendiumCaseType = IsCompendium;

      const isFishGenericCaseType = TemplateID === "FISHGeneric";
      const fishCaseTypes = new Set(fishTestData.data.submenuData.map(item => item.CaseType));

      if (this.medicalProcedures.includes(this.templateData.cardtype)) {
        this.isCompendiumCaseType = true;
      }

      if (fishCaseTypes.has(this.templateData.cardtype)) {
        this.isFishTestCasetype = true;
      }

      if (isFishGenericCaseType || this.isFishTestCasetype) {
        this.MoreFlags.push(
          { label: 'Kit Lot Required', formControlName: 'iskitlotrequired', propertyName: 'IsKitLotRequired' },
          { label: 'Data Set Required', formControlName: 'isdatasetrequired', propertyName: 'IsDataSetRequired' },
          { label: 'Screening Type', formControlName: 'isscreeningtype', propertyName: 'Is_Screening_Type' }
        );
      }

      if (isFishGenericCaseType) {
        this.MoreFlags.push(
          { label: 'Own Diagnosis', formControlName: 'isquicktest', propertyName: 'ISQUICKTEST' },
          { label: 'Own Summary', formControlName: 'isownsummary', propertyName: 'IsOwnSummary' },
          { label: 'All Site Summary', formControlName: 'hasallsitesummary', propertyName: 'HasAllSiteSummary' }
        );
      }

      if (isFishGenericCaseType && finalizeafterTc) {
        const hiddenFlags = new Set(['isownsummary', 'hasallsitesummary', 'isscreeningtype', 'isdatasetrequired', 'iskitlotrequired', 'isquicktest']);
        this.MoreFlags = this.MoreFlags.filter(flag => !hiddenFlags.has(flag.formControlName));
      }

      if (IsCompendium || ['Clinical Chemistry', 'ClinicalChemistry_V2'].includes(this.templateData.cardtype)) {
        this.MoreFlags.push({ label: 'Custom Results', formControlName: 'hascustomresults', propertyName: 'Has_Custom_Results' });
      }

      const billableOnceCaseTypes = new Set(billableOnceData.data.submenuData.map(item => item.CaseType));
      if (billableOnceCaseTypes.has(this.templateData.cardtype)) {
        this.MoreFlags.push({ label: 'Billable Once', formControlName: 'billableonce', propertyName: 'BillableOnce' });
      }

      if (this.MoreFlags.length > 1)
        this.MoreFlags = this.MoreFlags.sort((a, b) => a.label.localeCompare(b.label));
    });

  }
  getStainData() {
    let keyword = this.templateData.Keyword;
    let caseType = this.caseType;
    this._ngxService.stop();
    this.stainData$ = this._vitalHttpService
      .GetData({
        OperationName: null,
        Query: this._vitalHttpService.GetQuery(keyword),
        Variables: {
          orgid: `${this.orgId}`,
          accid: `${this.accountId}`,
          casetype: `${caseType}`
        },
      }, this.deploymentKey)
      .pipe(
        take(1),
        map((i) => i.data.submenuData),
        map((i) => (i == null || i.length == 0 ? of(null) : i)),
        concatMap((e) => e),
        groupBy((i: any) => i.testid),
        mergeMap((group) => group.pipe(toArray())),
        map((i) => {
          let panel = i[0]?.Panel;
          let panelId = i[0]?.testid;
          let caseType = i[0]?.CaseType;
          let accountId = i[0]?.AccountId;
          let isAscending = this.checkOrder(i, 'Procedure_Name')
          let previousSortValue = isAscending
          let panelSequence = i[0]?.Sequence;
          return  {
            panel,
            panelId,
            caseType,
            accountId,
            isAscending,
            previousSortValue,
            panelSequence,
            procedures: i.filter(j=>j.Procedure_Id ?? 0 > 0),
          };
        }),
        toArray(),
        tap((res) => {
          this._ngxService.stop();
          this.groupLevelSort = this.checkOrder(res, 'panel')
          this.previousGroupLevelSort = this.groupLevelSort
        }),
        catchError((error) => {
          this._ngxService.stop()
          console.error('data not found', error);
          return of(null);
        })
      );
    this.filteredStainData$ = combineLatest([
      this.stainData$,
      this.filtercondition$,
    ]).pipe(
      map(([data, filter]: any) => {
        if (data && data.length) {      
          let res = [];

          this.panelNames = data.map((i) => i.panel);
          this.isOtherAttribute = !data.filter(
            (i) =>
              i.procedures.filter(
                (j) => j.Procedure_Name?.toLowerCase() == 'others'
              ).length
          ).length;

          res =
            filter.searchText != ''
              ? data.map((i) => ({
                ...i,
                procedures: i.procedures.filter((j) =>
                  this.stringNormalize(`${j.Procedure_Name} (${j.CPT_Code
                    }) ${j.Panel}`).includes(this.stringNormalize(filter.searchText.toLowerCase()))
                ),
              }))
              : data;
          res = res.length && filter.searchText != '' ? res.filter((i) => i.procedures?.length > 0) : res;
          const totalCount = res.reduce((p, c) => (
            p + c.procedures.length
          ), 0)
          const activeCount = res.reduce((p, c) => (
            p + c.procedures.filter((j) => j.Status.toLowerCase() == 'active').length
          ), 0)
          if (filter.status.toLowerCase() != 'all') {


            res = filter.status.toLowerCase() == 'active'
              ? res.map((i) => ({
                ...i,
                procedures: i.procedures.filter((j) => j.Status.toLowerCase() == 'active'),
              }))
              : res.map((i) => ({
                ...i,
                procedures: i.procedures.filter((j) => j.Status.toLowerCase() != 'active'),
              }))
          }
          res = res.length && filter.searchText != '' ? res.filter((i) => i.procedures?.length > 0) : res;
          this.DataForExcel = res;
          return { data: res, totalCount, activeCount, isNoDataFound: false }
        }
        return { data: [], totalCount: 0, activeCount: 0, isNoDataFound: true };
      }),
      tap((res) => this.stainsDataForSort = { currentValue: res.data, previousValue: res.data.map((i) => ({ ...i, procedures: i.procedures.map((j) => ({ ...j })) })) }),
      tap(() => this.ngAfterViewInit())
    );


  }

  stringNormalize(str: any) {
    return str != null ? str.toString().toLowerCase().replace(/[- )(]/g, "") : '';
  }

  drop(event: CdkDragDrop<any>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  toggleButton(updatedValue, searchValue = '') {
    this.filtercondition.next({
      ...this.filtercondition.value, ...updatedValue
    });
    this.searchText = updatedValue.searchText ? updatedValue.searchText : searchValue
  }

  getAccountCasetypes(accountid: any) {
    let queryVariable = { accid: accountid.toString() };
    let query = this.GetQuery('labcasetype');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    return this._vitalHttpService.GetData(queryResult, this.deploymentKey);
  }

  getDropDownValuesDefault() {
    let queryVariable = { orgid: '-1', attributeType: 'SendOutBillTo' };
    let query = this.GetQuery('getOrganizationAttributes');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    return this._vitalHttpService.GetData(queryResult, this.deploymentKey);
  }

  getSendoutFlagAndSpawnStatus() {
    let queryVariable = {};
    let query = this.GetQuery('getSendoutFlagAndSpawnStatus');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    return this._vitalHttpService.GetData(queryResult, this.deploymentKey);
  }

  getAssociatedSendout() {
    let queryVariable = { accid: this.accountId.toString() };
    let query = this.GetQuery('getAssociatedSendout');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    return this._vitalHttpService.GetData(queryResult, this.deploymentKey);
  }

  getDropDownData() {
    forkJoin([
      // this.getDropDownServiceType(),
      this.getAccountCasetypes(this.accountId),
      this.getDropDownValuesDefault(),
      this.getSendoutFlagAndSpawnStatus(),
      this.getAssociatedSendout(),
    ]).subscribe((data: any) => {
      // this.serviceType = data[0].data.submenuData;
      this.accountCaseTypes = data[0].data.submenuData.filter(ev => ev.Status == 'Active');
      this.sendOutBillTo = data[1].data.submenuData;
      this.SendOutFullfilledTestAction = data[2].data.submenuData.filter(
        (i: any) => i.Keyword === 'SendOutFullfilledTestAction'
      );
      this.sendOutLabId = data[3].data.submenuData;

      this.sendOutBillToDropdownvalue = this.sendOutBillTo.map((i) => i.AttributeName)
      this.SendOutFullfilledTestActionDropdownvalue = this.SendOutFullfilledTestAction.map((i) => i.Value)
      this.sendOutLabIdDropdownvalue = this.sendOutLabId.map((i) => i.AccountName)
      this.accountCaseTypesDropdownValue = this.accountCaseTypes.map((i) => i.Display_Name)
    });
  }

  print(e: any) {
    this.formAdditionalTests.controls.isactive.patchValue(e);
  }

  procedureid: number = 0;

  onSave() {
    this.formAdditionalTests.patchValue({
      accountid: this.templateData.cardIdentifier,
      casetype: this.templateData.secondarykeys.casetype,
      modifiedby: Number(this.loggedInUserId),
      createdby: Number(this.loggedInUserId),
    });
    if (this.isValidate()) {

      let MolecularResult = this.Licensure.value
        .filter((i) => i.resultName != '')
        ?.map((i) => i.resultName)
        .join(',');

      const dataArray = [{
        "accountid": this.accountId,
        "casetype": this.caseType,
        "panel": this.getters.panel.value,
        "procedurename": this.getters.procedurename.value,
        "cptcodes": this.getters.cptcodes.value,
        "procedureid": this.getters.procedureid.value,
        "testid": this.getters.testid.value,
        "isactive": this.getters.isactive.value,
        "sequence": this.getters.sequence.value,
        "tsequence": this.getters.tsequence.value,
        "modifiedby": this.getters.modifiedby.value,
        "createdby": this.getters.createdby.value,
        "billingtype": this.getters.billingtype.value,
        "defaultsendoutservicetype": this.getters.defaultsendoutservicetype.value ? this.getters.defaultsendoutservicetype.value : null,
        "sendoutfullfilledtestaction": this.getters.sendoutfullfilledtestaction.value ? this.getters.sendoutfullfilledtestaction.value : null,
        "defaultsendoutbillto": this.getters.defaultsendoutbillto.value ? this.getters.defaultsendoutbillto.value : null,
        "spawntestonservicetype": this.getters.spawntestonservicetype.value ? this.getters.spawntestonservicetype.value : null,
        "spawncasetype": this.SelectedSpawnCasetypeValue ? this.SelectedSpawnCasetypeValue : null,
        "spawncasetypemnemonic": this.getters.spawncasetypemnemonic.value ? this.getters.spawncasetypemnemonic.value : null,
        "sendoutflag": this.getters.sendoutflag.value ? this.getters.sendoutflag.value : null,
        "spawnstatus": this.getters.spawnstatus.value ? this.getters.spawnstatus.value : null,
        "ordercodeid": this.getters.ordercodeid.value ? this.getters.ordercodeid.value : null,
        "defaultsendoutlab": this.selectedsendOutLabId ? this.selectedsendOutLabId : null,
        "procedurecode": this.getters.procedurecode.value,
        "machinetestcode": this.getters.machinetestcode.value,
        "pid": this.getters.pid.value,
        "summarydisplayname": this.getters.summarydisplayname.value,
        "procedureabbreviation": this.getters.procedureabbreviation.value,
        "defaultsplitintlab": this.getters.defaultsplitintlab.value,
        "hasallsitesummary": this.getters.hasallsitesummary.value,
        "isowndiagnosis": this.getters.isquicktest.value,
        "isownsummary": this.getters.isownsummary.value,
        "hascustomresults": this.getters.hascustomresults.value,
        "iskitlotrequired": this.getters.iskitlotrequired.value,
        "billableonce": this.getters.billableonce.value,
        "isdatasetrequired": this.getters.isdatasetrequired.value,
        "isscreeningtype": this.getters.isscreeningtype.value,
        "splitteststatuspending": this.getters.splitteststatuspending.value,
        "globalsendout": this.getters.globalsendout.value,
        "StainLevelResult": this.getters.StainLevelResult.value,
        "oldtestid": this.getters.oldtestid.value,
        "MolecularResult": this.getters.MolecularResult.value
      }]

      this._ngxService.start()
      this.isLoading=true
      if (this.procedureId==0 && this.cptCodes==null) {
        this._vitalHttpService.bulkUploadTests(dataArray,this.deploymentKey).subscribe({
          next: (result) => {
            if(result?.length)
            {
              this.handleSuccess(result[0]?.NoteMessage,"Create",dataArray);
              if (result[0]?.NoteMessage.includes('already exists')) return;
              this.procedureid = result[0]?.procedureid1;
              MolecularResult && this.saveOrgAttributes();
              this.resetForm(), this.getStainData();
            }
            else{
              this._snackbar.open('Something went wrong.', 'Close');
            }
          },
          error: (err) => this.handleError(err),
          complete: () => {this._ngxService.stop();this.isLoading=false}
        }); 
      } else {
        if (this.isNewPanelOrProcedure) {
          this._vitalHttpService.bulkUploadTests(dataArray,this.deploymentKey).subscribe({
            next: (result) => {
              if(result?.length)
              {
                this.handleSuccess(result[0]?.NoteMessage,"Create",dataArray);
                if (result[0]?.NoteMessage.includes('already exists')) return;
                this.procedureid = result[0]?.procedureid1;
                MolecularResult && this.saveOrgAttributes();
                this.resetForm(), this.getStainData();
              }
              else
              {
                this._snackbar.open('Something went wrong.', 'Close');
              }
            },
            error: (err) => this.handleError(err),
            complete: () => {this._ngxService.stop();this.isLoading=false}
          });
        } else
          this._vitalHttpService.updateTests(dataArray,this.deploymentKey).subscribe({
            next: (result) => {
              if(result)
              {
                this.saveOrgAttributes();
                this.handleSuccess(result.Message,"Edit",dataArray);
                this.resetForm(), this.getStainData();          
              }
              else{
                this._snackbar.open('Something went wrong.', 'Close');
              }
            },
            error: (err) => this.handleError(err),
            complete: () => {this._ngxService.stop();this.isLoading=false}
          });
      }
   
    } else return;
  }

  private GetQuery(keyword: string) {
    let query: string = '';
    let mainQueryIndex: number = this._vitalHttpService.mainQueryList.findIndex(
      (mainQuery) => mainQuery.Keyword.toLowerCase() == keyword.toLowerCase()
    );
    if (mainQueryIndex > -1)
      query = this._vitalHttpService.mainQueryList[mainQueryIndex].Query;
    return query;
  }

  openAddProcedure(procedureName: string, panelObject: any) {
    this.stainHeading = this.sourceApp ? 'Create Test/Stain' : 'Create Additional Stains';
    this.isNewPanelOrProcedure = true;
    if (procedureName != 'Panel') {

      this.formAdditionalTests.patchValue({
        panel: panelObject.panel,
        testid: panelObject.panelId,
      });
      this.isPanelDisable = true;
    }

    if (procedureName == 'Others') {
      this.getters.procedurename.patchValue('Others');
      this.isProcedureDisable = true;
    }
    this.isOthersInput = procedureName === 'Others';

    ///Molecular
    this.Licensure.clear();
    this.addMolecularSection();

    this.CreateOrEditPage();
  }

  openEditProcedure(item: any) {
    this.savedAdditionalStainsDetails = item
    this.BindSaveData()
    this.stainHeading = this.sourceApp ? 'Edit Test/Stain' : 'Edit Additional Stains';
    this.procedureid = item.Procedure_Id;
    this.activityEntity.entityId = this.procedureid;
    this.activityService.setActivitySession(this.activityEntity);
    this.isPanelDisable = true;
    this.getOrgAttributeresultDropdown();
    this.CreateOrEditPage();
    //this.setSequenceValidation();
    this.cptCodes=item.CPT_Code;
    this.procedureId=item.Procedure_Id;
    // let testID = this.templateData.submenuData
    //   ? this.templateData.submenuData.filter((va) => va.Panel == item.frmpanel)
    //   : item.testid;
    this.formAdditionalTests.patchValue({
      panel: item.Panel,
      procedurename: item.Procedure_Name,
      cptcodes: item.CPT_Code,
      procedureid: item.Procedure_Id,
      testid: item.testid,
      isactive: item.Status === 'Active',
      sequence: item.Sequence,
      tsequence: item.Procedure_Sequence,
      billingtype: item.BillingType,
      defaultsendoutservicetype: item.Default_Send_Out_Service_Type,
      sendoutfullfilledtestaction: item.Send_Out_Fullfilled_Test_Action,
      defaultsendoutbillto: item.Default_Send_Out_Bill_To,
      spawntestonservicetype: item.SpawnTestOnServiceType,
      spawncasetype: item.SpawnCaseType,
      spawncasetypemnemonic: item.SpawnCaseTypeMnemonic,
      sendoutflag: item.Send_Out_Flag,
      spawnstatus: item.SpawnStatus,
      ordercodeid: item.Order_Code,
      defaultsendoutlab: item.Default_Send_Out_Lab,
      procedurecode: item.Procedure_Code,
      machinetestcode: item.Machine_Test_Code,
      pid: item.PId,
      summarydisplayname: item.SummaryDisplayName,
      procedureabbreviation: item.ProcedureAbbreviation,
      defaultsplitintlab: item.Default_SplitIntLab,
      hasallsitesummary: item.HasAllSiteSummary,
      isquicktest: item.IsOwnDiagnosis ? item.IsOwnDiagnosis : false,
      isownsummary: item.IsOwnSummary ? item.IsOwnSummary : false,
      hascustomresults: item.Has_Custom_Results ? item.Has_Custom_Results : false,
      iskitlotrequired: item.IsKitLotRequired ? item.IsKitLotRequired : false,
      billableonce: item.BillableOnce ? item.BillableOnce : false,
      isdatasetrequired: item.IsDataSetRequired ? item.IsDataSetRequired : false,
      isscreeningtype: item.Is_Screening_Type ? item.Is_Screening_Type : false,
      splitteststatuspending: item.SplitTestStatusPending ? item.SplitTestStatusPending : false,
      globalsendout: item.GlobalSendOut ? item.GlobalSendOut : false,
      StainLevelResult: item.StainLevelResult,
      oldtestid: item.testid,
    });
    if (item.SpawnCaseType) {
      this.SelectedSpawnCasetypeValue = this.accountCaseTypes.find(
        (i) => i.Display_Name === item.SpawnCaseType)?.Case_Type;
    }
    if (item.Default_Send_Out_Lab) {
      this.selectedsendOutLabId = this.sendOutLabId.find(
        (i) => i.AccountName === item.Default_Send_Out_Lab).AssociatedAccount
    }
    this.oldObject = [this.formAdditionalTests.value];
  }

  BindSaveData() {
    const setDefault = (property, defaultValue) => {
      if (this.savedAdditionalStainsDetails[property] == null) {
        this.savedAdditionalStainsDetails[property] = defaultValue;
      }
    };

    const booleanProperties = [
      'HasAllSiteSummary',
      'IsOwnSummary',
      'BillableOnce',
      'IsOwnDiagnosis',
      'Has_Custom_Results',
      'IsKitLotRequired',
      'IsDataSetRequired',
      'Is_Screening_Type',
      'SplitTestStatusPending',
      'GlobalSendOut',
      'StainLevelResult'
    ];

    booleanProperties.forEach(prop => setDefault(prop, false));

    const stringProperties = [
      'Default_Send_Out_Service_Type',
      'Default_Send_Out_Bill_To',
      'Send_Out_Fullfilled_Test_Action',
      'SpawnTestOnServiceType',
      'SpawnCaseTypeMnemonic',
      'Send_Out_Flag',
      'SpawnStatus',
      'Procedure_Code',
      'Machine_Test_Code'
    ];

    stringProperties.forEach(prop => setDefault(prop, ''));

    if (this.savedAdditionalStainsDetails.SpawnCaseType != null) {
      this.savedAdditionalStainsDetails.SpawnCaseType = this.accountCaseTypes.find((i) => i.Case_Type === this.savedAdditionalStainsDetails.SpawnCaseType)?.Display_Name || '';
    } else {
      this.savedAdditionalStainsDetails.SpawnCaseType = '';
    }

    if (this.savedAdditionalStainsDetails.Default_Send_Out_Lab != null) {
      this.savedAdditionalStainsDetails.Default_Send_Out_Lab = this.sendOutLabId.find((i) => i.AssociatedAccount === this.savedAdditionalStainsDetails.Default_Send_Out_Lab)?.AccountName || '';
    } else {
      this.savedAdditionalStainsDetails.Default_Send_Out_Lab = '';
    }
  }

  openPreview() {
    this._ngxService.start()
    this.dialog.openLargeDialog('Additional Stains', this.stainData$.pipe(
      map((j: any) => (j.map((i: any) => ({
        ...i,
        procedures: i.procedures.filter((j) => j.Status.toLowerCase() == 'active'),
      }))))
    ), 'stains')
    this._ngxService.stop()
  }

  private handleSuccess(Message: string, ActionType: string, data: any, data1?: any) {
    let sessionId = this.commonService.generateGuid();
    this.getStainData();

    if (ActionType === 'Edit') {

      data[0].isactive = data[0].isactive == true ? 'Active' : 'In Active';

      this.oldObject[0].isactive = this.oldObject[0]?.isactive == true ? 'Active' : 'In Active';
      this.oldObject[0].spawncasetype = this.oldObject[0]?.spawncasetype == '' ? null : this.oldObject[0]?.spawncasetype;
      this.oldObject[0].defaultsendoutlab = this.oldObject[0]?.defaultsendoutlab == '' ? null : this.oldObject[0]?.defaultsendoutlab;
      this.commonService.createActivityObject(data[0].procedureid, data[0].procedurename, this.templateData.menuURL, ActionType, data[0], this.oldObject[0], sessionId, this.auditableColumns);

    }
    if (ActionType === 'Create') {
      data[0].isactive = data.isactive == true ? 'Active' : 'In Active';
      this.commonService.createActivityObject(data[0].procedureid, data[0].procedurename, this.templateData.menuURL, ActionType, data[0], {}, sessionId, this.auditableColumns);
      //console.log(data[0].procedureid,data[0].panel, this.templateData.menuURL, ActionType, data[0], {}, sessionId,this.auditableColumns)
    }
    if (ActionType === 'Copy') {
      this.commonService.auditDetails('', '', [], data, 'Copy', this.templateData, this.auditableColumns);
    }
    this._ngxService.stop();
    this._snackbar.open(`${Message}.`, 'Close');
  }

  private handleError(err) {
    this._ngxService.stop();
    this._snackbar.open('Something went wrong. Please try again', 'Close');
    console.error(err)
    this.showLoader=false;

  }

  resetForm() {
    this._ngxService.start()
    const previousValue = this.filtercondition.value
    this.activityEntity.entityId = '';
    this.activityService.setActivitySession(this.activityEntity);
    this.savedAdditionalStainsDetails = ''
    this.selectedsendOutLabId = ''
    this.isEdit = false;
    this.isOthersInput = false
    this.isNewPanelOrProcedure = false;
    this.isProcedureDisable = false;
    this.isPanelDisable = false;
    this.stainHeading = this.templateData.menuURL;
    this.SelectedSpawnCasetypeValue = '';
    this.formAdditionalTests.reset({
      panel: '',
      procedurename: '',
      cptcodes: '',
      procedureid: null,
      testid: null,
      isactive: true,
      sequence: null,
      tsequence: null,
      modifiedby: -100,
      createdby: -100,
      billingtype: null,
      defaultsendoutservicetype: "",
      sendoutfullfilledtestaction: "",
      defaultsendoutbillto: "",
      spawntestonservicetype: "",
      spawncasetype: "",
      spawncasetypemnemonic: "",
      sendoutflag: "",
      spawnstatus: "",
      ordercodeid: null,
      defaultsendoutlab: "",
      procedurecode: "",
      machinetestcode: "",
      pid: null,
      summarydisplayname: null,
      procedureabbreviation: null,
      defaultsplitintlab: null,
      hasallsitesummary: false,
      isquicktest: false,
      isownsummary: false,
      hascustomresults: false,
      iskitlotrequired: false,
      billableonce: false,
      isdatasetrequired: false,
      isscreeningtype: false,
      splitteststatuspending: false,
      globalsendout: false,
      StainLevelResult: false,
    });
    this.RemoveValidators();
    this.toggleButton(previousValue)
    this._ngxService.stop()
  }

  // sortData(
  //   isAscending: boolean,
  //   panelId: string,
  //   accountid: string,
  //   caseType: string,
  //   from: 'Panel' | 'Procedure' = 'Panel'
  // ) {

  //    this.dialog.openLabadminConfimationdialog('', `The ${from}'s will be sorted in ${isAscending ? 'ascending' : 'descending'} order, it cannot be reverted. Do you want to proceed?`, 'Ok', 'Cancel') 
  //   // this.matdialog.open(ConfirmLabadminComponent, {
  //   //   disableClose: true,
  //   //   width: '360px',
  //   //   panelClass: 'admin-custom-popup',
  //   //   data: { header: "", message: `The ${from}'s will be sorted in ${isAscending ? 'ascending' : 'descending'} order, it cannot be reverted. Do you want to proceed?`, continue: "OK", cancel: "Cancel" }
  //   // })
  //   //return dialogRef
  //   .afterClosed().subscribe(result => {
  //     if (result) {
  //       let postData = {
  //         orderby: isAscending
  //           ? qualityAttributeAndFlags.Ascending
  //           : qualityAttributeAndFlags.Descending,
  //         organizationId: null,
  //         oper: qualityAttributeAndFlags.Stains,
  //         parentId: panelId,
  //         accountid: accountid,
  //         casetype: caseType,
  //       };
  //       this._ngxService.start();
  //       this._vitalHttpService.UpdateSequence(postData, this.deploymentKey).subscribe({
  //         next: (res) => {
  //           this.getStainData();
  //           this._snackbar.open('Procedures sorted successfully.', 'Close');
  //         },
  //         error: () => {
  //           this._snackbar.open(
  //             'Somenthing went wrong. Try after sometime.',
  //             'Close'
  //           );
  //           this._ngxService.stop();
  //         },
  //         complete: () => this._ngxService.stop(),
  //       });
  //     }
  //   });

  // }

  getOrgAttributeresultDropdown() {
    this.tempAttributeTypes = [];
    let obj = {
      organizationid: this.orgId,
      attributetype: ['ProcedureID'],
      tablename: 'OrganizationAttributes',
      AttributeContextId: this.procedureid,
    };
    this.getOrgAttibutes(obj);
  }

  getOrgAttibutes(obj: any) {
    this.globalData = [];
    this._ngxService.start();
    this._vitalHttpService
      .getOrganizationAttributes(obj, this.deploymentKey)
      .subscribe(
        async (result) => {
          this.globalData = JSON.parse(result.Message);
          this.Licensure.clear();
          this.globalData.filter(
            (i) => i.Attribute_ID == this.procedureid.toString()
          );
          let savedValue = [];
          savedValue = this.globalData
            .filter(
              (i) => i.Attribute_Context_Id == this.procedureid.toString()
            )
            ?.map((i) => i.Attribute_Name);
          this.savedMolecularValue = savedValue
          savedValue != null && savedValue.length
            ? savedValue.map((i: any) => {
              this.addMolecularSection(i);
            })
            : this.addMolecularSection('');
          this._ngxService.stop();
        },
        (error) => {
          this._ngxService.stop();
          console.error(error);
        }
      );
  }

  saveOrgAttributes() {
    let molecularSaveValue = this.Licensure.value.filter(
      (i: any) => i.resultName != ''
    );
    let OrganizationAttributeData = [];
    for (let i = 0; i < molecularSaveValue.length; i++) {
      OrganizationAttributeData.push({
        createdby:
          sessionStorage.getItem('Userid') == ''
            ? -100
            : sessionStorage.getItem('Userid'),
        AttributeType: 'ProcedureID',
        AttributeName: molecularSaveValue[i].resultName,
        AttributeContext: this.templateData.cardtype,
        organizationid: sessionStorage.getItem('org_id'),
        IsActive: true,
        SequenceOrder: null,
        SLNO: i + 1,
        Attributecontextid: this.procedureid,
        AttributeDescription: null,
        attributeValue: null,
        AttributeServiceType: null,
        attributecontextid2: null,
        attributecontextid3: null,
      });
    }
    let dataJson = {
      OrganizationID: this.orgId,
      ProcedureId: this.procedureid.toString(),
      OrganizationAttributeData: OrganizationAttributeData,
    };
    this.getters.StainLevelResult.value && this.addOrgAttributes(dataJson);
  }

  //#region
  addOrgAttributes(dataArray: any) {
    this._vitalHttpService.saveOrganizationAttribute(dataArray, this.deploymentKey).subscribe(
      (result) => {
        if (!result.errors) {
          if (result.length > 0) {
            let dataExistsCount = 0;
            for (let i = 0; i < result.length; i++) {
              if (
                Object.values(result).every(function (item: any) {
                  return item.Status == 'Ignored' ? true : false;
                })
              ) {
                dataExistsCount++;
              }
            }
          }
        }
      },
      (error) => {
        this._ngxService.stop();
        this._snackbar.open('Something went wrong.Please try again', 'Close');
        console.error(error);
      }
    );
  }
  //#endregion
  onChangeinput(text) {
    this.subjectfilterOpition.next(this._filter(text));
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    let selectedValue = this.Licensure.value
      .map((i) => i.resultName)
      .filter((i) => i != '');
    let normaValues = this.molecularResults.filter(
      (option) =>
        option.toLowerCase().includes(filterValue) &&
        !selectedValue.includes(filterValue)
    );
    return normaValues.filter((i) => !selectedValue.includes(i));
  }

  molecularFormGroup = this._fb.group({
    formArray: this._fb.array([]),
  });

  get Licensure() {
    return this.molecularFormGroup.get('formArray') as FormArray;
  }

  buildMolecularfrmGP(resultName): FormGroup {
    this.molecularId = this.molecularId + 1;
    return this._fb.group({
      resultName: [resultName],
      mandate: [''],
      molecularId: [this.molecularId],
    });
  }

  addMolecularSection(resultName = '') {
    this.Licensure.push(this.buildMolecularfrmGP(resultName));
    this.Licensure.controls.forEach((control: any) => {
      control.valueChanges.subscribe((data: any) => {
        this.subjectfilterOpition.next(this._filter(data.resultName));
      });
    });
  }

  resetOnFocus = (data) => {
    // console.log(this.Licensure)
    //  this.Licensure
    // this.subjectfilterOpition.next(this._filter(data));
  }

  removeMolecularSection(id: any) {
    id == 0
      ? this.Licensure.at(0).patchValue({ resultName: '' })
      : this.Licensure.removeAt(id);
  }

  showAddIocn(index) {
    return this.Licensure.value.length === index + 1;
  }

  drop_stains(event: CdkDragDrop<any>, from: 'group' | 'procedure' = 'procedure', item: any) {
    if (event.previousContainer === event.container) {

      if (event.previousContainer === event.container) {
        moveItemInArray(
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
      }

      if (from === 'group') {
        this.sequenceTracking('group', 'drop')
        this.groupLevelSort = this.checkOrder(event.container.data, 'panel')
      }
      else {
        this.sequenceTracking(item.panelId, 'drop')
        item.isAscending = this.checkOrder(event.container.data, 'Procedure_Name')
      }
    }
  }

  isTrue = (value: boolean | null) => value != null ? (value ? 'Yes' : 'No') : ''

  //#region - Export,upload & import
  exportexcel() {
    const fileName = this.sourceApp=='VitalDx'?'Tests/Stains.xlsx':`Tests/Stains_${this.templateData.secondarykeys.OrganizationId.toString()}.xlsx`;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    let ExcelDataToExport=  this.DataForExcel.reduce((p,c)=>[...p,...c.procedures],[])?.map((i:any)=>{
      let obj={
        'Id':i.id?.toString(),
        'Stain Id':i.Procedure_Id?.toString(),
        'Case Type':i.CaseType,
        'Panel Sequence':i.Sequence?.toString(),
        'Panel':i.Panel,
        'Stains Sequence':i.Procedure_Sequence,
        'Stain Name':i.Procedure_Name,
        'Quick Stain' :this.isTrue(i.ISQUICKTEST),
        'Billing Type':i.BillingType,
        'Spawn Stain On Service Type':i.SpawnTestOnServiceType,
        'Spawn Case Type':i.SpawnCaseType,
        'Spawn Case Type Mnemonic':i.SpawnCaseTypeMnemonic,
        'Send Out Flag':i.Send_Out_Flag,
        'Send Out Fullfilled Stain Action':i.Send_Out_Fullfilled_Test_Action,
        'Own Diagnosis':this.isTrue(i.IsOwnDiagnosis),
        'Own Summary':this.isTrue(i.IsOwnSummary),
        'Kit Lot Required':this.isTrue(i.IsKitLotRequired),
        'Data Set Required':this.isTrue(i.IsDataSetRequired),
        'Summary Display Name':i.SummaryDisplayName,
        'Has All Site Summary':this.isTrue(i.HasAllSiteSummary),
        'Default Send Out Lab':i.Default_Send_Out_Lab?.toString(),
        'Default Send Out Service Type':i.Default_Send_Out_Service_Type,
        'Default Send Out Bill To':i.Default_Send_Out_Bill_To,
        'Spawn Status':i.SpawnStatus,
        'CPT Code':i.CPT_Code?.toString(),
        'Billable Once':this.isTrue(i.BillableOnce),
        'Screening Type':this.isTrue(i.Is_Screening_Type),
        'Machine Stain Code':i.Machine_Test_Code?.toString(),
        'Panel Code':i.Order_Code,
        'Default Split Int Lab' : i.Default_SplitIntLab,
        'Split Test Status Pending':this.isTrue(i.SplitTestStatusPending),
        'Custom Results':this.isTrue(i.Has_Custom_Results),
        'Stain Abbreviation':i.ProcedureAbbreviation,
        'Global Send Out':this.isTrue(i.GlobalSendOut),
        'TIQ':this.isTrue(i.Is_TIQ),
        'Orderable through Panel':i.isOrderablethroughPanel,
        'Consider For Threshold':i.ConsiderForThreshold,
        'Department Details':i.DepartmentDetails,
        'Copy As Attachment':this.isTrue(i.CopyAsAttachment),
        'Result At Stain Level':this.isTrue(i.StainLevelResult),
        'Status':ConvertStatus(i.Status),
        'Created By':i.CreatedBy?.replace(/[0-9]|\(|\)/g,'')?.trim(),
        'Created Date':convertLocalDateTime(i.CreatedDate),
        'Modified By':i.ModifiedBy?.replace(/[0-9]|\(|\)/g,'')?.trim(),
        'Modified Date':convertLocalDateTime(i.ModifiedDate)
      }
      if (this.sourceApp === 'VitalDx'){ 
        delete obj['Procedure Id']
        delete obj['Id']
        delete obj['Quick Stain']
        delete obj['Billing Type']
        delete obj['Summary Display Name']
        delete obj['Panel Code']
        delete obj['Default Split Int Lab']
        delete obj['Split Test Status Pending']
        delete obj['Custom Results']
        delete obj['Stain Abbreviation']
        delete obj['Global Send Out']
        delete obj['TIQ']
        delete obj['Orderable through Panel']
        delete obj['Consider For Threshold']
        delete obj['Department Details']
        delete obj['Spawn Stain On Service Type']
        delete obj['Spawn Case Type']
        delete obj['Send Out Flag']
        delete obj['Spawn Case Type Mnemonic']
        delete obj['Copy As Attachment'] 
        delete obj['Result At Stain Level']
        delete obj['Send Out Fullfilled Stain Action']
        delete obj['Own Diagnosis']
        delete obj['Own Summary']
        delete obj['Kit Lot Required']
        delete obj['Data Set Required']
        delete obj['Default Send Out Lab']
        delete obj['Has All Site Summary']
        delete obj['Default Send Out Service Type']
        delete obj['Default Send Out Bill To']
        delete obj['Spawn Status']
        delete obj['Billable Once']
        delete obj['Screening Type']
        delete obj['Machine Stain Code']
        delete obj['Panel Code']
        delete obj['Stain Id']
      }
      return obj

    })
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(ExcelDataToExport);
      XLSX.utils.book_append_sheet(wb, ws, `Stains`);
    XLSX.writeFile(wb, fileName);
  }
  //#region Export

  // Upload screen
  loadUploadScreen() {
    this.uploadClicked = true;
    this.gridPage = false;
    let queryVariable = {
      tablename: 'AccountProcedures',
      identity: 'false',
      nullable: 'false',
    };
    let query = this.SubMenuCardModel.GetQuery('getTableColumns');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this._vitalHttpService.GetData(queryResult).subscribe(
      (data) => {
        if (!data.errors) {
          if (data) {
            let allFields = {};
            let bulkUpdateFields = {};
            allFields['Panel'] = null;
            allFields['ProcedureName'] = null;
            bulkUpdateFields['Id'] = null;
            // allFields['Sequence'] = null;
            for (let i = 0; i < data.data.allFields.length; i++) {
              allFields[data.data.allFields[i]['Column']] = null;
              bulkUpdateFields[data.data.allFields[i]['Column']] = null;
            }
            this.AllFields[0] = allFields;
            this.BulkUpdateFields[0] = bulkUpdateFields;
            delete this.AllFields[0]['accountid'];
            delete this.AllFields[0]['casetype'];
            delete this.AllFields[0]['testid'];
            delete this.AllFields[0]['procedureid'];
            delete this.AllFields[0]['AccountProcedureGUID'];
            delete this.AllFields[0]['TestSequence'];
            delete this.AllFields[0]['IsDefaultTest'];
            delete this.AllFields[0]['CreatedDate'];
            delete this.AllFields[0]['CreatedBy'];
            delete this.AllFields[0]['ModifiedDate'];
            delete this.AllFields[0]['ModifiedBy'];
            delete this.AllFields[0]['StainLevelResult'];
            delete this.AllFields[0]['isactive'];
            delete this.AllFields[0]['BillingType'];
          //  delete this.AllFields[0]['SpawnCaseTypeMnemonic'];
            delete this.AllFields[0]['OFAccountId'];
            delete this.AllFields[0]['PId'];
            // delete this.AllFields[0]['IsOwnDiagnosis'];
            delete this.AllFields[0]['SummaryDisplayName'];
            delete this.AllFields[0]['TSequence'];
            this.AllFields[0]['ProcedureCode'];
            delete this.AllFields[0]['RefData'];
            delete this.AllFields[0]['AlternateProcedureId'];
            delete this.AllFields[0]['DefaultSplitIntLab'];
            delete this.AllFields[0]['ProcedureAbbreviation'];
            delete this.AllFields[0]['IsTIQ'];
            delete this.AllFields[0]['OrderCodeID'];
            delete this.AllFields[0]['ResultReceivedDeviceId'];
            delete this.AllFields[0]['ProcessorDeviceId'];
            delete this.AllFields[0]['OrderSentDeviceId'];
            delete this.AllFields[0]['isOrderablethroughPanel'];
            delete this.AllFields[0]['ConsiderForThreshold'];
            delete this.AllFields[0]['TriggerEvent'];
            delete this.AllFields[0]['DepartmentDetails'];
            delete this.AllFields[0]['CopyAsAttachment'];
            delete this.AllFields[0]['ProcedureCount'];
            delete this.AllFields[0]['DefaultSendOutReasonTemplateId'];
            delete this.AllFields[0]['SourceGUID'];
            delete this.AllFields[0]['ISQUICKTEST'];
            delete this.AllFields[0]['GlobalSendOut'];
            delete this.AllFields[0]['SplitTestStatusPending'];
            if (!this.isCompendiumCaseType) this.AllFields[0]['ResultAtStainLevel'] = null
            this.MandatoryFields[0]['Panel'] = null;
            this.MandatoryFields[0]['ProcedureName'] = null;
            this.MandatoryFields[0]['CPTCodes'] = null;
            delete this.BulkUpdateFields[0]['OFAccountId'];
            delete this.BulkUpdateFields[0]['PId'];
            delete this.BulkUpdateFields[0]['accountid'];
            delete this.BulkUpdateFields[0]['AccountProcedureGUID'];
            delete this.BulkUpdateFields[0]['RefData'];
            delete this.BulkUpdateFields[0]['AlternateProcedureId'];
            delete this.BulkUpdateFields[0]['IsDefaultTest'];
            delete this.BulkUpdateFields[0]['CreatedDate'];
            delete this.BulkUpdateFields[0]['CreatedBy'];
            delete this.BulkUpdateFields[0]['ModifiedDate'];
            delete this.BulkUpdateFields[0]['ModifiedBy'];
            delete this.BulkUpdateFields[0]['SourceGUID'];
            delete this.BulkUpdateFields[0]['DefaultSendOutReasonTemplateId'];
            delete this.BulkUpdateFields[0]['testid'];
            delete this.BulkUpdateFields[0]['casetype'];
            delete this.BulkUpdateFields[0]['procedureid'];
          }
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  clearOrganization() {
    this.copyStainsMethodForm.patchValue({
      frmOrganization: '',
    });
  }
  // Back to upload
  testForm = this._fb.group({
    frmpanel: ['', Validators.required],
    frmprocedure: ['', Validators.required],
    frmcptcodes: ['', Validators.required],
    frmtestsequence: ['', Validators.required],
    frmsequence: ['', Validators.required],
    frmbillingtype: [''],
    frmdefaultsendoutservicetype: [''],
    frmsendoutfullfilledtestaction: [''],
    frmdefaultsendoutbillto: [''],
    frmspawntestonservicetype: [''],
    frmspawncasetype: [''],
    frmsendoutflag: [''],
    frmspawnstatus: [''],
    frmordercodeid: [''],
    frmdefaultsendoutlabid: [''],
    frmprocedurecode: [''],
    frmprocedurename: [''],
    frmmachinetestcode: [''],
    frmpid: [''],
    frmsummarydisplayname: [''],
    frmprocedureabbreviation: [''],
    frmdefaultsplitintlab: [''],
    frmtestid: null,
    frmprocedureid: null,
    frmactive: true,
    frmhasallsitesummary: false,
    frmisquicktest: false,
    frmisownsummary: false,
    frmhascustomresults: false,
    frmiskitlotrequired: false,
    frmisbillableonce: false,
    frmisdataSetrequired: false,
    frmisscreeningtype: false,
    frmsplitteststatuspending: false,
    frmglobalsendout: false,
    frmcasetypemnemonic: [''],
  });

  copyStainsMethodForm = this._fb.group({
    frmDepKey: [
      sessionStorage.getItem('deploymentKey').toUpperCase(),
      Validators.required,
    ],
    frmOrganization: [''],
    frmAccountId: [''],
    frmcpyasinactive: false,
  });

  refreshGrid() {
    this.uploadClicked = false;
    this.noDataFound = false;
    this.noData = false;
    this.copyDataClicked = false;
    this.copyClickedSequence = true;
    this.gridPage = true;
    this.addEditScreen = false;
    let query;
    this.removeGrid();
    let queryVariable = {
      casetype: this.templateData.secondarykeys.casetype.toString(),
      accid: this.templateData.cardIdentifier.toString(),
    };
    if (this.destDeployment != undefined && this.destDeployment != null) {
      if (
        this.destDeployment.toLowerCase() == 'thx' ||
        this.destDeployment.toLowerCase() == 'thxvar'
      ) {
        query = this.SubMenuCardModel.GetQuery('casetypestainlistthx');
      } else {
        query = this.SubMenuCardModel.GetQuery('casetypestainlist');
      }
    }

    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this._vitalHttpService.GetData(queryResult, this.deploymentKey).subscribe(
      (data) => {
        if (!data.errors) {
          this.templateData.submenuData = [];
          this.templateData.submenuData = data.data.submenuData;
          this.testForm.reset();
          this.copyStainsMethodForm.controls.frmOrganization.enable();
          this.copyStainsMethodForm.controls.frmDepKey.enable();
          //this.showStainData(this.templateData.submenuData);
        }
      },
      (error) => {
        console.error(error);
      }
    );
    this.searchResult = [];
  }

  //To get the fresh data
  refreshData() {
    let query;
    let queryVariable = {
      casetype: this.templateData.secondarykeys.casetype.toString(),
      accid: this.templateData.cardIdentifier.toString(),
    };
    if (
      this.destDeployment.toLowerCase() == 'thx' ||
      this.destDeployment.toLowerCase() == 'thxvar'
    ) {
      query = this.SubMenuCardModel.GetQuery('casetypestainlistthx');
    } else {
      query = this.SubMenuCardModel.GetQuery('casetypestainlist');
    }

    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this._vitalHttpService.GetData(queryResult, this.deploymentKey).subscribe(
      (data) => {
        if (!data.errors) {
          this.templateData.submenuData = [];
          this.templateData.submenuData = data.data.submenuData;
          this.testForm.reset();
          this.copyStainsMethodForm.controls.frmOrganization.enable();
          this.copyStainsMethodForm.controls.frmDepKey.enable();
        }
      },
      (error) => {
        console.error(error);
      }
    );
    this.searchResult = [];
  }

  // Download All Fields
  downloadAllFields() {
    let bigData = [];
    let finalArrData = [];
    const data = this.sampleDataAllFields.map(item => {
      const { ResultAtStainLevel, ...newItem } = item;
      return {
        ...newItem
      }
    })
    let filename =
      'Additional Stains_' +
      'All Fields_' +
      this.templateData.secondarykeys.OrganizationId.toString() +
      '.xlsx';
      let allFields = Object.keys(this.AllFields[0])
      for (let i = 0; i < allFields.length; i++) {
        if (JSON.stringify(allFields[i]) !== "{}") {
          let field = allFields[i];
          let primary = {};
          switch (field) {
            case 'SpawnTestOnServiceType':
              primary = { header: field, value: this.spawnsendoutServiceType.map(value => ({ name: value })) };
              break;
            case 'SendOutFullfilledTestAction':
              primary = { header: field, value: this.SendOutFullfilledTestActionDropdownvalue.map(value => ({ name: value })) };
              break;
            case 'SpawnStatus':
              primary = { header: field, value: this.spawnStatus.map(value => ({ name: value })) };
              break;
            case 'DefaultSendOutBillTo':
              primary = { header: field, value: this.sendOutBillToDropdownvalue.map(value => ({ name: value })) };
              break;
            case 'DefaultSendOutServiceType':
              primary = { header: field, value: this.SendOutServiceType.map(value => ({ name: value })) };
              break;
            case 'SendOutFlag':
                primary = { header: 'SendOutFlag', value: this.SendOutFlagList.map(value => ({ name: value })) };
                break;
            default:
              if (field !== 'SendOutFlag') {
                primary = { header: field, value: "" };
              }
              break;
          }
          bigData.push(primary)
        }
      }

      var finalData = {
        "name": "AccountProcedures",
        "values": bigData
      }
      finalArrData.push(finalData);
      const sampleAllFields = !this.isCompendiumCaseType ? this.sampleDataAllFields : data
      // Convert to the desired format
      const SampleDataAllFieldsArray = Object.keys(sampleAllFields[0]).map((key, index) => {
        return {
            header: key,
            value: sampleAllFields[0][key]
        };
      });
      var sampledata = {
        "name": "sampledata",
        "values": SampleDataAllFieldsArray
      }
      finalArrData.push(sampledata)
  
    this.workbookData = this.transform(finalArrData)
    this.exportAsXLSX(filename);
  }

  // Download Mandatory Fields
  downloadMandatoryFields() {
    let filename =
      'Additional Stains_' +
      'Minimal Fields_' +
      this.templateData.secondarykeys.OrganizationId.toString() +
      '.xlsx';
    var ws = XLSX.utils.json_to_sheet(this.MandatoryFields);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '' + 'AccountProcedures');
    ws = XLSX.utils.json_to_sheet(this.sampleDataMinimalFields);
    XLSX.utils.book_append_sheet(wb, ws, '' + 'SampleData');
    XLSX.writeFile(wb, filename);
  }

    // Download Update Fields
    downloadUpdateFields() {
      let bigData = [];
      let finalArrData = [];
      let filename =
        'Additional Stains_' +
        'Bulk Update_' +
        this.templateData.secondarykeys.OrganizationId.toString() +
        '.xlsx';
        let bulkUpdateFields = Object.keys(this.BulkUpdateFields[0])
        for (let i = 0; i < bulkUpdateFields.length; i++) {
          if (JSON.stringify(bulkUpdateFields[i]) !== "{}") {
            let field = bulkUpdateFields[i];
            let primary = {};
            switch (field) {
              case 'SpawnTestOnServiceType':
                primary = { header: field, value: this.spawnsendoutServiceType.map(value => ({ name: value })) };
                break;
              case 'SendOutFullfilledTestAction':
                primary = { header: field, value: this.SendOutFullfilledTestActionDropdownvalue.map(value => ({ name: value })) };
                break;
              case 'SpawnStatus':
                primary = { header: field, value: this.spawnStatus.map(value => ({ name: value })) };
                break;
              case 'DefaultSendOutBillTo':
                primary = { header: field, value: this.sendOutBillToDropdownvalue.map(value => ({ name: value })) };
                break;
              case 'DefaultSendOutServiceType':
                primary = { header: field, value: this.SendOutServiceType.map(value => ({ name: value })) };
                break;
              case 'SendOutFlag':
                  primary = { header: 'SendOutFlag', value: this.SendOutFlagList.map(value => ({ name: value })) };
                  break;
              default:
                if (field !== 'SendOutFlag') {
                  primary = { header: field, value: "" };
                }
                break;
            }
            bigData.push(primary)
          }
        }

        var finalData = {
          "name": "AccountProcedures",
          "values": bigData
        }
        finalArrData.push(finalData);

        // Convert to the desired format
        const SampleDataUpdateFieldsArray = Object.keys(this.SampleDataUpdateFields[0]).map((key, index) => {
          return {
              header: key,
              value: this.SampleDataUpdateFields[0][key]
          };
        });
        var sampledata = {
          "name": "sampledata",
          "values": SampleDataUpdateFieldsArray
        }
        finalArrData.push(sampledata)
    
      this.workbookData = this.transform(finalArrData)
      this.exportAsXLSX(filename);
    }

    transform(data) {
      let noOfRowaToGenerate = 200;
      return data.map(({ name, values }) => {
        if (name == 'sampledata') {
          noOfRowaToGenerate = 1;
        }
        const headers = values.reduce((prev, next) =>
          ({
            ...prev, [next.header]: Array.isArray
              (next.value) ? next.value.map(({ name }) => name) : next.value
          }), {})
        return {
          workSheet: name,
          rows: Array(noOfRowaToGenerate).fill(headers)
        }
      })
    }

    exportAsXLSX(name): void {
      this.excelService.exportAsExcelFile(this.workbookData, name);
    }

    SampleDataUpdateFields =[
      {
        ID : "1234"
       ,isactive: "1/0"
       ,ISQUICKTEST: "1/0"
       ,BillingType: "BillingType"
       ,SpawnTestOnServiceType: "SpawnTestOnServiceType"
       ,SpawnCaseType: "SpawnCaseType"
       ,SpawnCaseTypeMnemonic: "SpawnCaseTypeMnemonic"
       ,SendOutFlag: "SendOutFlag"
       ,SendOutFullfilledTestAction: "SendOutFullfilledTestAction"
       ,TestSequence: "123"
       ,IsOwnDiagnosis: "1/0"
       ,IsOwnSummary: "1/0"
       ,IsKitLotRequired: "1/0"
       ,IsDataSetRequired: "1/0"
       ,SummaryDisplayName : "SummaryDisplayName"
       ,HasAllSiteSummary : "1/0"
       ,DefaultSendOutLab : "1/0"
       ,DefaultSendOutServiceType : "DefaultSendOutServiceType"
       ,DefaultSendOutBillTo : "DefaultSendOutBillTo"
       ,SpawnStatus : "SpawnStatus"
       ,CPTCodes : "C123"
       ,ProcedureName : "ProcedureName"
       ,TSequence : "123"
       ,BillableOnce : "1/0"
       ,IsScreeningType : "1/0"
       ,MachineTestCode : "MachineTestCode"
       ,ProcedureCode : "ProcedureCode"
       ,DefaultSplitIntLab : "123"
       ,SplitTestStatusPending : "1/0"
       ,hasCustomResults : "1/0"
       ,ProcedureAbbreviation : "ProcedureAbbreviation"
       ,GlobalSendOut : "1/0"
       ,IsTIQ : "1/0"
       ,OrderCodeID : "1234"
       ,ResultReceivedDeviceId : "1234"
       ,ProcessorDeviceId : "1234"
       ,OrderSentDeviceId : "1234"
       ,isOrderablethroughPanel: "1/0"
       ,ConsiderForThreshold: "1/0"
       ,TriggerEvent: "1/0"
       ,DepartmentDetails: "DepartmentDetails"
       ,CopyAsAttachment: "1/0"
       ,ProcedureCount: "123"
       ,StainLevelResult: "1/0"
      }
    ];

  sampleDataMinimalFields = [
    {
      Panel: 'Panel',
      ProcedureName: 'ProcedureName',
      CPTCodes: 'C123',
    },
  ];

  // sample data for excel
  sampleDataAllFields = [
    {
      Panel: 'panel',
      ProcedureName: 'ProcedureName',
      CPTCodes: 'CPTCodes',
      ProcedureCode: 'ProcedureCode',
      MachineTestCode: 'MachineTestCode',
      DefaultSendOutServiceType: 'DefaultSendOutServiceType',
      SpawnTestOnServiceType: 'SpawnTestOnServiceType',
      SpawnCaseType: 'SpawnCaseType',
      SendOutFlag: 'SendOutFlag',
      SendOutFullfilledTestAction: 'SendOutFullfilledTestAction',
      SpawnCaseTypeMnemonic:"SpawnCaseTypeMnemonic",
      IsOwnSummary: '1/0',
      IsKitLotRequired: '1/0',
      IsDataSetRequired: '1/0',
      HasAllSiteSummary: '1/0',
      DefaultSendOutLab: '123',
      DefaultSendOutBillTo: 'DefaultSendOutBillTo',
      SpawnStatus: 'SpawnStatus',
      BillableOnce: '1/0',
      IsScreeningType: '1/0',
      hasCustomResults: '1/0',
      ResultAtStainLevel: '1/0',
      IsOwnDiagnosis:'IsOwnDiagnosis',
    }
  ];

  onFileDropped($event) {
    this.onFileChange($event);
  }

  // Method to hit once a file is dragged to or uploaded
  onFileChange(ev) {
    this.bulkUpdateFile = false
    this.minFieldsFile = false
    this.UpdateColumnMisMatch = false
    this.missingColumn = []
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if(filename.toLowerCase().includes('bulk update')){
      this.bulkUpdateFile = true
    }else if(filename.toLowerCase().includes('minimal fields')){
      this.minFieldsFile = true;
    }
    if (
      splitarry[1].toUpperCase() != 'XLSX' &&
      splitarry[1].toUpperCase() != 'XLS'
    ) {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    } else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect.filter(
            (va) => va.toLowerCase() != 'sampledata'
          );
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(
              (va) => va.toLowerCase() == 'accountprocedures'
            );
            this.sheetsToSelect.length == 1
              ? this.convertToJson(validSheet[0])
              : this.openModal();
          } else {
            this.convertToJson(this.sheetsToSelect[0]);
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = '';
  }

  //Method to validate sheet data.
  convertToJson(sheetname) {
    var worksheet;
    this.workBook.SheetNames.find((e) => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    });
    this.excelDataArray = [];
    let tempExcelArr = [];
    tempExcelArr = XLSX.utils.sheet_to_json(worksheet, { defval: null });
    if (tempExcelArr.length == 0) {
      this._snackbar.open(
        'The uploaded excel does not contain any data.',
        'Close'
      );
      tempExcelArr = [];
      return;
    }
    
    tempExcelArr = tempExcelArr.filter(obj => {
      for (let key in obj) {
          if ((obj[key] !== null) && (obj[key] != 'null') && (obj[key] !== '')) {
            return true; // If any non-null value is found, keep the object
          }
      }
      return false; // If all values are null, discard the object
    });
    this.uploadedHeaders = Object.keys(tempExcelArr[0]);
    this.expectedHeaders = this.bulkUpdateFile? Object.keys(this.BulkUpdateFields[0]): (this.minFieldsFile ? Object.keys(this.MandatoryFields[0]) : Object.keys(this.AllFields[0]));
    if(this.bulkUpdateFile){
      if(Object.keys(tempExcelArr[0]).length < 2){
        this._snackbar.open("Please Provide Valid Data", "close")
        return;
      }
       // Check if arrays are of equal length
       if (this.expectedHeaders.length !== this.uploadedHeaders.length || !(this.expectedHeaders.sort().join(',') === this.uploadedHeaders.sort().join(','))) {
         this.UpdateColumnMisMatch = true;
      }
      this.missingColumn = this.expectedHeaders.filter(header => !this.uploadedHeaders.includes(header))
    }else if(!this.bulkUpdateFile){
      if (this.expectedHeaders.length !== this.uploadedHeaders.length || !(this.expectedHeaders.sort().join(',') === this.uploadedHeaders.sort().join(','))) {
        this._snackbar.open("Invalid excel", "Close") 
        return;
      }
    }

    let primary = {};
    let tempArray = [];
    for (let i = 0; i < tempExcelArr.length; i++) {
      for (let [key, value] of Object.entries(tempExcelArr[i])) {
        if (!key.toString().match(/empty/i)) {
          // if (key == 'Testname' && key == 'ProcedureName' && key == 'CPTCode') {
          //   primary[key] = value.toString().trim();
          // }
          if(this.bulkUpdateFile){
            if(['isactive','isquicktest','isowndiagnosis','isownsummary','iskitlotrequired', 
              'isdatasetrequired', 'hasallsitesummary','billableonce', 'isscreeningtype', 'splitteststatuspending',
              'hascustomresults','globalsendout', 'istiq', 'isorderablethroughpanel', 'considerforthreshold',
              'triggerevent' ,'copyasattachment','StainLevelResult'].includes(key.toString().toLowerCase())){
                if(value == 0 || value == false || value == 'false'){
                  value = '0'
                }else{
                  value = value;
                }
            }else{
              if(value){
                value = value;
              }else{
                value = null;
              }
            }
          }

          primary[key] = value;
        }
      }
      tempArray.push(primary);
      primary = {};
    }
    tempExcelArr = tempArray;
    this.sheetHeader = [];
    let temp = [];
    temp = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    temp[0].reverse();
    temp[0].push('Notes');
    temp[0].reverse();
    this.sheetHeader = temp[0].filter(
      (va) => !va.toString().toLowerCase().match(/empty/i)
    );
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, '');
      }
    }
    // Add the key and value to the primary object in tempArray
    if(this.UpdateColumnMisMatch){
      for(let i=0; i<tempExcelArr.length; i++){
       for(let j=0; j<this.missingColumn.length; j++){
         tempExcelArr[i][this.missingColumn[j]] = '';
       }
      }
    }
    
    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);

    this.excelDataArray.sort((a, b) =>
      a.procedurename < b.procedurename
        ? -1
        : a.procedurename > b.procedurename
          ? 1
          : 0
    );
    let i = 1;
    this.excelDataArray.find((d): any => {
      Object.assign(d, {
        slno: i,
        notes: '',
        tablename: 'accountprocedures',
        accountid: sessionStorage.getItem('Locationid'),
        casetype: this.templateData.cardtype.toString(),
        organizationid: this.templateData.secondarykeys.OrganizationId.toString(),
      });
      if (!this.bulkUpdateFile) {
        Object.assign(d, {
          type: 'Upload'
        });
      }
      i++;
    });
    this.validateExcelData(this.excelDataArray);
  }

  // Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(
      this.sheetsToSelect
    );
    //let result=this.commonService.selectdSheet;
    if (result) {
      this.convertToJson(result);
    }
  }

  disableApprovebtn() {
    let existsCount = 0;
    for (let i = 0; i < this.excelDataArray.length; i++) {
      if (
        this.excelDataArray[i]['notes'] &&
        this.excelDataArray[i]['notes'].toString().match(/already exist/i)
      ) {
        existsCount++;
      }
    }
    if (existsCount == this.excelDataArray.length) {
      return true;
    }
    if (this.copyDataClicked) {
      if (this.selectedData && this.selectedData.length == 0) {
        return true;
      }
    }
    return false;
  }

  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(
      (va) => va.slno != row.dataItem.slno
    );
    if (grid.rows.length == 0) {
      this.removeGrid();
    }
    this._snackbar.open('Deleted successfully.', 'Close');
  }

  // Cancel

  formatItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (
          value == 'Failure' ||
          value.toString().includes('Invalid') ||
          value.toString().includes('exist') ||
          value.toString().includes('Mandatory') ||
          value.toString().includes('does not match')||
          value.toString().includes('missing')
        ) {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        } else if (
          value == 'Valid' ||
          value.toString().includes('approval') ||
          value.toString().includes('created')||
          value.toString().includes('Success')
        ) {
          wjCore.toggleClass(e.cell, 'high-value', true);
        } else if (
          value.toString() == 'Ignored' ||
          value.toString() == 'Ignore'
        ) {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
    }
  }

  initialisedGrid(flexgrid, hitCount?) {
    this.selectedData = [];
    // this.masterLevel ? grid.headersVisibility = wjcGrid.HeadersVisibility.Column : null
    if (hitCount) {
      if (!this.postUpload) {
        let selectedData = [];
        this.selector = new Selector(flexgrid, {
          itemChecked: () => {
            this.selectedData = flexgrid.rows.filter((r) => r.isSelected);
          },
        });
      }
    }
    const tt = new wjcCore.Tooltip();
    flexgrid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      const col = e.getColumn(),
        row = e.getRow();
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }
  removeGrid() {
    this._ngxService.start()
    this.gridWidth = 0;
    this.excelDataArray = [];
    this.postUpload = false;
    this.postDownload = false;
    this.checkStatus = false;
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    this.showDelete = true;
    this.searchResult = [];
    this.copyStainsMethodForm.controls.frmOrganization.enable();
    this.copyStainsMethodForm.controls.frmDepKey.enable();
    this.copyStainsMethodForm.patchValue({
      frmOrganization: '',
    });
    this._ngxService.stop()
  }

  // Convert Obj to lower
  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key,
        keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {};
      while (n--) {
        key = keys[n];
        let value = '';
        value =
          typeof inputArr[i][key] == 'string'
            ? inputArr[i][key].replace(/\s+/g, ' ')
            : inputArr[i][key];
        newobj[key.replace(/\s/g, '').toLowerCase()] = value;

      }
      array.push(newobj);
    }
    return array;
  }

  // Validate Excel
  validateExcelData(excelArr) {

    if (!this.copyDataClicked) {
      for (let k = 0; k < excelArr.length; k++) {
        excelArr[k].panel = !excelArr[k].panel
          ? excelArr[k].panel
          : excelArr[k].panel.toString().trim();
        excelArr[k].procedurename = !excelArr[k].procedurename
          ? excelArr[k].procedurename
          : excelArr[k].procedurename.toString().trim();
        excelArr[k].cptcodes = !excelArr[k].cptcodes
          ? excelArr[k].cptcodes
          : excelArr[k].cptcodes.toString().trim();
      }
    }
    this._ngxService.start();
    let dataArray = [];
    dataArray = this.convertObjKeysToLower(excelArr);
    if (this.bulkUpdateFile && this.missingColumn.some(e=> e.toLowerCase() == 'id')) {
      this._snackbar.open("Please provide valid data", 'Failed');
      this._ngxService.stop();
      return;
    }
    dataArray = dataArray.map(item => {
      const { resultatstainlevel, ...newItem } = item;
      return {
        ...newItem,
        StainLevelResult: item.resultatstainlevel ? item.resultatstainlevel : 0,
      }
    })
    if(!this.bulkUpdateFile){
    this._vitalHttpService.ValidateTestData(dataArray, this.deploymentKey).subscribe(
      (result) => {
        this._ngxService.stop();
        if (!result.errors) {
          if (result.length > 0) {
            this._ngxService.stop();
            if (
              result[0]['InvalidColumns'] &&
              result[0]['InvalidColumns'].length > 0
            ) {
              this.showInvalidColumns = true;
              this.invalidColumns = result[0].InvalidColumns;
            }
            dataArray.filter((e) => {
              result.filter((r) => {
                if (r.SlNo === e.slno) {
                  e.notes = r.NoteMessage;
                }
              });
            });
            this._ngxService.stop();
            this.AddGridData(dataArray);
          } else {
            this._snackbar.open('Please provide valid data.', 'Failed');
          }
        }
      },
      (error) => {
        this._ngxService.stop();
        this._snackbar.open(
          'An error occurred while processing your request.',
          'Failed'
        );
      }
    );
    }else{
      this._vitalHttpService.ValidateBulkUpdateTestData(dataArray, this.deploymentKey).subscribe(
        (result) => {
          this._ngxService.stop();
          if (!result.errors) {
            if (result.length > 0) {
              this._ngxService.stop();
              if (
                result[0]['InvalidColumns'] &&
                result[0]['InvalidColumns'].length > 0
              ) {
                this.showInvalidColumns = true;
                this.invalidColumns = result[0].InvalidColumns;
              }
              dataArray.filter((e) => {
                result.filter((r) => {
                  if (r.SlNo === e.slno) {
                    e.notes = r.NoteMessage;
                    e.panel= r.Panel;
                  }
                });
              });
              this._ngxService.stop();
              this.AddGridData(dataArray);
            } else {
              this._snackbar.open('Please provide valid data.', 'Failed');
            }
          }
        },
        (error) => {
          this._ngxService.stop();
          this._snackbar.open(
            'An error occurred while processing your request.',
            'Failed'
          );
        }
      );
    }
  }

  AddGridData(data) {
    this._ngxService.start();
    this.sheetHeader;
    this.gridDisplay = true;
    this.gridArray = [];
    this.gridData = new CollectionView([]);
    let primary = {};
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          let mandatoryFieldEmpty = false;
          let cptvalidation = false;
          for (let [key, value] of Object.entries(data[i])) {
            if (!key.toString().match(/empty/i)) {
              let flag = false;
              if(key?.toString().toLowerCase()=='spawntestonservicetype' && value){
                if(!this.spawnsendoutServiceType.some(item =>item?.toString().toLowerCase() === value?.toString().toLowerCase())){
                  primary['notes'] = 'Invalid SpawnTestOnServiceType'
                }
              }else if(key?.toString().toLowerCase()=='spawnstatus' && value){
                if(!this.spawnStatus.some(item =>item?.toString().toLowerCase() === value?.toString().toLowerCase())){
                  primary['notes'] = 'Invalid SpawnStatus'
                }
              }else if(key?.toString().toLowerCase()=='defaultsendoutbillto' && value){
                if(!this.sendOutBillToDropdownvalue.some(item =>item?.toString().toLowerCase() === value?.toString().toLowerCase())){
                  primary['notes'] = 'Invalid SendOutBillToDropdownvalue'
                }
              }else if(key?.toString().toLowerCase()=='defaultsendoutservicetype' && value){
                if(!this.SendOutServiceType.some(item =>item?.toString().toLowerCase() === value?.toString().toLowerCase())){
                  primary['notes'] = 'Invalid SendOutServiceType'
                }
              }else if(key?.toString().toLowerCase()=='sendoutflag' && value){
                if(!this.SendOutFlagList.some(item =>item?.toString().toLowerCase() === value?.toString().toLowerCase())){
                  primary['notes'] = 'Invalid SendOutFlagList'
                }
              }else if(key?.toString().toLowerCase()=='sendoutfullfilledtestaction' && value){
                if(!this.SendOutFullfilledTestActionDropdownvalue.some(item =>item?.toString().toLowerCase()=== value?.toString().toLowerCase())){
                  primary['notes'] = 'Invalid SendOutFullfilledTestActionDropdownvalue'
                }
              }else if(key?.toString().toLowerCase()=='spawncasetype' && value){
                if(!this.accountCaseTypesDropdownValue.some(item =>item?.toString().toLowerCase()=== value?.toString().toLowerCase())){
                  primary['notes'] = 'Invalid SpawnCasetype'
                }
              }else if(key?.toString().toLowerCase()=='defaultsendoutlab' && value && !this.postUpload){
                if(!this.sendOutLabIdDropdownvalue.some(item =>item?.toString().toLowerCase() === value?.toString().toLowerCase())){
                  primary['notes'] = 'Invalid DefaultSendOutLab'
                }
              }

              if(!this.bulkUpdateFile){
              if (
                key.toString().toLowerCase() != 'panel' &&
                key.toString().toLowerCase() != 'procedurename' &&
                key.toString().toLowerCase() != 'cptcodes'
              ) {
                if (value != null) {
                  if (value?.toString().toLowerCase().trim() == 'null') {
                    flag = true;
                  }
                  primary[key] = value;
                }
              }
              flag
                ? (primary['notes'] = 'Null values exist!')
                : data[i]['notes'] == ''
                  ? (primary['notes'] = 'Valid')
                  : null;
              if (
                key.toString().toLowerCase() == 'panel' ||
                key.toString().toLowerCase() == 'procedurename' ||
                key.toString().toLowerCase() == 'cptcodes'
              ) {
                if (
                  value == null ||
                  value?.toString().toLowerCase().trim() == 'null' ||
                  !/\S/.test(value.toString())
                ) {
                  mandatoryFieldEmpty = true;
                } else if (key.toString().toLowerCase() == 'cptcodes') {
                  if (value?.toString().match(/[^a-zA-Z0-9\-., /]/) != null) {
                    primary['notes'] = 'Special chars exist in CPT Code';
                  }
                }
                primary[key] = value;
              }
              if (mandatoryFieldEmpty) {
                value = 'Mandatory field is missing';
                this.excelDataArray[i]['notes'] = value;
                primary['notes'] = value;
              }
            }else{
              if(['procedureid','cptcodes','testsequence','id','tsequence','procedurename'].includes(key.toString().toLowerCase())){
                if(!this.missingColumn.find(e=> e.toLowerCase() == key.toString().toLowerCase())) {
                  if(key.toString().toLowerCase() == 'id' && (!value || value == 'null')) primary['notes'] = 'Id missing'
                  if (key.toString().toLowerCase() == 'cptcodes') {
                    if (value?.toString().match(/[^a-zA-Z0-9\-., /]/) != null) {
                      primary['notes'] = 'Special chars exist in CPT Code';
                    }
                  }
                  if(!value || value?.toString().toLowerCase().trim() == 'null'){
                    this.excelDataArray[i]['notes'] = 'Mandatory field is missing';
                    primary['notes'] = 'Mandatory field is missing';
                  }
               }
              }
              primary[key] = value;
            }
            }
          }
          this.gridArray.push(primary);
        }
        if (this.copyDataClicked && this.postUpload) {
          this.selector
            ? (this.selector.column.grid.headersVisibility =
              HeadersVisibility.Column)
            : null;
        }
        this.excelDataArray = this.gridArray;
        this.gridData = new CollectionView(this.excelDataArray, {
          groupDescriptions: ['panel'],
        });
        this.gridWidth = 170 * this.sheetHeader.length + 37;
        if (this.gridWidth > 1300) {
          this.gridWidth = 1300;
        }
      }
    }
    this._ngxService.stop();

    return false;
  }

  ExportExcel(flex) {
    let excel = [];
    let grid = cloneDeep(flex.rows);
    grid.forEach((e) => {
      delete e._data['notemessage'];
      delete e._data['_gd'];
      delete e._data['_name'];
      delete e._data['_level'];
      delete e._data['_isBottomLevel'];
      delete e._data['_groups'];
      delete e._data['_items'];
      delete e._data['_path'];
      delete e._data['accountid1'];
      delete e._data['procedureid1'];
      delete e._data['testid1'];
      delete e._data['testid'];
      delete e._data['procedureid'];
      delete e._data['accountid'];
      delete e._data['createdby'];
      excel.push(e._data);
    });
    let filename =
      'Additional Stains_' +
      this.templateData.secondarykeys.OrganizationId.toString() +
      '.xlsx';
    var ws = XLSX.utils.json_to_sheet(excel.reverse());
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '' + 'accountprocedures');
    XLSX.writeFile(wb, filename);
  }
  stainData: boolean = false

  // Upload test Data
  uploadTestData() {
    if (this.copyDataClicked) {
      let selectData = [];
      for (let i = 0; i < this.selectedData.length; i++) {
        this.selectedData[i]._data.procedureid = 0
        selectData.push(this.selectedData[i]._data);
      }
      this.excelDataArray = selectData;
    }
    if (this.excelDataArray.length) {
      for (let k = 0; k < this.excelDataArray.length; k++) {
        if (this.excelDataArray[k]) {
          const foundLab = this.sendOutLabId.find((i) => i.AccountName === this.excelDataArray[k]?.defaultsendoutlab);
          if (foundLab) {
            this.excelDataArray[k].defaultsendoutlab = foundLab.AssociatedAccount;
          }
          else this.excelDataArray[k].defaultsendoutlab = null          
        }
      }
    }
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if (!this.excelDataArray[i]['notes']) {
          this._snackbar.open(
            'Data upload failed! Please check the data for datatype mismatch.',
            'Close'
          );
        } else if (
          !this.excelDataArray[i]['notes'].toString().match(/approval/i) &&
          !this.excelDataArray[i]['notes']
            .toString()
            .match(/already exists/i) &&
          this.excelDataArray[i]['notes'].toString().toLowerCase() != 'valid' 
        ) {
          errorcount++;
        }
      }
      if (errorcount > 0) {
        this._snackbar.open(
          'Some of the data in the rows have issues. Please fix them and try again.',
          'Close'
        );
        return;
      }
      if (this.invalidColumns != '') {
        this._snackbar.open(
          'Invalid columns found! Please upload valid data.',
          'Close'
        );
        return;
      } else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          if (this.excelDataArray[i].isactive == null) {
            this.excelDataArray[i].isactive = 1;
          } else {
            this.excelDataArray[i].isactive =
              this.excelDataArray[i].isactive == 0 ||
                this.excelDataArray[i].isactive.toString().toLowerCase() ==
                'false'
                ? 0
                : 1;
            // this.excelDataArray[i].isactive = (!this.excelDataArray[i].isactive || this.excelDataArray[i].isactive == null ||
            //  this.excelDataArray[i].isactive == '1' || this.excelDataArray[i].isactive == 'true') && (this.excelDataArray[i].isactive != 0) ? 1 : 0
            if (this.addEditScreen) {
              for (let [key, value] of Object.entries(this.excelDataArray[i])) {
                if (key != 'isactive') {
                  if (value === 'null' || value === '' || value === null) {
                    this.excelDataArray[i][key] = null;
                  } else {
                    this.excelDataArray[i][key] = value.toString();
                  }
                }
              }
            } else if(!this.bulkUpdateFile){
              for (let [key, value] of Object.entries(this.excelDataArray[i])) {
                if (value === 'null' || value === '' || value === null) {
                  this.excelDataArray[i][key] = null;
                } else {
                  // this.excelDataArray[i][key] = value.toString();
                  this.excelDataArray[i][key] = value;
                }
              }
            }
          }

          delete this.excelDataArray[i]['slno'];
          delete this.excelDataArray[i]['tablename'];
          delete this.excelDataArray[i]['notes'];
          this.excelDataArray[i]['createdby'] = !sessionStorage.getItem(
            'Userid'
          )
            ? -100
            : sessionStorage.getItem('Userid');
          this.excelDataArray[i]['accountid'] =
            this.templateData.cardIdentifier.toString();
          this.excelDataArray[i]['casetype'] =
            this.templateData.cardtype.toString();
          if (this.uploadClicked) {
            if (
              this.excelDataArray[i]['isactive'] == 1 ||
              this.excelDataArray[i]['isactive'] == null ||
              this.excelDataArray[i]['isactive'] == true ||
              this.excelDataArray[i]['isactive'] == '1'
            ) {
              this.excelDataArray[i]['isactive'] = 1;
            } else {
              this.excelDataArray[i]['isactive'] = 0;
            }
          }
          if (this.copyDataClicked) {
            this.excelDataArray[i]['isactive'] = this.copyDataClicked
              ? this.checkStatus
                ? 0
                : 1
              : 1;
          }

          if (this.copyDataClicked)
            //Do not copy sequence
            this.excelDataArray[i]['tsequence'] = null;
        }
      }
      let dataArray = [];
      this._ngxService.start();
      dataArray = this.convertObjKeysToLower(this.excelDataArray);

      dataArray = dataArray.map(item => {
        const { stainlevelresult, ...newItem } = item;
        return {
          ...newItem,
          StainLevelResult: item.stainlevelresult ? item.stainlevelresult : 0,
        }
      })

      if(!this.bulkUpdateFile){
      this._vitalHttpService.bulkUploadTests(dataArray, this.deploymentKey).subscribe(
        (result) => {
          this._ngxService.stop();
          this.copyStainsMethodForm.controls.frmOrganization.disable();
          this.copyStainsMethodForm.controls.frmDepKey.disable();
          if (!result.errors) {
            if (this.addEditScreen) {
              for (let i = 0; i < result.length; i++) {
                if (result[i].isactive == true) {
                  result[i].isactive = 1;
                } else {
                  result[i].isactive = 0;
                }
              }
            } else {
              // if(this.uploadClicked)
              // for (let i = 0; i < result.length; i++) {
              //   if (result[i].sequence == null) {
              //     result[i].sequence = result[i].testsequence
              //   }
              //   else {
              //     result[i].sequence = null
              //   }
              // }
            }
            if (result.length > 0) {
              this.postUpload = true;
              this.postDownload = true;
              this.showDelete = false;
              if (this.copyDataClicked && this.postUpload) {
                this.copyStainsMethodForm.controls.frmOrganization.disable();
                this.copyStainsMethodForm.controls.frmDepKey.disable()
              }
              this.excelDataArray = [];
              for (let i = 0; i < result.length; i++) {
                result[i].Notes = result[i].NoteMessage;
                result[i].accountId = result[i].accountid1;
                result[i].procedureId = result[i].procedureid1;
                result[i].testId = result[i].testid1;
                result[i].testsequence = result[i].testsequence1;
                if (result[i]['NoteMessage'].match(/already exists/)) {
                  result[i]['STATUS'] = 'Ignored';
                }
              }
              this.excelDataArray = this.convertObjKeysToLower(result);
              this._ngxService.start();
              this.AddGridData(this.excelDataArray);
              this._ngxService.stop();
              let dataExistsCount = 0;
              for (let i = 0; i < result.length; i++) {
                if (
                  Object.values(result).every(function (item: any) {
                    return item.STATUS == 'Ignored' ? true : false;
                  })
                ) {
                  dataExistsCount++;
                }
              }
              if (dataExistsCount == result.length) {
                this._snackbar.open('Stains already exist.', 'Close');
              } else {
                if (this.copyDataClicked) {
                  this.handleSuccess('Data added successfully.', "Copy", this.excelDataArray)
                  this._snackbar.open('Data added successfully.', 'Close');
                } else {
                  this._snackbar.open('Data uploaded successfully.', 'Close');
                  this.commonService.auditDetails('', '', [], result, 'Upload', this.templateData, this.auditableColumns);
                }
              }
            } else {
              this._snackbar.open(
                'Data upload failed! Please check the data for type mismatch.',
                'Close'
              );
            }
          }
        },
        (error) => {
          this._ngxService.stop();
          this._snackbar.open('Something went wrong.Please try again', 'Close');
          console.error(error);
        }
      );
      }else{
        this._vitalHttpService.bulkUpdateTests(dataArray, this.deploymentKey).subscribe(
          (result) => {
            this._ngxService.stop();
            this.copyStainsMethodForm.controls.frmOrganization.disable();
            this.copyStainsMethodForm.controls.frmDepKey.disable();
            if (!result.errors) {
              if (result.length > 0) {
                this.postUpload = true;
                this.postDownload = true;
                this.showDelete = false;
                if (this.copyDataClicked && this.postUpload) {
                  this.copyStainsMethodForm.controls.frmOrganization.disable();
                  this.copyStainsMethodForm.controls.frmDepKey.disable()
                }
                this.excelDataArray = [];
                for( let i = 0; i < dataArray.length; i++){
                  dataArray[i].Notes = result[i].NoteMessage;
                  dataArray[i].Status = result[i].Status;
                }
                this.excelDataArray = this.convertObjKeysToLower(dataArray);
                this._ngxService.start();
                this.AddGridData(this.excelDataArray);
                this._ngxService.stop();
                let dataExistsCount = 0;
                for (let i = 0; i < result.length; i++) {
                  if (
                    Object.values(result).every(function (item: any) {
                      return item.Status == 'Ignored' ? true : false;
                    })
                  ) {
                    dataExistsCount++;
                  }
                }
                if (dataExistsCount == result.length) {
                  this._snackbar.open('Stains already exist.', 'Close');
                } else {
                  this._snackbar.open('Data updated successfully.', 'Close');
                  this.commonService.auditDetails('', '', [], dataArray, 'Bulk Update', this.templateData, '');
                }
              } else {
                this._snackbar.open(
                  'Data upload failed! Please check the data for type mismatch.',
                  'Close'
                );
              }
            }
          },
          (error) => {
            this._ngxService.stop();
            this._snackbar.open('Something went wrong.Please try again', 'Close');
            console.error(error);
          }
        ); 
      }
    }
  }

  loadCopyScreen() {
    this.noDataFound = false;
    this.copyDataClicked = true;
    this.gridPage = false;
    this.gridWidth = 0;
    this.DeploymentKeys = this._vitalHttpService.DeploymentKeys;
    this.srcDeployment = this.destDeployment;
    this.copyStainsMethodForm.patchValue({
      frmDepKey: sessionStorage.getItem('deploymentKey').toUpperCase(),
    });
    this.getListOrg();
  }

  //get data in copy from Entity
  getListOrg() {
    let queryVariable = { accountid: null, IsListedAsOF: null };
    let query = this.SubMenuCardModel.GetQuery('accountDetails');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this._ngxService.start();
    this._vitalHttpService.GetData(queryResult, this.srcDeployment).subscribe(
      (data) => {
        if (!data.errors) {
          this.resetFormErrors();
          if (data.data.submenuData && data.data.submenuData.length > 0) {
            this.organizationList = data.data.submenuData;
          }
          this._ngxService.stop();
        }
      },
      (error) => {
        console.error(error);
        this._ngxService.stop();
      }
    );
    this._ngxService.stop();
  }

  resetFormErrors() {
    Object.keys(this.testForm.controls).forEach((key) => {
      this.testForm.controls[key].setErrors(null);
    });
    this.copyStainsMethodForm.controls.frmOrganization.enable();
  }

  onChangeDeployment(e, depKey) {
    this.selectedData = [];
    this.copyStainsMethodForm.patchValue({
      frmOrganization: '',
      frmAccountId: '',
      frmcpyasinactive: false,
    });
    this.gridWidth = 0;
    if (e.source.selected) {
      this.srcDeployment = depKey;
      this.copyStainsMethodForm.patchValue({
        frmDepKey: depKey,
        frmOrganization: '',
        frmAccountId: '',
        frmcpyasinactive: false,
      });
      this.copyStainsMethodForm.controls.frmOrganization.enable();
      this.searchResult = [];
      this.excelDataArray = [];
      this.getListOrg();
    }
  }

  //get data for the second time in copy from Entity (PATCHING)
  selectedCopyDataOrganization(event, data) {
    this.selectedData = [];
    if (event.source.selected && Object.keys(data).length > 0) {
      this.copyStainsMethodForm.patchValue({
        frmAccountId: data.accountid,
        frmOrganization: data.accountname.trimStart(),
      });
      this.getDataToCopy();
    }
  }

  //Copy data from one org to another
  getDataToCopy() {
    if (typeof this.copyStainsMethodForm.value.frmAccountId == 'object') {
      let frmAccountId = this.copyStainsMethodForm.value.frmAccountId ?? [];
      this.copyStainsMethodForm.value.frmOrganization = frmAccountId[0].accid;
    }
    let queryVariable = {
      casetype: this.templateData.secondarykeys.casetype.toString(),
      accid: this.copyStainsMethodForm.value.frmAccountId.toString(),
    };
    let query = this.SubMenuCardModel.GetQuery('casetypestaincopylist');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this._ngxService.start();
    this._vitalHttpService
      .GetData(queryResult, this.copyStainsMethodForm.value.frmDepKey)
      .subscribe(
        (data) => {
          this._ngxService.stop();
          if (!data.errors) {
            let copyData = data.data.submenuData;
            this.checkStatus = false;
            if (copyData.length == 0) {
              this.excelDataArray.length = 0;
              this.gridWidth = 0;
              this.noData = true;
              return;
            }
            this.gridWidth = 1;
            this.copyDataProcess(copyData);
          }
        },
        (error) => {
          this._ngxService.stop();
          console.error(error);
        }
      );
  }

  ExportExcelCopy(flexgrid) {
    let excel = [];
    let grid = cloneDeep(flexgrid.rows);
    grid.forEach((e) => {
      for (let [key, value] of Object.entries(e._data)) {
        e._data[key] = value;
        if (key == 'testid1') {
          e._data['testid'] = value;
        } else if (key == 'procedureid1') {
          e._data['procedureid'] = value;
        } else if (key == 'accountid1') {
          e._data['accountid'] = value;
        }
      }
      delete e._data['slno'];
      delete e._data['notemessage'];
      delete e._data['createdby'];
      delete e._data['_gd'];
      delete e._data['_name'];
      delete e._data['_level'];
      delete e._data['_isBottomLevel'];
      delete e._data['_groups'];
      delete e._data['_items'];
      delete e._data['_path'];
      delete e._data['accountid1'];
      delete e._data['procedureid1'];
      delete e._data['testid1'];
      delete e._data['testsequence1'];
      excel.push(e._data);
    });

    let filename =
      'Additional Stains_' +
      this.templateData.secondarykeys.OrganizationId.toString() +
      '.xlsx';
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '' + 'accountprocedures');
    XLSX.writeFile(wb, filename);
  }
  // validation while copying
  copyDataProcess(copyData: []) {
    let dataForValidation = [];
    for (let i = 0; i < copyData.length; i++) {
      let primary = {};
      for (let [key, value] of Object.entries(copyData[i])) {
        if (key.toString() == 'testid') {
          key = 'TestId';
        }
        if (key.toString() == 'Panel') {
          key = 'Panel';
        } else {
          key = key.toString().replace(/_/g, '');
        }
        primary[key] = value;
      }
      primary['notes'] = '';
      primary['slno'] = i + 1;
      primary['tablename'] = 'accountprocedures';
      primary['casetype'] = this.templateData.cardtype.toString();
      dataForValidation.push(primary);
    }
    this.ConvertToJson('', true, dataForValidation);
  }

  ConvertToJson(
    sheetname,
    copyfrmorg: boolean = false,
    copyData: object[] = []
  ) {
    var worksheet;
    !copyfrmorg
      ? this.workBook.SheetNames.find((e) => {
        if (e.toLowerCase() == sheetname.toLowerCase()) {
          worksheet = this.workBook.Sheets[e];
        }
      })
      : null;
    this.excelDataArray = [];
    let tempExcelArr = [];
    tempExcelArr = !copyfrmorg
      ? XLSX.utils.sheet_to_json(worksheet, { defval: null })
      : copyData;
    if (tempExcelArr.length == 0) {
      !copyfrmorg
        ? this._snackbar.open(
          'The uploaded excel does not contain any data.',
          'Close'
        )
        : null;
      tempExcelArr = [];
      return;
    }
    let primary = {};
    let tempArray = [];
    if (!this.copyFlag) {
      for (let i = 0; i < tempExcelArr.length; i++) {
        for (let [key, value] of Object.entries(tempExcelArr[i])) {
          if (!key.toString().match(/empty/i)) {
            if (key.toString().match(/sequence/i)) {
              if (value && value.toString() == '0') {
                value = 0;
              } else {
                value = value;
              }
            } else {
              if (value == null) {
                value = null;
              } else if (value == '') {
                value = '';
              } else if (value.toString().match(/null/i)) {
                value = 'null';
              }
            }
            primary[key] = value;
          }
        }
        tempArray.push(primary);
        primary = {};
      }
      tempExcelArr = tempArray;
    }
    this.sheetHeader = [];
    let temp = [];
    temp = !copyData
      ? XLSX.utils.sheet_to_json(worksheet, { header: 1 })
      : Object.keys(tempExcelArr[0]);
    if (!copyData) {
      temp[0].reverse();
      temp[0].push('Notes');
      this.sheetHeader = temp[0].filter(
        (va) => !va.toString().toLowerCase().match(/empty/i)
      );
    } else {
      temp.reverse();
      temp.push('Notes');
      this.sheetHeader = temp.filter(
        (va) => !va.toString().toLowerCase().match(/empty/i)
      );
    }
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, '');
      }
    }
    this.excelDataArray = [];
    if (copyData) {
      this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
      let i = 1;
      this.excelDataArray.find((d): any => {
        Object.assign(d, {
          slno: i,
          notes: '',
          type: 'copy',
          tablename: 'accountprocedures',
          accountid: sessionStorage.getItem('Locationid'),
          casetype: this.templateData.cardtype.toString(),
          organizationid:
            this.templateData.secondarykeys.OrganizationId.toString(),
        });
        i++;
      });
    } else {
      this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
      let i = 1;
      this.excelDataArray.find((d): any => {
        Object.assign(d, {
          slno: i,
          notes: '',
          type: 'Upload',
          tablename: 'accountprocedures',
          accountid: sessionStorage.getItem('Locationid'),
          casetype: this.templateData.cardtype.toString(),
        });
        i++;
      });
    }

    this.validateExcelData(this.excelDataArray);
  }

  //Org data suggestion based on number or alphabet
  fetchOrgSeries(value: string) {
    // this._ngxService.start()
    value = value?.toString().trim();
    this.searchResult = [];
    // this.copyStainsMethodForm.value.frmOrgID = '';
    value = value?.toString().trim();
    if (!value) {
      // this._ngxService.stop()
      this.searchResult = this.organizationList.filter(x => x.accountname).sort((a, b) => a.accountname?.toString().toLowerCase() < b.accountname?.toString().toLowerCase() ? -1 : a.accountname?.toString().toLowerCase() > b.accountname?.toString().toLowerCase() ? 1 : 0)
      return (this.searchResult = this.searchResult.splice(0, 25));
    }
    let idValidate = /^[0-9]*$/;

    if (!idValidate.test(value)) {
      if (value.length > 2) {
        this.searchResult = this.organizationList.filter(function (series) {
          if (series && series.accountname != null) {
            return series.accountname
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
          }
        });
        this.searchResult = this.searchResult.splice(0, 25);
      }
    } else {
      this.searchResult = this.organizationList.filter(function (series) {
        if (series && series.accountid != null) {
          return series.accountid
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      });
      this.searchResult = this.searchResult.splice(0, 25);
    }
    this.copyStainsMethodForm.value.frmOrganization =
      this.organizationList.filter(function (series: any) {
        if (series && series.accountname != null) {
          return series.accountid
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      });
    this.resetFormErrors();
    // this._ngxService.stop(
  }

  //end #region - Export,upload & import

  customErrorMessages: any = {
    required: {
      panel: 'Enter the Panel.',
      procedurename: 'Enter the Test/Stain Name.',
      cptcodes: 'Enter the CPT Codes.',
      sequence: 'Enter the Panel Sequence.',
      tsequence: 'Enter the Test/Stain Sequence.',
    },
    invalidDropDownValue: {
      ordercodeid: 'Select Order Code ID from the Dropdown.',
      defaultsendoutservicetype:
        ' Select Default Send Out Service Type  from the Dropdown.',
      sendoutfullfilledtestaction:
        'Select Send Out Full Filled Test Action from the Dropdown.',
      defaultsendoutbillto: 'Select Default send Out Bill To from the Dropdown.',
      defaultsendoutlab: 'Select Default Send Out Lab Id from the Dropdown.',
      spawncasetype: 'Select Spawn Casetype from the Dropdown.',
      spawntestonservicetype:
        'Select Spawn Test on Service Type from the Dropdown.',
      spawnstatus: 'Select spawn Status from the Dropdown.',
      sendoutflag: 'Select Send Out Flag from the Dropdown.'
    },
  };

  isValidate(): boolean {
    for (const controlName in this.formAdditionalTests.controls) {
      const control = this.formAdditionalTests.get(controlName);
      if (control?.errors) {
        const errorMessages = [];
        for (const errorKey in control?.errors) {
          const errorMessage = this.customErrorMessages[errorKey][controlName];
          errorMessages.push(errorMessage);
          this._snackbar.open(errorMessages.join(' '), 'Close');
          this.updateValidity(controlName)
          document.getElementById(controlName).querySelector('input')?.focus()

          break;
        }
        return false;
      }
    }
    if (this.getters.spawntestonservicetype.value) {
      if (!this.getters.spawncasetype.value) {
        this._snackbar.open('Please select the Spawn Casetype.', 'Close')
        document.getElementById("spawncasetype").querySelector('input')?.focus()
        return false
      }
      if (!this.getters.spawncasetypemnemonic.value) {
        this._snackbar.open('Please enter the Spawn Casetype Mnemonic.', 'Close')
        document.getElementById("spawncasetypemnemonic").querySelector('input')?.focus()
        return false
      }
    }
    if (this.getters.spawncasetypemnemonic.value && !this.getters.spawncasetype.value) {
      this._snackbar.open('Please select the Spawn Casetype.', 'Close')
      document.getElementById("spawncasetype").querySelector('input')?.focus()
      return false
    }
    return (this.hasDuplicateProperty())
  }
  updateValidity(controlName: string) {
    this.formAdditionalTests.get(controlName).markAsDirty();
    this.formAdditionalTests.get(controlName).markAllAsTouched();
    this.formAdditionalTests.get(controlName).updateValueAndValidity()
    document.getElementById(controlName).querySelector('input')?.focus()
  }

  setValidity() {

  }



  hasDuplicateProperty(): boolean {
    let values = [];
    if (this.Licensure.value.length > 1) {
      for (const control in this.Licensure.controls) {
        if (!this.Licensure.controls[control].get('resultName').value.trim() && control != "0") {
          this.Licensure.removeAt(Number(control))
        }
      }
    }
    if (this.getters.StainLevelResult.value) {
      if (this.Licensure.value.length === 1 && !this.Licensure.value[0].resultName) {
        this._snackbar.open('Enter atleast one Result value.', 'Close');
        const element = document.getElementById(`resultName0`) as HTMLInputElement | null;
        element?.focus();
        return false
      }
      else {
        for (const obj of this.Licensure.value) {
          let Name = obj.resultName;
          if (values?.includes(Name)) {
            const index = this.Licensure.value.indexOf(obj);
            this.Licensure.controls[index].get('resultName').setValue('');
            const element = document.getElementById(`resultName${index}`) as HTMLInputElement | null;
            element.value = '';
            element?.focus();
            this._snackbar.open('Result Already Exists', 'Close');
            return false;
          } else {
            values.push(obj.resultName);
          }
        }
      }
    }
    return true;
  }

  SetValidators() {
    this.formAdditionalTests.controls.defaultsendoutservicetype.setValidators([
      dropDownValidationonlist(this.SendOutServiceType),
    ]);
    this.formAdditionalTests.controls.defaultsendoutlab.setValidators([
      dropDownValidation(this.sendOutLabId, 'AccountName'),
    ]);
    this.formAdditionalTests.controls.sendoutfullfilledtestaction.setValidators(
      [dropDownValidation(this.SendOutFullfilledTestAction, 'Value')]
    );
    this.formAdditionalTests.controls.defaultsendoutbillto.setValidators([
      dropDownValidation(this.sendOutBillTo, 'AttributeName'),
    ]);
    this.formAdditionalTests.controls.spawncasetype.setValidators([
      dropDownValidation(this.accountCaseTypes, 'Display_Name'),
    ]);
    this.formAdditionalTests.controls.spawntestonservicetype.setValidators([
      dropDownValidationonlist(this.spawnsendoutServiceType),
    ]);
    this.formAdditionalTests.controls.sendoutflag.setValidators([
      dropDownValidationonlist(this.SendOutFlagList),
    ]);
    this.formAdditionalTests.controls.spawnstatus.setValidators([
      dropDownValidationonlist(this.spawnStatus),
    ]);
  }

  RemoveValidators() {
    this.formAdditionalTests.controls.sequence.clearValidators();
    this.formAdditionalTests.controls.tsequence.clearValidators();
  }

  setSequenceValidation() {
    this.formAdditionalTests.controls.sequence.setValidators([
      Validators.required,
    ]);
    this.formAdditionalTests.controls.tsequence.setValidators([
      Validators.required,
    ]);
  }

  GetButtondetails() {
    this.GetButtonAccess(this._vitalHttpService.CasemenuAction);
  }
  uploadBtn: boolean = true;
  createBtn: boolean = true;
  editBtn: boolean = true;
  exportBtn: boolean = true;
  CopyToOrg: boolean = true;

  //#endregion

  //#region get button access
  GetButtonAccess(actionButtonDetails: any) {
    let seletedMenuPermissions
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.labadminService.templateData.menuURL)["ActionButton"]
    }
    else {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    }
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case 'Upload':
          this.uploadBtn =
            seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'Create':
          this.createBtn =
            seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'Edit':
          this.editBtn =
            seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'Export':
          this.exportBtn =
            seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
        case 'CopyToOrg':
          this.CopyToOrg =
            seletedMenuPermissions[i].IsPermitted === 'true' ? false : true;
          break;
      }
    }
  }

  ngAfterViewInit(): void {
    this.maindiv?.changes.subscribe((comps: QueryList<any>) => {
      if (comps.first) comps.first.nativeElement.scrollTop = this.scrollHistory;
    });
  }

  onScroll = (e: any) => (this.scrollHistory = e.target.scrollTop);

  bindSelectedoption(e: any, value: string) {
    const selectedValue = e;
    if (value === 'sendOutLabId') {
      this.getters.spawncasetype.patchValue('');
      if (this.getters.defaultsendoutlab.value == '') {
        this.getAccountCasetypes(this.accountId).subscribe((data: any) => {
          this.accountCaseTypes = data.data.submenuData;
          this.accountCaseTypesDropdownValue = this.accountCaseTypes.map((i) => i.Display_Name)
        })
      }
      this.selectedsendOutLabId = this.sendOutLabId.find(
        (i) => i.AccountName === selectedValue).AssociatedAccount;

      this.getAccountCasetypes(this.selectedsendOutLabId).subscribe((data: any) => {
        this.accountCaseTypes = data.data.submenuData;
        this.accountCaseTypesDropdownValue = this.accountCaseTypes.map((i) => i.Display_Name)
        if (this.accountCaseTypes.length == 0 || this.getters.defaultsendoutlab.value == '') {
          this.getAccountCasetypes(this.accountId).subscribe((data: any) => {
            this.accountCaseTypes = data.data.submenuData;
            this.accountCaseTypesDropdownValue = this.accountCaseTypes.map((i) => i.Display_Name)
          })
        }
        this.formAdditionalTests.controls.spawncasetype.setValidators([
          dropDownValidation(this.accountCaseTypes, 'Display_Name'),
        ]);
      })
    }
    else {
      this.SelectedSpawnCasetypeValue = this.accountCaseTypes.find(
        (i) => i.Display_Name === selectedValue)?.Case_Type;
    }
  }

  private checkOrder(array, key): 'ASC' | 'DESC' | 'UNS' | '' {
    if (array.length <= 1) return ""
    var aa = array.slice(1);
    if (aa.every((a, i) => array[i][key]?.trim()?.toLowerCase() <= a[key]?.trim()?.toLowerCase())) {
      return "ASC";
    }
    if (aa.every((a, i) => array[i][key]?.trim()?.toLowerCase() >= a[key]?.trim()?.toLowerCase())) {
      return "DESC";
    }
    return "UNS";
  }

  enableSortEdit() {
    this.sequenceEditMode = !this.sequenceEditMode
    // this._ngxService.start()
    this.filtercondition.next({ status: 'Active', searchText: '' })
    this.isSortDataChanged = false
  }

  returnToList() {
    if (this.isSortDataChanged) {

      this.dialog.openLabadminConfimationdialog('', "All your changes will be lost. Do you want to proceed?", 'OK', 'Cancel')
      .afterClosed().subscribe(result => {
        if (result) {
          this.filtercondition.next({ status: 'all', searchText: '' })
          this.sequenceEditMode = !this.sequenceEditMode
          this.getStainData();
          this.tracking = {}
        }
      })

    }
    else {
      this.filtercondition.next({ status: 'all', searchText: '' })
      this.sequenceEditMode = !this.sequenceEditMode
      // this._ngxService.start()
      //this.filtercondition.next({ status:'All', searchText: '' })
      this.tracking = {}
    }
  }

  sequenceTracking(from: string, message: 'ASC' | 'DESC' | 'drop', values: any = [], fromcode: boolean = false) {
    if (!fromcode) {
      if (this.tracking.hasOwnProperty(from)) this.tracking[from].push(message)
      else this.tracking[from] = [message]
    }

    if (from === 'group') {
      if (message === 'ASC') {
        values = values.sort((a, b) => (a.panel.toLowerCase() > b.panel.toLowerCase() ? 1 : -1))
        !fromcode && this.updateLocalSequence('group')
        this.groupLevelSort = 'ASC'
      }
      else if (message === 'DESC') {
        values = values.sort((a, b) => (a.panel.toLowerCase() > b.panel.toLowerCase() ? -1 : 1))
        this.groupLevelSort = 'DESC'
        !fromcode && this.updateLocalSequence('group')
      }
    }
    else {
      if (message === 'ASC') {
        values.procedures = values.procedures.sort((a, b) => (a.Procedure_Name.toLowerCase() > b.Procedure_Name.toLowerCase() ? 1 : -1))
        values.isAscending = 'ASC'
        !fromcode && this.updateLocalSequence(values.panelId)
      }
      else if (message === 'DESC') {
        values.procedures = values.procedures.sort((a, b) => (a.Procedure_Name.toLowerCase() > b.Procedure_Name.toLowerCase() ? -1 : 1))
        values.isAscending = 'DESC'
        !fromcode && this.updateLocalSequence(values.panelId)
      }
    }

    this.checkIfDataChanged()
  }

  saveSequence() {
    this.showLoader=true;
    this._ngxService.start()
    let groupSort = []
    let groupSortforAudit = []
    let oldgroupSortforAudit = []
    let attrSort = []
    let attrSortforAudit = []
    let oldattrSortforAudit = [];
    let grpSorted = false

    try {
      for (let index = 0; index < this.stainsDataForSort.currentValue.length; index++) {
        const currentElement = this.stainsDataForSort.currentValue[index]
        let previousElement = this.stainsDataForSort.previousValue[index]


        if (this.tracking.hasOwnProperty('group')) {

          if (!grpSorted) {
            const ascIndex = this.tracking['group'].lastIndexOf('ASC')
            const descIndex = this.tracking['group'].lastIndexOf('DESC')
            const sortedValue = ascIndex >= descIndex ? ascIndex : descIndex

            if (sortedValue != -1) {

              if (this.previousGroupLevelSort != this.tracking['group'][sortedValue]) {
                this.sequenceTracking('group', this.tracking['group'][sortedValue], this.stainsDataForSort.previousValue, true)
                groupSort.push({ organizationId: null, oper: qualityAttributeAndFlags.Stains, orderby: this.tracking['group'][sortedValue], accountid: currentElement.accountId, casetype: currentElement.caseType, parentId: null })


                groupSortforAudit.push({
                  id: currentElement.panelId,
                  name: currentElement.panel,
                  new: this.tracking['group'][sortedValue],
                  old: this.previousGroupLevelSort,
                  groupname: currentElement.panel,
                  groupid: null
                })

                for (let index2 = 0; index2 < this.stainsDataForSort.previousValue.length; index2++) {
                  this.stainsDataForSort.previousValue[index2].panelSequence = index2 + 1
                }

                previousElement = this.stainsDataForSort.previousValue[index]
                grpSorted = true
              }

              const len = this.tracking['group'].length - 1
              if (this.tracking['group'][len] === this.tracking['group'][sortedValue]) delete this.tracking['group']

              else this.tracking['group'] = ['drop']
            }
          }

          if (currentElement.panelId != previousElement.panelId && this.tracking.hasOwnProperty('group')) {
            attrSort.push({ id: currentElement.panelId, sequence: previousElement.panelSequence, to: qualityAttributeAndFlags.Swap_sequence_Stains_panels })
            //attrSortforAudit.push({pID:currentElement.panelId,pname:currentElement.panel,sequence:previousElement.panelSequence,type:qualityAttributeAndFlags.Swap_sequence_Stains_panels})
            //oldattrSortforAudit.push({pID:currentElement.panelId,pname:currentElement.panel,sequence:currentElement.panelSequence});

            groupSortforAudit.push({
              id: currentElement.panelId,
              name: currentElement.panel,
              new: previousElement.panelSequence,
              old: currentElement.panelSequence,
              groupname: currentElement.panel,
              groupid: null
            })
          }
        }

        if (this.tracking.hasOwnProperty(previousElement.panelId)) {
          const key = previousElement.panelId
          const ascIndex = this.tracking[key].lastIndexOf('ASC')
          const descIndex = this.tracking[key].lastIndexOf('DESC')
          const sortedValue = ascIndex >= descIndex ? ascIndex : descIndex

          if (sortedValue != -1) {

            if (previousElement.isAscending != this.tracking[key][sortedValue]) {

              this.sequenceTracking('attr', this.tracking[key][sortedValue], previousElement, true)

              groupSort.push({ organizationId: null, oper: qualityAttributeAndFlags.Stains, orderby: this.tracking[key][sortedValue], accountid: currentElement.accountId, casetype: currentElement.caseType, parentId: previousElement.panelId })
              //  groupSortforAudit.push({ PanelId:previousElement.panelId, PanelName:previousElement.panel, sequence: this.tracking[key][sortedValue]})
              // oldgroupSortforAudit.push({ PanelId:previousElement.panelId,PanelName:previousElement.panel, sequence: currentElement.previousSortValue})

              groupSortforAudit.push({
                id: previousElement.panelId,
                name: previousElement.panel,
                new: this.tracking[key][sortedValue],
                old: currentElement.previousSortValue,
                groupname: previousElement.panel,
                groupid: null
              })

              const i = this.stainsDataForSort.previousValue.findIndex((qa) => qa.panelId === key);
              for (let index = 0; index < this.stainsDataForSort.previousValue[i].procedures.length; index++) {
                this.stainsDataForSort.previousValue[i].procedures[index].Procedure_Sequence = index + 1
              }
              previousElement = this.stainsDataForSort.previousValue[index]
            }

            const len = this.tracking[key].length - 1
            if (this.tracking[key][len] === this.tracking[key][sortedValue]) delete this.tracking[key]

            else this.tracking[key] = ['drop']
          }

          if (this.tracking.hasOwnProperty(previousElement.panelId)) {
            const currentChildElement = this.stainsDataForSort.currentValue.filter((qa) => qa.panelId === previousElement.panelId)[0];

            for (let childIndex = 0; childIndex < previousElement.procedures.length; childIndex++) {
              const previousChildattrElement = previousElement.procedures[childIndex]
              const currentChildattrElement = currentChildElement.procedures[childIndex]

              if (currentChildattrElement.Procedure_Id != previousChildattrElement.Procedure_Id) {
                attrSort.push({ id: currentChildattrElement.Id, sequence: previousChildattrElement.Procedure_Sequence, to: qualityAttributeAndFlags.Swap_sequence_Stains_procedures })
                // attrSortforAudit.push({pID:currentChildattrElement.Procedure_Id,pname:currentChildattrElement.Procedure_Name,sequence:previousChildattrElement.Procedure_Sequence,type:qualityAttributeAndFlags.Swap_sequence_Stains_procedures})
                //oldattrSortforAudit.push({pID:currentChildattrElement.Procedure_Id,pname:currentChildattrElement.Procedure_Name,sequence:currentChildattrElement.Procedure_Sequence});

                groupSortforAudit.push({
                  id: currentChildattrElement.Procedure_Id,
                  name: currentChildattrElement.Procedure_Name,
                  new: previousChildattrElement.Procedure_Sequence,
                  old: currentChildattrElement.Procedure_Sequence,
                  groupname: currentChildElement.panel,
                  groupid: currentChildElement.panelId
                })
              }
            }
          }
        }
      }
      const result: any = {};

      groupSortforAudit.forEach(item => {

        const groupName = item.groupname;
        let itemName = item.name;
        const oldValue = item.old;
        const newValue = item.new;

        if (item.groupid === null) {
          itemName += " Group";
        }
        result[groupName] = result[groupName] || [];
        result[groupName].push({
          [itemName]: [
            {
              Sequence:
              {
                oldValue: oldValue,
                newValue: newValue
              }

            }
          ]
        });
      });
      //this._ngxService.stop()
      this._vitalHttpService.SwapSequence2({ UpdateSequences: attrSort, SortItems: groupSort }, this.deploymentKey).subscribe({
        next: (res) => {
          this._snackbar.open('Sequence updated successfully.', 'Close')
          this.filtercondition.next({ status: 'all', searchText: '' })
          this.sequenceEditMode = !this.sequenceEditMode
          this.getStainData()
          this.tracking = {}
          let sessionId = this.commonService.generateGuid();
          this.commonService.createActivityObject('', "Sequence", this.templateData.menuURL, 'Edit', '', '', sessionId, this.auditableColumns, '', result);
          this._ngxService.stop()
          this.showLoader=false;
        },
        error: (err) => this.handleError(err)
      })
    } catch (error) {
      this.handleError(error)
    }
  }

  updateLocalSequence(from: string) {
    if (from === 'group') {
      for (let index = 0; index < this.stainsDataForSort.currentValue.length; index++) {
        this.stainsDataForSort.currentValue[index].panelSequence = index + 1
      }
    }
    else {
      const i = this.stainsDataForSort.currentValue.findIndex((qa) => qa.panelId === from);
      for (let index = 0; index < this.stainsDataForSort.currentValue[i].procedures.length; index++) {
        this.stainsDataForSort.currentValue[i].procedures[index].Procedure_Sequence = index + 1
      }
    }
  }

  checkIfDataChanged() {
    this.isSortDataChanged = this.stainsDataForSort.currentValue.map((i) => i.panelId).join(',') != this.stainsDataForSort.previousValue.map((i) => i.panelId).join(',')

    if (!this.isSortDataChanged) {
      for (let index = 0; index < this.stainsDataForSort.currentValue.length; index++) {
        this.isSortDataChanged = this.stainsDataForSort.currentValue[index].procedures.map((i) => i.Procedure_Id).join(',') != this.stainsDataForSort.previousValue[index].procedures.map((i) => i.Procedure_Id).join(',')
        if (this.isSortDataChanged) break
      }
    }
  }

  openViewProcedure(procedure: any) {
    this.isView = true
    this.isEdit = false
    this.getOrgAttributeresultDropdown();
    this.viewPageData = procedure
  }

  returnFromView() {
    this.isView = false
    this.isEdit = false
    this.viewPageData = {}
  }

  onReset = () => {
    this.openEditProcedure(this.savedAdditionalStainsDetails)
  }

  patchFlagValue(controlName: any) {
    const control = this.formAdditionalTests.get(controlName)
    control.patchValue(!control.value)
  }

  onReturn() {
    if (!this.isStainsDataChanged()) this.resetForm()
    else this.openReturnPopup()
  }

  isStainsDataChanged() {
    const propertiesToCheck = [
      { detail: 'Panel', getter: 'panel' },
      { detail: 'Procedure_Name', getter: 'procedurename' },
      { detail: 'CPT_Code', getter: 'cptcodes' },
      { detail: 'Sequence', getter: 'sequence' },
      { detail: 'Procedure_Sequence', getter: 'tsequence' },
      { detail: 'SpawnCaseTypeMnemonic', getter: 'spawncasetypemnemonic' },
      { detail: 'Default_Send_Out_Service_Type', getter: 'defaultsendoutservicetype' },
      { detail: 'Send_Out_Fullfilled_Test_Action', getter: 'sendoutfullfilledtestaction' },
      { detail: 'Default_Send_Out_Bill_To', getter: 'defaultsendoutbillto' },
      { detail: 'SpawnTestOnServiceType', getter: 'spawntestonservicetype' },
      { detail: 'SpawnCaseType', getter: 'spawncasetype' },
      { detail: 'Send_Out_Flag', getter: 'sendoutflag' },
      { detail: 'SpawnStatus', getter: 'spawnstatus' },
      { detail: 'Default_Send_Out_Lab', getter: 'defaultsendoutlab' },
      { detail: 'Procedure_Code', getter: 'procedurecode' },
      { detail: 'Machine_Test_Code', getter: 'machinetestcode' },
      { detail: 'HasAllSiteSummary', getter: 'hasallsitesummary' },
      { detail: 'IsOwnDiagnosis', getter: 'isquicktest' },
      { detail: 'BillableOnce', getter: 'billableonce' },
      { detail: 'IsOwnSummary', getter: 'isownsummary' },
      { detail: 'Has_Custom_Results', getter: 'hascustomresults' },
      { detail: 'IsKitLotRequired', getter: 'iskitlotrequired' },
      { detail: 'IsDataSetRequired', getter: 'isdatasetrequired' },
      { detail: 'Is_Screening_Type', getter: 'isscreeningtype' },
      { detail: 'SplitTestStatusPending', getter: 'splitteststatuspending' },
      { detail: 'GlobalSendOut', getter: 'globalsendout' },
      { detail: 'StainLevelResult', getter: 'StainLevelResult' },
      { detail: 'SpawnCaseTypeMnemonic', getter: 'spawncasetypemnemonic' },
      { detail: 'Status', getter: 'isactive', compareAsActive: true }
    ];

    for (let { detail, getter, compareAsActive } of propertiesToCheck) {
      const savedDetailValue = this.savedAdditionalStainsDetails[detail];
      const getterValue = this.getters[getter].value;
      if (compareAsActive) {
        if ((savedDetailValue === 'Active') !== getterValue) {
          return true;
        }
      } else {
        if (savedDetailValue !== getterValue) {
          return true;
        }
      }
    }
    return this.ismolecularDataChanged();
  }


  ismolecularDataChanged(): boolean {
    let data = this.Licensure.value
      .filter((i) => i.resultName != '')
      ?.map((i) => i.resultName)
    const initialRoles = new Set(data);
    return !this.savedMolecularValue.every(role => initialRoles.has(role)) || data.length !== this.savedMolecularValue.length;
  }

  openReturnPopup() {

    this.dialog.openLabadminConfimationdialog('', "All your changes will be lost. Do you want to proceed?", 'OK', 'Cancel')
    .afterClosed().subscribe(result => {
      if (result) {
        this.resetForm()
      }
    })
  }

  onreset = () => this.openEditProcedure(this.savedAdditionalStainsDetails)

  onCreateReturn() {
    const getters = this.getters;
    const nonOthersProcedure = () => getters.procedurename.value && getters.procedurename.value !== 'Others';
    const isPanelActive = () => getters.panel.value && !this.isPanelDisable;

    const propertiesToCheck = [
      'cptcodes', 'sequence', 'tsequence', 'defaultsendoutservicetype', 'sendoutfullfilledtestaction',
      'defaultsendoutbillto', 'spawntestonservicetype', 'spawncasetype', 'sendoutflag', 'spawnstatus',
      'defaultsendoutlab', 'procedurecode', 'machinetestcode', 'hasallsitesummary', 'isquicktest', 'billableonce',
      'isownsummary', 'hascustomresults', 'iskitlotrequired', 'isdatasetrequired', 'isscreeningtype',
      'splitteststatuspending', 'globalsendout', 'StainLevelResult', 'spawncasetypemnemonic'
    ];

    const hasTruthyProperty = (properties) => {
      return properties.some(property => getters[property].value);
    };

    if (nonOthersProcedure() || hasTruthyProperty(propertiesToCheck) || isPanelActive() || !getters.isactive.value) {
      this.openReturnPopup();
    } else {
      this.resetForm();
    }
  }

  isFilterEnabledChanged() {
    const x = this.filtercondition.value
    return !(x.searchText == '' && ['all','active'].includes(x.status));
  }

   getDataForDx() {
    //let _this = this;
    this.labAdminSubscription = this.labAdminSessionService.getLabAdminSession.pipe(take(1),concatMap(session=>
      this.store.select("breadcrum").pipe(concatMap(i=>{
        this.userSessionDetails = session["userDetails"];
        let menuObj: object = i[i.length - 1];
        menuObj = { ...menuObj, accountLevel: 1 }
        return this.commonService.getCommmonTemplateData(menuObj, this.userSessionDetails,'',true)

      }))
    )).subscribe( session => {
     this.orgId = this.userSessionDetails.organizationId;
          this.loggedInUserId = String(this.userSessionDetails.userId);
          this.userSessionDetails.queryVariables = { orgid: `${this.orgId}`, accid: `${this.accountId}`, casetype: `${this.caseType}` }
          this.deploymentKey = this.labadminService.deploymentKey;
          this.stainHeading = "Manage Tests";
          if (this.orgId && this.accountId && this.caseType) {
            this.templateData = this.labadminService.templateData;
            this.templateData.cardtype = this.caseType;
            this.templateData.cardIdentifier = this.accountId;
            this.templateData.secondarykeys['casetype'] = this.caseType;
            this.getStainData();
            this.getDropDownData();
            this.commonService.createContext(this.templateData.secondarykeys, '', this.templateData.menuURL);
            this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
            this.GetButtonAccess(this.labadminService.subMenuPermissions)
          }
          else {
            this.filteredStainData$ = of({ data: [], totalCount: 0, activeCount: 0, isNoDataFound: true });
            // this.filteredStainData$ =
            this.ngAfterViewInit();
            this.gridPage = true;
          }
          // _this.getAuditableDetails(_this.labadminService.templateData.menuURL)

          // this.GetButtonAccess(this.VitalHttpServices.SubmenuAction)
        })
      }
    // })
  

  filterCaseTypes(caseTypeList, searchInpValue) {
    if (!searchInpValue) {
      return caseTypeList;
    }

    const normalizedValue = searchInpValue.toString().toLowerCase();
    const regex = new RegExp(normalizedValue, 'i');

    return caseTypeList?.filter(item => regex.test(item.displayname)) || [];
  }

  setCaseType(item) {
    if(item)
    {
      this.searchText = "";
      this.toggleButton({ status: 'all' })
      this.caseType = item.selectedObject?.casetype;
      this.caseTypeDisplayName = item?.selectedValue;
      if (!this.hitCount) {
        this.getDataForDx();
       // this.getStainData();
        this.hitCount++;
      }
      else {
        this.templateData.cardtype = item.selectedObject.casetype;
        this.templateData.secondarykeys.casetype = item.selectedObject?.casetype;
        this.getStainData();
      }
    }
  }

  caseTypeSelectionCheck() {
    if (!this.caseTypeList.length) {
      this.caseType = "";
      return true;
    }
    else {
      let normalizedValue = (value) => { return value.toString().toLowerCase() }
      let index = -1;
      index = this.caseTypeList.findIndex(va => normalizedValue(va.displayname) == normalizedValue(this.caseTypeDisplayName));
      if (index != -1) {
        this.caseType = this.caseTypeList[index].casetype;
      }
      return (index != -1);
    }
  }

  ngOnDestroy() {
    if (this.labAdminSubscription)
      this.labAdminSubscription.unsubscribe();

    if (this.storeSub)
      this.storeSub.unsubscribe();
  }

  getAuditableDetails(location: any) {
    this._vitalHttpService.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);

    })
  }

  trimFieldValue(controlName: any) {
    this.commonService.trimFieldValue(this.copyStainsMethodForm, controlName, this.copyStainsMethodForm.value.frmOrganization)
  }

  toggleCollapse(item: any) {
    if (this.collapsedGroups.isSelected(item)) {
      this.collapsedGroups.deselect(item);
    } else {
      this.collapsedGroups.select(item);
    }
  }

  collapseAll(stainData: any[]): void {
    console.log(stainData)
    this.collapsedGroups.clear();   
    stainData.forEach(item => this.collapsedGroups.select(item));
  }

  expandAll(): void {
    this.collapsedGroups.clear();   
  }
  
  areAllCollapsed(items: any[]): boolean {
    return items.every(item => this.isCollapsed(item));
  }

  isCollapsed(item: any): boolean {
    return this.collapsedGroups.isSelected(item);
  }

  clearSearch(type: any)
  {
    this.filteredStainData$ = of({ data: [], totalCount: 0, activeCount: 0, isNoDataFound: true });  
    this.caseTypeDisplayName = ''; 
    if (type === 'Locations') {
      // Reset accountId and clear the caseTypeList array
      this.accountId = 0;
      this.caseTypeList = [];    
    }      
   
         
  }

}

export const dropDownValidation = (validValues: any[] = [], Value: any) => {

  let validNames = validValues?.map((item) => item[`${Value}`]);

  return (control) => {
    const enteredValue = control.value;
    if (enteredValue)
      if (Value == 'AssociatedAccount')
        return validNames?.length === 0 ||
          validNames?.some((name) => name === enteredValue)
          ? null
          : { invalidDropDownValue: true };
      else
        return validNames?.length === 0 || validNames?.includes(enteredValue)
          ? null
          : { invalidDropDownValue: true };
  };
};

export const dropDownValidationonlist = (validValues: any[] = []) => {
  return (control) => {
    const enteredValue = control.value;
    if (enteredValue)
      return validValues?.length === 0 || validValues?.includes(enteredValue) ? null : { invalidDropDownValue: true };
  };
};

export function noWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'required': true };
  };
}


