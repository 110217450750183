
<section class="default-loader" *ngIf="loading && requestCount >= 2">
    <mat-spinner class="labadmin-spinner" [diameter]="50"></mat-spinner>
</section>
<div mat-dialog-title class="reset-password-header">
    <div class="vdm-heading">
        <span>Approve Reset Password</span>
    </div>
    <div class="d-flex" *ngIf="!status && !loading">
        <img class="ml-2 info-icon" width="13px" src="/assets/images_vla/Icon_Information.svg" alt="Information"/>
        <span class="hover-message">There is no request for password reset from <span class="font-weight-bold">{{config.user.username}}</span></span>
    </div>
    <img class="ml-auto cursor-pointer" mat-dialog-close src="/assets/icons/Close_black.svg" alt="cross" />
</div>
<mat-dialog-content class="reset-password-content">
    <div class="reset-password-items" [class.remove-padding-on-loading]="loading && requestCount === 1">
        <app-shimmerui *ngIf="loading && requestCount === 1" [line]="3" [col]="2"></app-shimmerui>
        <app-shimmerui *ngIf="loading && requestCount === 1" [line]="3" [col]="2"></app-shimmerui>
        <ng-container *ngIf="requestCount > 1">
            <div class="each-item">
                <span>Request ID</span>
                <span>:</span>
                <div class="d-flex align-items-center">
                    <span *ngIf="status && !requestId.value" class="bold">{{approvePasswordData?.RequestID}}</span>
                    <div *ngIf="!status || requestId.value" class="search-request-id">
                        <input type="text" maxlength="25" [formControl]="requestId" (keyup.enter)="GetApprovePasswordRequests(true)" placeholder="Search Request ID"/>
                        <img class="ml-auto cursor-pointer" width="10px" [style.opacity]="requestId.value ? '1' : '0'" src="/assets/icons/Close_black.svg" alt="cross" (click)="clearSearch()" />
                        <img class="ml-auto cursor-pointer" width="14px" src="/assets/images_vla/Icon_Search.svg" alt="search" (click)="GetApprovePasswordRequests(true)"/>
                    </div>
                    <div *ngIf="status && status != 'open'" [class]="status">{{status | titlecase}}</div>
                </div>
            </div>
            <div class="each-item">
                <span>Login ID</span>
                <span>:</span>
                <span class="bold">{{approvePasswordData?.LoginID ? approvePasswordData.LoginID : config.user.loginId}}</span>
            </div>
            <div class="each-item">
                <span>Request Date</span>
                <span>:</span>
                <span class="bold">{{approvePasswordData?.RequestedDate | formatDate:'MM/dd/yyyy, h:mm:ss a'}}</span>
            </div>
            <div class="each-item">
                <span>First Name</span>
                <span>:</span>
                <span class="bold">{{approvePasswordData?.FirstName ? approvePasswordData.FirstName : config.user.firstName}}</span>
            </div>
            <div class="each-item">
                <span>Request From IP</span>
                <span>:</span>
                <span class="requested-ip">
                    {{approvePasswordData?.RequestedIP}}
                    <img [src]="requistFromIpImg" width="17px"/>
                </span>
            </div>
            <div class="each-item">
                <span>Last Name</span>
                <span>:</span>
                <span class="bold">{{approvePasswordData?.LastName ? approvePasswordData.LastName : config.user.lastName}}</span>
            </div>
            <div class="each-item">
                <span>Last Successfull Login IP</span>
                <span>:</span>
                <span class="bold">{{approvePasswordData?.SuccessIP}}</span>
            </div>
            <div class="each-item">
                <span>Facility Address</span>
                <span>:</span>
                <span class="bold">{{approvePasswordData?.FacilityAddress ? approvePasswordData.FacilityAddress : config.user.facilityAddress}}</span>
            </div>
        </ng-container>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="my-2 mx-2 d-flex align-items-center justify-content-center w-100">
        <div *ngIf="isNoDataFound" class="no-request-found">
            <img src="/assets/images_vla/Icon_Error.svg" alt="cross" />
            <span>No password reset request found</span>
        </div>
        <div *ngIf="invalidInput" class="no-request-found">
            <img src="/assets/images_vla/Icon_Error.svg" alt="cross" />
            <span>Please Enter the Request ID to search.</span>
        </div>
        <app-button buttonclass="secondary" customClass="admin" [isDisabled]="status != 'open'" class="reset-password-btn mr-2" (click)="approveOrDenyPassword('Denied')">Deny</app-button>
        <app-button buttonclass="primary" customClass="admin" [isDisabled]="status != 'open'" class="reset-password-btn" (click)="approveOrDenyPassword('Approved')">Approve</app-button>
    </div>
</mat-dialog-actions>