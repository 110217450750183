import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-representative-card',
  templateUrl: './representative-card.component.html',
  styleUrls: ['./representative-card.component.scss']
})
export class RepresentativeCardComponent {

  @Input() cardInfo: any;
  @Input() openedLocation: any;
  @Input() finalAssignedUsers: any;
  @Output() deleteEventClicked: EventEmitter<any> = new EventEmitter<any>();
  toolTipText: any = '';

  deleteClicked() {
    this.deleteEventClicked.emit('delete');
  }

  doesRoleExists(finalObject: any) {
    if(this.finalAssignedUsers?.length) {
      let assignedRoles = this.finalAssignedUsers?.filter((item: any) => item?.RoleName === 'Account Manager' && item?.IsAssociationActive);
      let isAccountManager = assignedRoles.map(item => item?.RoleName).includes(finalObject?.RoleName);
      return isAccountManager ? true : false;
    } else {
      return false;
    }
  }

  enableToolTip(e, type = '') {
    const text = e.scrollWidth > e.clientWidth ? e.textContent : '';
    this.toolTipText = text;
  }
}
