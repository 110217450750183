<mat-spinner *ngIf="fromLocation !=='location' && showSpinner" class="labadmin-spinner" style="margin: 40vh 0vw 0vh 45vw;" [diameter]="50">
</mat-spinner>

<div *ngIf="fromLocation =='location' && showSpinner" class="spinner-div">
  <mat-spinner class="labadmin-spinner" [diameter]="50" >
  </mat-spinner>
</div>

<div *ngIf="!showSpinner" [ngClass]="viewType =='small'?'modal-backdrop-class row m-0 absolute':'modal-backdrop-class row m-0 relative'">
<div class="col-sm-5" style="height: 100vh;" *ngIf="viewType =='small'" ></div>
<div  [ngClass]="viewType =='small'?'col-sm-7 info-card relative px-0':'col-sm-12 info-card relative px-0'">
  <span class="card close_btn" (click)="CloseView()" *ngIf="viewType == 'small'">
    <mat-icon class="close_icon">close</mat-icon>
  </span>

  <app-userdetails *ngIf="fromLocation !=='location'" [userDetials]="userDetails" [userRoles]="savedRoles" [ViewType]="viewType" (EditUser)="redirectoEdit($event)" [loginType]="userInfo.logintype" [isADEnabled]="userInfo.isadenabled" [type]="type" [vendorName]="userInfo.vendorname" [statusChanged]="statusChanged" ></app-userdetails>
  <app-client-location-details *ngIf="fromLocation =='location'" [isHideLocationGrid] = "isHideLocationGrid" [locationDetails]="locationDetails" (editLocation)="editLocation($event)"></app-client-location-details>

  <app-detailstabs [tabData]="tabList" (selectedTab)="getTabData($event)"></app-detailstabs>

<div class="common-tab-wrap">
  <app-infodetails *ngIf="selectedTab =='details' && fromLocation != 'location'"  [userDetials]="userDetails" [userRoles]="savedRoles" [type]="type" [statusChanged]="statusChanged"></app-infodetails>
  <app-location-info-details *ngIf="selectedTab =='details' && fromLocation == 'location'" [isHideLocationGrid] = "isHideLocationGrid" [locationDetails]="locationDetails" ></app-location-info-details>

  <app-infonpi  *ngIf="selectedTab =='npi'" [userDetials]="userDetails" [isHideLocationGrid] = "isHideLocationGrid" [fromLocation] = "fromLocation" ></app-infonpi>
  <app-infoassociation [userDetails]="userDetails" [userRoles]="savedRoles" *ngIf="selectedTab =='associations'" [locationDetails]="locationDetails" [isHideLocationGrid] = "isHideLocationGrid"  [fromLocation] = "fromLocation"></app-infoassociation>

  <app-infopreference [userDetails]="userDetails" [userRoles]="savedRoles" *ngIf="selectedTab =='preferences' && fromLocation != 'location'"></app-infopreference>
  <app-location-preferences [locationInfo] ="locationDetails" [isHideLocationGrid] = "isHideLocationGrid"  *ngIf="selectedTab =='preferences' && fromLocation == 'location'" ></app-location-preferences>

  <app-infoattachment *ngIf="selectedTab =='attachments & notes' || selectedTab == 'documents'" [isHideLocationGrid] = "isHideLocationGrid" [locationDetails] = "locationDetails" [from]="fromLocation" [pathUserId]="pathUserId"></app-infoattachment>

  <app-infofax [userDetials]="userDetails" *ngIf="selectedTab =='fax & printing' || selectedTab =='fax'" [isHideLocationGrid] = "isHideLocationGrid" [fromLocation] = "fromLocation"></app-infofax>
</div>
</div>

</div>


