<div class="col-sm-12 m-0 p-0" *ngIf="!adduserform">
  <div class="col-md-12" *ngIf="gridDisplay">
    <div class="modal-header admin-model-header mt-3">
      <h3 class="modal-title w-100 admin-model-header-txt">
        <strong>Users</strong>
      </h3>
    </div>
  </div>
  <!-- Grid part -->
  <div *ngIf="gridDisplay" class="gridshow col-sm-12 mt-3">
    <div class="d-block col-sm-12 p-0">
      <wj-flex-grid #grid [isReadOnly]="true" [itemsSource]="gridData"
        (initialized)="initGrid(grid)" (click)="onClick(grid, $event)">
        <div *ngFor="let col of columns">
          <wj-flex-grid-column
            [visible]="shouldHideColumn(col.header)"
            [width]="200" [minWidth]="40" [binding]="col.binding" [header]="col.header" [fixed]="true"
            [allowResizing]="true" [allowDragging]="false" [format]="'d'">
          </wj-flex-grid-column>
           <wj-flex-grid-column
            [visible]="(col.header =='Last Logged In Date')"
            [width]="200" [minWidth]="40" [binding]="col.binding" [header]="col.header" [fixed]="true"
            [allowResizing]="true" [allowDragging]="false" [format]="'MM/dd/yyyy hh:mm:ss tt'">
          </wj-flex-grid-column>
        </div>
        <wj-flex-grid-filter #filter></wj-flex-grid-filter>
      </wj-flex-grid>

      <br />
      <div class="navigator-fix">
        <wj-collection-view-navigator *ngIf="showPaginationMainGrid" 
          headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
        </wj-collection-view-navigator>
      </div>
      <div class="mt-2">
        <span *ngIf="commonService.isLiveAccount && btnCreate">
          <strong>
            <span>Note:</span>
          </strong> Users can be created only in test environments. To create users in live environments, Please contact
          VitalAdmin support
        </span>
      </div>
      <div class="row col-sm-12 align-center mt-4 p-0 ml-0">
        <button *ngIf="organizationLoginType == 'Email' && checkSelectedUsers()" type="button" mat-raised-button class="admin-btn-success mr-3"
          [disabled]="btnmigratemultipleusers" (click)="bulkMigrateUsers(grid)">
          Migrate
        </button>
        <button type="button" mat-raised-button class="admin-btn-success mr-3" [disabled]="btnExport"
          (click)="exportExcel(grid)">
          Export
        </button>
        <button type="button" mat-raised-button class="admin-btn-success mr-3" [disabled]=" btnCreate"
          (click)="SelectedModal(AddUser)">
          Create
        </button>
        <button *ngIf="ifLabEntity" type="button" mat-raised-button class="admin-btn-success" [disabled]="btnUpload"
          (click)="bulkUpload()">
          Upload</button>
      </div>
    </div>

  </div>
  <!-- Nodata part -->
  <div *ngIf="gridEmpty&& gridwidth == 0" class="col-sm-12">
    <button type="button" mat-raised-button class="admin-btn-success mt-4" [disabled]=" btnCreate"
      (click)="SelectedModal(AddUser)">
      Create
    </button>
    <div class="nodata-wrap mt-4">
      <div class="nodata-design">No Data Found</div>
    </div>
  </div>

  <!--View Part-->
  <div *ngIf="ViewDisplay" class="col-sm-12">
    <div class="modal-header admin-model-header mt-3">
      <h3 class="modal-title w-100 admin-model-header-txt">
        <strong>User Details</strong>
      </h3>
    </div>
    <!-- View userpersonal info part -->
    <div class="mt-3 _scroll">
        <span class="user-btn-wrap">
            <div class="text-center" *ngIf="!ifStatusDeleted">
                <div id="text1">
                  <button mat-mini-fab class="m-2" title="Migrate User"
                    *ngIf="organizationLoginType?.toString().toLowerCase() =='email' && userAuthStatus?.toString().toLowerCase() != 'verified'"
                    (click)=" migrateUser(resdata[0].userid,grid)" [disabled]="btnmigrateuser">
                    <em class="fa fa-reply" title="Migrate User" id="displaynone"></em>
                  </button>

                  <span class="spa" [title]="checklock == 'false' ? 'Lock User' : 'Unlock User'">
                    <button mat-mini-fab class="m-2" [style.background-color]="btninactiveuser || !checklock ? 'lightgray !important' :'' " (click)=" lockout(resdata[0].userid)" [disabled]="btninactiveuser || !checklock">
                      <em [disabled] = "true" class="fa fa-unlock"  aria-hidden="true" title="Unlock User"
                      *ngIf="checklock == false"></em>
                      <em class="fa fa-lock"  aria-hidden="true"  title="Lock User"
                      *ngIf="checklock == true"></em>
                    </button>
                  </span>

                  <button mat-mini-fab class="m-2"
                    *ngIf="organizationLoginType?.toString().toLowerCase() == 'email' && userAuthStatus?.toString().toLowerCase() == 'verified'" title="Reset Password"
                    (click)="resetUserPasswordByEmail()" [disabled]="btnregeneratepassword ">
                    <img src="../../../../assets/icons/Email Invitation.svg" alt="email invitation" title="Reset Password" id="displaynone">
                  </button>

                  <button mat-mini-fab class="m-2"  title="Regenerate Password" *ngIf="!['verified','autoinitiated', 'autoreinitiated'].includes(userAuthStatus?.toString().toLowerCase())"
                    (click)="regeneratePassword(resdata[0].userid)" [disabled]="btnregeneratepassword ">
                    <em class="fa fa-undo" title="Regenerate Password" id="displaynone"></em>
                  </button>

                  <!-- <button mat-mini-fab class="m-2" *ngIf="deploymentCheck" (click)="regeneratePassword(resdata[0].userid)"
                    [disabled]="btnregeneratepassword ">
                    <em class="fa fa-undo" title="Regenerate Password" id="displaynone"></em>
                  </button> -->

                  <span class="spa" [title]="restusersstatus?.toString().toLowerCase() == 'inactive' ? 'Activate User' : 'Deactivate User'">
                    <button mat-mini-fab class="m-2" (click)="changestatus(resdata[0].userid,'status')" [disabled]="btnunlock">
                      <em class="fa fa-check" aria-hidden="true" title="Activate User"
                        *ngIf="restusersstatus?.toString().toLowerCase() == 'inactive'"></em>
                      <em class="fa fa-times" aria-hidden="true" title="Deactivate User"
                        *ngIf="restusersstatus?.toString().toLowerCase() == 'active'"></em>
                    </button>
                  </span>

                  <button mat-mini-fab class="m-2" *ngIf="(userAuthStatus?.toString().toLowerCase() != 'verified' && userAuthStatus?.toString().toLowerCase() != 'autoinitiated' && userAuthStatus?.toString().toLowerCase() != 'autoreinitiated')"
                    (click)="approveRequest(resdata[0].userid)" title="Approve Password Request"
                    [disabled]="btnapprovepasswordrequest">
                    <em class="fa fa-thumbs-up" title="Approve Password Request" aria-hidden="true"></em>
                  </button>

                </div>
              </div>
          </span>

      <div class="row">
        <div class="col-sm-6">
          <div class="row mt-2">
            <div class="col-sm-4">
              <label scope="row" class="bor-radious" aria-readonly="true">Title </label>
            </div>
            <div class="col-sm-1">
            </div>
            <div class=" col-sm-6">
              <span class="label-font">{{usertitle}}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-4">
              <span scope="row" class="bor-radious" aria-readonly="true"> Name </span>
            </div>
            <div class="col-sm-1">
            </div>
            <div class=" col-sm-6">
              <span class="label-font">{{FormattedDisplayName}}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-4">
              <span scope="row" class="bor-radious" aria-readonly="true">Middle Initials </span>
            </div>
            <div class="col-sm-1">
            </div>
            <div class=" col-sm-6">
              <span class="label-font">{{resdata[0].middleinitials}}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-4">
              <span scope="row" class="bor-radious" aria-readonly="true"> Alias Name </span>
            </div>
            <div class="col-sm-1">
            </div>
            <div class=" col-sm-6">
              <span class="label-font">{{useraliasName}}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-4">
              <span scope="row" class="bor-radious" aria-readonly="true">Login Name </span>
            </div>
            <div class="col-sm-1">
              <img class="copyIcon cursor"  *ngIf="userdisplayname?.toString().toLowerCase() != 'not specified'" alt="copy" (click)="copy(userdisplayname)"
                src="/assets/images/copy.svg" />
            </div>
            <div class=" col-sm-6">
              <span class="label-font">{{userdisplayname}}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-4">
              <span scope="row" class="bor-radious" aria-readonly="true">GUID </span>
            </div>
            <div class="col-sm-1">
            </div>
            <div class=" col-sm-6">
              <span class="label-font">{{userExternalUserGUID}}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-4">
              <span scope="row" class="bor-radious" aria-readonly="true"> Name Display Format </span>
            </div>
            <div class="col-sm-1">
            </div>
            <div class=" col-sm-6">
              <span class="label-font">{{resdata[0].usernamedisplayformat}}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-4">
              <span scope="row" class="bor-radious" aria-readonly="true"> Name Report Format </span>
            </div>
            <div class="col-sm-1">
            </div>
            <div class=" col-sm-6">
              <span class="label-font">{{resdata[0].usernamereportformat}}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-4">
              <span scope="row" class="bor-radious" aria-readonly="true">NPI </span>
            </div>
            <div class="col-sm-1">
            </div>
            <div class=" col-sm-6">
              <span class="label-font">{{resdata[0].npi}}</span>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-sm-4">
              <span scope="row" class="bor-radious" aria-readonly="true">UPIN </span>
            </div>
            <div class="col-sm-1">
            </div>
            <div class=" col-sm-6">
              <span class="label-font">{{resdata[0].upin}}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-4">
              <span scope="row" class="bor-radious" aria-readonly="true">ZIP </span>
            </div>
            <div class="col-sm-1">
            </div>
            <div class=" col-sm-6">
              <span class="label-font">{{resdata[0].zip}}</span>
            </div>
          </div>
          <div *ngIf="organizationLoginType?.toString().toLowerCase() =='email'" class="row mt-2">
            <div class="col-sm-4">
              <span scope="row" class="bor-radious" aria-readonly="true">Allow Login </span>
            </div>
            <div class="col-sm-1">
            </div>
            <div class=" col-sm-6">
              <span class="label-font">{{allowLogin}}</span>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row mt-2">
            <div class="col-sm-4">
              <span scope="row" class="bor-radious" aria-readonly="true">Qualification </span>
            </div>
            <div class="col-sm-1">
            </div>
            <div class="col-sm-6 word-wrap">
              <span class="label-font">{{resdata[0].eduqualification}}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-4">
              <span scope="row" class="bor-radious" aria-readonly="true">Email </span>
            </div>
            <div *ngIf="resdata[0].email?.toString().toLowerCase()!='not specified'" class="col-sm-1">
              <img class="copyIcon cursor" alt="copy" (click)="copy(resdata[0].email)" src="/assets/images/copy.svg" />
            </div>
            <div *ngIf="resdata[0].email==='Not Specified'" class="col-sm-1">
            </div>
            <div class=" col-sm-6">
              <span class="label-font">{{resdata[0].email}}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-4">
              <span scope="row" class="bor-radious" aria-readonly="true">Phone </span>
            </div>
            <div class="col-sm-1">
            </div>
            <div class=" col-sm-6">
              <span class="label-font">{{resdata[0].phone}}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-4">
              <span scope="row" class="bor-radious" aria-readonly="true">Address </span>
            </div>
            <div class="col-sm-1">
            </div>
            <div class=" col-sm-6">
              <span class="label-font">{{ Address }}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-4">
              <span scope="row" class="bor-radious" aria-readonly="true">Region </span>
            </div>
            <div class="col-sm-1">
            </div>
            <div class=" col-sm-6">
              <span class="label-font">{{resdata[0].Region}}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-4">
              <span scope="row" class="bor-radious" aria-readonly="true">Gender </span>
            </div>
            <div class="col-sm-1">
            </div>
            <div class=" col-sm-6">
              <span class="label-font">{{resdata[0].gender}}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-4">
              <span scope="row" class="bor-radious" aria-readonly="true">Created By</span>
            </div>
            <div class="col-sm-1">
            </div>
            <div class=" col-sm-6">
              <span class="label-font">{{resdata[0].createdby}}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-4">
              <span scope="row" class="bor-radious" aria-readonly="true">Created Date </span>
            </div>
            <div class="col-sm-1">
            </div>
            <div class=" col-sm-6">
              <span class="label-font">{{createddate}}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-4">
              <span scope="row" class="bor-radious" aria-readonly="true">Modified By </span>
            </div>
            <div class="col-sm-1">
            </div>
            <div class=" col-sm-6">
              <span class="label-font">{{resdata[0].modifiedby}}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-4">
              <span scope="row" class="bor-radious" aria-readonly="true">Modified Date </span>
            </div>
            <div class="col-sm-1">
            </div>
            <div class=" col-sm-6">
              <span class="label-font">{{modifieddate}}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-4">
              <span scope="row" class="bor-radious" aria-readonly="true">Last Logged In Date </span>
            </div>
            <div class="col-sm-1">
            </div>
            <div class=" col-sm-6">
              <span class="label-font">{{this.matchCreatedDate(createddate,resdata[0].LastLoggedInDate)}}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-4">
              <span scope="row" class="bor-radious" aria-readonly="true">SSN </span>
            </div>
            <div class="col-sm-1">
            </div>
            <div class=" col-sm-6">
              <span class="label-font">{{resdata[0].SSN}}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-4">
              <span scope="row" class="bor-radious" aria-readonly="true">Fax </span>
            </div>
            <div class="col-sm-1">
            </div>
            <div class=" col-sm-6">
              <span class="label-font">{{resdata[0].fax}}</span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-4">
              <span scope="row" class="bor-radious" aria-readonly="true">Status </span>
            </div>
            <div class="col-sm-1">
            </div>
            <div class=" col-sm-6">
              <span class="label-font">{{resdata[0].ustatus}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 mt-2">
          <span scope="row" class="bor-radious" aria-readonly="true">Roles</span>
        </div>
        <mat-chip-list class="col-sm-10 p-0">
          <mat-chip *ngFor="let roles of  myRolesArray" selected color="chip.color" class="mr-2 mt-2">
            <span class="label-font">{{ roles }}</span>
          </mat-chip>
        </mat-chip-list>
      </div>
      <div class="row">
          <div class="col-sm-2 mt-2 pt-2">
            <span scope="row" class="bor-radious" aria-readonly="true">WorkGroup</span>
          </div>
          <mat-chip-list class="col-sm-10 p-0 mt-2">
            <ng-container *ngIf="DepartmentList && DepartmentList?.length > 0 && !DepartmentList[0]?.toString().toLowerCase().includes('not specified'); else noDepartments">
              <mat-chip *ngFor="let deptNames of  DepartmentList" selected color="chip.color" class="mr-2 mt-2">
                <span class="label-font">{{ deptNames }}</span>
              </mat-chip>
            </ng-container>
            <ng-template #noDepartments>
              <span class="col-sm-9 not-specified">Not Specified</span>
            </ng-template>
          </mat-chip-list>
        </div>
    </div>
    <div class="sub-button mt-4">
      <button *ngIf="DeleteRole" (click)="deleteUser('DeleteAssociation')" title="Delete User" mat-raised-button
        class="admin-btn-success mr-4">Remove Associations</button>
      <button (click)="back()" mat-raised-button class="admin-btn-success mr-4">Back</button>
      <button (click)="exportData()" [disabled]="btnExport" mat-raised-button mat-raised-button
        class="admin-btn-success mr-4">Export</button>
      <div *ngIf="!ifStatusDeleted">
        <button mat-raised-button class="admin-btn-success mr-4" [disabled]="btnDelete" (click)="changeuserstatus('deleted')">Delete</button>
        <button (click)="editMethod()" [disabled]="btnEdit" mat-raised-button class="admin-btn-success">Edit</button>
      </div>
    </div>
  </div>

</div>


  <!-- Edit part -->
  <!-- <div *ngIf="detailsDisplay" class="col-sm-12">
    <app-edit-user [userData]="usersdeatils" [organizationLoginType]="organizationLoginType"
      [userLoginType]="userLoginType" [fromComponent]="fromComponent"></app-edit-user>
  </div>
</div> -->
<!-- Manage user -->
<app-manage-user *ngIf="adduserform" [userData]="usersdeatils" [editMode]="editMode"
[fromComponent]="fromComponent"  [organizationLoginType]="organizationLoginType" (sendCommonKey)="setCommonKey($event)" [userLoginType]="userLoginType" [creatLiveFlag]="CreateLive"></app-manage-user>

<!--Regenerate Password-->
<div *ngIf="ifPasswordClicked" class="col-sm-12">
  <div class="col-sm-8">
    <div class="modal overlay" id="alluserModal" role="dialog">
      <div class="modal-dialog" id="modaldialog">
        <!-- Modal content-->
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header admin-model-header">
            <h3 class="modal-title w-100 admin-model-header-txt">Regenerate Password</h3>
          </div>
          <div class="modal-body">
            <div class="row">
              <p id="statuschanges">Are you sure you want to regenerate password for {{ LogName}}?</p>
            </div>
          </div>
          <div class="modal-footer">
            <button mat-raised-button class="admin-btn-success mr-4" (click)="regeneratePasswordPopUp()"
              type="button">Yes</button>
            <button mat-raised-button class="admin-btn-success" (click)="ifPasswordClicked = false"
              type="button">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Reset Password  -->
<div *ngIf="ifResetPasswordClicked" class="col-sm-12">
  <div class="col-sm-8">
    <div class="modal overlay" id="alluserModal" role="dialog">
      <div class="modal-dialog" id="modaldialog">
        <!-- Modal content-->
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header admin-model-header">
            <h3 class="modal-title w-100 admin-model-header-txt">Reset Password</h3>
          </div>
          <div class="modal-body">
            <div class="row">
              <p id="statuschanges">A mail will be sent to this user to their given email address.</p>
            </div>
          </div>
          <div class="modal-footer">
            <button mat-raised-button class="admin-btn-success mr-4" (click)="toSendEmail(resdata[0].email)"
              type="button">Yes</button>
            <button mat-raised-button class="admin-btn-success" (click)="ifResetPasswordClicked = false"
              type="button">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="ifPasswordGenerated" class="col-sm-12">
  <div class="col-sm-8">
    <div class="modal overlay" id="alluserModal" role="dialog">
      <div class="modal-dialog" id="modaldialog">
        <!-- Modal content-->
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header admin-model-header">
            <h3 class="modal-title w-100 admin-model-header-txt" id="statuschanges">Regenerate Password</h3>
          </div>
          <div class="modal-body">
            <div class="row" id="password_Gen">
              <p>Loginname:</p>
              <img class="copyIcon cursor" alt="copy" (click)="copy(LogName)" src="/assets/images/copy.svg" />&nbsp;
              <p> {{LogName}} </p>
            </div>

            <div class="row" id="password_Gen">
              <p>Password:</p>
              <img class="copyIcon cursor" alt="copy" (click)="copy(passwordGen)" src="/assets/images/copy.svg" />&nbsp;
              <p>{{passwordGen}} </p>
            </div>
            <div class="align-center">
              <button mat-raised-button class="admin-btn-success align-center" (click)="ifPasswordGenerated = false"
                type="button">OK</button>
            </div>
          </div>
          <div class="modal-footer" id="modal-footer">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--UserStatus-->
<div *ngIf="ifUserStatus" class="col-sm-12">
  <div class="modal overlay" id="alluserModal" role="dialog">
    <div class="modal-dialog" id="modaldialog">
      <!-- Modal content-->
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header admin-model-header">
          <h3 class="modal-title w-100 admin-model-header-txt">User Status</h3>
        </div>
        <div class="modal-body">
          <div class="row">
            <p id="statuschanges">Are you sure you want to {{msg}} <b>{{ UserName }}</b> ?
            </p>
          </div>
        </div>
        <div class="modal-footer">
          <button mat-raised-button class="admin-btn-success mr-4" (click)="statusChange('status')" type="button">
            <span>{{msg}}</span>
          </button>
          <button mat-raised-button class="admin-btn-success" (click)="close('status')" type="button">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>


<!--UserDelete-->
<!-- <div *ngIf="ifUserDelete" class="col-sm-12">
  <div class="modal overlay" id="alluserModal" role="dialog">
    <div class="modal-dialog" id="modaldialog">
      <div class="modal-content">
        <div class="modal-header del-model-header">
          <h3 class="modal-title w-100 admin-model-header-txt">Delete User</h3>
        </div>
        <div class="modal-body">
          <div class="row">
            <p id="deleteuser">Are you sure you want to delete the user <strong>{{ statusloginname}} ?</strong>
            </p>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-sm btn-color admin-btn-success" (click)="deleteUser()" type="button">
            <span>Yes</span>
          </button>
          <button class="btn btn-sm cnclbtn-color" (click)="close()" type="button">No</button>
        </div>
      </div>
    </div>
  </div>
</div> -->


<!--Lockout-->
<div *ngIf="ifUnlockClicked" class="col-sm-12">
  <div class="modal overlay" id="alluserModal" role="dialog">
    <div class="modal-dialog" id="modaldialog">

      <!-- Modal content-->
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header admin-model-header">
          <h3 class="modal-title w-100 admin-model-header-txt">Unlock User</h3>
        </div>
        <div class="modal-body">
          <div class="row">
            <p id="statuschanges">Are you sure you want to unlock {{ lockoutloginname}} ?</p>
          </div>

        </div>
        <div class="modal-footer">
          <button mat-raised-button class="admin-btn-success mr-4" (click)="unlockUserPopUp()"
            type="button">Yes</button>
          <button mat-raised-button class="admin-btn-success" (click)="ifUnlockClicked = false"
            type="button">No</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Approve  Request-->
<div *ngIf="ifApproveRequest" class="col-sm-12">
  <div class="modal overlay" id="alluserModal" role="dialog">
    <div class="modal-dialog" id="modaldialog">
      <!-- Modal content-->
      <div class="modal-content" id="approvecontent">
        <div class="modal-header" id="approveheader">
          <h3 class="modal-title admin-model-header-txt" id="alluserModalLabel">Approve Regenerate Password</h3>
        </div>

        <div class="modal-body">
          <div class="row">

          </div>
          <div class="row">

            <div class="col-3">
              <strong>Request ID : </strong>
              <span *ngIf="!showSearch && requestID!=undefined">{{requestID}}</span>
              <div *ngIf="showSearch && requestID==undefined">
                <input style="    height: 25px;
                    position: absolute;
                    top: 0px;
                    width: 197px;
                    left: 93px;cursor: auto;" type="text" class="form-control" autofocus name="searchValue"
                  [(ngModel)]="searchValue" placeholder="Search ...." />
                <em class="fa fa-search" style="position: absolute;
                  top: 6px;
                  left: 263px;cursor:pointer
                  " (click)="onsearchapproveRequest()"></em>
              </div>

            </div>
            <div class="col-3">
              <strong>Request Date : </strong>
              <span *ngIf="requestedDate!=undefined">{{requestedDate | date:'dd-MMM-yy'}}</span>
            </div>
            <div class="col-3">
              <strong>Requested from IP : </strong>
              <span *ngIf="requestedIP!=undefined">{{requestedIP}} </span>
            </div>
            <div class="col-3">
              <strong>Last Successfull Login IP : </strong>
              <span *ngIf="successIP!=undefined">{{successIP}}</span>
            </div>

          </div>
          <div class="row" style="margin-top: 40px;">

            <div class="col-3">
              <strong>Login ID : </strong>
              <span *ngIf="loginnames!=undefined">{{loginnames}} </span>
            </div>
            <div class="col-3">
              <strong>First Name : </strong>
              <span *ngIf="firstName!=undefined"> {{firstName}}</span>
            </div>
            <div class="col-3">
              <strong>Last Name : </strong>
              <span *ngIf="lastnames!=undefined">{{lastnames}}</span>
            </div>
            <div class="col-3">
              <strong>Facility Address:</strong>
              <span *ngIf="facilityaddress!=undefined">{{facilityaddress}}</span>
            </div>

          </div>

          <div *ngIf="showMsg" class="row nodata-wrap mt-4">
            <div class="nodata-design"> No Data Found
            </div>

          </div>
          <!-- <div class="row" style="text-align: center;justify-content: center;">
                <p style="
            font-weight: 500;">cdxf </p>
              </div> -->
        </div>
        <div class="modal-footer">
          <button mat-raised-button class="admin-btn-success mr-4" (click)="requestApproval('Approved')" type="button"
            [disabled]="htnapproved">
            <span>Approve</span>
            <!-- <span  *ngIf="userStatus !== 'active'">Active</span>
                <span  *ngIf="userStatus === 'active'">Inactive</span> -->
          </button>
          <button mat-raised-button class="admin-btn-success mr-4" (click)="requestApproval('Denied')" [disabled]="htnapproved" type="button">
            <span>Deny</span>
            <!-- <span  *ngIf="userStatus !== 'active'">Active</span>
                <span  *ngIf="userStatus === 'active'">Inactive</span> -->
          </button>
          <button mat-raised-button class="admin-btn-success" (click)="closeApprovalPopup()"
            type="button">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Login Deatils-->
<div *ngIf="iflogindeatils" class="col-sm-12">
  <div class="col-sm-8">
    <div class="modal overlay" id="alluserModal" role="dialog">
      <div class="modal-dialog" id="modaldialog">
        <!-- Modal content-->
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header admin-model-header">
            <h3 class="modal-title w-100 admin-model-header-txt">Login Details</h3>
          </div>
          <div class="modal-body" style="padding: 15px 100px;">
            <div class="row">
              <p id="statuschanges">User Created Successfully.</p>
            </div>
            <div class="row pl-5">
              <p>Login Name: &nbsp; </p>&nbsp;<img class="mb-2 copyIcon cursor" alt="copy"
                (click)="copy(valueloginname)" src="/assets/images/copy.svg" />&nbsp;
              <p>{{valueloginname}}</p>
            </div>
            <div class="row pl-5">
              <p>Password: &nbsp; </p>&nbsp;<img class="mb-2 copyIcon cursor" alt="copy" (click)="copy(passworddata)"
                src="/assets/images/copy.svg" />&nbsp;
              <p>{{passworddata}}</p>
            </div>
          </div>
          <div class="modal-footer align-center">
            <div class="align-center">
              <button mat-raised-button class="admin-btn-success align-center" (click)="checkingFlags()"
                type="button">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!--Bulk Upload-->
<div *ngIf="ifBulkUpload" class="my-bulk-form">
  <div class="modal-header admin-model-header mt-3">
    <h3 class="modal-title w-100 admin-model-header-txt main-title">
      <strong>Upload Users</strong>
    </h3>
  </div>
  <div class="button-field-wrap m-1 p-0">
    <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
      <mat-label class="d-flex">
        Template
      </mat-label>
      <mat-select disableOptionCentering class="ml-2">
        <mat-option class="temp-font-size" (click)="downloadMandatoryFieldsforFacility()">
          <mat-icon class="pr-2 get-app">get_app</mat-icon>Minimal Fields for Facility
        </mat-option>
        <mat-option class="temp-font-size" (click)="downloadMandatoryFieldsforLab()">
          <mat-icon class="pr-2 get-app">get_app</mat-icon>Minimal Fields For Lab
        </mat-option>
        <mat-option class="temp-font-size" (click)="downloadUploadUsersforFacility()">
          <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields For Facility
        </mat-option>
        <mat-option class="temp-font-size" (click)="downloadUploadUsersForlab()">
          <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields For Lab
        </mat-option>
        <mat-option class="temp-font-size" (click)="downloadUpdateUsers()">
          <mat-icon class="pr-2 get-app">get_app</mat-icon>Bulk Update Users
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="button-field-wrap">
    <span class="instr-size mt-2 mb-2">
      <img aria-hidden="true" class="mb-1" alt="help" src="/assets/icons/help.svg" width="15px" height="auto" />
      You can download the sample template file to upload.</span>
  </div>

  <div class="col-sm-12 p-0" *ngIf="gridExcelWidth == 0">
    <div class="image-height" (fileDropped)="onFileDropped($event)" appDnd>
      <input type="file" id="fileDropRef" #fileDropRef click="value = null" value="" (change)="onFileChange($event)"
        hidden />
      <span class="align-center img-wrap">
        <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
      </span>
      <span>Drag & Drop excel files here</span>
      <span class="align-center">Or</span>
      <label class="btn-sm btn rounded browse ml-5" for="fileDropRef">Browse File</label>
    </div>
    <button mat-raised-button class="admin-btn-success mt-2" (click)="refreshGrid()">
      Back
    </button>
  </div>

  <div *ngIf="gridExcelDisplay && gridExcelWidth > 0">
    <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" #grid
      (initialized)="initLocationGrid(grid)" [headersVisibility]="'Column'" (formatItem)="formatItem(grid, $event)"
      [frozenColumns]="2">
      <div *ngFor="let columnname of sheetHeader">
        <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
          [visible]=" !(columnname == 'Status')" [allowResizing]="true" [width]="170" [allowDragging]="false"
          [allowSorting]="false" [format]="'d'">
        </wj-flex-grid-column>
      </div>

      <!-- Status -->
      <wj-flex-grid-column [header]="'Status'" [binding]="Status" *ngIf="postUpload" [allowResizing]="true"
        [allowDragging]="false" [allowSorting]="false" [format]="'d'">
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
          <span [ngStyle]="{color: cell.item.Status=='Success' ? 'green' : 'red'}">{{cell.item.Status}}
          </span>
        </ng-template>
      </wj-flex-grid-column>

      <!-- Action -->
      <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="126" [isReadOnly]="true">
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
          <button class="edit-delete-btn" (click)="deleteRow(grid, row)"><em id="deleteButton" [title]="'Delete'"
              class="p-0 fa fa-trash"></em></button>
        </ng-template>
      </wj-flex-grid-column>


      <wj-flex-grid-filter #filter></wj-flex-grid-filter>
    </wj-flex-grid>

    <div class="align-center">
      <wj-collection-view-navigator *ngIf="showPaginationMainGrid" class="mr-2"
        headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true" [cv]="gridData">
      </wj-collection-view-navigator>
      <wj-menu [(value)]="gridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationMainGrid">
        <wj-menu-item [value]="0">No Paging</wj-menu-item>
        <wj-menu-item [value]="10">10</wj-menu-item>
        <wj-menu-item [value]="15">15</wj-menu-item>
        <wj-menu-item [value]="30">30</wj-menu-item>
        <wj-menu-item [value]="50">50</wj-menu-item>
      </wj-menu>
    </div>

    <div class="row pl-3">
      <div class="col-sm-2 p-0">
        <button mat-raised-button class="admin-btn-success mr-2 mt-1" *ngIf="gridExcelDisplay" (click)="removeGrid()">
          Back
        </button>
      </div>
      <div class="col-sm-10 button-wrap p-0 m-0">
        <div *ngIf="excelDataArray.length > 0" class="m-1 p-0">
          <button mat-raised-button class="admin-btn-success mr-2" (click)="uploadUserData()"
            *ngIf="!postUpload">Approve</button>
          <button mat-raised-button class="admin-btn-success mr-2" *ngIf="postUpload"
            (click)="refreshGrid()">Finish</button>
          <button mat-raised-button class="admin-btn-success mr-2" *ngIf="!postUpload"
            (click)="refreshGrid()">Cancel</button>
          <button mat-raised-button *ngIf="postUpload" class="admin-btn-success" (click)="ExportExcel(grid)">Download
            Result</button>
        </div>
      </div>
    </div>
    <div *ngIf="false" class="row pl-3 m-1 p-0 error-field-wrap">
      <div class="error-msg">*Invalid Data: </div>&nbsp;<span class="error-msg">{{invalidData}} </span>
    </div>
  </div>


  <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
    <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
  </div>

</div>

