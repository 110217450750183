import { Component, Input, OnInit } from '@angular/core';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcCore from '@grapecity/wijmo';
import { catchError, concatMap, map, of, take } from 'rxjs';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { Store } from '@ngrx/store';
import { trigger } from '@angular/animations';
import { da } from 'date-fns/locale';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DialogService } from 'src/app/core/services/dialog.service';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';


@Component({
  selector: 'app-manage-medications',
  templateUrl: './manage-medications.component.html',
  styleUrls: ['./manage-medications.component.scss']
})
export class ManageMedicationsComponent implements OnInit {
@Input() templateData:any;
organizationId:Number|null=null;
accountId:Number|null=null
userId:Number|null=null
deploymentKey:string=''
caseList:casetypeDTO[]=[]
medicationList:MedicationsDTO[]=[];
gridData: wjcCore.CollectionView<MedicationsDTO[]>;
loader:boolean=false;
gridShow:boolean = false;
caseType:string|null=null;
defaultValueOnCreate:{}|null=null

userSessionDetails 
listedGridHeader: any = [];
sourceApp:string=''
SubMenuCardModel: SubMenuCardModel;
saveOrEditCompleted:any
//RBAC
editBtn:boolean=false;
createBtn:boolean=false;
exportBtn:boolean=false;
activityEntity: any;
auditableColumns: any;
exportExcelFields = {
  CaseType: { displayName: "Case Type", isDate: false },
  CaseTypeDisplayName: { displayName: "Case Type Display Name", isDate: false },
  MedicationBrand: { displayName: "Medication Brand", isDate: false },
  MedicationCode: { displayName: "Medication Code", isDate: false },
  MedicationDescription: { displayName: "Medication Description", isDate: false },
  IsActive: { displayName: "Status", isDate: false },
  CreatedBy: { displayName: "Created By", isDate: false },
  CreatedDate: { displayName: "Created Date", isDate: true },
  ModifiedBy: { displayName: "Modified By", isDate: false },
  ModifiedOn: { displayName: "Modified Date", isDate: true },
}

constructor(  private store: Store<{ breadcrum: [] }>
  ,private _templateHeaders: TemplateHeadersService,
  private _activityService: ActivityTrackerService,
  private _dailogService: DialogService,
  private datashare: DataShareService, public commonService: CommonService,public _vitalHttpService: VitalHttpServices,
  private _labadminServices:LabadminService,
  private labAdminSessionService:LabadminSessionService,private _snackbar:MatSnackBar,
  private ngxLoader:NgxUiLoaderService,
) {
  this.SubMenuCardModel = new SubMenuCardModel(
    commonService,
    _vitalHttpService,
    datashare
  );
 }
  ngOnInit(): void {
    let checkLaunchUrl = window.location.href.split('/#/')[1];
    if(checkLaunchUrl!='home')
      { this.sourceApp='VitalDx';
        this.labAdminSessionService.getLabAdminSession
          .pipe(
            concatMap((session: any) =>
              this.store.select('breadcrum').pipe(
                take(1),
                
                concatMap((i:any) => {
                  this.userSessionDetails = session['userDetails'];
                  this.sourceApp = 'VitalDx';
                  return this.commonService.getCommmonTemplateData(i[i.length-1], this.userSessionDetails,'submenu',true).then(data=>data);
                })
              )
            )
          )
          .subscribe((data) => {
            this.templateData = this._labadminServices.templateData;
            this.deploymentKey = this._labadminServices.deploymentKey;
            this.userId = this.userSessionDetails.userId;
            this.organizationId=this.userSessionDetails.organizationId
            this.accountId =
              this.userSessionDetails.userAccountIDs[0].accountId;
              this.getMedicationList();
              this.getListConfig()
              this.getAuditableDetails(this.templateData.menuURL)
              this.getButtonAccess(this._labadminServices.subMenuPermissions);
              this._activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': 'Lab' }, { 'key': 'Lab Management', 'value': this.templateData['menuURL'] }] })
              this._activityService.getActivitySession.subscribe(res => this.activityEntity = res);
              this.activityEntity.entityId = '';
              this._activityService.setActivitySession(this.activityEntity);
          });
      }
      else{
    this.caseType=this.templateData.secondarykeys.casetype
    this.defaultValueOnCreate={CaseType:this.caseType}
    this.setLocalVariables();
    this.getMedicationList();
    this.getAuditableDetails(this.templateData.menuURL)
    this.getListConfig()
    this.getButtonAccess(this._vitalHttpService.CasemenuAction);
    this._activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': this.templateData['menuURL'] }] })
    this._activityService.getActivitySession.subscribe(res => this.activityEntity = res);
     
  }

  
  }
  getListConfig(){
   let headerData= this._templateHeaders.templateList.find(i=>i.templateName=="Medications")
    if(headerData){
      this.listedGridHeader=headerData.headers
      if(this.sourceApp.toLowerCase()=='vitaldx'&& this.listedGridHeader[0].Name!='Case Type')
        { 
         this.listedGridHeader.unshift({ Name: 'Case Type', type: 'input', key: 'CaseTypeDisplayName', colWidth: 'col-sm-1' }) 
        }
    }
  }
  setLocalVariables()
  {
    this.organizationId= Number(sessionStorage.getItem('org_id')) 
   this.accountId= Number(sessionStorage.getItem('AccountID')) 
   this.userId=Number(sessionStorage.getItem('Userid'))
   this.deploymentKey=sessionStorage.getItem('DeploymentKey')
   
  }
  getButtonAccess(actionButtonDetails:any) {
    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = actionButtonDetails.find(va => va.Htext == this._labadminServices.templateData.menuURL)["ActionButton"]
    }
    else {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == "Masters")['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    }
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      if (seletedMenuPermissions[i].Button === "Export") {
        this.exportBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
      else if (seletedMenuPermissions[i].Button === "Create") {
        this.createBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
      else if (seletedMenuPermissions[i].Button === "Edit") {
        this.editBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
    }
  }

  getMedicationList(){
    this.gridShow=false
    this.sourceApp==''? this.ngxLoader.start():''
    this.getListConfig()
    this.loader=true
    this._vitalHttpService.GetData({
      OperationName:null,
      Query:this._vitalHttpService.GetQuery(this.templateData.Keyword),
      Variables:{ OrganizationId:this.organizationId.toString(),AccountId:this.accountId.toString(),CaseType:this.caseType}
    },this.deploymentKey).pipe(
      map(i=>i?.data?.submenu),
      catchError((err)=>{
        this._snackbar.open('Something went Wrong.','close')
        console.error(err)
        return of(err)
      })
    ).subscribe({next:(data:[])=>{
      this.medicationList=[...data??[]]
      this.gridData=new CollectionView(data??[])
      // console.log(this.medicationList)
      this.gridShow=true
      this.sourceApp==''?this.ngxLoader.stop():''
    },
    error:(err)=>{
      console.error(err)
      this.gridShow=true 
      this.sourceApp==''?this.ngxLoader.stop():''
      this._snackbar.open('Something went wrong.','close')
    }
  })
  }
  emitFilters(event:any){
    let caseType=event?.CaseType?.object?.CaseType??'All Case Types';
    if(caseType=='All Case Types')
    {
      this.gridData=new CollectionView(this.medicationList)

    }
    else{
      this.gridData=new CollectionView(this.medicationList.filter((i:MedicationsDTO)=>i.CaseType==caseType))
    }


  }
  handleListingScreen(event){
    if(event=='openList')
    {
      // this.activityEntity.entityId ='';
      // this._activityService.setActivitySession(this.activityEntity);
    }
  }
  newEditMethod(event){
    // this.activityEntity.entityId = event?.rowData.MedicationId;
    // this._activityService.setActivitySession(this.activityEntity);

  }
  newDeleteMethod(event){

  }
  recieveObjFromListing(event){
    let newData=event.newData;
    let oldData=event.oldData;
      if(newData.Action=='Create')
      {
        if(this.sourceApp=='VitalDx'&&newData.CaseType.join('~')==''){
          this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
          this._snackbar.open('Select Atleast One Case Type.','close')
          return 
        }
        let saveObject:saveMedicationDTO={
          CaseType:this.caseType==null?newData.CaseType.join('~'):this.caseType,
          OrganizationId:this.organizationId,
          AccountId:this.accountId,
          MedicationCode: newData.MedicationCode,
          MedicationDescription: newData.MedicationDescription,
          MedicationBrand: newData.MedicationBrand,
          ModifiedBy: newData.ModifiedBy,
          IsActive: Boolean(newData.IsActive),
          MedicationId:0,
        }
        if(this.validateDuplicate(saveObject))
          {
            this._dailogService.openLabadminConfimationdialog(
              '',
              'Duplicate entry found. Do you wish to proceed?',
              'OK',
              'Cancel'
            ).afterClosed().subscribe((result) => {
              if(result)
                this.saveOrEditMedication('create',saveObject)
              else 
                return  ( this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed')
            })
          } 
        else {
          this.saveOrEditMedication('create',saveObject)
        }       
      }
      else{
        let saveObject:saveMedicationDTO={
          CaseType:oldData.CaseType,
          OrganizationId:this.organizationId,
          AccountId:this.accountId,
          MedicationCode: newData.MedicationCode,
          MedicationDescription: newData.MedicationDescription,
          MedicationBrand: newData.MedicationBrand,
          ModifiedBy: this.userId.toString(),
          IsActive: Boolean(newData.IsActive),
          MedicationId:oldData.MedicationId,
        }           
        if(oldData.IsActive!='Active'&&newData.IsActive&&this.validateDuplicate(saveObject)){
          this._dailogService.openLabadminConfimationdialog(
            '',
            'Active record found. Do you wish to proceed?',
            'OK',
            'Cancel'
          ).afterClosed().subscribe((result) => {
            if(result)
              this.saveOrEditMedication('update',saveObject,oldData)
            else 
              return ( this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed')
          })
        } 
        else {
          this.saveOrEditMedication('update',saveObject,oldData)
        }     
      }
  }

  validateDuplicate(data:saveMedicationDTO):Boolean{
    const activeList_=this.medicationList.filter((i:any)=>i.IsActive);
    const incativeList_=this.medicationList.filter((i:any)=>!i.IsActive);
   
        let isExist=activeList_.find((i:any)=>
        i.MedicationCode?.toString()?.toLowerCase()?.trim()==data.MedicationCode?.toString()?.toLowerCase()?.trim()
        &&this.convertToBoolean(i.IsActive)==data.IsActive
        );
        
        return isExist?data.CaseType.split('~').includes(isExist.CaseType):false

}



saveOrEditMedication(oper:string='create',saveObject:saveMedicationDTO,oldObject:any=null){
  const sucessMessage=oper=='create'?`Data Saved Succuessfully.`:`Data Updated Successfully.`
  const failedMessage=oper=='create'?'Something went wrong.':'Something went wrong.'
  this.sourceApp==''?this.ngxLoader.start():''
  try {
   this._labadminServices.addAndUpdateMedication(saveObject,oper=='create'?'':'update',this.deploymentKey).subscribe({next:(data:any)=>{
    if(data.Message=='Success')
      {
        // this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
        this.getMedicationList();
        let successObj = [{ 'MedicationCode': saveObject.MedicationCode }]
        let sessionId = this.commonService.generateGuid();
        let returnedList=data.Content.split(',')
        for(let i=0;i<returnedList.length;i++)
        {
          let saveobj={...saveObject,IsActive:saveObject.IsActive?'Active':'InActive',CaseType:saveObject.CaseType.split('~')[i]}
          oper=='create'?
          this.commonService.createActivityObject(returnedList[i],`${saveObject.MedicationCode}`, this.templateData.menuURL, 'Create', saveobj, {}, sessionId,this.auditableColumns)
          :
          this.commonService.createActivityObject(returnedList[i],`${saveObject.MedicationCode}`, this.templateData.menuURL, 'Edit', saveobj, oldObject, sessionId,this.auditableColumns)
        }

        this._snackbar.open(sucessMessage,'close')
        this.gridShow=false
        this.sourceApp==''?this.ngxLoader.stop():''
      }
      else{
        this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
        this._snackbar.open(failedMessage,'close')
        this.gridShow=false
        this.sourceApp==''?this.ngxLoader.stop():''
      }
      },
  error:(err)=>{
    console.error(err)
    this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
    this._snackbar.open(failedMessage,'close')
    this.gridShow=true
    this.sourceApp==''?this.ngxLoader.stop():''
  }
})
} catch (error) {
  console.error(error)
  this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed' ;
}
}
convertToBoolean(value:any)
{
  if(typeof(value)=='string')
    return value=='Active'
  return value

}
getAuditableDetails(location: any) {
  this._vitalHttpService.getDisplayColumns({ "TableName": location }).subscribe((res) => {
    this.auditableColumns = JSON.parse(res.content.JsonData);
  })
}
}

export interface saveMedicationDTO {
  OrganizationId: Number;
  AccountId: Number;
  MedicationId: Number;
  MedicationCode: string;
  MedicationDescription: string;
  MedicationBrand: string;
  CaseType: string;
  ModifiedBy: string;
  IsActive: Boolean;
}
export interface casetypeDTO{
  CaseType:string  
  CaseTypeDisplayName:string  
}
export interface MedicationsDTO{
 
        Seq:Number
        MedicationId:Number
        MedicationCode:string
        MedicationDescription:string
        MedicationBrand:string
        CaseType:string
        CaseTypeDisplayName:string
        IsActive:Boolean
        ModifiedBy:string
        CreatedBy:string
        ModifiedDate:string
        CreatedDate:string
}
