import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-manage-json-editor',
  templateUrl: './manage-json-editor.component.html',
  styleUrls: ['./manage-json-editor.component.scss']
})
export class ManageJsonEditorComponent {
  [x: string]: any;
  isDirty: any;
  beautifiedJson: any

  constructor(public dialogRef: MatDialogRef<ManageJsonEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _snackBar: MatSnackBar) {
    this.getBeautifiedJson(data.jsonText)
  }

  onContinue() {
    this.isDirty = false;
    if (this.isValid) {
      this.dialogRef.close({ jsonText: this.beautifiedJson, result: this.isValid ? 'Success' : 'Failure' });
      this.canDeactivate()
    } else {
      this._snackBar.open("Please provide valid JSON", "Close");
      return
    }
  }

  canDeactivate(): boolean {
    return true
  }

  ngOnInit(): void {
    this.getBeautifiedJson(this.data.jsonText)
  }

  isJsonValid(inputJson) {
    try {
      let jsonObj = JSON.parse(inputJson);
      this.isValid = true;
      return this.isValid;
    }
    catch (e) {
      this.isValid = false;
      return this.isValid;
    }
  }

  getBeautifiedJson(inputJson, validate?: Boolean) {
    if (!this.isValid && validate) {
      this._snackBar.open("Please provide valid JSON", "Close");
      return
    }
    if (inputJson) {
      if (this.isJsonValid(inputJson)) {
        let jsonObj = JSON.parse(inputJson);
        let formattedJsonStr = JSON.stringify(jsonObj, null, 4);
        this.beautifiedJson = formattedJsonStr
      } else {
        this.beautifiedJson = inputJson
      }
    }
  }

}
