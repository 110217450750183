<div *ngIf="templateData && templateData.labels && gridwidth > 0" style="margin:10px;">
  <wj-flex-grid #gridcb [ngStyle]="{'width.px': gridwidth}" [itemsSource]="gridData" 
    [isReadOnly]="true" style="display: block; margin: auto !important;" (initialized)="initGrid(gridcb)">
    <!-- (click)="onClick(gridData)" -->
    <div *ngFor="let columnname of gridHeader">
      <wj-flex-grid-column [binding]="columnname" [header]="columnname.split('_').join(' ')" [width]="170"
        [allowResizing]="true"
        [visible]="!(columnname == 'Case_Id' || columnname == 'Entity_Name' || columnname == 'description' )"
        [allowDragging]="false" [format]="'d'">
      </wj-flex-grid-column>
    </div>
    <wj-flex-grid-filter #filter></wj-flex-grid-filter>
  </wj-flex-grid>
  <br />
  <div class="align-center">
    <wj-collection-view-navigator headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true"
      [cv]="gridData"> </wj-collection-view-navigator>
  </div>
  <div class="sub-button mt-3 align-center">
    <button type="button" mat-raised-button class="admin-btn-success"  (click)="exportExcel(gridcb)">
      Export
    </button>
  </div>
</div>
<div *ngIf="gridwidth == 0" class="nodata-wrap mt-4">
  <div class="nodata-design"> No Data Found</div>
</div>
