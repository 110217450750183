import { DatePipe, formatDate } from '@angular/common';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';
import { DataShareService } from '../../../core/services/datashare.service';
import { WjFlexGrid } from '@grapecity/wijmo.angular2.grid';
import * as wjcCore from '@grapecity/wijmo';
import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjCore from '@grapecity/wijmo';

declare var $: any;

@Component({
  selector: 'app-org-table',
  templateUrl: './org-table.component.html',
  styleUrls: ['./org-table.component.scss']
})

export class OrgTableComponent implements OnInit {
  [x: string]: any;
  userid: string = "";
  isDirty: any;
  orgid: string = "";
  gridData: any = [];
  selectedItems: any = [];
  OrganizationList: any[];
  UserList: any[];
  public ifOrgUpload = false;
  repeatedNames;
  checkStatus = false;
  searchInput: string = '';
  searchRoleInput: string = '';
  searchSiteInput: string = '';
  searchSiteTextInput: string = '';
  searchUserInput: string = '';
  public roletoggle: boolean = true;
  public ifNoRole: boolean = false;
  public roleSelected: string = '';
  toggle: boolean = false;
  usertoggle: boolean = false;
  public searchResult: Array<any> = [];
  public searchuserlist: Array<any> = [];
  public searchrolelist: Array<any> = [];
  public usersubscript: Subscription;
  public icdCodesList: any = [];
  attributesName: any;
  public rolesArray: any = [];
  selectedOrg: any = {};
  getTableData: boolean = false;
  SubMenuCardModel: any;
  public destSiteID: any;
  public DeploymentKeys = [];
  public finalData: any;
  public siteToggle: boolean = false;
  public selectedDeploymentKey;
  public srcDeployment;
  public showMsg: boolean = false;
  public NotConfiguredCasetype: boolean = false;
  public gridResult: any = [];
  public destRoles: any = [];
  public roleDiff: string;
  public siteFound: boolean = false;
  public ifNoSiteFound: boolean = false;
  @Input() dataToTakeAsInput: any;
  myDate: string = '';
  todaydate = new Date();
  postUpload: boolean = false;

  @ViewChild('flexgrid') flexgrid: WjFlexGrid;

  constructor(public dialogRef: MatDialogRef<OrgTableComponent>,
    private datashare: DataShareService,
    private datePipe: DatePipe, private dialog: MatDialog, private commonService: CommonService, public VitalHttpServices: VitalHttpServices, public _snackBar: MatSnackBar, public ngxService: NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      VitalHttpServices,
      datashare
    );
    this.DeploymentKeys = this.VitalHttpServices.DeploymentKeys;
    this.myDate = this.datePipe.transform(this.todaydate, 'yyyy-MM-dd HH:mm:ss.SSS');
  }

  public GetQuery(keyword: string) {
    let query: string = "";
    let mainQueryIndex: number = this.VitalHttpServices.mainQueryList.findIndex(mainQuery => mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase());
    if (mainQueryIndex > -1)
      query = this.VitalHttpServices.mainQueryList[mainQueryIndex].Query;
    return query;
  }

  ngOnInit(): void {
    if (this.dataToTakeAsInput.TableName.toString().toLowerCase() != 'organizationdiagtemplates' && this.dataToTakeAsInput.TableName.toString().toLowerCase() != 'organizationreportsdef' && this.dataToTakeAsInput.TableName.toString().toLowerCase() != 'organizationattributes') {
      this.getListOrg();
    } else if (this.dataToTakeAsInput.TableName.toString().toLowerCase() == 'organizationreportsdef') {
      this.destDeployment = sessionStorage.getItem('deploymentKey').toUpperCase();
      this.DeploymentKeys = this.DeploymentKeys.filter(item => item !== this.destDeployment);
      this.onChangeDeployment(this.destDeployment);

    }
    else {
      this.destDeployment = sessionStorage.getItem('deploymentKey').toUpperCase();
      this.DeploymentKeys = this.DeploymentKeys.filter(item => item !== this.destDeployment);
      this.onChangeDeployment(this.destDeployment);
    }
  }


  getSubSitesList(orgid) {
    //get sites list for the casetype
    let query = this.GetQuery('SubSitesListForRuleOuts');
    let queryVariable = { orgid: orgid, casetype: this.dataToTakeAsInput.CaseType }
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    let dbName = this.srcDeployment;
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(
      (Resdata) => {
        if (!Resdata.errors) {
          this.attributesName = JSON.parse(JSON.stringify(Resdata.data.submenuData));
          let allSite = {};
          allSite = {
            "SiteID": "all",
            "SiteName": "all"
          }
          this.attributesName.push(allSite);
        }
        this.ngxService.stop();
      }, (error) => {
        console.error(error);
        this.ngxService.stop();
      });
  }

  // to get the data in the select
  getListOrg() {
    this.OrganizationList = [];
    let dbName;
    let query = this.GetQuery('adduserfilterorg');
    let queryString = ""
    if (!this.dataToTakeAsInput.TableName.match(/organizationdiagtemplates/i)) {
      queryString = "organizationid !=\"" + sessionStorage.getItem('org_id') + "\""
    }
    // to send the selected Deployment key as the connection string only in Diagnosis template
    if (this.dataToTakeAsInput.TableName.toLowerCase() == 'organizationdiagtemplates' || this.dataToTakeAsInput.TableName.toLowerCase() == 'organizationattributes') {
      dbName = this.srcDeployment;
    }
    else {
      dbName = sessionStorage.getItem('deploymentKey').toUpperCase();
    }
    let queryVariable = { "filterParam": queryString };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(res => {
      if (res.data.Organizations_list && res.data.Organizations_list.length > 0) {
        this.OrganizationList = res.data.Organizations_list;
      }
      this.ngxService.stop();
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }

  // upload to store the data in the DB
  uploadData(flexgrid, allowForSave?) {
    let excelData = flexgrid || this.flexgrid;
    let selectedData = [];
    this.selector = new Selector(excelData, {
      itemChecked: () => {
        this.selectedItems = excelData.rows.filter(r => r.isSelected);
      }
    });
    for (let i = 0; i < this.selectedItems.length; i++) {
      selectedData.push(this.selectedItems[i]._data);
    }
    this.finalData = selectedData;
    if (!allowForSave) {
      if (this.dataToTakeAsInput.TableName.toLowerCase() == 'organizationdiagtemplates') {
        this.validateCasetypes(selectedData);
      } else if (this.dataToTakeAsInput.TableName.toLowerCase() == 'organizationreportsdef') {
        this.validateRoles(selectedData);
      } else if (this.dataToTakeAsInput.TableName.toLowerCase() == 'organizationattributes') {
        this.validateSubSites(selectedData);
      }
      else {
        this.SaveOrgCopyData(selectedData);
      }
    } else {
      this.SaveOrgCopyData(selectedData);
    }

  }

  validateSubSites(data) {
    let query = this.GetQuery('SubSitesListForRuleOuts');
    let queryVariable = { orgid: this.dataToTakeAsInput.orgid, casetype: this.dataToTakeAsInput.CaseType }
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    let dbName = this.srcDeployment;
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(
      (Resdata) => {
        if (!Resdata.errors) {
          let subsites;
          this.siteFound = false;
          subsites = JSON.parse(JSON.stringify(Resdata.data.submenuData));
          for (var i = 0; i < subsites.length; i++) {
            if (subsites[i].SiteName.toLowerCase() == this.searchSiteTextInput.toLowerCase()) {
              this.siteFound = true;
              break;
            }
          }
          if (this.searchSiteTextInput.toLowerCase() == 'all') {
            this.siteFound = true;
          }
          if (!this.siteFound) {
            this.ifNoSiteFound = true;
          } else {
            this.ifNoSiteFound = false;
            this.SaveOrgCopyData(data);
          }
        }
        this.ngxService.stop();
      }, (error) => {

        console.error(error);
        this.ngxService.stop();
      });

  }
  removeDuplicates(arr) {
    let unique_array = []
    for (let i = 0; i < arr.length; i++) {
      if (unique_array.indexOf(arr[i]) == -1) {
        unique_array.push(arr[i])
      }
    }
    return unique_array
  }

  validateRoles(selectedData) {
    let queryResult: any;
    let queryVariable;
    let card;
    if (this.dataToTakeAsInput.cardType.toLowerCase() == 'laboratory') {
      card = 'Laboratory';
    } else if (this.dataToTakeAsInput.cardType.toLowerCase() == 'facility') {
      card = 'Ordering Facility';
    } else if (this.dataToTakeAsInput.cardType.toLowerCase() == 'epg') {
      card = 'Pathologists';
    }
    let query = this.GetQuery('getuserrolesforassociationtype');
    let Qstr = '';
    Qstr += "associationtype = \"" + card + "\"";

    queryVariable = { "associationtype": Qstr };
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    let dbName = sessionStorage.getItem('deploymentKey').toUpperCase();;
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(res => {
      if (!res.errors) {

        if (res.data.vwGetRolesBasedOnAccountAssociation_list.length > 0) {
          this.rolesdata = [];
          this.rolesdata = res.data.vwGetRolesBasedOnAccountAssociation_list; //unsorted
          //to get unique roles from array
          let rolesarray = [];
          this.rolesdata.forEach(element => {
            if (rolesarray.length > 0) {
              let foundRoleDuplicate = false;
              for (let kk = 0; kk < rolesarray.length; kk++) {
                let role: string = element.rolename;
                if (rolesarray[kk].rolename === role) {
                  foundRoleDuplicate = true;
                  break;
                }
              }
              if (foundRoleDuplicate == false) {
                //to get unique roles from array
                rolesarray.push(element);
              }
            }
            else {
              rolesarray.push(element);
            }

          });
          this.destRoles = rolesarray;
          let tempArray = [];
          for (let i = 0; i < this.destRoles.length; i++) {
            tempArray.push(this.destRoles[i].rolename)
          }
          let arr = [];
          for (var j = 0; j < selectedData.length; j++) {
            if (selectedData[j].Role_Name != 'Not Specified' && selectedData[j].Role_Name != 'null' && selectedData[j].Role_Name != null && selectedData[j].Role_Name != undefined) {
              arr.push(selectedData[j].Role_Name);
            }
          }
          let difference = arr.filter(x => tempArray.indexOf(x) === -1);
          if (difference.length > 0) {
            difference = this.removeDuplicates(difference);
            this.roleDiff = difference.toString();
            this.ifNoRole = true;
          } else {
            this.ifNoRole = false;
            this.SaveOrgCopyData(selectedData);
          }
        }
      }
      else {
        console.error(res.error);
      }
    });
  }

  close(flexgrid?, allowForSave?) {
    this.ifNoRole = false;
    this.ifNoSiteFound = false;
    if (allowForSave) {
      this.uploadData(flexgrid, allowForSave)
    }
  }

  validateCasetypes(selectedData) {
    if (sessionStorage.getItem('search_context').toLowerCase() == 'users' || sessionStorage.getItem('search_context').toLowerCase() == 'pathologist') {
      this.VitalHttpServices.deploymentKey = this.destDeployment;
      let query = this.GetQuery('getorgcasetypes');
      let queryVariable = { "orgid": this.dataToTakeAsInput.OrganizationID.toString() };
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      this.ngxService.start();
      this.VitalHttpServices.GetData(queryResult).subscribe(res => {
        this.VitalHttpServices.deploymentKey = this.srcDeployment
        if (!res.errors) {
          let tempArray = [];
          for (let i = 0; i < res.data.submenuData.length; i++) {
            tempArray.push(res.data.submenuData[i].casetype)
          }
          let configuredCasetype = [];
          configuredCasetype = selectedData.filter(item => tempArray.includes(item.Casetype))
          let invalidCasetype = [];
          let temp = [];
          invalidCasetype = selectedData.filter(item => !tempArray.includes(item.Casetype))
          for (let i = 0; i < invalidCasetype.length; i++) {
            temp.push(invalidCasetype[i].Casetype)
          }
          let displayInvalidCasetype = [...new Set(temp)];
          if (displayInvalidCasetype.length > 0) {
            this.repeatedNames = '';
            let str = '';
            for (let i = 0; i < displayInvalidCasetype.length; i++) {
              str += displayInvalidCasetype[i] + ', '
            }
            if (configuredCasetype.length > 0) {
              this.SaveOrgCopyData(configuredCasetype);
            }
            this.repeatedNames = str.substring(0, str.length - 2);
            this.ifOrgUpload = true;
          }
          else {
            if (configuredCasetype.length > 0) {
              this.SaveOrgCopyData(configuredCasetype);
            }
          }
        }
        this.ngxService.stop();
      }, error => {
        console.error(error);
        this.ngxService.stop();
      });
    }
    else {
      selectedData = selectedData.map(({Notes,...rest}) => ({...rest}))
      this.SaveOrgCopyData(selectedData);
    }
  }


  getOrgList() {

    let keyword;
    if (this.dataToTakeAsInput.cardType.toLowerCase() == 'laboratory') {
      keyword = 'getlaborganizations';
      //card = 'Laboratories';
    } else if (this.dataToTakeAsInput.cardType.toLowerCase() == 'facility') {
      keyword = 'getOForganizations';
    } else if (this.dataToTakeAsInput.cardType.toLowerCase() == 'epg') {
      keyword = 'getEPGorganizations';
    }
    let query = this.GetQuery(keyword);
    let queryVariable = {};
    let dbName = this.srcDeployment;
    var drpquery = this.VitalHttpServices.mainQueryList.filter(x => x.Keyword == keyword);
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": {}
    };
    this.getRoles(dbName);
    let queryResult = this.commonService.GetCardRequest(drpqueryRequest, query);
    this.ngxService.start();
    let orgArr = [];
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(res => {
      this.ngxService.stop();
      let result;
      if (keyword == 'getlaborganizations') {
        result = res.data.vwGetLabOrganizations_list;
      } else if (keyword == 'getOForganizations') {
        result = res.data.vwGetOFOrganizations_list;
      } else if (keyword == 'getEPGorganizations') {
        result = res.data.vwGetEPGOrganizations_list;
      }

      for (var i = 0; i < result.length; i++) {
        let arr = {};
        arr["organizationname"] = result[i].OrgName;
        arr["organizationid"] = result[i].OrganizationId;
        orgArr.push(arr);
      }

      // to remove duplicate accounts
      let keys = ['organizationname', 'organizationid'];
      let filtered = orgArr.filter(
        (s => o =>
          (k => !s.has(k) && s.add(k))
            (keys.map(k => o[k]).join('|'))
        )
          (new Set)
      );
      this.OrganizationList = filtered;
    });
  }

  checkValue(event) {
    if (event.target.checked) {
      this.checkStatus = true;
      if (this.dataToTakeAsInput.TableName.toLowerCase() == 'organizationreportsdef') {
        this.showMsg = true;
      }
    } else {
      this.checkStatus = false;
      if (this.dataToTakeAsInput.TableName.toLowerCase() == 'organizationreportsdef') {
        this.showMsg = false;
      }
    }
  }


  generateGUID() {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  getDestinationSubSites() {
    let query = this.GetQuery('SubSitesListForRuleOuts');
    let queryVariable = { orgid: this.dataToTakeAsInput.OrganizationID, casetype: this.dataToTakeAsInput.CaseType }
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    let dbName = this.destDeployment;
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(
      (Resdata) => {
        if (!Resdata.errors) {
          let siteList = JSON.parse(JSON.stringify(Resdata.data.submenuData));
          for (var i = 0; i < siteList.length; i++) {
            if (siteList[i].SiteName.toLowerCase() == this.searchSiteTextInput.toLowerCase()) {
              this.destSiteID = siteList[i].SiteID;
              break;
            }
          }
        }
        this.ngxService.stop();
      }, (error) => {

        console.error(error);
        this.ngxService.stop();
      });
  }

  // to generate save json
  generateSaveJson(selectedData) {
    let columns = '';
    let chilcColumns = '';
    let childTableName;
    for (let j = 0; j < this.dataToTakeAsInput.ColumnNames.length; j++) {
      columns += this.dataToTakeAsInput.ColumnNames[j] + ','
    }
    columns = columns.substring(0, columns.length - 1);
    let dxUserId = 'null';
    let arrayData = [];
    let selectedRows = [];
    let roleName = [];
    let userid = (sessionStorage.getItem('Userid') == null || sessionStorage.getItem('Userid') == '') ? -100 : sessionStorage.getItem('Userid');
    let currentDate = new Date();
    let transformedDate = formatDate(currentDate, 'yyyy/MM/dd', 'en');
    if (this.dataToTakeAsInput.TableName.toString().toLowerCase() == "organizationreportsdef") {
      for (let i = 0; i < this.dataToTakeAsInput.ChildColumNNames.length; i++) {
        chilcColumns += this.dataToTakeAsInput.ChildColumNNames[i] + ','
      }
      chilcColumns = chilcColumns.substring(0, chilcColumns.length - 1);
    }
    let reportIDarr = [];
    let repvalue = "";
    let count = 0;
    let rolarr = [];
    for (let i = 0; i < selectedData.length; i++) {
      let value = "";
      let childValue = "";
      if (this.dataToTakeAsInput.TableName.toString().toLowerCase() == "organizationgrosstemplates") {
        let Name = selectedData[i].Name.replace(/'/g, '`');
        let Description = selectedData[i].Description.replace(/'/g, '`');
        let isDefault = (selectedData[i].Default_Template == true) ? 1 : 0;
        let isActive = (selectedData[i].Active) ? 1 : 0;
        isActive = this.checkStatus ? 0 : isActive
        let Sequence = (selectedData[i].Sequence.toString().toLowerCase() == 'not specified') ? 50 : selectedData[i].Sequence
        value = value + "'" + Name + "'," + this.dataToTakeAsInput.OrganizationID + ",'" + selectedData[i].Casetype + "','" + Description + "'," + Sequence + "," + isDefault + "," + isActive + "," + userid + "," + transformedDate + "," + userid + "," + transformedDate;
      }
      else if (this.dataToTakeAsInput.TableName.toString().toLowerCase() == "organizationcommentstemplates") {
        let Name = selectedData[i].Name.replace(/'/g, '`');
        let Description = selectedData[i].Description.replace(/'/g, '`');
        let isActive = (selectedData[i].Active) ? 1 : 0;
        isActive = this.checkStatus ? 0 : isActive
        let Sequence = (selectedData[i].Sequence.toString().toLowerCase() == 'not specified') ? 50 : selectedData[i].Sequence
        value = value + "'" + Name + "'," + this.dataToTakeAsInput.OrganizationID + ",'" + selectedData[i].Type + "','" + selectedData[i].Casetype + "','" + Description + "'," + Sequence + "," + isActive + "," + userid + "," + transformedDate + "," + userid + "," + transformedDate;
      }
      else if (this.dataToTakeAsInput.TableName.toString().toLowerCase() == 'templates') {
        let productName = selectedData[i].Product_Name.replace(/'/g, '`');
        let context = selectedData[i].Context.replace(/'/g, '`')
        let mode = selectedData[i].Mode.replace(/'/g, '`')
        let contexttype = selectedData[i].Context_Type.replace(/'/g, '`')
        let template = selectedData[i].Template.replace(/'/g, '`')
        let templateType = selectedData[i].Template_Type.replace(/'/g, '`')
        let displayName = selectedData[i].Display_Name.replace(/'/g, '`')
        value = value + "'" + template + "','" + context + "','" + mode + "','" + contexttype + "','" + displayName + "','" + templateType + "'," + this.dataToTakeAsInput.accountid.toString() + ",'" + productName + "'," + userid + "," + transformedDate + "," + this.dataToTakeAsInput.OrganizationID;
      } else if (this.dataToTakeAsInput.TableName.toString().toLowerCase() == "organizationattributes") {
        let primarys = {}
        let userid = (sessionStorage.getItem('Userid') == null || sessionStorage.getItem('Userid') == '') ? -100 : sessionStorage.getItem('Userid');


        selectedData[i].attributename = selectedData[i].RuleOut;
        delete selectedData[i].RuleOut;

        selectedData[i].attributecontext = selectedData[i].CaseType;
        delete selectedData[i].CaseType;
        let isActive = (selectedData[i].Active) ? 1 : 0;
        isActive = this.checkStatus ? 0 : isActive
        selectedData[i].Status = isActive;
        delete selectedData[i].Active;
        delete selectedData[i].SubSite;
        delete selectedData[i].Sequence;

        if (this.searchSiteTextInput == "all") {
          selectedData[i].Action = "all";
          selectedData[i].attributecontextid = "all";
        } else {
          selectedData[i].Action = "add";
          selectedData[i].attributecontextid = this.destSiteID;
        }
        selectedData[i].SequenceOrder = 0;
        selectedData[i].CreatedBy = userid;
        selectedData[i].ModifiedBy = userid;
        selectedData[i].organizationid = this.dataToTakeAsInput.OrganizationID;
        selectedData[i].AttributeID = 0;

      }
      else if (this.dataToTakeAsInput.TableName.toString().toLowerCase() == "organizationdiagtemplates") {
        Object.keys(selectedData[i]).forEach(key => {
          if (key == 'IsUserLevelTemplateEnabled' || key == 'AllowCytotechnologistToSignoutCases' || key == 'AllowCytotechSupervisorToSignoutCases'
            || key == 'isGroup' || key == 'Active' || key == 'Billable' || key == 'Hidden' || key == 'Benign' || key == 'IsSiteNotSurvivedTemplate'
            || key == 'IsSiteNotSubmittedTemplate' || key == 'Report_to_Registry' || key == 'Abnormal') {
            if (selectedData[i][key] === 'Not Specified' || selectedData[i][key] === '' || selectedData[i][key] === null) {
              selectedData[i][key] = 0;
            }
            if (selectedData[i][key] == true) {
              selectedData[i][key] = 1;
            }
            else {
              selectedData[i][key] = 0;
            }
          }
          if (selectedData[i][key] === 'Not Specified' || selectedData[i][key] === null) {
            selectedData[i][key] = '';
          }
          if (key == 'Display_Order' || key == 'Severity_Order' || key == 'Site_Group_Order' || key == 'Site_ID' || key == 'Org_Parent_ID') {
            if (selectedData[i][key] === 'Not Specified' || selectedData[i][key] === '' || selectedData[i][key] === null) {
              selectedData[i][key] = 'null';
            }
          }
        });
        let isActive = (selectedData[i].Active) ? 1 : 0;
        selectedData[i].Active = this.checkStatus ? 0 : isActive
        value = "";
        selectedRows.push(selectedData[i]);
        if (sessionStorage.getItem('search_context').toLowerCase() == 'users' || sessionStorage.getItem('search_context').toLowerCase() == 'pathologist') {
          dxUserId = (!sessionStorage.getItem('DxUserId') || sessionStorage.getItem('DxUserId') == '') ? 'null' : sessionStorage.getItem('DxUserId');
        }
        else {
          dxUserId = 'null';
        }
      }
      else if (this.dataToTakeAsInput.TableName.toString().toLowerCase() == "organizationreportsdef") {
        let gridRow = [];
        gridRow = this.gridResult.filter(
          data => (data.Report_ID == selectedData[i].Report_ID)
        );

        if (count == 0) {
          for (var k = 0; k < this.gridResult.length; k++) {
            for (var l = 0; l < selectedData.length; l++) {
              if (this.gridResult[k].Report_ID == selectedData[l].Report_ID && this.gridResult[k].Role_Name == selectedData[l].Role_Name) {
                let tmpobj = {
                  reportid: selectedData[l].Report_ID,
                  rolename: selectedData[l].Role_Name,
                }
                rolarr.push(tmpobj)
              }
            }
          }
          count = 1;
        }
        for (var j = 0; j < gridRow.length; j++) {
          Object.keys(gridRow[j]).forEach(key => {
            if (key == 'Include_Header' || key == 'IsAsynchronous_Report' || key == 'IsScheduled_Report') {
              if (gridRow[j][key] === 'Not Specified' || gridRow[j][key] === '' || gridRow[j][key] === null) {
                gridRow[j][key] = 0;
              }
              if (gridRow[j][key] == true) {
                gridRow[j][key] = 1;
              }
              else {
                gridRow[j][key] = 0;
              }
            }
            if (gridRow[j][key] === 'Not Specified' || gridRow[j][key] === null) {
              gridRow[j][key] = '';
            }
            if (key == 'Name' || key == 'Description' || key == 'Template_File' || key == 'Version' || key == 'Criteria_Display_Names' || key == 'ApproxGenerationTime' || key == 'OrganizationReportsDefGUID'
              || key == 'MaxDays' || key == 'ShowMaxRecord' || key == 'Cron_Expression' || key == 'Role_Name' || key == 'Cron_Description') {
              if (gridRow[j][key] === 'Not Specified' || gridRow[j][key] === '' || gridRow[j][key] === null) {
                gridRow[j][key] = 'null';
              }
            }
          });
          let title = gridRow[j].Title.replace(/'/g, '`');
          let name = gridRow[j].Name.replace(/'/g, '`');
          let description = gridRow[j].Description.replace(/'/g, '`');
          let category = gridRow[j].Category.replace(/'/g, '`');
          let storedProcedure = gridRow[j].Stored_Procedure.replace(/'/g, '`');
          let templateFile = gridRow[j].Template_File.replace(/'/g, '`');
          let namedRange = gridRow[j].Named_Range.replace(/'/g, '`');
          let criteria = gridRow[j].Criteria.replace(/'/g, '`');
          let includeHeader = (gridRow[j].Include_Header == true) ? 1 : 0;
          let version = gridRow[j].Version;
          let IsScheduledReport = (gridRow[j].IsScheduled_Report == true) ? 1 : 0;
          let CriteriaDisplayNames = gridRow[j].Criteria_Display_Names.replace(/'/g, '`');
          let IsAsynchronousReport = (gridRow[j].IsAsynchronous_Report == true) ? 1 : 0;
          let ApproxGenerationTime = gridRow[j].ApproxGenerationTime;
          let OrganizationReportsDefGUID = null;
          if (OrganizationReportsDefGUID == 'null' || OrganizationReportsDefGUID == null || OrganizationReportsDefGUID == '' || OrganizationReportsDefGUID == undefined) {
            OrganizationReportsDefGUID = this.generateGUID();
          }
          let CreatedBy = userid;
          let CreatedDate = this.myDate;
          let ModifiedBy = userid;
          let ModifiedDate = this.myDate;
          let organizationID = this.dataToTakeAsInput.OrganizationID;
          let ConnectionName;
          let IsMultiSheetEnabled = gridRow[j].IsMultiSheetEnabled == true ? 1 : 0;
          let IsHTMLReport = gridRow[j].IsHTMLReport== true ? 1 : 0;
          let MaxDays = gridRow[j].MaxDays;
          let ShowMaxRecord = gridRow[j].ShowMaxRecord;
          let isactive = this.checkStatus ? 0 : 1;
          let repVal = [];
          if (reportIDarr.length > 0) {
            repVal = reportIDarr.filter(
              data => data.Report_ID == gridRow[j].Report_ID
            );
          }
          let temp = {
            'Report_ID': gridRow[j].Report_ID
          }
          reportIDarr.push(temp);

          if (repVal.length == 0) {
            roleName = [];
            let roledata = rolarr.filter(
              data => (data.reportid == selectedData[i].Report_ID)
            );
            if (roledata.length > 0) {
              for (var n = 0; n < roledata.length; n++) {
                roleName.push(roledata[n].rolename);
              }
            }
            ConnectionName = gridRow[j].Connection_Name.replace(/'/g, '`');
            value = value + "'" + title + "','" + name + "','" + description + "','" + category + "','" + storedProcedure + "','" + templateFile + "','" + namedRange + "','" + criteria + "'," + includeHeader + "," + version + "," + IsScheduledReport + ",'" +
              CriteriaDisplayNames + "'," + IsAsynchronousReport + "," + ApproxGenerationTime + ",'" + OrganizationReportsDefGUID + "'," + MaxDays + "," + ShowMaxRecord + "," + CreatedBy + ",'" + ModifiedBy + "','" + CreatedDate + "','" + ModifiedDate + "'," +
              organizationID + "," + IsMultiSheetEnabled + ",'" + ConnectionName + "'," + IsHTMLReport + "," + isactive;
          }

          childTableName = this.dataToTakeAsInput.ChildTableName;
          childValue = roleName.toString();

        }

      }
      if (this.dataToTakeAsInput.TableName.toString().toLowerCase() == "organizationreportsdef") {
        if (value != "") {
          arrayData.push({
            "tableName": this.dataToTakeAsInput.TableName,
            "columns": columns,
            "values": value,
            "tableName1": childTableName,
            "columns1": chilcColumns,
            "values1": childValue,
            "InActive": this.checkStatus
          });
        }
      }
      else if (this.dataToTakeAsInput.TableName.toString().toLowerCase() != "organizationattributes") {
        arrayData.push({
          "tableName": this.dataToTakeAsInput.TableName,
          "columns": columns,
          "values": value,
          "tableName1": childTableName,
          "columns1": chilcColumns,
          "values1": childValue,
          "InActive": this.checkStatus
        });
      }
    }
    if (this.dataToTakeAsInput.TableName.toString().toLowerCase() == "organizationattributes") {
      arrayData.push({
        "tableName": this.dataToTakeAsInput.TableName,
        "RuleOutdata": selectedData
      });
    }


    let sendJson = {
      "loggedInUserId": (!sessionStorage.getItem('Userid') || sessionStorage.getItem('Userid') == '') ? -100 : sessionStorage.getItem('Userid'),
      "entityType": "Added New",
      "entityID": "",
      "actionperformed": "Copying data to org",
      "actiondetails": "Copying data to org",
      "entitydata": "",
      "auditDate": transformedDate,
      "organizationID": sessionStorage.getItem('org_id'),
      "inserttotable": arrayData,
      "dataRows": selectedRows,
      "deploymentkey": this.destDeployment,
      "dxUserId": dxUserId
    };
    this.copyDatatoOrg(sendJson);
  }

  //#region Validates duplicate Custom Report
  async validateCustomReport(srcOrgData, isPostUpload?: boolean) {
    if (this.dataToTakeAsInput.TableName.toString().toLowerCase() == 'organizationreportsdef') {
      sessionStorage.setItem('deploymentKey', this.destDeployment.toLowerCase());
    }

    let dbName = sessionStorage.getItem('deploymentKey').toUpperCase();
    this.repeatedNames = "";
    let siteid = "";
    await this.VitalHttpServices.getOrgDataForCopy(this.dataToTakeAsInput.OrganizationID, this.dataToTakeAsInput.CaseType, this.userid, this.dataToTakeAsInput.TableName, this.searchRoleInput, siteid, this.searchSiteTextInput, dbName).toPromise().then(parentOrgData => {
      if (!parentOrgData.errors) {
        let destOrgData
        destOrgData = parentOrgData.filter((element) => {
          return element.Title !== null;
        })
        for (let i = 0; i < srcOrgData.length; i++) {
          if (destOrgData.some(x => x.Title.toString().toLowerCase() == srcOrgData[i]["Title"].toString().toLowerCase() &&
            x.Category.toString().toLowerCase() == srcOrgData[i]["Category"].toString().toLowerCase())) {
            srcOrgData[i]["Notes"] = "Custom Report already exists";
          } else {
            srcOrgData[i]["Notes"] = "Will be added on approval";
          }
        }
        this.AddGridData(srcOrgData);
      }
    })
  }
  //#endregion

  // save copy from other Entity
  async SaveOrgCopyData(data) {
    let filteredData = [];
    let dbName = sessionStorage.getItem('deploymentKey').toUpperCase();
    this.repeatedNames = "";
    let siteid = "";
    await this.VitalHttpServices.getOrgDataForCopy(this.dataToTakeAsInput.OrganizationID, this.dataToTakeAsInput.CaseType, this.userid, this.dataToTakeAsInput.TableName, this.searchRoleInput, siteid, this.searchSiteTextInput, dbName).toPromise().then(async parentOrgData => {
      if (!parentOrgData.errors) {

        let orgData
        let tempData
        if (this.dataToTakeAsInput.TableName.toString().toLowerCase() != 'organizationreportsdef') {
          if (this.dataToTakeAsInput.TableName.toString().toLowerCase() == 'organizationattributes') {
            orgData = parentOrgData.filter((element) => {
              return element.RuleOut !== null;
            })
          }
          else if (this.dataToTakeAsInput.TableName.toString().toLowerCase() != 'templates') {
            orgData = parentOrgData.filter((element) => {
              return element.Name !== null;
            })
          }
          else {
            orgData = parentOrgData.filter((element) => {
              return element.Display_Name !== null;
            })
          }
          for (let i = 0; i < orgData.length; i++) {
            if (this.dataToTakeAsInput.TableName.toString().toLowerCase() == 'organizationdiagtemplates') {
              filteredData = [];
            }
            else if (this.dataToTakeAsInput.TableName.toString().toLowerCase() == 'organizationattributes') {
              tempData = orgData[i].RuleOut.toString().toLowerCase().trim();
              if (data.filter(parentData => parentData['RuleOut'].toLowerCase().trim() === tempData).length > 0)
                filteredData.push(data.filter(parentData => parentData['RuleOut'].toLowerCase().trim() === tempData));
  
            }
            else if (this.dataToTakeAsInput.TableName.toString().toLowerCase() != 'templates') {
              tempData = orgData[i].Name.toString().toLowerCase().trim();
              if (data.filter(parentData => parentData['Name'].toLowerCase().trim() === tempData).length > 0)
                filteredData.push(data.filter(parentData => parentData['Name'].toLowerCase().trim() === tempData));
            }
            else {
              tempData = orgData[i].Display_Name.toString().toLowerCase().trim();
              if (data.filter(parentData => parentData['DisplayName'] && parentData['DisplayName'].toLowerCase().trim() === tempData).length > 0)
                filteredData.push(data.filter(parentData => parentData['DisplayName'] && parentData['DisplayName'].toLowerCase().trim() === tempData));
            }
          }
        }
        if (filteredData && filteredData.length > 0) {
          let repeatedNames1 = '';
          let repArr = [];
          for (let i = 0; i < filteredData.length; i++) {
            if (this.dataToTakeAsInput.TableName.toString().toLowerCase() == 'organizationattributes') {
              repeatedNames1 += filteredData[i][0].RuleOut.toString() + ','
            }
            else if (this.dataToTakeAsInput.TableName.toString().toLowerCase() != 'templates') {
              repeatedNames1 += filteredData[i][0].Name.toString() + ','
            }
            else {
              repeatedNames1 += filteredData[i][0].Display_Name.toString() + ','
            }
          }
          this.repeatedNames = repeatedNames1.substring(0, repeatedNames1.length - 1);
          this.ifOrgUpload = true;
          return
        }
        else {
          this.generateSaveJson(data);
        }
      }
    }, error => {
      console.error(error);
      this._snackBar.open('Something went wrong.Please try again', 'Close');
    });
  }

  // copy data to org
  async copyDatatoOrg(data) {
    this.ngxService.start();
    await this.VitalHttpServices.SaveOrgCopyData(data).toPromise().then(result => {
      if (!result.errors) {
        if (result.Success == false) {
          this._snackBar.open(result.Message, 'Close');
        }
        else {
          this.ifOrgUpload = false;
          this.postUpload = true;
          this._snackBar.open(result.Message, 'Success');
          data['Status'] = result.Message;
          if (this.dataToTakeAsInput.TableName.toString().toLowerCase() == "organizationreportsdef") {
            this.commonService.auditDetails('', '', [], data.inserttotable, 'Copy', this.dataToTakeAsInput.templateData,{});
            this.onNoClick();
            let arrData = [];
            let col;
            let childcol;
            for (let i = 0; i < this.dataToTakeAsInput.ChildColumNNames.length; i++) {
              childcol += this.dataToTakeAsInput.ChildColumNNames[i] + ','
            }
            childcol = childcol.substring(0, childcol.length - 1);
            for (let j = 0; j < this.dataToTakeAsInput.ColumnNames.length; j++) {
              col += this.dataToTakeAsInput.ColumnNames[j] + ','
            }
            col = col.substring(0, col.length - 1);
            arrData.push({
              "tableName": this.dataToTakeAsInput.TableName,
              "columns": col,
              "values": "",
              "tableName1": this.dataToTakeAsInput.childTableName,
              "columns1": childcol,
              "values1": ""
            });
            let oldJson = {
              "loggedInUserId": (!sessionStorage.getItem('Userid') || sessionStorage.getItem('Userid') == '') ? -100 : sessionStorage.getItem('Userid'),
              "entityType": "Added New",
              "entityID": "",
              "actionperformed": "Copying data to org",
              "actiondetails": "Copying data to org",
              "entitydata": "",
              "auditDate": this.myDate,
              "organizationID": sessionStorage.getItem('org_id'),
              "inserttotable": arrData,
              "dataRows": "",
              "deploymentkey": this.destDeployment,
              "dxUserId": ""
            };
          } else {
            /* For custom Report audit is throwing error cause its not implemented yet so moved this below line here */
            this.commonService.auditDetails('', '', [], data.inserttotable, 'Copy', this.dataToTakeAsInput.templateData, this.dataToTakeAsInput.auditableColumns);
          }
        }
      }
      this.ngxService.stop();
    }, error => {
      this._snackBar.open("An error occurred while processing your request", 'Failed');
      console.info(error);
    });
  }

  onNoClick() {
    this.isDirty = false;
    this.dialogRef.close('true');
    this.canDeactivate()
    if (this.dataToTakeAsInput.TableName.toString().toLowerCase() == 'organizationdiagtemplates' || this.dataToTakeAsInput.TableName.toString().toLowerCase() == 'organizationreportsdef') {
      this.VitalHttpServices.deploymentKey = this.destDeployment.toUpperCase();
    }
  }

  // warning model close
  closeModel() {
    this.ifOrgUpload = false;
  }

  canDeactivate(): boolean {
    return true
  }

  // to get data for copy Entity
  async getDataToCopy(orgid, userid, dataToTakeAsInput) {
    this.ngxService.start();
    this.selectedItems = [];
    this.checkStatus = false;
    this.showMsg = false;
    let roleSel;
    let dbName;
    let siteId;
    let siteName;
    if (this.dataToTakeAsInput.TableName.toString().toLowerCase() == 'organizationreportsdef') {
      this.postUpload = false;
      roleSel = this.searchRoleInput;
      dbName = this.srcDeployment;
      siteId = "";
      siteName = "";
    } else if (this.dataToTakeAsInput.TableName.toString().toLowerCase() == 'organizationattributes') {
      roleSel = "";
      siteId = this.searchSiteInput;
      dbName = this.srcDeployment;
      siteName = "";
      this.getDestinationSubSites();
    }
    else {
      roleSel = "";
      siteId = "";
      dbName = sessionStorage.getItem('deploymentKey').toUpperCase();;
      siteName = "";
    }
    await this.VitalHttpServices.getOrgDataForCopy(orgid, dataToTakeAsInput.CaseType, userid, dataToTakeAsInput.TableName, roleSel, siteId, siteName, dbName).toPromise().then(async data => {
      this.ngxService.stop();
      if (data && data.length > 0) {
        this.userid = '';
        this.getTableData = true;
        this.gridResult = data;
        if (this.dataToTakeAsInput.TableName.toString().toLowerCase() == 'organizationreportsdef') {
          let activeData = data.filter(va => va.Status == true)
          await this.validateCustomReport(activeData);
        } else {
          this.AddGridData(data);
        }
      } else {
        if (dataToTakeAsInput.TableName.match(/organizationdiagtemplates/i) && this.searchUserInput != '' && userid != '') {
          this._snackBar.open("No templates for the selected user", "Close")
        }
        this.getTableData = true;
        this.AddGridData([])
      }
    })
  }

  // table to get the data from db
  AddGridData(data) {
    let gridarray = []
    let primary = {}
    this.columnDefs = this.dataToTakeAsInput.columnDefs
    if (data) {
      if (data.length > 0) {
        if (this.dataToTakeAsInput.TableName.toString().toLowerCase() == 'organizationdiagtemplates') {
          if (data[0].StatusMessage.toString().toLowerCase() == 'casetype not configured') {
            data = [];
          }
        }
      }
    }
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {}
          for (let [key, value] of Object.entries(data[i])) {
            for (let j = 0; j < this.columnDefs.length; j++) {
              if (key === this.columnDefs[j]) {
                value = (value == null) ? 'Not Specified' : value;
                primary[key] = value
              }
            }
          }
          gridarray.push(primary)
        }

        gridarray.sort((e, f) => (e.Site > f.Site) ? 1 : -1);
        if (gridarray.length > 10) {
          this.gridShowDiv = true;
          this.hideGrid = false;
          this.showPagination = true;
        }
        else {
          this.showPagination = false;
        }
        this.gridData = this.dataToTakeAsInput.TableName.toString().toLowerCase() == 'templates' ? new CollectionView(gridarray) : new CollectionView(gridarray, { pageSize: 10 });
        if (gridarray.length > 10) {
          this.showPaginationMainGrid = true;
        } else {
          this.showPaginationMainGrid = false;
        }
        this.gridwidth = (170 * this.columnDefs.length) + 37;
        if (this.gridwidth > 1300)
          this.gridwidth = 1300;
      } else {
        this.gridData = new CollectionView([]);
        this.gridwidth = 0;
      }
    }
  }

  fetchSeries(value: string) {
    this.searchResult = []
    if (value === '') {
      return (this.searchResult = []);
    }
    this.toggle = false;
    let idValidate = /^[0-9]*$/

    if (!idValidate.test(value)) {
      if (value.length > 2) {
        this.toggle = true
        this.searchResult = this.OrganizationList.filter(function (series) {
          if (series && series.organizationname != null) {
            return series.organizationname.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchResult = this.searchResult.splice(0, 25);
      }
    }
    else {
      this.toggle = true;
      this.searchResult = this.OrganizationList.filter(function (series) {
        if (series && series.organizationid != null) {
          return series.organizationid.toString().toLowerCase().includes(value.toLowerCase());
        }
      });
      this.searchResult = this.searchResult.splice(0, 25);
    }
  }

  initializeGrid(flexgrid) {
    flexgrid.collapseGroupsToLevel(0);
    setTimeout(function () {
      if (this.flexgrid) this.flexgrid.refresh(true);
    });
    if (this.dataToTakeAsInput.TableName.toString().toLowerCase() == 'organizationreportsdef') {
      flexgrid.columns.getColumn("Report_ID").visible = false;
    }

    this.selector = new Selector(flexgrid, {
      itemChecked: () => {
        var va = flexgrid.rows.filter((r) => r.isSelected);
        this.selectedItems = va;
        this.idsArray = [];
        for (
          let i = 0;
          i < this.selectedItems[this.selectedItems.length - 1].length;
          i++
        ) {
          this.idsArray.push(
            this.selectedItems[this.selectedItems.length - 1][i]._data.ID
          );
        }
      },
    });

    flexgrid.formatItem.addHandler((s: any, e: wjcGrid.FormatItemEventArgs) => {
      // center-align column headers
      if (e.panel == s.columnHeaders) {
        e.cell.innerHTML =
          '<div class="v-center">' + e.cell.innerHTML + '</div>';
      }

      // custom rendering for "Diff" columns
      if (e.panel == s.cells) {
        let col = s.columns[e.col];
        let item;
        let html = '';
        if (e.row > 0 && col.binding == 'ICD_Codes') {
          item = e.cell.textContent;
          let item1 = e.cell.textContent.split(';')[0];
          let item2 = e.cell.textContent.split(';')[1];
          if (item2 === 'Yes') {
            html =
              `<div class="diff-{cls}" >` +
              `<span style="font-size:75%"> ${item1}</span> ` +
              `<em class="fa fa-info-circle text-primary pull-right text-md" title="ICD codes configured for Sub-site, Site location"    style="cursor:pointer;" aria-hidden="true"  ></em>`;
          } else {
            html =
              `<div class="diff-{cls}" >` +
              `<span style="font-size:75%"> ${item1}</span> `;
          }
          e.cell.innerHTML = html;
        }
      }
    });
  }

  selectedOrganization(data) {
    this.userid = '';
    this.toggle = false;
    this.orgid = data.organizationid;
    this.orgName = data.organizationname;
    this.searchInput = data.organizationname
    this.getListUsers(data.organizationid);
    this.getSubSitesList(data.organizationid);
  }

  selectedUser(data) {
    this.usertoggle = false;
    this.userid = data.userid;
    this.userName = data.username;
    this.searchUserInput = data.username;
  }

  selectedRole(data) {
    this.roletoggle = false;
    this.searchRoleInput = data.rolename;
  }

  selectedSite(data) {
    this.siteToggle = false;
    this.searchSiteInput = data.SiteID;
    this.searchSiteTextInput = data.SiteName;
  }


  uniqByKeepLast(data, key) {
    return [new Map(data.map(x => [key(x), x])).values()]
  }
  onChangeDeployment(DeploymentKey) {
    this.selectedItems = [];
    this.srcDeployment = DeploymentKey;
    this.AddGridData([]);
    this.gridwidth = -1
    this.searchInput = '';
    this.searchUserInput = '';
    this.usertoggle = false;
    this.toggle = false;
    if (this.dataToTakeAsInput.TableName.toLowerCase() == 'organizationreportsdef') {
      this.getOrgList();
    } else {

      this.getListOrg();
    }

  }

  fetchUserSeries(value: string) {
    this.searchuserlist = []
    if (value === '') {
      return (this.searchuserlist = []);
    }
    this.usertoggle = false;
    let idValidate = /^[0-9]*$/

    if (!idValidate.test(value)) {
      if (value.length > 0) {
        this.usertoggle = true
        this.searchuserlist = this.UserList.filter(function (series) {
          if (series && series.username != null) {
            return series.username.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchuserlist = this.searchuserlist.splice(0, 25);
      }
    }
    else {
      this.usertoggle = true;
      this.searchuserlist = this.UserList.filter(function (series) {
        if (series && series.userid != null) {
          return series.userid.toString().toLowerCase().includes(value.toLowerCase());
        }
      });
      this.searchuserlist = this.searchuserlist.splice(0, 25);
    }
  }
  public searchSitelist: any = [];
  fetchSiteSeries(value: string) {
    this.searchSitelist = []
    if (value === '') {
      return (this.searchSitelist = []);
    }
    this.siteToggle = false;
    let idValidate = /^[0-9]*$/

    if (!idValidate.test(value)) {
      if (value.length > 0) {
        this.siteToggle = true
        this.searchSitelist = this.attributesName.filter(function (series) {
          if (series && series.SiteName != null) {
            return series.SiteName.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchSitelist = this.searchSitelist.splice(0, 25);
      }
    }
  }
  fetchRoleSeries(value: string) {
    this.searchrolelist = []
    if (value === '') {
      return (this.searchrolelist = []);
    }
    this.roletoggle = false;
    let idValidate = /^[0-9]*$/

    if (!idValidate.test(value)) {
      if (value.length > 0) {
        this.roletoggle = true
        this.searchrolelist = this.rolesArray.filter(function (series) {
          if (series && series.rolename != null) {
            return series.rolename.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchrolelist = this.searchrolelist.splice(0, 25);
      }
    }
  }

  // to get the data in the select
  getListUsers(orgid) {
    let query = this.GetQuery('getusersdiagnosistemplate');
    let queryVariable = { "orgid": orgid.toString() };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult).subscribe(res => {
      if (!res.errors) {
        this.UserList = res.data.Card;
      }
      this.ngxService.stop();
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }

  getRoles(depKey) {
    let queryResult: any;
    let queryVariable;
    let card;
    if (this.dataToTakeAsInput.cardType.toLowerCase() == 'laboratory') {
      card = 'Laboratory';
    } else if (this.dataToTakeAsInput.cardType.toLowerCase() == 'facility') {
      card = 'Ordering Facility';
    } else if (this.dataToTakeAsInput.cardType.toLowerCase() == 'epg') {
      card = 'Pathologists';
    }
    let query = this.GetQuery('getuserrolesforassociationtype');
    let i, Qstr = "";
    Qstr += "associationtype = \"" + card + "\"";

    queryVariable = { "associationtype": Qstr };
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    let dbName = depKey;
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(res => {
      if (!res.errors) {

        if (res.data.vwGetRolesBasedOnAccountAssociation_list.length > 0) {
          this.rolesdata1 = [];
          this.rolesdata1 = res.data.vwGetRolesBasedOnAccountAssociation_list; //unsorted
          //to get unique roles from array
          let rolesarray = [];
          this.rolesdata1.forEach(element => {
            if (rolesarray.length > 0) {
              let foundRoleDuplicate = false;
              for (let kk = 0; kk < rolesarray.length; kk++) {
                let role: string = element.rolename;
                if (rolesarray[kk].rolename === role) {
                  foundRoleDuplicate = true;
                  break;
                }
              }
              if (foundRoleDuplicate == false) {
                //to get unique roles from array
                rolesarray.push(element);
              }
            }
            else {
              rolesarray.push(element);
            }
          });
          this.rolesArray = rolesarray;
        }
      }
      else {
        console.error(res.error);
      }
    });
  }

  //#region Validate excel/copy data before approving
  isValid() {
    let isValidData = true;
    if(this.dataToTakeAsInput.TableName.toString().toLowerCase() == 'organizationreportsdef')
    {
      if (this.selectedItems && this.selectedItems.length > 0) {
        if ((this.selectedItems.some(x => !x._data.Notes || x._data.Notes.includes('already exists')
          || (x._data.Notes?.toString().match(/already exists/i)) || x._data.Notes.includes('already exists')))
        ) {
          isValidData = false;
        }
      } else {
        isValidData = false;
      }
    }
    return isValidData;
  }
  //#endregion

  //#region apply color to the Warning, Error, & Success messages 
  formatItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value.toString().match(/Custom Report already exists/)) {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value.toString().match(/Will be added on approval/)) {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
      }
    }
  }
  //#endregion

}

class ColDef {
  constructor(public binding?: string, public header?: string) { }
}
