import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { saveAs as importedSaveAs } from "file-saver";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import { ActivityTrackerService } from "src/app/core/services/activity-tracker.service";
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { Desktoptemplate } from 'src/app/model/desktoptemplate';
import * as XLSX from 'xlsx';
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';
import { InjectorService } from '../../../core/services/Injectorservices';
import { BaseGridContainerComponent } from '../../commoncomponents/templateclasses/basegrid';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
@Component({
  selector: 'app-desktoptemplate',
  templateUrl: './desktoptemplate.component.html',
  styleUrls: ['./desktoptemplate.component.scss']
})

export class DesktoptemplateComponent extends BaseGridContainerComponent implements OnInit {
  @ViewChild("takeInput", { static: false })
  InputVar: ElementRef;

  gridHeader = ["TemplateId", "Location", "DisplayName", "TemplateType", "Template", "Context", "ContextType", "Mode", "ProductName", "CreatedBy", "CreatedDate", "ModifiedBy", "ModifiedDate"];
  @Input()
  public subMenudata: any;
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  labels: any;
  @Input()
  rowData: any;
  columnDefs: any = [];
  gridwidth: number = 0;
  labelFirstCount: number = 0;

  @Input()
  templateData: any;
  @Input()
  templateChangedTime: string;

  auditableColumns: any;
  activityEntity: any;

  oldEditJson: any;
  cvPaging: CollectionView;
  data: any;
  gridData: CollectionView;
  public SubMenuCardModel;
  public hideGrid = true;
  public showDescription = false;
  public showAddTemplatePage = false;
  public description;
  public createdBy;
  public createdDate;
  public expiryDate;
  public effectiveDate;
  public evaluationCriteriaCode;
  public fileList;
  public ifUpload = false;
  public editTemplatePage = false;
  public addTemplatePage = false;
  public invalidFile = true;
  public isCopyOrBulk: boolean = false;
  addVDTemplateData = this._fb.group({
    ProductName: ['', [Validators.maxLength(50), Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]]
    , DisplayName: ['', [Validators.maxLength(100), Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]]
    , Template: ['']
    , TemplateFile: ['']
    , TemplateType: ['', [Validators.maxLength(50), Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]]
    , Context: ['', [Validators.maxLength(100), Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]]
    , ContextType: ['', [Validators.maxLength(100), Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]]
    , Mode: ['', [Validators.maxLength(100), Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]]
    , Account: ['']
  });
  templateid: any;
  TemplatetypeOptionsArray: any;
  vddesktopFile: File;
  gridarray: any;
  hideExportBtn: boolean = true;
  hideCreateBtn: boolean = true;
  hideUpdateBtn: boolean = true;
  dataFound: boolean;
  overWriteTemplateFlag: boolean = false;
  hideDownloadBtn: boolean = true;
  hideCopyToOrgBtn: boolean = true;
  replaceFileWarning: boolean;
  AccountNameList: any = [];
  showPaginationMainGrid: boolean;
  destDeployment: string;
  hideDeleteBtn: boolean = true;
  hideResetBtn: boolean;
  hiddenColumns: any = [];
  uploadFileContent: any;
  editedRow: any;
  oldFileContent: any;
  newUploadedFile: any;
  isCopy: boolean | Boolean;
  groupBy: string[] = [];
  listGridheaders: { "Name": string; "type": string; "key": string; "colWidth": string; }[];
  hideUploadBtn: boolean = true;
  vdTempFormCopy: any;
  resetFlag: boolean = false;

  constructor(private ngxService: NgxUiLoaderService, public activityService: ActivityTrackerService, public vitalHttpServices: VitalHttpServices, public DataShare: DataShareService,
    private _snackbar: MatSnackBar, private dialog: MatDialog, public commonService: CommonService, private injectorServiceGridContainer: InjectorService,
    private _fb: FormBuilder) {
    super(injectorServiceGridContainer);
    this.SubMenuCardModel = new SubMenuCardModel(commonService, vitalHttpServices, DataShare);
  }

  ngOnInit(): void {
    this.destDeployment = sessionStorage.getItem("deploymentKey");
    this.getAuditableDetails(this.templateData.menuURL);
    this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': 'parentMenu', 'value': this.templateData.menuURL }] })
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
  }

  getAuditableDetails(location: any) {
    this.vitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }

  getStatus(isActive: any) {
    if (isActive == 'Yes' || 1) {
      return 'Active';
    } else {
      return 'Inactive';
    }
  }

  //#region get grid
  AddGridData() {
    this.getAccountName();
    this.isCopy = false;
    this.isCopyOrBulk = false;
    this.showAddTemplatePage = false;
    this.gridarray = [];
    let primary = {}
    if (this.templateData.submenuData) {
      if (this.templateData.submenuData.length > 0) {
        for (let i = 0; i < this.templateData.submenuData.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(this.templateData.submenuData[i])) {
            this.gridHeader.find((e): any => {
              if (key === e) {
                if (value == null) {
                  value = 'Not Specified'
                }
                primary[key] = value.toString();
              }
              if (key === "Location") {
                if (value == null) {
                  value = ''
                }
                primary[key] = value.toString();
              }
            });
          }
          this.gridarray.push(primary);
        }
        if (this.gridarray.length > 10) {
          this.showPaginationMainGrid = true;
        } else {
          this.showPaginationMainGrid = false;
        }
        this.groupBy = ["Template Type"];
        this.listGridheaders = this.getDataHeaders(this.gridarray);
        this.gridarray = this.getGroupedData(this.gridarray);
        this.gridData = new CollectionView(this.gridarray, { pageSize: 10 })
        this.gridwidth = (170 * this.gridHeader.length) + 37;
        if (this.gridwidth > 1300) {
          this.gridwidth = 1300;
        }
      }
    }
    if (this.gridarray.length > 0) {
      this.dataFound = true;
      this.hideGrid = true;
    }
    else {
      this.dataFound = false;
      this.hideGrid = true;
    }
  }
  //#endregion

  //#region get dropdown valyes for Add/Edit form
  async getDropdownValues() {
    let drpquery = this.vitalHttpServices.mainQueryList.filter(x => x.Keyword == "CategoryDropDown");
    let drpqueryRequest: any = {
      "OperationName": null,
      "Query": drpquery[0].Query,
      "Variables": { "keyword": "AllVDTemplatesDDL", "context": "VDTemplates" }
    };
    let dbnane = 'configdb'
    await this.vitalHttpServices.GetData(drpqueryRequest, dbnane).toPromise().then(result => {
      if (!result.errors) {
        let tempDropdownList = JSON.parse(result.data.submenuData[0].Items_JSON);
        this.TemplatetypeOptionsArray = tempDropdownList;
      }
    }, error => {
      console.info(error);
    });
  }
  //#endregion

  //#region get Account list name and Id
  getAccountName() {
    let queryVariable = { orgid: sessionStorage.getItem('org_id') };
    let query = this.SubMenuCardModel.GetQuery("ListAccountNameandId");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        if (data) {
          this.AccountNameList = data.data.submenuData;
        }
      }
    },
      error => {
        console.error(error);
      });
  }

  //#endregion

  //#region redirects to grid page
  goBack() {
    this.showDescription = false;
    this.activityEntity.entityId = '';
    this.activityService.setActivitySession(this.activityEntity);
    if (this.gridarray.length > 0) {
      this.dataFound = true;
      this.hideGrid = true;
    }
    else {
      this.dataFound = false;
      this.hideGrid = true;
    }
    this.showAddTemplatePage = false;
  }
  //#endregion

  //#region redirects to add new template form
  async goCreate() {
    await this.getDropdownValues();
    this.ifUpload = false;
    this.fileList = null;
    this.ngxService.start();
    this.addVDTemplateData.reset();
    this.addTemplatePage = true;
    this.editTemplatePage = false;
    this.showDescription = false;
    this.hideGrid = false;
    this.showAddTemplatePage = true;
    this.ngxService.stop();
    this.resetFlag = false;
  }
  //#endregion

  //#region Prepare JSON Obj to be passed to an API
  generateJsonVDTemplate(data) {
    let actionParam = this.editTemplatePage == true ? 'UPDATE' : 'INSERT';
    let template;
    if (this.fileList) {
      template = this.fileList.name.includes("C:\\fakepath\\") == true ? this.fileList.name.replace("C:\\fakepath\\", "") : this.fileList.name;
    }
    else {
      template = data.Template;
    }
    let accountidvalue;
    if (data.Account != null) {
      let valueaccount = data.Account.split('(')
      for (var i = 1; i < valueaccount.length; i++) {

        valueaccount = valueaccount[i].split(')');
      }
      accountidvalue = valueaccount[0];
    }
    else {
      accountidvalue = data.Account;
    }
    let accountID = accountidvalue == null ? null : accountidvalue
    let sendJson = new Desktoptemplate(data, actionParam, template, this.templateid, accountID, parseInt(this.commonService.orgid))
    this.saveVDTemplate(actionParam, sendJson);
  }
  //#endregion

  //#region call API to INSERT/UPDATE
  async saveVDTemplate(actionParam, sendJson) {
    if (sendJson.accountID) {
      const data = this.AccountNameList.find(va => va.ID == sendJson.accountID);
      if (!data) {
        this._snackbar.open("Please Select Valid Location");
        return;
      }
    }

    let array = [{
      "slno": 1
      , "notes": ""
      , "context": sendJson.context
      , "mode": sendJson.mode
      , "displayname": sendJson.displayname
      , "template": sendJson.template
      , "preview": sendJson.preview
      , "templatetype": sendJson.templatetype
      , "productname": sendJson.productname
      , "templateid": sendJson.templateid
      , "accountid": sendJson.accountID
      , "contexttype": sendJson.contexttype
      , "organizationid": sendJson.organizationid
      , "modifiedby": sendJson.modifiedby
    }]
    //if file uploaded
    if (this.fileList) {
      this.ngxService.start();
      const frmData = new FormData();
      frmData.append("file", this.fileList);
      this.convertFileData(this.fileList, actionParam);

      await this.vitalHttpServices.addEditVDTemplate(array, this.destDeployment).toPromise().then(async data => {
        if ( data.content!="" && data.content[0].status.toLowerCase() == 'success' && data.status.toLowerCase() == 'success') {
          let insertedData = data.content;
          await this.vitalHttpServices.UploadFile(frmData).toPromise().then(async data => {
            if (data.content) {
              if (this.fileList.name) {
                sendJson["templatefile"] = this.fileList.name;
              }
              if (actionParam == 'INSERT') {
                this._snackbar.open("Saved successfully", "Success");
                await this.vitalHttpServices.uploadFile(this.uploadFileContent)
                  .subscribe((res) => {
                    res.content[0]['type'] = 'file';
                    sendJson['templatefile'] = JSON.stringify(res.content[0]);
                    this.commonService.auditDetails("templateid", "displayname", insertedData, [sendJson], 'Create', this.templateData, this.auditableColumns);
                  });
              }
              else {
                this._snackbar.open("Updated successfully", "Success");
                if (this.downloadVDTemplateShow(this.editedRow)) {
                  this.downloadOldVDTemplate(this.editedRow, this.oldEditJson, sendJson, 'Edit');
                } else {
                  this.vitalHttpServices.uploadFile(this.uploadFileContent)
                    .subscribe((res) => {
                      res.content[0]['type'] = 'file';
                      sendJson['templatefile'] = JSON.stringify(res.content[0]);
                      this.oldEditJson['templatefile'] = '';
                      this.commonService.auditDetails("TemplateId", "DisplayName", [this.oldEditJson], [sendJson], 'Edit', this.templateData, this.auditableColumns)
                    });
                }

              }
              await this.getUpdatedGrid();
              this.goBack();
              this.ngxService.stop();
            }
            else {
              this._snackbar.open(data.Message, "Failure");
              this.ngxService.stop();
            }
          },
            error => {
              console.log(error);
            });
        }
        else {
          if (data.content!=""&&data.content[0].status.toLowerCase() == 'ignored' && data.content[0].notes.toLocaleLowerCase()=="template with same displayname already exists!") {
         
            this._snackbar.open("Display Name already exists!", "Close");
          } else {
            this._snackbar.open("Something went wrong", "Close");
            console.error(data.statusmessage)
          }
          this.ngxService.stop();
        }
      },
        error => {
          console.log(error);
        });

      this.ngxService.stop();
    }
    //if file is not uploaded
    else {
      this.ngxService.start();
      await this.vitalHttpServices.addEditVDTemplate(array, this.destDeployment).toPromise().then(async data => {
        this.ngxService.stop();
        if (data.content!="" && data.content[0].status.toLowerCase() == 'success' && data.status.toLowerCase() == 'success') {
          if (actionParam == 'INSERT') {
            this._snackbar.open("Saved successfully", "Success");
            this.commonService.auditDetails("templateid", "displayname", data.content, [sendJson], 'Create', this.templateData, this.auditableColumns)
          }
          else {
            this._snackbar.open("Updated successfully", "Success");
            this.commonService.auditDetails("TemplateId", "DisplayName", [this.oldEditJson], [sendJson], 'Edit', this.templateData, this.auditableColumns)
          }
          await this.getUpdatedGrid();
          this.goBack(); 
        }
        else {
          if (data.content!=""&&data.content[0].status.toLowerCase() == 'ignored' && data.content[0].notes.toLocaleLowerCase()=="template with same displayname already exists!") {
            this._snackbar.open("Display Name already exists!", "Close");
            


          } else {
            this._snackbar.open("Something went wrong", "Close");
            console.error(data.statusmessage)
          }
        }
      },
        error => {
          console.log(error)
        });
      this.ngxService.stop();
    }
  }
  //#endregion

  //#region Delete VD Template
  deleteVDTemplate(row) {
    if (this.hideDeleteBtn) {
      this._snackbar.open("User is not authorized !", "Close");
      return;
    }

    let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
      disableClose: true,
          autoFocus: false,
      width: '500px',
      data: { header: "", message: "", alert: "Clicking on OK, will delete the template! Do you wish to continue ?", continue: "OK", cancel: "Cancel" }
    });
    return dialogRef.afterClosed().toPromise().then(result => {
      if (result) {
        let extension = row.Template?.toString().split('.').pop();
        let templateName = "";
        if (extension == 'lbl' || extension == 'nlbl' || extension == 'txt' || extension == 'mwp'
          || extension == 'label') {
          templateName = row.Template;
        }
        let userid = (sessionStorage.getItem('Userid') == null || sessionStorage.getItem('Userid') == '') ? -100 : sessionStorage.getItem('Userid');

        let array = {
          Id: row.TemplateId,
          FileName: templateName,
          UserID: userid,
          action: 'DELETE'
        }
        this.vitalHttpServices.deleteVDTemplate(array, this.destDeployment).toPromise().then((res) => {
          if (res.status.toLowerCase() == 'success') {
            this._snackbar.open("Template deleted successfully.", 'Close');
            if (this.downloadVDTemplateShow(row)) {
              this.downloadOldVDTemplate(row, row, {}, 'Delete');
            } else {
              this.commonService.auditDetails('TemplateId', 'DisplayName', [row], [{}], 'Delete', this.templateData, this.auditableColumns);
            }
            this.getUpdatedGrid();
          }
          else {
            console.error(res.Message);
            this._snackbar.open('Delete failed!', 'Close')
          }
        }, error => {
          console.error(error);
        })
      }
      else {
        return
      }
    }, error => {
      console.log(error);
    });
  }
  //#endregion

  //#region event for uploaded file
  onFileSelect(event) {
    this.fileList = null;
    if (event.target.files.length > 0) {
      this.fileList = event.target.files[0];
      this.ifUpload = true;
    }
  }
  //#endregion

  //#region Redirects to edit page
  async editVDTemplate(row) {

    this.oldEditJson = row;
    this.editedRow = row;
    this.activityEntity.entityId = this.oldEditJson.TemplateId;
    this.activityService.setActivitySession(this.activityEntity);
    if (this.hideUpdateBtn) {
      this._snackbar.open("User is not authorized !", "Close");
      return;
    }
    this.ifUpload = false;
    this.fileList = null;
    this.ngxService.start();
    await this.getDropdownValues();
    this.showAddTemplatePage = true;
    this.hideGrid = false;
    this.resetFlag = true;
    this.addTemplatePage = false;
    this.editTemplatePage = true;
    this.templateData.submenuData.find(e => {
      if (row.TemplateId == e.TemplateId) {
        this.templateid = e.TemplateId;
        this.addVDTemplateData.patchValue(
          {
            ProductName: e.ProductName
            , DisplayName: e.DisplayName
            , Template: e.Template
            , TemplateType: e.TemplateType
            , Context: e.Context
            , ContextType: e.ContextType
            , Mode: e.Mode
            , Account: e.Location
            ,TemplateFile: ''
          });

      }
    })
    this.vdTempFormCopy = JSON.parse(JSON.stringify(this.addVDTemplateData.value));

    this.ngxService.stop();
  }
  //#endregion

  //#region get updated grid data
  async getUpdatedGrid() {
    this.ngxService.start();
    this.activityEntity.entityId = '';
    this.activityService.setActivitySession(this.activityEntity);
    let queryVariable = { "orgid": this.commonService.orgid.toString() };
    let query = this.SubMenuCardModel.GetQuery("labvdtemplates");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.vitalHttpServices.GetData(queryResult, this.destDeployment).toPromise().then(data => {
      if (!data.errors) {
        this.templateData.submenuData = [];
        if (data.data.submenuData.length > 0) {
          this.templateData.submenuData = data.data.submenuData;
        }
        this.AddGridData();
      }
      this.ngxService.stop();
    },
      error => {
        this.ngxService.stop();
        console.log(error);
      });
    this.ngxService.stop();
  }
  //#endregion

  //#region download template
  async downloadVDTemplate(filename) {
    let extension = filename.Template.split('.').pop();
    if (extension == 'lbl' || extension == 'nlbl' || extension == 'txt' || extension == 'mwp'
      || extension == 'label') {
      this.invalidFile = true;
      await this.vitalHttpServices.downloadVDTemplate(filename.Template).toPromise().then(async res => {
        if (res) {
          const content = await res.text()
          if (content == 'File not found.') {
            this._snackbar.open("Template file does not exist!", "Close");
            return
          }
          const file = new Blob([res], { type: 'nlbl/lbl' });
          importedSaveAs(file, filename.Template);
        }
        // else {
        // this._snackbar.open("Template download failed!", "Failure");
        // }
      },
        (error) => {
          this._snackbar.open("Template file does not exist!", "Failure");
          console.log(error);
        }
      );
    }
    else {
      this.invalidFile = false;
    }
    this.invalidFile = true;
  }

  //#endregion
  downloadVDTemplateShow(filename) {
    let extension = filename.Template.split('.').pop();
    if (extension == 'lbl' || extension == 'nlbl' || extension == 'txt' || extension == 'mwp') {
      return true;
    }
    else {
      return false;
    }
  }

  //#region reset file
  removeTemplate() {
    this.InputVar.nativeElement.value = "";
    this.fileList = null;
    this.ifUpload = false;
  }
  //#endregion

  ResetData() {
    this.addVDTemplateData.patchValue(this.vdTempFormCopy)
  }

  ngOnChanges() {
    this.GetButtondetails();
    this.AddGridData();
  }

  GetButtondetails() {
    this.GetButtonAccess(this.vitalHttpServices.SubmenuAction);
  }

  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Edit":
          this.hideUpdateBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Create":
          this.hideCreateBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.hideExportBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Download":
          this.hideDownloadBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "CopyToOrg":
          this.hideCopyToOrgBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Delete":
          this.hideDeleteBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Upload":
          this.hideUploadBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
      }
    }
  }

  //#region Export Grid data
  ExportExcel(flex) {
    let orgname = sessionStorage.getItem('Org_Name');
    // let LabName = sessionStorage.getItem("LabName");
    let file_name = 'VitalDesktop List_';
    if (orgname != 'undefined' && orgname) {
      file_name = file_name + orgname;
      if (file_name.length > 31) {
        let fileName = file_name.split('_')[0] + '_';
        let orgName = file_name.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
        if (orgName.length > 31) {
          fileName = file_name.split('_')[0].replace(' ', '') + '_';
          file_name = fileName + orgName + '_';
        }
        else {
          file_name = fileName + orgName + '_';
        }
      }
      else {
        file_name = file_name + '_';
      }
    }
    file_name = file_name + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    if (file_name.length > 31) {
      let fileName = file_name.split('_')[0] + '_';
      if(fileName.length>31){
        file_name = fileName.split('_')[0] +'.xlsx';
      }else{
        if(this.templateData.secondarykeys){
          file_name = fileName  + this.templateData.secondarykeys.OrganizationId.toString()+'.xlsx';
      }
      else{
        file_name = fileName.split('_')[0] +'.xlsx';
      }
    }
    }
    let excel = [];
    // let modifiedExcel: any = [];
    const view = flex.collectionView;
    let oldPgSize = view.pageSize
    view.pageSize = 0;
    flex.beginUpdate();
    flex.rows.find(e => {
      delete e._data["Actions"];
      excel.push(e._data);
    });
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'VitalDesktop');
    XLSX.writeFile(wb, file_name);
    view.pageSize = oldPgSize;
    flex.endUpdate()
  }
  //#endregion

  //#region check Template Exists in the server
  async ValidateVDTemplate() {
    let obj
    if (this.addVDTemplateData.value.TemplateType != 'barcode') {
      obj = { header: "Confirmation", message: "Please either enter the value for Template field or upload a Template File!", alert: "", continue: "OK", cancel: "dontshow" }
    }
    else {
      obj = { header: "Confirmation", message: "Please enter the value for Template field!", alert: "", continue: "OK", cancel: "dontshow" }
    }
    if (((this.fileList) && (this.addVDTemplateData.value.Template && this.addVDTemplateData.value.Template != '')) ||
      (!(this.fileList) && !(this.addVDTemplateData.value.Template && this.addVDTemplateData.value.Template != ''))) {
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: obj
      });
      return dialogRef.afterClosed().toPromise().then(result => {
        if (result) {
          return;
        }
      },
        error => {
          console.log(error);
        });
    }

    if (this.fileList) {
      let extension = this.fileList.name.split('.').pop();
      if (extension == 'lbl' || extension == 'nlbl' || extension == 'txt' || extension == 'mwp'
        || extension == 'label') {
        const frmData = new FormData();
        frmData.append("file", this.fileList);
        await this.vitalHttpServices.checkTemplateExists(frmData).toPromise().then(data => {
          if (data.content) {
            //file exists
            let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
              disableClose: true,
              autoFocus: false,
              width: '360px',
              panelClass: 'admin-custom-popup',
              data: { header: "", message: "Template Already Exists!",alert:"Clicking on OK will replace the existing template. Do you wish to continue?", continue: "OK", cancel: "Cancel" }
            });
            return dialogRef.afterClosed().toPromise().then(result => {
              if (result) {
                this.generateJsonVDTemplate(this.addVDTemplateData.value);
              }
              else {
                this.removeTemplate();
                return
              }
            },
              error => {
                console.log(error);
              });
          }
          else {
            //file doesn't exist
            this.generateJsonVDTemplate(this.addVDTemplateData.value);
          }
        },
          error => {
            console.log(error);
          });
      }
      else {
        this._snackbar.open("Upload a valid template file", "Failure")
        return
      }
    }
    else {
      this.generateJsonVDTemplate(this.addVDTemplateData.value);
    }
  }

  //#region Tooltip for Grid
  initLocationGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null)
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }
  //#endregion

  filterAccounts(value: string) {

    if (!value) {
      return this.AccountNameList
    }
    return this.AccountNameList ? this.AccountNameList.filter(v => v.Location.toString().toLowerCase().includes(value.toString().toLowerCase())) : this.AccountNameList
  }


  filterLists(searchValue, list, filterKey) {
    if (!searchValue) {
      return list;
    }
    else
      return list ? list.filter(va => va[filterKey].toString().toLowerCase().includes(searchValue.toString().toLowerCase())) : list
  }

  shouldHideColumn(columnname: string): boolean {
    this.hiddenColumns = ['CreatedBy', 'CreatedDate', 'ModifiedBy', 'ModifiedDate'];
    return !this.hiddenColumns.toString().toLowerCase().includes(columnname.toLowerCase());
  }

  convertFileData(file, action) {
    //#used for activity tracker for file upload
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      let fileContent = (<string>fileReader.result).split(',')[1]
      this.uploadFileContent = [{
        "id": "",
        "name": file.name,
        "source": fileContent.toString()
      }]
      if (action != 'INSERT') {
        this.vitalHttpServices.uploadFile(this.uploadFileContent)
          .subscribe((res) => {
            res.content[0]['type'] = 'file';
            this.newUploadedFile = JSON.stringify(res.content[0]);
          });
      }
    }
    //
  }

  downloadOldVDTemplate(fileData, oldData, newData, action) {
    this.vitalHttpServices.downloadVDTemplate(fileData.Template).subscribe((res) => {
      if (res) {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(res)

        fileReader.onload = () => {
          let fileContent = (<string>fileReader.result).split(',')[1]
          this.oldFileContent = [{
            "id": "",
            "name": fileData.Template,
            "source": fileContent.toString()
          }]
          if (action == 'Edit') {
            this.vitalHttpServices.uploadFile(this.oldFileContent)
              .subscribe((res) => {
                res.content[0]['type'] = 'file';
                oldData['templatefile'] = JSON.stringify(res.content[0]);
                newData['templatefile'] = this.newUploadedFile;
                this.commonService.auditDetails("templateid", "DisplayName", [oldData], [newData], action, this.templateData, this.auditableColumns)
              });
          } else {
            this.vitalHttpServices.uploadFile(this.oldFileContent)
              .subscribe((res) => {
                res.content[0]['type'] = 'file';
                oldData['templatefile'] = JSON.stringify(res.content[0]);
                this.commonService.auditDetails('TemplateId', 'DisplayName', [oldData], [newData], action, this.templateData, this.auditableColumns);
              });
          }
        }
      }
    },
      (error) => {
        if (action == 'Edit') {
          oldData['templatefile'] = 'File not found';
          newData['templatefile'] = this.newUploadedFile;
          this.commonService.auditDetails("templateid", "DisplayName", [oldData], [newData], action, this.templateData, this.auditableColumns);
        } else {
          oldData['templatefile'] = 'File not found';
          this.commonService.auditDetails('TemplateId', 'DisplayName', [oldData], [newData], action, this.templateData, this.auditableColumns);
        }
      }
    );
  }

  onBulkOrCopyClick(isCopy?: Boolean) {
    this.hideGrid = false;
    this.showAddTemplatePage = false;
    this.isCopyOrBulk = true;
    this.isCopy = isCopy ? isCopy : false;
  }

  /* List page changes start*/
  createVDTemplate(event) {
    if (!event.bulkUpload) {
      this.goCreate();
    }
    else {
      this.onBulkOrCopyClick();
    }
  }
  /* List page changes end*/

  // getDataHeaders(gridData) {
  //   let valueObj = [];
  //   for (let [key, value] of Object.entries(gridData[0])) {
  //     if (key != 'TemplateType' && key != 'ProductName' && key != 'Mode' && key != 'CreatedBy' && key != 'ModifiedBy' && key != 'CreatedDate' && key != 'ModifiedDate') {
  //       let obj = {
  //         "Name": key.toString().replace(/([A-Z])/g, ' $1').trim(),
  //         "type": "text",
  //         "key": key,
  //         "colWidth": (key == 'TemplateId' || key == 'ContextType') ? "col-sm-1 mr-0 ml-2 p-0" : "col-sm-2 mr-0 ml-2 p-0"
  //       }
  //       valueObj.push(obj)
  //     }
  //   }
  //   return valueObj;
  // }

  getDataHeaders(gridData) {
    let valueObj = [{
      "Name": "ID",
      "type": "text",
      "key": "TemplateId",
      "colWidth": "col-sm-1"
    },
    {
      "Name": "Display Name",
      "type": "text",
      "key": "DisplayName",
      "colWidth": "col-sm-2 padding-align"
    }, {
      "Name": "Template",
      "type": "text",
      "key": "Template",
      "colWidth": "col-sm-2 padding-align"
    }, {
      "Name": "Kit Type",
      "type": "text",
      "key": "ContextType",
      "colWidth": "col-sm-2 padding-align"
    }, {
      "Name": "Device",
      "type": "text",
      "key": "Context",
      "colWidth": "col-sm-2 padding-align"
    }, {
      "Name": "Location",
      "type": "text",
      "key": "Location",
      "colWidth": "col-sm-2 padding-align"
    }]
    return valueObj;
  }

  getGroupedData(arrayData) {
    let hierData = [];
    arrayData.map(va => {
      let parentInfo: { Name: string, DataList?: Object[] } = { Name: va.TemplateType };
      if (!hierData.some(val => val.Name == va.TemplateType)) {
        parentInfo.DataList = arrayData.filter(val => val.TemplateType == va.TemplateType);
        hierData.push(parentInfo);
      }
    });
    if (!hierData.length) {
      hierData = arrayData;
    }
    return hierData;
  }

  disableReset() {
    return JSON.stringify(this.vdTempFormCopy) == JSON.stringify(this.addVDTemplateData.value)
  }

  disableSaveButton() {
    if (((this.fileList) && (this.addVDTemplateData.value.Template && this.addVDTemplateData.value.Template != '')) ||
      (!(this.fileList) && !(this.addVDTemplateData.value.Template && this.addVDTemplateData.value.Template != ''))) {
      return true;
    }
  }

}
