import { Clipboard } from '@angular/cdk/clipboard';
import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import {  MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjGrid from '@grapecity/wijmo.grid';
import * as wjInput from '@grapecity/wijmo.input';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import * as XLSX from 'xlsx';
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';
import { CommonService } from '../../../core/services/commonservices';

@Component({
  selector: 'app-interface,',
  templateUrl: './interface.component.html',
  styleUrls: ['./interface.component.scss']
})
export class InterfaceComponent implements OnChanges {
  @ViewChild('flexGrid', { static: true }) flexGrid: wjGrid.FlexGrid;
  @ViewChild('selectionMode', { static: true }) selectionMode: wjInput.ComboBox;
  @ViewChild('inputValue') inputValue: ElementRef;
  public gridheader = [
    'Status',
    'Type',
    'Go_Live_Date',
    'Vendor',
    'Message',
    'Handler',
    'Name'
  ];

  @Input() public templateData: any;
  public intmapheaders = ['Lookup_Value', 'Lookup_Type', 'Lookup_ID'];
  data: any[];
  public Handler;
  public isOutbound;
  public gridDisplay = false;
  public intMapGrid = true;
  displayGrid;
  intgridwidth;
  public interfacesDetailsData;
  public rulesDetailsData;
  public intMapDetailsData;
  currentSelection: string;
  public accountname;
  public organizationname;
  public Description;
  public Direction;
  public Vendor;
  public Type;
  public System;
  public MessageSubtype;
  public Folder_Date_Format;
  public Go_Live_Date;
  public Interface_Name;
  public Interface_Engine;
  public OrganizationID;
  public Local_Outbound_Folder;
  public Local_Outbound_Bck_Folder;
  public Local_Source_Folder;
  public Local_Processing_Folder;
  public Local_Success_Folder;
  public Local_Failed_Folder;
  public Local_Ignored_Folder;
  public Group_ID;
  public MSH;
  public In_Testing;
  public Status;
  public FindFileIn;
  public FileType;
  public Archive_Path;
  public Show_Message;
  public subMenuCardModel;
  public Lab_ID_Count;
  public detailsDisplay = false;
  tabIndex = 0;
  gridwidth;
  gridData: CollectionView;
  intMapGridData;
  public gridEmpty;
  showRulesData;
  public interfacesData;
  showPaginationMainGrid: boolean;
  showPaginationIntMapGrid: boolean;
  selector: any;
  public hideExportBtn: boolean = true;
  public actionButtonDetails: any[];

  ngOnChanges() {
    this.AddGridData();
    this.GetButtondetails();
    // this.gridData = new wjcCore.CollectionView(this.AddGridData());

  }
  constructor(
    public vitalHttpServices: VitalHttpServices,
    private clipboard: Clipboard,
    public DataShare: DataShareService,
    private _snackbar: MatSnackBar,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService
  ) {
    this.subMenuCardModel = new SubMenuCardModel(commonService, vitalHttpServices, DataShare);
  }
  GetButtondetails() {
    this.GetButtonAccess(this.vitalHttpServices.SubmenuAction);
    // this.DataShare.SubmenuAction.subscribe(data => {
    //   if (data) {
    //     // this.actionButtonDetails = data;
    //     this.GetButtonAccess(data);
    //   }
    // });
  }

  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case 'Export':
          this.hideExportBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
      }
    }
  }
  initGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      const col = e.getColumn(),
        row = e.getRow();
      tt.hideDelay = 9999999;
      tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }

  onClick(grid, e) {
    let hti = grid.hitTest(e);
    if (hti.panel === grid.cells) {
      this.ngxService.start();
      let row = grid.rows[hti.row];
      let dataItem = row.dataItem;
      if (dataItem == null) {
        this.ngxService.stop();
        return;
      } else {
        this.gridDisplay = false;
        this.detailsDisplay = true;
        let handler = dataItem.Handler;
        // let queryVariable = {
        //   orgid: organizationid.toString(),
        //   handlerID: handler.toString()
        // };
        // let query = this.SubMenuCardModel.GetQuery('getinterfacedetails');
        // let queryResult = this.commonService.GetCardRequest(
        //   queryVariable,
        //   query
        // );
        // this.vitalHttpServices.GetData(queryResult).subscribe(data => {
        this.interfacesDetailsData = this.interfacesData.filter(
          data => data.Handler == handler
        );
        this.Handler = this.interfacesDetailsData[0].Handler;
        this.Description = this.interfacesDetailsData[0].Description;
        this.Interface_Name = this.interfacesDetailsData[0].Interface_Name;
        this.Direction = this.interfacesDetailsData[0].Message;
        this.Vendor = this.interfacesDetailsData[0].Vendor;
        this.System = this.interfacesDetailsData[0].System;
        this.MessageSubtype = this.interfacesDetailsData[0].MessageSubtype;
        this.Folder_Date_Format =
          this.interfacesDetailsData[0].Folder_Date_Format == ''
            ? 'Not Specified'
            : this.interfacesDetailsData[0].Folder_Date_Format;
        this.Go_Live_Date = this.interfacesDetailsData[0].Go_Live_Date;
        this.Interface_Engine = this.interfacesDetailsData[0].Type;
        this.OrganizationID = this.interfacesDetailsData[0].OrganizationID;
        this.Local_Outbound_Folder =
          this.interfacesDetailsData[0].Local_Outbound_Folder.toString().toLowerCase() == 'na' || this.interfacesDetailsData[0].Local_Outbound_Folder.toString().toLowerCase() == '' || this.interfacesDetailsData[0].Local_Outbound_Folder.toString().toLowerCase() == ' ' || this.interfacesDetailsData[0].Local_Outbound_Folder.toString().toLowerCase() == null
            ? 'Not Specified'
            : this.interfacesDetailsData[0].Local_Outbound_Folder;
        this.Local_Outbound_Bck_Folder =
          this.interfacesDetailsData[0].Local_Outbound_Bck_Folder.toString().toLowerCase() == 'na' || this.interfacesDetailsData[0].Local_Outbound_Bck_Folder.toString().toLowerCase() == '' || this.interfacesDetailsData[0].Local_Outbound_Bck_Folder.toString().toLowerCase() == ' ' || this.interfacesDetailsData[0].Local_Outbound_Bck_Folder.toString().toLowerCase() == null
            ? 'Not Specified'
            : this.interfacesDetailsData[0].Local_Outbound_Bck_Folder;
        this.Local_Source_Folder =
          this.interfacesDetailsData[0].Local_Source_Folder.toString().toLowerCase() == 'na' || this.interfacesDetailsData[0].Local_Source_Folder.toString().toLowerCase() == '' || this.interfacesDetailsData[0].Local_Source_Folder.toString().toLowerCase() == ' ' || this.interfacesDetailsData[0].Local_Source_Folder.toString().toLowerCase() == null
            ? 'Not Specified'
            : this.interfacesDetailsData[0].Local_Source_Folder;
        this.Local_Processing_Folder =
          this.interfacesDetailsData[0].Local_Processing_Folder.toString().toLowerCase() == 'na' || this.interfacesDetailsData[0].Local_Processing_Folder.toString().toLowerCase() == '' || this.interfacesDetailsData[0].Local_Processing_Folder.toString().toLowerCase() == ' ' || this.interfacesDetailsData[0].Local_Processing_Folder.toString().toLowerCase() == null
            ? 'Not Specified'
            : this.interfacesDetailsData[0].Local_Processing_Folder;
        this.Local_Success_Folder =
          this.interfacesDetailsData[0].Local_Success_Folder.toString().toLowerCase() == 'na' || this.interfacesDetailsData[0].Local_Success_Folder.toString().toLowerCase() == '' || this.interfacesDetailsData[0].Local_Success_Folder.toString().toLowerCase() == ' ' || this.interfacesDetailsData[0].Local_Success_Folder.toString().toLowerCase() == null
            ? 'Not Specified'
            : this.interfacesDetailsData[0].Local_Success_Folder;
        this.Local_Failed_Folder =
          this.interfacesDetailsData[0].Local_Failed_Folder.toString().toLowerCase() == 'na' || this.interfacesDetailsData[0].Local_Failed_Folder.toString().toLowerCase() == '' || this.interfacesDetailsData[0].Local_Failed_Folder.toString().toLowerCase() == ' ' || this.interfacesDetailsData[0].Local_Failed_Folder.toString().toLowerCase() == null
            ? 'Not Specified'
            : this.interfacesDetailsData[0].Local_Failed_Folder;
        this.Local_Ignored_Folder =
          this.interfacesDetailsData[0].Local_Ignored_Folder.toString().toLowerCase() == 'na' || this.interfacesDetailsData[0].Local_Ignored_Folder.toString().toLowerCase() == '' || this.interfacesDetailsData[0].Local_Ignored_Folder.toString().toLowerCase() == ' ' || this.interfacesDetailsData[0].Local_Ignored_Folder.toString().toLowerCase() == null
            ? 'Not Specified'
            : this.interfacesDetailsData[0].Local_Ignored_Folder;
        this.Group_ID = this.interfacesDetailsData[0].Group_ID;
        this.MSH = this.interfacesDetailsData[0].MSH;
        this.In_Testing = this.interfacesDetailsData[0].In_Testing;
        this.Status = this.interfacesDetailsData[0].Status;
        this.FindFileIn = this.interfacesDetailsData[0].FindFileIn;
        this.FileType = this.interfacesDetailsData[0].FileType;
        this.Archive_Path = this.interfacesDetailsData[0].Archive_Path;
        this.Show_Message = this.interfacesDetailsData[0].Show_Message;
        if (this.Direction.includes('Outbound')) {
          this.isOutbound = true;
        } else {
          this.isOutbound = false;
        }
        this.getRulesData();
        this.getIntMapData();
        this.ngxService.stop();
      }
    }
  }
  changeTab(event) {
    this.tabIndex = event.index;
  }

  // navigatorToggle(grid){
  //   const vw=grid.collectionView;
  //   vw._view;
  // }

  ExportToExcel() {
    let excelName ;
    let orgname = sessionStorage.getItem("Org_Name");
    excelName ==  this.templateData.secondarykeys.OrganizationId.toString();
    let filename = 'Interface Details_';
    if (orgname != 'undefined' && orgname) {
      filename = filename + orgname;
    if (filename.length > 31) {
      let fileName = filename.split('_')[0] + '_';
      let orgName = filename.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
          if (orgName.length > 31) {
            fileName = filename.split('_')[0].replace(' ','') + '_';
            filename = fileName + orgName + '_';
          }
          else {
            filename = fileName + orgName + '_';
          }
        }
        else {
          filename = filename + '_';
        }
      }
    let interfaceGrid = [];
    var sheet = XLSX.utils.book_new();
    for (let i = 0; i < this.interfacesData.length; i++) {
      interfaceGrid.push(this.interfacesData[i]);
    }
    filename =  filename +  this.templateData.secondarykeys.OrganizationId.toString()+ '.xlsx';
    if(filename.length > 31){
      let fileName = filename.split('_')[0] + '_';
      if(fileName.length>31){
        filename = fileName.split('_')[0] +'.xlsx';
      }else{
         if(this.templateData.secondarykeys){
        filename = fileName  + this.templateData.secondarykeys.OrganizationId.toString()+'.xlsx';
      }
      else{
        filename = fileName.split('_')[0] +'.xlsx';
      }
    }
    }
    var mainGrid = XLSX.utils.json_to_sheet(interfaceGrid);
    XLSX.utils.book_append_sheet(
      sheet,
      mainGrid,
      'List of interfaces',
    );

    XLSX.writeFile(sheet, filename);
  }

  ExportDetailsToExcel() {
    let masterConfig = [];
    let distRules = [];
    let intMap = [];
    let filename = 'Interface Details_';
    let orgname = sessionStorage.getItem('Org_Name') ;
    if (orgname != 'undefined' && orgname) {
      filename = filename + orgname;
    if (filename.length > 31) {
      let fileName = filename.split('_')[0] + '_';
      let orgName = filename.split('_')[1].match(/\b(\w)/g).join('').toUpperCase();
          if (orgName.length > 31) {
            fileName = filename.split('_')[0].replace(' ','') + '_';
            filename = fileName + orgName + '_';
          }
          else {
            filename = fileName + orgName + '_';
          }
        }
        else {
          filename = filename + '_';
        }
      }
    var sheet = XLSX.utils.book_new();
    filename = filename +  this.templateData.secondarykeys.OrganizationId.toString()+ '.xlsx';
    if(filename.length > 31){
      let fileName = filename.split('_')[0] + '_';
      if(fileName.length>31){
        filename = fileName.split('_')[0] +'.xlsx';
      }else{
         if(this.templateData.secondarykeys){
        filename = fileName  + this.templateData.secondarykeys.OrganizationId.toString()+'.xlsx';
      }
      else{
        filename = fileName.split('_')[0] +'.xlsx';
      }
    }
    }
    if (this.interfacesData) {
      if (this.interfacesDetailsData.length > 0) {
        masterConfig.push(this.interfacesDetailsData[0]);
        var sheetData1 = XLSX.utils.json_to_sheet(masterConfig);
        XLSX.utils.book_append_sheet(sheet, sheetData1, 'Master Configuration');
      }
    }
    if (this.rulesDetailsData) {
      if (this.rulesDetailsData.length > 0) {
        for (let i = 0; i < this.rulesDetailsData.length; i++) {
          distRules.push(this.rulesDetailsData[i]);
        }
        var sheetData2 = XLSX.utils.json_to_sheet(distRules);
        XLSX.utils.book_append_sheet(sheet, sheetData2, 'Distribution Rules');
      }
    }
    if (this.intMapDetailsData) {
      if (this.intMapDetailsData.length > 0) {
        for (let i = 0; i < this.intMapDetailsData.length; i++) {
          intMap.push(this.intMapDetailsData[i]);
        }
        var sheetData3 = XLSX.utils.json_to_sheet(intMap);
        XLSX.utils.book_append_sheet(sheet, sheetData3, 'Mapping Data');
      }
      XLSX.writeFile(sheet, filename);
    }
  }
  changeTab1(event) {
    this.tabIndex = event.index;
  }
  expandedcard(item) {
    this.rulesDetailsData.forEach(va => (va['isExpanded'] = false));
    item['isExpanded'] = true;
  }

  getRulesData() {
    this.ngxService.start();
    let queryVariable = { handlerId: this.Handler.toString() };
    let query = this.vitalHttpServices.GetQuery('getrulesdata');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(resdata => {
      if (!resdata.errors) {
        this.rulesDetailsData = resdata.data.submenuData;
        this.rulesDetailsData.forEach(rule => {
          rule['isExpanded'] = false;
        });
        if (this.rulesDetailsData.length == 0) {
          this.showRulesData = false;
          return this.showRulesData;
        } else {
          this.showRulesData = true;
        }
      }
    }, error => {
      console.error(error);
    });
    this.ngxService.stop();
  }

  copy(value: string) {
    this.clipboard.copy(value);
    this._snackbar.open('','Copied');
  }

  getIntMapData() {
    this.ngxService.start();
    let queryVariable = { handlerID: this.Handler.toString() };
    let query = this.vitalHttpServices.GetQuery('getintmapdata');
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    let gridarray = [];
    let primary = {};
    this.vitalHttpServices.GetData(queryResult).subscribe(data => {
      this.intMapDetailsData = data.data.submenuData;
      if (this.intMapDetailsData) {
        if (this.intMapDetailsData.length > 0) {
          for (let i = 0; i < this.intMapDetailsData.length; i++) {
            primary = {};
            for (let [key, value] of Object.entries(
              this.intMapDetailsData[i]
            )) {
              for (let j = 0; j < this.intmapheaders.length; j++) {
                if (
                  key === this.intmapheaders[j].toString().replace(/ /g, '')
                ) {
                  primary[key] = value;
                }
              }
            }
            gridarray.push(primary);
          }
        } else {
          this.intMapGridData = new CollectionView(gridarray, { pageSize: 10 });
          if (this.intMapGridData.isEmpty) {
            this.displayGrid = false;
          } else {
            this.displayGrid = true;
          }
          this.gridwidth = 0;
        }
        if (gridarray.length > 10) {
          this.showPaginationIntMapGrid = true;
        } else {
          this.showPaginationIntMapGrid = false;
        }
        this.intMapGridData = new CollectionView(gridarray);
        this.intMapGridData.pageSize = 10;
        if (this.intMapGridData.isEmpty) {
          this.displayGrid = false;
        } else {
          this.displayGrid = true;
        }
        this.intgridwidth = 700 * (this.gridheader.length - 1) + 37;
        if (this.intgridwidth > 1300) {
          this.intgridwidth = 250;
        }
      }
      this.gridDisplay = false;
    });
    this.ngxService.stop();
  }

  back() {
    this.gridDisplay = true;
    this.gridEmpty = false;
    this.isOutbound = null;
    this.detailsDisplay = false;
    this.rulesDetailsData = [];
  }
  AddGridData() {
    if (this.templateData.submenuData != null) {
      this.interfacesData = this.templateData.submenuData;
      //let organizationid = sessionStorage.getItem('org_id');
      // let queryVariable = { orgid: organizationid.toString() };
      // let query = this.SubMenuCardModel.GetQuery('labinterface');
      // let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      let gridarray = [];
      let primary = {};
      // this.vitalHttpServices.GetData(queryResult).subscribe(data => {
      // this.interfacesData = data.data.submenuData;
      if (this.interfacesData) {
        if (this.interfacesData.length > 0) {
          for (let i = 0; i < this.interfacesData.length; i++) {
            primary = {};

            for (let [key, value] of Object.entries(this.interfacesData[i])) {
              for (let j = 0; j < this.gridheader.length; j++) {
                if (key === this.gridheader[j].toString().replace(/ /g, '')) {
                  if (value == 'NA') {
                    value = 'Not Specified';
                    primary[key] = value;
                  } else {
                    primary[key] = value;
                  }
                }
              }
            }
            gridarray.push(primary);
            this.gridDisplay = true;
            this.gridEmpty = false;
          }
        } else {
          this.gridData = new CollectionView(gridarray, { pageSize: 10 });
          this.gridwidth = 0;
          this.gridDisplay = false;
          this.gridEmpty = true;
        }
        if (gridarray.length > 10) {
          this.showPaginationMainGrid = true;
        } else {
          this.showPaginationMainGrid = false;
        }
        this.gridData = new CollectionView(gridarray, { pageSize: 10 });
        this.gridwidth = 500 * ((this.gridheader.length - 1) + 37);
        if (this.gridwidth > 1300) {
          this.gridwidth = 798;
        }
      } else {
        this.gridEmpty = true;
        this.gridDisplay = false;
      }
      // });
    } else {
      this.gridEmpty = true;
      this.gridDisplay = false;
    }
  }
}
