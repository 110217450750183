import { DataShareService } from 'src/app/core/services/datashare.service';
import { CommonService } from '../../../core/services/commonservices';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';

export class SubMenuCardModel {
  constructor(private commonService: CommonService, private vitalHttpServices: VitalHttpServices,
    private datashare: DataShareService) { }
  public orgguidArray = [];
  public orgguid = [];
  public gridDatamulti = [];

  public GetQuery(keyword: string) {
    let query: string = '';
    let mainQueryIndex: number = this.vitalHttpServices.mainQueryList.findIndex(
      mainQuery =>
        mainQuery.Keyword.toLowerCase() == keyword.toLowerCase()
    );
    if (mainQueryIndex > -1)
      query = this.vitalHttpServices.mainQueryList[mainQueryIndex].Query;
    return query;
  }

  GetData(accountId: number, keyword: string, secondarykeys: any) {
    return this.vitalHttpServices.GetData(this.getParameters(accountId, keyword, secondarykeys) 
    );

  }

  // GetAssociatedOrgGUID(accid: string){
  //   let queryResult: any;
  //   let queryVariable = { "accid": accid };
  //   let query = this.GetQuery("vdguidassocfacility");
  //   queryResult = this.commonService.GetCardRequest(queryVariable, query);
  //     this.vitalHttpServices.GetData(queryResult).subscribe(data => {
  //       this.orgguid = data.data.submenuData;
  //       for(let i=0; i<this.orgguid.length; i++){
  //         let abc = this.orgguid[i].OrganizationGUID;
  //         this.orgguidArray.push(abc);
  //       }
  //       this.datashare.getGuids(this.orgguidArray)
  //   });
  // }

  private getParameters(accountId: number, keyword: string, secondarykeys: any) {
    let queryResult: any;
    let queryVariable;
    if (keyword == 'vdinformation' || keyword === 'vdjarprinter' || keyword === 'vdcassetteprinter' ||
      keyword === 'vdslideprinter' || keyword === 'vddocumentprinter' || keyword === 'vdtechnicalsheetprinter' ||
      keyword === 'vdmedicaldeviceprinter' || keyword === 'vdprinteractions') {
      queryVariable = { orgId: sessionStorage.getItem('orgid'), id: accountId.toString() };
    } else if (keyword === 'addendumreason' || keyword === 'amendmentreason' || keyword === 'correctionreason') {
      queryVariable = { orgid: sessionStorage.getItem('orgid'), templatetype: keyword };
    } else if (keyword === 'addendumnotes') {
      queryVariable = { orgid: sessionStorage.getItem('orgid'), templatetype: keyword, casetype: secondarykeys.casetype.toString() };
    } else if (keyword === 'getOrganizationReviseTemplatesReason') {
      queryVariable = secondarykeys;
    }
    else if (keyword === 'accountgetlogo') {
      queryVariable = { accountid: accountId.toString() };
    }
    else if(keyword=='MicroCodesUserLevel')
    {
      queryVariable = { accid: this.commonService.selectedRolesUserIDForMicroCodes ? this.commonService.selectedRolesUserIDForMicroCodes.toString():accountId.toString() };
    }
    else if(keyword=='usercasecomments')
    {
      queryVariable = { accid: this.commonService.selectedUserForCaseComments ? this.commonService.selectedUserForCaseComments.toString():accountId.toString() };
    }
    else if (keyword== 'getUserLevelcustomabbreviations'){
      queryVariable={userid : accountId.toString()}
    }
    else if(keyword=='UserAdequacyCodes')
    {
      queryVariable = { accid: this.commonService.selectedUserForAC ? this.commonService.selectedUserForAC.toString():accountId.toString() };
    }
    else if(keyword=='associatedaccounts' ){
      queryVariable = { filterparameter: "accountid=\""+accountId+"\"or associatedaccountid=\""+accountId+"\"" };
    }
    else if (sessionStorage.getItem('deploymentKey').match(/thx/i) && keyword == 'casetypestainlist') {
      keyword = 'casetypestainlistthx'
      queryVariable = { accid: accountId.toString() };
    }
    else if (keyword == 'getudfdetailsforP4' || keyword == 'getudfdetails') {
      queryVariable ={    orgid: sessionStorage.getItem('org_id').toString(),
      casetype: secondarykeys.casetype.toString(),
      filter: "accountid = null or accountid =\"" +accountId+"\""
    }
    }
    else if(keyword == 'newmanageruleouts' && this.vitalHttpServices.deploymentKey.match(/thx/i)) {
      keyword += 'p4';
      queryVariable = new Object();
    }
    else {
      queryVariable = { accid: accountId.toString() };
    }
    if (secondarykeys.casetype) {
      queryVariable.casetype = secondarykeys.casetype.toString();
    }
    if (secondarykeys.orgid) {
      queryVariable.orgid = secondarykeys.orgid.toString();
    }
    let query: string = '';
    query = this.GetQuery(keyword);
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    return queryResult;
  }
}
