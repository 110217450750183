<div class="modal-dialog ExecutesqlDialog" id="my-modal-dialog">
  <div class="modal-content">
    <!-- Modal Header -->
    <div class="modal-header admin-model-header">
      <h3 class="modal-title w-100 admin-model-header-txt">Execute SQL</h3>
      <!-- <span type="button" data-dismiss="modal" (click)="CloseExecuteSql()" class="mr-2 d-flex">
        <img src="../../../../assets/icons/Mini_Icon.svg" alt="Minimize" class="material-icons md-24 cursor mr-2"
          title="Minimize">
        <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24 cursor"
          title="Close">
      </span> -->
    </div>

    <!-- Modal body -->
    <div class="modal-body col-sm-12 d-flex m-0" id="my-modal-body">
      <mat-tab-group animationDuration="0ms" class="main-tab-group p-0 m-0 tab-wrapper" [selectedIndex]="selectedTab"
        (selectedTabChange)="onClickInSqlTab($event)">
        <mat-tab class="row col-sm-12" *ngFor="let tab of ExecuteSqlTabArray; let tabIndex = index"
          [label]="tab.TabName">
          <ng-template mat-tab-label>{{ tab.ResultContent.Deployment.toUpperCase()}}
            <div *ngIf="tab.Count>0">
              <a title="Close" class="text-danger ml-4" (click)="removeThisTab(tabIndex)"><em
                  class="fa fa-remove text-xs"></em></a>
            </div>
          </ng-template>
          <div class="container-fluid col-sm-12 mt-2 p-0">
            <div class="col-sm-12">
              <div class="row col-sm-12 d-flex">
                <div class="col-sm-2">
                  <mat-form-field appearance="outline" class="frmMatSelect pr-0 ml-1 w-100">
                    <!-- <mat-label *ngIf="defaultdeploymentkey && defaultdeploymentkey !== 'Execute Query'"><strong>{{tab.ResultContent.Deployment}}<span class="error-msg">*</span></strong></mat-label> -->
                    <mat-label><strong>Deployment<span class="error-msg">*</span></strong></mat-label>
                    <mat-select disableOptionCentering value="{{tab.ResultContent.Deployment.toUpperCase()}}"
                      style="border: none !important;">
                      <mat-option *ngFor="let i of DeploymentKeys" (onSelectionChange)="selectDeployment(tab,$event)"
                        value="{{i.toString().toUpperCase()}}">{{i}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-sm-9">
                  <textarea class="col-sm-12 border-style execute-wrap" rows="5" id="idQuerytxt"
                    [(ngModel)]="tab.ResultContent.Querytxt" placeholder="Execute Query" required></textarea>
                </div>

                <div class="col-sm-1 p-0">
                  <div class="col-sm-1 p-0 ml-1 mb-3 mt-2">
                    <button [disabled]="tab.ResultContent.Deployment == 'EXECUTE SQL'" mat-raised-button
                      class="admin-btn-success w-100 exec-wrap" (click)="SubmitExecuteSql(tab.ResultContent)"
                      matTooltip="Execute" matTooltipPosition="after">
                      <em class="fa fa-lg fa-play-circle"></em>
                    </button>
                  </div>

                  <div class="col-sm-1 p-0 ml-1">
                    <button [disabled]="tab.ResultContent.Deployment == 'EXECUTE SQL'" mat-raised-button
                      class="admin-btn-success w-100" (click)="SubmitExecuteSvrObj(tab.ResultContent)"
                      matTooltip="Execute Server Objects" matTooltipPosition="after">
                      <em class="fa fa-lg fa-cogs"></em>
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 p-0 no-v-scroll" *ngIf="tab.ResultContent.sqlResult && tab.ResultContent.sqlResult.labels">
                <readonlygrid-container [component]="'Execute SQL'" [valueShare]="tab.ResultContent" [templateData]="tab.ResultContent.sqlResult">
                </readonlygrid-container>
              </div>
              <div class="row col-12 p-2 m-0 mt-2"
                *ngIf="tab.ResultContent.exeSvrObjResult && tab.ResultContent.exeSvrObjResult != ''">
                <br />
                <textarea id="txtareaExeSvrObj" name="txtareaExeSvrObj" rows="10"
                  [(ngModel)]="tab.ResultContent.exeSvrObjResult">
              </textarea>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
      <div class="p-0 mt-1 ml-2">
        <img src="../../../assets/icons/Create.svg" alt="Create" class="material-icons md-24 cursor"
          (click)="AddSqlTab()" title="Create">
      </div>
    </div>
    <!-- Modal footer -->
    <div class="modal-footer footer-align"></div>
  </div>
</div>
