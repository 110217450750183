<div class="title">
 
  <div  class="mr-auto p-2 pl-4 "><h2 mat-dialog-title class="vdm-heading"><b>{{data.header}}</b></h2> </div>
   <div class="ml-auto p-3 pr-4 cursor">
    <img class="ml-auto cursor-pointer" mat-dialog-close src="/assets/icons/Close_black.svg" alt="cross" />
    </div>
    <!-- <mat-icon mat-dialog-close>close</mat-icon></div> -->
</div>
<mat-dialog-content *ngIf="data.preview.toLowerCase() == 'qualityattribute'">
  <section *ngIf="data.qualityAttribueList as qualityAttribueList ">
    <div>      
      <div class="qualityAttribute-main" *ngFor="let item of qualityAttribueList">
        <div class="d-flex">
          <span class="ml-1">{{ item.attrGroupName.attrname }}</span>
          <div class="line"><mat-divider></mat-divider></div>
        </div>
        
        <div *ngIf="item.attrList.length > 0" class="items-list">
          <div class="items" *ngFor="let item of item.attrList">
            <p>{{ item.attrname }}<span>
               <img class="ml-2"                   *ngIf="item.iscomment" src="../../../../assets/images/comment.png" /></span></p>
          </div>
        </div>
      </div>
    </div>
  </section>
</mat-dialog-content>
<mat-dialog-content *ngIf="data.preview.toLowerCase() ==  'flags' ">
  <!-- <div class="vdm-heading">
    <span>{{data.header}}</span>
  </div>  -->
  <section *ngIf="data.qualityAttribueList as flagList ">   
    
      <div class="items-list">
        <div class="items" *ngFor="let item of flagList">
          <p>{{ item.attrname }}</p>
        </div>
      </div>
  
  </section>
</mat-dialog-content>
<mat-dialog-content *ngIf="data.preview.toLowerCase() == 'stains'">
  <section *ngIf="data.qualityAttribueList|async as satinData ">
    <div class="p-3">
      <div class="qualityAttribute-main" *ngFor="let i of satinData">
        <div class="d-flex">
          <span class="ml-1">{{i.panel}}</span>
        </div>
        <div *ngIf="i.procedures.length > 0" class="items-list">
          <div class="items" *ngFor="let prodedure of i.procedures">
            <p>{{prodedure.Procedure_Name}}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</mat-dialog-content>
