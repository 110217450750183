import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as wjcCore from '@grapecity/wijmo';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';

@Component({
  selector: 'app-manage-payers-lookup',
  templateUrl: './manage-payers-lookup.component.html',
  styleUrls: ['./manage-payers-lookup.component.scss']
})
export class ManagePayersLookupComponent {
  lookuppage: boolean = false;
  searchData: boolean = false;
  PayersGridData: wjcCore.CollectionView<any>;//remove
  // @Output() gridshow: boolean;
  @Input() templateData: any;
  importData: any[] = [];
  dropdownvalues: any;
  listedGridHeader: any;
  lookupdata: any;
  payernameSearch: any;
  electronicidSearch: any;
  zipSearch: any;
  stateSearch: any;
  citySearch: any;
  sheetHeader: any[];
  radioChecked: boolean = false;
  deploymentkey: string = '';
  statedropdown: any[];
  PayerList: any;
  @Output() searchClicked: EventEmitter<any> = new EventEmitter<any>();

  gridHeaderList: any;
  gridheader: string[];
  mainlist: boolean = false;
  SubMenuCardModel: SubMenuCardModel;
  statedropdownlist: any[];
  GridData: any;
  isSelected: boolean=false;
  constructor(
    private _fb: FormBuilder,
    private datashare: DataShareService,
    private commonService: CommonService,
    private vitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar,
    private _dialogRef: MatDialogRef<ManagePayersLookupComponent>,
    private ngxService: NgxUiLoaderService,
    public templateHeaders: TemplateHeadersService,
    @Inject(MAT_DIALOG_DATA) public config: any,
  ) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, vitalHttpServices, datashare);
  }
  ngOnInit(): void {
    this.deploymentkey = sessionStorage.getItem('deploymentKey');
    this.dropdownValuesForStates();
    this.sheetHeader = ["PayerName", "ElectronicPayerID", "IsElectronicPayer", "PayerFamily", "Address", "State", "Phone", "Fax", "Email"];
    this.LookupForm.patchValue({
      payerName: this.config.prefilledData.payerName,
      electronicPayerId: this.config.prefilledData.electronicPayerId,
      city: this.config.prefilledData.city,
      state: this.config.prefilledData.state,
      zip: this.config.prefilledData.zip,
      addressandcontact: this.config.prefilledData.addressandcontact
    })
    this.search(); 
  }
  displayedColumns(item: any, header: any) {
    return item[header]
  }
  LookupForm = this._fb.group({
    payerName: ['', Validators.required],
    electronicPayerId: [''],
    city: [''],
    state: [''],
    zip: [''],
    addressandcontact: [false]
  })

  returnBtn() {
    this.templateData = false;
  }
  dropdownValuesForStates() {
    let query = this.SubMenuCardModel.GetQuery('managepayersstate');
    let queryVariable = {};
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(
      data => {
        if (!data.errors) {
          if (data?.data?.submenuData) {
            this.statedropdown = [...new Set(data?.data?.submenuData.map(obj => obj.state.toString().trim()))];
          }
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  stateValues($event: any, controlName: any) {
    this.LookupForm.patchValue({ [controlName]: $event });
  }

  search() {
    this.searchData = true;
    let query = this.SubMenuCardModel.GetQuery('payerssearch');
    this.lookupdata = this.LookupForm.controls;
    this.payernameSearch = (
      (this.lookupdata.payerName.value == '' || !this.lookupdata.payerName.value) ?
        this.config.prefilledData.payerName.toString().trim() :
        this.lookupdata.payerName.value.toString().trim()
    );
   if(!this.lookupdata.payerName.value || this.lookupdata.payerName.value.toString().trim() == '') {
    this._snackbar.open('Please provide payername','close')
    return;
   }
    this.electronicidSearch = (
      (this.lookupdata.electronicPayerId.value == '' || !this.lookupdata.electronicPayerId.value) ?
        this.config.prefilledData.electronicPayerId.toString().trim() :
        this.lookupdata.electronicPayerId.value.toString().trim()
    );

    this.zipSearch = (
      (this.lookupdata.zip.value == '' || !this.lookupdata.zip.value) ?
        this.config.prefilledData.zip.toString().trim() :
        this.lookupdata.zip.value.toString().trim()
    );

    this.stateSearch = (
      (this.lookupdata.state.value == '' || !this.lookupdata.state.value) ?
        this.config.prefilledData.state :
        this.lookupdata.state.value.toString().trim()
    );

    this.citySearch = (
      (this.lookupdata.city.value == '' || !this.lookupdata.city.value) ?
        this.config.prefilledData.city.toString().trim() :
        this.lookupdata.city.value.toString().trim()
    );

    this.electronicidSearch = this.electronicidSearch === "" ? null : this.electronicidSearch;
    this.zipSearch = this.zipSearch === "" ? null : this.zipSearch;
    this.stateSearch = this.stateSearch === "" ? null : this.stateSearch;
    this.citySearch = this.citySearch === "" ? null : this.citySearch;
    let queryString =null;
     
    if(this.payernameSearch != null) {
      queryString = "organizationname like \"%" + this.payernameSearch + "%\"";
    }
    if (this.electronicidSearch != null) {
      if (queryString != null)
        queryString += "electronicpayerid in \"" + this.electronicidSearch + "\":string[]"
      else
        queryString = "electronicpayerid in \"" + this.electronicidSearch + "\":string[]"
    }
    if (this.zipSearch != null) {
      if (queryString != null)
        queryString += "zip in \"" + this.zipSearch + "\":string[]"
      else
        queryString = "zip in \"" + this.zipSearch + "\":string[]"
    }
    if (this.electronicidSearch != null) {
      if (queryString != null)
        queryString += "city like \"%" + this.payernameSearch + "%\""
      else
        queryString = "city like \"%" + this.payernameSearch + "%\""
    }
    if (this.electronicidSearch != null) {
      if (queryString != null)
        queryString += "state like \"%" + this.stateSearch + "%\""
      else
        queryString = "state like \"%" + this.stateSearch + "%\""
    }

    let queryVariable = {"filterParameter" : queryString};
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult).subscribe(
      data => {
        if (!data.errors) {
          this.ngxService.stop();
          this.searchClicked.emit({}); 
          this.PayerList = data.data.submenuData; 
          if (data.data.submenuData.length == 0) {
            this.GridData = true;
          }
          else {
            this.GridData = false;
          }
        }
        if (data.length < 0) {
          this._snackbar.open('No records found', 'close');
          return;
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  handleRadioChange(event, data, index: any) {
    var radioButton = event.target;
    if (radioButton.checked) {
      this.isSelected=true;
      if (this.LookupForm.controls.addressandcontact.value == true) {
        data.address1 = '';
        data.address2 = '';
        data.phone = '';
        data.email = '';
      }
      this.importData.push(data);
      this.radioChecked = index;
    }
  }

  saveData() {
    if(!this.isSelected) {
      this._snackbar.open('Please select the row','close');
      return;
    }
    this._dialogRef.close(this.importData);
  }
}


