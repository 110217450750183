<div class="tab-wrap" id="container_maincard" >
  <mat-tab-group animationDuration="0ms" class="main-tab-group" style="background-color: white;"
    [selectedIndex]="selectedTab" (selectedTabChange)="tabChanged($event)">
    <mat-tab *ngFor="let tab of tabs; let tabIndex = index" [label]="tab.title">
      <ng-template mat-tab-label *ngIf="tab.Removable">{{ tab.title }}
        <a title="Close" class="text-danger ml-2" (click)="removeTab(tabIndex)"><em class="fa fa-remove text-xs"></em></a>
      </ng-template>
      <div>
        <div *ngIf="tab.tabData">
          <!-- style="overflow-x: hidden;" -->
          <div *ngIf="tab.tabData.notinitial">
            <vital-general-main-card [mainCard]="tab.tabData.mainCard" [labels]="tab.tabData.labels"
              [menuJson]="tab.tabData.menuJson" [accountid]="tab.tabData.accountid" [cardtype]="tab.tabData.cardtype"
              [secondarykeys]="tab.tabData.secondarykeys" [rolepermission]="tab.tabData.rolepermission"
              [rolemenu]="tab.tabData.rolemenu" (changeTab)="changeTab($event)" [roleMenuPermissions]="tab.tabData.roleMenuPermissions" [GroupCode]="tab.tabData.mainCard.Group_Code"  (scrollUpMaincard)="scrolltoTop($event)" >
            </vital-general-main-card>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div class="nav-item tabitem dr-url-wrap align-center">
    <span class="dx-icon">
      <img alt="icon" src="../../../assets/icons/Icon.png" width="25px" height="auto">
    </span><a (click)="goToLink(dxurl)" mat-raised-button class="dx-url" target="_blank">{{dxurl}}</a>
  </div>
</div>


<!-- <div class="nav nav-md tabWrapper">
    <ul class="nav nav-md tabsSet" (click)="$event.preventDefault();">
        <li class="nav-item tabitem" *ngFor="let tabz of tabs; let i= index" >
            <a class="nav-link" data-toggle="tab" placement="top" *ngIf="tabz.TabName"   matTooltip="{{tabz.title}}" container="body"   title="{{tabz.title}}"
            [selectedIndex]="selectedTab" (click)="tabChanged($event)">
                {{tabz.title}}
                <a class="text-danger ml-2" (click)="removeTab(i)" (click)="$event.preventDefault();"  title="Close" >
                    <em class="fa fa-remove text-xs"></em></a>
            </a>
        </li>
    </ul>
</div>
    <div class="tab-content">
       <ng-content></ng-content>
    </div> -->
