<div class="modal-dialog AddUserDialog" [ngClass]="{ 'modal-dialog AddUserDialog': fromComponent != 'SetupNewFacility', 'SetupNewFacilitymodal SetupNewFacilityAddUserDialog' : fromComponent == 'SetupNewFacility' }">
  <form [formGroup]="frm_AddUsergroup">
    <div class="modal-content header-wrapss">
      <!-- Modal Header -->
      <div class="modal-header admin-model-header header-freeze">
        <h3 class="modal-title w-100 admin-model-header-txt" *ngIf="fromComponent != 'SetupNewFacility'">Create New
          User
        </h3>
        <h3 class="modal-title w-100 admin-model-header-txt" *ngIf="fromComponent == 'SetupNewFacility'">Create New
          User
        </h3>
        <span type="button" data-dismiss="modal" (click)="AddUsergroupClose(true)" class="mr-2">
          <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons cursor" title="Close">
        </span>
      </div>
      <br />
      <!-- Modal body -->
      <div class="modal-body">
        <div class="container-fluid popup-wrap p-0 m-0">
          <div class="row mb-1 mx-0">
            <div class="col-lg-6 p-0 m-md-1 m-lg-0">
              <div class="row m-0 p-0">
                <div class="col-md-4 p-0 m-0">
                  <label for="DKeys3"><strong>
                      Deployment:&nbsp;&nbsp;<span class="text-danger pr-1">*</span></strong></label>
                </div>
                <div class="col-md-8 p-0 m-0">
                  <select name="DeploymentKeys" id="DKeys3" [ngClass]="{'form-control': true, 'selectIconChanges' : this.fromComponent == 'SetupNewFacility'}"
                    formControlName="frmDeploymentKey" (change)="onChangeDep($event.target.value)">
                    <option value="none" disabled selected>--Select--</option>
                    <!-- <option *ngFor="let i of DeploymentKeys" value={{i}}>{{i}}</option> -->
                    <ng-container *ngFor="let i of DeploymentKeys">
                      <option value="{{ i.toString().toUpperCase() }}">{{ i }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-lg-6 p-0 m-md-1 m-lg-0">
              <div class="row m-0 p-0">
                <div class="col-md-4 p-0 m-0 pl-2">
                  <label for="OrgSearch"><strong>Group:&nbsp;&nbsp;<span class="text-danger pr-1">*</span></strong></label>
                </div>
                <div class="col-md-8 p-0 m-0">
                  <input type="text" class="form-control" formControlName="frmOrganization" />
                  <!-- value={{SelectedOrg}} -->
                  <!-- <span *ngIf="this.fromComponent != 'SetupNewFacility' && defaultdeploymentkey" (click)="funcShowPopOrg()" [ngStyle]="frm_AddUsergroup.value.frmDeploymentKey == 'none' ? {'pointer-events': 'none'} : {'pointer-events': 'auto'}"
                    style="padding: 5px; display: block;color:blue; text-decoration: underline; float: right;"> -->
                  <span *ngIf="!isselectorganization && !selecteddefaultdeploymentkey  || selecteddefaultdeploymentkey ==='ALL'"
                    (click)="funcShowPopOrg()" [ngStyle]="frm_AddUsergroup.value.frmDeploymentKey == 'none' ? {'pointer-events': 'none'} : {'pointer-events': 'auto'}"
                    style="padding: 5px; display: block;color:blue; text-decoration: underline; float: right;cursor: pointer;">
                    <!-- data-toggle="collapse" data-target="#PopOrg"> -->
                    <em class="fa fa-check-circle mr-2"></em>Select Group
                  </span>

                  <span *ngIf="selecteddefaultdeploymentkey && selecteddefaultdeploymentkey !=='ALL' && !isselectorganization"
                    (click)="funcShowPopOrg()" style="padding: 5px; display: block;color:blue; text-decoration: underline; float: right;cursor: pointer;">
                    <!-- data-toggle="collapse" data-target="#PopOrg"> -->
                    <em class="fa fa-check-circle mr-2"></em>Select Group
                  </span>

                  <span *ngIf="!selecteddefaultdeploymentkey  || selecteddefaultdeploymentkey ==='ALL' && isselectorganization"
                    style="padding: 5px; display: block;color:rgb(156, 156, 172); text-decoration: underline; float: right;cursor: pointer;">
                    <!-- data-toggle="collapse" data-target="#PopOrg"> -->
                    <em class="fa fa-check-circle mr-2"></em>Select Group
                  </span>

                </div>
              </div>
              <div class="row m-0 p-0" *ngIf="NoDeploymentKeyAlert">
                <div class="col-md-12 p-0 m-0 text-left text-danger">
                  * Select Deployment Key
                </div>
              </div>
            </div>
          </div>
          <div>
            <span *ngIf="!createLiveUser">
              <strong>
                <span>Note:</span>
              </strong> Users with all roles can be created only in test environments, In live environment user can be created only with specific configured roles. 
              Please contact VitalAdmin support.
            </span>
          </div>
          <div class="row mb-1">
            <div class="col-lg-6 p-0 m-0"></div>
            <div class="col-lg-5 p-0 m-0">
              <div id="PopOrg" class="collapse mt-3 px-3 pt-1 pb-3" style="overflow-y: auto; max-height: 400px; min-height:150px">
                <span type="button" data-dismiss="modal" (click)="closeManualPopOrg()" class="mr-2">
                  <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24 cursor"
                    title="Close">
                </span>

                <form [formGroup]="frm_AddUserSelectOrgGroup">

                  <div class="row m-0 p-0">
                    <div class="col-md-4 p-0 m-0 text-right pr-1">
                      <label for="drpOrgSearch"><strong>Search Group:&nbsp;&nbsp;</strong></label>
                    </div>
                    <div class="col-md-8 p-0 m-0">
                      <select name="drpOrgSearch" id="drpOrgSearch" class="form-control w-100" formControlName="frmDrpOrgSearch">
                        <option value="Group ID">Group ID</option>
                        <option value="Group Name">Group Name</option>
                      </select>
                    </div>
                  </div>

                  <div class="row m-0 p-0">
                    <div class="col-md-4 p-0 m-0 text-right pr-1">
                      <label for="drpOrgSearch"><strong></strong></label>
                    </div>
                    <div class="col-md-8 p-0 m-0 mt-2 mb-2">
                      <input type="text" id="inpOrgSearch" class="form-control p-2" name="inpOrgSearch" placeholder="Enter Group"
                        formControlName="frmOrgSearchInpTxt">
                      <button (click)='btnSearchOrg(frm_AddUserSelectOrgGroup.value)' [disabled]="frm_AddUserSelectOrgGroup.invalid"
                        id="btnSearchOrg" [ngStyle]="frm_AddUserSelectOrgGroup.invalid && {'background-color': '#e5e5e5'}">
                        <em class="fa fa-search"></em>
                      </button>
                    </div>
                  </div>

              <div class="row m-0 p-0" *ngIf="showOrgData">
                <div *ngFor="let data of templateData; let i = index; let even = even; let odd = odd" class="col-md-6 p-1 m-0 displaySearchedOrg"
                  (click)="setOrganization(data)" style="cursor: pointer;">
                  {{i+1}} - {{data.organizationname }} ({{data.organizationid}})
                </div>
              </div>
              <div class="col-sm-12">
                <span class="w-50 text-center mt-4" *ngIf="NoOrgData" class="nodata-designs">
                  No Data Found
                </span>
              </div>
  </form>
</div>
</div>
</div>
<div class="row p-0 m-0">
  <div class="col-12 ShowFrmAddUserDetail" *ngIf="ShowFrmAddUserDetail" style="border-top: 1px solid #e5e5e5; overflow-y: hidden;">
    <app-manage-user [editMode]="false" [fromComponent]="'homepage'" [organizationLoginType]="organizationLoginType"
      [organizationID]="organizationID" [DeploymentKeys]="frm_AddUsergroup.controls.frmDeploymentKey.value" [creatLiveFlag] ="createLiveUser"></app-manage-user>
  </div>
</div>
</div>
</div>
<!-- Modal footer -->
<!-- <div class="modal-footer" style=" border-top: none !important; display: block; justify-content: normal;">
        
      </div>
      <br />
      <br /> -->
</div>
</form>
</div>

<!--Login Deatils-->
<div *ngIf="iflogindeatils">
  <div class="col-sm-8">
    <div class="modal overlay" id="alluserModal" role="dialog">
      <div>
        <!-- Modal content-->
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header admin-model-header">
            <h3 class="modal-title w-100 admin-model-header-txt">Login Details</h3>
          </div>
          <div class="modal-body popup-wrap" style="padding: 15px 100px;">
            <div class="row">
              <p id="statuschanges">User Created Successfully.</p>
            </div>
            <div class="row pl-5">
              <p style="font-weight: 500;">Login Name: &nbsp; </p>&nbsp;<img alt="copy" class="mb-2 copyIcon cursor"
                (click)="copy(valueloginname)" src="/assets/images/copy.svg" />&nbsp;
              <p>{{valueloginname}} </p>
            </div>
            <div class="row pl-5">
              <p style="font-weight: 500;">Password: &nbsp; </p>&nbsp;<img alt="copy" class="mb-2 copyIcon cursor"
                (click)="copy(passworddata)" src="/assets/images/copy.svg" />&nbsp;
              <p>{{passworddata}} </p>
            </div>
          </div>
          <div class="modal-footer align-center">
            <div class="align-center">
              <button mat-raised-button class="admin-btn-success align-center" (click)="AddUsergroupClose(true)" type="button">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>