import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { Store } from '@ngrx/store';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription, take } from 'rxjs';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { TemplateViewService } from 'src/app/core/utility/template-view.service';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import * as XLSX from 'xlsx';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { VitalAdminTabService } from '../../tab.service';
import { LockUnlockUserDialogComponent } from 'src/app/base/popup/lock-unlock-user-dialog/lock-unlock-user-dialog.component';

@Component({
  selector: 'app-custom-abbreviations',
  templateUrl: './custom-abbreviations.component.html',
  styleUrls: ['./custom-abbreviations.component.scss']
})
export class CustomAbbreviationsComponent implements OnInit{
  SubMenuCardModel: SubMenuCardModel;
  @Input()  templateData: any;
  public SubmenuCardModel;
  public DeploymentKeys = [];
  noDataFound: boolean;
  gridShow: boolean;
  gridheader: string[];
  listedGridHeader: any = [];
  gridData: wjcCore.CollectionView<any>;
  showPaginationMainGrid: boolean;
  gridHeaderList: any;
  listHeaderbackup: any;
  uploadBtn: boolean;
  createBtn: boolean;
  editBtn: boolean;
  exportBtn: boolean;
  copyBtn: boolean;
  auditableColumns: any;
  saveOrEditCompleted: any;
  activityEntity: any;
  deleteBtn: any;
  destDeployment: string;
  selectedFilter = 'Group';
  userSessionDetails: any;
  sourceApp: string;
  storesub:Subscription = new Subscription();
  dbName: string;
  selectedData = { caseType: '', userId: '' };
  userFlag: boolean;
  userRoles: any[];
  selectedRole: any;
  selectedUser: any;
  userSelected: boolean;
  caseTypeList: any;
  tempCasetype: any[];
  noRole: boolean = false;
  copyfromScreen: boolean;
  tempExcelArr: any=[];
  copyFromOrgFrm = this._fb.group({
    frmDeplyment: '',
    frmOrganization: '',
    frmUser: ''
  })
  copyOrganizations: any;
  searchResult: any[];
  srcOrgName: any;
  srcOrgid: any;
  searchUserInput: string;
  srcUserid: string;
  searchInput: any;
  userListArray: any[];
  searchuserlist: any[];
  srcUserName: any;
  excelDataArray: any;
  excelDownloadArray: any[];
  showInvalidColumns: boolean;
  invalidColumns: string="";
  uploadbtnClicked: any = false;
  addEditClicked: any;
  showDelete: boolean;
  fromUserCard: boolean;
  copyBtnClicked: boolean;
  gridWidth: number;
  grid: number;
  gridArray: any;
  sheetHeader: any[];
  workBook: any;
  postUpload: any;
  selector: any;
  configuredData: any;
  altermessage: any;
  altermessageheader: string;
  tempSelectedUserId: any;
  cardContext: string;
  griddata: any[];
  toolTipText: any;
  optionToolTipText: any;
  checkStatus: boolean = false;
  searchText: any;
  constructor(
    private ngxService: NgxUiLoaderService,
    private datashare: DataShareService,
    public tabService: VitalAdminTabService,
    public VitalHttpServices: VitalHttpServices,
    public commonService: CommonService,
    private templateHeaders: TemplateHeadersService,
    private _snackbar: MatSnackBar,
    public activityService: ActivityTrackerService,
    private dialog: MatDialog,
    private labadminService: LabadminService,
    public templateViewService: TemplateViewService,
    private labAdminSessionService : LabadminSessionService,
    private _fb:FormBuilder,
    private store: Store<{ breadcrum : [] }>
  ) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      VitalHttpServices,
      datashare
    );
  }
  async ngOnInit() {
    // this.getAllCustomAbbreviations();
    this.destDeployment=sessionStorage.getItem('DeploymentKey').toUpperCase();
    let checkLaunchUrl=window.location.href.split('/#/')[1]
    if(checkLaunchUrl.toString().toLocaleLowerCase()!="home"){
     this.sourceApp = 'VitalDx'
     let  _this = this;
     await this.labAdminSessionService.getLabAdminSession.pipe(take(1)).subscribe(async session => {
       if (session["userDetails"] && Object.keys(session["userDetails"]).length > 1) {
        _this.userSessionDetails = session["userDetails"]
        _this.sourceApp = 'VitalDx'
        let menuObj={}
        this.storesub = this.store.select("breadcrum").pipe(take(1)).subscribe(async result => {
          menuObj = result[result.length -1]})
          this.storesub.unsubscribe();
          await _this.commonService.getCommmonTemplateData(menuObj,_this.userSessionDetails);
         _this.templateData=_this.labadminService.templateData;
         _this.dbName = _this.labadminService.deploymentKey;
         sessionStorage.setItem('deploymentKey', this.dbName);
         sessionStorage.setItem('org_id', this.templateData.secondarykeys.OrganizationId);
         this.addGridData(this.templateData.submenuData);
         this.GetButtonAccess(this.labadminService.subMenuPermissions)
         //this.commonService.createContext(this.templateData.secondarykeys, '', this.templateData.menuURL);
         this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': 'Lab' },{'key':'Pathology Management','value':this.templateData['menuURL']}] })
         this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
         this.getAuditableDetails(this.labadminService.templateData.menuURL)
       }})
     
    }
    else{
      if (sessionStorage.getItem("search_context").toLowerCase() == "users" || sessionStorage.getItem("search_context").toLowerCase() == 'physician' || sessionStorage.getItem("search_context").toLowerCase() == 'pathologist' || sessionStorage.getItem("search_context").toLowerCase() == "userid" || sessionStorage.getItem("search_context").toLowerCase() == 'physician') {
        this.userFlag = true;
        this.cardContext = 'fromUsers';
        this.userRoles = this.commonService?.userRoles(this.templateData.menuURL.replace(/\s/g, '')); sessionStorage.getItem("search_context").toLowerCase() == "userid";
        if (this.userRoles && this.userRoles.length > 0) {
          this.selectedRole = this.userRoles[0].RoleName.toString();
          this.selectedUser = this.userRoles[0].userid.toString();
          this.tempSelectedUserId = this.userRoles[0].userid.toString();
          this.templateViewService.globalMasterDataForQueries['userRole'] = this.userRoles[0].RoleName.toString();
          this.templateViewService.globalMasterDataForQueries['userId'] = this.userRoles[0].userid.toString();
          this.userSelected = true;
          this.getCaseType();
        }
        else {
          this.noRole = true;
        }   
        this.fromUserCard=true;
      }
      else{
        // this.addGridData(this.templateData.submenuData);
        this.getAllCustomAbbreviations();
        this.fromUserCard=false;
        this.cardContext="fromCaseType"
      } 
      this.getConfigurations();     
      this.destDeployment = sessionStorage.getItem('DeploymentKey').toUpperCase();
      
      if(this.userFlag){
        this.GetButtonAccess(this.VitalHttpServices.SubmenuAction);  
        this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': "parentMenu", 'value': this.templateData.menuURL }] })
      }
      else{
        this.GetButtonAccess(this.VitalHttpServices.CasemenuAction);  
        this.commonService.createContext(this.templateData.secondarykeys, '', this.templateData.menuURL);
      }
      // this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': "parentMenu", 'value': this.templateData.menuURL }] })
       this.getAuditableDetails(this.templateData.menuURL)
      // this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
  
    }
    
  }
  getConfigurations() {
    this.ngxService.start();
    let query = this.VitalHttpServices.GetQuery('CategoryDropDown');
    let queryVariable = { keyword: this.templateData.menuURL.toString(), context: 'bulkFunctionalities' };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult, 'configdb').subscribe(async (Resdata) => {
      this.ngxService.stop();
      if (!Resdata.errors) {
        this.configuredData = JSON.parse(Resdata?.data?.submenuData[0]?.Items_JSON);
        // this.sampleDataAttrAll=structuredClone(this.sampleData);
      }
    }, error => {
      this.ngxService.stop()
      console.error(error);
    })
  }
  getCaseType() {
    this.ngxService.start();
    this.VitalHttpServices.getCasetypesBasedOnRole(this.selectedRole, this.selectedUser).subscribe(
      (res) => {
        this.ngxService.stop();
        this.caseTypeList = res;
        this.getAllCustomAbbreviations();
        // this.filterCaseType = this.microScopicfrm.controls.frmCaseType.valueChanges
        //   .pipe(
        //     startWith(''),
        //     map((user: any) => user && typeof user === 'object' ? user.name || user.id : user),
        //     map((name: string) => name ? this.filterLookupAttrId(name) : this.caseTypeList.slice())
        //   );
        //}
      }, error => (
        this.ngxService.stop(),
        console.error(error)
      ));
  }
  
  GetButtonAccess(CasemenuAction) {
    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = CasemenuAction.find(e => e.Htext == "Templates").SubMenu.find(va=> va.URL == this.labadminService.templateData.menuURL)["ActionButton"]
    }
    else {
      seletedMenuPermissions = CasemenuAction.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    } 
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.uploadBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Create":
          this.createBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "CopyToOrg":
          this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Delete":
          this.deleteBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
      }
    }
  }

  getAllCustomAbbreviations() {
    let query = '';
    let queryVariable = {};
    query = this.SubMenuCardModel.GetQuery('getcustomabbreviations');
    queryVariable = {
      casetype: this.templateData.secondarykeys?.casetype?.toString() || null
      , orgid: sessionStorage.getItem('org_id')
      , userid:this.selectedUser?.toString() || "0"
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult).subscribe(
      data => {
        if (!data.errors) {
          this.templateData.submenuData = data.data.submenuData;
          this.templateData.submenuData && this.templateData.submenuData.length > 0 ? this.addGridData(this.templateData.submenuData) : this.gridShow = true;
          if (!this.templateData?.submenuData?.length) this.gridData = new CollectionView(...[]);
          // this.activityEntity.entityId = '';
          // this.activityService.setActivitySession(this.activityEntity);

        }
        this.ngxService.stop();
      },
      error => {
        // this.gridShow = false;
        console.error(error);
        this.ngxService.stop();
      }
    );
  }

  addGridData(templateData: any) {
    let primary: any = {};
    this.gridHeaderList = this.templateHeaders.templateList;
    for (let i = 0; i < this.gridHeaderList.length; i++) {
      if (this.gridHeaderList[i].templateName == 'CustomAbbreviations') {
        this.listedGridHeader = this.gridHeaderList[i].headers;
        this.listHeaderbackup = JSON.parse(JSON.stringify(this.listedGridHeader))
      }

    }
    let gridarray = [];
    this.gridheader = [
      'Id'
      , 'caseType'
      , 'Abbreviation'
      , 'Phrase'
      , 'MatchCriteria'
      , 'OrganizationID'
      , 'UserId'
      , 'CreatedBy'
      , 'CreatedDate'
      , 'ModifiedBy'
      , 'ModifiedDate'
      , 'Sequence'
      , 'IsUserlevelTemplates'
      , 'IsActive'
      , 'CreatedByName'
      , 'ModifiedByName'
      , 'UserLevelTemplateUserName'
    ]
    for (let i = 0; i < templateData?.length; i++) {
      primary = {};
      for (let [key, value] of Object.entries(templateData[i])) {
        if (key == 'Abbreviation' || key == 'Phrase') {
          if (value == '' || value == null) {
            value = 'Not Specified';
            primary[key] = value;
          }
          primary[key] = value;
        }
        for (let j = 0; j < this.gridheader.length; j++) {
          if (key === this.gridheader[j]) {
            primary[key] = value;
          }
        }
      }
      gridarray.push(primary)
    }
    gridarray.sort((a, b) => a.Sequence < b.Sequence ? -1 : 1)
    if(this.sourceApp == 'VitalDx'){
      gridarray = gridarray.filter(item => {
        return (!this.selectedData.caseType || item.caseType.toLowerCase() === this.selectedData.caseType.toLowerCase()) &&
          (this.selectedData.userId ? item.UserId === this.selectedData.userId : !item.UserId);
      });
    }
    this.gridData = new CollectionView(gridarray);
    this.gridShow = true;
    this.noDataFound = false;
    this.showPaginationMainGrid = gridarray.length > 10 ? true : false;
    return gridarray;
  }

  async recieveObjFromListing(object: any) {
    let flagObject;
    if(this.getPlainTextFromRichText(object.newData.Phrase)){
      this._snackbar.open("Please enter valid Phrase","Close");
      this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
      return;
    }
    object.newData = { ...object.newData, ...{ 'Context': 'Custom Abbreviations' } };
    if (this.userFlag) {
      object.newData = { ...object.newData, ...{ 'UserId': this.selectedUser } };
      if (object && object.newData && object.newData.hasOwnProperty('CaseType')) {
        delete object.newData.CaseType;
      }
    }
    if (object.newData.UserId) {
      object.newData = { ...object.newData, ...{ 'IsUserlevelTemplates': 1 } };
      await this.getListUsers("CE",object.newData.UserId);
      flagObject = this.userListArray.find(va => va.userid == object.newData.UserId);
    }
    else {
      object.newData = { ...object.newData, ...{ 'IsUserlevelTemplates': 0 } };
      object.newData.UserId = null
    }
    if (object.newData.Action == 'Edit') {
      object.newData['Id'] = object.oldData['Id']
    }
  
    if (object.newData.Action == 'Create' && object?.newData?.UserId && !(flagObject?.IsUserLevelAbbreviationEnabled)) {      
      this.dialog.open(LockUnlockUserDialogComponent, {
        width: '30vw',
        data: {
          imagePath: '../../../../assets/icons/user_icons/warning.svg',
          header: 'User level Create',
          message: 'All the Group level abbreviations will be copied to User level. Any changes made will be specific to the user only.',
          buttonname: 'OK',
          type: "mail-sent"
        }
      })
      .afterClosed().toPromise().then((result) => {
          this.insertOrEditCustomAbbreviations(object)
        // }
      });
    }
    else {
      this.insertOrEditCustomAbbreviations(object)
    }
  }
  getPlainTextFromRichText(html) {
    var tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv?.textContent?.trim() != '' ? false : true;
  }

  insertOrEditCustomAbbreviations(data) {
    this.ngxService.start();
    this.VitalHttpServices.templateManagementCE(data.newData, this.destDeployment).subscribe(response => {
      if (response.status == "Success") {
        this.selectedData = {...{ caseType: '', userId: '' }};
        this.selectedFilter = 'Group'
        if (response.content.message.toString().includes('already exist')) {
          this._snackbar.open(response.content.message, "Close");
          this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
          this.ngxService.stop();
          return
        }
        else {
          data.newData.IsActive = data.newData.IsActive == 1 ? 'Active' : 'Inactive'
          if (data.newData.Action === 'Create') {
            this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            // response.content['Id'] = response.content['userId']
            delete data.newData.Sequence
            this.commonService.auditDetails('id', 'Abbreviation', [response.content], [data.newData], data.newData.Action, this.templateData, this.auditableColumns);
            this._snackbar.open(response.content.message, "Close")
            if (!this.userFlag) {
              this.selectedUser = "0";
              this.cardContext="fromCaseType"
            }
            this.getAllCustomAbbreviations();
          }
          else if (data.newData.Action === 'Edit') {
            this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
            this.commonService.auditDetails('Id', 'Abbreviation', [data.oldData], [data.newData], data.newData.Action, this.templateData, this.auditableColumns);
            this._snackbar.open(response.content.message, "Close")
            if (!this.userFlag) {
              this.selectedUser = "0";
              this.cardContext="fromCaseType"
            }
            this.getAllCustomAbbreviations();
          }
          else {
            this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
            this._snackbar.open(response.content.message, "Close")
          }
          // this.addGridData(this.templateData.submenuData);
        }
      }
      else {
        this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
        this._snackbar.open("failed!", "Close")
      }
      this.ngxService.stop();
    }, error => {
      this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
      this.ngxService.stop();
      console.error(error);
    })
  }
  excelGridData(data) {
    this.ngxService.stop();
    this.griddata = [];
    this.gridArray=[];
    let primary = {};
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(data[i])) {
            primary[key] = value;

          }
          this.gridArray.push(primary);
        }
      }
      this.griddata = this.gridArray;
      if (!this.postUpload) {
        this.sheetHeader.includes("Result") && this.sheetHeader.splice(this.sheetHeader.indexOf("Result"), 1);
      } else {
        !this.sheetHeader.includes("Result") && this.sheetHeader.splice(0, 0, "Result")// this.sheetHeader.push("Status");
      }
      this.gridWidth = (170 * this.sheetHeader.length) + 37;
      if (this.gridWidth > 1300) {
        this.gridWidth = 1300;
      }
    }
    else {
      this.gridWidth = 0;
    }
  }
  displayedColumns(item: any, header: any) {
    return item[header]
  }
  deleteTemplate(value) {
    if (!this.deleteBtn) {
      let deleteId;
      deleteId = { ...{ 'Context': 'Custom Abbreviations', 'Action': 'Delete', 'Id': value.rowData.Id } };
      value.rowData = { ...value.rowData, ...{ 'Context': 'Custom Abbreviations', 'Action': 'Delete' } };
      value.rowData.IsActive = value.rowData.IsActive == 'Active' ? 1 : 0;
      let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
        disableClose: true,
          autoFocus: false,
        width: '400px',
        panelClass: 'admin-custom-popup',
        // data: { header: "", message: "This action will permanently delete the selected row.", alert: "Do you want to continue?", continue: "yes", cancel: "no" }
        data: { header: "", message: "Are you sure you want to delete this record?", continue: "Delete", cancel: "Cancel" }
      })
      return dialogRef.afterClosed().toPromise().then(result => {
        if (result) {
          this.VitalHttpServices.templateManagementCE(deleteId, this.destDeployment).subscribe(
            () => {
              this.selectedFilter = 'Group'
              value.rowData.IsActive = value.rowData.IsActive == 1 ? 'Active' : 'Inactive';
              this.commonService.auditDetails('Id', 'Abbreviation', [value.rowData], [], 'Delete', this.templateData, this.auditableColumns);
              this._snackbar.open('Custom abbreviation template deleted successfully', 'Close');
              this.selectedData = { caseType: '', userId: '' };
              if (!this.userFlag) {
                this.selectedUser = "0";
                this.cardContext="fromCaseType"
              }
              this.getAllCustomAbbreviations();
              this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
              this.commonService.auditDetails('', '', [], this.excelDataArray, 'Delete', this.templateData, this.auditableColumns);
            },
            error => {
              console.error('Error deleting item:', error);
            }
          );
        }
      })
    }
  }

  getAuditableDetails(location: any) {
    this.VitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }

  emitFilters($event: any) {
    this.selectedData = {...{caseType : $event?.CaseType?.object?.CaseType === 'All Case Types' ? '' : $event?.CaseType?.object?.CaseType, userId: $event?.UserId?.object?.userid}}
    if ( this.sourceApp!= 'VitalDx') {
      if ($event?.UserId?.object?.userid) {
        this.selectedUser = $event?.UserId?.object?.userid;
        this.cardContext='fromCaseTypeUsers';
      }
      else {
        this.selectedUser = null;
        this.cardContext='fromCaseType';
      }
      this.getAllCustomAbbreviations()
    }
    else{
      this.addGridData(this.templateData.submenuData);
    }
  }
  roleChanged($event: any) {
    this.setUserRole($event?.event, $event?.user)
  }
  
  setUserRole(event, data) {
    if (event.source.selected && data.userid != this.tempSelectedUserId) {
      this.selectedRole = data.RoleName.toString();
      this.selectedUser = data.userid.toString();
      this.templateViewService.globalMasterDataForQueries['userRole'] = data.RoleName.toString();
      this.templateViewService.globalMasterDataForQueries['userId'] = data.userid.toString();
      this.userSelected = true;
      this.tempSelectedUserId=data.userid.toString();
      this.getCaseType();
      // this.migrateenabling(data)
    }
  }
  copyFromOrg() {
    this.gridShow=false;
    this.DeploymentKeys = this.VitalHttpServices.DeploymentKeys;
    this.copyBtnClicked=true;
    this.copyFromOrgFrm.patchValue({
      frmDeplyment: this.destDeployment,
      frmOrganization: '',
      frmUser: ''
    })
  }
  uploadCustomAbbrData() {
    this.gridShow = false;
    this.uploadbtnClicked = true;
    this.altermessage = "All the Group level abbreviations will be copied to User level. Any changes made will be specific to the user only";
    this.altermessageheader = "User level Bulk Upload";
  }
  onChangeDeployment(e, depKey) {
    if (e.source.selected) {
      this.copyFromOrgFrm.patchValue({
        frmDeplyment: depKey
      })
       this.gridWidth = 0
       this.searchInput = '';
       this.searchResult = [];
       this.postUpload = false;
       this.excelDataArray = []
      this.getListOrg();
      this.copyFromOrgFrm.controls.frmOrganization.enable();
      this.copyFromOrgFrm.controls.frmUser.disable();
    }
  }
  getListOrg() {
    let dbName = this.copyFromOrgFrm.value.frmDeplyment;
    let queryVariable = {};
    let query = this.VitalHttpServices.GetQuery("adduserfilterorg");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(data => {
      if (!data.errors) {
        if (data) {
          if (data.data.Organizations_list.length > 0) {
            this.copyOrganizations = data.data.Organizations_list
          }
        }
      }
    }, error => {
      console.log(error);
    });
    this.ngxService.stop();
  }
  fetchOrgSeries(value: string) {
    value = value?.trim();
    this.searchResult = [];
    if (!value) {
      this.searchResult = this.copyOrganizations.slice(0, 25);
      return;
    }
    const idValidate = /^[0-9]*$/;
    const lowerCaseValue = value.toLowerCase();

    if (this.copyFromOrgFrm.value.frmOrganization) {
      const filterCondition = idValidate.test(value)
        ? (series) => series.organizationid && series.organizationid.toString().toLowerCase().includes(lowerCaseValue)
        : (series) => series.organizationname && series.organizationname.toString().toLowerCase().includes(lowerCaseValue);

      this.searchResult = this.copyOrganizations.filter(filterCondition).slice(0, 25);
    }
  }

  selectedCopyDataOrganization(event, data) {
    if (event.source.selected) {
      this.srcUserid = '';
      this.searchUserInput = '';
      this.srcOrgid = data.organizationid;
      this.srcOrgName = data.organizationname;
      this.searchInput = data.organizationname;
      this.getListUsers(this.srcOrgid);
      this.copyFromOrgFrm.controls.frmUser.enable();
    }
  }
  async getListUsers(from?, userid?:string) {
    const query = this.VitalHttpServices.GetQuery('GetCustomAbbrUsers');
    const queryVariable = { "orgid": from == 'CE' ? this.commonService.orgid : this.srcOrgid.toString(), "filtername": userid ? "userid =\"" + userid + "\" " : null   };
    const queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    const dbName = this.copyFromOrgFrm.value.frmDeplyment;

    try {
      const res: any = await this.VitalHttpServices.GetData(queryResult, dbName).toPromise();
      if (res && !res.errors) {
        this.userListArray = res.data.Card || [];
        this.userListArray.sort((a, b) => a.username.localeCompare(b.username));
      } else {
        this._snackbar.open('Something went wrong. Please try again!', 'Close');
      }
    } catch (error) {
      console.error("Error occurred while fetching user list:", error);
      this._snackbar.open('An error occurred while fetching user list. Please try again!', 'Close');
    } finally {
      this.ngxService.stop();
    }
  }

  fetchUserSeries(value: string) {
    value = value?.trim();
    this.searchuserlist = [];

    if (!value) {
        this.searchuserlist = this.userListArray.slice(0, 25);
        return;
    }

    const lowerCaseValue = value.toLowerCase();
    const isNotOrganization = (user) => user.username?.toLowerCase() !== 'organization';
    const matchingUsers = this.userListArray.filter(user => {
        const username = user.username?.toLowerCase();
        const userid = user.userid?.toString().toLowerCase();
        return (username && username.includes(lowerCaseValue) && isNotOrganization(user)) ||
            (userid && userid.includes(lowerCaseValue) && isNotOrganization(user));
    });

    this.searchuserlist = matchingUsers.slice(0, 25);
}
selectedCopyDataUser(event, data) {
  if (event.source.selected) {
    // this.removeGrid();
    this.srcUserid = data.userid;
    this.srcUserName = data.username;
    this.searchUserInput = data.username;
    this.getDataToCopy()
  }
}
removeGrid(){
this.excelDataArray=[];
this.tempExcelArr=[];
this.griddata=[];
this.gridWidth=0;
this.noDataFound=false;
this.srcOrgid = null;
this.srcUserid =null;
this.checkStatus = false;
this.copyFromOrgFrm.patchValue({
  frmDeplyment: this.destDeployment,
  frmOrganization: '',
  frmUser:''
})
}
getDataToCopy() {
  if (typeof (this.copyFromOrgFrm.value.frmOrganization) == 'object') {
    let frmorgid = 0;
    frmorgid = this.copyFromOrgFrm.value.frmOrganization
    this.copyFromOrgFrm.value.frmOrganization = frmorgid[0].orgid
  }
  let queryVariable;
  if (this.userFlag) {
    queryVariable = { "orgid": this.srcOrgid.toString(), userid: this.srcUserid ? this.srcUserid.toString() : "0" };
  }
  else{
    queryVariable={"orgid": this.srcOrgid.toString(), "casetype":this.templateData.cardtype.toString(),userid: this.srcUserid?.toString()  }
  }
  let query =  this.VitalHttpServices.GetQuery("getCopyCustomAbbrevation");
  let queryResult = this.commonService.GetCardRequest(queryVariable, query);
  this.ngxService.start();
  this.VitalHttpServices.GetData(queryResult, this.copyFromOrgFrm.value.frmDeplyment).subscribe(data => {
    this.ngxService.stop();
    if (!data.errors) {
      let copyData = data.data.submenuData
      copyData.forEach(element => {
        element.IsActive = 1
      });
      if (copyData.length == 0) {
        this.noDataFound = true;
        this.gridWidth=0;
        return
      }
      this.convertToJson(copyData);
    }
  }, error => {

    this.ngxService.stop();
    console.error(error);
  });
}
convertToJson(sheetname) {
  var worksheet;
  this.excelDataArray = [];
  let tempExcelArr = [];
  let primary = {}
  let tempArray = []
  this.sheetHeader = [];
  let temp = [];
  let i = 1;
  // this.workBook.SheetNames.find(e => {
  //   if (e.toLowerCase() == sheetname.toLowerCase()) {
  //     worksheet = this.workBook.Sheets[e];
  //   }
  // });
  tempExcelArr = sheetname
  if (tempExcelArr.length == 0) {
    this._snackbar.open("The uploaded sheet does not contain any data", "Close");
    tempExcelArr = [];
    return;
  }
  tempExcelArr.find(r => {
    for (let [key, value] of Object.entries(r)) {
      if (!key.toString().match(/empty/i)) {
        primary[key] = value;
      }
    }
    tempArray.push(primary)
    primary = {}
  });
  tempArray.forEach(element => {
    element.userid = null;
  });
  // temp = Object.keys(tempExcelArr[0]);
  // temp.reverse();
  // temp.push("Notes");
  // temp.push("Status");
  this.sheetHeader = ["Abbreviation","Phrase","CaseType","UserId","IsActive"];
  this.sheetHeader.reverse();
  this.sheetHeader.push("ResultMessage");
  this.sheetHeader.push("Result");
  this.sheetHeader.reverse();
  for (let i = 0; i < this.sheetHeader.length; i++) {
    if (this.sheetHeader[i]) {
      this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
    }
  }
  this.excelDataArray = this.convertObjKeysToLower(tempArray);
  this.excelDownloadArray = [...this.excelDataArray]

  this.excelDataArray.find(d => {
    if (this.cardContext == 'fromUsers') {
      Object.assign((d), {
        slno: Number(i), resultmessage: '', type: 'insert', tablename: this.configuredData.tablename, organizationid: Number(this.commonService.orgid), userid: this.selectedUser
      });
    }
    else if (this.cardContext == 'fromCaseTypeUsers') {
      Object.assign((d), {
        slno: Number(i), resultmessage: '', type: 'insert', tablename: this.configuredData.tablename, organizationid: Number(this.commonService.orgid), userid: this.selectedUser, casetype: this.templateData.cardtype.toString()
      });
    }
    else if (this.cardContext == 'fromCaseType') {
      Object.assign(d, {
        slno: Number(i), resultmessage: '', type: 'insert', tablename: this.configuredData.tablename, organizationid: Number(this.commonService.orgid), casetype: this.templateData.cardtype.toString()
      });
    }
  
    i++;
  }); 
   this.validateExcel(this.excelDataArray);
  //this.excelGridData(this.excelDataArray)
}
  validateExcel(excelArr) {
    this.ngxService.start();
    const dataArray = this.convertObjKeysToLower(excelArr);
    let validateObj = {
      "storedProcedureName": this.configuredData.sp[0]["validate"],
      "jsonData": dataArray,      
      "isValidate": true
    }
    this.VitalHttpServices.bulkUploadFunctionalities(validateObj, this.destDeployment).subscribe(
      (result) => {
        if (!result.errors) {
          let data;
          if (result.status.toLowerCase() == 'success') {
            // if (!result.errors) {
            this.postUpload = false;
            data = JSON.parse(result.content);
            if (data[0]["InvalidColumns"] && data[0]["InvalidColumns"].length > 0) {
              this.showInvalidColumns = true;
              this.invalidColumns = data[0].InvalidColumns;
            }
            this.excelDataArray.filter(e => {
              data.find(r => {
                if (r.slno === e.slno) {
                  e.resultmessage = r.resultmessage;
                  e.isvalid = r.isvalid;
                  e.showinformation= r.showinformation
                  e.showpopup = r.showpopup;
                  e.alertMessage = r.alertmessage;
                  e.alertHeader = r.alertmessageheader;
                  e.sequence = 0
                }
              })
            });
            this.excelGridData(this.excelDataArray);
            this.tempExcelArr=[];
          }
          else {
            this._snackbar.open("Something went wrong!", "Close");
          }     
        } else {
          this._snackbar.open("Please provide valid data", 'Failed');
        }
        this.ngxService.stop();
      },
      (error) => {
        this._snackbar.open("An error occurred while processing your request", 'Failed');
        console.error(error);
        this.ngxService.stop();
      }
    );
  }
  initGrid(grid) {
      if (!this.postUpload) {
        this.selector = new Selector(grid, {
          itemChecked: () => {
            this.selectedData = grid.rows.filter(r => r.isSelected);
          }
        });
      }
  }
  convertObjKeysToLower(inputArr) {
    return inputArr.map(obj => {
      return Object.entries(obj).reduce((acc, [key, value]) => {
        acc[key.replace(/\s/g, "").toLowerCase()] = value;
        return acc;
      }, {});
    });
  }
  uploadCustomAbbr() {
    let showInformationPopUp = this.excelDataArray.find(va => va.showinformation == 1)
    if (showInformationPopUp) {
      this.dialog.open(LockUnlockUserDialogComponent, {
        width: '30vw',
        data: {
          imagePath: '../../../../assets/icons/user_icons/warning.svg',
          header: 'User level copy',
          message: 'All the Group level abbreviations will be copied to User level. Any changes made will be specific to the user only.',
          buttonname: 'OK',
          type: "mail-sent"
        }
      })
      .afterClosed().toPromise().then((result) => {
        this.uploadBulk()
      })
    } else {
      this.uploadBulk()
    }
  }
  uploadBulk() {
    this.excelDataArray = [...this.tempExcelArr]; 
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if (!this.excelDataArray[i]["resultmessage"]) {
          if (this.copyBtnClicked) {
            this._snackbar.open('Copying data failed! Please check the data for datatype mismatch.', 'Close');
          }
          else {
            this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
            return;
          }
        }
        else if (this.excelDataArray[i]["isvalid"] == 0 || this.excelDataArray[i]["isvalid"] == -1) {
          errorcount++;
        }
      }
      if (errorcount > 0) {
        this._snackbar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
        return
      }
      if ((this.invalidColumns != "")) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }

      let procName = this.configuredData.sp[0]["insert"]
      // this.excelDataArray?.attributeid == null || sendjson[0].attributeid == '' )
      this.ngxService.start();
      this.excelDataArray.forEach(ele => {
        ele.createdby = sessionStorage.getItem("Userid") || -100;
        if (this.checkStatus == true) {
          ele.isactive = 0;
        }
      })
      let upoloadObj = {
        "storedProcedureName": procName,
        "jsonData": this.excelDataArray
      }
      this.VitalHttpServices.bulkUploadFunctionalities(upoloadObj, this.destDeployment).subscribe(res => {
        this.ngxService.stop();
        if (res?.status?.toLowerCase() == 'success') {
          let result = JSON.parse(res.content);
          this.postUpload = true;
          this.checkStatus = false;
          this.excelDataArray?.filter(e => {
            result.find(r => {
              if (r.slno === e.slno) {
                e.resultmessage = r.resultmessage;
                e.result = r.result;
                e.sequence = r.sequence
              }
            });
          });
          this.excelGridData(this.excelDataArray);
          this.commonService.auditDetails('', '', [], this.excelDataArray, 'Copy', this.templateData, this.auditableColumns);
          this.tempExcelArr=[];

        }
      }, error => {
        this.ngxService.stop();
        console.error(error)
      })
    }
  }
  trimFieldValue(controlName: any) {
    this.commonService.trimFieldValue(this.copyFromOrgFrm, controlName, this.copyFromOrgFrm.value.frmOrganization)
  }
  trimUserFieldValue(controlName: any) {
    this.commonService.trimFieldValue(this.copyFromOrgFrm, controlName, this.copyFromOrgFrm.value.frmUser)
  }
  backClicked(event) {
    this.uploadbtnClicked = false;
    this.gridShow = true;
    this.copyBtnClicked = false;
    if (!this.userFlag) {
      this.selectedUser = "0";
      this.cardContext="fromCaseType"
    }
    this.getAllCustomAbbreviations()
  }
  refreshGrid() {
    if (!this.userFlag) {
      this.selectedUser = "0";
      this.cardContext="fromCaseType"
    }
    this.getAllCustomAbbreviations();
    this.copyBtnClicked = false;
    this.gridShow = true;
    this.excelDataArray = [];
    this.tempExcelArr=[];
    this.griddata = [];
    this.gridWidth = 0;
    this.noDataFound = false;
    this.srcOrgid = null;
    this.srcUserid = null;
    this.checkStatus = false;
    this.copyFromOrgFrm.reset();
  }
  custAbbrChecked(event, item) {
    if (event.target.checked) {
      this.tempExcelArr.push(item);
    }
    else {
      this.tempExcelArr = this.tempExcelArr.filter(arrItem => arrItem.slno !== item.slno);
    }
  }
  enableToolTip(e, type = '') {
    const text = e.scrollWidth > e.clientWidth ? e.textContent : '';
    type !== 'dropdown' ? this.toolTipText = text : this.optionToolTipText = text;
  }
  ExportExcel() {
    let excel =[]
    this.excelDataArray.forEach(item => {
      const { organizationid, slno, type, tablename,showpopup,showinformation, isvalid, alertMessage, alertHeader,createdby, ...rest } = item;
      excel.push(rest); // Push the transformed object into the array
    });    
    // excel.push(this.excelDataArray);
    let filename = this.templateData.menuURL + '_' + this.commonService.orgid.toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + this.configuredData.tablename);
    XLSX.writeFile(wb, filename);
  }
  checkValue(event) {
    if (event.target.checked) {
      this.checkStatus = true;
    } else {
      this.checkStatus = false;
    }
  }
  filtertemplates() {
    // for (let i = 0; i < this.excelDataArray?.length; i++) {
    //   this.excelDataArray[i].key = this.searchText;
    // }
    let excelDataArray = this.excelDataArray.filter(obj =>
      Object.values(obj).some(value =>
        typeof value === 'string' ? value.toLowerCase().includes(this.searchText.toLowerCase()) :
          typeof value === 'number' ? value.toString().includes(this.searchText) : false
      ))
    this.excelGridData(excelDataArray);
  }
  clearSearch() {
    this.searchText = '';
    this.filtertemplates();
  }
}
