<div id="vaEntityAttributes" class="col-sm-12 config-wrap">
    <div *ngIf="showGrid || searchEntityAttribute" class="modal-header admin-model-header mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt">
            <strong>
                <div>Configuration Management</div>
            </strong>
        </h3>
    </div>
    <div *ngIf="editAttribute" class="modal-header admin-model-header mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt">
            <strong>
                <div>{{entityName}}</div>
            </strong>
        </h3>
    </div>
    <!-- <div *ngIf="editAttribute && createEntity" class="modal-header admin-model-header ">
        <h3 class="modal-title w-100 admin-model-header-txt">
            <strong>
                <div>Add New Configuration</div>
            </strong>
        </h3>
    </div> -->
    <div class="row p-0 m-0">
        <div *ngIf="searchEntityAttribute" class="col-sm-12 mt-3">
            <div for="DKeys" class="input-wraper row drop-issue" id="drop-issue">
                <!-- <form [formGroup]="searchName"> -->
                <!-- <label class="label-font col-sm-3">Configuration Name<span class="error-msg"> * </span></label> -->
                <!-- <input class="search-bar form-control   col-sm-9" type="text" #searchbar
                [(ngModel)]="searchInput" (keyup)="getEntityAttributeList(searchbar.value)"
                placeholder="Search here ..." /> -->
                <div class="col-sm-6 p-0 m-0">
                    <mat-form-field id="mat" class=" input-hight frmMatSelect w-100" appearance="outline">
                        <mat-label>Configuration Name</mat-label>
                        <input type="text" #txtInput aria-label="Assignee" class="mt-2" placeholder="Search..." matInput
                            [formControl]="configname" [matAutocomplete]="auto2" [(ngModel)]="value">

                        <mat-autocomplete #auto2="matAutocomplete">
                            <mat-option class="mat-opt-align p-1 config-name-option text-truncate d-block" id="frmMatOption"
                                *ngFor="let option of filteredOptions | async"
                                value="{{ option.name }} ({{ option.refconfigname }})"
                                (onSelectionChange)="getEntityAttributeList($event)" autoActiveFirstOption
                                title="{{ option.name }} ({{ option.refconfigname }})">
                                {{ option.name }} ({{ option.refconfigname }})
                            </mat-option>
                        </mat-autocomplete>
                        <button matSuffix mat-icon-button><em class="fa fa-chevron-down chevron-align"
                                style="position: relative;;margin-bottom: 6px;"></em></button>
                        <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" title="Reset Search"
                            (click)="fnToggleFilter()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <div class="col-sm-3 col-md-1 p-0 m-0" style="display:flex; justify-content: center; /* center horizontally */
                align-items: center; /* center vertically */">
                    <em class="fa fa-info-circle" aria-hidden="true" title="View Flags Category-wise" style="font-size: 27px;
                    color: #00bcd4;" (click)="viewAllFlags()"></em>
                </div>

                <!-- <mat-form-field id="mat" style="display:none" class="col-sm-6 input-hight w-100 "
                appearance="outline">
                <mat-label>Configuration Name</mat-label>
                <input type="text" #txtInput aria-label="Assignee" placeholder="Search..." matInput
                    [formControl]="configname" [matAutocomplete]="auto2" [(ngModel)]="value">
                <em class="fa fa-chevron-down chevron-align mb-2"></em>
                <mat-autocomplete #auto2="matAutocomplete">
                    <mat-option *ngFor="let option of filteredOptions | async"
                        value="{{ option.name }} ({{ option.refconfigname }})"
                        (onSelectionChange)="getEntityAttributeList($event)" autoActiveFirstOption>
                        {{ option.name }} ({{ option.refconfigname }})
                    </mat-option>
                </mat-autocomplete>
                <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" title="Reset Search"
                    (click)="fnToggleFilter()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field> -->

                <!-- <div class="col-sm-1 error-msg">
                <em class="fa fa-remove mt-3" title="Cancel" (click)="fnToggleFilter()" aria-hidden="true"></em>
            </div> -->
                <!-- </form> -->
            </div>
        </div>
        <div *ngIf="showGrid && searchEntityAttribute" class="col-sm-12">
            <div *ngIf="templateData && templateData.length>0" class='padding-cell mt-2'>
                <wj-flex-grid [isReadOnly]="true" id="theGridTallRows" (initialized)="initGrid(grid)"
                    (formatItem)="formatItem(grid, $event)" [headersVisibility]="'Column'" [(itemsSource)]="gridData"
                    #grid>
                    <wj-flex-grid-column [header]="'Action'"  *ngIf= "!orglevelFinalstate1 && !accountlevelFinalstate && !accountlevelFinalstate1  && !orglevelFinalstate && !deploymentLevel &&  (!finalstateforthislocation || !finalstateforalllocation)" align="center" [width]="100" [isReadOnly]="true">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                            <button class="edit-delete-btn" [disabled]="hideEditBtn"
                                (click)="editEntityAttribute(row)"><em id="editButton" [title]="'Update'"
                                    class="edit-template p-0 fa fa-edit"></em></button>
                        </ng-template>
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="'Name'" [width]="135" [binding]="'Name'" [isReadOnly]="true">
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="'Description'" [width]="140" [binding]="'Description'"
                        [isReadOnly]="true">
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="'Configuration Level'" [width]="140"
                        [binding]="'Configuration_Level'" [isReadOnly]="true">
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="'Default Configuration 1'" [width]="140"
                        [binding]="'Default_Configuration_1'" [isReadOnly]="true">
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="'Deployment Level Value 1'" [width]="150"
                        [binding]="'Deployment_Level__Value_1'" [isReadOnly]="true">
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="'All Locations Value 1'" [width]="140"
                        [binding]="'All_Locations_Value_1'" [isReadOnly]="true">
                    </wj-flex-grid-column>
                    <wj-flex-grid-column *ngIf="!hidethislocation"  [header]="'This Location Value 1'" [width]="140"
                        [binding]="'This_Location_Value_1'" [isReadOnly]="true">
                    </wj-flex-grid-column>
                    <wj-flex-grid-column *ngIf="gridValueShowing" [header]="'Default Configuration 2'"
                        [binding]="'Default_Configuration_2'" [isReadOnly]="true">
                    </wj-flex-grid-column>
                    <wj-flex-grid-column *ngIf="gridValueShowing" [header]="'Deployment Level Value 2'"
                        [binding]="'Deployment_Level_Value_2'" [isReadOnly]="true">
                    </wj-flex-grid-column>
                    <wj-flex-grid-column *ngIf="gridValueShowing" [header]="'All Locations Value 2'"
                        [binding]="'All_Locations_Value_2'" [isReadOnly]="true">
                    </wj-flex-grid-column>
                    <wj-flex-grid-column *ngIf="gridValueShowing && !hidethislocation" [header]="'This Location Value 2'"
                        [binding]="'This_Location_Value_2'" [isReadOnly]="true">
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="'defaultvalue'" [binding]="'defaultvalue'" [isReadOnly]="true"
                        [visible]="!('defaultvalue')">
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="'validvalues'" [binding]="'validvalues'" [isReadOnly]="true"
                        [visible]="!('validvalues')">
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="'category'" [binding]="'category'" [isReadOnly]="true"
                        [visible]="!('category')">
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="'EntityType'" [binding]="'EntityType'" [isReadOnly]="true"
                        [visible]="!('EntityType')">
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="'EntityId'" [binding]="'EntityId'" [isReadOnly]="true"
                        [visible]="!('EntityId')">
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="'LocationVeID'" [binding]="'AccountVeID'" [isReadOnly]="true"
                        [visible]="!('AccountVeID')">
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="'OrgVeId'" [binding]="'OrgVeId'" [isReadOnly]="true"
                        [visible]="!('OrgVeId')">
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="'scope'" [binding]="'scope'" [isReadOnly]="true"
                        [visible]="!('scope')">
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="'id'" [binding]="'id'" [isReadOnly]="true" [visible]="!('id')">
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="'refconfigname'" [binding]="'refconfigname'" [isReadOnly]="true"
                        [visible]="!('refconfigname')">
                    </wj-flex-grid-column>
                </wj-flex-grid>
                
                <div *ngIf="orglevelFinalstate1 || accountlevelFinalstate || deploymentLevel ||  (finalstateforalllocation && finalstateforthislocation)" >
                    <span>
                      <B> Note: Selected configuration cannot be modified</B>
                    </span>
                </div>
                <div *ngIf="accountlevelFinalstate1 || accountlevelFinalstate2" >
                    <span>
                      <B> Note: Selected configuration cannot be modified</B>
                    </span>
                </div>
            </div>
            <div *ngIf="templateData && !templateData.length>0" class="mt-4">
                <div class="nodata-wrap">
                    <div class="nodata-design">No Data Found</div>
                </div>
            </div>
        </div>

        <div *ngIf="editAttribute" class="mt-2 col-sm-12">
            <div class="row">
                <form [formGroup]="frmEntityAttribute" class="col-sm-6">
                    <div *ngIf="editEntity">
                        <div *ngIf="Attr1Freetext" class="row mt-2 col-sm-12">
                            <mat-form-field appearance="outline" class=" col-sm-12 w-100">
                                <mat-label>
                                    {{fieldNameCongiguration1}}<strong><span class="error-msg">*</span></strong>
                                </mat-label>
                                <input type="text" matInput title="{{ frmEntityAttribute.value.configValue1 }}"
                                    formControlName="configValue1" [errorStateMatcher]="matcher">
                            </mat-form-field>
                        </div>
                        <div *ngIf="Attr1LooseDropDown" class="col-sm-12 mt-2 input-wraper config-wrap">
                            <mat-form-field class="col-sm-12 pl-0 input-hight w-100" appearance="outline">
                                <mat-label>
                                    <strong>{{fieldNameCongiguration1}}<span *ngIf="!checkNullforConfig1"
                                        class="error-msg">*</span></strong>
                                </mat-label>
                                <em class="fa fa-chevron-down chevron-alignment"></em>
                                <input type="text" placeholder="Select a value or Enter input like CD-12:20 PM"
                                    aria-label="Assignee" matInput formControlName="configValue1"
                                    [matAutocomplete]="auto3">
                                <mat-autocomplete #auto3="matAutocomplete">
                                    <mat-option class="mat-opt-align" *ngFor="let option of filterdConfig  | async"
                                        value="{{ option.value }}({{option.key}})" autoActiveFirstOption>
                                        {{ option.value }}({{option.key}})
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <div class="row mt-2">
                                <div class="col-sm-12">
                                    <P class="note-mark text-xs font-weight-bold ">
                                        <img alt="help" class="mr-1 mb-1" src="../../../assets/icons/help.svg"
                                            width="15px" height="auto">
                                        <span> This configuration accepts user input values</span>
                                    </P>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="Attr1StrictDropDown" class="row mt-2 col-sm-12">
                            <mat-form-field class="col-sm-12" appearance="outline">
                                <mat-label>
                                    <strong>{{fieldNameCongiguration1}}<span *ngIf="!checkNullforConfig1"
                                        class="error-msg">*</span></strong>
                                </mat-label>

                                <mat-select disableOptionCentering formControlName="configValue1">
                                    <mat-option *ngFor="let cdv of dropdownValues" value="{{ cdv.value }}">{{cdv.value}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="accountlevelFinalstate1==true || accountlevelFinalstate2==true" >
                                <span>
                                  <B> Note: Selected configuration cannot be modified</B>   </span>
                            </div>

                        </div>
                        <div class="mt-2">
                            <div *ngIf="Attr2Freetext" class="row mt-2 col-sm-12">
                                <mat-form-field appearance="outline" class="  col-sm-12 w-100">
                                    <mat-label>
                                        <strong>{{fieldNameCongiguration2}}<span *ngIf="!checkNullforConfig1"
                                                class="error-msg">*</span></strong>
                                    </mat-label>
                                    <input type="text" matInput title="{{ frmEntityAttribute.value.configValue2 }}"
                                        formControlName="configValue2" [errorStateMatcher]="matcher">
                                </mat-form-field>

                            </div>
                            <div *ngIf="Attr2LooseDropDown" class="col-sm-12 mt-2 input-wraper config-wrap">
                                <mat-form-field class="col-sm-12 pl-0 input-hight w-100" appearance="outline">
                                    <mat-label>
                                        <strong>{{fieldNameCongiguration2}}<span *ngIf="!checkNullforConfig1"
                                                class="error-msg">*</span></strong>
                                    </mat-label>
                                    <em class="fa fa-chevron-down chevron-align mb-2"></em>
                                    <input type="text" placeholder="Search configuration or Enter new value"
                                        aria-label="Assignee" matInput formControlName="configValue2"
                                        [matAutocomplete]="auto4">
                                    <mat-autocomplete #auto4="matAutocomplete">
                                        <mat-option class="mat-opt-align" *ngFor="let option of filterdConfig2  | async"
                                            value=" {{ option.value }}({{option.key}})" autoActiveFirstOption>
                                            {{ option.value }}({{option.key}})
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <div class="col-sm-12 mt-2">
                                    <div class=" row">
                                        <P class="note-mark text-xs font-weight-bold ">
                                            <img alt="help" class="mr-1 mb-1" src="../../../assets/icons/help.svg"
                                                width="15px" height="auto">
                                            <span> This configuration accepts user input values</span>
                                        </P>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="Attr2StrictDropDown" class="row mt-2 col-sm-12">
                                <mat-form-field appearance="outline" class="col-sm-12 w-100">
                                    <mat-label>
                                        <strong>{{fieldNameCongiguration2}}<span *ngIf="!checkNullforConfig2"
                                            class="error-msg">*</span></strong>
                                    </mat-label>

                                    <mat-select disableOptionCentering formControlName="configValue2">
                                        <mat-option *ngFor="let cdv of dropdownValues2"
                                            [value]="cdv.value">{{cdv.value}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <!-- <div *ngIf="accountlevelFinalstate1==true || accountlevelFinalstate2==true || finalstateforthislocation1==true || finalstateforthislocation2==true || finalstateforalllocation1==true || finalstateforalllocation2==true" >
                                    <span>
                                      <B> Note: Selected configuration cannot be modified</B>   </span>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div *ngIf="supportBoth" class="row mt-2">
                        <div class="col-sm-12">
                            <div class="col-sm-12 row">

                                <mat-radio-group aria-label="Select an option">
                                    <mat-radio-button [color]="task.color" (change)="clickProtect('account')" [disabled]="finalstateforthislocation ? true : null" class="col-sm-6 mr-1" formControlName="scope" value="3" checked>This Location </mat-radio-button>
                                    <mat-radio-button [color]="task.color" (change)="clickProtect('organization')" [disabled]="finalstateforalllocation ? true : null" class="col-sm-6 mr-1" formControlName="scope" value="2">All Locations</mat-radio-button>
                                </mat-radio-group>

                                <!-- <div class="col-sm-6">
                                    <span [attr.disabled]="finalstateforthislocation ? true : null" >
                                        <input type="radio" value="3" (click)="clickProtect('account')" 
                                        formControlName="scope" class="mr-1" />This Location 
                                    </span>
                                </div>
                                <div class="col-sm-6">
                                    <span [attr.disabled]="finalstateforalllocation ? true : null">
                                        <input type="radio" value="2" (click)="clickProtect('organization')" 
                                        formControlName="scope" class="mr-1"  />All Locations
                                    </span>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div *ngIf="orgLevel || frmEntityAttribute.value.scope==2" class="mt-2 col-sm-12">
                        <P class="note-mark text-xs font-weight-bold">
                            <em class="fa fa-warning" style="font-size:14px;color:red"></em>
                            <span> This will have an impact on all the locations. Do you wish to update?</span>
                        </P>
                    </div>
                    <div class="align-center mt-4">
                        <button (click)="backFromFormPage()" mat-raised-button
                            class="admin-btn-success mr-4">Back</button>
                        <button *ngIf="!createEntity" mat-raised-button class="admin-btn-success"
                            (click)="editEntityExtAttribute(frmEntityAttribute.value)">Configure</button>
                    </div>
                </form>

                <div *ngIf="intstMsg" class="col-sm-6 mt-2">
                    <div class="col-sm-12">
                        <P class="note-mark text-xs font-weight-bold ">
                            <img alt="help" class="mr-1 mb-1" src="../../../assets/icons/help.svg" width="15px"
                                height="auto">
                            <span> {{intstMsg}}</span>
                        </P>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="row p-0 m-0 mt-2">
        <div class="col-12 p-0 m-0">
            <!-- The Modal -->
            <div class="modal vaEntityAttributesModal" id="vaEntityAttributesModal" data-backdrop="static"
                style="overflow: hidden;" data-keyboard="false">
                <div class="modal-dialog" style="max-width: 90%; border-radius: 15px; height: 90%;">
                    <div class="modal-content vaEntityAttributesModal-model" style=" overflow: auto;">
                        <!-- Modal Header -->
                        <div class="admin-model-header">
                            <h4 class="modal-title p-1">Flags Information</h4>
                        </div>
                        <!-- Modal body -->
                        <div class="modal-body" style="font-size:16px !important">
                            <ng-container *ngIf="(asyncTabs | async) === null">
                                Loading tabs...
                            </ng-container>
                            <mat-tab-group [selectedIndex]="selectedTab.value" class="stretched-tabs"
                                (selectedTabChange)="addViewGridData(-100, $event)">
                                <mat-tab [disabled]="false" *ngFor="let tab of asyncTabs | async">
                                    <ng-template mat-tab-label>{{tab.label | uppercase}}</ng-template>
                                    <div *ngIf="showviewflaggrid" class="row p-0 m-0">
                                        <div *ngIf="gridarray1 && gridarray1.length > 0" class="col-1 p-2 m-0">&nbsp;
                                        </div>
                                        <div *ngIf="gridarray1 && gridarray1.length > 0"
                                            class="text-center col-10 p-2 m-0">
                                            <!-- style="font-size:16px !important" -->
                                            <wj-flex-grid (initialized)="initGrid(viewflagsgrid)"
                                                [headersVisibility]="'Column'" [isReadOnly]="true"
                                                [itemsSource]="gridData2" class="align-center mt-3" #viewflagsgrid>
                                                <div *ngFor="let columnname1 of columns">
                                                    <wj-flex-grid-column [width]="'*'" [minWidth]="50"
                                                        [binding]="columnname1.binding" align="left"
                                                        [header]="columnname1.header.split('_').join(' ')"
                                                        [allowResizing]="false" [allowDragging]="false" [fixed]="true"
                                                        [format]="'d'">
                                                    </wj-flex-grid-column>
                                                </div>
                                                <wj-flex-grid-column [header]="'Edit'" align="center" [width]="120"
                                                    [minWidth]="120" [isReadOnly]="true">
                                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'">
                                                        <button class="edit-delete-btn"
                                                            (click)="selectFlags(viewflagsgrid, $event)">
                                                            <em id="selectFlagButton" [title]="'Select Flag'"
                                                                style="font-size: 18px;"
                                                                class="edit-template cursor p-0 fa fa-hand-o-up">
                                                            </em>
                                                        </button>
                                                    </ng-template>
                                                </wj-flex-grid-column>
                                                <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                                            </wj-flex-grid>
                                            <div class="alignText">
                                                <wj-collection-view-navigator *ngIf="gridarray1.length > 10"
                                                    class="mt-2" headerFormat="Page {currentPage:n0} of {pageCount:n0}"
                                                    [byPage]="true" [cv]="gridData2">
                                                </wj-collection-view-navigator>
                                            </div>
                                        </div>
                                        <div *ngIf="gridarray1 && gridarray1.length > 0" class="col-1 p-2 m-0">&nbsp;
                                        </div>
                                        <div *ngIf="!(gridarray1 && gridarray1.length > 0)" class="mt-4">
                                            <div class="nodata-wrap">
                                                <div class="nodata-design">No Data Found</div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-tab>
                            </mat-tab-group>
                            <div class="mt-4">
                                <button type="button" mat-raised-button class="admin-btn-success"
                                    (click)="closePopUp()">Close</button>
                            </div>
                        </div>
                        <!-- Modal footer -->
                        <div class="modal-footer mb-3" style="display: block;margin: auto;">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>