<div class="p-2 infodetails">
  <mat-spinner class="labadmin-spinner loader-aligned"
    *ngIf="loading && fromLocation != 'location'" [diameter]="50">
  </mat-spinner>

  <mat-spinner class="labadmin-spinner loader-aligned"
    [ngClass]="{'spinner-margin-hide-grid': isHideLocationGrid, 'spinner-margin-show-grid': !isHideLocationGrid}"
    *ngIf="loading  && fromLocation == 'location'" [diameter]="50">
  </mat-spinner>

  <div class=" col-sm-12 row" *ngIf="!noDataFoundFlag && !loading" style="font-size: 12px;">
    <div class="col-sm-12 font-size-color">
      <div class="row mt-2 mb-2 ">
        <span class=" col-sm-3 ">NPI </span>
        <div class=" col-sm-8 pl-0 d-flex">
          <div class="ml-2 mr-3"> :</div>
          <strong>
            {{npiDetails?.['npi']}}
            <span [ngClass]="npiDetails.basic?.['status']=='A' ?'active_lable':'Inactive_lable'">{{
              npiDetails.basic?.['status']=='A' ?'Active':npiDetails.basic?.['status']}}</span>
          </strong>
        </div>
      </div>

      <div class="row mb-2">
        <span class=" col-sm-3 ">Enumerate Date</span>
        <span class="ml-2 mr-3">: </span>
        <strong class=" col-sm-6 pl-0">{{npiDetails?.['enumerationdate'] || '-'}} </strong>
      </div>

      <div class="row mb-2">
        <span class=" col-sm-3 ">NPI Type</span>
        <span class="ml-2 mr-3">: </span>
        <strong class=" col-sm-6 pl-0" *ngIf="fromLocation != 'location'">NPI-1 Individual</strong>
        <strong class=" col-sm-6 pl-0" *ngIf="fromLocation == 'location'">NPI-2 Organization</strong>
      </div>

      <div class="row mb-2">
        <span class=" col-sm-3 ">Sole Proprietor</span>
        <span class="ml-2 mr-3">: </span>
        <strong class=" col-sm-6 pl-0"> {{npiDetails.basic?.['soleproprietor'] || '-'}} </strong>
      </div>

      <div class="row mb-2">
        <span class="col-sm-3 ">Mailing Contact</span>
        <span class="ml-2 mr-3">: </span>
        <div class="col-sm-6 pl-0">
          <div class="col-sm-12 _600 p-0 pb-1">{{npiDetails.mailingContactAddress}}</div>
          <div class="col-sm-12 p-0">
            Phone: {{npiDetails?.address[0]?.telephonenumber || '-' }} | Fax:
            {{npiDetails?.address[0]?.faxnumber || '-' + '.'}}
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <span class="col-sm-3 ">Primary Practice Contact</span>
        <span class="ml-2 mr-3">: </span>
        <div class="col-sm-6 pl-0">
          <div class="col-sm-12 _600 p-0 pb-1">{{npiDetails.primaryPracticeContactAddress}}</div>
          <div class="col-sm-12 p-0">
            Phone: {{npiDetails?.address[1]?.telephonenumber || '-' }} | Fax:
            {{npiDetails?.address[1]?.faxnumber || '-' + '.'}}
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <span class=" col-sm-3 ">Secondary Practice Contact</span>
        <span class="ml-2 mr-3">: </span>
        <div class="col-sm-6 pl-0">
          <div class="col-sm-12 _600 p-0 pb-1">{{npiDetails.secondaryPracticeContactAddress || '-'}}</div>
          <div class="col-sm-12 p-0" *ngIf="npiDetails.practicelocation.length">
            Phone: {{npiDetails?.practicelocation[0]?.telephonenumber || '-' }} | Fax:
            {{npiDetails?.practicelocation[0]?.faxnumber || '-' + '.'}}
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <span class=" col-sm-3 ">Health Information Exchange</span>
        <span class="ml-2 mr-3">: </span>
        <strong class=" col-sm-6 pl-0">{{npiDetails?.['healthinfo'] || '-'}}</strong>
      </div>

      <div class="row mt-2 "  >
        <span class=" col-sm-3 ">Other Identifiers </span>
        <div  class=" col-sm-9 pl-0 px-0 d-flex">
          <div class="ml-2 mr-3"> :</div>
          <div class="grid_view w-100">
            <div class="header_title px-2">
              <div class="row">
                <div class="col-sm-4 _600">Issuer</div>
                <div class="col-sm-4 _600">State</div>
                <div class="col-sm-4 _600">Number</div>
              </div>
            </div>
            <div class="grid-data-content" *ngIf="npiDetails['identifiers']?.length">
              <div class="row" *ngFor="let item of npiDetails['identifiers']">
                <div class="col-sm-4 _600">{{item.desc}}</div>
                <div class="col-sm-4 _600">{{item.state}}</div>
                <div class="col-sm-4 _600">{{item.identifier}}</div>
              </div>
            </div>
             <div class="p-1 text-center" *ngIf="!npiDetails['identifiers']?.length">
              No Data Available
            </div>

          </div>
        </div>
      </div>

      <div class="row mt-3">
        <span class=" col-sm-3 ">Taxonomy </span>

        <div class=" col-sm-9 pl-0  px-0 d-flex">
          <div class="ml-2 mr-3"> :</div>
          <div class="grid_view w-100">
            <div class="header_title px-2">
              <div class="row">
                <div class="col-sm-3 _600">Primary Taxonomy</div>
                <div class="col-sm-4 _600 pl-4">Selected Taxonomy</div>
                <div class="col-sm-2 _600">State</div>
                <div class="col-sm-3 _600">License Number</div>
              </div>
            </div>
            <div class="grid-data-content">
              <div class="row" *ngFor="let item of npiDetails['taxonomies']">
                <div class="col-sm-3 _600">{{item.primary == "true" ? "Yes" : item.primary}}</div>
                <div class="col-sm-4 _600 pl-4">{{item.code +'-'+ item.desc}}</div>
                <div class="col-sm-2 _600">{{item.state}}</div>
                <div class="col-sm-3 _600">{{item.license}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="col-sm-12 nodata-header-wrap" *ngIf="noDataFoundFlag">
    <span class="col-sm-12 nodata-wrapper">No Data Available</span>
  </div>
</div>