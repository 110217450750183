import { createReducer, on } from '@ngrx/store';
import { decrement, addNabvTab,  enableListView, disableListView, setReloadingPage, changeDetailsView } from '../actions/navbar.actions';
import { changeListPageView } from '../../components/share/model/store.models';

export const initialState:any = [];

export const BredCrumReducer = createReducer(
  initialState,
  on(decrement, (state,action) => [...state.slice(0, state.length - 1)]),
  on(addNabvTab, (state,{value}) => [...changeTabData(state,value)]),
  on(changeDetailsView,(state,{URL,showDetails})=>state.map(i=>i.URL==URL?(showDetails?{...i,showListPage:true,showDetails,reload:false}:{...i,showListPage:true,showDetails}):{...i})),
  on(enableListView,(state,{URL,reload})=>state.map(i=>i.URL==URL?{...i,showListPage:true,reload}:{...i})),
  on(disableListView,(state,{URL})=>state.map(i=>i.URL==URL?{...i,showListPage:false}:{...i})),
  on(setReloadingPage,(state,{URL,reload})=>state.map(i=>i.URL==URL?{...i,reload}:{...i})),
  
  
);


function changeClientState(state,value:any){
  let state_=state.map(i=>i.Htext=='Clients'?{...i,...value?.value}:i)
  return [...state_]
}

function changeTabData(data,value){
  let updateData = [...data];
  if(updateData.length>0){
   updateData= updateData.slice(0,value.Level);
    updateData[value.Level] = value
  }else{
    updateData = [...updateData, value];
  }
  return updateData;
}
