import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../../core/services/commonservices';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';

@Component({
  selector: 'template-container',
  templateUrl: './templatecontainer.component.html',
  styleUrls: ['./templatecontainer.component.scss']
})
export class TemplateContainerComponent implements OnInit, OnChanges {
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  public template: string = '';
  @Input() mainCard: any;
  @Input()
  public submenuData: any;
  @Input()
  labels: any;
  @Input()
  public GroupData: any;
  labelFirstCount: number = 0;

  @Input()
  templateData: any;
  @Input()
  templateChangedTime: string;


  constructor(private commonService: CommonService, private ref: ChangeDetectorRef, private activatedroute: ActivatedRoute,
    private router: Router, public vitalHttpServices: VitalHttpServices) {
  }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
    let v: string = "";
  }
}
