<div class="row" *ngIf="!uploadClicked">
    <div class="col-md-12">
        <div class="modal-header admin-model-header mt-3">
            <h3 class="modal-title w-100 admin-model-header-txt">
                <strong>{{selectedpage}} Tab Focus</strong>
            </h3>
        </div>
    </div>
</div>

<div class="col-sm-12 row p-0 mt-2 tab-seq" *ngIf="!uploadClicked">
    <!-- <div class="col-sm-8">
      <mat-checkbox class="label-style selectALL col-sm-6 p-0" [(ngModel)]="enableTabFocus"
          [ngModelOptions]="{standalone: true}" [color]="task.color" (click)="updatetabFlag($event)">
          <span>Enable tab focus</span>
      </mat-checkbox>
    </div> -->

    <div class="col-sm-8 enable-wrap" *ngIf="!uploadClicked">
        <label class="switch" [(ngModel)]="enableTabFocus" (change)="updatetabFlag($event)">
            <input type="checkbox" [checked]='enableTabFocus'[disabled]="!editBtn">
            <span class="slider round"></span>
        </label>
        <span class="col-sm-3">Enable tab focus</span>



<!--
        <mat-slide-toggle class="label-style selectALL col-sm-6 p-0" [(ngModel)]="enableTabFocus" [ngModelOptions]="{standalone: true}"
            [color]="task.color" (change)="updatetabFlag($event)">
            <span>Enable tab focus</span>
        </mat-slide-toggle> -->

    </div>
    <div class="col-sm-4 mt-2 p-0 button-wrap" *ngIf="!uploadClicked">

        <mat-form-field class="col-sm-8 p-0 m-0" appearance="outline">
                <mat-label>Page Type</mat-label>
                <mat-select type="text" matInput #searchbar [(value)]="selectedpage">
                  <mat-option *ngFor="let option of options" [value]="option.value" (click)="resetTabIndex(option.value)">
                    {{ option.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
    </div>
</div>

<div *ngIf="false">

    <div class="col-sm-12 row p-0" *ngIf="!uploadClicked">
        <div class="col-sm-12 button-wrap p-0">
            <span class="col-sm-6 font-size p-0 button-wrap">
                <strong>Note: </strong> {{notes}}
            </span>
        </div>
    </div>
    <div *ngIf="false">
        <div class="col-sm-3 p-0" *ngIf="!uploadClicked">
            <span class="col-md-9 d-flex p-0">
                <span class="mr-2 opt-hover"  [ngClass]="{ 'disabled': !enableTabFocus }">
                    <div *ngIf="enableHorizontal " class="active">
                        <i class="fa fa-align-justify" aria-hidden="true"></i>
                        <span class="cursor " matTooltip="The tab order sequence will be set Horizontally" [disabled]="!enableTabFocus" (click)="toggle('horiz')"
                            > Horizontal</span>
                    </div>
                    <div *ngIf="!enableHorizontal ">
                        <i class="fa fa-align-justify" aria-hidden="true"></i>
                        <span class="cursor " matTooltip="The tab order sequence will be set Horizontally" (click)="toggle('horiz')"
                           > Horizontal</span>
                    </div>
                </span>

                <span class="opt-hover" [ngClass]="{ 'disabled': !enableTabFocus }">
                    <div *ngIf="!enableVertical">
                        <i class="fa fa-align-justify verticalvalue" aria-hidden="true"></i>
                        <span class="cursor" matTooltip="The tab order sequence will be set Vertically" (click)="toggle('vert')"
                            > Vertical</span>
                    </div>
                    <div *ngIf="enableVertical" class="active">
                        <i class="fa fa-align-justify verticalvalue" aria-hidden="true"></i>
                        <span class="cursor" matTooltip="The tab order sequence will be set Vertically" (click)="toggle('vert')"
                            > Vertical</span>
                    </div>
                </span>

            </span>
        </div>
        <div *ngIf="!uploadClicked">
                <div [formGroup]="tabSequenceForm">
                    <!-- Horizontal HTML-->
                    <div *ngIf="enableHorizontal">
                        <!-- Case Information data -->
                        <div class="col-sm-12 d-flex mt-3 p-0">
                            <div class="col-sm-2 color">
                                <strong>{{caseheadertext}}</strong>
                            </div>
                            <span class="col-sm-9 pl-0 mt-1">
                                <hr class="border-line">
                            </span>
                            <!-- // more hide -->
                            <div class="col-sm-1 button-wrap">
                                <span *ngIf="HCmoreHide">
                                    <em class="fa fa-chevron-down mt-1 more-arrow" (click)="HCmoreHide=false"></em>
                                </span>
                                <span *ngIf="!HCmoreHide" (click)="HCmoreHide">
                                    <em class="fa fa-chevron-up mt-1 more-arrow" (click)="HCmoreHide=true"></em>
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-12" *ngIf="!HCmoreHide">

                            <!-- Case Profile -->
                            <div class="col-sm-12 d-flex">
                                <div class="col-sm-4">
                                    <form class="row d-flex box-align">
                                        <div class="row col-sm-12 p-1 label-hor align-center">
                                            <span class="col-sm-10 p-1">
                                                Case Profile
                                            </span>
                                            <span class="col-sm-2 fr pl-4">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (click)="saveForValue('ddlCaseProfile',configuredtabindex.value)"
                                                    (change)="save('ddlCaseProfile',$event,Horizontal)" value="1" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <!-- Case type , Procedure , No of jars -->
                            <div class="col-sm-12 d-flex">
                                <div class="col-sm-4 p-0">
                                    <form class="d-flex box-align">
                                        <div class="row col-sm-12 p-1 label-hor align-center">
                                            <span class="col-sm-10 p-1">
                                                Case type
                                            </span>
                                            <span class="col-sm-2 fr pl-4">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('ddlCaseType',$event,'Horizontal')" value="2" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor align-center">
                                            <span class="col-sm-10 p-1">
                                                Extraction Procedure
                                            </span>
                                            <span class="col-sm-2 fr pl-4">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('ddlprocedure',$event,'Horizontal')" value="3" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor align-center">
                                            <span class="col-sm-10 pl-1">
                                                No of jars
                                            </span>
                                            <span class="col-sm-2 fr pl-4">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtNoJars',$event,'Horizontal')" value="4" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <!-- \ -->
                            <div class="col-sm-12 d-flex">
                                <div class="col-sm-4 p-0">
                                    <form class="d-flex box-align">
                                        <div class="row col-sm-12 p-1 label-hor align-center">
                                            <span class="col-sm-10 p-1">
                                                Other Case type
                                            </span>
                                            <span class="col-sm-2 fr pl-4">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtOtherCaseType',$event,'Horizontal')" value="5" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor align-center">
                                            <span class="col-sm-10 p-1">
                                                Other ExtractionProcedure
                                            </span>
                                            <span class="col-sm-2 fr pl-4">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtOtherExtractionProcedure',$event,'Horizontal')"
                                                    value="6" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor align-center">
                                            <span class="col-sm-10 pl-1">
                                                Site Schema
                                            </span>
                                            <span class="col-sm-2 fr pl-4">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('ddlSiteScheme',$event,'Horizontal')" value="7" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <!-- \ -->
                            <div class="col-sm-12 d-flex">
                                <div class="col-sm-4 p-0">
                                    <form class="d-flex box-align">
                                        <div class="row col-sm-12 p-1 label-hor align-center">
                                            <span class="col-sm-10 p-1">
                                                External Laboratory
                                            </span>
                                            <span class="col-sm-2 fr pl-4">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('ddlExternalLab',$event,'Horizontal')" value="8" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="col-sm-12 p-1 label-hor align-center">

                                            <span class="col-sm-10 p-1 mr-2">
                                                Services
                                            </span>
                                            <span class="col-sm-2 fr pl-4">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('ddlServices',$event,'Horizontal')" value="9" [disabled]="!enableTabFocus">
                                            </span>

                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor align-center">
                                            <span class="col-sm-10 pl-1">
                                                Reference #
                                            </span>
                                            <span class="col-sm-2 fr pl-4">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtRefNumber',$event,'Horizontal')" value="10" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <!-- Collection date,Collection time,Services,Encounter -->
                            <div class="col-sm-12 d-flex">
                                <div class="col-sm-4 d-flex pl-0">
                                    <div class="row col-sm-6 p-1 label-hor align-center">
                                        <div class="col-sm-12 p-1">
                                            <span class="col-sm-10 p-0">
                                                Collection date
                                            </span>
                                            <span class="col-sm-2 fr mr-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtCollectionDate',$event,'Horizontal')" value="11" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-sm-6 p-1 label-hor align-center">
                                        <div class="col-sm-12 p-1">
                                            <span class="col-sm-10 p-0">
                                                Collection time
                                            </span>
                                            <span class="col-sm-2 fr mr-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtCollectionTime',$event,'Horizontal')" value="12" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-8 d-flex p-0 ml-2 ">

                                    <div class="col-sm-6 p-1 label-hor align-center">
                                        <div class="col-sm-12 d-flex pl-0">
                                            <span class="col-sm-10 p-1 mr-2">
                                                Kit/Requisition
                                            </span>
                                            <span class="col-sm-2 fr pl-4">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtRequisition',$event,'Horizontal')" value="13" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-sm-6 p-1 label-hor align-center">
                                        <div class="col-sm-12 d-flex pl-0">
                                            <span class="col-sm-10 p-1 mr-2">
                                                Encounter
                                            </span>
                                            <span class="col-sm-2 fr pl-4">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtEncounter',$event,'Horizontal')" value="14" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Recieved date, Recieved time, Kit/requisition#,ICD-10(indicative),ICD-10(indicative) -->
                            <div class="col-sm-12 d-flex">
                                <div class="col-sm-4 d-flex pl-0">
                                    <div class="row col-sm-6 p-1 label-hor align-center">
                                        <div class="col-sm-12 p-1">
                                            <span class="col-sm-10 p-0">
                                                Recieved date
                                            </span>
                                            <span class="col-sm-2 fr pl-4 mr-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtRecievedDate',$event,'Horizontal')" value="15" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-sm-6 p-1 label-hor align-center">
                                        <div class="col-sm-12 p-1">
                                            <span class="col-sm-10 p-0">
                                                Recieved time
                                            </span>
                                            <span class="col-sm-2 fr pl-4 mr-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtReceivedTime',$event,'Horizontal')" value="16" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-8 d-flex p-0 ml-2 ">
                                    <div class="col-sm-6 p-1 label-hor align-center">
                                        <div class="col-sm-12 d-flex pl-0">
                                            <span class="col-sm-10 mr-2 p-1">
                                                Referring physician
                                            </span>
                                            <span class="col-sm-2 fr pl-4 pl-3">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtReferringPhysician',$event,'Horizontal')" value="17" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 d-flex pl-0">
                                        <div class="row col-sm-6 p-0 label-hor align-center">
                                            <div class="col-sm-12 p-0">
                                                <span class="col-sm-10 pl-2 pr-0 mr-3">
                                                    ICD-10(indicative)
                                                </span>
                                                <span class="col-sm-2 p-0">
                                                    <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                        (change)="save('txtICD1',$event,'Horizontal')" value="18" [disabled]="!enableTabFocus">
                                                </span>
                                            </div>
                                        </div>

                                        <div class="row col-sm-6 p-0 label-hor align-center">
                                            <div class="col-sm-12 p-0">
                                                <span class="col-sm-10 pl-2 pr-0 mr-3">
                                                    ICD-10(indicative)
                                                </span>
                                                <span class="col-sm-2 p-0">
                                                    <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                        (change)="save('txtICD2',$event,'Horizontal')" value="19" [disabled]="!enableTabFocus">
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Laboratory, Referring physician,ICD-10(indicative),ICD-10(indicative) -->
                            <div class="col-sm-12 d-flex">
                                <div class="col-sm-4">
                                    <form class="row d-flex box-align">
                                        <div class="row col-sm-12 p-1 label-hor align-center">
                                            <span class="col-sm-10 p-1">
                                                Laboratory
                                            </span>
                                            <span class="col-sm-2 fr pl-4">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('ddlLaboratory',$event,'Horizontal')" value="20" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-sm-4 ml-3 pl-1">
                                    <form class="row d-flex box-align mr-0">
                                        <div class="row col-sm-12 p-1 label-hor align-center">
                                            <span class="col-sm-10 p-1">
                                                Referring physician Fax#
                                            </span>
                                            <span class="col-sm-2 fr pl-4 pl-3">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtRefPhysicianFaxNo',$event,'Horizontal')" value="21" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-sm-4 d-flex pl-0">
                                    <div class="row col-sm-6 p-0 label-hor align-center">
                                        <div class="col-sm-12 p-0">
                                            <span class="col-sm-10 pl-2 pr-0 mr-3">
                                                ICD-10(indicative)
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtICD3',$event,'Horizontal')" value="22" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row col-sm-6 p-0 label-hor align-center">
                                        <div class="col-sm-12 p-0">
                                            <span class="col-sm-10 pl-2 pr-0 mr-3">
                                                ICD-10(indicative)
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtICD4',$event,'Horizontal')" value="23" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Hospital/Facility ,Referring physician Fax#,ICD-10(indicative),ICD-10(indicative) -->
                            <div class="col-sm-12 d-flex">
                                <div class="col-sm-4">
                                    <form class="row d-flex box-align">
                                        <div class="row col-sm-12 p-1 label-hor align-center">
                                            <span class="col-sm-10 p-1">
                                                Hospital/Facility
                                            </span>
                                            <span class="col-sm-2 fr pl-4">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtOH',$event,'Horizontal')" value="24" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-sm-4 ml-3 pl-1">
                                    <form class="row d-flex box-align mr-0">
                                        <div class="row col-sm-12 p-1 label-hor align-center">
                                            <span class="col-sm-10 p-1">
                                                Copy reports to referring priority physician
                                            </span>
                                            <span class="col-sm-2 fr pl-4 pl-3">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('chkReports',$event,'Horizontal')" value="25" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-sm-4 d-flex pl-0">
                                    <div class="row col-sm-6 p-0 label-hor align-center">
                                        <div class="col-sm-12 p-0">
                                            <span class="col-sm-10 pl-2 pr-0 mr-3">
                                                ICD-10(indicative)
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtICD5',$event,'Horizontal')" value="26" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row col-sm-6 p-0 label-hor align-center">
                                        <div class="col-sm-12 p-0">
                                            <span class="col-sm-10 pl-2 pr-0 mr-3">
                                                ICD-10(indicative)
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtICD6',$event,'Horizontal')" value="27" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Ordering physician ,Referring physician Email,ICD-10(indicative),ICD-10(indicative) -->
                            <div class="col-sm-12 d-flex">
                                <div class="col-sm-4">
                                    <form class="row d-flex box-align">
                                        <div class="row col-sm-12 p-1 label-hor align-center">
                                            <span class="col-sm-10 p-1">
                                                Ordering physician
                                            </span>
                                            <span class="col-sm-2 fr pl-4">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('ddlPhysician',$event,'Horizontal')" value="28" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-sm-4 ml-3 pl-1">
                                    <form class="row d-flex box-align mr-0">
                                        <div class="row col-sm-12 p-1 label-hor align-center">
                                            <span class="col-sm-10 p-1">
                                                Tracking #
                                            </span>
                                            <span class="col-sm-2 fr pl-4">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtClientTrackingNo',$event,'Horizontal')" value="29" [disabled]="!enableTabFocus">
                                            </span>

                                        </div>
                                    </form>
                                </div>
                                <div class="col-sm-4 d-flex pl-0">
                                    <div class="row col-sm-6 p-0 label-hor align-center">
                                        <div class="col-sm-12 p-0">
                                            <span class="col-sm-10 pl-2 pr-0 mr-3">
                                                ICD-10(indicative)
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtICD7',$event,'Horizontal')" value="30" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row col-sm-6 p-0 label-hor align-center">
                                        <div class="col-sm-12 p-0">
                                            <span class="col-sm-10 pl-2 pr-0 mr-3">
                                                ICD-10(indicative)
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtICD8',$event,'Horizontal')" value="31" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 d-flex">
                                <div class="col-sm-4 p-0">
                                    <form class="d-flex box-align">
                                        <div class="row col-sm-12 p-1 label-hor align-center">
                                            <span class="col-sm-10 p-1">
                                                Lab case #
                                            </span>
                                            <span class="col-sm-2 fr pl-4">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtLabCaseNo',$event,'Horizontal')" value="32" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor align-center">
                                            <span class="col-sm-10 p-1">
                                                S/O Bill To
                                            </span>
                                            <span class="col-sm-2 fr pl-4">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (click)="saveForValue('ddlSendOutBillTo',configuredtabindex.value)"
                                                    (change)="save('ddlSendOutBillTo',$event,'Horizontal')" value="33" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor align-center">
                                            <span class="col-sm-10 p-1">
                                                Bill To
                                            </span>
                                            <span class="col-sm-2 fr pl-4">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('ddlBillTo',$event,'Horizontal')" value="34" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>


                                    </form>
                                </div>
                            </div>

                            <div class="col-sm-12 d-flex">
                                <div class="col-sm-4 p-0">
                                    <form class="d-flex box-align">
                                        <div class="row col-sm-12 p-1 label-hor align-center">
                                            <span class="col-sm-10 p-1">
                                                Send in laboratory
                                            </span>
                                            <span class="col-sm-2 fr pl-4">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('ddlSendInLab',$event,'Horizontal')" value="35" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor align-center">
                                            <span class="col-sm-10 p-1">
                                                LMP Date
                                            </span>
                                            <span class="col-sm-2 fr pl-4">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtLmpDate',$event,'Horizontal')" value="36" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor align-center">
                                            <span class="col-sm-10 p-1">
                                                Priority
                                            </span>
                                            <span class="col-sm-2 fr pl-4">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('ddlCasePriority',$event,'Horizontal')" value="37" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="col-sm-12 d-flex">
                                <div class="col-sm-12">
                                    <form class="row d-flex box-align">
                                        <div class="row col-sm-4 p-1 label-hor align-center">
                                            <span class="col-sm-10 p-1">
                                                S/O Services
                                            </span>
                                            <span class="col-sm-2 fr pl-4">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (click)="saveForValue('ddlSendOutServices',configuredtabindex.value)"
                                                    (change)="save('ddlSendOutServices',$event,'Horizontal')" value="38" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                        <div class="row col-sm-4 p-1 label-hor align-center">
                                            <span class="col-sm-10 p-1">
                                                For Research use
                                            </span>
                                            <span class="col-sm-2 fr pl-4">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('chkResearch',$event,'Horizontal')" value="39" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>

                        <!-- Patient Information data -->
                        <div class="col-sm-12 d-flex p-0 mt-3">
                            <div class="col-sm-2 color">
                                <strong>{{patientheadertext}}</strong>
                            </div>
                            <span class="col-sm-9 pl-0 mt-1">
                                <hr class="border-line">
                            </span>
                            <!-- // more hide -->
                            <div class="col-sm-1 button-wrap">
                                <span *ngIf="HPmoreHide">
                                    <em class="fa fa-chevron-down mt-1 more-arrow" (click)="HPmoreHide=false"></em>
                                </span>
                                <span *ngIf="!HPmoreHide" (click)="HPmoreHide">
                                    <em class="fa fa-chevron-up mt-1 more-arrow" (click)="HPmoreHide=true"></em>
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-12" *ngIf="!HPmoreHide">

                            <!-- Last Name,First Name,Middle Name,Address 1-->
                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-3 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Last Name
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtLastName',$event,'Horizontal')" value="40" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                First Name
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtFirstName',$event,'Horizontal')" value="41" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Middle Name
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtMiddleName',$event,'Horizontal')" value="42" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Address 1
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtAddress1',$event,'Horizontal')" value="43" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <!-- Gender,Date of birth,SSN#,Address 2 -->
                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-3 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Gender
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('ddlGender',$event,'Horizontal')" value="44" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Date of birth
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtDateOfBirth',$event,'Horizontal')" value="45" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                SSN#
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtSSNNo',$event,'Horizontal')" value="46" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Address 2
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtAddress2',$event,'Horizontal')" value="47" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <!-- EMR#,PMS/PWS#,Home phone,City -->
                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-3 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                EMR#
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtMedRecNo',$event,'Horizontal')" value="48" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                PMS/PWS#
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (click)="saveForValue('txtOrderNo',configuredtabindex.value)"
                                                    (change)="save('txtOrderNo',$event,'Horizontal')" value="49" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Home phone
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtPatientHomePhone',$event,'Horizontal')" value="50" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                City
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtCity',$event,'Horizontal')" value="51" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <!-- Clear,Look up,Work phone,State -->
                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-3 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Email
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtPatientEmail',$event,'Horizontal')" value="52" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Look Up Patient Details
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('btnSearch',$event,'Horizontal')" value="53" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Work phone
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtPatientWorkPhone',$event,'Horizontal')" value="54" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                State
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('drpState',$event,'Horizontal')" value="55" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <!-- Race,Ethnicity,Cell phone#,Zip -->
                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-3 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Suffix
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtSuffix',$event,'Horizontal')" value="56" [disabled]="!enableTabFocus">

                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-horhide">
                                            <div class="hidebox">

                                            </div>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-horhide">
                                            <div class="hidebox">

                                            </div>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Zip
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtZip',$event,'Horizontal')" value="57" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <!-- Email,Sexual Orientation -->
                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-3 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <!-- <div class="row col-sm-12 p-1 label-hor" >
                                        <span class="col-sm-10 pl-1"  >
                                            ///
                                        </span>
                                        <span class="col-sm-2"   >
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                 (click)="saveForValue('txtRefPhysicianFaxNo',configuredtabindex.value)"
                                                (change)="save('txtRefPhysicianFaxNo',$event,Horizontal)"  value="">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-hor" >
                                        <span class="col-sm-10 pl-1"  >
                                            ///
                                        </span>
                                        <span class="col-sm-2"   >
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                 (click)="saveForValue('txtRefPhysicianFaxNo',configuredtabindex.value)"
                                                (change)="save('txtRefPhysicianFaxNo',$event,Horizontal)"  value="">
                                        </span>
                                    </div> -->

                                        <!-- <div class="row col-sm-12 p-1 label-hor">
                                        <span class="col-sm-10 pl-1">
                                            Email
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtPatientEmail',$event,Horizontal)" value="19">
                                        </span>
                                    </div> -->

                                        <!-- <div class="row col-sm-12 p-1 label-hor">
                                        <span class="col-sm-10 pl-1">
                                            Sexual Orientation
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                 (click)="saveForValue('txtRefPhysicianFaxNo',configuredtabindex.value)"
                                                (change)="save('txtRefPhysicianFaxNo',$event,Horizontal)"  value="20">
                                        </span>
                                    </div> -->
                                    </form>
                                </div>
                            </div>
                        </div>

                        <!-- Supplemental Information data -->
                        <div class="col-sm-12 d-flex p-0 mt-3">
                            <div class="col-sm-3 color">
                                <strong>{{supplementalHeadertext}}</strong>
                            </div>
                            <span class="col-sm-8 pl-0 mt-1">
                                <hr class="border-line">
                            </span>
                            <!-- // more hide -->
                            <div class="col-sm-1 button-wrap">
                                <span *ngIf="HSmoreHide">
                                    <em class="fa fa-chevron-down mt-1 more-arrow" (click)="HSmoreHide=false"></em>
                                </span>
                                <span *ngIf="!HSmoreHide" (click)="HSmoreHide">
                                    <em class="fa fa-chevron-up mt-1 more-arrow" (click)="HSmoreHide=true"></em>
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-12" *ngIf="!HSmoreHide">

                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-3 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Clinical Information
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtClinicalInfo',$event,'Horizontal')" value="58" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Special instructions
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtSpecial',$event,'Horizontal')" value="59" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Send Out Note
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtSendOutNotes',$event,'Horizontal')" value="60" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Clinical Stage
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('ddlClinicalStages',$event,'Horizontal')" value="61" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                    </form>
                                </div>
                            </div>

                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-3 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                PSA Value
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtPSARange',$event,'Horizontal')" value="62" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <!-- Insurance  -->
                        <div class="col-sm-12 d-flex p-0 mt-3">
                            <div class="col-sm-2 color">
                                <strong>{{headertext}}</strong>
                            </div>
                            <span class="col-sm-9 pl-0 mt-1">
                                <hr class="border-line">
                            </span>
                            <!-- // more hide -->
                            <div class="col-sm-1 button-wrap">
                                <span *ngIf="InsurancemoreHide">
                                    <em class="fa fa-chevron-down mt-1 more-arrow" (click)="InsurancemoreHide=false"></em>
                                </span>
                                <span *ngIf="!InsurancemoreHide" (click)="InsurancemoreHide">
                                    <em class="fa fa-chevron-up mt-1 more-arrow" (click)="InsurancemoreHide=true"></em>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="!InsurancemoreHide" class="mt-3">
                            <!-- Primary Insurance data -->
                            <div class="row col-sm-12 mt-3 ml-2 more-data mb-2">
                                <div class="col-sm-2 p-0">
                                    <strong>{{PinsuranceHeadertext}}</strong>
                                </div>
                            </div>
                            <!-- Relationship,Plan type,Payor,Insurance code -->
                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-2 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Relationship
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPrimaryRelationship',$event,'Horizontal')"
                                                    value="63" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Suffix
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespSuffix1',$event,'Horizontal')"
                                                    value="64" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Plan type
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPrimaryPlanType',$event,'Horizontal')"
                                                    value="65" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Payor
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPayor1',$event,'Horizontal')" value="66" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Insurance code
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtIC1',$event,'Horizontal')" value="67" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <!-- Last Name,First Name,Middle Initial,Address 1,Address 2 -->
                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-2 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Last Name
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtLastName1',$event,'Horizontal')"
                                                    value="68" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                First Name
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespFirstName1',$event,'Horizontal')"
                                                    value="69" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Middle Initial
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespMiddleInitials1',$event,'Horizontal')"
                                                    value="70" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Address 1
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtAddress11',$event,'Horizontal')"
                                                    value="71" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Address 2
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtAddress12',$event,'Horizontal')"
                                                    value="72" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <!-- DOB,Gender,Policy #,City,State -->
                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-2 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                DOB
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtDob1',$event,'Horizontal')" value="73" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Gender
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGender1',$event,'Horizontal')" value="74" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Policy #
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPolicyNo1',$event,'Horizontal')"
                                                    value="75" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                City
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtCity1',$event,'Horizontal')" value="76" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                State
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtState1',$event,'Horizontal')" value="77" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <!-- Group/Plan,Group#,Patient policy,Zip -->
                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-2 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Group/Plan
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGroupName1',$event,'Horizontal')"
                                                    value="78" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Group#
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGroupNo1',$event,'Horizontal')" value="79" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Patient policy
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientPolicyno1',$event,'Horizontal')"
                                                    value="80" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Zip
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtZip1',$event,'Horizontal')" value="81" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-horhide">
                                            <div class="hidebox">
                                                <span class="col-sm-10 pl-1">

                                                </span>
                                                <span class="col-sm-2 fr p-0">

                                                </span>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>


                            <!-- Secondary Insurance data -->
                            <div class="row col-sm-12 mt-3 ml-2 more-data mb-2">
                                <div class="col-sm-2 p-0">
                                    <strong>{{SinsuranceHeadertext}}</strong>
                                </div>
                            </div>
                            <!-- Relationship,Plan type,Payor,Insurance code -->
                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-2 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Relationship
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtSecondaryRelationship',$event,'Horizontal')"
                                                    value="82" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Suffix
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespSuffix2',$event,'Horizontal')"
                                                    value="83" [disabled]="!enableTabFocus">
                                            </span>


                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Plan type
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (click)="saveForValue('SubscribeInfo_txtSecondaryPlanType',configuredtabindex.value)"
                                                    (change)="save('SubscribeInfo_txtSecondaryPlanType',$event,'Horizontal')"
                                                    value="84" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Payor
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPayor2',$event,Horizontal)" value="85" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Insurance code
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtIC2',$event,'Horizontal')" value="86" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <!-- Last Name,First Name,Middle Initial,Address 1,Address 2 -->
                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-2 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Last Name
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtLastName2',$event,'Horizontal')"
                                                    value="87" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                First Name
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespFirstName2',$event,'Horizontal')"
                                                    value="88" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Middle Initial
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespMiddleInitials2',$event,'Horizontal')"
                                                    value="89" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Address 1
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtAddress21',$event,'Horizontal')"
                                                    value="90" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Address 2
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (click)="saveForValue('SubscribeInfo_txtAddress22',configuredtabindex.value)"
                                                    (change)="save('SubscribeInfo_txtAddress22',$event,'Horizontal')"
                                                    value="91" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <!-- DOB,Gender,Policy #,City,State -->
                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-2 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                DOB
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtDob2',$event,'Horizontal')" value="92" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Gender
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGender2',$event,'Horizontal')" value="93" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Policy #
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPolicyNo2',$event,Horizontal)" value="94" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                City
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtCity2',$event,'Horizontal')" value="95" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                State
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtState2',$event,'Horizontal')" value="96" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <!-- Group/Plan,Group#,Patient policy,Zip,Insurance type code -->
                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-2 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Group/Plan
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGroupName2',$event,'Horizontal')"
                                                    value="97" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Group#
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGroupNo2',$event,'Horizontal')" value="98" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Patient policy
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientPolicyno2',$event,'Horizontal')"
                                                    value="99" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Zip
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtZip2',$event,'Horizontal')" value="100" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Insurance type code
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_drpInsuranceTypeCode2',$event,'Horizontal')"
                                                    value="101" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <!-- Teritary Insurance data -->
                            <div class="row col-sm-12 mt-3 ml-2 more-data mb-2">
                                <div class="col-sm-2 p-0">
                                    <strong>{{TinsuranceHeadertext}}</strong>
                                </div>
                            </div>
                            <!-- Relationship,Plan type,Payor,Insurance code -->
                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-2 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Relationship
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtTertiayRelationship',$event,'Horizontal')"
                                                    value="102" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Suffix
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespSuffix3',$event,'Horizontal')"
                                                    value="103" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Plan type
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtTertiayPlanType',$event,'Horizontal')"
                                                    value="104" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Payor
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPayor3',$event,'Horizontal')" value="105" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Insurance code
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtIC3',$event,'Horizontal')" value="106" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <!-- Last Name,First Name,Middle Initial,Address 1,Address 2 -->
                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-2 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Last Name
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtLastName3',$event,'Horizontal')"
                                                    value="107" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                First Name
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespFirstName3',$event,Horizontal)"
                                                    value="108" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Middle Initial
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (click)="saveForValue('SubscribeInfo_txtRespMiddleInitials3',configuredtabindex.value)"
                                                    (change)="save('SubscribeInfo_txtRespMiddleInitials3',$event,'Horizontal')"
                                                    value="109" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Address 1
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtAddress31',$event,'Horizontal')"
                                                    value="110" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Address 2
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtAddress32',$event,'Horizontal')"
                                                    value="111" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <!-- DOB,Gender,Policy #,City,State -->
                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-2 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                DOB
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtDob3',$event,'Horizontal')" value="112" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Gender
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (click)="saveForValue('SubscribeInfo_txtGender3',configuredtabindex.value)"
                                                    (change)="save('SubscribeInfo_txtGender3',$event,'Horizontal')" value="113" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Policy #
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPolicyNo3',$event,'Horizontal')"
                                                    value="114" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                City
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtCity3',$event,'Horizontal')" value="115" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                State
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtState3',$event,'Horizontal')" value="116" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <!-- Group/Plan,Group#,Patient policy,Zip,Insurance type code -->
                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-2 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Group/Plan
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGroupName3',$event,Horizontal)"
                                                    value="117" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Group#
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGroupNo3',$event,'Horizontal')"
                                                    value="118" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Patient policy
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientPolicyno3',$event,'Horizontal')"
                                                    value="119" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Zip
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtZip3',$event,Horizontal)" value="120" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Insurance type code
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_drpInsuranceTypeCode3',$event,'Horizontal')"
                                                    value="121" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <!-- Guarantor Insurance data -->
                            <div class="row col-sm-12 mt-3 ml-2 more-data mb-2">
                                <div class="col-sm-2 p-0">
                                    <strong>{{GinsuranceHeadertext}}</strong>
                                </div>
                            </div>
                            <!-- Relationship,Plan type,Payor,Insurance code -->
                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-2 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Relationship
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_ddlPatientRelationship1',$event,'Horizontal')"
                                                    value="122" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Last name
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeLastName',$event,'Horizontal')"
                                                    value="123" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                First name
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeFirstName',$event,Horizontal)"
                                                    value="124" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Middle Initial
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeMiddleName',$event,'Horizontal')"
                                                    value="125" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <!-- Last Name,First Name,Middle Initial,Address 1,Address 2 -->
                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-2 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Home phone
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeHomePhone',$event,'Horizontal')"
                                                    value="126" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Work phone
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeWorkPhone',$event,'Horizontal')"
                                                    value="127" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Address1
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeAddress1',$event,'Horizontal')"
                                                    value="128" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Address2
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeAddress2',$event,'Horizontal')"
                                                    value="129" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <!-- DOB,Gender,Policy #,City,State -->
                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-2 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                City
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeCity',$event,'Horizontal')"
                                                    value="130" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                State
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_drpPatientRelativeState',$event,'Horizontal')"
                                                    value="131" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Zip
                                            </span>
                                            <span class="col-sm-2 fr p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeZip',$event,'Horizontal')"
                                                    value="132" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Suffix
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeSuffix',$event,'Horizontal')"
                                                    value="133" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                    </form>
                                </div>
                            </div>

                        </div>

                        <!-- Button data -->
                        <div class="col-sm-12 d-flex p-0 mt-3">
                            <div class="col-sm-2 color">
                                <strong>{{ButtonHeadertext}}</strong>
                            </div>
                            <span class="col-sm-9 pl-0 mt-1">
                                <hr class="border-line">
                            </span>
                            <!-- // more hide -->
                            <div class="col-sm-1 button-wrap">
                                <span *ngIf="HBmoreHide">
                                    <em class="fa fa-chevron-down mt-1 more-arrow" (click)="HBmoreHide=false"></em>
                                </span>
                                <span *ngIf="!HBmoreHide" (click)="HBmoreHide">
                                    <em class="fa fa-chevron-up mt-1 more-arrow" (click)="HBmoreHide=true"></em>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="!HBmoreHide" class="mt-3">
                            <!-- Save,Save & close,Save & proceed to site information,Cancel Requisition -->
                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-3 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Save
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('lnkSaveOnly',$event,Horizontal)" value="134" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Save & close
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('btnsaveonly',$event,'Horizontal')" value="135" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Save & proceed to site information
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('btnProceed',$event,'Horizontal')" value="136" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Cancel Requisition
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('btnCancelReq',$event,'Horizontal')" value="137" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <!-- Order,Accession,Cancel order,Return -->
                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-3 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Order
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('btnOrder',$event,'Horizontal')" value="138" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Accession
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('btnAccession',$event,'Horizontal')" value="139" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Cancel order
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('btnCancelOrder',$event,'Horizontal')" value="140" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Return
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('btnCancel',$event,'Horizontal')" value="141" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <!-- Send out case,Reject -->
                            <div class="col-sm-12 d-flex p-0">
                                <div class="col-sm-3 p-0">
                                    <form class="d-flex p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Send out case
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('btnSentOut',$event,'Horizontal')" value="142" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-hor">
                                            <span class="col-sm-10 pl-1">
                                                Reject
                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('btnReject',$event,'Horizontal')" value="143" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- Vertical HTML-->
                    <div *ngIf="enableVertical">
                        <!-- Case Information data -->
                        <div class="col-sm-12 d-flex mt-3 p-0">
                            <div class="col-sm-2 color p-0">
                                <strong>{{caseheadertext}}</strong>
                            </div>
                            <span class="col-sm-9 pl-0 mt-1">
                                <hr class="border-line">
                            </span>
                            <!-- // more hide -->
                            <div class="col-sm-1 button-wrap">
                                <span *ngIf="VCmoreHide">
                                    <em class="fa fa-chevron-down mt-1 more-arrow" (click)="VCmoreHide=false"></em>
                                </span>
                                <span *ngIf="!VCmoreHide" (click)="VCmoreHide">
                                    <em class="fa fa-chevron-up mt-1 more-arrow" (click)="VCmoreHide=true"></em>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="!VCmoreHide" class="row col-sm-12">

                            <div class="col-sm-4">
                                <form class="p-1 box-align" [formGroup]="tabSequenceCaseInfoForm">

                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Case Profile
                                        </span>
                                        <span class="col-sm-2 pl-3 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlCaseProfile',$event,'Vertical')" value="1" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Case type
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlCaseType',$event,'Vertical')" value="2" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Other CaseType
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtOtherCaseType',$event,'Vertical')" value="3" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            External Laboratory
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlExternalLab',$event,'Vertical')" value="4" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="col-sm-12 d-flex pl-0">
                                        <div class="col-sm-6 p-1 label-ver">
                                            <span class="col-sm-10 pl-1 mr-3">
                                                Collection date
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtCollectionDate',$event,'Vertical')" value="5" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="col-sm-6 p-1 label-ver">
                                            <span class="col-sm-10 pl-1 mr-3">
                                                Collection time
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtCollectionTime',$event,'Vertical')" value="6" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 d-flex pl-0">
                                        <div class="col-sm-6 p-1 label-ver">
                                            <span class="col-sm-10 pl-1 mr-3">
                                                Recieved date
                                            </span>
                                            <span class="col-sm-2 p-0 m-1">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtRecievedDate',$event,'Vertical')" value="7" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="col-sm-6 p-1 label-ver">
                                            <span class="col-sm-10 pl-1 mr-3">
                                                Recieved time
                                            </span>
                                            <span class="col-sm-2 p-0 m-1">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtReceivedTime',$event,'Vertical')" value="8" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Laboratory
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlLaboratory',$event,'Vertical')" value="9" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Hospital/Facility
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtOH',$event,'Vertical')" value="10" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Ordering physician
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlPhysician',$event,'Vertical')" value="11" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Lab case#
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtLabCaseNo',$event,'Vertical')" value="12" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Send in laboratory
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlSendInLab',$event,'Vertical')" value="13" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            S/O Services
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlSendOutServices',$event,'Vertical')" value="14" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>
                                </form>
                            </div>

                            <div class="col-sm-4 p-0">
                                <form class="p-1 box-align" [formGroup]="tabSequenceCaseInfoForm">
                                    <div class="row col-sm-12 p-1 label-verhide">
                                        <div class="hidebox">
                                            <span class="col-sm-10 pl-1">

                                            </span>
                                            <span class="col-sm-2 pl-3">

                                            </span>
                                        </div>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Extraction Procedure
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlprocedure',$event,'Vertical')" value="15" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>



                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Other ExtractionProcedure
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtOtherExtractionProcedure',$event,'Vertical')" value="16" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Services
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlServices',$event,'Vertical')" value="17" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Kit/requisition#
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtRequisition',$event,'Vertical')" value="18" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Referring physician
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtReferringPhysician',$event,'Vertical')" value="19" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Referring physician Fax#
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtRefPhysicianFaxNo',$event,'Vertical')" value="20" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Copy reports to referring priority physician
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('chkReports',$event,'Vertical')" value="21" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Tracking #
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtClientTrackingNo',$event,'Vertical')" value="22" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            S/O Bill To
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlSendOutBillTo',$event,'Vertical')" value="23" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            LMP Date
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtLmpDate',$event,'Vertical')" value="24"  [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            For Research use
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('chkResearch',$event,'Vertical')" value="25" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>
                                </form>
                            </div>

                            <div class="col-sm-4 p-0">
                                <form class="p-1 box-align" [formGroup]="tabSequenceCaseInfoForm">
                                    <div class="row col-sm-12 p-1 label-verhide">
                                        <div class="hidebox">
                                            <span class="col-sm-10 pl-1">

                                            </span>
                                            <span class="col-sm-2 pl-3">

                                            </span>
                                        </div>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            No of jars
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtNoJars',$event,'Vertical')" value="26" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Site Schema
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlSiteScheme',$event,'Vertical')" value="27" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Reference #
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtRefNumber',$event,'Vertical')" value="28" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Encounter
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtEncounter',$event,'Vertical')" value="29" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="col-sm-12 d-flex pl-0">
                                        <div class="col-sm-6 p-1 label-ver">
                                            <span class="col-sm-10 pl-1 pr-1 mr-3">
                                                ICD-10(indicative)
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtICD1',$event,'Vertical')" value="30" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="col-sm-6 p-1 label-ver">
                                            <span class="col-sm-10 pl-1 pr-1 mr-3">
                                                ICD-10(indicative)
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (click)="saveForValue('txtICD2',configuredtabindex.value)"
                                                    (change)="save('txtICD2',$event,'Vertical')" value="31" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 d-flex pl-0">
                                        <div class="col-sm-6 p-1 label-ver">
                                            <span class="col-sm-10 pl-1 pr-1 mr-3">
                                                ICD-10(indicative)
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtICD3',$event,'Vertical')" value="32" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="col-sm-6 p-1 label-ver">
                                            <span class="col-sm-10 pl-1 pr-1 mr-3">
                                                ICD-10(indicative)
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtICD4',$event,'Vertical')" value="33" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 d-flex pl-0">
                                        <div class="col-sm-6 p-1 label-ver">
                                            <span class="col-sm-10 pl-1 pr-1 mr-3">
                                                ICD-10(indicative)
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtICD5',$event,'Vertical')" value="34" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="col-sm-6 p-1 label-ver">
                                            <span class="col-sm-10 pl-1 pr-1 mr-3">
                                                ICD-10(indicative)
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtICD6',$event,'Vertical')" value="35" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 d-flex pl-0">
                                        <div class="col-sm-6 p-1 label-ver">
                                            <span class="col-sm-10 pl-1 pr-1 mr-3">
                                                ICD-10(indicative)
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtICD7',$event,'Vertical')" value="36" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="col-sm-6 p-1 label-ver">
                                            <span class="col-sm-10 pl-1 pr-1 mr-3">
                                                ICD-10(indicative)
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtICD8',$event,'Vertical')" value="37" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Bill To
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlBillTo',$event,'Vertical')" value="38" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Priority
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlCasePriority',$event,'Vertical')" value="39" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                </form>
                            </div>
                        </div>

                        <!-- Patient Information data -->
                        <div class="col-sm-12 d-flex mt-3 p-0">
                            <div class="col-sm-2 color p-0">
                                <strong>{{patientheadertext}}</strong>
                            </div>
                            <span class="col-sm-9 pl-0 mt-1">
                                <hr class="border-line">
                            </span>
                            <!-- // more hide -->
                            <div class="col-sm-1 button-wrap">
                                <span *ngIf="VPmoreHide">
                                    <em class="fa fa-chevron-down mt-1 more-arrow" (click)="VPmoreHide=false"></em>
                                </span>
                                <span *ngIf="!VPmoreHide" (click)="VPmoreHide">
                                    <em class="fa fa-chevron-up mt-1 more-arrow" (click)="VPmoreHide=true"></em>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="!VPmoreHide" class="row col-sm-12">
                            <div class="col-sm-3  p-0">
                                <form class="p-1 box-align">
                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Last Name
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtLastName',$event,'Vertical')" value="40" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Gender
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlGender',$event,'Vertical')" value="41" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            EMR#
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtMedRecNo',$event,'Vertical')" value="42" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Email
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtPatientEmail',$event,'Vertical')" value="43" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Suffix
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtSuffix',$event,'Vertical')" value="44" [disabled]="!enableTabFocus">

                                        </span>
                                    </div>
                                </form>
                            </div>

                            <!-- Last Name,First Name,Middle Name,Address 1-->
                            <div class="col-sm-3 p-0">
                                <form class="p-1 box-align">
                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            First Name
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtFirstName',$event,'Vertical')" value="45" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Date of birth
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtDateOfBirth',$event,'Vertical')" value="46" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            PMS/PWS#
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtOrderNo',$event,'Vertical')" value="47" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Look Up Patient Details
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('btnSearch',$event,'Vertical')" value="48" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-verhide">
                                        <div class="hidebox">
                                            <span class="col-sm-10 pl-1">

                                            </span>
                                            <span class="col-sm-2">

                                            </span>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <!-- Last Name,First Name,Middle Name,Address 1-->
                            <div class="col-sm-3 p-0">
                                <form class="p-1 box-align">
                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Middle Name
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtMiddleName',$event,'Vertical')" value="49" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            SSN#
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtSSNNo',$event,'Vertical')" value="50" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Home phone
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtPatientHomePhone',$event,'Vertical')" value="51" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Work phone
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtPatientWorkPhone',$event,'Vertical')" value="52" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-verhide">
                                        <div class="hidebox">
                                            <span class="col-sm-10 pl-1">

                                            </span>
                                            <span class="col-sm-2">

                                            </span>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <!-- Last Name,First Name,Middle Name,Address 1-->
                            <div class="col-sm-3 p-0">
                                <form class="p-1 box-align">
                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Address 1
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtAddress1',$event,'Vertical')" value="53" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Address 2
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtAddress2',$event,'Vertical')" value="54" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            City
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtCity',$event,'Vertical')" value="55" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            State
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('drpState',$event,'Vertical')" value="56" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Zip
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtZip',$event,'Vertical')" value="57" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <!-- supplemental Information data -->
                        <div class="col-sm-12 d-flex mt-3 p-0">
                            <div class="col-sm-3 color p-0">
                                <strong>{{supplementalHeadertext}}</strong>
                            </div>
                            <span class="col-sm-8 pl-0 mt-1">
                                <hr class="border-line">
                            </span>
                            <!-- // more hide -->
                            <div class="col-sm-1 button-wrap">
                                <span *ngIf="VSmoreHide">
                                    <em class="fa fa-chevron-down mt-1 more-arrow" (click)="VSmoreHide=false"></em>
                                </span>
                                <span *ngIf="!VSmoreHide" (click)="VSmoreHide">
                                    <em class="fa fa-chevron-up mt-1 more-arrow" (click)="VSmoreHide=true"></em>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="!VSmoreHide" class="row col-sm-12">
                            <div class="col-sm-3 p-0">
                                <form class="p-1 box-align">
                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Clinical Information
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtClinicalInfo',$event,'Vertical')" value="58" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>
                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            PSA Value
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtPSARange',$event,'Vertical')" value="59" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>
                                </form>
                            </div>
                            <div class="col-sm-3 p-0">
                                <form class="d-flex p-1 box-align">
                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Special instructions
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtSpecial',$event,'Vertical')" value="60" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>
                                </form>
                            </div>
                            <div class="col-sm-3 p-0">
                                <form class="d-flex p-1 box-align">
                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Send Out Note
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtSendOutNotes',$event,'Vertical')" value="61" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>
                                </form>
                            </div>
                            <div class="col-sm-3 p-0">
                                <form class="d-flex p-1 box-align">
                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Clinical Stage
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlClinicalStages',$event,'Vertical')" value="62" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>
                                </form>
                            </div>

                        </div>

                        <!-- Insurance -->
                        <div class="col-sm-12 d-flex mt-3 p-0">
                            <div class="col-sm-2 color p-0">
                                <strong>{{headertext}}</strong>
                            </div>
                            <span class="col-sm-9 pl-0 mt-1">
                                <hr class="border-line">
                            </span>
                            <!-- // more hide -->
                            <div class="col-sm-1 button-wrap">
                                <span *ngIf="VIPmoreHide">
                                    <em class="fa fa-chevron-down mt-1 more-arrow" (click)="VIPmoreHide=false"></em>
                                </span>
                                <span *ngIf="!VIPmoreHide" (click)="VIPmoreHide">
                                    <em class="fa fa-chevron-up mt-1 more-arrow" (click)="VIPmoreHide=true"></em>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="!VIPmoreHide" class="mt-3">
                            <!-- Primary Insurance data -->
                            <div class="row col-sm-12 mt-3 ml-2 more-data mb-2">
                                <div class="col-sm-2 p-0">
                                    <strong>{{PinsuranceHeadertext}}</strong>
                                </div>
                            </div>
                            <div class="row col-sm-12">
                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Relationship
                                            </span>
                                            <span class="col-sm-2 p-0 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPrimaryRelationship',$event,'Vertical')"
                                                    value="63" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Last name
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtLastName1',$event,'Vertical')" value="64" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                DOB
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (click)="saveForValue('SubscribeInfo_txtDob1',configuredtabindex.value)"
                                                    (change)="save('SubscribeInfo_txtDob1',$event,'Vertical')" value="65" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Group/Plan
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGroupName1',$event,'Vertical')" value="66" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Suffix
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespSuffix1',$event,'Vertical')"
                                                    value="67" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>


                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                First name
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespFirstName1',$event,'Vertical')"
                                                    value="68" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Gender
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGender1',$event,'Vertical')" value="69" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Group#
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGroupNo1',$event,'Vertical')" value="70" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Plan type
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPrimaryPlanType',$event,'Vertical')"
                                                    value="71" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Middle Initial
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespMiddleInitials1',$event,'Vertical')"
                                                    value="72" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Policy#
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPolicyNo1',$event,'Vertical')" value="73" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Patient policy
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientPolicyno1',$event,'Vertical')"
                                                    value="74" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Payor
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPayor1',$event,'Vertical')" value="75" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Address 1
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtAddress11',$event,'Vertical')" value="76" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                City
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtCity1',$event,'Vertical')" value="77" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Zip
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtZip1',$event,'Vertical')" value="78" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Insurance code
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtIC1',$event,'Vertical')" value="79" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Address 2
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtAddress12',$event,'Vertical')" value="80" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                State
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtState1',$event,'Vertical')" value="81" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                    </form>
                                </div>
                            </div>

                            <!-- Secondary Insurance data -->
                            <div class="row col-sm-12 mt-3 ml-2 more-data mb-2">
                                <div class="col-sm-2 p-0">
                                    <strong>{{SinsuranceHeadertext}}</strong>
                                </div>
                            </div>
                            <div class="row col-sm-12">
                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Relationship
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtSecondaryRelationship',$event,'Vertical')"
                                                    value="82" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Last name
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtLastName2',$event,'Vertical')" value="83" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                DOB
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtDob2',$event,'Vertical')" value="84" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Group/Plan
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGroupName2',$event,'Vertical')" value="85" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Suffix
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespSuffix2',$event,'Vertical')"
                                                    value="86" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                First name
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespFirstName2',$event,'Vertical')"
                                                    value="87" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Gender
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGender2',$event,'Vertical')" value="88" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Group#
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGroupNo2',$event,'Vertical')" value="89" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Plan type
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtSecondaryPlanType',$event,'Vertical')"
                                                    value="90" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Middle Initial
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespMiddleInitials2',$event,'Vertical')"
                                                    value="91" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Policy#
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPolicyNo2',$event,'Vertical')" value="92" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Patient policy
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientPolicyno2',$event,'Vertical')"
                                                    value="93" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Payor
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPayor2',$event,'Vertical')" value="94" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Address 1
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtAddress21',$event,'Vertical')" value="95" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                City
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtCity2',$event,'Vertical')" value="96" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Zip
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtZip2',$event,'Vertical')" value="97" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Insurance code
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtIC2',$event,'Vertical')" value="98" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Address 2
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtAddress22',$event,'Vertical')" value="99" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                State
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtState2',$event,'Vertical')" value="100" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Insurance type code
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_drpInsuranceTypeCode2',$event,'Vertical')"
                                                    value="101" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>


                                    </form>
                                </div>
                            </div>

                            <!-- Teritary Insurance data -->
                            <div class="row col-sm-12 mt-3 ml-2 more-data mb-2">
                                <div class="col-sm-2 p-0">
                                    <strong>{{TinsuranceHeadertext}}</strong>
                                </div>
                            </div>
                            <div class="row col-sm-12">
                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Relationship
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtTertiayRelationship',$event,'Vertical')"
                                                    value="102" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Last name
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtLastName3',$event,'Vertical')" value="103" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                DOB
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtDob3',$event,'Vertical')" value="104" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Group/Plan
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGroupName3',$event,'Vertical')"
                                                    value="105" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Suffix
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespSuffix3',$event,'Vertical')"
                                                    value="106" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                First name
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespFirstName3',$event,'Vertical')"
                                                    value="107" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Gender
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGender3',$event,'Vertical')" value="108" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Group#
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGroupNo3',$event,'Vertical')" value="109" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Plan type
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtTertiayPlanType',$event,'Vertical')"
                                                    value="110" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Middle Initial
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespMiddleInitials3',$event,'Vertical')"
                                                    value="111" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Policy#
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPolicyNo3',$event,'Vertical')" value="112" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Patient policy
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientPolicyno3',$event,'Vertical')"
                                                    value="113" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Payor
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPayor3',$event,'Vertical')" value="114" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Address 1
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtAddress31',$event,'Vertical')" value="115" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                City
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtCity3',$event,'Vertical')" value="116" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Zip
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtZip3',$event,'Vertical')" value="117" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Insurance code
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtIC3',$event,'Vertical')" value="118" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Address 2
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtAddress32',$event,'Vertical')" value="119" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                State
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtState3',$event,'Vertical')" value="120" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Insurance type code
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_drpInsuranceTypeCode3',$event,'Vertical')"
                                                    value="121" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <!-- guarantor Insurance data -->
                            <div class="row col-sm-12 mt-3 ml-2 more-data mb-2">
                                <div class="col-sm-2 p-0">
                                    <strong>{{GinsuranceHeadertext}}</strong>
                                </div>
                            </div>
                            <div class="row col-sm-12">
                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Relationship
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_ddlPatientRelationship1',$event,'Vertical')"
                                                    value="122" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Home phone
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeHomePhone',$event,'Vertical')"
                                                    value="123" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                City
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeCity',$event,'Vertical')"
                                                    value="124" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Last name
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeLastName',$event,'Vertical')"
                                                    value="125" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Work phone
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeWorkPhone',$event,'Vertical')"
                                                    value="126" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                State
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_drpPatientRelativeState',$event,'Vertical')"
                                                    value="127" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                First name
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeFirstName',$event,'Vertical')"
                                                    value="128" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Address1
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeAddress1',$event,'Vertical')"
                                                    value="129" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Zip
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeZip',$event,'Vertical')"
                                                    value="130" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Middle initial
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeMiddleName',$event,'Vertical')"
                                                    value="131" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Address2
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeAddress2',$event,'Vertical')"
                                                    value="132" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Suffix
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeSuffix',$event,'Vertical')"
                                                    value="133" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>

                        <!-- button data -->
                        <div class="col-sm-12 d-flex mt-3 p-0">
                            <div class="col-sm-2 color p-0">
                                <strong>{{ButtonHeadertext}}</strong>
                            </div>
                            <span class="col-sm-9 pl-0 mt-1">
                                <hr class="border-line">
                            </span>
                            <!-- // more hide -->
                            <div class="col-sm-1 button-wrap">
                                <span *ngIf="VBmoreHide">
                                    <em class="fa fa-chevron-down mt-1 more-arrow" (click)="VBmoreHide=false"></em>
                                </span>
                                <span *ngIf="!VBmoreHide" (click)="VBmoreHide">
                                    <em class="fa fa-chevron-up mt-1 more-arrow" (click)="VBmoreHide=true"></em>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="!VBmoreHide" class="row col-sm-12">
                            <div class="col-sm-3  p-0">
                                <form class="p-1 box-align">
                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Save
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('lnkSaveOnly',$event,'Vertical')" value="134" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Order
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('btnOrder',$event,'Vertical')" value="135" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Send out case
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('btnSentOut',$event,'Vertical')" value="136" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>
                                </form>
                            </div>

                            <!-- Last Name,First Name,Middle Name,Address 1-->
                            <div class="col-sm-3 p-0">
                                <form class="p-1 box-align">
                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Save & close
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('btnsaveonly',$event,'Vertical')" value="137" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Accession
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('btnAccession',$event,'Vertical')" value="138" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Reject
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('btnReject',$event,'Vertical')" value="139" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>
                                </form>
                            </div>

                            <!-- Last Name,First Name,Middle Name,Address 1-->
                            <div class="col-sm-3 p-0">
                                <form class="p-1 box-align">
                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Save & proceed to site information
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('btnProceed',$event,'Vertical')" value="140" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Cancel order
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('btnCancelOrder',$event,'Vertical')" value="141" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>
                                </form>
                            </div>

                            <!-- Last Name,First Name,Middle Name,Address 1-->
                            <div class="col-sm-3 p-0">
                                <form class="p-1 box-align">
                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Cancel Requisition
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('btnCancelReq',$event,'Vertical')" value="142" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Return
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('btnCancel',$event,'Vertical')" value="143" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>


                    <!-- Default HTML-->
                    <div *ngIf="!enableVertical && !enableHorizontal">
                        <!-- Case Information data -->
                        <div class="col-sm-12 d-flex mt-3 p-0">
                            <div class="col-sm-2 color p-0">
                                <strong>{{caseheadertext}}</strong>
                            </div>
                            <span class="col-sm-9 pl-0 mt-1">
                                <hr class="border-line">
                            </span>
                            <!-- // more hide -->
                            <div class="col-sm-1 button-wrap">
                                <span *ngIf="VCmoreHide">
                                    <em class="fa fa-chevron-down mt-1 more-arrow" (click)="VCmoreHide=false"></em>
                                </span>
                                <span *ngIf="!VCmoreHide" (click)="VCmoreHide">
                                    <em class="fa fa-chevron-up mt-1 more-arrow" (click)="VCmoreHide=true"></em>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="!VCmoreHide" class="row col-sm-12">

                            <div class="col-sm-4">
                                <form class="p-1 box-align" [formGroup]="tabSequenceCaseInfoForm">
                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Case Profile
                                        </span>
                                        <span class="col-sm-2 pl-3 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)"
                                                (change)="save('ddlCaseProfile',$event,'Default')" maxlength="3"
                                                value="{{caseProfile}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Case type
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlCaseType',$event,'Default')" value="{{caseType}}" [disabled]="!enableTabFocus">

                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Other CaseType
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtOtherCaseType',$event,'Default')" value="{{otherCaseType}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            External Laboratory
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlExternalLab',$event,'Default')"
                                                value="{{externalLaboratory}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="col-sm-12 d-flex pl-0">
                                        <div class="col-sm-6 p-1 label-ver">
                                            <span class="col-sm-10 pl-1 mr-3">
                                                Collection date
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    value="{{collectionDate}}"
                                                    (change)="save('txtCollectionDate',$event,'Default')" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="col-sm-6 p-1 label-ver">
                                            <span class="col-sm-10 pl-1 mr-3">
                                                Collection time
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    value="{{collectionTime}}"
                                                    (change)="save('txtCollectionTime',$event,'Default')" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 d-flex pl-0">
                                        <div class="col-sm-6 p-1 label-ver">
                                            <span class="col-sm-10 pl-1 mr-3">
                                                Recieved date
                                            </span>
                                            <span class="col-sm-2 p-0 m-1">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    value="{{recievedDate}}"
                                                    (change)="save('txtRecievedDate',$event,'Default')" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="col-sm-6 p-1 label-ver">
                                            <span class="col-sm-10 pl-1 mr-3">
                                                Recieved time
                                            </span>
                                            <span class="col-sm-2 p-0 m-1">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtReceivedTime',$event,'Default')"
                                                    value="{{recievedTime}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Laboratory
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlLaboratory',$event,'Default')" value="{{laboratory}}" [disabled]="!enableTabFocus">

                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Hospital/Facility
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtOH',$event,'Default')" value="{{hospitalFacility}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Ordering physician
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlPhysician',$event,'Default')" value="{{orderingPhysician}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Lab case#
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtLabCaseNo',$event,'Default')" value="{{labCase}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Send in laboratory
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlSendInLab',$event,'Default')" value="{{sendInlaboratory}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            S/O Services
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlSendOutServices',$event,'Default')" value="{{services}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>
                                </form>
                            </div>

                            <div class="col-sm-4 p-0">
                                <form class="p-1 box-align" [formGroup]="tabSequenceCaseInfoForm">
                                    <div class="row col-sm-12 p-1 label-verhide">
                                        <div class="hidebox">
                                            <span class="col-sm-10 pl-1">

                                            </span>
                                            <span class="col-sm-2 pl-3">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    value="15" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Extraction Procedure
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlprocedure',$event,'Default')" value="{{procedure}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>



                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Other ExtractionProcedure
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtOtherExtractionProcedure',$event,'Default')"
                                                value="{{otherExtractionProcedure}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Services
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlServices',$event,'Default')" value="{{Services1}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Kit/requisition#
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtRequisition',$event,'Default')" value="{{kitRequisition}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Referring physician
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtReferringPhysician',$event,'Default')"
                                                value="{{referringPhysician}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Referring physician Fax#
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtRefPhysicianFaxNo',$event,'Default')"
                                                value="{{referringPhysicianFax}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Copy reports to referring priority physician
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('chkReports',$event,'Default')" value="{{copyReport}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Tracking #
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtClientTrackingNo',$event,'Default')" value="{{tracking}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            S/O Bill To
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlSendOutBillTo',$event,'Default')" value="{{soBillTo}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            LMP Date
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtLmpDate',$event,'Default')" value="{{lmpDate}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            For Research use
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('chkResearch',$event,'Default')" value="{{forResearchUse}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>
                                </form>
                            </div>

                            <div class="col-sm-4 p-0">
                                <form class="p-1 box-align" [formGroup]="tabSequenceCaseInfoForm">
                                    <div class="row col-sm-12 p-1 label-verhide">
                                        <div class="hidebox">
                                            <span class="col-sm-10 pl-1">

                                            </span>
                                            <span class="col-sm-2 pl-3">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    value="15" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            No of jars
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtNoJars',$event,'Default')" value="{{noOfJars}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Site Schema
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlSiteScheme',$event,'Default')" value="{{siteSchema}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Reference #
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtRefNumber',$event,'Default')" value="{{reference}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Encounter
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtEncounter',$event,'Default')" value="{{encounter}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="col-sm-12 d-flex pl-0">
                                        <div class="col-sm-6 p-1 label-ver">
                                            <span class="col-sm-10 pl-1 pr-1 mr-3">
                                                ICD-10(indicative)
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtICD1',$event,'Default')" value="{{icd1}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="col-sm-6 p-1 label-ver">
                                            <span class="col-sm-10 pl-1 pr-1 mr-3">
                                                ICD-10(indicative)
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtICD2',$event,'Default')" value="{{icd2}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 d-flex pl-0">
                                        <div class="col-sm-6 p-1 label-ver">
                                            <span class="col-sm-10 pl-1 pr-1 mr-3">
                                                ICD-10(indicative)
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtICD3',$event,'Default')" value="{{icd3}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="col-sm-6 p-1 label-ver">
                                            <span class="col-sm-10 pl-1 pr-1 mr-3">
                                                ICD-10(indicative)
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtICD4',$event,'Default')" value="{{icd4}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 d-flex pl-0">
                                        <div class="col-sm-6 p-1 label-ver">
                                            <span class="col-sm-10 pl-1 pr-1 mr-3">
                                                ICD-10(indicative)
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtICD5',$event,'Default')" value="{{icd5}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="col-sm-6 p-1 label-ver">
                                            <span class="col-sm-10 pl-1 pr-1 mr-3">
                                                ICD-10(indicative)
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtICD6',$event,'Default')" value="{{icd6}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 d-flex pl-0">
                                        <div class="col-sm-6 p-1 label-ver">
                                            <span class="col-sm-10 pl-1 pr-1 mr-3">
                                                ICD-10(indicative)
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtICD7',$event,'Default')" value="{{icd7}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="col-sm-6 p-1 label-ver">
                                            <span class="col-sm-10 pl-1 pr-1 mr-3">
                                                ICD-10(indicative)
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('txtICD8',$event,'Default')" value="{{icd8}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Bill To
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlBillTo',$event,'Default')" value="{{billTo}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Priority
                                        </span>
                                        <span class="col-sm-2 pl-3">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlCasePriority',$event,'Default')" value="{{priority}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <!-- Patient Information data -->
                        <div class="col-sm-12 d-flex mt-3 p-0">
                            <div class="col-sm-2 color p-0">
                                <strong>{{patientheadertext}}</strong>
                            </div>
                            <span class="col-sm-9 pl-0 mt-1">
                                <hr class="border-line">
                            </span>
                            <!-- // more hide -->
                            <div class="col-sm-1 button-wrap">
                                <span *ngIf="VPmoreHide">
                                    <em class="fa fa-chevron-down mt-1 more-arrow" (click)="VPmoreHide=false"></em>
                                </span>
                                <span *ngIf="!VPmoreHide" (click)="VPmoreHide">
                                    <em class="fa fa-chevron-up mt-1 more-arrow" (click)="VPmoreHide=true"></em>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="!VPmoreHide" class="row col-sm-12">
                            <div class="col-sm-3  p-0">
                                <form class="p-1 box-align">
                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Last Name
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtLastName',$event,'Default')" value="{{lastName}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Gender
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlGender',$event,'Default')" value="{{gender}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            EMR#
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtMedRecNo',$event,'Default')" value="{{emr}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Email
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtPatientEmail',$event,'Default')" value="{{email}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Suffix
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtSuffix',$event,'Default')" value="{{patSuffix}}" [disabled]="!enableTabFocus">

                                        </span>
                                    </div>
                                </form>
                            </div>

                            <!-- Last Name,First Name,Middle Name,Address 1-->
                            <div class="col-sm-3 p-0">
                                <form class="p-1 box-align">
                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            First Name
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtFirstName',$event,'Default')" value="{{firstName}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Date of birth
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtDateOfBirth',$event,'Default')" value="{{dob}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            PMS/PWS#
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtOrderNo',$event,'Default')" value="{{pmsPws}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Look Up Patient Details
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('btnSearch',$event,'Default')" value="{{lookUp}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-verhide">
                                        <div class="hidebox">
                                            <span class="col-sm-10 pl-1">

                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    value="" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <!-- Last Name,First Name,Middle Name,Address 1-->
                            <div class="col-sm-3 p-0">
                                <form class="p-1 box-align">
                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Middle Name
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtMiddleName',$event,'Default')" value="{{middleName}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            SSN#
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtSSNNo',$event,'Default')" value="{{ssn}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Home phone
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtPatientHomePhone',$event,'Default')" value="{{homePhone}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Work phone
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtPatientWorkPhone',$event,'Default')" value="{{workPhone}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-verhide">
                                        <div class="hidebox">
                                            <span class="col-sm-10 pl-1">

                                            </span>
                                            <span class="col-sm-2">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    value="" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <!-- Last Name,First Name,Middle Name,Address 1-->
                            <div class="col-sm-3 p-0">
                                <form class="p-1 box-align">
                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Address 1
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtAddress1',$event,'Default')" value="{{address1}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Address 2
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtAddress2',$event,'Default')" value="{{address2}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            City
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtCity',$event,'Default')" value="{{city}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            State
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('drpState',$event,'Default')" value="{{state}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver">
                                        <span class="col-sm-10 pl-1">
                                            Zip
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtZip',$event,'Default')" value="{{zip}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <!-- supplemental Information data -->
                        <div class="col-sm-12 d-flex mt-3 p-0">
                            <div class="col-sm-3 color p-0">
                                <strong>{{supplementalHeadertext}}</strong>
                            </div>
                            <span class="col-sm-8 pl-0 mt-1">
                                <hr class="border-line">
                            </span>
                            <!-- // more hide -->
                            <div class="col-sm-1 button-wrap">
                                <span *ngIf="VSmoreHide">
                                    <em class="fa fa-chevron-down mt-1 more-arrow" (click)="VSmoreHide=false"></em>
                                </span>
                                <span *ngIf="!VSmoreHide" (click)="VSmoreHide">
                                    <em class="fa fa-chevron-up mt-1 more-arrow" (click)="VSmoreHide=true"></em>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="!VSmoreHide" class="row col-sm-12">
                            <div class="col-sm-3 p-0">
                                <form class="p-1 box-align">
                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Clinical Information
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtClinicalInfo',$event,'Default')" value="{{clinicalInfo}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>
                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            PSA Value
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtPSARange',$event,'Default')" value="{{psavalue}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>
                                </form>
                            </div>
                            <div class="col-sm-3 p-0">
                                <form class="d-flex p-1 box-align">
                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Special instructions
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtSpecial',$event,'Default')" value="{{specialInstructions}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>
                                </form>
                            </div>
                            <div class="col-sm-3 p-0">
                                <form class="d-flex p-1 box-align">
                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Send Out Note
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('txtSendOutNotes',$event,'Default')" value="{{sendOutNote}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>
                                </form>
                            </div>
                            <div class="col-sm-3 p-0">
                                <form class="d-flex p-1 box-align">
                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Clinical Stage
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('ddlClinicalStages',$event,'Default')" value="{{clinicalStage}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>
                                </form>
                            </div>

                        </div>

                        <!-- Insurance -->
                        <div class="col-sm-12 d-flex mt-3 p-0">
                            <div class="col-sm-2 color p-0">
                                <strong>{{headertext}}</strong>
                            </div>
                            <span class="col-sm-9 pl-0 mt-1">
                                <hr class="border-line">
                            </span>
                            <!-- // more hide -->
                            <div class="col-sm-1 button-wrap">
                                <span *ngIf="VIPmoreHide">
                                    <em class="fa fa-chevron-down mt-1 more-arrow" (click)="VIPmoreHide=false"></em>
                                </span>
                                <span *ngIf="!VIPmoreHide" (click)="VIPmoreHide">
                                    <em class="fa fa-chevron-up mt-1 more-arrow" (click)="VIPmoreHide=true"></em>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="!VIPmoreHide" class="mt-3">
                            <!-- Primary Insurance data -->
                            <div class="row col-sm-12 mt-3 ml-2 more-data mb-2">
                                <div class="col-sm-2 p-0">
                                    <strong>{{PinsuranceHeadertext}}</strong>
                                </div>
                            </div>
                            <div class="row col-sm-12">
                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Relationship
                                            </span>
                                            <span class="col-sm-2 p-0 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPrimaryRelationship',$event,'Default')"
                                                    value="{{pRelationship}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Last name
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtLastName1',$event,'Default')"
                                                    value="{{pLastName}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                DOB
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtDob1',$event,'Default')" value="{{pDob}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Group/Plan
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGroupName1',$event,'Default')"
                                                    value="{{pGroupPlan}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Suffix
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespSuffix1',$event,'Default')"
                                                    value="{{pSuffix}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>


                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                First name
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespFirstName1',$event,'Default')"
                                                    value="{{pFirstName}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Gender
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGender1',$event,'Default')"
                                                    value="{{pGender}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Group#
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGroupNo1',$event,'Default')"
                                                    value="{{pGroup}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Plan type
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPrimaryPlanType',$event,'Default')"
                                                    value="{{pPlanType}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Middle Initial
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespMiddleInitials1',$event,'Default')"
                                                    value="{{pMiddleInitial}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Policy#
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPolicyNo1',$event,'Default')"
                                                    value="{{pPolicy}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Patient policy
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientPolicyno1',$event,'Default')"
                                                    value="{{pPatientPolicy}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Payor
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPayor1',$event,'Default')"
                                                    value="{{pPayor}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Address 1
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtAddress11',$event,'Default')"
                                                    value="{{pAddress1}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                City
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtCity1',$event,'Default')"
                                                    value="{{pCity}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Zip
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtZip1',$event,'Default')" value="{{pZip}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Insurance code
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtIC1',$event,'Default')"
                                                    value="{{pInsuranceCode}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Address 2
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtAddress12',$event,'Default')"
                                                    value="{{pAddress2}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                State
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtState1',$event,'Default')"
                                                    value="{{pState}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                    </form>
                                </div>
                            </div>

                            <!-- Secondary Insurance data -->
                            <div class="row col-sm-12 mt-3 ml-2 more-data mb-2">
                                <div class="col-sm-2 p-0">
                                    <strong>{{SinsuranceHeadertext}}</strong>
                                </div>
                            </div>
                            <div class="row col-sm-12">
                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Relationship
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtSecondaryRelationship',$event,'Default')"
                                                    value="{{sRelationship}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Last name
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtLastName2',$event,'Default')"
                                                    value="{{sLastName}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                DOB
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtDob2',$event,'Default')" value="{{sDob}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Group/Plan
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGroupName2',$event,'Default')"
                                                    value="{{sGroupPlan}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Suffix
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespSuffix2',$event,'Default')"
                                                    value="{{sSuffix}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                First name
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespFirstName2',$event,'Default')"
                                                    value="{{sFirstName}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Gender
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGender2',$event,'Default')"
                                                    value="{{sGender}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Group#
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGroupNo2',$event,'Default')"
                                                    value="{{sGroup}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Plan type
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtSecondaryPlanType',$event,'Default')"
                                                    value="{{sPlanType}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Middle Initial
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespMiddleInitials2',$event,'Default')"
                                                    value="{{sMiddleInitial}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Policy#
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPolicyNo2',$event,'Default')"
                                                    value="{{sPolicy}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Patient policy
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientPolicyno2',$event,'Default')"
                                                    value="{{sPatientPolicy}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Payor
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPayor2',$event,'Default')"
                                                    value="{{sPayor}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Address 1
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtAddress21',$event,'Default')"
                                                    value="{{sAddress1}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                City
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtCity2',$event,'Default')"
                                                    value="{{sCity}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Zip
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtZip2',$event,'Default')" value="{{sZip}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Insurance code
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtIC2',$event,'Default')"
                                                    value="{{sInsuranceCode}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Address 2
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtAddress22',$event,'Default')"
                                                    value="{{sAddress2}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                State
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtState2',$event,'Default')"
                                                    value="{{sState}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Insurance type code
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_drpInsuranceTypeCode2',$event,'Default')"
                                                    value="{{sInsuranceTypeCode}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>


                                    </form>
                                </div>
                            </div>

                            <!-- Teritary Insurance data -->
                            <div class="row col-sm-12 mt-3 ml-2 more-data mb-2">
                                <div class="col-sm-2 p-0">
                                    <strong>{{TinsuranceHeadertext}}</strong>
                                </div>
                            </div>
                            <div class="row col-sm-12">
                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Relationship
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtTertiayRelationship',$event,'Default')"
                                                    value="{{tRelationship}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Last name
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtLastName3',$event,'Default')"
                                                    value="{{tLastName}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                DOB
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtDob3',$event,'Default')" value="{{tDob}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Group/Plan
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGroupName3',$event,'Default')"
                                                    value="{{tGroupPlan}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Suffix
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespSuffix3',$event,'Default')"
                                                    value="{{tSuffix}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                First name
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespFirstName3',$event,'Default')"
                                                    value="{{tFirstName}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Gender
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGender3',$event,'Default')"
                                                    value="{{tGender}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Group#
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtGroupNo3',$event,'Default')"
                                                    value="{{tGroup}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Plan type
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtTertiayPlanType',$event,'Default')"
                                                    value="{{tPlanType}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Middle Initial
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtRespMiddleInitials3',$event,'Default')"
                                                    value="{{tMiddleInitial}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Policy#
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPolicyNo3',$event,'Default')"
                                                    value="{{tPolicy}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Patient policy
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientPolicyno3',$event,'Default')"
                                                    value="{{tPatientPolicy}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Payor
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPayor3',$event,'Default')"
                                                    value="{{tPayor}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Address 1
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtAddress31',$event,'Default')"
                                                    value="{{tAddress1}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                City
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtCity3',$event,'Default')"
                                                    value="{{tCity}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Zip
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtZip3',$event,'Default')" value="{{tZip}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Insurance code
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtIC3',$event,'Default')"
                                                    value="{{tInsuranceCode}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Address 2
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtAddress32',$event,'Default')"
                                                    value="{{tAddress2}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                State
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtState3',$event,'Default')"
                                                    value="{{tState}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Insurance type code
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_drpInsuranceTypeCode3',$event,'Default')"
                                                    value="{{tInsuranceTypeCode}}"  [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>


                            <!-- guarantor Insurance data -->
                            <div class="row col-sm-12 mt-3 ml-2 more-data mb-2">
                                <div class="col-sm-2 p-0">
                                    <strong>{{GinsuranceHeadertext}}</strong>
                                </div>
                            </div>
                            <div class="row col-sm-12">
                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Relationship
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_ddlPatientRelationship1',$event,'Default')"
                                                    value="{{gRelationship}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Home phone
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeHomePhone',$event,'Default')"
                                                    value="{{gHomePhone}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                City
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeCity',$event,'Default')"
                                                    value="{{gCity}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">

                                            <span class="col-sm-10 pl-1">
                                                Last name
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeLastName',$event,'Default')"
                                                    value="{{gLastName}}" [disabled]="!enableTabFocus">
                                            </span>

                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Work phone
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeWorkPhone',$event,'Default')"
                                                    value="{{gWorkPhone}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                State
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_drpPatientRelativeState',$event,'Default')"
                                                    value="{{gState}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                First name
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeFirstName',$event,'Default')"
                                                    value="{{gFirstName}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Address1
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeAddress1',$event,'Default')"
                                                    value="{{gAddress1}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Zip
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeZip',$event,'Default')"
                                                    value="{{gZip}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                    </form>
                                </div>

                                <div class="col-sm-2 p-0">
                                    <form class="p-1 box-align">
                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Middle initial
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeMiddleName',$event,'Default')"
                                                    value="{{gMiddleInitial}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Address2
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeAddress2',$event,'Default')"
                                                    value="{{gAddress2}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>

                                        <div class="row col-sm-12 p-1 label-ver align-center">
                                            <span class="col-sm-10 pl-1">
                                                Suffix
                                            </span>
                                            <span class="col-sm-2 p-0">
                                                <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                    (change)="save('SubscribeInfo_txtPatientRelativeSuffix',$event,'Default')"
                                                    value="{{gSuffix}}" [disabled]="!enableTabFocus">
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <!-- button data -->
                        <div class="col-sm-12 d-flex mt-3 p-0">
                            <div class="col-sm-2 color p-0">
                                <strong>{{ButtonHeadertext}}</strong>
                            </div>
                            <span class="col-sm-9 pl-0 mt-1">
                                <hr class="border-line">
                            </span>
                            <!-- // more hide -->
                            <div class="col-sm-1 button-wrap">
                                <span *ngIf="VBmoreHide">
                                    <em class="fa fa-chevron-down mt-1 more-arrow" (click)="VBmoreHide=false"></em>
                                </span>
                                <span *ngIf="!VBmoreHide" (click)="VBmoreHide">
                                    <em class="fa fa-chevron-up mt-1 more-arrow" (click)="VBmoreHide=true"></em>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="!VBmoreHide" class="row col-sm-12">
                            <div class="col-sm-3  p-0">
                                <form class="p-1 box-align">
                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Save
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('lnkSaveOnly',$event,'Default')" value="{{bSave}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Order
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('btnOrder',$event,'Default')" value="{{bOrder}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Send out case
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('btnSentOut',$event,'Default')" value="{{bSendOutCase}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>
                                </form>
                            </div>


                            <div class="col-sm-3 p-0">
                                <form class="p-1 box-align">
                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Save & close
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('btnsaveonly',$event,'Default')" value="{{bSaveClose}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Accession
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('btnAccession',$event,'Default')" value="{{bAccession}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Reject
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('btnReject',$event,'Default')" value="{{bReject}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>
                                </form>
                            </div>


                            <div class="col-sm-3 p-0">
                                <form class="p-1 box-align">
                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Save & proceed to site information
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('btnProceed',$event,'Default')" value="{{bSaveProceed}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Cancel order
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('btnCancelOrder',$event,'Default')" value="{{bCancelOrder}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>
                                </form>
                            </div>


                            <div class="col-sm-3 p-0">
                                <form class="p-1 box-align">
                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Cancel Requisition
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('btnCancelReq',$event,'Default')" value="{{bCancelRequistion}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>

                                    <div class="row col-sm-12 p-1 label-ver align-center">
                                        <span class="col-sm-10 pl-1">
                                            Return
                                        </span>
                                        <span class="col-sm-2">
                                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3"
                                                (change)="save('btnCancel',$event,'Default')" value="{{bReturn}}" [disabled]="!enableTabFocus">
                                        </span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!--Save and upload buttons -->
                    <div class="col-sm-12 button-wrap mt-2">
                        <!-- <button mat-raised-button class="admin-btn-success mr-4">
                            Export
                        </button> -->
                        <button mat-raised-button class="admin-btn-success mr-4"
                            (click)="loadUploadScreen()" [disabled]="!enableTabFocus || uploadBtn">
                            Upload
                        </button>
                        <button mat-raised-button class="admin-btn-success mr-4" [disabled]="editBtn" (click)="resetTabIndex()" [disabled]="!enableTabFocus">
                            Reset
                        </button>
                        <button mat-raised-button class="admin-btn-success" [disabled]="createBtn"
                            (click)="updateTabIndexOrder()" [disabled]="!enableTabFocus">
                            Save
                        </button>
                    </div>
                </div>
        </div>


        <!-- Upload  -->
        <div *ngIf="uploadClicked" class="mt-3 my-manage-form">
            <div class="modal-header admin-model-header mb-2 mt-3">
                <h3 class="modal-title w-100 admin-model-header-txt main-title">
                    <strong>Upload Tab Focus</strong>
                </h3>
            </div>
            <div class="button-field-wrap m-1 p-0">
                <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
                    <mat-label class="d-flex">
                        Templates
                    </mat-label>
                    <!-- <em class="fa fa-chevron-down chevron-align"></em> -->
                    <mat-select disableOptionCentering class="ml-2 temp-mat-align">
                        <mat-option class="temp-font-size" (click)="downloadTemplate('allFieldsCheck')">
                            <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="button-field-wrap">
                <span class="instr-size mb-2">
                    <img aria-hidden="true" alt="help" class="mb-1" src="/assets/icons/help.svg" width="15px" height="auto" />
                    You can download the sample template file to upload {{templateData.menuURL}}.</span>
            </div>

            <div class="col-sm-12 p-0" *ngIf="gridwidth == 0">
                <div class="image-height" (fileDropped)="onFileChange($event)" appDnd>
                    <input type="file" id="fileDropRef" #fileDropRef click="value = null" value="" (change)="onFileChange($event)"
                        hidden />
                    <span class="align-center img-wrap">
                        <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
                    </span>
                    <span>Drag & Drop excel files here</span>
                    <span class="align-center">Or</span>
                    <label class="btn-sm btn rounded browse ml-5" for="fileDropRef">Browse File</label>
                </div>
                <button mat-raised-button class="admin-btn-success mt-4" (click)="backSelect()">
                    Back
                </button>
            </div>

            <div *ngIf="gridwidth > 0">
                <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" (initialized)="initGrid(grid)" #grid
                    [headersVisibility]="'Column'" (formatItem)="formateItem(grid, $event)">
                    <!-- Status -->
                    <wj-flex-grid-column [header]="'Status'" [binding]="status" *ngIf="postUpload" [allowResizing]="true"
                        [width]="170" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                            <span [ngStyle]="{color: cell.item.status=='Success' ?'green':cell.item.status=='Ignored' ? '#ff9800' : 'red' }">{{cell.item.status}}
                            </span>
                        </ng-template>
                    </wj-flex-grid-column>

                    <!-- Action -->
                    <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="126"
                        [isReadOnly]="true" [format]="'d'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                            <button class="edit-delete-btn" (click)="deleteRow(grid, row)">
                                <em id="deleteButton" [title]="'Delete'" class="p-0 fa fa-trash"></em>
                            </button>
                        </ng-template>
                    </wj-flex-grid-column>
                    <div *ngFor="let columnname of sheetHeader">
                        <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                            [allowResizing]="true" [width]="'*'" [allowDragging]="false" [allowSorting]="false"
                            [format]="'d'">
                        </wj-flex-grid-column>
                    </div>
                    <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                </wj-flex-grid>

                <div class="row pl-3">
                    <div class="col-sm-2 p-0 mt-4">
                        <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridwidth > 0" (click)="gridRemove()">Back</button>
                        <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridwidth == 0" (click)="backSelect()">Back</button>
                    </div>
                    <div class="col-sm-10 button-wrap p-0 mt-4">
                        <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4" (click)="backSelect()">Cancel</button>
                        <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4" [disabled]="disableApprovebtn()"
                            (click)="approveTabIndex()">Approve</button>
                        <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4" (click)="backSelect()">Finish</button>
                        <button mat-raised-button *ngIf="postUpload" class="admin-btn-success" (click)="ExportExcel(grid)">Download
                            Result</button>
                    </div>
                </div>
            </div>
            <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
                <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
            </div>
        </div>
    </div>
</div>
<app-manage-tab-sequence2 *ngIf="getTabCondition()" [templateData]="templateData" (uploadFlags)='uploadFlags($event)' [tempTabFlagObject]="tempTabFlagObj"
    [tabindexFlag]="enableTabFocus" [rbacObject]="rbacObj" [page]="page"></app-manage-tab-sequence2>
