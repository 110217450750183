<!-- <div *ngIf="UserType; then UsersAction else otherAction"> </div>
<ng-template #UsersAction> -->
  <span class="action-btn-wrap">
      <div class="row" *ngIf="cardtype?.toString().toLowerCase() == 'users' && userStatus?.toString().toLowerCase() != 'deleted'">
          <div class="row p-2 action-btn-wrp">
            <div *ngIf="mainCard?.Locked?.toString().toLowerCase() != undefined"  class="col-3 mr-2 mb-2">
              <span class="spa" [title]="mainCard?.Locked?.toString().toLowerCase() === 'false' ? 'Unlock User' : 'Lock User'">
              <button mat-mini-fab class="m-2" (click)="ifUnlockClicked= true"
               [style.background-color]="btninactiveuser || mainCard?.Locked?.toString().toLowerCase()  == 'false'? 'lightgray !important' :'' " [disabled]="mainCard.Locked.toString().toLowerCase()  == 'false'">
                <em class="fa fa-unlock"  *ngIf="mainCard?.Locked?.toLowerCase() == 'false'" title="Unlock User" aria-hidden="true"></em>
                <em class="fa fa-lock"  *ngIf="mainCard?.Locked?.toLowerCase() == 'true'" title="Lock User" aria-hidden="true"></em>
              </button>
            </span>
            </div>
            <div *ngIf="userStatus?.toString().toLowerCase() != undefined" class="col-3 mr-2">
              <span class="spa" [title]="userStatus?.toString().toLowerCase() !== 'active' ? 'Activate User' : 'Deactivate User'">
              <button mat-mini-fab class="m-2" (click)="ifUserStatus=true" [disabled]="inactiveuser ">
                <em class="fa fa-check" aria-hidden="true" title="Activate user" *ngIf="userStatus?.toString().toLowerCase() != 'active'"></em>
                <em class="fa fa-times" aria-hidden="true" title="Deactivate user" *ngIf="userStatus?.toString().toLowerCase() == 'active'"></em>
                <!-- <img src="../../../../assets/icons/user_icons/active_user.svg" alt="active-user" title="Active user"
                  class="material-icons md-24" *ngIf="userStatus.toLowerCase() !== 'active'">
                <img src="../../../../assets/icons/user_icons/inactive_user.svg" alt="inactive-user" title="InActive user"
                  class="material-icons md-24" *ngIf="userStatus.toLowerCase() === 'active'"> -->
              </button>
            </span>
            </div>
            <div *ngIf="populateCaseList !== undefined" class="col-3 mr-2">
              <button mat-mini-fab class="m-2" (click)="PopulateCaseList()" title="Populate CaseList"
              [disabled]="populateCaseList ">
              <em class="fa fa-user-plus" title="Populate CaseList" aria-hidden="true"></em>
            </button>
            </div>
            <div *ngIf="populateASCCases !== undefined" class="col-3 mr-2">
              <button mat-mini-fab class="m-2" (click)="PopulateASCCases()" title="Populate ASC Cases"
                [disabled]="populateASCCases ">
                <em class="fa fa-edit" title="Populate ASC Cases" aria-hidden="true"></em>
              </button>
            </div>
            <div *ngIf="!['verified','autoinitiated', 'autoreinitiated'].includes(userAuthStatus?.toString().toLowerCase())" class="col-3 mr-2">
              <button mat-mini-fab class="m-2" title="Regenerate Password" (click)="ifPasswordClicked=true" [disabled]="regeneratepassword">
              <em class="fa fa-undo" title="Regenerate Password" id="displaynone"></em>
            </button>
            </div>
            <div  *ngIf="organizationLoginType?.toString().toLowerCase() == 'email' && userAuthStatus?.toString().toLowerCase() == 'verified'" class="col-3 mr-2">
              <button mat-mini-fab class="m-2" title="Reset Password" (click)="resetPasswordPopup()" [disabled]="regeneratepassword">
              <img src="../../../../assets/icons/Email Invitation.svg" alt="email invitation" title="Reset Password" id="displaynone"  class="icon-size">
            </button>
            </div>
            <div *ngIf="(userAuthStatus?.toString().toLowerCase() != 'verified' && userAuthStatus?.toString().toLowerCase() != 'autoinitiated' && userAuthStatus?.toString().toLowerCase() != 'autoreinitiated')" class="col-3 mr-2">
              <button mat-mini-fab class="m-2"  (click)="approveRequest()" title="Approve Password Request" [disabled]="approvepasswordrequest ">
                <em class="fa fa-thumbs-up" title="Approve Password Request" aria-hidden="true"></em>
              </button>
            </div>
            <div *ngIf="organizationLoginType?.toString().toLowerCase() =='email' && userAuthStatus?.toString().toLowerCase() != 'verified'" class="col-3 mr-2">
              <button mat-mini-fab class="m-2" [disabled]="migratuser" (click)="migrateUser()" title="Migrate User">
              <em class="fa fa-solid fa-reply fa-flip-horizontal" title="Migrate User" aria-hidden="true"></em>
            </button>
            </div>
      </div>
          <div *ngIf="ifResetPasswordClicked" class="col-sm-12">
            <div class="col-sm-8">
              <div class="modal overlay" id="myModal" role="dialog">
                <div class="modal-dialog" id="modaldialog">
                  <!-- Modal content-->
                  <div class="modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header admin-model-header">
                      <h3 class="modal-title w-100 admin-model-header-txt">Reset Password</h3>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <p id="statuschanges">A mail will be sent to this user to their given email address.</p>
                      </div>
                    </div>
                    <div class="modal-footer mt-4">
                      <button mat-raised-button class="admin-btn-success mr-4" (click)="toSendEmail(email)"
                        type="button">Yes</button>
                      <button mat-raised-button class="admin-btn-success" (click)="ifResetPasswordClicked = false"
                        type="button">No</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row p-0 m-0" *ngIf="cardtype?.toString().toLowerCase() == 'physician' && userStatus?.toString().toLowerCase() != 'deleted'">
          <div class="row p-2 action-btn-wrp">
            <div *ngIf="mainCard?.Locked?.toString().toLowerCase() != undefined" class="col-3 mr-2 mb-2">
            <span class="spa" [title]="mainCard?.Locked?.toString().toLowerCase() === 'false' ? 'Unlock User' : 'Lock User'">
              <button mat-mini-fab class="m-2" (click)="ifUnlockClicked=true" [style.background-color]="btninactiveuser || mainCard?.Locked?.toString().toLowerCase()  == 'false'? 'lightgray !important' :'' " [disabled]="mainCard?.Locked?.toString().toLowerCase()  == 'false'">
                <em class="fa fa-unlock"  *ngIf="mainCard?.Locked?.toString().toLowerCase() == 'false'" title="Unlock User" aria-hidden="true"></em>
                <em class="fa fa-lock"  *ngIf="mainCard?.Locked?.toString().toLowerCase() == 'true'"  title="Lock User" aria-hidden="true"></em>
              </button>
            </span>
            </div>
            <div *ngIf="userStatus?.toString().toLowerCase() != undefined" class="col-3 mr-2 mb-2">
              <span class="spa" [title]="userStatus?.toString().toLowerCase() != 'active' ? 'Activate User' : 'Deactivate User'">
                <button mat-mini-fab class="m-2" (click)="ifUserStatus=true" [disabled]=" inactiveuser">
                  <em class="fa fa-check" aria-hidden="true" title="Activate User" *ngIf="userStatus?.toString().toLowerCase() != 'active'"></em>
                  <em class="fa fa-times" aria-hidden="true" title="Deactivate User" *ngIf="userStatus?.toString().toLowerCase() == 'active'"></em>
                </button>
              </span>
            </div>
            <div *ngIf="!['verified','autoinitiated', 'autoreinitiated'].includes(userAuthStatus?.toString().toLowerCase())" class="col-3 mr-2 mb-2">
              <button mat-mini-fab class="m-2" title="Regenerate Password" (click)="ifPasswordClicked=true" [disabled]="regeneratepassword ">
              <em class="fa fa-undo" title="Regenerate Password" id="displaynone"></em>
            </button>
          </div>
          <div  *ngIf="(userAuthStatus?.toString().toLowerCase() != 'verified' && userAuthStatus?.toString().toLowerCase() != 'autoinitiated' && userAuthStatus?.toString().toLowerCase() != 'autoreinitiated')" class="col-3 mr-2 mb-2">
              <button mat-mini-fab class="m-2" (click)="approveRequest()" title="Approve Password Request" [disabled]="approvepasswordrequest">
                <em class="fa fa-thumbs-up" title="Approve Password Request" aria-hidden="true"></em>
              </button>
          </div>
          <div *ngIf="organizationLoginType?.toString().toLowerCase() =='email' && userAuthStatus?.toString().toLowerCase() != 'verified'" class="col-3 mr-2 mb-2">
            <button mat-mini-fab class="m-2" [disabled]="migratuser" (click)="migrateUser()" title="Migrate User">
              <em class="fa fa-solid fa-reply fa-flip-horizontal" title="Migrate User" aria-hidden="true"></em>
            </button>
          </div>
          <div  *ngIf="organizationLoginType?.toString().toLowerCase() == 'email' && userAuthStatus?.toString().toLowerCase() == 'verified'" class="col-3 mr-2 mb-2">
            <button mat-mini-fab class="m-2" title="Reset Password" (click)="resetPasswordPopup()" [disabled]="regeneratepassword ">
              <img src="../../../../assets/icons/Email Invitation.svg" alt="email invitation" title="Reset Password" id="displaynone"  class="icon-size">
            </button>
          </div>
          </div>
        </div>

        <div class="row p-0 m-0" *ngIf="cardtype?.toString().toLowerCase() == 'pathologist' && userStatus?.toString().toLowerCase() != 'deleted'">
          <div class="row p-2 action-btn-wrp">
            <div *ngIf="mainCard?.Locked?.toString().toLowerCase() != undefined" class="col-3 mr-2 mb-2">
            <span class="spa" [title]="mainCard?.Locked?.toString().toLowerCase() === 'false' ? 'Unlock User' : 'Lock User'">
              <button mat-mini-fab class="m-2" (click)="ifUnlockClicked=true" [style.background-color]="btninactiveuser || mainCard?.Locked?.toString().toLowerCase()  == 'false'? 'lightgray !important' :'' " [disabled]="mainCard?.Locked?.toString().toLowerCase()  == 'false'">
                <em class="fa fa-unlock"  *ngIf="mainCard?.Locked?.toString().toLowerCase() == 'false'" title="Unlock User" aria-hidden="true"></em>
                <em class="fa fa-lock"  *ngIf="mainCard?.Locked?.toString().toLowerCase() == 'true'"  title="Lock User" aria-hidden="true"></em>
              </button>
            </span>
          </div>
          <div *ngIf="userStatus?.toString().toLowerCase() !== undefined" class="col-3 mr-2 mb-2">
          <span class="spa" [title]="userStatus?.toString().toLowerCase() !== 'active' ? 'Activate User' : 'Deactivate User'">
            <button mat-mini-fab class="m-2" (click)="ifUserStatus=true" [disabled]=" inactiveuser">
              <em class="fa fa-check" aria-hidden="true" title="Activate User" *ngIf="userStatus?.toString().toLowerCase() != 'active'"></em>
              <em class="fa fa-times" aria-hidden="true" title="Deactivate User" *ngIf="userStatus?.toString().toLowerCase() == 'active'"></em>
            </button>
          </span>
        </div>
        <div *ngIf="!['verified','autoinitiated', 'autoreinitiated'].includes(userAuthStatus?.toString().toLowerCase())" class="col-3 mr-2 mb-2">
          <button mat-mini-fab class="m-2"  title="Regenerate Password" (click)="ifPasswordClicked=true" [disabled]="regeneratepassword ">
          <em class="fa fa-undo" title="Regenerate Password" id="displaynone"></em>
        </button>
      </div>
      <div  *ngIf="organizationLoginType?.toString().toLowerCase() == 'email' && userAuthStatus?.toString().toLowerCase() == 'verified'" class="col-3 mr-2 mb-2">
        <button mat-mini-fab class="m-2" title="Reset Password" (click)="resetPasswordPopup()" [disabled]="regeneratepassword">
          <img src="../../../../assets/icons/Email Invitation.svg" alt="email invitation" title="Reset Password" id="displaynone"  class="icon-size">
        </button>
      </div>
      <div  *ngIf="(userAuthStatus?.toString().toLowerCase() != 'verified' && userAuthStatus?.toString().toLowerCase() != 'autoinitiated' && userAuthStatus?.toString().toLowerCase() != 'autoreinitiated')" class="col-3 mr-2 mb-2">
        <button mat-mini-fab class="m-2" (click)="approveRequest()" title="Approve Password Request" [disabled]="approvepasswordrequest">
          <em class="fa fa-thumbs-up" title="Approve Password Request" aria-hidden="true"></em>
        </button>
      </div>
      <div  *ngIf="organizationLoginType?.toString().toLowerCase() =='email' && userAuthStatus?.toString().toLowerCase() != 'verified'" class="col-3 mr-2 mb-2">
        <button mat-mini-fab class="m-2" [disabled]="migratuser" (click)="migrateUser()" title="Migrate User">
          <em class="fa fa-solid fa-reply fa-flip-horizontal" title="Migrate User" aria-hidden="true"></em>
        </button>
      </div>
    </div>
  </div>
  <div class="row p-0 m-0" *ngIf="cardtype?.toString().toLowerCase() == 'location' ">
    <div class="col-12 p-0 m-0">
      <!-- <ng-container *ngFor="let action of actions;let ai=index">
                <button *ngIf="action.actiontype === 'Case Type'" style="margin-inline: 3% ;margin-block:2%;"
                  class=" md-fab md-btn success " title="{{action.buttontitle}}" (click)="openModal(action.actiontype)">
                  <em class="fa  fa-download" aria-hidden="true"></em>
                </button>
              </ng-container>
              <div  *ngIf="cardtype ==  mainCard.Name">
                <button style="margin-inline: 3% ;margin-block:2%;" class=" md-fab md-btn  blue  m-a" title="Export file"
                  (click)="exportLabData(secondarykeys)">
                  <span class="material-icons"> file_upload </span>
                </button>
              </div> -->
    </div>
  </div>

  <div class="row p-0 m-0" *ngIf="cardtype?.toString().toLowerCase() == 'facility' ">
    <div class="col-12 p-0 m-0">
      <!-- <ng-container *ngFor="let action of actions;let ai=index">
                <button *ngIf="action.actiontype === 'Case Type'" style="margin-inline: 3% ;margin-block:2%;"
                  class=" md-fab md-btn success " title="{{action.buttontitle}}" (click)="openModal(action.actiontype)">
                  <em class="fa  fa-download" aria-hidden="true"></em>
                </button>
              </ng-container>
              <div  *ngIf="cardtype ==  mainCard.Name">
                <button style="margin-inline: 3% ;margin-block:2%;" class=" md-fab md-btn  blue  m-a" title="Export file"
                  (click)="exportLabData(secondarykeys)">
                  <span class="material-icons"> file_upload </span>
                </button>
              </div> -->
    </div>
  </div>

  <div class="row p-0 m-0" *ngIf="cardtype?.toString().toLowerCase() == 'lab' && importbutton === 1">
    <div class="col-12 p-0 m-0">
      <div class="row p-0 m-0">
        <div class="col-3 mr-2 action-btn-wrp">
          <ng-container *ngFor="let action of actions;let ai=index">
            <button mat-mini-fab class="m-2" *ngIf="action.actiontype === 'Case Type'" [disabled]="importFileButton"
              title="{{action.buttontitle}}" (click)="openModal(action.actiontype)">
              <em class="fa fa-download" aria-hidden="true"></em>
            </button>
          </ng-container>
        </div>
        <div class="col-3" *ngIf="!editGroupButton">
          <button mat-mini-fab class="m-2" title="Edit Group details" (click)="SelectedModal('EditGroup')">
            <em class="fa fa-pencil"></em>
          </button>
        </div>
        <!-- // vitalaxis representation -->
        <div class="col-3 dropdown ml-3 mt-2">
          <button mat-mini-fab type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false" (click)="isFlag = !isFlag" *ngIf="userRepresentatives?.length" title="Active Representatives">
            <img src="/assets/icons/VitalAxis/badge_vitalaxis.svg" alt="Close" class="material-icons md-24 cursor"
              title="Close">
          </button>

          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" (click)="$event.stopPropagation()">
            <ng-container *ngFor="let contactInfo of userRepresentatives">
              <app-representative-card
                  [cardInfo]="contactInfo"
                  [openedLocation]="'view'"></app-representative-card>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row p-0 m-0" *ngIf="cardtype==mainCard.Name">
    <div class="col-12 p-0 m-0 action-btn-wrp">
      <div class="row p-0 m-0">
        <div class="col-3">
          <button mat-mini-fab class="m-2" title="Export file" (click)="exportLabData(secondarykeys)"
            [disabled]="exportfile ">
            <em class="fa fa-upload"></em>
          </button>
        </div>
        <div class="col-3">
          <button mat-mini-fab class="m-2" title="Group Compare" (click)="SelectedModal('OrgCompare')"
            [disabled]="orgcompare">
            <mat-icon aria-hidden="false">compare
            </mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row p-0 m-0" *ngIf="isGroupCard">
    <div class="col-12 p-0 m-0 action-btn-wrp">
      <div class="row p-0 m-0">
        <div class="col-3" *ngIf="!editGroupButton">
          <button mat-mini-fab class="m-2" title="Edit Group details" (click)="SelectedModal('EditGroup')">
            <em class="fa fa-pencil"></em>
          </button>
        </div>

        <!-- // vitalaxis representation -->
        <div class="col-3 dropdown ml-3 mt-2">
          <button mat-mini-fab type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false" (click)="isFlag = !isFlag" *ngIf="userRepresentatives?.length" title="Active Representatives">
            <img src="/assets/icons/VitalAxis/badge_vitalaxis.svg" alt="Close" class="material-icons md-24 cursor"
              title="Close">
          </button>

          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" (click)="$event.stopPropagation()">
            <ng-container *ngFor="let contactInfo of userRepresentatives">
              <app-representative-card
                  [cardInfo]="contactInfo"
                  [openedLocation]="'view'"></app-representative-card>
            </ng-container>
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- </ng-template> -->
  <!-- <div class="my-modal-wrapper">
          <div class="OrgCompareinLabModal" id="{{'OrgCompareinLabModal' + modalID}}" ngClass={{GenericModalClass}}
            data-backdrop="static" data-keyboard="false"> -->
  <!-- id="OrgCompareinLabModal" -->
  <!---Org Compare Modal-->
  <!-- <span *ngIf="SelectedMenuModel === 'OrgCompareinLab'">
              <template-OrgCompare [fromButton]="fromData" [CaseType]="mainCard.Name" [OrgID]="mainCard.OrganizationID"
                [AccID]="mainCard.id" [ModalID]="modalID" (ModalIDNew)="receiveMessage($event)">
              </template-OrgCompare>
            </span>
          </div>
        </div> -->
  <!-- <span *ngIf="SelectedMenuModel === 'OrgCompareinLab'">
          <template-OrgCompareActionBtn [fromButton]="fromData" [CaseType]="mainCard.Name" [OrgID]="mainCard.OrganizationID"
            [AccID]="mainCard.id">
          </template-OrgCompareActionBtn>
        </span> -->

  <div *ngIf="ifUnlockClicked">
    <div class="modal overlay" id="myModal" role="dialog"
      style="display: flex;background-color: #0c0c0c75;z-index: 11111;">
      <div class="modal-dialog" style="margin: auto;">

        <!-- Modal content-->
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header admin-model-header">
            <h3 class="modal-title w-100 admin-model-header-txt">Unlock User</h3>
          </div>
          <div class="modal-body">
            <div class="row">
              <p style="padding-inline: 47px;">Are you sure you want to unlock {{LogName}}?</p>
            </div>
          </div>
          <div class="modal-footer">
            <button mat-raised-button class="admin-btn-success mr-4" (click)="UnlockUserPopUp()"
              type="button">Yes</button>
            <button mat-raised-button class="admin-btn-success" (click)="ifUnlockClicked = false"
              type="button">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="ifPasswordClicked">
    <div class="modal overlay" id="myModal" role="dialog"
      style="display: flex;background-color: #0c0c0c75;z-index: 11111;">
      <div class="modal-dialog" style="margin: auto;">
        <!-- Modal content-->
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header admin-model-header">
            <h3 class="modal-title w-100 admin-model-header-txt">Regenerate Password</h3>
          </div>
          <div class="modal-body">
            <div class="row">
              <p style="padding-inline: 47px;">Are you sure you want to Regenerate password for {{LogName}}?
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <button mat-raised-button class="admin-btn-success mr-4" (click)="RegeneratePasswordPopUp()"
              type="button">Yes</button>
            <button mat-raised-button class="admin-btn-success" (click)="ifPasswordClicked = false"
              type="button">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="ifPasswordGenerated">
    <div class="modal overlay" id="myModal" role="dialog"
      style="display: flex;background-color: #0c0c0c75;z-index: 11111;">
      <div class="modal-dialog pop-up-size">
        <!-- Modal content-->
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header admin-model-header">
            <h3 class="modal-title w-100 admin-model-header-txt">Regenerate Password</h3>
          </div>
          <div class="modal-body">
            <div class="row" style="text-align: center;justify-content: center;">
              <p>Loginname:</p>
              <img class="mb-2 copyIcon cursor" (click)="copy(LogName)" alt="copy"
                src="/assets/images/copy.svg" />&nbsp;
              <p style="font-weight: 500;">{{LogName}} </p>
            </div>
            <div class="row" style="text-align: center;justify-content: center;">
              <p>Password:</p>
              <img class="mb-2 copyIcon cursor" (click)="copy(passwordGen)" alt="copy"
                src="/assets/images/copy.svg" />&nbsp;
              <p style="font-weight: 500;">{{passwordGen}} </p>
            </div>
          </div>
          <div class="modal-footer align-center">
            <div class="align-center">
              <button mat-raised-button class="admin-btn-success align-center" (click)="ifPasswordGenerated = false"
                type="button">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="ifUserUnlockedSuccess">
    <div class="modal overlay" id="myModal" role="dialog"
      style="display: flex;background-color: #0c0c0c75;z-index: 11111;">
      <div class="modal-dialog" style="margin: auto;">
        <!-- Modal content-->
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header admin-model-header">
            <h3 class="modal-title w-100 admin-model-header-txt">Unlock/Lock User</h3>
          </div>
          <div class="modal-body">
            <div class="row">
              <p style="padding-inline: 47px;">{{UserUnlockMessage}}</p>
            </div>
          </div>
          <div class="modal-footer align-center">
            <div class="align-center">
              <button mat-raised-button class="admin-btn-success align-center" (click)="ifPasswordGenerated = false"
                type="button">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="ifUserStatus">
    <div class="modal overlay" id="myModal" role="dialog"
      style="display: flex;background-color: #0c0c0c75;z-index: 11111;">
      <div class="modal-dialog" style="margin: auto;">
        <!-- Modal content-->
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header admin-model-header">
            <h3 class="modal-title w-100 admin-model-header-txt">User Status</h3>
          </div>
          <div class="modal-body">
            <div class="row">
              <p style="padding-inline: 43px;"> Are you sure you want to {{msg}} <b>{{UserName}}</b>?
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <button mat-raised-button class="admin-btn-success mr-4" (click)="statusChange()" type="button">
              <span>{{msg}}</span>
            </button>
            <button mat-raised-button class="admin-btn-success" (click)="close()" type="button">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>

    <div *ngIf="ifApproveRequest">
      <div class="modal overlay" id="myModal" role="dialog"
        style="display: flex;align-items: center;background-color: #0c0c0c75;z-index: 11111;">
        <div class="modal-dialog" cdkDragHandle  cdkDragRootElement=".cdk-overlay-pane" style="margin: auto;">
          <!-- Modal content-->
          <div class="modal-content" style="height: 288;width: 1216px;left: -360px;">
            <div class="modal-header"
              style="padding: 0px !important;background-color: #00bcd4;color: white; justify-content: center;    height: 31px;">
              <h3 class="modal-title admin-model-header-txt" id="myModalLabel">Approve Reset Password</h3>
            </div>

            <div class="modal-body">
              <div class="row">

              </div>
              <div class="row">

                <div class="col-3">
                  <strong>Request ID : </strong>
                  <span *ngIf="!showSearch && requestID!=undefined">{{requestID}}</span>
                  <div *ngIf="showSearch && requestID==undefined">
                    <input style="    height: 25px;
                          position: absolute;
                          top: 0px;
                          width: 197px;
                          left: 93px;cursor: auto;" type="text" class="form-control" autofocus name="searchValue"
                      [(ngModel)]="searchValue" placeholder="Search ...." />
                    <em class="fa fa-search" style="position: absolute;
                        top: 6px;
                        left: 263px;cursor:pointer
                        " (click)="approveRequest()"></em>
                  </div>

                </div>
                <div class="col-3">
                  <strong>Request Date : </strong>
                  <span *ngIf="requestedDate!=undefined">{{requestedDate | date:'dd-MMM-yy'}}</span>
                </div>
                <div class="col-3">
                  <strong>Requested from IP : </strong>
                  <span *ngIf="requestedIP!=undefined">{{requestedIP}} </span>
                </div>
                <div class="col-3">
                  <strong>Last Successfull Login IP : </strong>
                  <span *ngIf="successIP!=undefined">{{successIP}}</span>
                </div>

              </div>
              <div class="row" style="margin-top: 40px;">

                <div class="col-3">
                  <strong>Login ID : </strong>
                  <span *ngIf="loginname!=undefined">{{loginname}} </span>
                </div>
                <div class="col-3">
                  <strong>First Name : </strong>
                  <span *ngIf="firstName!=undefined"> {{firstName}}</span>
                </div>
                <div class="col-3">
                  <strong>Last Name : </strong>
                  <span *ngIf="lastname!=undefined">{{lastname}}</span>
                </div>
                <div class="col-3">
                  <strong>Facility Address:</strong>
                  <span *ngIf="facilityaddress!=undefined">{{facilityaddress}}</span>
                </div>

              </div>

              <div class="row" *ngIf="showMsg" class="nodata-wrap mt-4">
                <div class="nodata-design"> No Data Found
                </div>
              </div>
              <!-- <div class="row" style="text-align: center;justify-content: center;">
                      <p style="
                  font-weight: 500;">cdxf </p>
                    </div> -->
            </div>
            <div class="modal-footer">
              <button mat-raised-button class="admin-btn-success mr-4" (click)="requestApproval('Approved')" type="button"
                [disabled]="htnapproved">
                <span>Approve</span>
                <!-- <span  *ngIf="userStatus !== 'active'">Active</span>
                      <span  *ngIf="userStatus === 'active'">Inactive</span> -->
              </button>
              <button mat-raised-button class="admin-btn-success mr-4" (click)="requestApproval('Denied')" type="button" [disabled]="htnapproved">
                <span>Deny</span>
                <!-- <span  *ngIf="userStatus !== 'active'">Active</span>
                      <span  *ngIf="userStatus === 'active'">Inactive</span> -->
              </button>
              <button mat-raised-button class="admin-btn-success" (click)="closeApprovalPopup()"
                type="button">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" id="m-sm">
      <div class="row-col h-v">
        <div class="row-cell v-m">
          <div class="modal-dialog modal-md">
            <div class="modal-content">
              <div class="modal-header admin-model-header">
                <h3 class="modal-title w-100 admin-model-header-txt">Import CaseType</h3>
              </div>
              <div class="modal-body p-lg" style="max-height: 500px;overflow: auto;">

                <div style="text-align: center;">
                  <div class="container">
                    <input #fileUpload type="file" (click)="fileUpload.value = null" (change)="importFile($event)" hidden
                      accept=".json" [multiple]="false">
                    <label class="btn-sm btn rounded browse" (click)="fileUpload.click()">
                      <em class="fa fa-upload mr-1" aria-hidden="true"></em> Browse File </label>
                  </div>

                  <div class="card vt-card mt-3" *ngIf="fileName !== ''">
                    <div class="row py-2">
                      <div class="col-sm-2">
                        <div
                          style="position: absolute;height: 38px;width: 1px;background-color: #dac6c6;top: -36%;left: 85%;">
                        </div>
                        <em class="fa fa-file text-primary" aria-hidden="true"
                          style="position: absolute;left: 34%;font-size: 32px;top: -28%;"></em>
                        <em class="material-icons md-12 text-white" style="position: relative;top: 2%;">file_upload
                        </em>
                      </div>
                      <div class="col-sm-10"> <strong>{{fileName}}</strong></div>
                    </div>

                  </div>
                </div>
                <!-- <div class="row">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text">Upload</span>
                          </div>
                          <div class="custom-file">
                            <input type="file" class="custom-file-input" id="inputGroupFile01">
                            <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <label>
                          Upload File
                          <input type="file"  id="myInput"
                          (change)="handleFileInput($event)" aria-describedby="myInput">

                        </label>
                      </div> -->
              </div>
              <div class="modal-footer">
                <button mat-raised-button class="admin-btn-success mr-4" id="closeModal" data-dismiss="modal" #closeModal
                  type="button">Close</button>
                <button mat-raised-button class="admin-btn-success" [disabled]="isImpbtnDisable"
                  (click)='fnImportCasetype(secondarykeys)'>
                  Import
                </button>
              </div>
              <!-- <div id="snackbarVal">{{SnackBarMessage}}</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </span>
