import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { StaticMessages } from '../../utility/MessageConstants';
import { convertLocalDateTime, menuUrl } from '../../../Utility Functions';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { CommonService } from 'src/app/core/services/commonservices';

@Component({
  selector: 'app-approve-password',
  templateUrl: './approve-password.component.html',
  styleUrls: ['./approve-password.component.scss']
})
export class ApprovePasswordComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public config: {user : { userId: string, loginId: string, facilityAddress: string, username: string, firstName: string, lastName: string, type: string, loggedInUserName: string }, loggedInUserId :  string },
    private _dialogRef: MatDialogRef<ApprovePasswordComponent>,
    private _labadminservice: LabadminService,
    private _snackbar: MatSnackBar,
    private activityService: ActivityTrackerService,
    private commonService: CommonService,
  ){}
  
  status : 'approved' | 'denied' | string = ''
  requestId = new FormControl('');
  approvePasswordData : approveRequest
  isNoDataFound : boolean = false
  requistFromIpImg : string
  loading: boolean = false
  invalidInput: boolean = false
  requestCount: number = 0
  activityEntity: any
  
  ngOnInit(): void {
    this.GetApprovePasswordRequests(false)
    this.setActivitySession()
    this.requestId.valueChanges.subscribe((res) => {
      if(!res) {
        this.status = ''
        this.approvePasswordData = null
        this.requistFromIpImg = ''
        this.isNoDataFound = false
      }      
      this.invalidInput = false
    })
  }

  GetApprovePasswordRequests(search: boolean){
    this.invalidInput = search && !this.requestId.value
    if(this.invalidInput) return

    this.isNoDataFound = false
    this.loading = true
    ++this.requestCount
    this._labadminservice.GetUserDetailsForApproveResetPassword(this.config.user.userId, this.requestId.value, search, this._labadminservice.deploymentKey).subscribe({
      next: ((res : approveRequest) =>{
        this.approvePasswordData = res
        this.requistFromIpImg = this.approvePasswordData.RequestedIP == this.approvePasswordData.SuccessIP ? '/assets/images_vla/Icon_Check.svg' : '/assets/images_vla/Icon_Cancel.svg' 
        this.status = res.Status.toLowerCase()
        this.turnOffLoader()
      }),
      error: ((err) => {
        console.log(err)
        this.onNoDataFound()
        if(this.requestId.value) this.isNoDataFound = true
        this.turnOffLoader()
    })
    })
  }

  approveOrDenyPassword(action : 'Approved' | 'Denied'){
    try {
      this.loading = true
      let obj = {
        "LoginID" : this.config.user.loginId,
        "UserID": this.config.user.userId,
        "RequestID": this.approvePasswordData.RequestID,
        "ActionBy": this.config.loggedInUserId,
        "Status": action
    }
      this._labadminservice.approveOrDenyPassword(obj, this._labadminservice.deploymentKey).subscribe({
        next: ((res) => {
          this._dialogRef.close()
          this._snackbar.open(`Password ${action} Successfully`, 'Close', {
            horizontalPosition: 'end'
          });
          let transactionid=this.commonService.generateGuid();
          let info = `"${this.config.user.username}" Reset Password Request.`
          let value = {'Status':{"newValue": `Reset Password Request was ${action}.`}, 'Action By': {"newValue": this.config.user.loggedInUserName}, 'Action Date': {"newValue": convertLocalDateTime(new Date().toString())}}
          this.commonService.createActivityObject(this.config.user.userId, this.config.user.username, menuUrl(this.config.user.type), 'Create', {}, {}, transactionid, '', {}, value, info)
        }),
        error: ((err) => this.onError())
      })
    } catch (error) {
      this.onError()
    }
    
  }

  turnOffLoader(){
    this.loading = false
    if(this.requestCount === 1){
      ++this.requestCount
    }
  }

  onError(){
    this.loading = false
    this._snackbar.open(StaticMessages.ON_ERROR, 'Close', {
      horizontalPosition: 'end'
    });
  }

  clearSearch(){
    this.requestId.setValue('')
  }

  onNoDataFound(){
      this.status = ''
      this.approvePasswordData = null
      this.requistFromIpImg = ''
      this.isNoDataFound = false
  }

  setActivitySession(){
    const menuurl = menuUrl(this.config.user.type)
    this.activityService.setActivitySession({ 'entityId': '', 'entityType': menuurl, 'context': [{ 'key': 'parentMenu', 'value': menuurl }] })
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res)
  }
}

interface approveRequest{
  RequestID: string
  RequestedDate: string
  LoginID: string
  RequestedIP: string
  SuccessIP: string
  FirstName: string
  LastName: string
  FacilityAddress: string
  Status: string
}