import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LabadminService } from '../../labadmin.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { CommonService } from 'src/app/core/services/commonservices';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { addNabvTab, disableListView, setReloadingPage } from '../../store/actions/navbar.actions';
import {editUrlObj} from '../share/utility/routingConstans'
import { LabadminSessionService } from '../../services/labadmin-session.service';
import { forkJoin, map } from 'rxjs';
@Component({
  selector: 'app-commondetails',
  templateUrl: './commondetails.component.html',
  styleUrls: ['./commondetails.component.scss']
})
export class CommondetailsComponent {

  userDetails: any = {};
  npiDetails: any = {};
  savedRoles: any = [];
  tabList = []

  @Output() CloseDetials = new EventEmitter<any>();
  @Output() EditUser = new EventEmitter<string>();
  @Output() EditLocation = new EventEmitter<string>();
  @Input() viewType = "small"
  @Input() userInfo
  @Input() fromLocation='';
  @Input() contentOf:string|null=null;
  @Input() isHideLocationGrid:boolean = false;
  @Input() type: string



  selectedTab: string = '';
  userid = ''
  locationDetails:any;
  showSpinner: boolean=true;
  loggedInUserId:number = 0;
  pathUserId: number = 0
  URL:string|null=''
  statusChanged:boolean[]=[false]

  constructor
  (
    private store: Store<{ breadcrum: [] }>, 
    private commonService: CommonService, 
    private labAdminservice: LabadminService, 
    private vitalHttpServices: VitalHttpServices,
    private _router:Router, 
    private labAdminSessionService: LabadminSessionService
  ) 
  {
    if(this.labAdminservice.fromGlobalSearch){
      let getTabsEntities = {
        "Providers": "LabAdminClientsProviders",
        "Users": "LabAdminClientsUsers",
        "Clients": "LabAdminClientsLocations",
        "Referring Physicians": "LabAdminClientsReferringPhysicians",
        "Pathologist": "LabAdminUsers",
        "labusers": "LabAdminUsers"
      }
      this.getTabs(getTabsEntities[this.labAdminservice.selectedDropdown])
    }
    else{
      store.select('breadcrum').subscribe(data => {
        if (data.length > 0) {
          if (data.length > 1) {
            this.getTabs(data[data.length - 1]['DetailsMenu'])
          }
          else {
            this.getTabs(data[data.length - 1]['SubMenu'][0]['DetailsMenu'])
          }
        }
      })
     }
  }

CloseView(){
  this.CloseDetials.emit(false)
  this.statusChanged[0] && this.store.dispatch(setReloadingPage({URL:this.URL,reload:true}))
}

  async ngOnChanges() {
    let _this = this;
    this.URL=editUrlObj[this.contentOf]?.URL
    this.labAdminSessionService.getLabAdminSession.subscribe(
      async (session) => {
        this.loggedInUserId = session['userDetails'].userId;
      }
    );
    if(this.userInfo.associationType == 'Laboratory'){
      this.contentOf='labUser'
    }
    else if(this.userInfo.associationType == "Ordering Facility"){
      this.contentOf='clientUser'
    }
    _this.showSpinner=true;
    if ((this.userInfo !== '' || this.npiDetails !== '') &&  this.fromLocation !== 'location') {
      this.userid = this.userInfo['userid']
      this.getUserDetailsAndRoles(this.userid);
    }
    else if(this.fromLocation == 'location'){
      await this.getLocationDetails();

    }
    else
    _this.showSpinner=false;
  }

  async getLocationDetails(){
    if(this.userInfo){
     await this.labAdminservice.getLocationDetails(this.userInfo?.['ofAccountId'],Number(this.labAdminservice.organizationId), this.labAdminservice.deploymentKey).
      toPromise().then(async(data)=>{
        if(data){
          this.userDetails = data;
          this.locationDetails = {...data, viewNPIStatus : data.NPI.toString().toLowerCase() != 'unknown' && data.NPI.toString().length == 10 ? this.getProviderStatus(data.ProviderStatus) : ''};
          this.userDetails.OrganizationID = this.userInfo?.['organizationId'];
          this.userDetails.AccountID = this.userInfo?.['ofAccountId'];
          this.locationDetails.OrganizationID = this.userInfo?.['organizationId'];
          this.locationDetails.AccountID = this.userInfo?.['ofAccountId'];

          await this.GetServices();
          this.showSpinner=false
        }
      }).catch((err)=>
     this.showSpinner=false 
      )
    }
  }

  async GetServices(){
    await this.labAdminservice.GetServices(this.userInfo?.['ofAccountId'],2,this.loggedInUserId,this.labAdminservice.deploymentKey).
    toPromise().then((data) =>{
      if(data) {
        const filteredArray = data
          ?.filter((value: string | null | undefined) => value !== null && value !== undefined && value.trim() !== '') // Trim whitespace
          .map((value: string | null | undefined) => value?.toLowerCase()); // Convert to lowercase

        const uniqueArray = [...new Set(filteredArray)]; // Remove duplicates

        const titleCaseArray = uniqueArray.map((value: string | null | undefined) => {
          // Convert each value to title case
          return value!.replace(/\b\w/g, char => char.toUpperCase()); // Use ! to assert that value is not null or undefined
        });

        this.userDetails.serviceDetails = titleCaseArray.join(', ');
        this.locationDetails.serviceDetails = titleCaseArray.join(', ');
      }
    });
  }

  getTabs(menuName: string) {
    let newQuery = this.vitalHttpServices.mainQueryList.filter(
      (x) => x.Keyword == 'getVitalAdminMenusForDx'
    )[0].Query;
    let OrgId = this.labAdminservice.organizationId
    let queryString = "Organizationid in \"" + OrgId + "\":string[] OR Organizationid in \"-1\":string[]"
    let newQueryVariable = {
      CardName: menuName,
      filtername: queryString
    };
    let newQueryResult = this.commonService.GetCardRequest(
      newQueryVariable,
      newQuery
    );
    this.vitalHttpServices.GetData(newQueryResult, this.labAdminservice.deploymentKey).subscribe((newResData) => {
      let data = JSON.parse(newResData.data.submenuData[0].JSONMenu).MenuItems;
      this.labAdminservice.subMenuPermissions = JSON.parse(newResData.data.submenuData1[0].Permission).MenuItems
      this.tabList = data;
      if (data.length > 0) {
        this.selectedTab = data[0].Htext.toLowerCase();
      }
    });
  }

  getTabData(event) {
    this.selectedTab = event.toLowerCase();
  }

  getUserDetailsAndRoles(userid) {
  let _this=this
    forkJoin([this.labAdminservice.getUserProfile(userid, this.labAdminservice.deploymentKey) ,this.labAdminservice.getsaveRoles(this.userid, this.labAdminservice.deploymentKey)]).pipe(map((data:any)=>({userDetails:data[0],roles:data[1]})))
    .subscribe({
      next(value:any){
        _this.userDetails= {...value.userDetails, viewNPIStatus : value.userDetails.NPI.toString().toLowerCase() != 'unknown' && value.userDetails.NPI.toString().length == 10 ? _this.getProviderStatus(value.userDetails.ProviderStatus) : ''}
        //console.log(value.userDetails);
        _this.savedRoles=value.roles
        _this.pathUserId = value.roles.find((j) => j.Role.toLowerCase() == 'pathologist')?.UserID ?? 0
        if(_this.pathUserId === 0){
          _this.tabList = _this.tabList.filter((j) => j.Template.toLowerCase() != 'attachments')
        }
        _this.showSpinner=false
      },
      error(err) {
        console.log(err)
        _this.showSpinner=false
      }

    })
  }

  getuserRoles() {
    this.labAdminservice.getsaveRoles(this.userid, this.labAdminservice.deploymentKey).subscribe(data => {
      this.savedRoles = data;
    })
  }
  redirectoEdit(userId: any) {
    if (this.labAdminservice.fromGlobalSearch) {
      this.EditLocation.emit(userId)
    }
    else {
      if (this.contentOf) {
        let routerObj = { ...editUrlObj[this.contentOf] }
        routerObj.routerLink = routerObj.routerLink + userId
        let obj: any = { Htext: routerObj.Htext, Level: routerObj.Level, routerLink: routerObj.routerLink }
        this.store.dispatch(addNabvTab({ value: obj }));
        routerObj?.URL && this.store.dispatch(disableListView({URL:routerObj?.URL}))
        if (routerObj.queryParams) {
          let finalObj: any = { id: userId, link: routerObj.routerLink };
          this._router.navigate([routerObj.routerLink], { queryParams: routerObj.queryParams })
        }
        else {
          let finalObj: any = { id: userId, link: routerObj.routerLink };
          this._router.navigate([routerObj.routerLink]);
        }
      }
    }
  }
  editLocation(accountId) {
    if (this.labAdminservice.fromGlobalSearch) {
      this.EditLocation.emit(accountId)
    }
    else {
      let routerObj = { ...editUrlObj[this.contentOf] }
      routerObj.routerLink = routerObj.routerLink + accountId
      let obj: any = { Htext: routerObj.Htext, Level: routerObj.Level, routerLink: routerObj.routerLink }
      this.store.dispatch(addNabvTab({ value: obj }));
      routerObj?.URL && this.store.dispatch(disableListView({URL:routerObj?.URL}))
      if (routerObj.queryParams)
        this._router.navigate([routerObj.routerLink], { queryParams: routerObj.queryParams })
      else this._router.navigate([routerObj.routerLink])
    }
  }

  getProviderStatus = (status : string) =>  status ? status : 'Active'
}



