<ng-container *ngIf="loader$ |async as loader">
    <section class="default-loader" *ngIf="loader.entireLoader.loading">
        <mat-spinner class="labadmin-spinner" [diameter]="50"></mat-spinner>
    </section>

<mat-dialog-content>
    <div class="heading">
        <span>Contact Information</span>
    </div>
    <div class="d-flex flex-column mb-3">
        <div class="p-1 text-style">Printing is enabled, Please contact our support to install Vital Agent for printing
            reports</div>
        <div class="d-flex p-1">
            <div class="d-flex align-items-center">
                <img width="12px" src="/assets/images_vla/Call.svg" />
                <strong class="icon-value ml-2">+1 (234)525 4665</strong>
            </div>
            <div class="d-flex ml-4 align-items-center">
                <img width="14px" src="/assets/images_vla/Email.svg" />
                <strong class="icon-value ml-2">support@vitalaxis.com</strong>
            </div>
        </div>
    </div>
    <hr>
    <ng-container *ngIf="loader$|async as loader">
        <section [formGroup]="detailsForm">
            <app-shimmerui [line]="1" *ngIf="loader.formDetails.loading"></app-shimmerui>
            <div class="details-style" *ngIf="!loader.formDetails.loading">
                <app-custom-input labelValue="Contact Person" formcontrolname="Name" placeholder="Enter Contact Person"
                    required="true" maxLength="50"></app-custom-input>
                <app-custom-input mask="000.000.0000x0000000000" labelValue="Phone Number" formcontrolname="Phone"
                    placeholder="Enter Phone Number" required="true" maxLength="50">
                </app-custom-input>
                <app-custom-input labelValue="Email ID" formcontrolname="Email" placeholder="Enter Email ID"
                    required="true" maxLength="50">
                </app-custom-input>
            </div>
        </section>
        <div class="pl-4 mt-3 d-flex align-center justify-content-center ">
            <app-button buttonclass="primary"  [isDisabled]="loader.formDetails.loading" customClass="admin" (click)="sendEmail()">Save</app-button>
        </div>
    </ng-container>
</mat-dialog-content>
</ng-container>
