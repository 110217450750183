<div mat-dialog-title class="confirmHeader p-2 d-flex justify-content-center">
    <h1 class="header" *ngIf="data.header">{{data.header}}</h1>
</div>
<span class="migrate_wrap">
    <div mat-dialog-content>
        <div class="row col-12 p-0 m-0 box-wrap">
            <textarea class="text-area-c" style="resize: none; cursor: auto;" [ngStyle]="{'color': !isJsonValid(beautifiedJson) ? 'red' :  'green'}"
            [(ngModel)]="beautifiedJson"></textarea>
        </div>
    </div>
</span>

<div mat-dialog-actions class="confirm_dia d-flex justify-content-center"
    [ngStyle]="{'justify-content': data.cancel === 'dontshow' ? 'center':''}">

    <button *ngIf="data.cancel !== 'dontshow'" type="button" [mat-dialog-close]="data.animal" cdkFocusInitial
        class="md-btn md-flat mb-0 p-t-xs p-b-xs p-l-sm p-r-sm m-r-xs mr-4 cancelButton">
        {{data.cancel}}
    </button>

    <button type="submit" class="md-btn md-flat mb-0 p-t-xs p-b-xs p-l-sm p-r-sm m-r-xs ml-3 confirmButton"
        (click)="onContinue()">
        {{data.continue}}
    </button>
    <button class="md-btn md-flat mb-0 p-t-xs p-b-xs p-l-sm p-r-sm m-r-xs ml-3 confirmButton"
        (click)="getBeautifiedJson(beautifiedJson,true)"> Prettify
    </button>
</div>
