import { Component, Input } from '@angular/core';
import { LabadminService } from '../../labadmin.service';

@Component({
  selector: 'app-infonpi',
  templateUrl: './infonpi.component.html',
  styleUrls: ['./infonpi.component.scss']
})
export class InfonpiComponent {

  userid = ''

  @Input() userDetials: any
  @Input() fromLocation:string='';
  @Input() isHideLocationGrid:boolean = false;
  npiDetails = {}
  otherIdentifiers = []
  noDataFoundFlag: boolean = false;
  loading: boolean = false;

  constructor(private labadminService: LabadminService) {
  }

  ngOnInit() {
    let action = 'SearchUser'
    if(this.fromLocation == 'location') action = 'SearchLocation'
    this.getNPIDetails(action);
    // {
    //   this.getLocationNpiDetails();
    // }
    // else{
    //   this.getNPIDetails();
    // }
  }

  // checkNPIDetails() {
  //   return Object.keys(this.npiDetails).length
  // }

  getNPIDetails(action: string) {
    if (this.userDetials['NPI']?.toLowerCase() === 'unknown' || !this.userDetials['NPI']) {
      this.noDataFoundFlag = true;
      return
    }
    else {
      let objData = {
        City: null,
        FirstName: null,
        LastName: null,
        NPI: this.userDetials['NPI'],
        OrganizationName: null,
        State: null,
        Taxonomy: null,
        Zip: null
      }
      // this.userid = this.userDetials['UserID'];
      this.loading = true
      this.labadminService.npiValidate(Number(this.labadminService.organizationId), action, objData, this.labadminService.deploymentKey).subscribe({
        next: data => {          
        this.loading = false
        if (data.length)
          this.npiDetails = { 
            ...data[0], 
            
            mailingContactAddress: [data[0].address[0]?.address1, data[0].address[0]?.address2, data[0].address[0]?.city, data[0].address[0]?.state, `${data[0].address[0]?.zip}-${data[0].address[0]?.zip4}` ].filter(j => j).join(', '),

            primaryPracticeContactAddress: data[0].address.length > 1 ? [data[0].address[1]?.address1, data[0].address[1]?.address2, data[0].address[1]?.city, data[0].address[1]?.state, `${data[0].address[1]?.zip}-${data[0].address[1]?.zip4}` ].filter(j => j).join(', ') : '',

            secondaryPracticeContactAddress: data[0].practicelocation.length ? [data[0].practicelocation[0]?.address1, data[0].practicelocation[0]?.address2, data[0].practicelocation[0]?.city, data[0].practicelocation[0]?.state, `${data[0].practicelocation[0]?.zip}-${data[0].practicelocation[0]?.zip4}` ].filter(j => j).join(', ') : '',
          };
        else
          this.noDataFoundFlag = true
      },
      error: (err) => {
        console.error(err)
        this.loading = false
      }})
    }
  }

  // getLocationNpiDetails(){
  //   if(this.userDetials?.['NPI']?.toLowerCase() == 'unknown'){
  //     this.noDataFoundFlag = true;
  //     return;
  //   }
  //   let objData = {
  //     City: '',
  //     FirstName: '',
  //     LastName: '',
  //     NPI: this.userDetials['NPI'],
  //     OrganizationName: '',
  //     State: '',
  //     Taxonomy: '',
  //     Zip: ''
  //   }
  //   this.labadminService.locationNpiDetails(this.labadminService.deploymentKey, this.labadminService.organizationId, objData).subscribe(data => {
  //     if (data.length)
  //       this.npiDetails = data[0];
  //     else
  //       this.noDataFoundFlag = true
  //   })
  // }

  // remove comma(,) and null in the address
  // showAddressValue(addressDetailsArray) {
  //   let value = ""
  //   addressDetailsArray.forEach(val => {
  //     if (val)
  //       value += val + ',';
  //   });
  //   return value.toString().trim()[value.length - 1] == ',' ? value.substring(0, value.length - 1) : value;
  // }
}
